import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {equals} from 'ramda'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {updateUser} from '@daedalus/core/src/auth/components/FetchUser'
import {Brand} from '@daedalus/core/src/brand/types'

import {loadModule} from '../../utils/loadModule'
import {useOneTimePassword} from './hooks/useOneTimePassword'
import {setVisibility} from './slice'
import {getIsOneTimePasswordOpen} from './slice/selectors'

const RESEND_WAITING_TIME = 30

export type UIProps = {
  isOpen: boolean
  onClose: () => void
  isSecondStep: boolean
  phoneNumber: string
  setIsSecondStep: (value: boolean) => void
  validateOneTimePassword: (oneTimePassword: string) => void
  oneTimePasswordError: boolean
  setOneTimePasswordError: (value: boolean) => void
  setPhoneNumber: (value: string) => void
  requestOneTimePassword: () => void
  shouldEnableSend: boolean
  isSubmitting: boolean
  isValidating: boolean
  shouldCountTime: boolean
  secondsRemaining: number
  hasSubmitError: boolean
  userCountryCode: string
}

type OneTimePasswordFlowProps = {
  brand: Brand
  languageCode: string
  oneTimePasswordUrl: string
  userCountryCode: string
  verifyUrl: string
}

type ErrorType = {
  response: {
    status: number
  }
}

export const MobileLoadable = loadModule<UIProps>(
  async () =>
    import(
      /* webpackChunkName: "OneTimePasswordMobile" */
      './components/Mobile'
    ),
  {}
)

export const DesktopLoadable = loadModule<UIProps>(
  async () =>
    import(
      /* webpackChunkName: "OneTimePasswordDesktop" */
      './components/Desktop'
    ),
  {}
)

export const OneTimePasswordFlow = ({
  brand,
  languageCode,
  oneTimePasswordUrl,
  verifyUrl,
  userCountryCode
}: OneTimePasswordFlowProps) => {
  const isOpen = useSelector(getIsOneTimePasswordOpen)
  const dispatch = useDispatch()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isSecondStep, setIsSecondStep] = useState(false)
  const [shouldCountTime, setShouldCountTime] = useState(false)
  const [secondsRemaining, setSecondsRemaining] = useState(RESEND_WAITING_TIME)
  const {isMobile} = useDeviceLayout()
  const dispatchTeamEvent = useDispatchTeamEvent()
  const {code} = brand

  const [hasSubmitError, setHasSubmitError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [oneTimePasswordError, setOneTimePasswordError] = useState(false)

  const {sendOneTimePassword, validateOneTimePassword} = useOneTimePassword({
    brandCode: code,
    languageCode,
    phoneNumber,
    oneTimePasswordUrl,
    verifyUrl
  })

  const onClose = () => {
    dispatch(setVisibility(false))
    setIsSecondStep(false)
    setHasSubmitError(false)
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.SMSOverlay,
          action: Action.Displayed,
          team: Team.Retention
        })
      )
    }
  }, [isOpen])

  const requestOneTimePassword = async () => {
    try {
      setIsSubmitting(true)
      setOneTimePasswordError(false)
      await sendOneTimePassword()
      dispatchTeamEvent(
        trackEvent({
          category: Category.System,
          entity: Entity.SMS,
          action: Action.Succeeded
        })
      )

      setShouldCountTime(true)
      setIsSecondStep(true)
      setHasSubmitError(false)
    } catch (error: unknown) {
      setHasSubmitError(true)
      const knowError = error as ErrorType

      // Unauthorized error, send event without PII
      if (knowError?.response?.status === 401) {
        const errorWithoutPII = {
          status: 401,
          data: {
            brandCode: code,
            languageCode: languageCode,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - log error message
            // eslint-disable-next-line
            errorMessage: error?.message
          }
        }

        dispatchTeamEvent(
          trackEvent({
            category: Category.System,
            entity: Entity.SMS,
            action: Action.Errored,
            payload: {
              errorMessage: errorWithoutPII
            }
          })
        )

        return
      }

      // Unknown error, send event with all info so we can investigate
      dispatchTeamEvent(
        trackEvent({
          category: Category.System,
          entity: Entity.SMS,
          action: Action.Errored,
          payload: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - log error message
            // eslint-disable-next-line
            errorMessage: error?.message
          }
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleValidateOneTimePassword = async (oneTimePassword: string) => {
    setIsValidating(true)
    const isCorrect = await validateOneTimePassword(oneTimePassword)

    if (isCorrect) {
      dispatch(setVisibility(false))
      updateUser({publicUrl: null, dispatch, skipLoadingState: true})
      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.OTP,
          action: Action.Succeeded
        })
      )
      setOneTimePasswordError(false)
      setIsValidating(false)
      return
    }

    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.OTP,
        action: Action.Errored
      })
    )

    setOneTimePasswordError(true)
    setIsValidating(false)
  }

  const shouldEnableSend = shouldCountTime && secondsRemaining > 0

  useEffect(() => {
    if (!shouldCountTime) return

    const interval = setInterval(() => {
      setSecondsRemaining(secondsRemaining - 1)
    }, 1000)

    if (equals(secondsRemaining, 0)) {
      clearInterval(interval)

      setShouldCountTime(false)
      setSecondsRemaining(RESEND_WAITING_TIME)
    }

    return () => clearInterval(interval)
  }, [secondsRemaining, shouldCountTime])

  const [mountChildren, setMountChildren] = useState(isOpen)
  useEffect(() => {
    if (isOpen) setMountChildren(isOpen)
  }, [isOpen])

  // Defer loading child components until opened once
  if (!mountChildren) return null

  return isMobile ? (
    <>
      <MobileLoadable
        isOpen={isOpen}
        phoneNumber={phoneNumber}
        isSecondStep={isSecondStep}
        setIsSecondStep={setIsSecondStep}
        validateOneTimePassword={handleValidateOneTimePassword}
        oneTimePasswordError={oneTimePasswordError}
        setOneTimePasswordError={setOneTimePasswordError}
        setPhoneNumber={setPhoneNumber}
        requestOneTimePassword={requestOneTimePassword}
        shouldEnableSend={shouldEnableSend}
        isSubmitting={isSubmitting}
        isValidating={isValidating}
        shouldCountTime={shouldCountTime}
        secondsRemaining={secondsRemaining}
        onClose={onClose}
        hasSubmitError={hasSubmitError}
        userCountryCode={userCountryCode}
      />
    </>
  ) : (
    <DesktopLoadable
      isOpen={isOpen}
      phoneNumber={phoneNumber}
      isSecondStep={isSecondStep}
      setIsSecondStep={setIsSecondStep}
      validateOneTimePassword={handleValidateOneTimePassword}
      oneTimePasswordError={oneTimePasswordError}
      setOneTimePasswordError={setOneTimePasswordError}
      setPhoneNumber={setPhoneNumber}
      requestOneTimePassword={requestOneTimePassword}
      shouldEnableSend={shouldEnableSend}
      isSubmitting={isSubmitting}
      isValidating={isValidating}
      shouldCountTime={shouldCountTime}
      secondsRemaining={secondsRemaining}
      onClose={onClose}
      hasSubmitError={hasSubmitError}
      userCountryCode={userCountryCode}
    />
  )
}
