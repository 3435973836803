import {useEffect, useState} from 'react'
import debounce from 'lodash/debounce'

export const useScrollPosition = (withoutDebounce = false) => {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const callback = debounce(
      () => {
        setScrollPosition(window.scrollY)
      },
      withoutDebounce ? 0 : 300
    )

    window.addEventListener('scroll', callback)

    return () => {
      window.removeEventListener('scroll', callback)
    }
  }, [])

  return {
    scrollY: scrollPosition
  }
}
