import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import cookieConfig from 'config/cookies/web2AppBottomSheet'
import useGenerateAppDownloadUrl from 'hooks/useGenerateAppDownloadUrl'
import once from 'lodash/once'
import {getDevicePlatform} from 'modules/meta/selectors'
import Image from 'next/image'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Text} from '@daedalus/atlas/Text'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {Placement} from '@daedalus/core/src/deepLinking/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {HighlightedTitle} from '../HighlightedTitle'

interface Props {
  isVisible: boolean
}

export const Web2AppBottomSheet = ({isVisible}: Props) => {
  const {isMobile} = useDeviceLayout()
  const {brandIsVio} = useBrand()
  const dispatch = useDispatch()
  const devicePlatform = useSelector(getDevicePlatform)
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false)
  const {generateGeneralDeepLink} = useGenerateAppDownloadUrl()
  const appDownloadUrl = generateGeneralDeepLink(Placement.HomePageBottomSheet)

  const toggleBottomSheet = useCallback(() => {
    setBottomSheetOpen(value => !value)
  }, [])

  const {current: openWeb2AppBottomSheetOnce} = useRef(
    once(() => {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.AppInstallBottomSheet,
          action: Action.Displayed
        })
      )
      toggleBottomSheet()
    })
  )

  useEffect(() => {
    if (isVisible) {
      openWeb2AppBottomSheetOnce()
    }
  }, [isVisible, openWeb2AppBottomSheetOnce])

  const setWeb2AppBottomSheetCookie = useCallback(() => {
    const {secure, sameSite, maxAge: expires} = cookieConfig.options
    setCookie(cookieConfig.name, 'true', {
      secure,
      sameSite,
      expires
    })
  }, [])

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      setWeb2AppBottomSheetCookie()

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.AppInstallButton,
          action: Action.Clicked,
          payload: {
            selectedElement: 'continue_in_app_bottom_sheet'
          }
        })
      )

      setTimeout(() => {
        window.open(appDownloadUrl, '_blank')
        toggleBottomSheet()
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
    },
    [dispatch, devicePlatform, toggleBottomSheet, setWeb2AppBottomSheetCookie]
  )

  const handleClose = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.AppInstallBottomSheetClose,
        action: Action.Clicked
      })
    )
    setWeb2AppBottomSheetCookie()
    toggleBottomSheet()
  }, [dispatch, toggleBottomSheet, setWeb2AppBottomSheetCookie])

  if (!isMobile || (!brandIsVio && isMobile)) return null

  return (
    <BottomSheet
      headerVariant="floating"
      isOpen={isBottomSheetOpen}
      prefersReducedMotion
      isModal
      disableDrag
      onClose={handleClose}
    >
      <ContentWrapper paddingTop="s500" paddingBottom="s300">
        <Flex.Column justifyContent="center" alignItems="center">
          <Image
            alt="vio.com"
            src="/static/vio-app-icon.svg"
            height={64}
            width={64}
          />
          <ContentWrapper paddingTop="s400" paddingBottom="s600">
            <Text variant="title2XL">
              <HighlightedTitle source="UnlockExclusiveSavingsApp" />
            </Text>
          </ContentWrapper>
          <Button
            variant="primary"
            fullWidth
            size="xl"
            dataId="ContinueInApp"
            onClick={handleClick}
          >
            <FormattedMessageWrapper
              id="continueInApp"
              defaultMessage="Continue in app"
            />
          </Button>
        </Flex.Column>
      </ContentWrapper>
    </BottomSheet>
  )
}
