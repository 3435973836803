import React, {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {CompactOffer} from 'components/Offer/Compact'
import {getOfferVariant} from 'components/Offer/Shared/utils'
import {useOfferContainer} from 'hooks/useOfferContainer'
import {
  getAnchorHotelInDaedalusFormat,
  getHotelsInDaedalusFormat,
  getHotelTopOffer,
  getModuleState,
  getOfferEntity,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {ModuleState as SapiModuleState} from 'modules/sapiSearch/slice'
import {getError} from 'modules/search/selectors'
import {HotelType} from 'types/Hotel'
import {getHotelWithCheapestVisibleTopOffer} from 'utils/offers'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {PopUp} from '@daedalus/atlas/PopUp'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'

import {ExitPopupContent} from './ExitPopupContent'
import {ExitPopupHeader} from './ExitPopupHeader'
import {ExitPopupSkeleton} from './ExitPopupSkeleton'
import {MODAL_WIDTH, PopupOfferContainer} from './styles'
import {useExitPopup} from './useExitPopup'

export const ExitPopupSearchPage: React.FC = () => {
  const {isMobile} = useDeviceLayout()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const searchError = useSelector(getError)
  const moduleState = useSelector(getModuleState)
  const searchIsFinished = moduleState === SapiModuleState.SearchCompleted

  const {closePopup, isOpen} = useExitPopup({
    page: 'Search',
    isEnabled: searchIsFinished
  })

  const anchorHotel = useSelector(
    getAnchorHotelInDaedalusFormat
  ) as unknown as HotelType

  const paginatedResults = useSelector(
    getHotelsInDaedalusFormat
  ) as unknown as HotelType[]

  const searchHasError = Boolean(searchError)

  const isAnchorWithOffers = (hotel: HotelType) =>
    hotel?.offers?.length > 0 && hotel?.topOfferData

  const hotelsList: HotelType[] = useMemo(() => {
    if (isAnchorWithOffers(anchorHotel)) {
      if (anchorHotel) return [anchorHotel]
      return []
    }
    return paginatedResults
  }, [anchorHotel, paginatedResults])

  const hotelDetails = getHotelWithCheapestVisibleTopOffer(
    hotelsList,
    isAuthenticated
  )

  const hotelId = hotelDetails?.objectID

  const offerEntity = useSelector(state => getOfferEntity(state, hotelId))

  const offer = useSelector(state => getHotelTopOffer(state, hotelId))

  const {hasSplitBookingOffer, splitBookingType, splitBookingOffers} =
    useSelector(state => getSplitBookingDetails(state, hotelId))

  const offerIndex = 0 //  We always use first Item of offers list

  const isCheapest = true
  const isBofhOffer = getIsBofhOffer(offer)
  const isSplitBooking = useMemo(
    () => hasSplitBookingOffer && splitBookingType === 'single_flow',
    [hasSplitBookingOffer, splitBookingType]
  )

  const {anchorPrice, anchorPriceTotal} = useOfferContainer({
    offerEntity,
    hotelId
  })

  const sourceComponent = useMemo(
    () => (isMobile ? 'MobileBestDealModal' : 'DesktopBestDealModal'),
    [isMobile]
  )

  useEffect(() => {
    if (isOpen) {
      if ((!hotelDetails && searchIsFinished) || searchHasError) {
        closePopup()
      }
    }
  }, [hotelDetails, searchIsFinished, searchHasError, isOpen])

  // Prevent targeting all customers
  if (!isOpen) return null

  // Showing loading state
  if (!hotelDetails)
    return (
      <PopUp
        width={MODAL_WIDTH}
        isOpen={isOpen}
        closeAction={closePopup}
        headerVariant="inverse"
        titleBarProps={{
          centerContent: <ExitPopupHeader variant="loading" />,
          centerAligned: !isMobile
        }}
      >
        <ExitPopupSkeleton />
      </PopUp>
    )

  return (
    <PopUp
      width={MODAL_WIDTH}
      isOpen={isOpen}
      noPaddings
      headerVariant="inverse"
      titleBarProps={{
        centerContent: <ExitPopupHeader variant="searchResultsPage" />,
        centerAligned: !isMobile
      }}
      closeAction={() => closePopup()}
      footer={
        <PopupOfferContainer>
          <CompactOffer
            {...(isSplitBooking
              ? {
                  isSplitBooking: true,
                  variant: 'CompactDefault',
                  sourceComponent,
                  splitBookingOffers,
                  hasRoomLabels: true,
                  anchorPrice,
                  anchorPriceTotal,
                  hotelId
                }
              : {
                  hasInteraction: true,
                  offerPosition: offerIndex,
                  variant: 'CompactDefault',
                  sourceComponent,
                  offerVariant: getOfferVariant({
                    isCheapest,
                    isBofhOffer
                  }),
                  hasRoomLabels: true,
                  anchorPrice,
                  anchorPriceTotal,
                  offer,
                  hotelId
                })}
          />
        </PopupOfferContainer>
      }
    >
      <ExitPopupContent hotel={hotelDetails} />
    </PopUp>
  )
}
