import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {accounts} from 'google-one-tap'
import {Dispatch} from 'redux'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {useIsGDPRCountry} from '@daedalus/core/src/_web/utils/cookies/hooks/cookieComplianceGeolocation'
import loadScript from '@daedalus/core/src/_web/utils/network/loadScript'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  setGoogleOneTapSignedIn,
  setLastLoginUrl
} from '@daedalus/core/src/auth/business/persistData'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {
  getIsLoading,
  selectIsAuthenticated
} from '@daedalus/core/src/auth/modules/selectors'
import {socialLoginService} from '@daedalus/core/src/auth/services'
import {CognitoHostedUIIdentityProvider} from '@daedalus/core/src/auth/types/Cognito'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {isCookieAcceptedMoreThanFiveMinutesAgo} from './utils/constraints'

declare let google: {
  accounts: accounts
}

const signInViaSocialLogin = async () => {
  const currentURL = new URL(window.location.href)
  setLastLoginUrl(currentURL.toString())
  setGoogleOneTapSignedIn()
  await socialLoginService(CognitoHostedUIIdentityProvider.Google)
}

interface Props {
  isCookiesAcceptedByUser: boolean
}

export const GoogleOneTap = ({isCookiesAcceptedByUser}: Props) => {
  const isLoading = useSelector(getIsLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isGDPRCountry = useIsGDPRCountry()
  const dispatch = useDispatch()

  const handleOneTapClick = (dispatch: Dispatch) => () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.GoogleOneTap,
        action: Action.Clicked,
        team: Team.Retention,
        payload: {
          authenticationMethod: 'Google'
        }
      })
    )

    setTimeout(() => {
      signInViaSocialLogin()
    }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
  }

  const initializeGoogleOneTap = useCallback(async (dispatch: Dispatch) => {
    try {
      await loadScript('https://accounts.google.com/gsi/client', true)

      window.onGoogleLibraryLoad = () => {
        google.accounts.id.initialize({
          client_id:
            '19374736224-qpt7ocsss5tvalq3uukao08mb9m4lm8h.apps.googleusercontent.com',
          cancel_on_tap_outside: false,
          callback: handleOneTapClick(dispatch),
          use_fedcm_for_prompt: true
        })

        google.accounts.id.prompt()
      }
    } catch (error) {
      // The Google One Tap script is rejected by AD Blockers, which causes unnecessary noise on Sentry, so we decided to ignore it
    }
  }, [])

  // Not a GDPR country or user's already accepted cookies and the cookie is older than 5 minutes
  const cookieBanner = getCookie('cookieConsentTimestamp')
  const isEligibleForGoogleOneTap = Boolean(
    !isGDPRCountry ||
      (isCookiesAcceptedByUser &&
        isCookieAcceptedMoreThanFiveMinutesAgo(cookieBanner))
  )

  useEffect(() => {
    if (
      !isAuthenticated &&
      !isLoading &&
      !isReactNativeWebView() &&
      isEligibleForGoogleOneTap
    ) {
      initializeGoogleOneTap(dispatch)
    }
  }, [
    isAuthenticated,
    isLoading,
    isEligibleForGoogleOneTap,
    dispatch,
    initializeGoogleOneTap
  ])

  return null
}
