import React from 'react'

import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ProviderLogo} from '../ProviderLogo'

export const ReviewSource = ({providerCode}: {providerCode: string}) => {
  return (
    <Flex.Row gap="s200">
      <Text variant="bodyXS" colorPath="content.neutral.c600">
        <FormattedMessageWrapper id="via" defaultMessage="via" />
      </Text>
      <ProviderLogo providerCode={providerCode} height={16} />
    </Flex.Row>
  )
}
