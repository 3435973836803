import {
  initializeUserCookiesPreference,
  setCookiesAcceptedByUser as setStateForCookiesAcceptedByUser
} from 'modules/cookies/slice'
import {
  isCookiesAcceptedByUser,
  setCookiesAcceptedByUser as setLocalStorageForCookiesAcceptedByUser
} from 'utils/persistence'

import {MiddlewareType} from '..'

const middleware: MiddlewareType = store => next => action => {
  switch (action.type) {
    case initializeUserCookiesPreference.type: {
      const {dispatch} = store
      dispatch(setStateForCookiesAcceptedByUser(isCookiesAcceptedByUser()))
      break
    }

    case setStateForCookiesAcceptedByUser.type: {
      if (action.payload) {
        setLocalStorageForCookiesAcceptedByUser()
      }

      return next(action)
    }
  }

  return next(action)
}

export default middleware
