import React from 'react'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import InjectMetaDescription from '@daedalus/core/src/localization/components/InjectMetaDescription'

const InjectPageMetaDescription = () => {
  const {brandName} = useBrand()

  return <InjectMetaDescription brandName={brandName} />
}

export default InjectPageMetaDescription
