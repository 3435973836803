import React, {useCallback} from 'react'
import {
  CaptionLabelProps,
  DateFormatter,
  DayContentProps,
  DayPicker,
  Matcher
} from 'react-day-picker'
import {cx} from '@linaria/core'
import addMonths from 'date-fns/addMonths'
import isSameDay from 'date-fns/isSameDay'
import isSameMonth from 'date-fns/isSameMonth'
import {enUS} from 'date-fns/locale'
import subDays from 'date-fns/subDays'

import {Icon} from '@daedalus/atlas/Icon'
import {AvailabilityHotelEntity} from '@daedalus/core/src/availability/types'
import {WEEKENDS_INDEXES} from '@daedalus/core/src/datePicker/config'
import {
  dateFormat,
  dateStringToMiddayDate,
  dateToMiddayDate,
  MiddayDate,
  UTS_DATE_FORMAT
} from '@daedalus/core/src/utils/date'

import {MonthName, WeekDay} from '../components'
import {ColorPriceDayInner} from '../components/ColorPriceDayInner'
import {availabilityDatepickerClassNames} from './styles'

export type DatePickerVariant = 'vertical' | 'horizontal'

export type DayOfWeekType = 0 | 1 | 2 | 3 | 4 | 5 | 6

type ReactRefType<ElementType> = {current: null | ElementType}

interface Props {
  containerRef?: ReactRefType<HTMLDivElement>
  selectedDate: string | null | undefined
  firstDayOfWeek: number
  maxMonthsCount?: number
  months: string[]
  numberOfMonthsToShow?: number
  onDayClick: (date: string) => void
  weekdaysShort: string[]
  showWeekDays?: boolean
  variant?: DatePickerVariant
  earliestCheckInDate?: Date
  hotelAvailabilityPrices: AvailabilityHotelEntity
  checkIn: string
  onMonthChange: (date: Date) => void
  showTotalPrices?: boolean
  searchComplete?: boolean
}

const getInitialMonth = (
  selectedDate: MiddayDate | null,
  variant: DatePickerVariant
) => {
  const todayDate = dateToMiddayDate(new Date())
  if (!selectedDate) {
    return todayDate
  }

  if (variant === 'vertical') {
    return selectedDate < todayDate ? selectedDate : todayDate
  }

  return selectedDate
}

const DatePickerComponent: React.FC<Props> = ({
  containerRef,
  selectedDate,
  weekdaysShort,
  months,
  firstDayOfWeek,
  numberOfMonthsToShow = 14,
  maxMonthsCount = 12,
  onDayClick,
  variant = 'horizontal',
  earliestCheckInDate = dateToMiddayDate(new Date()),
  hotelAvailabilityPrices,
  checkIn,
  onMonthChange,
  showWeekDays = false,
  showTotalPrices,
  searchComplete
}) => {
  const handleDayClick = (date: MiddayDate) => {
    const selectedDateDate = dateFormat(date, UTS_DATE_FORMAT)
    onDayClick(selectedDateDate)
  }

  const selectedDateDate = selectedDate
    ? dateStringToMiddayDate(selectedDate)
    : undefined
  const maxCheckInDate = subDays(
    addMonths(earliestCheckInDate, maxMonthsCount),
    1
  )

  const initialMonth = getInitialMonth(
    selectedDateDate || dateStringToMiddayDate(checkIn),
    variant
  )
  const fromMonth =
    variant === 'horizontal' ? earliestCheckInDate : initialMonth

  const toMonth = maxCheckInDate

  // FIXME: Days Of Week should be configurable based on Locale
  const modifiers: Record<string, Matcher | Matcher[]> = {
    startEnd: (day: Date) => isSameDay(day, selectedDateDate as Date),
    start: (day: Date) => isSameDay(day, selectedDateDate as Date),
    weekends: {dayOfWeek: [0, 6]}
  }

  const disabledDays = {
    before: earliestCheckInDate
  }

  const DayContent = useCallback(
    ({date}: DayContentProps) => (
      <ColorPriceDayInner
        day={date}
        hotelAvailabilityPrices={hotelAvailabilityPrices}
        searchComplete={searchComplete}
        showTotalPrices={showTotalPrices}
        shouldShowPrices
      />
    ),
    [hotelAvailabilityPrices, searchComplete, showTotalPrices]
  )

  const CaptionLabel = useCallback(
    ({displayMonth}: CaptionLabelProps) => {
      const isCheckInMonth = selectedDateDate
        ? isSameMonth(displayMonth, selectedDateDate)
        : false

      return (
        <MonthName
          months={months}
          isCheckInMonth={isCheckInMonth}
          date={displayMonth}
        />
      )
    },
    [selectedDate, months, variant]
  )

  const formatWeekdayName: DateFormatter = useCallback(
    (date: Date) => {
      const weekday = date.getDay()
      const isWeekend = WEEKENDS_INDEXES.includes(weekday)
      return (
        <WeekDay
          title={weekdaysShort[date.getDay()]}
          className={cx(isWeekend && 'isWeekend')}
        >
          {weekdaysShort[date.getDay()]}
        </WeekDay>
      )
    },
    [weekdaysShort, variant]
  )

  return (
    <div
      ref={containerRef}
      className={cx(
        variant === 'vertical' && 'isVertical',
        'isSingleMonth',
        showWeekDays && 'showWeekDays',
        availabilityDatepickerClassNames
      )}
    >
      <DayPicker
        locale={enUS}
        onDayClick={handleDayClick}
        classNames={{
          root: 'wrapper',
          day: 'day-cell',
          months: 'months-wrapper',
          month: 'month-wrapper',
          caption: 'caption-wrapper',
          caption_start: 'caption-start-wrapper',
          caption_label: 'caption-label-wrapper',
          caption_end: 'caption-end-wrapper',
          nav_button: 'nav-button',
          nav_button_next: 'nav-button-next',
          nav_button_previous: 'nav-button-previous',
          head_cell: 'week-day-wrapper',
          table: 'calendar-wrapper',
          head: 'calendar-week-days-wrapper',
          day_selected: 'day-selected',
          day_disabled: 'day-disabled'
        }}
        selected={selectedDateDate}
        modifiers={modifiers}
        disabled={disabledDays}
        defaultMonth={initialMonth} // Superfluous but required for test mocking
        fromMonth={fromMonth}
        toMonth={toMonth}
        numberOfMonths={numberOfMonthsToShow}
        formatters={{
          formatWeekdayName
        }}
        weekStartsOn={firstDayOfWeek as DayOfWeekType}
        disableNavigation={variant !== 'horizontal'}
        onMonthChange={onMonthChange}
        components={{
          DayContent,
          CaptionLabel,
          IconRight: () => <Icon name="ChevronRight" />,
          IconLeft: () => <Icon name="ChevronLeft" />
        }}
        modifiersClassNames={{
          selected: 'selected',
          today: 'today',
          weekends: 'weekends',
          start: 'start',
          disabled: 'disabled',
          outside: 'outside',
          week: 'week',
          end: 'end'
        }}
      />
    </div>
  )
}

export const AvailabilityDatePicker = React.memo(DatePickerComponent)
