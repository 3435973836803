import React, {useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getFacilitiesFilterVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {searchParamToFacet} from 'modules/search/constants'
import {FilterUrlParam} from 'modules/search/types'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {FacilitiesFilterBottomSheetContentLoadable} from './Loadable'

export const FacilitiesFilterBottomSheet = () => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  // Store the facetKey in a ref to simulate an instance variable that won't change when the component re-renders
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const facetFilterKeys = useRef([searchParamToFacet.facilities.param])
  const {
    submitFilters,
    getFilters,
    clearFilters,
    resetFilters,
    calculateIfFiltersChanged,
    getAppliedFilters,
    toggleMultiFilter
  } = useFiltersForm(facetFilterKeys.current)

  const isOpen = useSelector(getFacilitiesFilterVisibility)
  const facetFilterKey = facetFilterKeys.current[0]
  const facetFilter = getFilters()[facetFilterKey] as FilterUrlParam
  const appliedFacetFilter = getAppliedFilters()[facetFilterKey]

  const handleApply = () => {
    if (calculateIfFiltersChanged(getFilters())) {
      submitFilters()
    } else {
      dispatch(hideOverlay())
    }
  }

  const handleClear = () => {
    if (facetFilter) {
      clearFilters()
    } else {
      dispatch(hideOverlay())
    }
  }

  const handleClose = () => {
    resetFilters()
    dispatch(hideOverlay())
  }

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={handleClose}
      titleBarProps={{
        centerContent: formatMessage(filterMessages.facilities)
      }}
      snapPoints={{top: 'full', middle: 0.95}}
      withDragHandler
      bottomBarContent={
        <Flex.Row
          justifyContent="space-between"
          data-id="facilitiesFilterBottomSheetButtons"
        >
          <Button
            dataId="clearFiltersButton"
            onClick={handleClear}
            variant="secondary"
          >
            <FormattedMessageWrapper id="clear" defaultMessage="Clear" />
          </Button>
          <Button dataId="applyFiltersButton" onClick={handleApply}>
            <FormattedMessageWrapper id="apply" defaultMessage="Apply" />
          </Button>
        </Flex.Row>
      }
    >
      {isOpen && (
        <FacilitiesFilterBottomSheetContentLoadable
          facetFilter={facetFilter}
          appliedFacetFilter={appliedFacetFilter}
          toggleMultiFilter={toggleMultiFilter}
        />
      )}
    </BottomSheet>
  )
}
