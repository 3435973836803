import React, {useCallback, useRef} from 'react'
import {useFiltersForm} from 'hooks/useFiltersForm'
import isNil from 'lodash/isNil'
import {Lov} from 'modules/search/slice'
import {takeLast} from 'ramda'
import {FacetType} from 'types/Search'
import {getFacetLabel} from 'utils/filters'

import {FilterDisplayContainer} from '../../FilterDisplayContainer/FilterDisplayContainer'
import {
  FacetFilter,
  FacetGroupItem,
  RenderType
} from '../../Filters/FacetFilter'

type Props = {
  facets: FacetType[]
  dataId?: string
  listOfValues: Lov
  labelMessage: string
  facetKey: string
  facetLabel: string
  renderType: RenderType
  facetGroups: FacetGroupItem[]
  isActive: boolean
  /** Name of Atlas icon */
  icon?: React.ReactElement
}

export const FacetFilterPopover = ({
  renderType,
  facetGroups,
  facetKey,
  facetLabel,
  labelMessage,
  facets,
  listOfValues,
  dataId,
  isActive,
  icon = null
}: Props) => {
  // Store the facetKey in a ref to simulate an instance variable that won't change when the component re-renders
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const facetFilterKeys = useRef([facetKey])
  const facetFilterKey = facetFilterKeys.current[0]

  const getLabel = getFacetLabel(facetLabel, listOfValues)
  const {
    submitFilters,
    getFilters,
    getAppliedFilters,
    toggleMultiFilter,
    clearFilters,
    resetFilters,
    trackFilterClick
  } = useFiltersForm(facetFilterKeys.current)

  const facetFilter = getFilters()[facetFilterKey]
  const appliedFacetFilter = getAppliedFilters()[facetFilterKey]

  const buildFacetsLabel = useCallback((): string => {
    if (isNil(appliedFacetFilter) || isNil(listOfValues)) {
      return labelMessage
    }

    if (appliedFacetFilter.length === 1) {
      return getLabel(appliedFacetFilter[0])
    }

    return `${getLabel(takeLast(1, appliedFacetFilter).toString())} +${
      appliedFacetFilter.length - 1
    }`
  }, [getLabel, appliedFacetFilter, labelMessage, listOfValues])

  return (
    <FilterDisplayContainer
      label={buildFacetsLabel() as React.ReactNode}
      icon={icon}
      dataId={dataId}
      title={labelMessage}
      isActive={isActive}
      onClear={clearFilters}
      onApply={submitFilters}
      onClose={resetFilters}
      onPillClick={trackFilterClick}
      paddingBottom={renderType == RenderType.Card ? 's500' : 's000'}
    >
      <FacetFilter
        isScrollable
        renderType={renderType}
        facets={facets}
        facetGroups={facetGroups}
        filters={facetFilter}
        appliedFilters={appliedFacetFilter}
        searchParamName={facetFilterKey}
        getLabel={getLabel}
        onChange={toggleMultiFilter}
        columns={2}
      />
    </FilterDisplayContainer>
  )
}
