import {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getCurrencyCode} from 'modules/meta/selectors'

const MOBILE_MAX_LENGTH = 10
const MAX_LENGTH = 14

export const usePriceUtils = (forceMobileLayout = false) => {
  const {formatNumber} = useIntl()

  const currencyCode = useSelector(getCurrencyCode)

  const getPriceDetails = useCallback(
    (price: number, isMobile = false) => {
      const maxLength =
        isMobile || forceMobileLayout ? MOBILE_MAX_LENGTH : MAX_LENGTH
      const formattedLength = formatNumber(price, {
        style: 'currency',
        currency: currencyCode,
        maximumFractionDigits: 0
      }).length

      return {
        isLongPrice: formattedLength + 1 > maxLength,
        shouldWrapAnchorPrice: formattedLength * 2 > maxLength && isMobile
      }
    },
    [currencyCode, formatNumber]
  )

  return {getPriceDetails}
}
