import React from 'react'

import {useGetHotelQuery} from '@daedalus/core/src/sapi/services/searchApi'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src'

type ContentHotelContextProps = {
  hotelId: string
  children: React.ReactNode
}

const ContentHotelContext = React.createContext<ContentHotel>(null)

export const useContentHotelContext = () => {
  const hotel = React.useContext(ContentHotelContext)

  return {
    hotel
  }
}

export const ContentHotelProvider = ({
  hotelId,
  children
}: ContentHotelContextProps) => {
  const {data: contentHotel} = useGetHotelQuery(
    {
      hotelId
    },
    {
      skip: !hotelId
    }
  )

  return (
    <ContentHotelContext.Provider value={contentHotel}>
      {children}
    </ContentHotelContext.Provider>
  )
}
