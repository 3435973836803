import {FatalErrorAction} from 'modules/common/actions/errors'
import {overlayMiddleware} from 'modules/overlay/slice'
import offersV3MappingMiddleware from 'modules/sapiSearch/middleware/offersV3MappingMiddleware'
import {Middleware} from 'redux'
import createSentryMiddleware from 'redux-sentry-middleware'
import {RootState} from 'store'

import coreAnalytics from '@daedalus/core/src/analytics/modules/middleware'
import {
  removeUserData,
  removeUserProfile
} from '@daedalus/core/src/auth/modules/middleware'
import {State} from '@daedalus/core/src/auth/modules/selectors'
import {SearchApiActionsType} from '@daedalus/core/src/sapi/services/searchApi/action'
import {dealFreezeMiddleware} from '@daedalus/shared/src/dealFreeze/modules/middleware'

import {ModuleActionType as SearchActionType} from '../modules/search/actions'
import analytics from './analytics'
import {ModuleActionType as AnalyticsActionType} from './analytics/actions'
import searchApiAnalytics from './analytics/searchApiAnalytics'
import errorHandling from './errorHandling'
import navigation from './navigation'
import {ModuleActionType as NavigationActionType} from './navigation/actions'
import persistence from './persistence'
import searchApi from './searchApi'
import userSessionMonitoring from './userSessionMonitoring'

export type StoreActionsType =
  | SearchActionType
  | AnalyticsActionType
  | NavigationActionType
  | FatalErrorAction
  | SearchApiActionsType

export type MiddlewareType = Middleware<StoreActionsType, RootState>

const middlewareList = [
  analytics,
  errorHandling,
  userSessionMonitoring,
  navigation,
  persistence,
  searchApi,
  coreAnalytics,
  searchApiAnalytics,
  offersV3MappingMiddleware,
  dealFreezeMiddleware,
  overlayMiddleware,
  ...(typeof Sentry === 'undefined'
    ? []
    : [
        createSentryMiddleware(Sentry, {
          stateTransformer: (state: State) => removeUserProfile(state),
          actionTransformer: action => removeUserData(action)
        })
      ])
]

const jsDomSafeMiddlewareList = [
  // analytics, // Writing script tags fails
  searchApiAnalytics,
  errorHandling,
  // userSessionMonitoring, // No need to track sessions in tests
  navigation,
  persistence
]

// Some redux middleware has side-effects like writing script tags that can fail
// Replace middleware list for test and storybook to only include middleware that works in those environments
const isTestEnvironment =
  process.env.STORYBOOK || process.env.NODE_ENV === 'test'
const safeMiddlewareList = isTestEnvironment
  ? jsDomSafeMiddlewareList
  : middlewareList

export default safeMiddlewareList
