import {MouseEvent, useCallback, useContext} from 'react'
import {useSelector} from 'react-redux'
import {useHandleOfferClick} from 'components/Offer/Shared/OfferInteraction/useHandleOfferClick'
import {useOfferTrackingContextWithFallback} from 'context/offerTracking'
import {apOfferMountTypes, OfferViewContext} from 'context/offerViewContext'
import noop from 'lodash/noop'
import {getOfferContext} from 'middleware/analytics/selectors'
import {getMetaBrandHasRooms} from 'modules/accommodation/selectors'
import {
  getAppLockedDealConfig,
  getHotelOfferEntity
} from 'modules/sapiSearch/selectors'
import {toggle} from 'opticks'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getClosestDataIdToEventTarget} from '@daedalus/core/src/_web/utils/browser'
import {useOpticks} from '@daedalus/core/src/experiments/components/OpticksProvider'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {useLogIn} from '@daedalus/shared/src/authentication/hooks/useLogIn'

import {useShouldOfferLeadToRooms} from './useShouldOfferLeadToRooms'

interface Props {
  component: string
  hotelId: string
  offer: SearchOffer
  /** @deprecated Uses outdated components from before the rebranding. Only used in the deprecated `components/OfferList` component. */
  offerPosition: number
  isSelectedOffer?: boolean
  onClick?: (offer: SearchOffer) => void
  isDiscount?: boolean
  isSplitBooking?: boolean
  logIn?: ReturnType<typeof useLogIn>
}

export const useOfferClickHandler = ({
  component,
  hotelId,
  offer,
  offerPosition,
  onClick,
  isDiscount,
  isSplitBooking,
  logIn = noop
}: Props) => {
  // Allow context to overwrite default selectors
  const {offerContext} = useOfferTrackingContextWithFallback({
    offer,
    offerEntity: useSelector(state => getHotelOfferEntity(state, hotelId)),
    offerContext: useSelector(state =>
      getOfferContext(state, hotelId, offer?.id, component)
    )
  })
  const brandHasRooms = useSelector(getMetaBrandHasRooms)
  const isBofhOffer = getIsBofhOffer(offer)
  const onOfferClicked = useHandleOfferClick({
    hotelId,
    sourceComponent: component,
    offer,
    isSplitBooking,
    logIn
  })
  const Opticks = useOpticks()
  const shouldGoToRooms = useShouldOfferLeadToRooms(isBofhOffer)
  const {isMobile, isDesktopXs} = useDeviceLayout()
  const offerView = useContext(OfferViewContext)
  const isMountedOnAp = apOfferMountTypes.includes(offerView)
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      const isMobileOrTablet = isMobile || isDesktopXs
      const selectedElementId = getClosestDataIdToEventTarget(event)

      if (Opticks) {
        Opticks.setAudienceSegmentationAttributes({
          isVioClicker: isBofhOffer
        })
      }

      if (
        isBofhOffer &&
        isMobileOrTablet &&
        !isMountedOnAp &&
        !isAppLockedDeal
      ) {
        const isRedirectVioLeader = toggle(
          '6cde7b20-bookingexp-standalone-ap-mobile-v5',
          brandHasRooms,
          false
        )

        if (isRedirectVioLeader && !isSplitBooking) {
          /**
           * OfferInteraction renders an <a> link (to RSP). Since we assign this experiment on click we cannot change the element to a button.
           * The following cancels the link open & allows `onOfferClicked` to show AP rooms instead
           */
          event.preventDefault()
        }
      }

      if (shouldGoToRooms && !isAppLockedDeal) {
        event.preventDefault()
      }

      onOfferClicked({
        offer,
        selectedElementId,
        offerContext,
        offerPosition,
        isDiscount
      })

      onClick?.(offer)
    },
    [onClick, offerContext, offerPosition, onOfferClicked, offer, isBofhOffer]
  )

  return {
    handleClick
  }
}
