import axios from 'axios'
import Settings from 'Settings'

const API_URL = 'https://translation.googleapis.com/language/translate/v2'

export const translateText = async (
  text: string,
  targetLanguage: string
): Promise<string> =>
  await axios
    .post(`${API_URL}?key=${Settings.get('GOOGLE_TRANSLATE_API_KEY')}`, {
      q: text,
      target: targetLanguage
    })
    .then(({data: {data}}) => {
      const {translations} = data

      if (translations.length) return translations[0].translatedText
      return ''
    })
