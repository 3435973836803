import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {toggle} from 'opticks'

import {setHoldOutGroup} from '../../slice'

export const SyncStateWithPriceWatchHoldoutGroup = () => {
  const dispatch = useDispatch()

  const isHoldOutGroup = toggle(
    'a87807d8-price-watch-toggle-holdout',
    true,
    false
  )

  useEffect(() => {
    dispatch(setHoldOutGroup(isHoldOutGroup))
  }, [isHoldOutGroup, dispatch])

  return null
}
