import React, {useRef} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {FilterDisplayContainer} from 'components/FilterDisplayContainer/FilterDisplayContainer'
import {filterMessages} from 'components/Filters/filterMessages'
import {PropertyTypeFilter} from 'components/Filters/PropertyTypeFilter/PropertyTypeFilter'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getFacets} from 'modules/sapiSearch/selectors'
import {searchParamToFacet} from 'modules/search/constants'
import {getListOfValues} from 'modules/search/selectors'
import {isNil, takeLast} from 'ramda'
import {getFacetLabel} from 'utils/filters'

import {PROPERTY_GROUPS, PROPERTY_TYPE_LIMIT} from './constants'

interface Props {
  isActive?: boolean
}

export const PropertyTypeFilterPopover: React.FC<Props> = ({
  isActive = false
}) => {
  const {formatMessage} = useIntl()
  const facets = useSelector(getFacets) ?? {}
  const listOfValues = useSelector(getListOfValues)

  const facetFilterKeys = useRef([searchParamToFacet.propertyTypes.param])
  const facetFilterKey = facetFilterKeys.current[0]

  const getLabel = getFacetLabel('PropertyType', listOfValues)
  const {
    submitFilters,
    getFilters,
    getAppliedFilters,
    toggleMultiFilter,
    clearFilters,
    resetFilters,
    trackFilterClick,
    setMultiFilters,
    removeMultiFilters
  } = useFiltersForm(facetFilterKeys.current)

  const facetFilter = getFilters()[facetFilterKey]
  const appliedFacetFilter = getAppliedFilters()[facetFilterKey]
  const labelMessage = formatMessage(filterMessages.propertyType)

  const buildFacetsLabel = React.useCallback((): string => {
    if (isNil(appliedFacetFilter) || isNil(listOfValues)) {
      return labelMessage
    }

    if (appliedFacetFilter.length === 1) {
      return getLabel(appliedFacetFilter[0])
    }

    return `${getLabel(takeLast(1, appliedFacetFilter).toString())} +${
      appliedFacetFilter.length - 1
    }`
  }, [getLabel, appliedFacetFilter, listOfValues, labelMessage])

  return (
    <FilterDisplayContainer
      label={buildFacetsLabel() as React.ReactNode}
      dataId="propertyTypeFilter"
      title={labelMessage}
      isActive={isActive}
      onClear={clearFilters}
      onApply={submitFilters}
      onClose={resetFilters}
      onPillClick={trackFilterClick}
      paddingBottom="s000"
    >
      <PropertyTypeFilter
        isPopover
        facets={facets[searchParamToFacet.propertyTypes.facet]}
        facetGroups={PROPERTY_GROUPS}
        filters={facetFilter}
        appliedFilters={appliedFacetFilter}
        searchParamName={facetFilterKey}
        getLabel={getLabel}
        columns={2}
        limit={PROPERTY_TYPE_LIMIT}
        onChange={toggleMultiFilter}
        showMoreRenderType="Link"
        setMultiFilters={setMultiFilters}
        removeMultiFilters={removeMultiFilters}
      />
    </FilterDisplayContainer>
  )
}
