export const searchParamToFacet = {
  starRatings: {param: 'starRatings', facet: 'starRating'},
  themes: {param: 'themes', facet: 'themeIds'},
  facilities: {param: 'facilities', facet: 'facilities'},
  propertyTypes: {param: 'propertyTypes', facet: 'propertyTypeId'},
  priceMin: {param: 'priceMin', facet: 'pricing.medianRateBkt'},
  priceMax: {param: 'priceMax', facet: 'pricing.medianRateBkt'},
  reviewScore: {param: 'reviewScore', facet: 'guestRating.overall'},
  objectID: {param: 'objectID', facet: 'objectID'},
  hotelName: {param: 'hotelName', facet: 'hotelName'}
}

export const filterParamNames = [
  'reviewScore',
  'starRatings',
  'propertyTypes',
  'facilities',
  'themes',
  'hotelName',
  'priceMin',
  'priceMax',
  'freeCancellation',
  'chainIds',
  'amenities'
]

export const HOSTEL_PROPERTY_TYPE_ID = '5'

export const PRICE_BUCKETS_COUNT = 31
export const PRICE_BUCKET_WIDTH = 10

export const SORT_FIELD_PRICE = 'price'
export const SORT_FIELD_DISTANCE = 'distance'
export const SORT_FIELD_GUEST_RATING = 'guestRating'
export const SORT_FIELD_STAR_RATING = 'starRating'

export const MAX_NO_RESULT = 3

export const persistedUTMParamsKeys = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'label'
] as const

export const persistedFilterKeys = [
  'facilities',
  'freeCancellation',
  'starRatings',
  'priceMin',
  'priceMax',
  'propertyTypes',
  'themes',
  'amenities',
  'reviewScore',
  'sortOrder',
  'sortField'
] as const

export const persistedBaseUrlParamKeys = [
  'token',
  'layout',
  'profile',
  ...persistedUTMParamsKeys
]

export const persistedUrlParamKeys = [
  ...persistedBaseUrlParamKeys,
  ...persistedFilterKeys
] as const
