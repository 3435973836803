import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useDatePickerTiming} from 'hooks/useDatePickerTiming'
import {useScrollPosition} from 'hooks/useScrollPosition'
import {trackTimingEvent} from 'middleware/analytics'
import {
  showDatePicker as openDatePickerOverlay,
  showDestinationPicker as openDestinationPickerOverlay,
  showGuestPicker as openGuestPickerOverlay
} from 'modules/overlay/actions'
import {getSearchDisplayName} from 'modules/search/selectors'
import {
  getSearchBoxValues,
  getShowRoomsLabel
} from 'modules/searchBox/selectors'
import {closeSearchBox} from 'modules/searchBox/slice'

import {scrollToWindowTop} from '@daedalus/core/src/_web/utils/browser'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {SearchFormVertical} from '@daedalus/shared/src/search/SearchFormVertical'

interface Props {
  onDestinationPickerClick?: () => void
}

export const FakeSearchFormWithStore: React.FC<Props> = ({
  onDestinationPickerClick
}) => {
  const dispatch = useDispatch()
  const {datePickerLoadedTimerStart} = useDatePickerTiming()

  const {checkIn, checkOut, rooms} = useSelector(getSearchBoxValues)
  const destination = useSelector(getSearchDisplayName)
  const {numberOfAdults, numberOfChildren, numberOfRooms} =
    roomsToConfigurationObject(rooms)

  const showRoomsLabel = useSelector(getShowRoomsLabel)
  const {scrollY} = useScrollPosition()

  useEffect(() => {
    trackTimingEvent(
      dispatch,
      'SearchBoxReady',
      'SearchBoxReady-start',
      Entity.SearchBox
    )
  }, [dispatch])

  const handleDestinationPickerClick = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DestinationPickerInput,
        action: Action.Clicked
      })
    )
    dispatch(openDestinationPickerOverlay())
    onDestinationPickerClick?.()
  }, [dispatch, onDestinationPickerClick])

  const handleDatePickerClick = useCallback(
    (type: DatePickerType) => {
      datePickerLoadedTimerStart()

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.DatePickerInput,
          action: Action.Clicked,
          component: type
        })
      )
      dispatch(openDatePickerOverlay(type))
    },
    [dispatch, datePickerLoadedTimerStart]
  )

  const handleGuestPickerClick = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.GuestPickerInput,
        action: Action.Clicked
      })
    )
    dispatch(openGuestPickerOverlay())
  }, [dispatch])

  const handleClose = useCallback(() => {
    if (scrollY > 0) {
      scrollToWindowTop()
    }

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.SearchBoxSearchButton,
        action: Action.Clicked
      })
    )
    dispatch(closeSearchBox())
  }, [dispatch, scrollY])

  return (
    <SearchFormVertical
      destination={destination}
      checkIn={checkIn}
      checkOut={checkOut}
      numberOfAdults={numberOfAdults}
      numberOfChildren={numberOfChildren}
      numberOfRooms={numberOfRooms}
      onDatePickerClick={handleDatePickerClick}
      onDestinationPickerClick={handleDestinationPickerClick}
      onGuestPickerClick={handleGuestPickerClick}
      onSubmit={handleClose}
      isRoomsLabelShown={showRoomsLabel}
    />
  )
}
