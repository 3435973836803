import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'DealFreeze Offers simulations',
  value: 'DealFreezeOffers',
  description: 'Scenarios for the DealFreeze Offers endpoint, offers check',
  routes: [
    {
      endpoint: EndpointNames.DealFreezeOffers,
      method: RequestMethod.GET,
      page: Pages.AP,
      variations: [
        {
          value: 'successfulFreeze',
          displayName: 'Successful Deal Freeze',
          description: 'Always returns the available response for offer check'
        },
        {
          value: 'unavailableFreeze',
          displayName: 'Unavailable Error Deal Freeze',
          description:
            'Always returns the Unavailable error response for offer check'
        },
        {
          value: 'priceMismatchFreeze',
          displayName: 'Price Mismatch Error Deal Freeze - Sold out',
          description:
            'Always returns the Price Mismatch - (Sold out) error response for offer check'
        }
      ]
    },
    {
      endpoint: EndpointNames.DealFreezeBookings,
      method: RequestMethod.POST,
      page: Pages.AP,
      variations: [
        {
          value: 'successfulFreeze',
          displayName: 'Successful Deal Freeze',
          description: 'Always returns a dummy `booking_status` link '
        }
      ]
    },
    {
      endpoint: EndpointNames.BookingStatus,
      method: RequestMethod.GET,
      page: Pages.AP,
      variations: [
        {
          value: 'successfulFreeze',
          displayName: 'Successful Deal Freeze',
          description: 'Always returns `frozen` status back'
        }
      ]
    },
    {
      endpoint: EndpointNames.DealFreezeUserLimits,
      method: RequestMethod.GET,
      page: Pages.AP,
      variations: [
        {
          value: 'successfulFreeze',
          displayName: 'Successful Deal Freeze',
          description:
            'Returns the user remaining DF bookings. 3 bookings by default or the amount specified in the `userLimits` URL param'
        }
      ]
    },
    {
      endpoint: EndpointNames.Bookings,
      method: RequestMethod.GET,
      page: Pages.AP,
      variations: [
        {
          value: 'successfulFreeze',
          displayName: 'Successful Deal Freeze',
          description: 'Return an empty value to avoid throwing an error.'
        }
      ]
    }
  ]
}
