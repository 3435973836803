import {defineMessages, MessageDescriptor} from 'react-intl'

enum Cities {
  lasVegas = 'lasVegas',
  newYork = 'newYork',
  cancun = 'cancun',
  niagaraFalls = 'niagaraFalls',
  tokyo = 'tokyo',
  dubai = 'dubai',
  london = 'london',
  istanbul = 'istanbul',
  paris = 'paris',
  singapore = 'singapore',
  nearMe = 'nearMe'
}

export interface City {
  name: MessageDescriptor
  placeId: string
}

export interface TranslatedCity {
  name: string
  placeId: string
}

const citiesNames = defineMessages({
  [Cities.nearMe]: {
    id: 'nearMe',
    defaultMessage: 'Near me'
  },
  [Cities.lasVegas]: {
    id: 'cities.lasVegas',
    defaultMessage: 'Las Vegas'
  },
  [Cities.newYork]: {
    id: 'cities.newYork',
    defaultMessage: 'New York City'
  },
  [Cities.cancun]: {
    id: 'cities.cancun',
    defaultMessage: 'Cancun'
  },
  [Cities.niagaraFalls]: {
    id: 'cities.niagaraFalls',
    defaultMessage: 'Niagara Falls'
  },
  [Cities.tokyo]: {
    id: 'cities.tokyo',
    defaultMessage: 'Tokyo'
  },
  [Cities.dubai]: {
    id: 'cities.dubai',
    defaultMessage: 'Dubai'
  },
  [Cities.london]: {
    id: 'cities.london',
    defaultMessage: 'London'
  },
  [Cities.istanbul]: {
    id: 'cities.istanbul',
    defaultMessage: 'Istanbul'
  },
  [Cities.paris]: {
    id: 'cities.paris',
    defaultMessage: 'Paris'
  },
  [Cities.singapore]: {
    id: 'cities.singapore',
    defaultMessage: 'Singapore'
  }
})

const placeIds = {
  [Cities.nearMe]: undefined,
  [Cities.lasVegas]: '48989',
  [Cities.newYork]: '49192',
  [Cities.cancun]: '59514',
  [Cities.niagaraFalls]: '31809',
  [Cities.tokyo]: '254251',
  [Cities.dubai]: '24399',
  [Cities.london]: '158584',
  [Cities.istanbul]: '47900',
  [Cities.paris]: '43958',
  [Cities.singapore]: '62938'
}

const buildCitiesData = (keys: Cities[]): City[] =>
  keys.reduce((acc, cur) => {
    acc.push({name: citiesNames[cur], placeId: placeIds[cur]})
    return acc
  }, [])

export const getCitiesData = (country: string): City[] => {
  if (['US', 'CA'].includes(country))
    return buildCitiesData([
      Cities.nearMe,
      Cities.lasVegas,
      Cities.newYork,
      Cities.cancun,
      Cities.niagaraFalls,
      Cities.tokyo
    ])

  return buildCitiesData([
    Cities.nearMe,
    Cities.dubai,
    Cities.london,
    Cities.istanbul,
    Cities.paris,
    Cities.singapore
  ])
}
