import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import Settings from 'Settings'

import {BusinessUnit, ReviewsResponse} from './trustPilot.types'

const TRUSTPILOT_API_URL = Settings.get('TRUSTPILOT_API_URL')
const BUSINESS_UNIT_ID = '634e76254b89780068df60cd'

export const trustPilotApi = createApi({
  reducerPath: 'trustPilotApi',
  baseQuery: fetchBaseQuery({baseUrl: TRUSTPILOT_API_URL}),
  endpoints: builder => ({
    getBusinessUnit: builder.query<BusinessUnit, void>({
      query: () => `business-units/${BUSINESS_UNIT_ID}`
    }),
    getReviews: builder.query<
      ReviewsResponse,
      {
        stars: number
        language: string
        perPage?: number
        page?: number
        orderBy?: string
      }
    >({
      query: ({
        stars,
        language,
        perPage,
        page,
        orderBy = 'createdat.desc'
      }) => ({
        url: `business-units/${BUSINESS_UNIT_ID}/reviews`,
        params: {stars, language, perPage, page, orderBy}
      })
    })
  })
})

export const {useGetBusinessUnitQuery, useGetReviewsQuery} = trustPilotApi
