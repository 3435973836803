import {useCallback, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistoryBack} from 'hooks/useHistoryBack'
import {getOverlay, getOverlayVisibility} from 'modules/overlay/selectors'
import {OverlayType} from 'modules/overlay/types'
import {
  getPopupOnExitShownState,
  getPopupOnExitVisibility,
  hidePopupOnExit,
  showPopupOnExit
} from 'modules/popupOnExit/slice'
import {getIsUserLandingDirectlyOnSrp} from 'modules/search/selectors'
import {toggle} from 'opticks'
import {setPopupOnExitShown} from 'utils/persistence'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {isPasswordlessLoginVisible} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {useExitIntentDetection} from './useExitIntentDetection'

export type ExitPopupPage = `${Page.Search}` | `${Page.Accommodation}`

const isReactNative = isReactNativeWebView()

/**
 * Hook that manages the exit popup behavior for SRP and AP
 * It handles showing/hiding the popup based on user exit intent and various conditions.
 *
 * @param page - The page where the exit popup is being used ('Search' or 'Accommodation')
 */

export const useExitPopup = ({
  page,
  isEnabled
}: {
  page: ExitPopupPage
  isEnabled: boolean
}) => {
  const dispatch = useDispatch()
  const isPopupOnExitShown = useSelector(getPopupOnExitShownState)
  const isPasswordlessPopupVisible = useSelector(isPasswordlessLoginVisible)
  const isOpen = useSelector(getPopupOnExitVisibility)
  const overlayState = useSelector(getOverlay)
  const dispatchTeamEvent = useDispatchTeamEvent()
  const isPriceWatchOverlayShown = useSelector(state =>
    getOverlayVisibility(state, OverlayType.PriceWatchOverlay)
  )
  const isDirectSrpUser = useSelector(getIsUserLandingDirectlyOnSrp)
  const {isMobile} = useDeviceLayout()

  const isExitPopupEnabled = useMemo(() => {
    switch (page) {
      case Page.Search:
        if (isMobile) {
          return (
            isDirectSrpUser &&
            !isPopupOnExitShown &&
            !isReactNative &&
            !overlayState.length &&
            !isPriceWatchOverlayShown &&
            isEnabled
          )
        }
        return (
          isEnabled &&
          !isPopupOnExitShown &&
          !isPasswordlessPopupVisible &&
          !overlayState?.length &&
          isEnabled
        )
      case Page.Accommodation:
        return (
          toggle('16b5e700-exit-popup-desktop-ap', false, true) &&
          !overlayState.length &&
          !isPopupOnExitShown &&
          isEnabled
        )
      default:
        return false
    }
  }, [
    isDirectSrpUser,
    isMobile,
    isPasswordlessPopupVisible,
    isPopupOnExitShown,
    isPriceWatchOverlayShown,
    overlayState.length,
    page,
    isEnabled
  ])

  const openPopup = useCallback(() => {
    dispatch(showPopupOnExit())
    dispatchTeamEvent(
      trackEvent({
        category: Category.System,
        entity: Entity.PopupOnExit,
        action: Action.Displayed
      })
    )
    setPopupOnExitShown(page)
  }, [dispatch, dispatchTeamEvent, page])

  const closePopup = useCallback(() => {
    dispatch(hidePopupOnExit())
  }, [dispatch])

  useExitIntentDetection({
    isEnabled: isExitPopupEnabled,
    onExitIntent: openPopup
  })

  // on mobile, we want to open the popup when the user clicks the back button
  // this is currently only enabled on SRP
  useHistoryBack(isExitPopupEnabled, () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.BrowserBackButton,
        action: Action.Clicked
      })
    )
    openPopup()
  })

  useEffect(() => {
    isPriceWatchOverlayShown && closePopup()
  }, [isPriceWatchOverlayShown, closePopup])

  return {openPopup, closePopup, isOpen}
}
