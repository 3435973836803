import {defineMessages} from 'react-intl'

export const filtersConfig = [
  {
    id: 'freeCancellation',
    icon: 'CalendarCheck4'
  },
  {
    id: 'breakfastIncluded',
    icon: 'Bowl'
  },
  {
    id: 'payLater',
    icon: 'CreditCard'
  },
  {
    id: 'priceSort',
    icon: 'SortDescending'
  }
] as const

export type FilterKey = (typeof filtersConfig)[number]['id']

export enum FilterSource {
  QuickFilters = 'quickFilters',
  MoreDealsQuickFilters = 'moreDealsQuickFilters',
  RemoveQuickFilters = 'removeQuickFilters',
  RemoveMoreDealsQuickFilters = 'removeMoreDealsQuickFilters'
}

export const messages = defineMessages({
  freeCancellation: {
    id: 'filters.freeCancellation',
    defaultMessage: 'Free cancellation'
  },
  breakfastIncluded: {
    id: 'mealType.breakfastIncluded',
    defaultMessage: 'Breakfast included'
  },
  payLater: {id: 'filters.payLater', defaultMessage: 'Pay Later'},
  priceSort: {
    id: 'filters.sortDescending',
    defaultMessage: 'Lowest price'
  }
})
