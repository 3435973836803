import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchBoxDestinationPicker} from 'hooks/searchBox'
import {useNavigateToSearchWithTrigger} from 'hooks/useNavigateToSearch'
import {useSetDeviceGeolocationSearchParams} from 'hooks/useSetDeviceGeolocationSearchParams'
import {getSearchContext} from 'middleware/analytics/selectors'
import {getUserCity, getUserCountryCode} from 'modules/meta/selectors'
import {useSapi} from 'modules/sapiSearch/SapiProvider'
import {
  LocationSearchType,
  setUserLocationSearchType
} from 'modules/search/slice'
import {SearchTrigger} from 'modules/searchBox/types'

import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {TeamContextType} from '@daedalus/core/src/analytics/components/TeamContext'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {today, tomorrow} from '@daedalus/core/src/utils/date'

import {getCitiesData, TranslatedCity} from '../utils'

type UseLocationTopDeals = {
  component: string
  team: TeamContextType['team']
}

export const useLocationTopDeals = (trackingPayload: UseLocationTopDeals) => {
  const [citiesList, setCitiesList] = useState<TranslatedCity[]>([])
  const [selectedCity, setSelectedCity] = useState<TranslatedCity>()
  const [isCitiesGroupActive, setIsCitiesGroupActive] = useState(true)
  const [isFetchingGeo, setIsFetchingGeo] = useState(false)
  const rawUserCity = useSelector(getUserCity)
  const currentUserCity = rawUserCity ? decodeURI(rawUserCity) : rawUserCity
  const userCountryCode = useSelector(getUserCountryCode)
  const {onNearbyPropertiesSuggestionSelected} = useSearchBoxDestinationPicker()
  const {setDeviceGeolocationSearchParams} =
    useSetDeviceGeolocationSearchParams()
  const {sapiClient} = useSapi()
  const {formatMessage} = useIntl()
  const isReactNative = isReactNativeWebView()
  const [alreadyTrackedCarousel, setAlreadyTrackedCarousel] = useState(false)
  const dispatch = useDispatch()
  const searchContext = useSelector(getSearchContext)

  const checkIn = today()
  const checkOut = tomorrow()
  const rooms = '2'

  const baseSearchParameters = {
    rooms,
    checkIn,
    checkOut,
    userSearch: '1' as const,
    homeSearch: '1' as const
  }
  const navigateToSearch = useNavigateToSearchWithTrigger(
    SearchTrigger.TopDealsTonightWidget
  )

  const getCurrentCityPlaceId = useCallback(
    async city => {
      try {
        const suggestions = await sapiClient.suggest(city, 1)
        return suggestions[0].objectID
      } catch (error) {
        console.error(error)
      }
    },
    [sapiClient]
  )

  useEffect(() => {
    if (sapiClient) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async () => {
        const preparedList: TranslatedCity[] = getCitiesData(userCountryCode)
          .map(city => ({
            name: formatMessage(city.name),
            placeId: city.placeId
          }))
          .filter(city => {
            // Exclude current user city if it matches to one in the list
            return city.name !== currentUserCity
          })

        if (currentUserCity) {
          const placeId = await getCurrentCityPlaceId(currentUserCity)
          const currentCityData = {
            name: currentUserCity,
            placeId
          }
          preparedList.unshift(currentCityData)
        }

        setCitiesList(preparedList)
      })()
    }
  }, [currentUserCity, sapiClient])

  useEffect(() => {
    if (citiesList.length) {
      setSelectedCity(citiesList[0])
    }
  }, [citiesList])

  const {ref, inView} = useInView({triggerOnce: true, threshold: 0.5})

  useEffect(() => {
    if (inView && !alreadyTrackedCarousel) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Carousel,
          action: Action.Displayed,
          ...trackingPayload,
          payload: {
            searchItem: {
              checkIn,
              checkOut,
              rooms
            }
          },
          analyticsContext: {
            [AnalyticsContext.SearchContext]: searchContext
          }
        })
      )
      setAlreadyTrackedCarousel(true)
    }
  }, [
    inView,
    searchContext,
    dispatch,
    alreadyTrackedCarousel,
    setAlreadyTrackedCarousel
  ])

  const togglePillsActivity = state => () => setIsCitiesGroupActive(state)

  const handleChangeLocation = async (index: number) => {
    if (citiesList[index].placeId === undefined) {
      setIsFetchingGeo(true)

      if (isReactNative) {
        dispatch(setUserLocationSearchType(LocationSearchType.IpLocation))
      } else {
        await setDeviceGeolocationSearchParams()
      }
      setSelectedCity(citiesList.find(city => city.placeId === undefined))
      setIsFetchingGeo(false)
    } else {
      setSelectedCity(citiesList[index])
    }
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.Carousel,
        action: Action.Clicked,
        ...trackingPayload,
        payload: {
          index,
          name: citiesList[index].name,
          placeId: citiesList[index].placeId,
          checkIn,
          checkOut
        }
      })
    )
  }

  const onHotelClick = ({hotelId}: {hotelId: string}) =>
    navigateToSearch({...baseSearchParameters, hotelId})

  const onSeeAllClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.CarouselSeeAll,
        action: Action.Clicked,
        ...trackingPayload,
        payload: {
          searchItem: {
            placeId: selectedCity.placeId,
            checkIn,
            checkOut,
            rooms: '2'
          }
        }
      })
    )
    if (selectedCity.placeId === undefined) {
      onNearbyPropertiesSuggestionSelected(true)
    } else {
      navigateToSearch({...baseSearchParameters, placeId: selectedCity.placeId})
    }
  }

  return {
    handleChangeLocation,
    onHotelClick,
    onSeeAllClick,
    citiesList,
    selectedCity,
    checkIn,
    checkOut,
    isFetchingGeo,
    isCitiesGroupActive,
    togglePillsActivity,
    ref
  }
}
