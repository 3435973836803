import {useSelector} from 'react-redux'
import {
  getLengthOfStay,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {getSelectedAvailabilityDate} from 'modules/search/selectors'
import {dateToMiddayString} from 'utils'

import {getCheckOutDate} from '@daedalus/core/src/searchParams/validateSearchParamDates'

export const useOfferCheckInCheckoutDates = ({sourceComponent}) => {
  const sourceComponents = [
    'MobileHotelViewAvailabilityPane',
    'MobileHotelViewAvailabilityCalendar'
  ]
  const {checkIn, checkOut} = useSelector(getSearchParameters) || {}
  const selectedAvailabilityDate = useSelector(getSelectedAvailabilityDate)
  const nights = useSelector(getLengthOfStay)

  if (
    selectedAvailabilityDate &&
    sourceComponent &&
    sourceComponents.includes(sourceComponent)
  )
    return {
      offerCheckIn: selectedAvailabilityDate,
      offerCheckOut: dateToMiddayString(
        getCheckOutDate(selectedAvailabilityDate, nights)
      )
    }
  return {offerCheckIn: checkIn, offerCheckOut: checkOut}
}
