import {
  autoBatchEnhancer,
  configureStore,
  EnhancedStore,
  Middleware,
  ThunkAction
} from '@reduxjs/toolkit'
import accommodation from 'modules/accommodation/slice'
import analytics from 'modules/analytics/slice'
import {appRatingsApi} from 'modules/appRatings'
import cookies from 'modules/cookies/slice'
import meta, {augmentMetaState} from 'modules/meta'
import {
  initialState as initialOverlayState,
  StateType as LocationStateType
} from 'modules/overlay/slice'
import overlay from 'modules/overlay/slice'
import popupOnExit from 'modules/popupOnExit/slice'
import progressBar from 'modules/progressBar/slice'
import sapiSearch from 'modules/sapiSearch/slice'
import {augmentSearchState} from 'modules/search'
import search from 'modules/search/slice'
import searchBox from 'modules/searchBox/slice'
import {trustPilotApi} from 'modules/trustPilot'
import {AnyAction, combineReducers} from 'redux'
import {history} from 'utils'

import {aclApi} from '@daedalus/core/src/acl/services/index'
import authentication from '@daedalus/core/src/auth/modules/slice'
import {bookApi} from '@daedalus/core/src/booking/services/bookApi'
import experiments from '@daedalus/core/src/experiments/modules/slice'
import {offerConfigurationApi} from '@daedalus/core/src/offer/services/offerConfigurationApi'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'
import colorPriceCalendar from '@daedalus/core/src/search/modules/ColorPriceCalendar/slice'
import oneTimePassword from '@daedalus/shared/src/authentication/OneTimePassword/slice'
import dealFreeze from '@daedalus/shared/src/dealFreeze/modules/slice'
import {dealFreezeApi} from '@daedalus/shared/src/dealFreeze/services/dealFreezeApi'
import priceWatch from '@daedalus/shared/src/priceWatch/slice'
import gemsBottomSheet from '@daedalus/shared/src/privateDeal/gems/modules/slice'

import appMiddlewareList from '../middleware'

export const middlewareList: Middleware[] = [
  searchApi.middleware,
  dealFreezeApi.middleware,
  bookApi.middleware,
  aclApi.middleware,
  offerConfigurationApi.middleware,
  trustPilotApi.middleware,
  appRatingsApi.middleware,
  ...appMiddlewareList
]

export const rootReducer = combineReducers({
  meta,
  overlay,
  searchBox,
  search,
  progressBar,
  popupOnExit,
  cookies,
  authentication,
  dealFreeze,
  experiments,
  sapiSearch,
  analytics,
  accommodation,
  gemsBottomSheet,
  oneTimePassword,
  priceWatch,
  [searchApi.reducerPath]: searchApi.reducer,
  [aclApi.reducerPath]: aclApi.reducer,
  [dealFreezeApi.reducerPath]: dealFreezeApi.reducer,
  [bookApi.reducerPath]: bookApi.reducer,
  [offerConfigurationApi.reducerPath]: offerConfigurationApi.reducer,
  [trustPilotApi.reducerPath]: trustPilotApi.reducer,
  [appRatingsApi.reducerPath]: appRatingsApi.reducer,
  colorPriceCalendar
})

export type RootState = ReturnType<typeof rootReducer>

export type Store = EnhancedStore<RootState, AnyAction, typeof middlewareList>

/**
 * Initialize a store with optional initialState
 */
export const initializeStore = (initialState: Partial<RootState> = {}): Store =>
  configureStore({
    reducer: rootReducer,
    preloadedState: {
      ...initialState,
      meta: augmentMetaState(initialState),
      search: augmentSearchState(initialState),
      overlay:
        (history.location.state as LocationStateType)?.overlay ||
        initialOverlayState
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(...middlewareList),
    enhancers: existingEnhancers => {
      // Add the autobatch enhancer to the store setup
      return existingEnhancers.concat(autoBatchEnhancer())
    }
  })

/**
 * The default App store
 */
export const store = initializeStore()

/**
 * Use this for thunks (eg. async actions)
 * ReturnType is optional
 */
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
