import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {getCookie} from '../../_web/utils/cookies'
import {
  experimentVariationsStore,
  useOpticks
} from '../../experiments/components/OpticksProvider'
import {getDataDogRumInstance} from '../components/DataDogClient'
import {trackExperimentServed} from '../modules/actions'

const EXPERIMENT_ID = 'aa-server-side-experiment'
const SOURCE_COOKIE_ID = 'X-Source'

/** Informs us which experiment is active (or why none) for Search */
const SOURCE_REASON_COOKIE = 'X-Source-Reason'

/** Reasons for the experiment decision to bail out for Search */
const BAIL_REASONS = [
  'unknown', // optimizely didn't load
  'none', // no experiment with prefix
  'multiple', // multiple experiments with the prefix
  'failed', // experiment decision failed for some reason
  'forced' // side was forced, all logic was skipped
]

export enum ExperimentPackageName {
  Search = 'search',
  Checkout = 'checkout',
  BookingManagement = 'booking-management'
}

/**
 * Extract the package configuration from the cookie value.
 * @param cookieValue - a string with the format package:experimentSide
 * @param packageName - the package name to track
 * @returns object
 */
const extractPackageConfigurationFromCookieValue = (
  cookieValue: string | undefined,
  packageName: ExperimentPackageName
) => {
  //Separating the packages
  const packageValues = cookieValue?.split(',') || []

  return packageValues.reduce((previousValue, currentValue) => {
    //Separating each parameter of the package
    const packageParameters = currentValue.split(':')
    const packageId = packageParameters[0]?.trim()
    if (packageName === packageId) {
      return packageParameters[1]
    }
    return previousValue
  }, '')
}

/**
 * This hook will track the experiments running on the infrastructure layer.
 * See the lambda's for details, but in short:
 * - Search - using Optimizely
 * - Checkout - not implemented
 * - BookingManagement - using our decision logic
 * @param packageName - the package name to track
 * @returns null
 */
export const useTrackServerABTestSource = (
  packageName: ExperimentPackageName
) => {
  const dispatch = useDispatch()
  const Opticks = useOpticks()

  const xSource = getCookie(SOURCE_COOKIE_ID)

  const experimentSide = extractPackageConfigurationFromCookieValue(
    xSource,
    packageName
  )

  useEffect(() => {
    const isSearch = packageName === ExperimentPackageName.Search
    const reason = getCookie(SOURCE_REASON_COOKIE)

    // No decision was made by Optimizely in the Search lambda
    if (isSearch && reason && BAIL_REASONS.includes(reason)) return

    // The search lambda dynamically grabs the experiment with prefix `platform-search-source-` from Optimizely
    const experimentId = isSearch ? reason : EXPERIMENT_ID
    const isActiveExperimentSide = experimentSide === 'experiment'

    if (Opticks && experimentId && experimentSide) {
      dispatch(
        trackExperimentServed(experimentId, isActiveExperimentSide ? 'b' : 'a')
      )

      getDataDogRumInstance().addFeatureFlagEvaluation(
        experimentId,
        isActiveExperimentSide
      )

      experimentVariationsStore.add(
        experimentId,
        isActiveExperimentSide ? 'b' : 'a'
      )
    }
  }, [Opticks, dispatch, experimentSide, packageName])

  return null
}
