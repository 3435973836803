import {type ReactNode, useContext, useMemo, useState} from 'react'
import {createContext} from 'react'

interface StreetviewContextValue {
  selectedHotelId: string
  setSelectedHotelId: (hotelId: string) => void
}

// @ts-expect-error - we're initializing it before use
const StreetViewContext = createContext<StreetviewContextValue>()

export const useStreetViewContext = () => useContext(StreetViewContext)

interface Props {
  children: ReactNode
  initialHotelId: string
}

export const StreetviewProvider = ({children, initialHotelId}: Props) => {
  const [selectedHotelId, setSelectedHotelId] = useState(initialHotelId)
  const value = useMemo(
    () => ({selectedHotelId, setSelectedHotelId}),
    [selectedHotelId, setSelectedHotelId]
  )
  return (
    <StreetViewContext.Provider value={value}>
      {children}
    </StreetViewContext.Provider>
  )
}
