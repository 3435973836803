import {getAllVariationsFromMetadata} from '@vio/debug-panel/src/utils/scenarioSimulator'

import {metadata as alternativeAvailabilityMetadata} from './AlternativeAvailability'
import {metadata as bookingsMetadata} from './Bookings'
import {metadata as dealFreezeOffersMetadata} from './DealFreezeOffers'
import {metadata as hotelMetadata} from './Hotel'
import {metadata as offersMetadata} from './Offers'
import {metadata as roomsMetadata} from './Rooms'
import {metadata as searchMetadata} from './Search'

export const scenariosList = [
  {
    ...hotelMetadata,
    variations: getAllVariationsFromMetadata(hotelMetadata)
  },
  {
    ...offersMetadata,
    variations: getAllVariationsFromMetadata(offersMetadata)
  },
  {
    ...alternativeAvailabilityMetadata,
    variations: getAllVariationsFromMetadata(alternativeAvailabilityMetadata)
  },
  {
    ...searchMetadata,
    variations: getAllVariationsFromMetadata(searchMetadata)
  },
  {
    ...bookingsMetadata,
    variations: getAllVariationsFromMetadata(bookingsMetadata)
  },
  {
    ...roomsMetadata,
    variations: getAllVariationsFromMetadata(roomsMetadata)
  },
  {
    ...dealFreezeOffersMetadata,
    variations: getAllVariationsFromMetadata(dealFreezeOffersMetadata)
  }
]
