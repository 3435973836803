import {
  ContentHotel,
  Hotel,
  SearchParameters
} from '@findhotel/sapi/dist/types/packages/core/src/types'

import {
  AnalyticsContext,
  HotelContext,
  SearchContext
} from '../analytics/types/Events'
import {AuthenticationMethod} from '../auth/types/Auth'
import {UserSearchHistoryItem} from '../searchHistory'
import {RNWebViewMessageTypes} from './types'

import type {User, UserAttributes} from '../auth/types/Cognito'

type RNMessagableScreen =
  | 'Home'
  | 'ManageNotifications'
  | 'PrivacyPolicy'
  | 'Debug'

export type UserAuthenticatedPayload =
  | {
      user: User | undefined
    }
  | undefined

type PriceWatchBottomSheetContent = {
  hotel: ContentHotel | Hotel
  priceComposition: {
    nightlyRate: number
    totalRate: number
    anchorPrice: number
    anchorPriceTotal: number
    shouldShowTotal: boolean
  }
  checkIn: string
  checkOut: string
  analyticsContext: {
    [AnalyticsContext.HotelContext]: HotelContext
    [AnalyticsContext.SearchContext]: SearchContext
  }
}

type UserActionPayload = {
  action: 'Scroll' | 'Click'
  entity: 'SearchResults' | 'AccommodationPageBackButton'
  context: PriceWatchBottomSheetContent
}

export type Message = {
  type: RNWebViewMessageTypes
  payload?:
    | {screen: RNMessagableScreen}
    | {currency: string}
    | {visibility: boolean}
    | {provider: AuthenticationMethod}
    | {userId: UserAttributes['sub']}
    | UserAuthenticatedPayload
    | {url: string}
    | {userSearchHistory: UserSearchHistoryItem[]}
    // TODO: remove this type once we move to USER_ACTION
    | PriceWatchBottomSheetContent
    | SearchParameters
    | UserActionPayload
    | {hotelId?: string}
}

/**
 * Uses the `ReactNativeWebView` on the `window` to post a message to Vio.com mobile app.
 */
const postMessageToWebView = ({type, payload}: Message): void => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: type,
      payload
    })
  )
}

export {postMessageToWebView}
