import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {AvailabilitySearchParameters} from '@findhotel/sapi'

import {
  AvailabilityEntity,
  AvailabilityHotelEntity
} from '../../../availability/types'

export enum ColorPriceCalendarRequestStatus {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export interface ColorPriceCalendarResult {
  availabilityPrices: AvailabilityHotelEntity | Record<string, never>
  status: ColorPriceCalendarRequestStatus
  error: Error | null
}

export type ColorPriceCalendarState = Record<string, ColorPriceCalendarResult>

interface Payload {
  parameters: AvailabilitySearchParameters
  mappedResponse: AvailabilityEntity
}

const initialState: ColorPriceCalendarState = {}

const getMonthIndex = (parameters: AvailabilitySearchParameters) =>
  parameters?.startDate?.substring(0, 7)

/**
 * The slice that contains information regarding the hotel availability prices for the ColorPriceCalendar component.
 * Also contains the completion status and error of the request.
 * @see ColorPriceCalendarState
 */
const {actions, reducer} = createSlice({
  name: 'colorPriceCalendar',
  initialState,
  reducers: {
    colorPriceCalendarAvailabilityRequested: (
      state,
      {payload}: PayloadAction<{parameters: AvailabilitySearchParameters}>
    ) => {
      const month = getMonthIndex(payload.parameters)

      if (month) {
        state[month] = {
          availabilityPrices: state[month]?.availabilityPrices || {},
          status: ColorPriceCalendarRequestStatus.PENDING,
          error: null
        }
      }
    },
    colorPriceCalendarAvailabilityReceived: (
      state,
      {payload}: PayloadAction<Payload>
    ) => {
      const month = getMonthIndex(payload.parameters)

      if (month) {
        state[month] = {
          ...state[month],
          availabilityPrices: Object.values(payload?.mappedResponse)[0] || {},
          status: ColorPriceCalendarRequestStatus.PENDING
        }
      }
    },
    colorPriceCalendarAvailabilityCompleted: (
      state,
      {payload}: PayloadAction<Payload>
    ) => {
      const month = getMonthIndex(payload.parameters)

      if (month) {
        state[month] = {
          ...state[month],
          availabilityPrices: Object.values(payload?.mappedResponse)[0] || {},
          status: ColorPriceCalendarRequestStatus.COMPLETED
        }
      }
    },
    setAvailabilityError: (state, {payload}) => {
      const month = getMonthIndex(payload.parameters)

      if (month) {
        state[month] = {
          availabilityPrices: {},
          status: ColorPriceCalendarRequestStatus.COMPLETED,
          error: payload.error
        }
      }
    },
    clearPriceColorCalendarAvailability: () => {
      return initialState
    }
  }
})

export const {
  colorPriceCalendarAvailabilityRequested,
  colorPriceCalendarAvailabilityReceived,
  colorPriceCalendarAvailabilityCompleted,
  setAvailabilityError,
  clearPriceColorCalendarAvailability
} = actions

export default reducer
