import TrackPageView from 'components/TrackPageView'
import {Alerts} from 'components/User/AlertsManagement'

import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {Page} from '@daedalus/core/src/analytics/types/Events'

export const AlertsRoute = () => (
  <>
    <TrackPageView pageName={Page.AlertManagement} />
    <InjectPageDataId page={Page.AlertManagement} />
    <Alerts />
  </>
)
