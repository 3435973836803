import {loadModule} from 'utils'

const Accommodation = loadModule(
  async () =>
    import(
      /* webpackChunkName: "accommodation" */
      './Accommodation'
    ),
  {}
)

export default Accommodation
