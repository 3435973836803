import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {getParamFromOfferBookURI} from './offers'
/*
 * Parses SAPI offer object and extract userIp param from redirect url.
 */
export const getUserIpFromOffer = (
  offer?: SearchOffer,
  fallbackIpAddress = ''
) => {
  if (!offer) return fallbackIpAddress

  try {
    const userIpParam = getParamFromOfferBookURI(offer.url, 'userIp')

    if (!userIpParam) return fallbackIpAddress
    return userIpParam
  } catch {
    return fallbackIpAddress
  }
}
