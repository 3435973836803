import debounce from 'lodash/debounce'

/**
 * Wraps the native `element.scrollTo` and returns a Promise that
 * resolves when the browser finishes the scroll animation.
 */
export const scrollTo = (
  // eslint-disable-next-line no-undef
  options?: ScrollToOptions
): Promise<void> => {
  window?.scrollTo(options)

  return new Promise(resolve => {
    /**
     * Using debounce to ensure the browser is done scrolling.
     * While it still scrolling, the browser will not stop calling the method
     * and debounce will prevent it from triggering until it stops.
     */
    const debouncedCallback = debounce(() => {
      window.removeEventListener('scroll', debouncedCallback)
      resolve()
    }, 50)

    window.addEventListener('scroll', debouncedCallback)

    // This is needed in case the browser does not scroll to ensure the Promise is resolved.
    debouncedCallback()
  })
}
