import objectHash from 'object-hash'
import {getUserSearchHistory, setUserSearchHistory} from 'utils/persistence'

import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {UserSearchHistoryItem} from '@daedalus/core/src/searchHistory'

const MAX_USER_SEARCH_HISTORY_COUNT = 10

const SAVE_USER_SEARCH = 'SAVE_USER_SEARCH'

export type ActionType = UserSearchHistoryItem & {
  type: typeof SAVE_USER_SEARCH
}

type SaveUserSearchHistoryType = ({
  searchParams,
  searchData,
  searchId
}: Omit<UserSearchHistoryItem, 'historyId'>) => ActionType

const saveUserSearchHistory: SaveUserSearchHistoryType = ({
  searchParams,
  searchData,
  searchId
}) => {
  let userSearchHistory = getUserSearchHistory(
    MAX_USER_SEARCH_HISTORY_COUNT,
    false
  )

  const {checkIn, checkOut, hotelId, placeId, rooms} = searchParams

  const historyId = objectHash({checkIn, checkOut, hotelId, placeId, rooms})

  userSearchHistory = userSearchHistory.filter(
    history => history.historyId !== historyId
  )

  userSearchHistory.unshift({
    historyId,
    searchParams,
    searchData,
    searchId
  })

  setUserSearchHistory(
    userSearchHistory.slice(0, MAX_USER_SEARCH_HISTORY_COUNT)
  )

  if (isReactNativeWebView()) {
    postMessageToWebView({
      type: RNWebViewMessageTypes.SET_USER_SEARCH_HISTORY,
      payload: {userSearchHistory}
    })
  }

  return {
    historyId,
    searchParams,
    searchData,
    searchId,
    type: SAVE_USER_SEARCH
  }
}

export default saveUserSearchHistory
