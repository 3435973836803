import {FilterKey} from 'components/Accommodation/OffersFilters/constants'
import {PolicySubSection} from 'components/Accommodation/Policies/types'
import {TabId} from 'components/HotelDetailsTabs'
import {Hotel} from 'modules/sapiSearch/slice'

import {Placement} from '@daedalus/core/src/deepLinking/types'
import {RoomsFilterKey} from '@daedalus/core/src/room/business/utils/filters'
import {Room} from '@daedalus/core/src/room/types/room'
import {PriceBreakdownModalProps} from '@daedalus/shared/src/booking/PriceBreakdownModal'
import {ContentHotel} from '@findhotel/sapi'

export enum OverlayType {
  HotelDetails = 'hotelDetails',
  // as part of sapi4eva-hotel-descriptions
  HotelDescription = 'hotelDescription',
  HotelFacilities = 'hotelFacilities',
  HotelPolicies = 'hotelPolicies',
  HotelDatePicker = 'hotelDatePicker',
  HotelGuestPicker = 'hotelGuestPicker',
  HotelAllOffers = 'hotelAllOffers',
  Filters = 'filters',
  PriceFilter = 'priceFilter',
  FacilitiesFilter = 'facilitiesFilter',
  SortBy = 'sortBy',
  SearchBox = 'searchBox',
  DestinationPicker = 'destinationPicker',
  DatePicker = 'datePicker',
  GuestPicker = 'guestPicker',
  Preferences = 'preferences',
  DeleteAccount = 'deleteAccount',
  SignIn = 'signIn',
  LightboxGalleryOverlay = 'lightboxGalleryOverlay',
  LocationOverlay = 'hotelLocationOverlay',
  EmailVerification = 'emailVerification',
  MembershipOverlay = 'membershipOverlay',
  OtherOverlay = 'otherOverlay',
  AccountOverlay = 'accountOverlay',
  ReferralOverlay = 'referralOverlay',
  VerticalGalleryOverlay = 'VerticalGalleryOverlay',
  SplitBookingDetails = 'SplitBookingDetails',
  SplitBookingInfoBottomSheet = 'SplitBookingInfoBottomSheet',
  RoomDetailsOverlay = 'RoomDetailsOverlay',
  RoomOfferPriceBreakdownOverlay = 'RoomOfferPriceBreakdownOverlay',
  ShareYourFeedbackOverlay = 'ShareYourFeedbackOverlay',
  ReviewScoreFilter = 'reviewScoreFilter',
  StarRatingsFilter = 'starRatingsFilter',
  AvailabilityPopup = 'availabilityPopup',
  PropertyTypeFilter = 'propertyTypeFilter',
  Web2AppPopup = 'web2AppPopup',
  GuestReviews = 'guestReviews',
  StreetView = 'streetView',
  PriceWatchOverlay = 'priceWatch',
  ReloadAP = 'reloadAP'
}

export enum DateGuestPickerSource {
  AccommodationPageSearch = 'apSearch',
  HotelViewRoomsSection = 'hotelViewRoomsSection'
}

export type HotelDetailsOverlayInfo = {
  type: OverlayType.HotelDetails
  hotelId: Hotel['objectID']
  activeTab?: TabId
  activeOffersFilters?: Array<FilterKey>
  initialImageIndex?: number
  refundableBookingsAlertVisibility?: boolean
  activeRoomsFilters?: Array<RoomsFilterKey>
}

export type HotelFacilitiesOverlayInfo = {
  type: OverlayType.HotelFacilities
  hotelId: Hotel['objectID']
}

export type PriceWatchOverlayInfo = {
  type: OverlayType.PriceWatchOverlay
  hotel: Hotel | ContentHotel
  rate: {
    baseRate: number
    localTaxes: number
    taxes: number
    nightlyRate: number
    totalRate: number
    localTaxesIncluded: boolean
    taxesIncluded: boolean
    currency: string
    shouldShowTotal: boolean
  }
  flow?: string
}

export type HotelPoliciesOverlayInfo = {
  type: OverlayType.HotelPolicies
  policySubSection: PolicySubSection
}

export type LightboxGalleryOverlayInfo = {
  type: OverlayType.LightboxGalleryOverlay
  hotelId: Hotel['objectID']
  initialImageIndex: number
  images: string[]
}

export type HotelAllOffersOverlayInfo = {
  type: OverlayType.HotelAllOffers
  hotelId: Hotel['objectID']
}

export type HotelDatePickerOverlayInfo = {
  type: OverlayType.HotelDatePicker
  source: DateGuestPickerSource
}

export type HotelGuestPickerOverlayInfo = {
  type: OverlayType.HotelGuestPicker
  source: DateGuestPickerSource
}

export type RoomDetailsOverlayInfo = {
  type: OverlayType.RoomDetailsOverlay
  roomId: Room['id']
  hotelId: Hotel['objectID']
}

export type Web2AppPopupOverlayInfo = {
  type: OverlayType.Web2AppPopup
  hotelId?: Hotel['objectID']
  placement: Placement
}

export type RoomOfferPriceBreakdownOverlayParams = Omit<
  PriceBreakdownModalProps,
  'isOpen' | 'onClose'
>
export type RoomOfferPriceBreakdownOverlayInfo = {
  type: OverlayType.RoomOfferPriceBreakdownOverlay
} & RoomOfferPriceBreakdownOverlayParams

export type GuestReviewsOverlayInfo = {
  type: OverlayType.GuestReviews
}

export type StreetViewOverlayInfo = {
  type: OverlayType.StreetView
  hotelId: string
}

/**
 * Union any overlays with parameters to allow for a type safe lookup
 * This is used for:
 * - overlay selectors to return correct parameters
 * - showOverlay, so enforce correct parameters are passed
 */
export type Overlay =
  | {
      type: OverlayType
    }
  | HotelDetailsOverlayInfo
  | HotelPoliciesOverlayInfo
  | HotelFacilitiesOverlayInfo
  | HotelAllOffersOverlayInfo
  | LightboxGalleryOverlayInfo
  | HotelDatePickerOverlayInfo
  | HotelGuestPickerOverlayInfo
  | RoomDetailsOverlayInfo
  | RoomOfferPriceBreakdownOverlayInfo
  | Web2AppPopupOverlayInfo
  | GuestReviewsOverlayInfo
  | PriceWatchOverlayInfo
  | StreetViewOverlayInfo
