import React from 'react'
import useHasGeolocationPermission from 'hooks/useHasGeolocationPermission'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {
  NearbyIconWrapper,
  NearbySuggestionButton,
  NearbySuggestionTextElement,
  NearbySuggestionTextWrapper,
  SuggestionElement
} from './styles'

interface Props {
  onClick: () => void
}

export const SuggestionNearby = ({onClick}: Props) => {
  const geolocationPermissionStatus = useHasGeolocationPermission()
  const isReactNative = isReactNativeWebView()

  return (
    <>
      <NearbySuggestionButton
        onClick={onClick}
        data-id="DestinationSuggestion--nearbySearch"
      >
        <SuggestionElement>
          <NearbyIconWrapper>
            <Icon
              name="Location"
              size="md"
              colorPath="content.interactive.c500"
            />
          </NearbyIconWrapper>
          <NearbySuggestionTextWrapper>
            <NearbySuggestionTextElement
              colorPath="content.interactive.c500"
              variant="labelS"
            >
              <FormattedMessageWrapper id="nearMe" defaultMessage="Near me" />
            </NearbySuggestionTextElement>
            {!geolocationPermissionStatus && !isReactNative && (
              <Text variant="bodyS" colorPath="content.neutral.c600">
                <FormattedMessageWrapper
                  id="searchBox.enableLocation"
                  defaultMessage="Enable location services"
                />
              </Text>
            )}
          </NearbySuggestionTextWrapper>
        </SuggestionElement>
      </NearbySuggestionButton>
    </>
  )
}
