import {useCallback, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useOfferTrackingContextWithFallback} from 'context/offerTracking'
import {useOfferCheckInCheckoutDates} from 'hooks/useOfferCheckInCheckoutDates'
import {
  getOfferContext,
  getParametrizedHotelContext
} from 'middleware/analytics/selectors'
import {
  getIsAnchorHotelViewed,
  getIsOfferViewed
} from 'modules/analytics/selectors'
import {setAnchorHotelViewed, setOfferViewed} from 'modules/analytics/slice'
import {
  getAnonymousId,
  getCurrencyCode,
  getLanguageCode,
  getLocalTaxesIncluded,
  getTaxesIncluded,
  getUserCountryCode
} from 'modules/meta/selectors'
import {
  getAppLockedDealConfig,
  getHotel,
  getHotelCity,
  getHotelOfferEntity,
  getIsAnchorHotel,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {SearchOffer} from 'modules/sapiSearch/slice'

import {SIZES} from '@daedalus/atlas/Image'
import {imageProvider} from '@daedalus/atlas/testutils/imageProvider'
import {persistAppLockedDealImpressionsCount} from '@daedalus/core/src/_web/appLockedDeal/impressionsCount'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/components/TeamContext'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page
} from '@daedalus/core/src/analytics/types/Events'
import {
  getIsLockedDeal,
  getUserId,
  selectIsLoading,
  selectShouldSeeOffersUnlocked
} from '@daedalus/core/src/auth/modules/selectors'
import isBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {getEpochSeconds} from '@daedalus/core/src/utils/date'

type InViewRef = (node?: Element) => void

const VIEW_THRESHOLD = 0.5
const VISIBILITY_CHECK_INTERVAL = 1000

/**
 * Determines whether the specified element is visible (not covered by another element).
 * WARNING: This function is not performant, so use it sparingly.
 */
const isElementVisible = (element?: Element): boolean => {
  if (!element) return false

  const {top, left, height, width} = element.getBoundingClientRect()
  const middlePoint = [left + width / 2, top + height / 2] as const

  const elementAtPoint = document.elementFromPoint(...middlePoint)

  return element.contains(elementAtPoint)
}

export const useTrackOfferView = (
  offer: SearchOffer,
  hotelId: string,
  component: string,
  bannerId?: string
): InViewRef => {
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()
  const offerRef = useRef<Element>(null)
  const offerIsViewed = useSelector(state => getIsOfferViewed(state, offer?.id))

  const {ref: inViewRef, inView} = useInView({
    skip: offerIsViewed,
    threshold: VIEW_THRESHOLD
  })

  const setCombinedRefs = useCallback(
    (node: Element): void => {
      offerRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const hotel = useSelector(state => getHotel(state, hotelId))
  const hotelCity = useSelector(state => getHotelCity(state, hotelId))

  // Allow context to overwrite default selectors
  const {offerEntity, offerContext: originalOfferContext} =
    useOfferTrackingContextWithFallback({
      offer,
      offerEntity: useSelector(state => getHotelOfferEntity(state, hotelId)),
      offerContext: useSelector(state =>
        getOfferContext(state, hotelId, offer?.id, component)
      )
    })

  const {offerCheckIn, offerCheckOut} = useOfferCheckInCheckoutDates({
    sourceComponent: component
  })

  const offerContext = {
    ...originalOfferContext,
    checkIn: offerCheckIn,
    checkOut: offerCheckOut
  }

  const isAnchorHotel = useSelector(state => getIsAnchorHotel(state, hotelId))

  const anchorHotelIsViewed = useSelector(state =>
    getIsAnchorHotelViewed(state, hotelId)
  )

  const shouldSeeOffersUnlocked = useSelector(selectShouldSeeOffersUnlocked)
  const isLoading = useSelector(selectIsLoading)
  const isOfferLocked = useSelector(getIsLockedDeal(offer))

  const analyticsContext = {
    [AnalyticsContext.HotelContext]: getParametrizedHotelContext(hotelId),
    [AnalyticsContext.OfferContext]: offerContext
  }

  const {isCheapest} = offerContext
  const anonymousId = useSelector(getAnonymousId)
  const {brand} = useBrand()
  const cio = customerIo(brand)
  const userId = useSelector(getUserId)
  const userCountryCode = useSelector(getUserCountryCode)
  const sapiSearchParameters = useSelector(getSearchParameters)
  const localTaxesIncluded = useSelector(getLocalTaxesIncluded)
  const taxesIncluded = useSelector(getTaxesIncluded)
  const currencyCode = useSelector(getCurrencyCode)
  const languageCode = useSelector(getLanguageCode)

  const {isAppLockedDeal, appDownloadLinkId} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isAnchorAppLockedDeal = isAnchorHotel && isAppLockedDeal

  const impressionDayCount = persistAppLockedDealImpressionsCount(
    isAnchorAppLockedDeal
  )

  useEffect(() => {
    /* If auth is still loading, isOfferLocked will be true
     * even if the user is logged in.
     * We need to wait to fire the event until auth is done
     * loading
     */
    if (isLoading) {
      return
    }

    if (inView && !offerIsViewed) {
      const intervalId = setInterval(() => {
        const offerIsVisible = isElementVisible(offerRef.current)

        if (offerIsVisible) {
          if (isAppLockedDeal) {
            dispatchTeamEvent(
              trackEvent({
                category: Category.System,
                entity: Entity.AppExclusiveOffer,
                action: Action.Displayed,
                analyticsContext: {
                  [AnalyticsContext.HotelContext]:
                    getParametrizedHotelContext(hotelId),
                  [AnalyticsContext.OfferContext]: offerContext
                },
                payload: {
                  bannerId: bannerId || appDownloadLinkId,
                  impressionDayCount
                }
              })
            )
          }

          dispatchTeamEvent(
            trackEvent({
              category: Category.User,
              entity: Entity.Offer,
              action: Action.Viewed,
              component,
              analyticsContext,
              payload: {
                isOfferLocked,
                isLogoVisible: isBofhOffer(offer),
                isPriceVisible: true,
                // Temporary params to debug issue on mobile
                shouldSeeOffersUnlocked: shouldSeeOffersUnlocked
              }
            })
          )

          if (isAnchorHotel && isCheapest) {
            const {imageURIs, hotelName, starRating, displayAddress} =
              hotel || {}
            const hotelImageUrl = imageURIs?.[0]
              ? imageProvider(imageURIs[0], SIZES['large'])
              : undefined
            const {checkIn, checkOut, rooms} = sapiSearchParameters || {}
            const {totalPrice, rateBreakdown} = offerContext || {}
            const {roomName} = offer || {}

            cio.trackEvent({
              name: `${Category.User}_${Entity.CheapestAnchorOffer}_${Action.Viewed}`,
              pageName: Page.Search,
              userId,
              anonymousId,
              starRating,
              hotelAddress: displayAddress,
              hotelName,
              hotelCity,
              hotelImageUrl,
              isAnchorHotel,
              hotelId,
              checkIn: getEpochSeconds(checkIn),
              checkOut: getEpochSeconds(checkOut),
              rooms,
              roomName,
              currency: currencyCode,
              locale: languageCode,
              country: userCountryCode,
              rate: {
                ...rateBreakdown,
                totalRate: Math.round(totalPrice * 100) / 100,
                taxesIncluded,
                localTaxesIncluded
              }
            })
          }

          dispatch(setOfferViewed({offerId: offer.id}))

          if (isAnchorHotel && !anchorHotelIsViewed) {
            dispatch(
              setAnchorHotelViewed({
                hotelId,
                chainID: hotel.chainID,
                parentChainID: hotel.parentChainID
              })
            )
          }

          const duplicateOffers =
            offerEntity?.offers?.filter(({id}) => id === offer.id)?.length ?? 0
          if (duplicateOffers > 1) {
            dispatchTeamEvent(
              trackEvent({
                category: Category.System,
                entity: Entity.Offer,
                action: Action.Duplicated,
                analyticsContext
              })
            )
          }

          clearInterval(intervalId)
        }
      }, VISIBILITY_CHECK_INTERVAL)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [inView, isLoading, anchorHotelIsViewed, isAnchorHotel, offerIsViewed])

  return setCombinedRefs
}
