import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  BaseOfferContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {Source} from '@daedalus/core/src/api-types/bovio/response/booking'

import {RootState} from '../../../testutils/configureStore'
import {getIsOfferViewTracked} from '../../modules/selector'
import {
  DealFreezeAnalyticsContext,
  setOfferViewTracked
} from '../../modules/slice'
import {ComponentSource} from '../../types'

interface props {
  offerId: string
  analyticsContext: DealFreezeAnalyticsContext
  inView: boolean
  component: ComponentSource | string
}

export const useTrackOfferView = ({
  offerId,
  analyticsContext,
  inView,
  component
}: props) => {
  const dispatch = useDispatch()
  const isOfferViewTracked = useSelector(state =>
    getIsOfferViewTracked(state as RootState, offerId)
  )

  useEffect(() => {
    if (!inView || isOfferViewTracked) return

    dispatch(setOfferViewTracked({offerId}))
    const {offerContext, priceContext, leadContext, hotelContext} =
      analyticsContext
    const offerContextWithChargeType = {
      ...offerContext,
      chargeType: [Source.DealFreeze]
    }
    const availableAnalyticsContexts = {
      ...(offerContext && {
        [AnalyticsContext.OfferContext]:
          offerContextWithChargeType as BaseOfferContext
      }),
      ...(priceContext && {[AnalyticsContext.PriceContext]: priceContext}),
      ...(leadContext && {[AnalyticsContext.LeadContext]: leadContext}),
      ...(hotelContext && {[AnalyticsContext.HotelContext]: hotelContext})
    }

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DealFreezeButton,
        action: Action.Viewed,
        analyticsContext: {...availableAnalyticsContexts},
        team: Team.Select,
        component
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inView, isOfferViewTracked, offerId, analyticsContext])
}
