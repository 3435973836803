import {useSelector} from 'react-redux'
import {getDeviceId} from 'modules/meta/selectors'
import {toggle} from 'opticks'

import {getScreenshotCount} from '@daedalus/core/src/analytics/hooks/useScreenshotTracking'
import {getEmailDomain} from '@daedalus/core/src/auth/business/authentication'
import {
  selectUserEmail,
  selectUserId
} from '@daedalus/core/src/auth/modules/selectors'
import {SearchParameters} from '@daedalus/core/src/sapi/types'

export const useSearchParams = (searchParameters: SearchParameters) => {
  const userId = useSelector(selectUserId)
  const userEmail = useSelector(selectUserEmail)
  const emailDomain = getEmailDomain(userEmail)
  const screenshots = getScreenshotCount()
  const deviceId = useSelector(getDeviceId)

  return {
    ...searchParameters,
    userId,
    emailDomain,
    deviceId,
    screenshots,
    // Added as part of feature-static-srp flag
    skipOffers: toggle('feature-static-srp', false, true)
  }
}
