import {loadModule} from 'utils'

export const FiltersOverlayContentLoadable = loadModule(
  async () =>
    import(
      /* webpackChunkName: "FiltersOverlayContent" */
      './FiltersOverlayContent'
    ),
  {}
)
