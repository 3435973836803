import React from 'react'

import {Text} from '@daedalus/atlas/Text'
import {Hotel} from '@findhotel/sapi'

interface Props {
  hotelDetails: Hotel
}

/**
 * This component is a modified copy of the Location component in the shared package.
 * When cleaning up the experimental no offers card, we should move this component
 * to the shared package and update other usages
 */
export const HotelLocation = ({hotelDetails}: Props) => {
  return (
    <div
      id={`HotelCardMobileLocation-${hotelDetails.objectID}`}
      data-id="HotelCardLocation"
    >
      <Text variant="bodyXS" colorPath="border.neutral.c600">
        {hotelDetails.placeDisplayName}
      </Text>
    </div>
  )
}
