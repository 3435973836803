import {useSelector} from 'react-redux'
import {getAnonymousId} from 'modules/meta/selectors'
import {toggle} from 'opticks'

import {selectIsReturningCustomer} from '@daedalus/core/src/acl/services'
import {getUser} from '@daedalus/core/src/auth/modules/selectors'
import {
  OFFLINE_CUG_TYPE,
  PLUS_CUG_TAG,
  SENSITIVE_CUG_TYPE,
  SIGNED_IN_CUG_TYPE
} from '@daedalus/core/src/offer/business/privateDeals'
import {getIsPhoneNumberVerified} from '@daedalus/shared/src/authentication/OneTimePassword/slice/selectors'
import {Offer} from '@findhotel/sapi'

type Props = {
  offer: undefined | Offer
  isAppLockedDeal: boolean
}

// TEMPORARY ALLOW LIST
// See context here: https://viodotcom.slack.com/archives/CQ5HPT3L2/p1711468121949479
const ALLOW_LIST = ['f84fdcdd-a1c0-4838-856f', 'd220d593-b786-4c2b-b188']

const OTPProviderMapping = [
  {
    providerCode: 'AGD',
    requiredAccessTier: [SIGNED_IN_CUG_TYPE, OFFLINE_CUG_TYPE]
  },
  {
    providerCode: 'HBD',
    requiredAccessTier: [SENSITIVE_CUG_TYPE, OFFLINE_CUG_TYPE]
  },
  {
    providerCode: 'DDT',
    requiredAccessTier: [SENSITIVE_CUG_TYPE, PLUS_CUG_TAG]
  },
  {providerCode: 'WTM', requiredAccessTier: [OFFLINE_CUG_TYPE]},
  {providerCode: 'NTD', requiredAccessTier: [OFFLINE_CUG_TYPE]},
  {providerCode: 'FPH', requiredAccessTier: [OFFLINE_CUG_TYPE]},
  {providerCode: 'CTE', requiredAccessTier: [OFFLINE_CUG_TYPE]}
]

const isOTPRequiredForProvider = (
  providerCode: Offer['providerCode'],
  requiredAccessTier: Offer['accessTier'][],
  offer: Offer
) => {
  return (
    offer?.providerCode === providerCode &&
    requiredAccessTier.includes(offer?.accessTier)
  )
}

export const isSmsLockedOffer = (
  offer: Offer | undefined,
  isAppLockedDeal: boolean
) => {
  const isOTPRequired = OTPProviderMapping.some(
    ({providerCode, requiredAccessTier}) =>
      isOTPRequiredForProvider(providerCode, requiredAccessTier, offer)
  )

  // OneTimePasswordFlow should not override isAppLockedDeal
  return isOTPRequired && !isAppLockedDeal
}

export const useIsRequiredOneTimePasswordFlow = ({
  offer,
  isAppLockedDeal
}: Props) => {
  const user = useSelector(getUser)
  const anonymousId = useSelector(getAnonymousId)
  const isReturningCustomer = useSelector(state =>
    selectIsReturningCustomer(state, anonymousId)
  )
  const isPhoneVerified = useSelector(getIsPhoneNumberVerified)

  const isOneTimePasswordFlowEnabled = toggle(
    'ea3355fc-feature-sms-flow',
    false,
    true
  )

  // If feature flag is disabled or user authenticate already verified the phone or is a returning customer, we don't need to require SMS flow
  if (
    !user ||
    !isOneTimePasswordFlowEnabled ||
    isPhoneVerified ||
    isReturningCustomer
  ) {
    return false
  }

  return (
    isSmsLockedOffer(offer, isAppLockedDeal) &&
    !ALLOW_LIST.find(partialId => user?.attributes?.sub?.includes(partialId))
  )
}
