export enum ColorScheme {
  light = 'light',
  dark = 'dark'
}
/**
 * How to detect the browser’s default theme
 *
 * @returns a boolean if the users browser’s mode is dark or not
 */
export const getIsBrowserDarkMode = (): boolean =>
  window?.matchMedia('(prefers-color-scheme: dark)').matches

/**
 * Get the preferred color scheme from the browser’s default theme
 *
 * @returns a string of the preferred color scheme
 */
export const getPreferredColorScheme = (): string => {
  if (getIsBrowserDarkMode()) {
    return ColorScheme.dark
  }
  return ColorScheme.light
}
