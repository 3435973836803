import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BottomCTA} from 'components/Accommodation/Offers/BottomCTA'
import SpinnerImportLoader from 'components/Loaders/SpinnerImportLoader'
import {RoundShareButton} from 'components/ShareButton'
import {ShareComponent, ShareType} from 'components/ShareButton/constants'
import {getLocationVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {getSearchBoxValues} from 'modules/searchBox/selectors'
import {loadModule} from 'utils'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {AccommodationFloatingHeader} from '@daedalus/shared/src/accommodation/AccommodationFloatingHeader'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src'

import {
  ExtendedMapContainer,
  MapOverlapBottomCTAPositioner,
  OverlayContent
} from '../styles'

import type {LocationMapRendererProps} from 'components/Accommodation/LocationOverlay/LocationMapRenderer/LocationMapRenderer'

type PropsType = {
  hotel: ContentHotel | undefined
}

const LocationMapRenderer = loadModule<LocationMapRendererProps>(
  async () =>
    import(
      /* webpackChunkName: "LocationMapRenderer" */
      'components/Accommodation/LocationOverlay/LocationMapRenderer/LocationMapRenderer'
    ),
  {loading: SpinnerImportLoader}
)

export const LocationOverlayMobile = ({hotel}: PropsType) => {
  const locationOverlayIsActive = useSelector(getLocationVisibility)

  const dispatch = useDispatch()
  const handleHideOverlay = () => dispatch(hideOverlay())
  const {checkIn, checkOut, rooms} = useSelector(getSearchBoxValues)
  const shareUrlParams = {
    hotelId: hotel?.objectID,
    checkIn,
    checkOut,
    rooms,
    component: ShareComponent.LocationOverlay
  }

  return (
    <Overlay
      enableBodyScrollLock
      isOpen={locationOverlayIsActive}
      shadeBackground={false}
      animationType={ANIMATION_TYPES.NONE}
    >
      {locationOverlayIsActive && hotel && (
        <OverlayContent>
          <ExtendedMapContainer>
            <LocationMapRenderer
              isMobile
              lat={hotel._geoloc.lat}
              lon={hotel._geoloc.lon}
            />
          </ExtendedMapContainer>
          <MapOverlapBottomCTAPositioner>
            <BottomCTA
              hotelId={hotel.objectID}
              sourceComponent="MobileHotelViewMapStaticOfferCTA"
            />
          </MapOverlapBottomCTAPositioner>
        </OverlayContent>
      )}
      <AccommodationFloatingHeader
        rightSlot={
          <RoundShareButton
            shareParams={shareUrlParams}
            component={ShareComponent.LocationOverlay}
            shareType={ShareType.ShareLink}
          />
        }
        leftSlot={
          <Button
            variant="secondary"
            isRounded
            isFloating
            iconStart={<Icon name="Close" />}
            dataId="LocationOverlayClose"
            onClick={handleHideOverlay}
            size="lg"
          />
        }
      />
    </Overlay>
  )
}
