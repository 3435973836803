import React, {useContext, useEffect} from 'react'
import {FormattedDate} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {cx} from '@linaria/core'
import {HighlightedTitle} from 'components/Home/HighlightedTitle'
import {useNavigateToSearchWithTrigger} from 'hooks/useNavigateToSearch'
import {getSearchContext} from 'middleware/analytics/selectors'
import {SearchTrigger} from 'modules/searchBox/types'
import {pick} from 'ramda'

import {Button} from '@daedalus/atlas/Button/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {roomsToString} from '@daedalus/core/src/room/business/roomConfiguration'
import {
  addDaysToDate,
  dateStringToMiddayDate
} from '@daedalus/core/src/utils/date'
import {GuestAndRoomText} from '@daedalus/shared/src/search/GuestAndRoomsText'

import {SimilarHotels} from '../components/SimilarHotels'
import {
  DateAndRoomsText,
  SeeAllText,
  TopSection,
  TopSectionText
} from '../components/TopSection'
import {CarouselWidgetsContext} from '../context/CarouselWidgetsContext'
import {DESTINATION_KEYS, ITINERARY_KEYS} from '../utils/constants'
import {getFirstBookingWithInterval} from './utils'

export const ExtendYourStay = () => {
  const {bookings, searchHistory} = useContext(CarouselWidgetsContext)
  const {isMobile} = useDeviceLayout()
  const dispatch = useDispatch()

  const navigateToSearch = useNavigateToSearchWithTrigger(
    SearchTrigger.ExtendYourStayWidget
  )

  const searchContext = useSelector(getSearchContext)

  const lastSearch = searchHistory?.[0]

  const bookingToExtend = getFirstBookingWithInterval(bookings)

  // Don't show Extend stay in case Recent searches anchor hotel is the same
  const shouldShowComponent =
    bookings?.length && bookingToExtend.hotelId !== lastSearch?.hotelId

  const trackingPayload = {
    component: 'StayLongerNearbyCarousel',
    team: Team.Retention
  }

  useEffect(() => {
    if (shouldShowComponent) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Carousel,
          action: Action.Displayed,
          ...trackingPayload,
          payload: {
            totalNumber: bookings?.length,
            searchItem: pick(
              [...DESTINATION_KEYS, ...ITINERARY_KEYS],
              bookingToExtend
            )
          },
          analyticsContext: {
            [AnalyticsContext.SearchContext]: searchContext
          }
        })
      )
    }
  }, [shouldShowComponent, searchContext, dispatch])

  if (!shouldShowComponent) {
    return null
  }
  const checkIn = bookingToExtend.checkOut
  const checkOut = addDaysToDate(checkIn, 1)
  const rooms = roomsToString(bookingToExtend.rooms)
  const baseSearchParameters = {
    rooms,
    checkIn,
    checkOut,
    userSearch: '1' as const,
    homeSearch: '1' as const
  }

  const onHotelClick = ({hotelId}: {hotelId: string}) =>
    navigateToSearch({...baseSearchParameters, hotelId})

  const onSeeAllClick = () => {
    const {hotelId} = bookingToExtend

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.CarouselSeeAll,
        action: Action.Clicked,
        ...trackingPayload,
        payload: {
          totalNumber: bookings?.length,
          searchItem: {
            hotelId,
            checkIn,
            checkOut,
            rooms
          }
        }
      })
    )

    navigateToSearch({...baseSearchParameters, hotelId})
  }

  return (
    <div className="chromatic-ignore">
      <TopSection
        className={cx(isMobile && '__mobile')}
        justifyContent="space-between"
        alignItems="center"
        gap="s200"
      >
        <TopSectionText>
          <Text variant="titleXL" dataId="ExtendYourStay">
            <HighlightedTitle source="ExtendYourStay" />
          </Text>

          <DateAndRoomsText variant="bodyS">
            <FormattedDate
              value={dateStringToMiddayDate(checkIn)}
              month="short"
              day="2-digit"
            />
            {' - '}
            <FormattedDate
              value={dateStringToMiddayDate(checkOut)}
              month="short"
              day="2-digit"
            />
            {' · '}
            <GuestAndRoomText rooms={rooms} />
          </DateAndRoomsText>
        </TopSectionText>
        {!isMobile && (
          <Button variant="secondary" onClick={onSeeAllClick}>
            <SeeAllText>
              <FormattedMessageWrapper
                id="suggestion.seeAll"
                defaultMessage="See all"
              />
            </SeeAllText>
          </Button>
        )}
      </TopSection>

      <SimilarHotels
        includeSameProperty
        hotelId={bookingToExtend.hotelId}
        checkIn={checkIn}
        checkOut={checkOut}
        rooms={rooms}
        trackingPayload={trackingPayload}
        onHotelClick={onHotelClick}
        hasSidePaddings={isMobile}
        parentId="extend-your-stay"
      />

      {isMobile && (
        <ContentWrapper paddingTop="s500" paddingX="s500">
          <Button variant="secondary" fullWidth onClick={onSeeAllClick}>
            <SeeAllText>
              <FormattedMessageWrapper
                id="suggestion.seeAll"
                defaultMessage="See all"
              />
            </SeeAllText>
          </Button>
        </ContentWrapper>
      )}
    </div>
  )
}
