import {getCookie, setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {getDomainName} from '@daedalus/core/src/utils/url'

export const USER_ALREADY_FROZE_A_DEAL = 'userAlreadyFrozeADeal'

export const setUserAlreadyFrozeADealCookie = () => {
  setCookie(USER_ALREADY_FROZE_A_DEAL, 'true', {
    domain: getDomainName(window.location.hostname),
    expires: 365 * 1 // 1 year
  })
}

export const getUserAlreadyFrozeADealCookie = () => {
  return getCookie(USER_ALREADY_FROZE_A_DEAL) || false
}
