import React from 'react'

import {Box} from '@daedalus/atlas/helpers/Box'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

export const GalleryLoading: React.FC = () => {
  return (
    <Box
      width="100%"
      height="100%"
      borderRadius="lg"
      overflow="hidden"
      position="relative"
    >
      <SkeletonLoader height={237} />
    </Box>
  )
}
