import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {Home} from 'components/Home'
import {SplashScreenRemover} from 'components/SplashScreenRemover'
import TrackPageView from 'components/TrackPageView'
import {useConditionalEffect} from 'hooks/useConditionalEffect'
import {useSyncHomePageSearchBox} from 'hooks/useSyncHomePageSearchBox'
import {useSyncStoreWithUrl} from 'hooks/useSyncStoreWithUrl'
import {getSapiMetaOptions} from 'modules/meta/selectors'
import {SapiProvider} from 'modules/sapiSearch/SapiProvider'
import Head from 'next/head'
import Settings from 'Settings'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {collectSapiVariationsParam} from '@daedalus/core/src/experiments/sapi'
import {ProfileKey} from '@findhotel/sapi/dist/types/packages/core/src'

import {organizationMicrodata, websiteMicrodata} from './microdata'

const HomeView = () => {
  const {isMobile} = useDeviceLayout()
  const {brandIsVio, brandSiteAddress} = useBrand()
  useSyncStoreWithUrl()
  useSyncHomePageSearchBox()
  const sapiMetaOptions = useSelector(getSapiMetaOptions)
  const dataFile = useSelector(getDataFile)
  const variations = collectSapiVariationsParam(
    dataFile?.featureFlags.map(({key}) => key)
  )
  const generateOptions = useCallback(
    () => ({
      ...sapiMetaOptions,
      variations
    }),
    [sapiMetaOptions]
  )

  useConditionalEffect(
    async () =>
      import(
        /* webpackChunkName: "AccommodationOverlayContent" */
        /* webpackPrefetch: true */
        'components/HotelDetailsOverlay/Mobile/AccommodationOverlayContent/AccommodationOverlayContent'
      ),
    isMobile
  )

  return (
    <>
      {brandIsVio && (
        <Head>
          <link rel="canonical" href={brandSiteAddress} />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(organizationMicrodata)
            }}
          ></script>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{__html: JSON.stringify(websiteMicrodata)}}
          ></script>
        </Head>
      )}
      <TrackPageView pageName={Page.Home} />
      <InjectPageDataId page={Page.Home} />
      <SplashScreenRemover />

      <SapiProvider
        profileKey={Settings.get('CLIENT_SAPI_PROFILE_KEY') as ProfileKey}
        generateOptions={generateOptions}
      >
        <Home />
      </SapiProvider>
    </>
  )
}

export default HomeView
