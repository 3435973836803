import React from 'react'
import {useIntl} from 'react-intl'
import {messages} from 'components/SplitBookings/messages'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Tooltip, TooltipPlacement} from '@daedalus/atlas/Tooltip'
import {OfferPrimaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {VariantLabel} from '../../Shared/VariantLabel'
import {SplitBookingBundleType} from '../../types'

interface MainProps {
  isSplitBooking?: false
  splitBookingType?: never
  anchorPrice: number
  anchorPriceTotal: number
  hotelId: string
  offer: SearchOffer
  offerVariant: OfferVariant
  onTagClick?: () => void
  variant: OfferPrimaryLayoutVariant
}

interface SplitBookingProps {
  isSplitBooking: true
  splitBookingType: SplitBookingBundleType
  offerVariant?: never
  hotelId: string
  variant: OfferPrimaryLayoutVariant
  onTagClick?: () => void
  anchorPrice?: never
  anchorPriceTotal?: never
  offer?: never
}

export const TagComponent: React.FC<MainProps | SplitBookingProps> = ({
  isSplitBooking = false,
  splitBookingType,
  variant = 'PrimaryDefault',
  anchorPrice,
  anchorPriceTotal,
  offerVariant,
  offer
}) => {
  const {formatMessage} = useIntl()
  const {isMobile} = useDeviceLayout()

  if (isSplitBooking) {
    if (splitBookingType === 'single_flow') {
      return null
    }

    if (isMobile) {
      return (
        <VariantLabel
          hasPadding={variant === 'PrimaryDefault'}
          variant={OfferVariant.SplitBooking}
        />
      )
    }

    return (
      <Tooltip
        content={formatMessage(messages.splitBookingInfo)}
        placement={TooltipPlacement.Bottom}
      >
        <VariantLabel
          hasPadding={variant === 'PrimaryDefault'}
          variant={OfferVariant.SplitBooking}
        />
      </Tooltip>
    )
  }

  return (
    <VariantLabel
      hasPadding={variant === 'PrimaryDefault'}
      anchorPrice={anchorPrice}
      anchorPriceTotal={anchorPriceTotal}
      offer={offer}
      variant={offerVariant}
    />
  )
}
