import {SearchUrlParams} from 'modules/search/types'

export const ensureBooleanUrlParams = (params: SearchUrlParams) => {
  const newParams = {}
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (typeof params[key] === 'boolean') {
        newParams[key] = params[key] ? 1 : 0
      } else {
        newParams[key] = params[key] // Copy non-boolean values as is
      }
    }
  }
  return newParams
}
