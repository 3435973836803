import * as React from 'react'
// TODO: Search TS preexisting issue
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {AbstractComponent} from 'react'
import {$Diff} from 'utility-types'

type InjectedPropsType = {queryString: string | void}

export function withUrlQueryString<P extends Record<string, unknown>>(
  Component: AbstractComponent<P>
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
): AbstractComponent<$Diff<P, InjectedPropsType>> {
  const queryString: string = (window.location && window.location.search) || ''
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const C = (props: $Diff<P, InjectedPropsType>) => (
    <Component {...props} queryString={queryString} />
  )
  C.displayName = Component.name
  return C
}
