import {toggle} from 'opticks'

import {Booking} from '../api-types/bovio/response/booking'
import {brands} from '../brand/config'
import {BrandCode} from '../brand/types'
import {Offer} from '../offer/types/offer'
import {addDaysToDate} from '../utils/date'
import {getIsProductionAppEnv} from '../utils/environment'

import type {OfferCheckAPIResponse} from '../offer/types/OfferCheck'

// If experimenting with API keys set BOVIO_API_KEY_EXPERIMENT_ID to the experiment ID
// Otherwise, leave BOVIO_API_KEY_EXPERIMENT_ID as null
// Set the two keys to the B side of the BoVio API experiment
const BOVIO_API_KEY_EXPERIMENT_ID = null
export const BOVIO_API_KEY_EXPERIMENT_STAGING = 'BOVIO_API_KEY_EXPERIMENT_test'
export const BOVIO_API_KEY_EXPERIMENT_PROD = 'BOVIO_API_KEY_EXPERIMENT'

/**
 * Returns BoVio profile API key. The key is different for each brand and app environment.
 * Can be forced with forceBovioApiKey query url param
 * @param object - An object containing brandCode, appEnv, and urlParams
 * @param object.brandCode - The brand code
 * @param object.appEnv - The application environment
 * @param object.urlParams - The URL parameters
 * @returns The BoVio profile API key
 */
export const getBovioApiKey = ({
  brandCode,
  appEnv,
  urlParams
}: {
  brandCode: BrandCode
  appEnv: string
  urlParams: Record<string, unknown>
}): string => {
  const isProdEnv = getIsProductionAppEnv(appEnv)

  if (!isProdEnv && urlParams.forceBovioApiKey) {
    return String(urlParams.forceBovioApiKey)
  }

  const profileKeyName = isProdEnv ? 'bovioProdApiKey' : 'bovioStagingApiKey'
  const profileKey =
    brands[brandCode]?.bookOnBrand?.[profileKeyName] ||
    (brands.vio.bookOnBrand?.[profileKeyName] as string)

  if (
    brandCode === 'vio' &&
    BOVIO_API_KEY_EXPERIMENT_ID &&
    toggle(BOVIO_API_KEY_EXPERIMENT_ID, false, true)
  ) {
    return isProdEnv
      ? BOVIO_API_KEY_EXPERIMENT_PROD
      : BOVIO_API_KEY_EXPERIMENT_STAGING
  }
  return profileKey
}

/**
 * Checks if the given argument is valid booking data
 * @param bookingData - The booking data to check
 * @returns `true` if `bookingData` is a valid booking
 */
export const isExistingBooking = (
  bookingData?: OfferCheckAPIResponse | Offer | Booking
): bookingData is Booking => {
  return !!(bookingData as Booking)?.confirmationId
}

interface BookAgainDates {
  bookAgainCheckIn: string
  bookAgainCheckOut: string
}

/**
 * Returns a consequent check in and check out date when provided the original checkout date
 * ie.: 2022-07-23
 * @param checkOutDate - The string that represents a date when a stay ends
 * @returns -
 */
export const getBookAgainCheckInCheckOutDates = (
  checkOutDate: string
): BookAgainDates => {
  const bookAgainCheckIn = checkOutDate
  const bookAgainCheckOut = addDaysToDate(bookAgainCheckIn, 1)
  return {bookAgainCheckIn, bookAgainCheckOut}
}

// if country is in member of European Union
const checkIsInEU = (countryCode: string) =>
  [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE'
  ].includes(countryCode)

/**
 * Checks if an international transaction fee should be applied based on the user's country and currency.
 * @param userCountry - The country of the user
 * @param userCurrency - The currency of the user
 * @returns `true` if an international transaction fee should be applied, `false` otherwise.
 */
export const checkInternationalTransactionFee = (
  userCountry?: string,
  userCurrency?: string
) => {
  const isEU = !!userCountry && checkIsInEU(userCountry)
  const isEuChargeable =
    !!userCurrency && ['EUR', 'GBP', 'USD', 'AUD', 'CAD'].includes(userCurrency)
  if (isEU && isEuChargeable) {
    return false
  }
  if (userCountry === 'US' && userCurrency === 'USD') return false
  if (userCountry === 'GB' && userCurrency === 'GBP') return false

  return true
}
