import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'Alternative Availability simulations',
  value: 'AlternativeAvailability',
  description:
    'Scenarios for the alternative availability endpoint, including prices by available dates',
  routes: [
    {
      endpoint: EndpointNames.AlternativeAvailability,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'withAvailability',
          displayName: 'Availability dates',
          description:
            'The availability endpoint returns alternate availability dates'
        },
        {
          value: 'withoutAvailability',
          displayName: 'No availability dates',
          description:
            'No alternate availability dates are returned from the endpoint'
        },
        {
          value: 'rateLimitError',
          displayName: 'Error - Rate limit hit',
          description:
            'Too many request have made to providers, so a rate limit error is returned with the code 429'
        }
      ]
    }
  ]
}
