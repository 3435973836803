import {useSelector} from 'react-redux'
import {getSearchType} from 'modules/sapiSearch/selectors'
import {SearchType} from 'modules/sapiSearch/utils'
import {toggle} from 'opticks'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

/**
 * Returns whether the experimental no offers card experiment is enabled
 */
export const useIsExperimentalNoOffersCardEnabled = () => {
  const searchType = useSelector(getSearchType)
  const {isMobile} = useDeviceLayout()

  return (
    searchType !== SearchType.Hotel &&
    isMobile &&
    toggle(
      '2a3185b4-mobile-hotel-card-no-offers',
      () => false,
      () => true
    )
  )
}
