import {createApi} from '@reduxjs/toolkit/query/react'
import {Except} from 'type-fest'

import {BookingStatus} from '../../../api-types/bovio/response/booking'
import {Status as BookingStatusApi} from '../../../api-types/bovio/response/booking_status'
import {Bookings} from '../../../api-types/bovio/response/bookings'
import {PaymentMethods as PaymentMethodsResponse} from '../../../api-types/bovio/response/payment_methods'
import {axiosBaseQuery} from '../../../utils/network/axiosBaseQuery'
import {BookingCreationResponse} from '../../types/BookingCreationResponse'
import {BookingFinalizationResponse} from '../../types/BookingFinalizationResponse'
import {BookingRequestWithPsd2} from '../../types/BookingRequest'
import {BookingsFiltersValues} from '../../types/BookingsFilterValues'
import {BookingsRequestsParams} from '../../types/BookingsRequestsParams'
import {
  BookingStatusBaseResponse,
  BookingStatusConfirmedResponse,
  BookingStatusErrorResponse,
  BookingStatusThreeDomainSecureRequiredResponse
} from '../../types/BookingStatus'
import {LinkType} from '../../types/Link'

export const BOOKING_REQUEST_ID_HEADER = 'x-booking-request-id'
export const API_KEY_HEADER = 'x-Api-Key'
export const REQUEST_ID_HEADER = 'x-Request-Id'

export interface BoVioErrorInputValidationDetailsError {
  field: string
  validations: [string]
}

export interface BoVioErrorInputValidationDetails {
  errors?: [BoVioErrorInputValidationDetailsError]
}

export interface BoVioErrorData {
  details?: BoVioErrorInputValidationDetails
  message: string
  providerCode?: string
  type: string
}

export interface PostBookingRequest {
  link: LinkType
  payload: BookingRequestWithPsd2
  httpRequestId: string
  bookingRequestId: string
  profile: string
  headers?: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
    'x-auth-token'?: string
  }
}

export interface PutBookingRequest {
  link: LinkType
  httpRequestId: string
  bookingRequestId: string
  profile: string
  headers?: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
  }
}

export interface PostEmailConfirmationRequest {
  link: LinkType
  payload: {
    email: string
  }
  experimentApiKey?: string
  headers: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
    [API_KEY_HEADER]?: string
  }
}

export interface GetBookingStatusRequest {
  link: LinkType
  httpRequestId: string
  bookingRequestId: string
  profileKey: string
  headers?: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
  }
}

export interface GetBookingsRequest {
  link: string
  headers: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
    'x-auth-token': string
  }
  params: BookingsRequestsParams
  filter: BookingsFiltersValues
}

export interface GetPaymentMethodsRequest {
  link: LinkType
  httpRequestId: string
  profileKey: string
  headers?: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
  }
}

export interface GetLastUsedBillingAddressRequest {
  url: string
  headers: {
    Accept: 'application/json'
    'X-Client-User-Agent': string
    'x-auth-token': string
  }
}

export interface GetLastUsedBillingAddressResponse {
  city: string | null
  countryCode: string | null
  line1: string | null
  line2: string | null
  postalCode: string | null
  stateCode: string | null
}

interface GetInvoiceRequest {
  url: string
  headers?: object
}

export type InvoiceRooms = {
  firstName: string
  lastName: string
}[]

export interface GetInvoiceResponse {
  number: number
  billing: {
    address: {
      city: string
      countryCode: string
      line1: string
      line2?: string
      postalCode: string
    }
    cardHolderName: string
    companyName?: string
    crn: string
    vat: string
  }
  booking: {
    confirmationNumber?: string
    checkIn: string
    checkOut: string
    guestName: string
    hotel: {
      address: {
        city: string
        country: string
        line: string
      }
      name: string
    }
    room: {
      cancellationPolicy: string
      numberOfAdults: number
      pricePerRoom: string
      roomType: string
    }
    rooms: InvoiceRooms
  }
  id: string
  insertedAt: string
  merchantOfRecord: {
    address: {
      line1: string
      line2: string
    }
    companyName: string
    kvk: string
    vat: string
  }
  payment: {
    base: string
    cardBrand: string
    cardLastFourDigits: string
    charged: string
    currency: string
    taxes: string
  }
}

export interface CreateInvoicePayload {
  billing: {
    address: {
      city: string
      countryCode: string
      line1: string
      line2?: string | null
      postalCode: string
    }
    cardHolderName: string
    companyName?: string
    crn?: string
    vat?: string
  }
}

export interface CreateInvoiceHeaders {
  Accept: 'application/json'
}

export interface CreateInvoiceRequest {
  link: LinkType
  payload: CreateInvoicePayload
  headers?: CreateInvoiceHeaders
}

export type CreateInvoiceResponse = GetInvoiceResponse

export interface CreateInvoiceQuery {
  url: CreateInvoiceRequest['link']['href']
  method: CreateInvoiceRequest['link']['method']
  data: CreateInvoiceRequest['payload']
  headers: CreateInvoiceRequest['headers']
}

export interface CreateInvoiceErrorResponse {
  data: BoVioErrorData
  headers: object
  status: number
}

export const bookApi = createApi({
  reducerPath: 'bookApi',
  tagTypes: ['Bookings'],
  baseQuery: axiosBaseQuery({
    baseUrl: ''
  }),
  serializeQueryArgs: ({endpointName}) => endpointName,
  endpoints: builder => ({
    createBooking: builder.mutation<
      BookingCreationResponse,
      PostBookingRequest
    >({
      query: ({
        link,
        payload,
        profile,
        httpRequestId,
        bookingRequestId,
        headers
      }) => ({
        url: link.href,
        method: link.method,
        data: payload,
        headers: {
          ...headers,
          [BOOKING_REQUEST_ID_HEADER]: bookingRequestId,
          [API_KEY_HEADER]: profile,
          [REQUEST_ID_HEADER]: httpRequestId
        }
      })
    }),
    bookingFinalization: builder.mutation<
      BookingFinalizationResponse,
      PutBookingRequest
    >({
      query: ({link, profile, httpRequestId, bookingRequestId, headers}) => ({
        url: link.href,
        method: link.method,
        headers: {
          ...headers,
          [BOOKING_REQUEST_ID_HEADER]: bookingRequestId,
          [API_KEY_HEADER]: profile,
          [REQUEST_ID_HEADER]: httpRequestId
        }
      })
    }),
    getBookingStatus: builder.query<
      | BookingStatusBaseResponse
      | BookingStatusThreeDomainSecureRequiredResponse
      | BookingStatusConfirmedResponse
      | BookingStatusErrorResponse,
      GetBookingStatusRequest
    >({
      query: ({
        link,
        profileKey,
        httpRequestId,
        bookingRequestId,
        headers
      }) => ({
        url: link.href,
        method: link.method,
        headers: {
          ...headers,
          [BOOKING_REQUEST_ID_HEADER]: bookingRequestId,
          [API_KEY_HEADER]: profileKey,
          [REQUEST_ID_HEADER]: httpRequestId
        }
      })
    }),
    sendBookingConfirmation: builder.mutation<
      void,
      PostEmailConfirmationRequest
    >({
      query: ({link, payload, headers, experimentApiKey}) => {
        // To prevent 'Attempting to define property on object that is not extensible' error
        const apiKeyHeader = experimentApiKey
          ? {[API_KEY_HEADER]: experimentApiKey}
          : {}

        return {
          url: link.href,
          method: link.method,
          data: payload,
          headers: {
            ...headers,
            ...apiKeyHeader
          }
        }
      }
    }),
    getBookings: builder.query<Bookings[], GetBookingsRequest>({
      query: ({link, headers, params}) => {
        return {
          url: link,
          headers,
          params
        }
      },
      serializeQueryArgs: ({endpointName, queryArgs}) => {
        if (queryArgs) {
          const {filter} = queryArgs
          return {filter}
        }
        return endpointName
      },
      providesTags: () => ['Bookings']
    }),
    createChargeLaterBooking: builder.mutation<
      BookingCreationResponse,
      PostBookingRequest
    >({
      query: ({
        link,
        payload,
        profile,
        httpRequestId,
        bookingRequestId,
        headers
      }) => ({
        url: link.href,
        method: link.method,
        data: payload,
        headers: {
          ...headers,
          [BOOKING_REQUEST_ID_HEADER]: bookingRequestId,
          [API_KEY_HEADER]: profile,
          [REQUEST_ID_HEADER]: httpRequestId
        }
      })
    }),
    getPaymentMethods: builder.query<
      PaymentMethodsResponse[],
      GetPaymentMethodsRequest
    >({
      query: ({link, profileKey, httpRequestId, headers}) => ({
        url: link.href,
        method: link.method,
        headers: {
          ...headers,
          [API_KEY_HEADER]: profileKey,
          [REQUEST_ID_HEADER]: httpRequestId
        }
      })
    }),
    getLastUsedBillingAddress: builder.query<
      GetLastUsedBillingAddressResponse,
      GetLastUsedBillingAddressRequest
    >({
      query: ({url, headers}) => ({
        url,
        method: 'GET',
        headers
      })
    }),
    getInvoice: builder.query<GetInvoiceResponse, GetInvoiceRequest>({
      query: ({url, headers}) => ({url, method: 'GET', headers})
    }),
    createInvoice: builder.mutation<
      CreateInvoiceResponse,
      CreateInvoiceRequest
    >({
      query: ({
        link,
        payload,
        headers
      }: CreateInvoiceRequest): CreateInvoiceQuery => ({
        url: link.href,
        method: link.method,
        data: payload,
        headers
      })
    })
  })
})

// We have a custom query key but there is no type support for that.
// So we cast that key type to request params type.
export const GET_BOOKING_STATUS_CACHE_KEY =
  'bookingStatus' as unknown as GetBookingStatusRequest

export const {
  useCreateBookingMutation,
  useBookingFinalizationMutation,
  useGetBookingStatusQuery,
  useSendBookingConfirmationMutation,
  useGetBookingsQuery,
  useCreateChargeLaterBookingMutation,
  useGetLastUsedBillingAddressQuery,
  useGetPaymentMethodsQuery,
  useGetInvoiceQuery,
  useCreateInvoiceMutation
} = bookApi

type GetBookingStatusData = Except<
  ReturnType<typeof useGetBookingStatusQuery>,
  'refetch'
>

export const getIsAsyncBookingInitializing = (
  bookingStatusData: GetBookingStatusData
) =>
  bookingStatusData.isLoading ||
  (bookingStatusData.isSuccess &&
    bookingStatusData.data?.status === BookingStatusApi.Initialized)

export const getIsAsyncBookingFinalizing = (
  bookingStatusData: GetBookingStatusData
) =>
  bookingStatusData.isLoading ||
  (bookingStatusData.isSuccess &&
    bookingStatusData.data?.status === BookingStatusApi.Finalizing)

export const getIsAsyncBookingProcessing = (
  bookingStatusData: GetBookingStatusData
) =>
  getIsAsyncBookingInitializing(bookingStatusData) ||
  getIsAsyncBookingFinalizing(bookingStatusData)

export const isStatusConfirmed = (status: BookingStatus | string) => {
  return (
    status === BookingStatus.Confirmed ||
    status === BookingStatus.ChargeLaterConfirmed
  )
}

export const getIsBookingStatusConfirmed = (
  bookingStatusData: GetBookingStatusData
) =>
  bookingStatusData.isSuccess &&
  isStatusConfirmed(bookingStatusData.data?.status)

export const getIsBookingStatusThreeDomainSecureRequired = (
  bookingStatusData: GetBookingStatusData
) =>
  bookingStatusData.isSuccess &&
  bookingStatusData.data?.status === BookingStatus.ThreeDomainSecureRequired

export const getIsBookingStatusError = (
  bookingStatusData: GetBookingStatusData
) =>
  bookingStatusData.isError ||
  (bookingStatusData.isSuccess &&
    bookingStatusData.data?.status === BookingStatus.Error)
