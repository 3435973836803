// Add as part of 366b54ed-price-difference-popup

const PRICE_CHANGE_THRESHOLD = 1

interface Props {
  clickedOfferRate: number
  totalRate: number
}

// Find the total price difference for the stay between the anchor hotel
// preferred rate or cheapest rate and the clicked offer
export const calculateWeb2AppPriceDifference = ({
  clickedOfferRate,
  totalRate
}: Props) => {
  if (totalRate <= 0 || clickedOfferRate <= 0)
    return {isPriceIncrease: false, isPriceDecrease: false, priceDifference: 0}

  const diff = totalRate - clickedOfferRate

  const isPriceDecrease = diff < -PRICE_CHANGE_THRESHOLD
  const isPriceIncrease = diff > PRICE_CHANGE_THRESHOLD

  const isPriceChanged = isPriceDecrease || isPriceIncrease
  const priceDifference = isPriceChanged ? (isPriceDecrease ? -diff : diff) : 0

  return {isPriceIncrease, isPriceDecrease, priceDifference}
}
