// TODO (Core): Add unit tests for this function
/**
 * Yielding control back to the browser breaks (long) tasks.
 * After each call of this function, it causes the current tasks to yield, continuing in a new task.
 *
 * scheduler.yield() is a new API still in Origin trial: https://chromestatus.com/feature/6266249336586240
 * While this is not enabled in browsers, we use setTimeout to break tasks into smaller ones.
 * Resource: https://innovativetravel.atlassian.net/wiki/spaces/ENG/pages/3791487583/Breaking+Long+Tasks+Optimizations
 */
export async function yieldToMain() {
  if ('scheduler' in window && 'yield' in window.scheduler) {
    return await window.scheduler.yield()
  }

  return new Promise(resolve => {
    setTimeout(resolve, 0)
  })
}
