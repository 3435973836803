import React, {useCallback, useRef} from 'react'
import {useIntl} from 'react-intl'
import {filterMessages} from 'components/Filters/filterMessages'
import {useFiltersForm} from 'hooks/useFiltersForm'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const filterKeys = ['amenities']
const elementName = 'quickFilterAmenities'

interface Props {
  isActive?: boolean
}
export const BreakfastPill: React.FC<Props> = ({isActive = false}) => {
  const {formatMessage} = useIntl()
  const {isMobile} = useDeviceLayout()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const {trackFilterClick, submitFilters, getAppliedFilters} = useFiltersForm(
    filterKeys,
    elementName
  )

  const {amenities = []} = getAppliedFilters()

  const handlePillClick = useCallback(() => {
    if (isActive) buttonRef.current?.blur()

    const updatedFilters = {
      amenities: isActive ? undefined : ['breakfast']
    }

    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })
    submitFilters(updatedFilters)
  }, [isActive, trackFilterClick, submitFilters])

  if ((!isMobile && isActive) || amenities.includes('allInclusive')) return null

  return (
    <Pill
      dataId="breakfastFilter"
      onClick={handlePillClick}
      isSelected={isActive}
      iconStart={<Icon name="Breakfast" />}
      iconEnd={isActive && <Icon name={'Close'} />}
    >
      {formatMessage(filterMessages.breakfast)}
    </Pill>
  )
}
