import {SELECTOR_MAX_CACHE_ENTRIES} from 'config/selectorCaching'
import {
  getHotel,
  getHotelIdsWithGreatDeal,
  getHotelOfferEntity
} from 'modules/sapiSearch/selectors'
import {Hotel, SearchOffer} from 'modules/sapiSearch/slice'
import {includes} from 'ramda'
import {createSelectorCreator, defaultMemoize} from 'reselect'
import {RootState} from 'store'

import {getShouldSeeOffersUnlocked} from '@daedalus/core/src/auth/modules/selectors'

import {listHotelTags} from './utils'

export type State = RootState

/**
 * Creates a selector with multiple cache entries to let selectors with parameters (ie. hotelId or offerId) get cached properly
 */
const createSelector = createSelectorCreator(defaultMemoize, {
  maxSize: SELECTOR_MAX_CACHE_ENTRIES
})

/**
 * Returns an array of offer id for offers that have been viewed by the user
 */
const getOffersViewed = (state: State): Array<SearchOffer['id']> =>
  state.analytics.offersViewed

/**
 * Returns whether an offer has been viewed by the user
 */
export const getIsOfferViewed = createSelector(
  [(__, offerId: SearchOffer['id']) => offerId, getOffersViewed],
  (
    offerId: SearchOffer['id'],
    offersViewed: Array<SearchOffer['id']>
  ): boolean => {
    if (offersViewed.length === 0) return false

    return includes(offerId, offersViewed)
  }
)

/**
 * Returns an array of hotel ids for anchor hotels that have been viewed by the user
 * in the same session (user has viewed at least one offer for the hotel)
 */
const getAnchorHotelsViewed = (state: State): Array<Hotel['objectID']> =>
  state.analytics.anchorHotelsViewed

/**
 * Returns whether an anchor hotel has been viewed by the user in the same session
 * (user has viewed at least one offer for the hotel)
 */
export const getIsAnchorHotelViewed = createSelector(
  [(__, hotelId: Hotel['objectID']) => hotelId, getAnchorHotelsViewed],
  (
    hotelId: Hotel['objectID'],
    anchorHotelsViewed: Array<Hotel['objectID']>
  ): boolean => {
    if (anchorHotelsViewed.length === 0) return false

    return includes(hotelId, anchorHotelsViewed)
  }
)

/**
 * Returns an array of hotel tags for the provided hotel id for tracking purposes
 */
export const getHotelTags = createSelector(
  [
    getHotel,
    getHotelOfferEntity,
    getHotelIdsWithGreatDeal,
    getShouldSeeOffersUnlocked
  ],
  (hotel, offerEntity, hotelIdsWithGreatDeal, shouldSeeOffersUnlocked) => {
    if (!hotel) return []

    return listHotelTags({
      hotel,
      offerEntity,
      hotelIdsWithGreatDeal,
      shouldSeeOffersUnlocked
    })
  }
)
