import {SuggestionSectionDataType} from '@daedalus/core/src/autocomplete/types'

export enum DatePickerType {
  CheckIn = 'checkIn',
  CheckOut = 'checkOut'
}

export enum OpenState {
  Closed = 'CLOSED',
  OpenByDefault = 'OPEN_BY_DEFAULT',
  OpenByUser = 'OPEN_BY_USER',
  Unset = 'UNSET'
}

/**
 * This value is sent to analytics to determine the "trigger" of a search.
 * It is also used by the searchBox slice to determine whether to open the searchbox after a search. Be sure to check this logic when adding/changing keys.
 */
export enum SearchTrigger {
  AccommodationPageDatePicker = 'ap_datePicker',
  AccommodationPageGuestPicker = 'ap_guestPicker',
  AccommodationSuggestedHotels = 'ap_suggestedHotels',
  AccommodationPageRoomsDatePicker = 'ap_roomsDatePicker',
  AccommodationPageRoomsGuestPicker = 'ap_roomsGuestPicker',
  DatePicker = 'datePicker',
  GuestPicker = 'guestPicker',
  RecentSearches = 'recentSearches', // Note: this is used for both the homepage recent searches widgets and recent searches features in the search box
  Suggestions = 'suggestions',
  Submit = 'submit',
  Close = 'close',
  Sort = 'sort',
  Filter = 'filter',
  UserLocation = 'userLocation',
  AvailabilityTile = 'availabilityTile',
  ContinueSearchWidget = 'similarProperties',
  ExtendYourStayWidget = 'stayLongerNearby',
  TopDealsTonightWidget = 'suggestedProperties'
}

export enum SearchBoxAncestor {
  Overlay = 'overlay',
  Page = 'page',
  Map = 'map'
}

// TODO (@find) - update the BooleanUrlParam types to be boolean and make sure the correct values are being stored
export interface SearchBoxValues {
  boundingBox?: Array<string | number>
  userLocationSearch?: BooleanUrlParam
  checkIn?: string
  checkOut?: string
  hotelId?: string
  placeId?: string
  rooms?: string
  optimizeRooms?: BooleanUrlParam
  address?: string
  homeSearch?: BooleanUrlParam
}

export type SearchBoxValue = SearchBoxValues[keyof SearchBoxValues]

export interface SearchBoxStateType {
  ancestor: SearchBoxAncestor | null | undefined
  searchBoxDestinationDisplayValue: string | null | undefined
  isDefaultDatesMessageVisible: boolean | null | undefined
  isDatesVisible: boolean
  isAnchorHotelUnavailable: boolean | null | undefined
  openDatePickerType: DatePickerType | null | undefined
  openState: OpenState
  values: SearchBoxValues
  searchTrigger: SearchTrigger | null | undefined
  suggestions: SuggestionSectionDataType[]
  isSearchBoxActive: boolean
  suggestionsSearchValue: string
}

export interface SetSuggestionsPayload {
  suggestions: SuggestionSectionDataType[]
  suggestionsSearchValue: string
}
