/**
 * BoFH API - Booking response
 */
export interface Booking {
  /**
   * Anonymous ID
   */
  anonymousId: string
  /**
   * Billing information
   */
  billing: Billing
  /**
   * Denotes whether the booking has multiple provider bookings (bundle) or a single provider
   * booking (regular).
   */
  bookingType: BookingType
  /**
   * Brand code
   */
  brandCode?: null | string
  /**
   * Booking ID of the other booking that composes the Split Bookings bundle.
   */
  bundleBookingId?: null | string
  /**
   * Cancellation ID
   */
  cancellationId?: null | string
  /**
   * List of cancellation penalties
   */
  cancellationPenalties: CancellationPenalty[]
  /**
   * Cancellation policy
   */
  cancellationPolicy: CancellationPolicy
  /**
   * The date and time (UTC) on which the booking was cancelled
   */
  cancelledAt?: null | string
  /**
   * The date and time (UTC) on which the booking was charged
   */
  chargedAt?: null | string
  /**
   * The first charge attempt due date.
   */
  chargeDueAt?: null | string
  /**
   * Check-in date
   */
  checkIn: string
  /**
   * Check-out date
   */
  checkOut: string
  /**
   * FH confirmation ID that can be used by FH customer support
   */
  confirmationId: string
  /**
   * Customer information
   */
  customer: Customer
  /**
   * Customer ISO-3166-1 alpha-2 country code
   */
  customerCountryCode?: null | string
  /**
   * Customer IP
   */
  customerIp: string
  /**
   * Customer support information
   */
  customerSupport: CustomerSupport
  /**
   * The date and time (UTC) on which an unpaid booking expires
   */
  expiresAt?: null | string
  /**
   * The date on which the booking fulfills
   */
  fulfillmentDate?: null | string
  /**
   * FH hotel ID
   */
  hotelId: string
  /**
   * FH booking ID
   */
  id: string
  /**
   * The date and time (UTC) on which the booking was created
   */
  insertedAt: string
  /**
   * Instructions
   */
  instructions: Instructions
  /**
   * Links generated for the booking
   */
  links: Link[]
  /**
   * Name of the entity that processes the customer payment.
   */
  merchantOfRecord: MerchantOfRecord | null
  /**
   * The booking offer identifier
   */
  offerId?: null | string
  /**
   * Offer metadata
   */
  offerMetadata: OfferMetadata
  /**
   * Payment information.
   */
  payment?: null | Payment
  /**
   * Prices
   */
  prices: Price[]
  /**
   * The confirmation ID that a provider needs in their customer support
   */
  providerConfirmationId: string
  /**
   * Provider trader information
   */
  providerTraderInformation?: ProviderTraderInformation
  /**
   * The redirect ID of the booking.
   */
  redirectId?: string
  /**
   * Refund informations
   */
  refund?: null | Refund
  /**
   * Rooms
   */
  rooms: Room[]
  /**
   * Booking source
   */
  source: Source
  /**
   * Booking status
   */
  status: BookingStatus
  /**
   * The date and time (UTC) on which the booking was last time updated
   */
  updatedAt: string
}

/**
 * Billing information
 */
export interface Billing {
  /**
   * Address
   */
  address: null | Address
  /**
   * First name
   */
  firstName: string
  /**
   * Last name
   */
  lastName: string
}

export interface Address {
  /**
   * City
   */
  city: string
  /**
   * Country code
   */
  countryCode: string
  /**
   * Address line one
   */
  line1: string
  /**
   * Address line two
   */
  line2?: null | string
  /**
   * Postal code
   */
  postalCode: string
  /**
   * State code
   */
  stateCode?: null | string
}

/**
 * Denotes whether the booking has multiple provider bookings (bundle) or a single provider
 * booking (regular).
 */
export enum BookingType {
  Bundle = 'bundle',
  Regular = 'regular'
}

/**
 * Cancellation penalty
 */
export interface CancellationPenalty {
  /**
   * Fixed amount of the penalty
   */
  amount: string
  /**
   * Currency of the fixed amount
   */
  currency: string
  /**
   * End date and time of the penalty
   */
  end: string
  /**
   * Start date and time of the penalty
   */
  start: string
}

/**
 * Cancellation policy
 */
export interface CancellationPolicy {
  /**
   * Deadline for free cancellation
   */
  deadline?: null | string
  /**
   * Description
   */
  description: string
}

/**
 * Customer information
 */
export interface Customer {
  /**
   * Email
   */
  email: string
  /**
   * First name
   */
  firstName: string
  /**
   * Last name
   */
  lastName: string
  /**
   * Phone number
   */
  phoneNumber?: null | string
}

/**
 * Customer support information
 */
export interface CustomerSupport {
  /**
   * Email
   */
  email: string
  /**
   * Phone number
   */
  phoneNumber?: null | string
}

/**
 * Instructions
 */
export interface Instructions {
  /**
   * The time at which a hotel stops allowing check-ins
   */
  checkInEnd?: null | string
  /**
   * The minimum age for a customer to be able to check-in at a property
   */
  checkInMinimumAge?: number | null
  /**
   * The time at which a hotel begins to allow check-ins
   */
  checkInStart?: null | string
  /**
   * The time by which a guest must check out
   */
  checkOutEnd?: null | string
  /**
   * Additional instructions from the hotel
   */
  details?: null | string
}

/**
 * Link
 */
export interface Link {
  /**
   * URL
   */
  href: string
  /**
   * HTTP method
   */
  method: MethodEnum
  /**
   * Type
   */
  type: LinkType
}

/**
 * HTTP method
 */
export enum MethodEnum {
  Delete = 'DELETE',
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT'
}

/**
 * Type
 */
export enum LinkType {
  BookingCancellation = 'booking_cancellation',
  BookingConfirmationNotification = 'booking_confirmation_notification',
  BookingPayment = 'booking_payment',
  Invoice = 'invoice',
  InvoiceCreation = 'invoice_creation',
  ManageBooking = 'manage_booking',
  PaymentMethods = 'payment_methods'
}

export enum MerchantOfRecord {
  Expedia = 'expedia',
  Findhotel = 'findhotel',
  Getaroom = 'getaroom',
  Hotel = 'hotel',
  Priceline = 'priceline'
}

/**
 * Offer metadata
 */
export interface OfferMetadata {
  /**
   * Information to help keep membership experience across the whole booking funnel
   */
  accessTier: string[]
}

export interface Payment {
  /**
   * Booking charge attempts
   */
  chargeAttempts?: ChargeAttempt[]
  /**
   * Payment method
   */
  method: MethodObject
  /**
   * Payment status.
   */
  status?: PaymentStatus
}

/**
 * Charge Attempt
 */
export interface ChargeAttempt {
  /**
   * Attempted At
   */
  attemptedAt: string
}

/**
 * Payment method
 */
export interface MethodObject {
  /**
   * Card information.
   */
  card: Card
  /**
   * The payment type.
   */
  type: MethodType
}

/**
 * Card information.
 */
export interface Card {
  /**
   * The card brand.
   */
  brand: string
  /**
   * The last four digits of the card.
   */
  lastFourDigits: string
}

/**
 * The payment type.
 */
export enum MethodType {
  Card = 'card'
}

/**
 * Payment status.
 */
export enum PaymentStatus {
  Failed = 'failed',
  Pending = 'pending',
  Refunded = 'refunded',
  Succeeded = 'succeeded',
  ThreeDomainSecureRequired = 'three_domain_secure_required'
}

/**
 * Price
 */
export interface Price {
  /**
   * Chargeable information
   */
  chargeable: Chargeable
  currencyCode: string
  /**
   * Hotel fees information
   */
  hotelFees: HotelFees
  /**
   * The type of the price
   */
  type: PriceType
}

/**
 * Chargeable information
 */
export interface Chargeable {
  /**
   * Total rate without taxes
   */
  base: string
  currency: string
  /**
   * Total of all taxes
   */
  taxes: string
  /**
   * Total of base rate and taxes
   */
  total: string
}

/**
 * Hotel fees information
 */
export interface HotelFees {
  /**
   * The breakdown of the hotel fees per type.
   */
  breakdown: Breakdown[]
  currency: string
  /**
   * Total of all hotel fees
   */
  total: string
}

export interface Breakdown {
  /**
   * The amount charged for the type.
   */
  total: string
  /**
   * The type of fee charged.
   */
  type: BreakdownType
}

/**
 * The type of fee charged.
 */
export enum BreakdownType {
  Other = 'other',
  ResortFee = 'resort_fee',
  Tax = 'tax'
}

/**
 * The type of the price
 */
export enum PriceType {
  ChargeableCurrency = 'chargeable_currency',
  ChargeablePrice = 'chargeable_price',
  HotelCurrency = 'hotel_currency',
  UserCurrency = 'user_currency'
}

/**
 * Provider trader information
 */
export interface ProviderTraderInformation {
  /**
   * Country code.
   */
  countryCode?: null | string
  /**
   * Trader name.
   */
  name?: null | string
  /**
   * Phone number
   */
  phoneNumber?: null | string
  /**
   * The URL of the terms and conditions. It can point to a PDF or a static HTML page.
   */
  termsAndConditionsUrl?: null | string
}

export interface Refund {
  /**
   * The refund amount.
   */
  amount: string
  /**
   * The refund amount currency.
   */
  currency: string
  /**
   * The status of the refund. For cancelled bookings, it will be `refunded`. For confirmed
   * bookings it will be `to_be_refunded`.
   */
  status: RefundStatus
}

/**
 * The status of the refund. For cancelled bookings, it will be `refunded`. For confirmed
 * bookings it will be `to_be_refunded`.
 */
export enum RefundStatus {
  Refunded = 'refunded',
  ToBeRefunded = 'to_be_refunded'
}

/**
 * Room
 */
export interface Room {
  /**
   * Bed type name
   */
  bedTypeName?: null | string
  /**
   * Describe what meals or drink options are included
   */
  boardType?: BoardType | null
  /**
   * Room check-in date
   */
  checkIn?: string
  /**
   * Room check-out date
   */
  checkOut?: string
  /**
   * Confirmation ID
   */
  confirmationId: string
  /**
   * Guest first name
   */
  firstName: string
  /**
   * Guests
   */
  guests: null | Guests
  /**
   * Room ID
   */
  id?: null | string
  /**
   * Guest last name
   */
  lastName: string
  /**
   * Room name
   */
  name?: null | string
  /**
   * Provider room ID
   */
  providerRoomId?: null | string
  /**
   * Provider room name
   */
  providerRoomName?: null | string
  /**
   * Sanitized room name
   */
  sanitizedName?: null | string
  /**
   * Smoking
   */
  smoking?: boolean | null
  /**
   * Special request
   */
  specialRequest?: null | string
}

export enum BoardType {
  AllInclusive = 'all_inclusive',
  Breakfast = 'breakfast',
  Dinner = 'dinner',
  FullBoard = 'full_board',
  HalfBoard = 'half_board',
  Lunch = 'lunch',
  RoomOnly = 'room_only'
}

export interface Guests {
  /**
   * Quantity of adults
   */
  adults: number
  /**
   * Child ages
   */
  childAges: number[]
  /**
   * Quantity of children
   */
  children: number
}

/**
 * Booking source
 */
export enum Source {
  ChargeLater = 'charge_later',
  DealFreeze = 'deal_freeze',
  Default = 'default'
}

/**
 * Booking status
 */
export enum BookingStatus {
  Cancelled = 'cancelled',
  ChargeLaterCancelled = 'charge_later_cancelled',
  ChargeLaterConfirmed = 'charge_later_confirmed',
  ChargeLaterExpired = 'charge_later_expired',
  ChargeLaterFailed = 'charge_later_failed',
  Confirmed = 'confirmed',
  Error = 'error',
  FreezeCancelled = 'freeze_cancelled',
  FreezeExpired = 'freeze_expired',
  Frozen = 'frozen',
  Initialized = 'initialized',
  Rebooked = 'rebooked',
  ThreeDomainSecureRequired = 'three_domain_secure_required'
}
