import {fetchShortUrl} from 'services/shortUrl'
import {createHotelShareUrl} from 'utils/sharing'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {logAndCaptureErrorMessage} from '@daedalus/core/src/utils/logging/errorHandlers'

export const useShare = ({shareParams, component, shareType}) => {
  const dispatchTeamEvent = useDispatchTeamEvent()
  const {brandShareUrl, brandIsVio} = useBrand()

  const brandHasShareUrl = Boolean(brandShareUrl)
  const shareIsAvailable = Boolean(navigator.share)
  const isDevelopmentMode = process.env.NODE_ENV === 'development'
  const isReactNative = isReactNativeWebView()

  const shortenAndShareUrl = (onUrlReady: (url: string) => void) => {
    const url = createHotelShareUrl({...shareParams, shareType})

    // return the full url for vio.com for the app to open
    if (brandIsVio) {
      return onUrlReady(url)
    }

    const timeoutMs = 3000
    const fetchShortUrlPromise = fetchShortUrl({url, brandShareUrl})

    const timeoutId = setTimeout(() => {
      onUrlReady(url)
    }, timeoutMs)

    fetchShortUrlPromise
      .then(shortUrl => {
        clearTimeout(timeoutId)
        onUrlReady(shortUrl)
      })
      .catch(error => {
        clearTimeout(timeoutId)
        logAndCaptureErrorMessage(error)
        onUrlReady(url)
      })
  }

  const shouldShow = () =>
    brandHasShareUrl &&
    (shareIsAvailable ||
      // Displaying it also in dev mode to make sure it's always visible in mobile-emulation for easier testing
      isDevelopmentMode ||
      isReactNative)

  const onShare = () => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.ShareLinkButton,
        action: Action.Clicked,
        component
      })
    )

    shortenAndShareUrl(shortUrl => {
      try {
        // Uses postMessage to webview if it's in the app
        if (isReactNativeWebView()) {
          postMessageToWebView({
            type: RNWebViewMessageTypes.SHARE,
            payload: {
              url: shortUrl
            }
          })
          // Use navigator.share from browser if it's not in the app
        } else if (navigator.share) {
          navigator.share({
            url: shortUrl
          })
        }
      } catch (error) {
        if (error.toString().includes('AbortError')) return
        logAndCaptureErrorMessage(error)
      }
    })
  }

  return {
    onShare,
    shouldShow
  }
}
