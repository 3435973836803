import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  getCurrentPageLength,
  getCurrentPageNumber,
  getCurrentPageOffset,
  getIsLoadMoreResultsPending,
  getSearchId
} from 'modules/sapiSearch/selectors'

type ReturnType = {
  isFooterVisibilitySensorEnabled: boolean
  onFooterView: () => void
}

const useOnFooterView = (): ReturnType => {
  const pageOffset: number = useSelector(getCurrentPageOffset)
  const pageNumber: number = useSelector(getCurrentPageNumber)
  const pageLength: number = useSelector(getCurrentPageLength)
  const searchId: string = useSelector(getSearchId)
  const loadMoreResultsIsPending: boolean = useSelector(
    getIsLoadMoreResultsPending
  )

  const dispatch = useDispatch()

  const [isFooterVisibilitySensorEnabled, setFooterVisibilitySensorEnabled] =
    useState(true)

  useEffect(() => {
    setFooterVisibilitySensorEnabled(true)
  }, [pageOffset, searchId])

  const onFooterView = useCallback(() => {
    if (loadMoreResultsIsPending) return

    setFooterVisibilitySensorEnabled(false)
  }, [
    dispatch,
    pageLength,
    pageOffset,
    pageNumber,
    searchId,
    loadMoreResultsIsPending
  ])

  return {
    isFooterVisibilitySensorEnabled,
    onFooterView
  }
}

export default useOnFooterView
