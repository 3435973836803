import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import isValid from 'date-fns/isValid'

const MAX_DAY_DIFFERENCE_TO_DISPLAY = 2

interface LastBookedLabelConfig {
  labelIsVisible: boolean
  dayDifference: number
}

export const getLastBookedLabelConfig = (
  timestamp: number
): LastBookedLabelConfig => {
  const bookingDate = new Date(timestamp * 1000)
  const currentDate = new Date()
  const dayDifference = differenceInCalendarDays(currentDate, bookingDate)

  return {
    labelIsVisible:
      isValid(bookingDate) &&
      dayDifference >= 0 &&
      dayDifference <= MAX_DAY_DIFFERENCE_TO_DISPLAY,
    dayDifference
  }
}

export const isLastBookedLabelVisible = (timestamp: number): boolean =>
  getLastBookedLabelConfig(timestamp).labelIsVisible
