import React, {lazy, Suspense} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BottomCTA} from 'components/Accommodation/Offers/BottomCTA'
import {useConditionalEffect} from 'hooks/useConditionalEffect'
import {getHotelFacilitiesVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src'

interface Props {
  hotel: ContentHotel | undefined
}

const FacilitiesBottomSheetContent = lazy(
  () =>
    import(
      /* webpackChunkName: "FacilitiesBottomSheetContent" */
      './FacilitiesBottomSheetContent'
    )
)

export const FacilitiesBottomSheet = ({hotel}: Props) => {
  const dispatch = useDispatch()
  const isFacilitiesOverlayOpen = useSelector(getHotelFacilitiesVisibility)

  const onClose = () => {
    dispatch(hideOverlay())
  }

  useConditionalEffect(
    async () =>
      import(
        /* webpackChunkName: "FacilitiesBottomSheetContent" */
        /* webpackPrefetch: true */
        './FacilitiesBottomSheetContent'
      )
  )

  return (
    <BottomSheet
      titleBarProps={{
        centerContent: (
          <FormattedMessageWrapper id="amenities" defaultMessage="Amenities" />
        )
      }}
      footerContent={
        <BottomCTA
          hotelId={hotel?.objectID}
          sourceComponent="MobileHotelViewAllFacilitiesStaticOfferCTA"
        />
      }
      snapPoints={{top: 'nearFull', middle: 0.7}}
      withDragHandler
      isOpen={isFacilitiesOverlayOpen}
      onClose={onClose}
      prefersReducedMotion
    >
      {isFacilitiesOverlayOpen && (
        <Suspense fallback={null}>
          <FacilitiesBottomSheetContent hotel={hotel} />
        </Suspense>
      )}
    </BottomSheet>
  )
}
