import {loadModule} from 'utils'

export const HotelDetailsOverlay = loadModule<{isOpen: boolean}>(
  async () =>
    import(
      /* webpackChunkName: "HotelDetailsOverlayDesktop" */
      './HotelDetailsOverlayDesktop'
    ),
  {}
)
