import React from 'react'

import {Box} from '@daedalus/atlas/helpers/Box'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

export const PropertySummaryLoading = () => (
  <Box width="100%">
    <Box width="100%" height="62px">
      <SkeletonLoader width={67} height={12} />
      <SkeletonLoader height={24} marginTop={4} width={100} />
      <SkeletonLoader height={20} marginTop={2} width={155} />
    </Box>
    <Box
      display="flex"
      marginTop="51px"
      alignItems="center"
      justifyContent="flex-start"
    >
      <SkeletonLoader height={32} width={32} />

      <Box marginLeft="s250">
        <SkeletonLoader width={55} height={20} />
        <SkeletonLoader width={79} height={15} marginTop={2} />
      </Box>
    </Box>
  </Box>
)
