import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import Settings from 'Settings'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'
import {BookingStatus} from '@daedalus/core/src/api-types/bovio/response/booking'
import {Bookings} from '@daedalus/core/src/api-types/bovio/response/bookings'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {getUserSessionService} from '@daedalus/core/src/auth/services'
import {useGetBookingsQuery} from '@daedalus/core/src/booking/services/bookApi'
import {BookingsFiltersValues} from '@daedalus/core/src/booking/types/BookingsFilterValues'
import {getBovioApiKey} from '@daedalus/core/src/booking/utils'
import {getLocalDeviceDate} from '@daedalus/core/src/utils/date'

const EMPTY_BOOKINGS: Bookings[] = []

export const useGetBookings = () => {
  const [idToken, setIdToken] = useState<string>()
  const appVersion = Settings.get('APP_VERSION')
  const appEnv = Settings.get('APP_ENV')

  const boVioEndpoint = Settings.get('CLIENT_BOVIO_ENDPOINT')
  const {brandCode} = useBrand()
  const apiKey = getBovioApiKey({
    brandCode,
    appEnv,
    urlParams: getQueryStringObject()
  })
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const headers = {
    Accept: 'application/json' as const,
    'X-Client-User-Agent': `home/${appVersion}`,
    'X-Api-Key': apiKey,
    'x-auth-token': idToken
  }

  const TODAY = getLocalDeviceDate()

  const params = {
    statuses: [BookingStatus.Confirmed, BookingStatus.ChargeLaterConfirmed],
    check_out_after: TODAY,
    sort: ['+check_in', '+inserted_at']
  }

  const result = useGetBookingsQuery(
    {
      link: `${boVioEndpoint}bookings/`,
      headers,
      params,
      filter: BookingsFiltersValues.Completed
    },
    {
      skip: Boolean(!idToken),
      selectFromResult: result => ({
        ...result,
        bookings: result.data ?? EMPTY_BOOKINGS
      })
    }
  )

  useEffect(() => {
    if (!idToken && isAuthenticated) {
      getUserSessionService()
        .then(resp => setIdToken(resp?.idToken.toString()))
        .catch(() =>
          console.error(
            'Cognito: Error fetching user session on bookings retrieval'
          )
        )
    }
  }, [idToken, isAuthenticated])

  return result
}
