'use client'

import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {DebugPanelLoader, DebugPanelModules} from '@vio/debug-panel'
import {AnimatePresence} from 'framer-motion'
import {useSyncOverlayState} from 'hooks/useSyncOverlayState'
import {
  Source,
  trackSystemPageErrored
} from 'middleware/analytics/actions/trackSystemPageErrored'
import {scenariosList} from 'mocks/scenarios'
import {getAnonymousId} from 'modules/meta/selectors'
import Accommodation from 'views/accommodation'
import Home from 'views/home'
import {Preferences} from 'views/preferences/Preferences'
import ProviderRedirect from 'views/providerRedirect'
import Search from 'views/search'
import User from 'views/user'

import {CognitoLogoutHandler} from '@daedalus/core/src/auth/components/CognitoLogoutHandler'
import {
  COGNITO_LOGIN_CALLBACK_ROUTE,
  COGNITO_LOGOUT_CALLBACK_ROUTE
} from '@daedalus/core/src/auth/constants'
import {experimentVariationsStore} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {CognitoPasswordlessLoader} from '@daedalus/shared/src/authentication/CognitoPasswordlessLoader'
import {useSUSIBottomSheet} from '@daedalus/shared/src/authentication/hooks/useSUSIBottomSheet'

import {
  ACCOMMODATION_ROUTE,
  HOME_ROUTE,
  PROFILE_ROUTE,
  PROVIDER_REDIRECT_ROUTE,
  SEARCH_ROUTE
} from './constants'

interface HomepageRedirectProps {
  source?: Source
}

const HomepageRedirect = ({source = Source.Routes}: HomepageRedirectProps) => {
  const dispatch = useDispatch()

  dispatch(
    trackSystemPageErrored({
      payload: {
        source,
        message: `Page not found: ${window.location.href}`
      }
    })
  )

  return <Redirect to="/" />
}

const DebugPanelWithOptimizelyExperiments = () => {
  const datafile = useSelector(getDataFile)
  const optimizelyFlags = datafile?.featureFlags?.map(({key}) => key) || []
  const anonymousId = useSelector(getAnonymousId)

  return (
    <DebugPanelLoader
      protectCode={[0, 0, 1, 1, 0, 1]}
      thirdPartyExperiments={optimizelyFlags}
      meta={{anonymousId}}
      scenarios={scenariosList}
      variationsStoreReader={experimentVariationsStore}
      modulesConfig={[
        DebugPanelModules.EXPERIMENTS,
        DebugPanelModules.SIMULATOR,
        DebugPanelModules.SETTINGS
      ]}
    />
  )
}

const Routes = () => {
  const history = useHistory()
  useSUSIBottomSheet()
  useSyncOverlayState()

  return (
    <AnimatePresence>
      <Switch>
        <Route path="/*.htm">
          {/* Catch legacy NFH URLs and redirect to homepage */}
          <HomepageRedirect source={Source.LegacyRoutes} />
        </Route>
        <Route exact path={HOME_ROUTE} component={Home} />
        <Route exact path={PROFILE_ROUTE}>
          <Preferences />
        </Route>
        <Route exact path={SEARCH_ROUTE} component={Search} />
        <Route path={ACCOMMODATION_ROUTE} component={Accommodation} />
        <Route path={PROVIDER_REDIRECT_ROUTE} component={ProviderRedirect} />
        <Route
          component={CognitoPasswordlessLoader}
          path={COGNITO_LOGIN_CALLBACK_ROUTE}
        />
        <Route
          render={props => (
            // @ts-expect-error TODO [react-router-upgrade] @dale-french fix this type
            <CognitoLogoutHandler {...props} history={history} />
          )}
          path={COGNITO_LOGOUT_CALLBACK_ROUTE}
        />

        <Route path="/user" component={User} />

        <Route path="/*debugPanel">
          <DebugPanelWithOptimizelyExperiments />
        </Route>
        <Route>
          <HomepageRedirect />
        </Route>
      </Switch>
    </AnimatePresence>
  )
}

export default Routes
