import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '@daedalus/core/src/_web/utils/persistence'

import {
  ParsePriceWatchKeyParams,
  PriceWatchData,
  PriceWatchEntry
} from '../types'

const PRICE_WATCH_STORAGE_KEY_V1 = 'priceWatch'
const PRICE_WATCH_STORAGE_KEY_V2 = 'priceWatchV2'

const getPriceWatchV1Keys = () => {
  const keys = safelyGetStorageItem(
    localStorage,
    PRICE_WATCH_STORAGE_KEY_V1,
    'getPriceWatchV1Keys failed'
  )
  return keys ? (JSON.parse(keys) as Record<string, string>) : {}
}

export const getPriceWatchV2Keys = () =>
  JSON.parse(
    safelyGetStorageItem(
      localStorage,
      PRICE_WATCH_STORAGE_KEY_V2,
      'getPriceWatchV2 failed'
    ) || 'null'
  ) || migratePriceWatchToV2({})

export const persistPriceWatch = (
  priceWatchKey: string,
  data?: PriceWatchEntry
) => {
  const priceWatchV2Keys = getPriceWatchV2Keys()
  if (!data) delete priceWatchV2Keys[priceWatchKey]
  else priceWatchV2Keys[priceWatchKey] = data

  return safelySetStorageItem(
    localStorage,
    PRICE_WATCH_STORAGE_KEY_V2,
    JSON.stringify(priceWatchV2Keys),
    'persistPriceWatchV2 failed'
  )
}

export const migratePriceWatchToV2 = (existingV2Keys: PriceWatchData) => {
  const oldKeys = getPriceWatchV1Keys()

  const migratedKeys = Object.entries(oldKeys).reduce(
    (acc, [key, id]) => {
      if (!acc[key]) {
        const [checkIn, nights, hotelId, rooms, currency] = key.split('#')
        acc[key] = {
          id,
          checkIn,
          nights: parseInt(nights, 10),
          hotelId,
          rooms,
          currency,
          enabled: true
        }
      }
      return acc
    },
    {...existingV2Keys}
  )

  safelySetStorageItem(
    localStorage,
    PRICE_WATCH_STORAGE_KEY_V2,
    JSON.stringify(migratedKeys),
    'migratePriceWatchToV2 failed'
  )

  return migratedKeys
}

export const parsePriceWatchKey = ({
  checkIn,
  nights,
  hotelId,
  rooms,
  currency
}: ParsePriceWatchKeyParams) =>
  `${checkIn}#${nights}#${hotelId}#${rooms}#${currency}`
