import React, {lazy, Suspense} from 'react'

interface Props {
  component: string
}

const LightboxGalleryOverlay = lazy(
  () =>
    import(
      /* webpackChunkName: "LightboxGalleryOverlay" */
      /* webpackPrefetch: true */
      './LightboxGalleryWithOffer'
    )
)

export const LightboxGalleryWithOffer = ({component}: Props) => (
  <Suspense fallback={null}>
    <LightboxGalleryOverlay component={component} />
  </Suspense>
)
