export const HOME_ROUTE = '/'
export const SEARCH_ROUTE = '/Hotel/Search'
export const PROVIDER_REDIRECT_ROUTE = '/ProviderRedirect'
export const PROFILE_ROUTE = '/Profile'
export const ALERTS_ROUTE = '/user/alerts'
export const MEMBERSHIP_ROUTE = '/user/membership'
export const ACCOUNT_ROUTE = '/user/account'
export const REFERRAL_ROUTE = '/user/membership/referral'

export const ACCOMMODATION_ROUTE = '/Hotel/:hotelSlug'

export const VIO_OFFER_CLICKED = 'vio_offer_clicked'
