import {getCookie} from '../../_web/utils/cookies'
import {cookieSecuritySettings} from '../../_web/utils/cookies/cookieSecuritySettings'
import {safelyGetStorageItem} from '../../_web/utils/persistence'

const maxAge = 365 * 2 // 2 years
const name = 'vclid'

export const vclidCookieConfig = {
  name,
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}

// getting vclid value from sessionStorage and cookieStorage
export const getPersistedVclidValue = (): string | undefined => {
  return (
    safelyGetStorageItem(
      sessionStorage,
      vclidCookieConfig.name,
      'vclid failed'
    ) || getCookie(vclidCookieConfig.name)
  )
}
