import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import atob from 'atob'
import TrackPageView from 'components/TrackPageView'
import externalRedirect from 'middleware/navigation/actions/externalRedirect'
import {toggle} from 'opticks'
import {ProviderRedirectQueryParamsType} from 'types/ProviderRedirect'
import {url} from 'utils'
import {isUrlWhitelisted} from 'utils/url'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {Page} from '@daedalus/core/src/analytics/types/Events'

import ProviderRedirect from './ProviderRedirect'

const REDIRECT_TIME = toggle('5de0338e-provider-redirect', 4000, 0) // ms

const ProviderRedirectView = () => {
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const urlParams = url.parse(
    location.search
  ) as ProviderRedirectQueryParamsType
  const dispatch = useDispatch()
  const {brand} = useBrand()
  const brandProviderCode = brand.providerCode
  const externalRedirectURL = atob(urlParams.redirectURI)
  const urlIsValid = isUrlWhitelisted(externalRedirectURL)

  useEffect(() => {
    if (urlIsValid) {
      window.setTimeout(
        () => dispatch(externalRedirect(externalRedirectURL)),
        REDIRECT_TIME
      )
    }
  }, [externalRedirectURL, urlIsValid])

  return (
    <>
      <TrackPageView pageName={Page.ProviderRedirect} />
      <InjectPageDataId page={Page.ProviderRedirect} />

      {urlIsValid ? (
        toggle(
          '5de0338e-provider-redirect',
          <ProviderRedirect
            // TODO: Search TS preexisting issue
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params={urlParams}
            brandProviderCode={brandProviderCode}
          />,
          <></>
        )
      ) : (
        <div>error during redirect</div>
      )}
    </>
  )
}

export default ProviderRedirectView
