import {useSelector} from 'react-redux'
import {getIsAnchorHotel} from 'modules/sapiSearch/selectors'
import {HotelOfferEntity} from 'modules/sapiSearch/slice'
import {getAllOffersParameters} from 'modules/searchApi/selectors'
import {RootState} from 'store'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {
  GetOffersParams,
  OffersState,
  useGetOffersQuery
} from '@daedalus/core/src/sapi/services/searchApi'
import {SearchParameters} from '@daedalus/core/src/sapi/types'

interface Props {
  hotelId: string | undefined
  skip?: boolean
}

interface ReturnValue {
  offerEntity: HotelOfferEntity
  isComplete: boolean
  searchedParameters: GetOffersParams | SearchParameters
}

export const useAllOffers = ({hotelId, skip}: Props): ReturnValue => {
  const {isDesktop} = useDeviceLayout()
  const isAnchor = useSelector(state => getIsAnchorHotel(state, hotelId))

  // Use filters in URL parameters for the desktop overlay case, but not for the anchor hotel.
  const searchParameters = useSelector((state: RootState) =>
    getAllOffersParameters(state, hotelId, isDesktop && !isAnchor)
  )

  const {data} = useGetOffersQuery(searchParameters, {
    skip: skip || !hotelId
  })

  return {
    offerEntity: data?.offerEntity,
    isComplete: data?.status === OffersState.Completed,
    searchedParameters: searchParameters
  }
}
