import {SearchType} from 'modules/sapiSearch/utils'

import {COMMON_SORT_LABELS, SORT_BY_WITH_DISTANCE} from './constants'

export const getSortByLabels = () => {
  return COMMON_SORT_LABELS
}

export const getFilterKey = (sortField: string, sortOrder: string) =>
  SORT_BY_WITH_DISTANCE.find(
    filter => filter.sortField === sortField && filter.sortOrder === sortOrder
  )

export const shouldShowSortByWithDistance = (searchType: SearchType) =>
  [SearchType.Hotel, SearchType.Place, SearchType.Location].includes(searchType)
