import {createSelector, createSlice} from '@reduxjs/toolkit'
import {RootState} from 'store'
import {getPopupOnExitShown} from 'utils/persistence'

export interface PopupOnExitState {
  hasBeenShown: boolean
  isOpen: boolean
}

const initialState: PopupOnExitState = {
  hasBeenShown: getPopupOnExitShown(),
  isOpen: false
}

const {actions, reducer} = createSlice({
  name: 'popupOnExit',
  initialState,
  reducers: {
    showPopupOnExit: state => {
      if (!getPopupOnExitShown()) {
        return {
          ...state,
          isOpen: true,
          hasBeenShown: true
        }
      }
    },
    hidePopupOnExit: state => {
      return {
        ...state,
        isOpen: false
      }
    }
  }
})

const getPopupOnExitVisibilityValue = (state: RootState) =>
  Boolean(state.popupOnExit?.isOpen) || false

export const getPopupOnExitVisibility = createSelector(
  [getPopupOnExitVisibilityValue],
  popupOnExitVisibility => popupOnExitVisibility
)

export const getPopupOnExitShownState = (state: RootState): boolean => {
  return Boolean(state.popupOnExit?.hasBeenShown)
}

export const {showPopupOnExit, hidePopupOnExit} = actions

export default reducer
