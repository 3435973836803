import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getLocalizationIsInitialized} from 'modules/meta/selectors'
import {initializeLocalizationConfig} from 'modules/meta/slice'

const InitializeMeta = ({children}) => {
  const dispatch = useDispatch()
  const localizationIsInitialized = useSelector(getLocalizationIsInitialized)

  useEffect(() => {
    if (!localizationIsInitialized) dispatch(initializeLocalizationConfig())
  }, [dispatch, localizationIsInitialized])

  return localizationIsInitialized ? children : null
}

export default InitializeMeta
