import {
  PLUS_OFFERS_TOKEN,
  SIGN_UP_TOKEN
} from '@daedalus/core/src/offer/business/privateDeals'

export enum LayoutType {
  /** Split view - renders both list and map */
  SPLIT = 'split',
  /** List view - renders list view only */
  LIST = 'list',
  /** Map view - renders map only */
  MAP = 'map'
}

export enum MapDisplayAction {
  /** Map should not be visible on the page */
  HIDE = 'hide',
  /** Map should be visible on the page */
  SHOW = 'show',
  /** Map should be expanded */
  EXPAND = 'expand',
  /** Map should be minimize */
  MINIMIZE = 'minimize'
}

export type SortField =
  | 'price'
  | 'popularity'
  | 'privateDeals'
  | 'distance'
  | 'travelHack'
  | 'guestRating'
  | 'starRating'
export type SortOrder = 'ascending' | 'descending'

export type FilterUrlParam = number | string | string[] | BooleanUrlParam

export interface FilterUrlParams {
  chainIds?: string[]
  facilities?: string[]
  freeCancellation?: BooleanUrlParam
  reviewScore?: string
  hotelName?: string
  noHostels?: BooleanUrlParam
  priceMax?: number
  priceMin?: number
  propertyTypes?: string[]
  starRatings?: string[]
  themes?: string[]
  sortField?: SortField
  sortOrder?: SortOrder
  amenities?: string[]
  offerFilters?: string
  roomFilters?: string
  payLater?: BooleanUrlParam
}

export interface SortUrlParams {
  sortField: SortField
  sortOrder: SortOrder
}

// TODO: dedupe with SearchParamsType in types/search (root types)
export interface SearchUrlParams
  extends FilterUrlParams,
    Partial<SortUrlParams> {
  anonymousId?: string
  boundingBox?: Array<string | number>
  checkIn?: string
  checkInDistance?: number
  checkOut?: string
  clickId?: string
  openSearchBox?: string
  currency?: string
  epv?: string
  esd?: string
  forceCurrencyChange?: BooleanUrlParam
  forceLanguageChange?: BooleanUrlParam
  fsmr?: string
  homeSearch?: BooleanUrlParam
  hotelId?: string
  hotelSlug?: string
  label?: string
  lang?: string
  layout?: LayoutType
  mlos?: number
  nights?: number
  optimizeAnchorRooms?: BooleanUrlParam
  optimizeRooms?: BooleanUrlParam
  placeId?: string
  profile?: string
  redirectId?: string
  rooms?: string
  sbc?: string
  scrollAnchorId?: string
  selectedOfferId?: string
  token?: typeof SIGN_UP_TOKEN | typeof PLUS_OFFERS_TOKEN
  userInteractedWithFilters?: BooleanUrlParam
  userLocationSearch?: BooleanUrlParam
  userSearch?: BooleanUrlParam
  utm_source?: string
  includeTaxes?: '0' | '1'
  showTotal?: '0' | '1'
  clickedOffer?: string
  clickedBundle?: string
  clickedBase?: number
  clickedTaxes?: number
  clickedFees?: number
  clickedProvider?: string
  address?: string
  clickedOfferRate?: number
  deviceId?: string
  lat?: string
  lon?: string
  isAnchorHotelSelectedInSRP?: string
  isAlertsManagementSearch?: string
}
