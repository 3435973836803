import React from 'react'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

export const PrimaryRoomOfferLoading = () => (
  <ContentWrapper padding="s000">
    <Flex.Row justifyContent="space-between">
      <div>
        <SkeletonLoader height={22} width={118} />
        <SkeletonLoader height={22} width={161} />
        <SkeletonLoader height={22} width={118} />
      </div>
      <div>
        <SkeletonLoader height={22} width={140} />
        <SkeletonLoader height={22} width={140} />
        <SkeletonLoader height={50} width={140} />
      </div>
    </Flex.Row>
  </ContentWrapper>
)
