import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import {UnavailabilityDesktop} from 'components/Unavailability/Desktop'
import {useGetHotelAvailabilityForCurrentSearch} from 'hooks/useGetHotelAvailabilityForCurrentSearch'
import {
  getAnchorComplete,
  getHotelHasOffers,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {setActiveHotelId} from 'modules/search/slice'
import {toggle} from 'opticks'

import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'

import {AccommodationPageLink} from './AccommodationPageLink'
import {BaseHotelCard} from './BaseHotelCard'
import {Gallery} from './Gallery'
import {OfferList} from './OfferList'
import {PropertySummary} from './PropertySummary/PropertySummary'
import {TopOffer} from './TopOfferInfo/TopOffer'

interface Props {
  shouldShowDivider?: boolean
  isPriorityCard?: boolean
}

export const HotelCardDesktop: React.FC<Props> = ({
  shouldShowDivider = true,
  isPriorityCard = false
}) => {
  const dispatch = useDispatch()
  const {isAnchor, hotelId, isHotelDetailsOverlay} =
    useContext(HotelCardContext)

  const hotelHasOffers = useSelector(state => getHotelHasOffers(state, hotelId))
  const showDivider = !isHotelDetailsOverlay && !isAnchor && shouldShowDivider
  const anchorComplete = useSelector(getAnchorComplete)

  const {hasSplitBookingOffer} = useSelector(state =>
    getSplitBookingDetails(state, hotelId)
  )

  const onMouseEnter = () => dispatch(setActiveHotelId(hotelId))
  const onMouseLeave = () => dispatch(setActiveHotelId(null))

  useGetHotelAvailabilityForCurrentSearch({isAnchor, hotelId})

  const shouldShowAvailabilityPane =
    anchorComplete && isAnchor && !hotelHasOffers && !hasSplitBookingOffer

  // Added as part of feature-static-srp flag
  const isStaticSRPFeatureEnabled = toggle('feature-static-srp', false, true)

  return (
    <Box
      data-id={isAnchor ? 'anchorHotel' : 'nonAnchorHotel'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      marginRight="auto"
      marginBottom={isAnchor && !isHotelDetailsOverlay ? 's500' : 's000'}
    >
      <BaseHotelCard
        isAnchor={isAnchor}
        galleryComponent={
          <AccommodationPageLink>
            <Gallery isPriorityCard={isPriorityCard} />
          </AccommodationPageLink>
        }
        propertySummaryComponent={
          <AccommodationPageLink>
            <PropertySummary />
          </AccommodationPageLink>
        }
        topOfferComponent={!isStaticSRPFeatureEnabled && <TopOffer />}
        offerListComponent={!isStaticSRPFeatureEnabled && <OfferList />}
        availabilityPaneComponent={
          shouldShowAvailabilityPane ? (
            <UnavailabilityDesktop sourceComponentPrefix={'HotelCardDesktop'} />
          ) : null
        }
      />
      {showDivider && (
        <ContentWrapper paddingY="s300">
          <Divider sideMargin="s400" />
        </ContentWrapper>
      )}
    </Box>
  )
}
