import {isReactNativeWebView} from '@daedalus/core/src/native'
import {parseQueryString} from '@daedalus/core/src/utils/url'

/**
 * Returns whether the app is running in a native webview or not
 * Also checks if the forceMobileApp query parameter is present
 * @returns boolean
 */
export const isNativeWebView = (): boolean => {
  const {forceMobileApp} = parseQueryString(location.search)

  return isReactNativeWebView() || Number(forceMobileApp) === 1
}
