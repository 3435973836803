import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getLocalTaxesIncluded, getTaxesIncluded} from 'modules/meta/selectors'
import {showOverlay} from 'modules/overlay/slice'
import {OverlayType} from 'modules/overlay/types'
import {
  getAnchorHotel,
  getFirstHotel,
  getHotelHasOffers,
  getOfferEntity,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'
import {toggle} from 'opticks'
import {
  getCheapestOfferTotalRate,
  getCheapestTopOfferByNightlyRate
} from 'utils/offers'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {FlowType} from '@daedalus/core/src/priceWatch/analytics'

import {SAW_PRICE_WATCH_OVERLAY} from '../services'

export const usePriceWatchWebOverlayTrigger = () => {
  // Depending on how fast the user scrolls, the shouldOpenPriceWatchOverlayOnScroll can be called more than once
  // So the wasCalled state prevents calling the showOverlay more than once
  const dispatch = useDispatch()
  const [wasCalled, setWasCalled] = useState(false)
  const firstHotel = useSelector(getFirstHotel)
  const anchorHotel = useSelector(getAnchorHotel)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const sawPriceWatchOverlay = Boolean(getCookie(SAW_PRICE_WATCH_OVERLAY))

  const hotelId = anchorHotel?.objectID || firstHotel?.objectID

  const hotelHasOffers = useSelector(state => getHotelHasOffers(state, hotelId))

  const offerEntity = useSelector(state => getOfferEntity(state, hotelId))

  const cheapestOffer = getCheapestTopOfferByNightlyRate(offerEntity?.offers)
  const cheapestTotalRate = getCheapestOfferTotalRate(offerEntity?.offers)
  const shouldShowTotal = useSelector(getShowTotalPrices)
  const localTaxesIncluded = useSelector(getLocalTaxesIncluded)
  const taxesIncluded = useSelector(getTaxesIncluded)

  const rate = {
    baseRate: cheapestOffer?.rate.base,
    localTaxes: cheapestOffer?.rate.hotelFees,
    taxes: cheapestOffer?.rate.taxes,
    nightlyRate: cheapestOffer?.nightlyRate,
    totalRate: cheapestTotalRate,
    localTaxesIncluded,
    taxesIncluded,
    currency: cheapestOffer?.currency,
    shouldShowTotal: shouldShowTotal
  }

  const isHoldOutGroup = toggle(
    'a87807d8-price-watch-toggle-holdout',
    true,
    false
  )

  const openPriceWatchOverlayOnScroll = () => {
    setWasCalled(true)

    if (
      !wasCalled &&
      !sawPriceWatchOverlay &&
      !isAuthenticated &&
      !isReactNativeWebView() &&
      !isHoldOutGroup &&
      hotelHasOffers
    ) {
      dispatch(
        showOverlay({
          type: OverlayType.PriceWatchOverlay,
          params: {
            hotel: anchorHotel || firstHotel,
            rate,
            flow: FlowType.SRP_SCROLL
          }
        })
      )
    }
  }

  return {
    openPriceWatchOverlayOnScroll
  }
}
