import {useSelector} from 'react-redux'
import {useCachedSearchTopOffers} from 'components/Accommodation/Offers/useCachedSearchTopOffers'
import {
  buildRoomContext,
  buildRoomOfferContext,
  getOfferContextFromRoomContext
} from 'middleware/analytics/contexts/roomContext'
import {getRoomContext} from 'middleware/analytics/selectors'
import {getAccommodationPageHotelId} from 'modules/accommodation/selectors'
import {getAnchorPrice} from 'modules/sapiSearch/utils'
import {getFilteredRoomsData} from 'modules/searchApi/selectors'

import {
  AnalyticsContext,
  type AnalyticsContextList
} from '@daedalus/core/src/analytics/types/Events'
import {getIsPrivateDeal} from '@daedalus/core/src/auth/modules/selectors'

interface Params {
  id: string
  isSplitBooking?: boolean
}

export const useGetRoomOfferAnalyticsContexts = ({
  id,
  isSplitBooking = false
}: Params) => {
  const {splitBooking} = useSelector(getFilteredRoomsData)

  const firstSbOffer = splitBooking?.offers?.[0]?.offer
  const hotelId = useSelector(getAccommodationPageHotelId)
  const {rooms} = useSelector(getFilteredRoomsData)
  const room = rooms?.find(({offers}) =>
    offers.some(({id: offerId}) => offerId === id)
  )
  const regularRoomContext = useSelector(state =>
    getRoomContext(state, room?.id)
  )

  const isPrivateDeal = useSelector(getIsPrivateDeal(firstSbOffer))
  const {offerEntity} = useCachedSearchTopOffers({
    hotelId
  })

  if (!rooms.length && !splitBooking) {
    return null
  }

  const regularOfferContext = regularRoomContext
    ? getOfferContextFromRoomContext(regularRoomContext, id)
    : null

  const firstSbOfferRoom = splitBooking?.offers?.[0]?.room
  const anchorPrice = getAnchorPrice(offerEntity)

  const sbRoomContext = isSplitBooking
    ? buildRoomContext(firstSbOfferRoom, 0, [])
    : null
  const sbOfferContext = isSplitBooking
    ? buildRoomOfferContext(
        firstSbOffer,
        1,
        firstSbOfferRoom,
        1,
        isPrivateDeal,
        anchorPrice
      )
    : null

  const contexts: AnalyticsContextList = {
    [AnalyticsContext.RoomContext]: isSplitBooking
      ? sbRoomContext
      : regularRoomContext,
    [AnalyticsContext.OfferContext]: {
      ...(isSplitBooking ? sbOfferContext : regularOfferContext),
      isRoomOffer: true
    },
    ...(isSplitBooking ? {[AnalyticsContext.SplitBooking]: splitBooking} : {})
  }

  return contexts
}
