import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getSearchParameters} from 'modules/sapiSearch/selectors'
import {getMlos} from 'modules/search/selectors'

import {Alert} from '@daedalus/atlas/Alert'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import OpacityAnimation from '@daedalus/atlas/helpers/OpacityAnimation'
import {Icon} from '@daedalus/atlas/Icon'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'

export const MLosAlert = () => {
  const {formatDate} = useIntl()
  const mlos = useSelector(getMlos)
  const searchParams = useSelector(getSearchParameters)

  const [isClosedByUser, setIsClosedByUser] = useState<boolean>(false)

  const handleCloseClick = () => {
    setIsClosedByUser(true)
  }

  if (!mlos || isClosedByUser) return null

  return (
    <OpacityAnimation delay={1500} duration={0.5}>
      <ContentWrapper paddingBottom="s500">
        <Alert icon={<Icon name="Information" />} onClose={handleCloseClick}>
          <FormattedMessageWrapper
            id="minStayAdjustedDates"
            defaultMessage="This property has a minimum stay of {mlos} nights. We have adjusted your original dates to {checkIn} - {checkOut} to accommodate it."
            values={{
              mlos,
              checkIn: formatDate(
                dateStringToMiddayDate(searchParams?.checkIn),
                {
                  day: '2-digit',
                  month: 'short'
                }
              ),
              checkOut: formatDate(
                dateStringToMiddayDate(searchParams?.checkOut),
                {
                  day: '2-digit',
                  month: 'short'
                }
              )
            }}
          />
        </Alert>
      </ContentWrapper>
    </OpacityAnimation>
  )
}
