import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'Offers simulations',
  value: 'Offers',
  description:
    'Scenarios for the offers long polling endpoint, including prices, cancellation policies, room types, etc.',
  routes: [
    {
      method: RequestMethod.GET,
      endpoint: EndpointNames.Offers,
      page: Pages.SRP,
      variations: [
        {
          value: 'anchorSoldOut',
          displayName: 'Anchor hotel is sold out',
          description: 'Only the Anchor Hotel is fully sold out'
        },
        {
          value: 'firstOfferIsGem',
          displayName: '1st offer is a Gem offer',
          description:
            'The first offer has the "engineered_plus" tag, which renders as a gem offer'
        },
        {
          value: 'firstOfferIsGemHBD',
          displayName: '1st offer is a Gem offer from HBD',
          description:
            'The first offer has the "engineered_plus" tag, which renders as a gem offer, and is from HBD'
        },
        {
          value: 'firstOfferIsNotCheapest',
          displayName: '1st offer is not cheapest',
          description:
            'The first offer known as "Primary offer" is not the cheapest'
        },
        {
          value: 'firstOfferIsPreferred',
          displayName: '1st offer is a preferred rate',
          description:
            'The first offers has the tag "preferred_rate" which renders as "Your choice" in the UI'
        },
        {
          value: 'firstOfferIsWeb2App',
          displayName: '1st Offer is Web2App',
          description:
            'The first Offer has the same Id as Offer inside the "promos" object'
        },
        {
          value: 'noOffersSRP',
          displayName: 'No offers at all SRP',
          description: 'Everything is unavailable'
        },
        {
          value: 'noWeb2AppOffers',
          displayName: 'No Web2App Offers at all',
          description: 'No Web2App Offers at all'
        },
        {
          value: 'onlyAnchorHotel',
          displayName: 'Only anchor hotel as result',
          description:
            'You will only see the anchor hotel as result, no other ones'
        },
        {
          value: 'primarySingleFlowSplitBooking',
          displayName: 'Primary offer single flow Splitbooking',
          description: 'Primary offer single flow Splitbooking'
        },
        {
          value: 'primarySplitBooking',
          displayName: 'Primary offer Split Booking',
          description:
            'The Primary offer is a Split Booking offer - please note that split booking offers only work for hotels'
        },
        {
          value: 'secondOfferIsGem',
          displayName: '2nd offer is a Gem offer',
          description:
            'The second offer has the "engineered_plus" tag, which renders as a gem offer'
        },
        {
          value: 'secondOfferIsGHA',
          displayName: '2nd offer is from GHA',
          description: 'The second offers is from GHA'
        },
        {
          value: 'secondOfferIsPreferred',
          displayName: '2nd offer is a preferred rate',
          description:
            'The second offers has the tag "preferred_rate" which renders as "Your choice" in the UI'
        },
        {
          value: 'secondOfferIsWeb2App',
          displayName: '2nd Offer is Web2App',
          description:
            'The second Offer has the same Id as Offer inside the "promos" object'
        },
        {
          value: 'secondarySingleFlowSplitBooking',
          displayName: 'Secondary offer single flow Splitbooking',
          description: 'Secondary offer single flow Splitbooking'
        },
        {
          value: 'secondarySplitBooking',
          displayName: 'Secondary offer Split Booking',
          description:
            'The Secondary offer is a Split Booking offer - please note that split booking offers only work for hotels'
        },
        {
          value: 'thirdOfferIsGem',
          displayName: '3rd offer is a Gem offer',
          description:
            'The third offer has the "engineered_plus" tag, which renders as a gem offer'
        },
        {
          value: 'thirdOfferIsPreferred',
          displayName: '3rd offer is a preferred rate',
          description:
            'The third offers has the tag "preferred_rate" which renders as "Your choice" in the UI'
        },
        {
          value: 'thirdOfferIsWeb2App',
          displayName: '3rd Offer is Web2App',
          description:
            'The third Offer has the same Id as Offer inside the "promos" object'
        },
        {
          value: 'oneOffer',
          displayName: 'Only one offer',
          description: 'Only one offer available for the first hotel'
        },
        {
          value: 'twoOffers',
          displayName: 'Only two offers',
          description: 'Only two offers available for the first hotel'
        },
        {
          value: 'threeOffers',
          displayName: 'Only three offers',
          description: 'Only three offers available for the first hotel'
        },
        {
          value: 'fourOffers',
          displayName: 'Only four offers',
          description: 'Only four offers available for the first hotel'
        }
      ]
    },
    {
      method: RequestMethod.GET,
      endpoint: EndpointNames.Offers,
      page: Pages.HP,
      variations: [
        {
          value: 'noOffersHP',
          displayName: 'No offers at all HP',
          description: 'Everything is unavailable'
        }
      ]
    }
  ]
}
