import {SplitBooking, SplitBookingDates} from '../../types/room'

export const getSplitBookingDates = (
  splitBooking: SplitBooking
): SplitBookingDates => {
  const {checkIn, checkOut: splitDate} = splitBooking.offers[0]
  const {checkOut} = splitBooking.offers[1]

  return {
    checkIn,
    splitDate,
    checkOut
  }
}
