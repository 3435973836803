import React from 'react'
import {useSelector} from 'react-redux'
import {selectIsCookiesAcceptedByUser} from 'modules/cookies/selectors'
import {getIsSyntheticTest} from 'modules/meta/selectors'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {GoogleOneTap} from '@daedalus/shared/src/authentication/GoogleOneTap'

export const GoogleOneTapWrapper = () => {
  const {brandIsVio} = useBrand()
  const isCookiesAcceptedByUser = useSelector(selectIsCookiesAcceptedByUser)
  const isSyntheticTest = useSelector(getIsSyntheticTest)

  if (!brandIsVio || isSyntheticTest) return null

  return <GoogleOneTap isCookiesAcceptedByUser={isCookiesAcceptedByUser} />
}
