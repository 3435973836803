import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getAnchorHotelId, getOfferEntity} from 'modules/sapiSearch/selectors'
import {getTopOffers} from 'modules/sapiSearch/utils'
import {getSerializedLandingQuery} from 'modules/search/selectors'

import {checkPriceChange} from '../utils/price'

interface useCheckPriceWatchMismatchProps {
  hotelId: string
  clickedOfferRate: number
}

type useCheckPriceWatchMismatchResponse = {
  isPriceDecrease: boolean
  isPriceIncrease: boolean
  priceDifference: number
  currentTopPrice: number
}

export const useCheckPriceWatchMismatch = ({
  hotelId,
  clickedOfferRate
}: useCheckPriceWatchMismatchProps): useCheckPriceWatchMismatchResponse => {
  const offerEntity = useSelector(state => getOfferEntity(state, hotelId))
  const serializedLandingQuery = useSelector(getSerializedLandingQuery)
  const priceWatchId = serializedLandingQuery?.priceWatchId ?? undefined
  const anchorHotelId = useSelector(getAnchorHotelId)
  const isAnchor = anchorHotelId === hotelId

  return useMemo(() => {
    if (!priceWatchId || !isAnchor)
      return {
        isPriceDecrease: false,
        isPriceIncrease: false,
        priceDifference: 0,
        currentTopPrice: 0
      }

    const topOffers = getTopOffers(false, offerEntity)

    const topOffer = topOffers?.sort((a, b) => a?.totalRate - b?.totalRate)?.[0]
      ?.totalRate

    const {isPriceIncrease, isPriceDecrease, priceDifference} =
      checkPriceChange({
        clickedOfferRate,
        currentTopOfferRate: topOffer,
        priceChangeThreshold: 2
      })

    return {
      isPriceDecrease,
      isPriceIncrease,
      priceDifference,
      currentTopPrice: topOffer
    }
  }, [clickedOfferRate, offerEntity, priceWatchId, isAnchor])
}
