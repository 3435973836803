import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {getPersistedSapiLabelWithFallback} from '@daedalus/core/src/_web/sapiLabel/business'
import {parseUrl} from '@daedalus/core/src/_web/utils/url'
import {isValidURL} from '@daedalus/core/src/utils/url'

import {
  getAnalyticsContext,
  getApiContext,
  getApplication,
  getIsFreezeOverlayOpen
} from '../../modules/selector'
import {setError} from '../../modules/slice'
import {
  DealFreezeEndpoints,
  useOfferCheckQuery
} from '../../services/dealFreezeApi'
import {constructOfferCheckQueryParams} from '../../utils/url'
import {DealFreezeErrorSource} from '../ErrorMessage/types'
interface Props {
  offerLink: string
}

const DEAL_FREEZE_OFFERS_PATH = 'offers/deal-freeze/'
const OFFER_CHECK_PATH = 'check'

export const OfferCheck = ({offerLink}: Props) => {
  const dispatch = useDispatch()
  const {boVioEndpoint} = useSelector(getApiContext)
  const isBottomSheetOpen = useSelector(getIsFreezeOverlayOpen)
  const {searchId} = useSelector(getApplication)
  const {isMobile} = useDeviceLayout()
  const {leadContext} = useSelector(getAnalyticsContext)
  const {redirectId} = leadContext || {}
  const isOfferLinkValid = isValidURL(offerLink)
  const label = getPersistedSapiLabelWithFallback()
  /**
   * We've observed that the offer link can sometimes be invalid,
   * so we should show an error when that happens because there is no way to proceed.
   */
  useEffect(() => {
    if (!isOfferLinkValid) {
      dispatch(
        setError({
          errored: true,
          message: 'SAPI offer link is invalid',
          component: DealFreezeErrorSource.FreezeOverlay,
          endpoint: DealFreezeEndpoints.SapiRoom
        })
      )
    }
  }, [dispatch, isOfferLinkValid])

  const {params, queryString} = constructOfferCheckQueryParams({
    offerLink,
    searchId,
    isMobile,
    anonymousId: getAnonymousId(),
    redirectId,
    label
  })

  const shouldSkip =
    !isOfferLinkValid ||
    (Object.values(params).some(value => value === undefined) &&
      !boVioEndpoint) ||
    !isBottomSheetOpen
  const {offer_token} = parseUrl(offerLink)?.searchObject || {}

  /**
   * Example URL:
   * offers/deal-freeze/723cce0c-fabc-4d65-a04d-29b105019524/check?anonymousId=67125316-b16d-4536-b809-76eedf11dca7&(rest of params)
   */
  const url = `${DEAL_FREEZE_OFFERS_PATH}${offer_token}/${OFFER_CHECK_PATH}?${queryString}`

  useOfferCheckQuery({url}, {skip: shouldSkip, refetchOnMountOrArgChange: true})

  return null
}
