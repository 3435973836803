import React from 'react'

import {Flex} from '@daedalus/atlas/helpers/Flex'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

import {
  BEST_DEAL_IMAGE_HEIGHT,
  ExitPopupContentWrapper,
  RatingLoadingWrapper
} from './styles'

export const ExitPopupSkeleton = () => {
  return (
    <ExitPopupContentWrapper>
      <SkeletonLoader height={26} />
      <SkeletonLoader height={BEST_DEAL_IMAGE_HEIGHT} />
      <SkeletonLoader height={12} width={67} marginTop={12} />
      <Flex.Row justifyContent="space-between" gap="s400">
        <div>
          <SkeletonLoader
            height={24}
            width={100}
            marginTop={4}
            marginBottom={2}
          />
          <SkeletonLoader height={20} width={155} />
        </div>
        <RatingLoadingWrapper
          justifyContent="space-between"
          alignItems="center"
        >
          <SkeletonLoader height={32} width={32} />
          <div>
            <SkeletonLoader height={20} width={55} />
            <SkeletonLoader height={15} width={79} />
          </div>
        </RatingLoadingWrapper>
      </Flex.Row>
    </ExitPopupContentWrapper>
  )
}
