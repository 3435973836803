import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'Hotel simulations',
  value: 'Hotel',
  description:
    'Scenarios for the hotel information endpoint, including the hotel name, address, and images (mostly triggered by AP, as the hotel information in SRP comes from the offer endpoint)',
  routes: [
    {
      endpoint: EndpointNames.Hotel,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'noImages',
          displayName: 'No hotel images',
          description:
            'The hotel has no images (might default back to the images from the search)'
        },
        {
          value: 'noSpInst',
          displayName: 'No special instructions for this hotel',
          description:
            'The hotel does not have any special instructions to render in the accommodation page'
        }
      ]
    }
  ]
}
