import {useEffect} from 'react'

import {getQueryStringObject} from '../../../_web/utils/url'
import {isReactNativeWebView} from '../../../native'
import {isRateCheckerFromUrl} from '../../../rateChecker/business'
import {loadFullStory} from '../../utils/FullStory'

interface Props {
  publicUrl: string
  isPartnersWidget?: boolean
}

export const FullStoryLoader = ({
  publicUrl,
  isPartnersWidget = false
}: Props) => {
  const searchParams = getQueryStringObject()
  const isRateChecker = isRateCheckerFromUrl(searchParams)

  useEffect(() => {
    const isMobileApp = isReactNativeWebView()

    if (isRateChecker || isMobileApp || isPartnersWidget) {
      // eslint-disable-next-line no-void
      void loadFullStory(publicUrl, 1500)
    }
  }, [isPartnersWidget, isRateChecker, publicUrl])

  return null
}
