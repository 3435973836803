import {useEffect} from 'react'

import {Page} from '../../types/Events'

/**
 * Injects a data-id attribute into the <body>. The value comes from the current Page/View
 */
export interface DataIdsProps {
  /** The current Page name used to identify the current view the user is browsing */
  page: Page | 'Success' | 'Checkout' | 'PostStayNps' | undefined
}

const InjectPageDataId = ({page}: DataIdsProps) => {
  useEffect(() => {
    if (page) {
      document.body.dataset.id = `${page}Page`
      return () => {
        delete document.body.dataset.id
      }
    }
  }, [page])

  return null
}

export default InjectPageDataId
