import {CookieConfig} from 'types/Cookie'

import {cookieSecuritySettings} from '../../utils/cookies/cookieSecuritySettings'

export const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

const options = {
  maxAge: 365 * 2, // 2 years
  ...cookieSecuritySettings(true)
}

export const visitsCountConfig = {
  name: 'visitsCount',
  options
}

export const lastVisitTimestampConfig = {
  name: 'lastVisitTimestamp',
  options
}
