import {
  logTimeEnd as polyfillLogTimeEnd,
  logTimeStart as polyfillLogTimeStart
} from './userTimingPolyfill'

declare let PerformanceTimers: Record<string, number>
declare global {
  interface Window {
    __PERFORMANCE_TIMERS__: typeof PerformanceTimers
  }
}

/**
 * Wrapper around performance mark / measure API to measure between now and
 * a given start marker. Uses feature detection and returns `null` if the performance API
 * or polyfills are not available.
 *
 * @param name - name of the end marker
 * @param measureStartName - name of start marker from which to calculate the measure
 * @returns duration of the measurement in ms or `null` for invalid measures
 */
const measure = (name: string, measureStartName: string): number | null => {
  if (!performance.mark && !window.__PERFORMANCE_TIMERS__) return null

  const measureEndName = `${name}-end`
  performance.mark(measureEndName)
  try {
    performance.measure(name, measureStartName, measureEndName)
  } catch (e) {
    // Do not report errors from measure, e.g. if the start marker doesn't exist
  }
  const entries = performance.getEntriesByName(name)
  const duration = entries.length && entries[0].duration
  if (!duration) return null

  performance.clearMarks(name)
  performance.clearMeasures(name)
  return Math.ceil(duration) // for now we're just interested in ms
}

/**
 * Wrapper to mark start markers, appends `-start` string after the mark automatically.
 *
 * @param name - name of the start marker
 */
export const logTimeStart = ((needPolyfill: boolean) =>
  needPolyfill
    ? polyfillLogTimeStart
    : (name: string) => performance.mark(`${name}-start`))(!performance.mark)

/**
 * Returns measure in ms between provided name and start marker, and logs the result to
 * the console.
 *
 * @example Measure from custom start marker
 * ```ts
 * logTimeStart('foo')
 * // 123ms later...
 * logTimeEnd('foo', 'foo-start') // 123
 * ```
 *
 * @example Measure from browser's built in markers
 * ```ts
 * // 456ms after responseStart...
 * logTimeEnd('PageLoad', 'responseStart') // 456
 * ```
 *
 * @param name - Name of the end marker
 * @param measureStartName - Name of start marker from which to calculate the measure
 * @param shouldResetStartMark - Whether to reset the start marker after measuring
 * @returns Duration of the measurement in ms or `null` for invalid measures
 */
export const logTimeEnd = ((needPolyfill: boolean) =>
  needPolyfill
    ? polyfillLogTimeEnd
    : (
        name: string,
        measureStartName = `${name}-start`,
        shouldResetStartMark = false
      ): number | null | undefined => {
        const measureResult = measure(name, measureStartName)
        console.log(
          `%cTiming ${name}: ${Number(measureResult)}ms`,
          'color: #9370DB'
        )
        if (shouldResetStartMark) performance.clearMarks(measureStartName)
        return measureResult
      })(!performance.mark)
