const measure = (start: number, end: number): number | null | undefined => {
  return Number((end - start).toFixed(0))
}

const measureEnd = (
  name: string,
  measureStartName: string
): number | null | undefined => {
  if (!window.__PERFORMANCE_TIMERS__) return null

  const timers = window.__PERFORMANCE_TIMERS__

  const startMarker: number = timers[measureStartName]
  if (!startMarker) return null

  const endMarker: number = Date.now()
  delete timers[name]

  return measure(startMarker, endMarker)
}

/**
 * This is a polyfill for {@link ./performanceTimer.logTimeStart}, please refer to that file for more info
 */
export const logTimeStart = (name: string) => {
  window.__PERFORMANCE_TIMERS__ = window.__PERFORMANCE_TIMERS__ || {}
  window.__PERFORMANCE_TIMERS__[name + '-start'] = Date.now()
}

/**
 * This is a polyfill for {@link ./performanceTimer.logTimeEnd}, please refer to that file for more info
 */
export const logTimeEnd = (
  name: string,
  measureStartName = `${name}-start`,
  shouldResetStartMark = false
): number | null | undefined => {
  console.log('Polyfilled measure')
  const measure = measureEnd(name, measureStartName)
  console.log(`%cTiming ${name}: ${Number(measure)}ms`, 'color: purple')
  if (shouldResetStartMark) {
    delete window.__PERFORMANCE_TIMERS__[measureStartName]
  }
  return measure
}
