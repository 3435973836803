import {matchPath} from 'react-router-dom'
import {SplitBookingDetails} from 'components/Offer/types'
import {ACCOMMODATION_ROUTE, PROVIDER_REDIRECT_ROUTE} from 'constants/index'
import {differenceInDays, parseISO} from 'date-fns'
import {HotelOfferEntity, SearchOffer} from 'modules/sapiSearch/slice'
import {
  addIndex,
  compose,
  contains,
  curry,
  flip,
  length,
  map,
  pick,
  pipe,
  prop,
  reject,
  slice,
  sort,
  values
} from 'ramda'
import {TopOfferDataType} from 'types/Hotel'
import {HotelType} from 'types/Hotel'
import {ProviderRedirectQueryParamsType} from 'types/ProviderRedirect'
import {url} from 'utils'

import {parseUrl} from '@daedalus/core/src/_web/utils/url'
import {increasePositionIndexByOne} from '@daedalus/core/src/analytics/utils/trackEventHelpers'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {
  isLockedDeal,
  PLUS_OFFERS_TOKEN,
  SIGN_UP_TOKEN
} from '@daedalus/core/src/offer/business/privateDeals'
import {
  BOFH_PROVIDER_CODES,
  providersMap
} from '@daedalus/core/src/offer/business/provider'
import {OfferTagLabel} from '@daedalus/core/src/offer/types/offer'
import {toDecreasePercentage} from '@daedalus/core/src/utils/number'
import {getUrlParam} from '@daedalus/core/src/utils/url'
import {OfferBookingUriParams} from '@daedalus/core/src/utils/url/types/UrlParams'
import {DeviceCategory} from '@daedalus/core/src/utils/userAgent/types'

interface BestTopOffer {
  topOfferIndex?: number
  price?: number
}

const VISIBLE_TOP_OFFERS = 3

export const getAnchorPrice = (
  topOfferData: TopOfferDataType | null | undefined
): number | null | undefined => topOfferData?.anchorPriceNightly

export const splitTopOffersAndRemainingOffers = (
  offers: SearchOffer[] | null | undefined,
  topOfferData: TopOfferDataType | null | undefined,
  numberOfOffersToShow: number
): {
  remainingOffers: SearchOffer[]
  topOffersToDisplay: SearchOffer[]
} => {
  if (!(offers?.length && topOfferData)) {
    return {topOffersToDisplay: [], remainingOffers: []}
  }

  // TODO: Move to utils
  const pickIndexes = compose(values, pick)
  const rejectIndexed = addIndex(reject)
  const containsIndex = curry((indexes, val, index) => contains(index, indexes))

  const omitIndexes = curry((indexes, list) =>
    rejectIndexed(containsIndex(indexes), list)
  )

  const topOfferIndexes = topOfferData?.offerIndexes || []
  const visibleTopOfferIndexes = topOfferIndexes.slice(0, numberOfOffersToShow)

  // TODO: TS - ramda
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let topOffersToDisplay = pickIndexes(visibleTopOfferIndexes, offers)

  const offersWithoutDisplayedTopOffers = omitIndexes(
    visibleTopOfferIndexes,
    offers
  )

  const missingAmountOfDisplayTopOffers =
    numberOfOffersToShow - topOffersToDisplay.length

  if (missingAmountOfDisplayTopOffers > 0) {
    topOffersToDisplay = topOffersToDisplay.concat(
      // modifying offersCopy on purpose to de-duplicate them
      offersWithoutDisplayedTopOffers.splice(0, missingAmountOfDisplayTopOffers)
    )
  }

  return {
    topOffersToDisplay,
    remainingOffers: offersWithoutDisplayedTopOffers
  }

  /* Assuming the topOffers are sorted properly this works fine :)
  const [topOffersToDisplay, remainingOffers] = splitAt(
    numberOfOffersToShow,
    offers
  )
   return { topOffersToDisplay, remainingOffers }
  */
}

/**
 * Returns the index of the cheapest top offer if there are any top offers,
 * otherwise it returns undefined
 */

export const getCheapestTopOfferIndex = (
  offers: SearchOffer[]
): number | undefined => {
  const topOfferIndexes = offers.reduce((topOffers, offer, index) => {
    const isTopOffer = hasOfferTag(offer, OfferTagLabel.IsTopOffer)
    if (isTopOffer) return topOffers.concat(index)
    return topOffers
  }, [])

  if (topOfferIndexes.length > 0) {
    return getCheapestTopOffer(topOfferIndexes, offers, true).topOfferIndex
  }
}

export const getCheapestTopOffer = (
  topOfferIndexes: number[],
  offers: SearchOffer[],
  isAuthenticated?: boolean
): BestTopOffer =>
  topOfferIndexes.reduce((bestOffer, topOfferIndex: number) => {
    const offer = offers[topOfferIndex]

    if (!offer) return bestOffer

    // Since the price is rounded when displayed, we need to round it before
    // comparing because otherwise in case of pairity in the rounded price,
    // the highest ranked offer may not be flagged as the cheapest one
    const bestOfferHasLowerPrice =
      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Math.round(bestOffer.price) <= Math.round(offer.nightlyRate)
    const isOfferLocked = isLockedDeal(offer, isAuthenticated)

    if (bestOfferHasLowerPrice || isOfferLocked) {
      return bestOffer
    }

    return {topOfferIndex, price: offer.nightlyRate}
  }, {})

/**
 * Determines and returns the cheapest non-locked, non-Vio and non-BKS offer from a set of offers.
 * It iterates through the given offers, comparing the nightly rate of each offer with the best one found so far,
 * ignoring any locked offers or offers from Vio (as determined by provider codes).
 *
 * @param offers - An array of offers to be evaluated.
 * @param isAuthenticated - A flag indicating if the user is authenticated.
 *
 * @returns SearchOffer | null | undefined The cheapest non-locked, non-Vio offer, or null if none found.
 *
 */
export const getCheapestSplitBookingOffer = (
  offers: SearchOffer[],
  isAuthenticated: boolean
): SearchOffer | null | undefined =>
  offers.reduce((bestOffer: SearchOffer | null | undefined, offer) => {
    if (!offer) return bestOffer

    const bestOfferHasHigherPrice =
      !bestOffer || bestOffer.nightlyRate > offer.nightlyRate
    const isOfferLocked = isLockedDeal(offer, isAuthenticated)

    if (
      bestOfferHasHigherPrice &&
      !isOfferLocked &&
      // exclude any BOFH offers
      !BOFH_PROVIDER_CODES.has(offer.providerCode) &&
      // exclude BKS offers
      offer.providerCode !==
        Object.keys(providersMap).find(
          key => providersMap[key] === providersMap.BKS
        )
    ) {
      // Current offer has a lower price and is not locked and not a vio offer, so it becomes the new bestOffer
      return offer
    }
    return bestOffer // Otherwise, keep the current bestOffer
  }, null) // Initializing bestOffer as null

// Finds hotel with cheapest visible top offer, and modifies topOfferData to
// return only this offer - used to show the cheapest offer in BestDealModal
export const getHotelWithCheapestVisibleTopOffer = (
  results: HotelType[],
  isAuthenticated: boolean | null | undefined
): HotelType | null | undefined => {
  if (!results) return null
  const bestOfferObject = results.reduce(
    (prevOfferObject: {bestPrice?: number; hotel?: HotelType}, hotel) => {
      if (!hotel || !hotel?.topOfferData || !hotel?.offers) {
        return prevOfferObject
      }

      const topOfferIndexes = hotel?.topOfferData?.offerIndexes?.length
        ? hotel?.topOfferData.offerIndexes
        : [0, 1, 2]

      const bestOfferFromVisible = getCheapestTopOffer(
        topOfferIndexes,
        hotel?.offers,
        isAuthenticated
      )

      if (
        prevOfferObject.bestPrice &&
        prevOfferObject.bestPrice <= bestOfferFromVisible.price
      ) {
        return prevOfferObject
      }

      // TODO - move the hotel assignment outside of the reduce
      return {
        bestPrice: bestOfferFromVisible.price,
        hotel: {
          ...hotel,
          topOfferData: {
            ...hotel.topOfferData,
            offerIndexes: [bestOfferFromVisible.topOfferIndex]
          }
        }
      }
    },
    {}
  )

  return bestOfferObject.hotel
}

const generateInternalRedirectLink = (
  params: ProviderRedirectQueryParamsType
) => {
  // Encodes the redirect URI into a base 64 string.
  // This is necessary because the `redirectURI` param is a full url, with
  // its own parameters. And encodeURI doesn't encode the hostname only the path
  const {redirectURI} = params

  const query = redirectURI
    ? {...params, redirectURI: btoa(redirectURI)}
    : params
  const queryStringedParams = url.stringify(query)

  return `${PROVIDER_REDIRECT_ROUTE}?${queryStringedParams}`
}

const generateBOFHRedirectLink = (params: ProviderRedirectQueryParamsType) => {
  return params.redirectURI
}

export const augmentBookURIWithSignUpTokenParam = (bookURI: string): string =>
  `${bookURI}&token=${SIGN_UP_TOKEN}`

export const augmentBookURIWithPlusTokenParam = (bookURI: string): string =>
  `${bookURI}&token=${PLUS_OFFERS_TOKEN}`

export const augmentBookURIWithUtmParams = (
  params: {[key: string]: string},
  bookURI: string
): string => {
  const utmQuery = Object.keys(params).reduce((prev, current) => {
    if (params[current]) {
      return `${prev}&${current}=${encodeURIComponent(params[current])}`
    }
    return prev
  }, '')

  return utmQuery ? `${bookURI}${utmQuery}` : bookURI
}

export const augmentBookURIWithOta = (ota: boolean, bookURI: string): string =>
  ota ? `${bookURI}&ota=1` : bookURI

export const augmentBookURIWithGsiteParam = (
  gsiteValue: string,
  bookURI: string
): string => `${bookURI}&gsite=${gsiteValue}`

export const augmentBookURIWithHotelCountry = (
  hotelCountry: string,
  bookURI: string
): string => `${bookURI}&hotelCountry=${hotelCountry}`

export const augmentBookUriWithUserCountry = (
  userCountry: string,
  bookURI: string
): string => `${bookURI}&userCountry=${userCountry}`

export const augmentBookURIWithClickId = (
  clickId: string,
  bookURI: string
): string => `${bookURI}&clickId=${clickId}`

export const augmentBookURIWithDeviceId = (
  deviceId: string,
  bookURI: string
): string => `${bookURI}&deviceId=${deviceId}`

export const augmentBookURIWithFreeCancellation = (
  freeCancellation: boolean,
  bookURI: string
): string => (freeCancellation ? `${bookURI}&freeCancellation=1` : bookURI)

export const augmentBookURIWithOptimizeRooms = (
  optimizeRooms: boolean,
  bookURI: string
): string => `${bookURI}&optimizeRooms=${optimizeRooms ? '1' : '0'}`

export const augmentBookURIWithProfileParam = (
  profile: string,
  bookURI: string
): string => `${bookURI}&profile=${profile}`

export const isSavingsDeal = (anchorPrice: number, price: number) => {
  const minimumPercentageSavedForDeals = 0.03
  return 1 - price / anchorPrice >= minimumPercentageSavedForDeals
}

export const generateRedirectLink = (
  redirectParams: ProviderRedirectQueryParamsType,
  isBofhRedirectHidden: boolean,
  isBofhOffer: boolean
) => {
  return isBofhOffer && isBofhRedirectHidden
    ? generateBOFHRedirectLink(redirectParams)
    : generateInternalRedirectLink(redirectParams)
}

export const getVisibleTopOffers = slice(0, VISIBLE_TOP_OFFERS)

export const getCheapestOfferNightlyRate = (offers: SearchOffer[]) =>
  (length(offers) && Math.min(...map(prop('nightlyRate'), offers))) || null

export const getCheapestOfferTotalRate = (offers: SearchOffer[]) =>
  (length(offers) && Math.min(...map(prop('totalRate'), offers))) || null

export const getMostExpensiveOfferNightlyRate = (offers: SearchOffer[]) =>
  (length(offers) && Math.max(...map(prop('nightlyRate'), offers))) || null

export const getCheapestTopOfferNightlyRate = (
  hit: HotelType
): number | null | undefined => {
  if (!hit?.offers?.length) return null

  const topOffers = getVisibleTopOffers(hit.offers)

  return getCheapestOfferNightlyRate(topOffers)
}

export const getCheapestTopOfferByNightlyRate = (
  offers: SearchOffer[]
): SearchOffer => {
  if (!offers?.length) return null

  const topOffers = getVisibleTopOffers(offers)

  return topOffers.find(
    offer => offer.nightlyRate === getCheapestOfferNightlyRate(topOffers)
  )
}

export const calculateSavingsPercentageByNightlyRate = (
  offers: SearchOffer[]
): number => {
  if (!offers || offers.length < 2) return 0

  const offerNightlyRates = offers.map(offer => offer.nightlyRate)
  const [firstLowest, secondLowest] = sort((a, b) => a - b, offerNightlyRates)

  const savingPercentage = toDecreasePercentage(firstLowest, secondLowest)

  return savingPercentage
}

export const getHighestVisiblePrice = (
  anchorPrice: number | null | undefined,
  offers: SearchOffer[],
  shouldSeeOffersUnlocked?: boolean
): number | undefined =>
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  anchorPrice ||
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  pipe<SearchOffer[]>(
    getVisibleTopOffers,
    // TODO: Search TS preexisting issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    reject(flip(isLockedDeal)(shouldSeeOffersUnlocked)),
    getMostExpensiveOfferNightlyRate
    // TODO: Search TS preexisting issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  )(offers)

/**
 * Return an offer position (index) by offerId from an offerEntity (starting from 0) and consider the split booking position
 * @param offerId - id of the offer
 * @param hotelOfferEntity - the offerEntity containing the offer
 * @param splitBookingDetails - split booking details
 * @param isHotelDetailsOverlayOpen - flag to check if the hotel details overlay is open
 */
export const getOfferPositionFromEntity = (
  offerId: string,
  hotelOfferEntity: HotelOfferEntity,
  splitBookingDetails: SplitBookingDetails,
  sourceComponent: string,
  deviceLayout: DeviceCategory
): {offerPosition: number; offerPositionFromOneWithSuffix: number | string} => {
  const {offers} = hotelOfferEntity || {}
  const {
    splitBookingPosition,
    hasSplitBookingOffer,
    splitBookingType,
    splitBookingOffers,
    offerPositionToRemove
  } = splitBookingDetails || {}
  const createResponse = (position: number, suffix = null) => {
    return {
      offerPosition: position,
      offerPositionFromOneWithSuffix: suffix
        ? `${increasePositionIndexByOne(position)}${suffix}`
        : increasePositionIndexByOne(position)
    }
  }

  if (!hasSplitBookingOffer) {
    const position = offers?.findIndex(offer => offer.id === offerId)
    return createResponse(position)
  }

  const sbOfferList = splitBookingOffers?.map(i => i.offer) ?? []
  const sbOfferIndex = splitBookingOffers?.findIndex(
    ({offer}) => offer.id === offerId
  )

  if (splitBookingType === 'single_flow') {
    // Click/View event: we don't show the single flow on the AP on mobile
    if (sourceComponent === 'MobileHotelViewTopOffers') {
      const position = offers?.findIndex(offer => offer.id === offerId)
      return createResponse(position)
    }

    // Click/View event: on the hotel details overlay on desktop the split booking offer is always at the top
    if (sourceComponent === 'DesktopOffersTab') {
      const position = [sbOfferList[0], ...offers].findIndex(
        o => o.id === offerId
      )
      return createResponse(position)
    }

    const filteredOffers = offers.filter((_, i) => i !== offerPositionToRemove)
    filteredOffers.splice(splitBookingPosition, 0, sbOfferList[0])

    const position = filteredOffers.findIndex(o => o.id === offerId)
    return createResponse(position)
  }

  // for split flow, determine the position to insert the sb offer based on the device layout and overlay state.
  // on desktop insert at position 3rd position (2) and for other cases insert at 4th (3)
  let splitFlowPosition = deviceLayout === 'desktop' ? 2 : 3

  // Click/View event: on the hotel details overlay on desktop the split booking offer is always at the 4th position (3)
  if (
    sourceComponent === 'DesktopOffersTab' ||
    sourceComponent === 'DesktopAllDealsViewSplitBookingOffersModal'
  ) {
    splitFlowPosition = 3
  }

  const offersWithSb = [...offers]
  offersWithSb.splice(splitFlowPosition, 0, sbOfferList[0])

  // always get the index of the first sb offer in the split booking to keep the same index for both sb offers
  if (sbOfferIndex >= 0) {
    const position = offersWithSb.findIndex(o => o.id === sbOfferList[0].id)
    return createResponse(position, sbOfferIndex > 0 ? 'b' : 'a')
  }

  const position = offersWithSb.findIndex(o => o.id === offerId)
  return createResponse(position)
}

/**
 * Factory function that return number of nights between checkIn and checkout
 * @param checkIn - checkIn date
 * @param checkOut - checkout date
 * @returns number
 */
export const getNumberOfNights = (checkIn: string, checkOut: string): number =>
  differenceInDays(parseISO(checkOut), parseISO(checkIn))

/**
 *
 * @param offerUrl
 * @returns
 */
export const getIsDirectSupplyFromOfferUrl = (offerUrl: string) => {
  const urlParams = new URLSearchParams(offerUrl)
  const ofd = urlParams?.get('ofd')
  const ofdParams = new URLSearchParams(ofd)
  const ds = ofdParams?.get('ds')

  if (!ds) return undefined

  return ds === 't'
}
/**
 * Parses and returns value of param by key from book_uri in offer url
 * @param url - SAPI offer.url
 * @param param - any key available in book_uri
 * @returns string | undefined
 */
export const getParamFromOfferBookURI = (
  url: string,
  param: keyof OfferBookingUriParams
): string | undefined => {
  const ofdParam = getUrlParam({
    search: parseUrl(url).search,
    param: 'ofd'
  }) as string

  const checkoutLink = ofdParam?.replace('book_uri=', '')
  const decodedCheckoutLink = decodeURIComponent(checkoutLink)
  return getUrlParam({
    search: parseUrl(decodedCheckoutLink).search,
    param
  }) as string
}

/**
 * Parses the checkout url from given offer link and retrieves
 * hotelSlug (hotelId) from the .../Hotel/xxx... path using same logic
 * as react-router and accommodation route as template to always match
 *
 * @param url SAPI offer.url
 * @returns hotel id
 */

export const getHotelIdFromOfferBookURIPath = (
  url: string
): string | undefined => {
  const ofdParam = getUrlParam({
    search: parseUrl(url).search,
    param: 'ofd'
  }) as string
  const checkoutLink = ofdParam?.replace('book_uri=', '')
  const decodedCheckoutLink = decodeURIComponent(checkoutLink)

  const data = parseUrl(decodedCheckoutLink)

  const match = matchPath(data.pathname, {
    path: ACCOMMODATION_ROUTE,
    exact: true,
    strict: false
  })

  return (match?.params as {hotelSlug?: string})?.hotelSlug || undefined
}

export const augmentBookURIWithAmenities = (
  amenities: string[],
  bookURI: string
): string =>
  amenities
    ? `${bookURI}${amenities.map(amenity => `&amenities=${amenity}`).join('')}`
    : bookURI
