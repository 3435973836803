import Settings from 'Settings'
import {RootState} from 'store'
import {MetaState} from 'types/State'
import {getLandingTrafficSourceFromUrl} from 'utils/trafficSource'

import {
  getOrGenerateAnonymousId,
  persistAnonymousId
} from '@daedalus/core/src/_web/anonymousId'
import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {
  getFirstVisit,
  persistFirstVisit
} from '@daedalus/core/src/_web/userConfig/userFirstVisit'
import {
  getVisitsCount,
  persistVisitsCount
} from '@daedalus/core/src/_web/userConfig/visitsCount'
import {getUrlParams} from '@daedalus/core/src/_web/utils/url'
import {getUserDeviceDetails} from '@daedalus/core/src/_web/utils/userAgent/device'

import {determineIsReturningUser} from './utils'

/**
 * Augment initial meta with client side props
 */
export const augmentMetaState = (
  initialState: Partial<RootState>
): MetaState => {
  const {devicePlatform, deviceCategory, userAgentDetails} =
    getUserDeviceDetails()

  const anonymousId = getOrGenerateAnonymousId()
  persistAnonymousId(anonymousId)
  persistVisitsCount()
  persistFirstVisit()
  const firstVisit = getFirstVisit()
  const isReturningUser = determineIsReturningUser(firstVisit)
  const visitsCount = getVisitsCount()
  const trafficSource = getLandingTrafficSourceFromUrl(
    initialState as RootState
  )
  const env = Settings.get('APP_ENV')
  const brandCode = getBrandCodeFromUrlOrCookie(env)
  const {deviceId} = getUrlParams() as {
    deviceId: MetaState['deviceId']
  }

  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    trafficSource,
    brandCode,
    anonymousId,
    devicePlatform,
    deviceCategory,
    userAgentDetails,
    isReturningUser,
    firstVisit,
    visitsCount,
    deviceId,
    // Allow tests to overwrite initial state
    ...(initialState?.meta || {})
  }
}

export {default} from './slice'
