import React, {useCallback} from 'react'
import {getAllFilterKeys} from 'components/Filters/utils'
import {emptyFilterState, useFiltersForm} from 'hooks/useFiltersForm'
import {getSapiFiltersFromDaedalusSearchParameters} from 'modules/sapiSearch/utils'

import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const allFilterKeys = getAllFilterKeys()

export const ClearAllPill = () => {
  const {trackFilterClick, clearFiltersAndApply, getAppliedFiltersCount} =
    useFiltersForm(allFilterKeys, 'clearAll')

  const count = getAppliedFiltersCount()

  const handleClear = useCallback(() => {
    const newSelectedFilters =
      getSapiFiltersFromDaedalusSearchParameters(emptyFilterState)

    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterPreference,
      action: Action.Submitted,
      payload: {
        newSelectedFilters: newSelectedFilters,
        allSelectedFilters: newSelectedFilters,
        appliedFiltersFrom: 'clearAll'
      }
    })
    clearFiltersAndApply()
  }, [clearFiltersAndApply, trackFilterClick])

  return count > 0 ? (
    <Pill onClick={handleClear} variant="link">
      <FormattedMessageWrapper id="clearAll" defaultMessage="Clear All" />
    </Pill>
  ) : null
}
