import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getOfferVariant} from 'components/Offer/Shared/utils'
import {OldHotelOfferEntity} from 'modules/sapiSearch/offersMapping'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'
import {
  getAnchorPrice,
  getAnchorPriceTotal,
  getTopOffers
} from 'modules/sapiSearch/utils'
import {getCheapestTopOfferIndex} from 'utils/offers'

import {getHasFreeCancellation} from '@daedalus/core/src/offer/business/cancellationPenalties'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'

const OFFER_TO_SHOW = 0

interface Props {
  offerEntity: OldHotelOfferEntity
  hotelId: string
  offerId?: string
  isOTAMode?: boolean
}

export const useOfferContainer = ({
  offerEntity,
  offerId,
  hotelId,
  isOTAMode = false
}: Props) => {
  const allOffers = offerEntity?.offers ?? []
  const topOffers = getTopOffers(isOTAMode, offerEntity)
  const offerIndex: number = useMemo(() => {
    if (offerId) return topOffers.findIndex(offer => offer.id === offerId)
    return OFFER_TO_SHOW
  }, [offerId, topOffers])

  const primaryOffer = topOffers[offerIndex]
  const isCheapest = getCheapestTopOfferIndex(topOffers) === offerIndex
  const isBofhOffer = getIsBofhOffer(primaryOffer)

  const offerVariant = useMemo(() => {
    return getOfferVariant({
      isCheapest,
      isBofhOffer
    })
  }, [isCheapest, isBofhOffer])

  const anchorPrice = getAnchorPrice(offerEntity)
  const anchorPriceTotal = getAnchorPriceTotal(offerEntity)
  const hasFreeCancellation = getHasFreeCancellation(
    primaryOffer?.cancellationPenalties
  )
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, primaryOffer?.id)
  )

  return {
    allOffers,
    topOffers,
    primaryOffer,
    primaryOfferIndex: offerIndex,
    offerVariant,
    anchorPrice,
    anchorPriceTotal,
    hasFreeCancellation,
    isAppLockedDeal,
    hasOffers: allOffers.length > 0
  }
}
