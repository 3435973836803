import {SplitBookingDetails} from 'components/Offer/types'
import {OldHotelOfferEntity} from 'modules/sapiSearch/offersMapping'
import {FilterUrlParams, SearchUrlParams} from 'modules/search/types'
import {any, complement, includes, isNil, move, split, values} from 'ramda'
import Settings from 'Settings'
import typeCast, {AskedType} from 'utils/typeCast'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {
  getPersistedVclidValue,
  vclidCookieConfig
} from '@daedalus/core/src/analytics/utils/vclidCookie'
import {META_PARAM_SEPARATOR} from '@daedalus/core/src/deepLinking/generateSearchParams'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {getCugDealsParameter} from '@daedalus/core/src/offer/business/getCugDealsParameter'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {OfferTagLabel} from '@daedalus/core/src/offer/types/offer'
import {Anchor, SearchParameters} from '@daedalus/core/src/sapi/types'
import {getUserTierForSapi} from '@daedalus/core/src/sapi/utils'
import {DeviceCategory} from '@daedalus/core/src/utils/userAgent/types'
import {
  ApiSearchParameters,
  ApiSearchParameters as SapiSearchParameters,
  FilterParameters,
  HotelId,
  HotelOfferEntity as SapiHotelOfferEntity
} from '@findhotel/sapi/dist/types/packages/core/src/types'

import {
  Hotel,
  HotelOfferEntity,
  HotelSearchParameters,
  LocationSearchParameters,
  PlaceSearchParameters,
  QuerySearchParameters,
  SearchOffer
} from './slice'

export enum SearchType {
  Hotel = 'hotel',
  Location = 'location',
  Place = 'place',
  Area = 'area',
  Address = 'address',
  Query = 'query',
  Unknown = 'unknown'
}

// default nights to set for traffic coming from GVR with no check-in and check-out dates set
const DEFAULT_GVR_NIGHTS = 3

export const getSearchParameter =
  (searchParameters: SearchUrlParams) =>
  (name: string, isMultiValue?: boolean, type?: AskedType) => {
    const parameter = searchParameters[name]

    if (isNil(parameter)) return undefined

    if (isMultiValue) {
      if (Array.isArray(parameter)) {
        return type ? parameter.map(param => typeCast(param, type)) : parameter
      }

      return type ? [typeCast(parameter, type)] : [parameter]
    }

    return type ? typeCast(parameter, type) : parameter
  }

// SAPI_TODO: unit test
export const getAnchorPrice = (
  offerEntity: HotelOfferEntity
): number | undefined => offerEntity?.anchorPriceRateBreakdown?.nightlyRate

export const getAnchorPriceTotal = (
  offerEntity: HotelOfferEntity
): number | undefined => offerEntity?.anchorPriceRateBreakdown?.totalRate

/**
 * Adds the sb offer to the top offers list
 */
export const getTopOffersWithSplitBookings = (
  isOTAMode: boolean,
  hotelOfferEntity: HotelOfferEntity,
  splitBookingDetails: SplitBookingDetails,
  deviceCategory: DeviceCategory
): SearchOffer[] => {
  const topOffers = getTopOffers(isOTAMode, hotelOfferEntity) ?? []
  const {
    splitBookingOffers,
    hasSplitBookingOffer,
    splitBookingType,
    offerPositionToRemove,
    splitBookingPosition
  } = splitBookingDetails

  if (!hasSplitBookingOffer) return topOffers

  const splitBookingOffersList = splitBookingOffers.map(i => i.offer) ?? []

  // For split flow, add both offers from split booking to the results and remove the offer at the specified position
  // - for desktop get two offers and insert the sb offer at position 3rd position
  // - for other cases get three offers and insert the sb offer at 4th position
  if (splitBookingType === 'split_flow') {
    const maxOffers = deviceCategory === 'desktop' ? 2 : 3
    return [...topOffers.slice(0, maxOffers), ...splitBookingOffersList]
  }

  // For single flow, apply filtering logic and add the first offer of the bundle
  const filteredOffers = topOffers.filter((_, i) => i !== offerPositionToRemove)
  filteredOffers.splice(splitBookingPosition, 0, splitBookingOffersList[0])
  return filteredOffers
}

export const getTopOffers = (
  isOTAMode: boolean,
  offerEntity?: HotelOfferEntity
): SearchOffer[] => {
  const topOffersLimit = isOTAMode
    ? 1
    : Settings.get('VISIBLE_TOP_OFFERS_LIMIT')
  return offerEntity?.offers?.slice(0, topOffersLimit) ?? []
}

/**
 * Set the currency exchange rate in the Settings
 */
export const setCurrencyExchangeRates = (
  currencyExchangeRate: number,
  currencyExchangeRateEur: number
) => {
  Settings.set('CURRENCY_EXCHANGE_RATE', currencyExchangeRate)
  Settings.set('CURRENCY_EXCHANGE_RATE_EUR', currencyExchangeRateEur)
}

/**
 * Type guard for searchParameters, allows safe access of `hotelId` prop
 * @returns boolean
 */
const isHotelSearchParams = (
  searchParameters: unknown
): searchParameters is HotelSearchParameters =>
  (searchParameters as HotelSearchParameters).hotelId !== undefined

/**
 * Type guard for searchParameters, allows safe access of `placeId` prop
 * @returns boolean
 */
const isPlaceSearchParams = (
  searchParameters: unknown
): searchParameters is PlaceSearchParameters =>
  (searchParameters as PlaceSearchParameters).placeId !== undefined

/**
 * Type guard for searchParameters, allows safe access of `geolocation` prop
 * @returns boolean
 */
const isLocationSearchParams = (
  searchParameters: unknown
): searchParameters is LocationSearchParameters =>
  (searchParameters as LocationSearchParameters).geolocation !== undefined

/**
 * Type guard for searchParameters, allows safe access of `query` prop
 * @returns boolean
 */
const isQuerySearchParams = (
  searchParameters: unknown
): searchParameters is QuerySearchParameters =>
  (searchParameters as QuerySearchParameters).query !== undefined

/**
 * Type guard for searchParameters, allows safe access of `boundingBox` prop
 * @returns boolean
 */
const isAreaSearchParams = (searchParameters: SapiSearchParameters) =>
  searchParameters.boundingBox !== undefined

/**
 * Type guard for searchParameters, allows safe access of `address` prop
 * @returns boolean
 */
const isAddressSearchParams = (
  searchParameters: SapiSearchParameters
): boolean => searchParameters.address !== undefined

/**
 * Get the search type from search parameters
 * @returns SearchType string
 */
export const getSearchTypeFromSearchParameters = (
  searchParameters: SearchParameters | undefined
): SearchType => {
  if (searchParameters) {
    if (isHotelSearchParams(searchParameters)) return SearchType.Hotel
    if (isPlaceSearchParams(searchParameters)) return SearchType.Place
    if (isLocationSearchParams(searchParameters)) return SearchType.Location
    if (isQuerySearchParams(searchParameters)) return SearchType.Query
    if (isAreaSearchParams(searchParameters)) return SearchType.Area
    if (isAddressSearchParams(searchParameters)) return SearchType.Address
  }

  return SearchType.Unknown
}

/**
 * Get the dealScore bucket 1 to 5 from a score
 * @param score - score between 0 and 1
 */
export const getBucketFromDealScore = (score: number): number => {
  if (score < 0.2) return 1
  if (score < 0.4) return 2
  if (score < 0.6) return 3
  if (score < 0.8) return 4
  return 5
}

/**
 * Get the regular price from a regularPriceRange
 * At the moment this is simply the average of the lower and upper bounds
 * @param range - regularPriceRange tuple, may miss the upper bound for the top bucket
 */
export const getRegularPrice = (
  range: [number, number | undefined]
): number => {
  const [lowerBound, upperBound] = range

  // Highest buckets have no upper bound so we cannot give a good indication of regular price
  if (!upperBound) return undefined

  return (lowerBound + upperBound) / 2
}

export const getTotalPrice = (
  rateBreakdown: SearchOffer['rate'],
  numberOfRooms: number
) =>
  (rateBreakdown.base + rateBreakdown.hotelFees + rateBreakdown.taxes) *
  numberOfRooms

/**
 * Validates if SAPI search parameters have all required parameters
 *
 * @param searchParameters - SearchParameters type
 * @returns Boolean
 */
export const areSapiSearchParamsValid = (
  searchParameters: SearchParameters | undefined
): boolean => {
  if (!searchParameters) return false

  const searchType = getSearchTypeFromSearchParameters(searchParameters)

  return includes(searchType, [
    SearchType.Hotel,
    SearchType.Place,
    SearchType.Area,
    SearchType.Location,
    SearchType.Address
  ])
}

/**
 * Returns all filters from the `searchParameters`, formatted according to the SAPI implementation.
 *
 * @param searchParameters - Search parameters formatted according to the daedalus implementation
 * @returns All filters, formatted according to the SAPI implementation
 */
export const getSapiFiltersFromDaedalusSearchParameters = (
  searchParameters: FilterUrlParams
): FilterParameters => {
  const getParameter = getSearchParameter(searchParameters as SearchUrlParams)

  return {
    starRating: getParameter('starRatings', true, 'number'),
    guestRating: getParameter('reviewScore', true, 'number'),
    propertyTypeId: getParameter('propertyTypes', true, 'number'),
    facilities: getParameter('facilities', true, 'number'),
    themeIds: getParameter('themes', true, 'number'),
    chainIds: getParameter('chainIds', true, 'number'),
    noHostels: getParameter('noHostels', false, 'boolean'),
    priceMin: getParameter('priceMin', false, 'number'),
    priceMax: getParameter('priceMax', false, 'number'),
    hotelName: getParameter('hotelName'),
    freeCancellation: getParameter('freeCancellation', false, 'boolean'),
    amenities: getParameter('amenities', true, 'string'),
    payLater: getParameter('payLater', false, 'boolean')
  }
}

/**
 * Returns the default number of nights
 */
const getDefaultNights = (
  nights: number | undefined,
  checkIn: string,
  checkOut: string,
  trafficSource: string
): number | undefined => {
  const isGvrTrafficSource = trafficSource === 'gha-vr'
  return isGvrTrafficSource && !checkIn && !checkOut
    ? DEFAULT_GVR_NIGHTS
    : nights
}

const hasFilters = (filters: SapiSearchParameters['filters'] = {}) =>
  any(complement(isNil), values(filters))

/**
 * If there are no checkIn or checkOut dates in the URL params then we want to return the checkIn and checkOut dates from the SAPI store (if they exist)
 * @param urlParams - Search store URL params
 * @param searchParameters - Optional sapiSearch store search parameters
 * @returns The checkIn and checkOut dates
 */
export const getCheckInCheckOutDates = (
  urlParams: SearchUrlParams,
  searchParameters?: SapiSearchParameters
) => {
  const getParameter = getSearchParameter(urlParams)
  const checkInUrlParam = getParameter('checkIn')
  const checkOutUrlParam = getParameter('checkOut')
  if (
    !checkInUrlParam &&
    !checkOutUrlParam &&
    searchParameters?.checkIn &&
    searchParameters?.checkOut
  ) {
    return {
      checkIn: searchParameters.checkIn,
      checkOut: searchParameters.checkOut
    }
  }

  return {
    checkIn: checkInUrlParam,
    checkOut: checkOutUrlParam
  }
}

// getting metadata base on urlParams
export const getMetadata = (
  esd?: string,
  epv?: string,
  vclid?: string
): Pick<ApiSearchParameters, 'metadata'>['metadata'] | undefined => {
  if (!epv && !esd && !vclid) return undefined
  return {
    ...(esd && {esd}),
    ...(epv && {epv}),
    ...(vclid && {vclid})
  }
}

// getting the metadata base on urlParams for the WebView
export const getWebViewMetadata = (
  param?: string, // string usually containing piped esd and epv
  epvParam?: string,
  vclid?: string
) => {
  if (!param && !vclid && !epvParam) return undefined

  let esd: string | undefined,
    epv = epvParam

  // we pipe these two values together to send via appsflyer see: core/src/deepLinking/generateSearchParams.ts:46
  if (param) {
    const [esdPart, epvPart] = split(META_PARAM_SEPARATOR, param)
    // first part is always esd see: core/src/deepLinking/generateSearchParams.ts:48
    esd = esdPart

    // if we don't have epv in the URL then get it from the param
    // second part is always epv see: core/src/deepLinking/generateSearchParams.ts:48
    if (!epv) epv = epvPart
  }

  return {
    ...(esd && {esd}),
    ...(epv && {epv}),
    ...(vclid && {vclid})
  }
}

/**
 * Calculates whether the anchor rooms should be optimized based on whether fsmr is oresent and whether the rooms are optimized.
 * Can be overridden by passing in the isOverridden parameter.
 * @param isRoomsOptimized - whether the rooms are optimized
 * @param isFsmr - whether fsmr is present
 * @param isOverridden - whether anchor rooms optimization is overridden
 * @returns boolean - whether the anchor rooms should be optimized
 */
export const calculateIsAnchorRoomsOptimized = (
  isRoomsOptimized: boolean | undefined,
  isFsmr: boolean | undefined,
  isOverridden: boolean | undefined
) => {
  if (isOverridden !== undefined) {
    return isOverridden
  }

  return isFsmr ? false : Boolean(isRoomsOptimized)
}

/**
 * Converts url parameters to SAPI search parameters
 */
export const urlParamsToSapiSearchParameters = (
  urlParams: SearchUrlParams,
  searchParameters: SapiSearchParameters,
  isBrandOffersLockedByDefault: boolean,
  isMemberPlus: boolean,
  isEmployee: boolean,
  landingTrafficSource: string,
  landingProfileId: string,
  isAuthenticated: boolean,
  isAuthLoading: boolean,
  sapiLabel: string,
  isAppLockedDealAudience: boolean
): SapiSearchParameters => {
  const getParameter = getSearchParameter(urlParams)

  const filters = getSapiFiltersFromDaedalusSearchParameters(urlParams)

  const cugDeals = getCugDealsParameter({
    isMemberPlus,
    isEmployee,
    urlParams,
    isBrandOffersLockedByDefault,
    isAppLockedDealAudience
  })

  const tier = getUserTierForSapi({
    isAuthenticated,
    isMemberPlus,
    urlParams,
    isBrandOffersLockedByDefault
  })

  const {checkIn, checkOut} = getCheckInCheckOutDates(
    urlParams,
    searchParameters
  )
  const isWebview = isReactNativeWebView()
  const vclid =
    getParameter(vclidCookieConfig.name) || getPersistedVclidValue() || ''
  const clickedOfferRate = getParameter('clickedOfferRate', false, 'number')
  // When clickedOfferRate is present, we want to ignore the esd and epv parameters
  const esd = clickedOfferRate ? undefined : getParameter('esd')
  const epv = clickedOfferRate ? undefined : getParameter('epv')

  // TODO: (@search) (@mobile-app) Clean up this up once the app fully adopts the new esd mapping via Appsflyer.
  // This is for backward compatibility with the 'getWebViewMetadata' function that is now moved into the app.
  // remove getWebViewMetadata and just use getMetadata
  const metadata = isWebview
    ? getWebViewMetadata(esd, epv, vclid)
    : getMetadata(esd, epv, vclid)

  const optimizeRooms = getParameter('optimizeRooms', false, 'boolean')
  const fsmr = getParameter('fsmr')
  const optimizeAnchorRoomsOverride = getParameter(
    'optimizeAnchorRooms',
    false,
    'boolean'
  )
  const optimizeAnchorRooms = calculateIsAnchorRoomsOptimized(
    optimizeRooms,
    Boolean(fsmr),
    optimizeAnchorRoomsOverride
  )

  const sapiSearchParameters: SapiSearchParameters = {
    query: getParameter('query'),
    address: getParameter('address'),
    hotelId: getParameter('hotelId'),
    boundingBox: getParameter('boundingBox', true, 'number'),
    placeId: getParameter('placeId'),
    optimizeRooms,
    optimizeAnchorRooms,
    checkIn,
    checkOut,
    rooms: getParameter('rooms'),
    dayDistance: getParameter('checkInDistance', false, 'number'), // TODO: SAPI_TODO: @votsa [@findhotel.sapi >= 1] Change dayDistance -> checkInDistance in SAPI
    nights: getDefaultNights(
      getParameter('nights', false, 'number'),
      getParameter('checkIn'),
      getParameter('checkOut'),
      landingTrafficSource
    ),
    sortField: getParameter('sortField'),
    sortOrder: getParameter('sortOrder'),
    cugDeals,
    ...(isAuthLoading ? {} : {tier}),
    originId: getParameter('profile') || landingProfileId,
    label: sapiLabel,
    metadata,
    sbc: getParameter('sbc'),
    preferredRate: clickedOfferRate
  }

  // If we have a preheat url, reuse the same searchId
  const searchIdCookie = getCookie('X-Sapi-Preheat-Search-Id')
  if (searchIdCookie) {
    sapiSearchParameters.searchId = searchIdCookie
  }

  if (hasFilters(filters)) {
    sapiSearchParameters.filters = filters
  }

  return sapiSearchParameters
}

/**
 * Determines whether any of the passed in `hotelOfferEntities` have offers
 */
export const hotelOfferEntitiesHaveOffers = (
  hotelOfferEntities?: Record<
    HotelId,
    SapiHotelOfferEntity | OldHotelOfferEntity
  >
) =>
  values(hotelOfferEntities).some(
    offerEntity => offerEntity?.offers?.length > 0
  )

/**
 * Returns a search location object based on the `navPathInfo` property from the `sapiObject`.
 */
export const getSearchLocationFromSapiObject = (sapiObject?: Anchor) => {
  const {adminDivision, city, country} = sapiObject?.navPathInfo || {}

  return {
    country: country?.name,
    region: adminDivision?.name,
    city: city?.name
  }
}

/**
 * [Search] Moves the prioritized offer to the top of the offers.
 * the criteria to move said offer is:
 * 1. If it's a "Your Choice" offer, it's moved to the top.
 * 2. If no "Your Choice" offer is present, the cheapest offer is moved to the top.
 * this is to make sure that the preferred rate goes on top and doesnt get locked.
 */
export const movePriorityOfferToTop = (
  offers: SearchOffer[]
): SearchOffer[] => {
  let offerIndex = -1

  offers.forEach((offer, index) => {
    if (hasOfferTag(offer, OfferTagLabel.IsYourChoice)) {
      offerIndex = index
    }
  })

  // If "Your Choice" offer isn't found, look for the cheapest offer
  if (offerIndex === -1) {
    offers.forEach((offer, index) => {
      if (
        offerIndex === -1 ||
        offer.nightlyRate < offers[offerIndex].nightlyRate
      ) {
        offerIndex = index
      }
    })
  }

  if (offerIndex !== -1) {
    return move(offerIndex, 0, offers)
  }

  return offers
}

/**
 * Determines whether a hotel has offers available.
 *
 * @param hotelOfferEntity - An object containing information about the hotel offers.
 * @returns boolean - Returns true if the hotel has at least one offer available, otherwise false.
 */
export const calculateHotelHasOffers = (
  hotelOfferEntity: HotelOfferEntity
): boolean => Boolean(hotelOfferEntity?.offers?.[0])

/**
 * Maps the provided hotel and its offer entity to the Daedalus format.
 *
 * @param hotel - The hotel entity.
 * @param hotelOfferEntity - The offer entity associated with the hotel.
 * @returns The hotel in Daedalus format.
 */
export const mapHotelToDaedalusFormat = (
  hotel: Hotel | undefined,
  hotelOfferEntity: HotelOfferEntity
) => {
  if (!hotel) return undefined
  return {
    ...hotel,
    offers: hotelOfferEntity?.offers,
    topOfferData: hotelOfferEntity?.topOfferData
  }
}

export const getHotelIdsWithOffers = (
  hotelIds: string[],
  hotelOfferEntities: Record<string, OldHotelOfferEntity>
): string[] => {
  return hotelIds.filter(
    hotelId => hotelOfferEntities?.[hotelId]?.offers?.length > 0
  )
}
