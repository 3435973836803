import React from 'react'

import SearchView from './SearchView'

const SearchContainer: React.FC = () => {
  return <SearchView />
}

const SearchContainerMemo = React.memo(SearchContainer)
export default SearchContainerMemo
