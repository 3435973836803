export const ROOM_CARD_GALLERY_HEIGHT_MOBILE = 206
export const ROOM_CARD_GALLERY_HEIGHT_DESKTOP = 170

const ROOM_CARD_GALLERY_WIDTH_MOBILE = '100%'
const ROOM_CARD_GALLERY_WIDTH_DESKTOP = 230

export const ROOM_CARD_GALLERY_STYLES = {
  mobileHeight: `${ROOM_CARD_GALLERY_HEIGHT_MOBILE}px`,
  mobileWidth: ROOM_CARD_GALLERY_WIDTH_MOBILE,
  desktopHeight: `${ROOM_CARD_GALLERY_HEIGHT_DESKTOP}px`,
  desktopWidth: `${ROOM_CARD_GALLERY_WIDTH_DESKTOP}px`
}

export const COMPACT_ROOMS_AMOUNT = 5
