import React, {FC} from 'react'
import {useSelector} from 'react-redux'
import {LocationDetails} from 'components/HotelCardLocationDetails'
import {TaxationAndNightlyPriceMessage} from 'components/TaxationAndNightlyPriceMessage'
import {
  getDistanceFromTarget,
  getHotel,
  getHotelAvailabilityPrices,
  getShowTotalPrices,
  State
} from 'modules/sapiSearch/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {StarRating} from '@daedalus/atlas/StarRating'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  HotelName,
  TotalPriceMessage
} from '@daedalus/shared/src/booking/HotelInfoCard/styles'
import {ReviewRating} from '@daedalus/shared/src/search/ReviewRating'

import {TOP_SECTION_MIN_HEIGHT} from '../HotelCardLayout/HotelCardLayout'
import {PropertySummaryLoading} from './PropertySummaryLoading'

interface Props {
  hotelId: string
  isCompactHotelCard?: boolean
}

const PropertySummaryComponent: FC<Props> = ({hotelId, isCompactHotelCard}) => {
  const hotelDetails = useSelector((state: State) => getHotel(state, hotelId))
  const distanceFromTarget = useSelector((state: State) =>
    getDistanceFromTarget(state, hotelId)
  )

  const showTotalPrices = useSelector(getShowTotalPrices)

  const hotelHasAvailabilityPrices = Boolean(
    useSelector(state => getHotelAvailabilityPrices(state, hotelId))
  )
  const shouldShowTaxationAndNightlyPrice = !(
    isCompactHotelCard && !hotelHasAvailabilityPrices
  )

  // Showing skeleton
  if (!hotelDetails || !hotelId) return <PropertySummaryLoading />

  return (
    <>
      <Box
        flexDirection="column"
        display="flex"
        flexWrap="wrap"
        minHeight={
          shouldShowTaxationAndNightlyPrice
            ? `${TOP_SECTION_MIN_HEIGHT - 20}px`
            : undefined
        }
        boxSizing="border-box"
        flexBasis="100%"
      >
        <div>
          <div data-id="StarRating">
            <StarRating
              wrapperId={`HotelCardMobileStarRating-${hotelId}`}
              rating={hotelDetails.starRating}
              size="xs"
            />
          </div>
          <HotelName
            id={`HotelCardMobileHotelName-${hotelId}`}
            data-id="HotelName"
          >
            {hotelDetails.hotelName}
          </HotelName>

          <Box data-id="LocationDetails" marginBottom="s300">
            <LocationDetails
              placeName={hotelDetails.placeDisplayName}
              distance={distanceFromTarget}
              isCompactHotelCard={isCompactHotelCard}
            />
          </Box>

          <div data-id="ReviewRating">
            <ReviewRating
              rating={hotelDetails.guestRating.overall}
              totalRatingCount={hotelDetails.reviewCount}
              hotelId={hotelDetails.objectID}
            />
          </div>
        </div>

        {shouldShowTaxationAndNightlyPrice && (
          <Box
            alignSelf="flex-end"
            flex="1"
            textAlign="right"
            marginTop="s500"
            paddingRight="s300"
          >
            {showTotalPrices ? (
              <TotalPriceMessage>
                <FormattedMessageWrapper id="total" defaultMessage="total" />
                &nbsp;
                <FormattedMessageWrapper
                  id="includingTaxes"
                  defaultMessage="incl tax and fees"
                />
              </TotalPriceMessage>
            ) : (
              <TaxationAndNightlyPriceMessage hotelId={hotelId} />
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export const PropertySummary = React.memo(PropertySummaryComponent)
