import React, {useCallback, useMemo, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {showStarRatingsFilter} from 'modules/overlay/actions'

import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {StarRatingBottomSheet} from './StarRatingBottomSheet'

const filterKeys = ['starRatings']
const elementName = 'quickFilterstarRating'

interface Props {
  quickFilterValues?: string[]
  isActive?: boolean
  isQuickFilter?: boolean
}
export const StarRatingPill: React.FC<Props> = ({
  quickFilterValues = [],
  isActive = false,
  isQuickFilter = false
}) => {
  const dispatch = useDispatch()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [starMin, starMax] = quickFilterValues

  const {getAppliedStarsLabel, trackFilterClick, submitFilters} =
    useFiltersForm(filterKeys, elementName)

  const appliedStarsLabel = getAppliedStarsLabel()

  const pillLabel = useMemo(() => {
    if (isQuickFilter) {
      return (
        <FormattedMessageWrapper
          id="filters.starRatingSelectedTo"
          defaultMessage="{starRating1} to {starRating2}-star"
          values={{starRating1: starMin, starRating2: starMax}}
        />
      )
    }
    return appliedStarsLabel
  }, [])

  const handlePillClick = useCallback(() => {
    if (isActive) buttonRef.current?.blur()

    const updatedFilters = {
      starRatings: isActive ? undefined : [starMin, starMax]
    }

    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })

    if (!isQuickFilter) {
      dispatch(showStarRatingsFilter())
    } else {
      submitFilters(updatedFilters)
    }
  }, [
    isActive,
    trackFilterClick,
    submitFilters,
    starMax,
    starMin,
    dispatch,
    isQuickFilter
  ])

  if (isQuickFilter && isActive) return null

  return (
    <>
      <Pill
        dataId={
          isQuickFilter
            ? `${starMin}to${starMax}StarFilter`
            : 'starRatingFilter'
        }
        onClick={handlePillClick}
        isSelected={isActive}
        iconStart={<Icon name="Star" />}
        iconEnd={isActive && isQuickFilter && <Icon name={'Close'} />}
        showArrow={!isQuickFilter}
      >
        {pillLabel}
      </Pill>
      <StarRatingBottomSheet />
    </>
  )
}
