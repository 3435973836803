import {identical} from 'ramda'

interface Props {
  clickedOfferRate: number
  currentTopOfferRate: number
  priceChangeThreshold: number
}

type checkPriceChangeResponse = {
  isPriceDecrease: boolean
  isPriceIncrease: boolean
  priceDifference: number
}

export function checkPriceChange({
  clickedOfferRate,
  currentTopOfferRate,
  priceChangeThreshold
}: Props): checkPriceChangeResponse {
  // Prevent returning NaN
  if (!clickedOfferRate || !currentTopOfferRate)
    return {
      isPriceDecrease: false,
      isPriceIncrease: false,
      priceDifference: 0.0
    }

  const rawPriceDifference = currentTopOfferRate - clickedOfferRate
  const percentageDifference = (rawPriceDifference / clickedOfferRate) * 100

  let priceDifference = parseFloat(rawPriceDifference.toFixed(2))

  // prevent returning -0
  if (identical(priceDifference, -0)) {
    priceDifference = 0
  }

  const isPriceDecrease = percentageDifference <= -priceChangeThreshold
  const isPriceIncrease = percentageDifference >= priceChangeThreshold

  return {
    isPriceDecrease,
    isPriceIncrease,
    priceDifference
  }
}
