import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import updateLocation from 'modules/common/actions/location'
import {getHotelDetailsOverlayInfo, getOverlay} from 'modules/overlay/selectors'
import {LocationStateType} from 'types/Search'
import {updateOverlayOfType} from 'utils/updateOverlayOfType'

export const useSyncAccommodationViewOverlaysState = () => {
  const dispatch = useDispatch()
  const {state: locationOverlayState} = useLocation<LocationStateType>()
  const overlay = useSelector(getOverlay)

  // get hotelDetails overlay from location state
  const locationHotelDetailsOverlay = locationOverlayState?.overlay?.find(
    overlay => overlay.type === 'hotelDetails'
  )

  // get hotelDetails overlay from redux state
  const stateHoteDetailsOverlay = useSelector(getHotelDetailsOverlayInfo)

  /**
   * If there is less overlays in history.state than it's in the reduxStore - it means we closed an overlay
   * We apply any changes made to the hotelDetails overlay in previous location state
   * to the new location state after moving back (closing an overlay) to keep those states synced
   */
  useEffect(() => {
    if (
      overlay?.length > locationOverlayState?.overlay?.length &&
      stateHoteDetailsOverlay &&
      locationHotelDetailsOverlay
    ) {
      // create updated overlays state with synced hotelDetails data
      const newOverlayState = updateOverlayOfType(
        locationOverlayState.overlay,
        'hotelDetails',
        stateHoteDetailsOverlay
      )

      // replace current location with state moved from previous location
      dispatch(
        updateLocation({
          method: 'replace',
          state: {
            overlay: newOverlayState
          }
        })
      )
    }
  }, [
    dispatch,
    overlay,
    locationOverlayState,
    stateHoteDetailsOverlay,
    locationHotelDetailsOverlay
  ])
}
