import {cookieSecuritySettings} from '@daedalus/core/src/_web/utils/cookies/cookieSecuritySettings'

const maxAge = 1
const name = 'webToAppBottomSheetSeen'

const config = {
  name,
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}

export default config
