import React, {ComponentProps} from 'react'
import {LovHit} from 'modules/search/slice'
import {ColorPath} from 'types/Theme'
import {getParentCategoryId} from 'utils/facilities'

import {Icon, IconSize, iconSizeMap} from '@daedalus/atlas/Icon'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

import {CategoryIcon} from './CategoryIcon'

type IconName = ComponentProps<typeof Icon>['name']
export const facilityIconsById: Record<number, IconName> = {
  6: 'Parking', // Parking
  8: 'Dog', // Pet Friendly
  299: 'Wifi', // Free Wi-Fi
  5: 'Restaurant', // Restaurant
  61: 'HotTub', // Hot Tub / Jacuzzi
  264: 'SmokingRoom', // Smoking Room
  7: 'SwimmingPoolLadder', // Swimming pool
  193: 'SpaWellness', // Spa & Wellness Centre
  50: 'Kitchen', // Kitchen
  211: 'Shower', // Private Bathroom
  18: 'Reception', // 24-Hour Reception
  35: 'Sauna', // Sauna
  293: 'Fire', // Fireplace
  244: 'Mug', // Breakfast in the Room
  2: 'ServiceBell', // Room Service
  9: 'Plane2', // Airport shuttle
  4: 'Dumbell', // Fitness Room/Gym
  221: 'PoolIndoor', // Pool Indoor
  63: 'Coins', // Casino
  21: 'Ice' // Air Conditioned
}

interface Props {
  facility: LovHit
  size?: IconSize
  colorPath?: ColorPath
}

export const IconLoader = ({size = 'md'}: {size?: IconSize}) => {
  const iconSize = iconSizeMap[size]

  return <SkeletonLoader circle width={iconSize} height={iconSize} />
}

export const FacilityIcon: React.FC<Props> = ({
  facility,
  size = 'md',
  colorPath = 'content.neutral.c600'
}) => {
  const iconName = facilityIconsById[facility.id]

  if (iconName)
    return <Icon name={iconName} size={size} colorPath={colorPath} />

  return (
    <CategoryIcon
      categoryId={getParentCategoryId(facility)}
      size={size}
      colorPath={colorPath}
    />
  )
}
