import {useDispatch} from 'react-redux'
import {trackTimingEvent} from 'middleware/analytics'
import {
  setIsAcquiringGeoCoordinates,
  setLocalizationConfig
} from 'modules/meta/slice'
import {
  LocationSearchType,
  setUserLocationSearchType
} from 'modules/search/slice'
import {
  GeolocationSource,
  getHasGeolocationPermission,
  getPosition
} from 'utils/geolocation'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  persistGeolocation,
  persistGeolocationSource
} from '@daedalus/core/src/utils/geolocation'
import {logAndCaptureErrorMessage} from '@daedalus/core/src/utils/logging/errorHandlers'

export const useSetDeviceGeolocationSearchParams = () => {
  const dispatch = useDispatch()

  const setDeviceGeolocationSearchParams = async () => {
    try {
      const hasPermission = await getHasGeolocationPermission()
      if (hasPermission) {
        dispatch(setIsAcquiringGeoCoordinates(true))
      }
      const {coords} = await getPosition()

      const {latitude, longitude} = coords
      const geolocation = {lat: latitude, lon: longitude}
      persistGeolocation(geolocation)
      persistGeolocationSource(GeolocationSource.Device)
      dispatch(
        setLocalizationConfig({
          geolocation
        })
      )

      dispatch(setUserLocationSearchType(LocationSearchType.UserLocation))
      dispatch(setIsAcquiringGeoCoordinates(false))
      trackTimingEvent(
        dispatch,
        'GeolocationCoordinates',
        'GeolocationCoordinates-start',
        Entity.GeolocationCoordinatesFetch,
        Team.Default,
        true
      )
    } catch (error) {
      dispatch(setIsAcquiringGeoCoordinates(false))
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.GeolocationCoordinatesFetch,
          action: Action.Errored,
          analyticsContext: {
            [AnalyticsContext.ErrorContext]: {
              errorType: 'GeolocationFetchError',
              errorMessage: 'failed to get geolocation coordinates'
            }
          }
        })
      )
      logAndCaptureErrorMessage(
        `Error fetching geolocation info - ${error?.message}`,
        {
          level: 'warning'
        }
      )
    }
  }

  return {
    setDeviceGeolocationSearchParams
  }
}
