import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export enum Source {
  AppErrorBoundary = 'AppErrorBoundary',
  ImportLoader = 'ImportLoader',
  LegacyRoutes = 'LegacyRoutes',
  Routes = 'Routes'
}

export type Props = {
  payload: {
    source: Source
    message: string
  }
}

export const trackSystemPageErrored = ({payload}: Props) =>
  trackEvent({
    category: Category.System,
    entity: Entity.Page,
    action: Action.Errored,
    payload
  })
