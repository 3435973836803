import {cookieSecuritySettings} from '@daedalus/core/src/_web/utils/cookies/cookieSecuritySettings'
import {getDomainName} from '@daedalus/core/src/utils/url'

const maxAge = 1 // 1 day
const name = 'c5wh18'

export const ANCHOR_HOTELS_VIEWED_KEY = 'ahv752'
export const ANCHOR_HOTEL_CHAINS_VIEWED_KEY = 'ahcv16'

const config = {
  name,
  options: {
    maxAge,
    domain: getDomainName(window.location.hostname),
    ...cookieSecuritySettings(true)
  }
}

export default config
