import React from 'react'
import {useSelector} from 'react-redux'
import {useHotelOverlayOffersFilters} from 'hooks/useHotelOverlayOffersFilters'
import {getOfferEntity} from 'modules/sapiSearch/selectors'
import Settings from 'Settings'
import {RootState} from 'store'

import {HotelId} from '@findhotel/sapi'

import {FilterKey, FilterSource} from './constants'
import {OffersFilter} from './OffersFilter'
interface PropsTypes {
  isLabelVisible?: boolean
  source: FilterSource
  hotelId: HotelId
}
export const OffersFiltersContainer = ({
  isLabelVisible = true,
  source,
  hotelId
}: PropsTypes) => {
  const {activeOffersFilters, toggleFilter} = useHotelOverlayOffersFilters()
  const sapiOfferEntity = useSelector((state: RootState) =>
    getOfferEntity(state, hotelId)
  )

  const availableOffersCount = sapiOfferEntity?.availableOffersCount || 0
  const shouldShowFilters =
    availableOffersCount > Settings.get('VISIBLE_TOP_OFFERS_LIMIT')
  const handleFilterClick = (id: FilterKey) => toggleFilter(id, source)

  return shouldShowFilters ? (
    <OffersFilter
      isLabelVisible={isLabelVisible}
      onFilterClick={handleFilterClick}
      activeFilters={activeOffersFilters}
    />
  ) : null
}
