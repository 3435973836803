/* eslint-disable @typescript-eslint/no-extra-semi */
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {History} from 'history'
import {referMember, ReferMemberResponseData} from 'services/user'
import Settings from 'Settings'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {persistCognitoUserTierOnCookie} from '@daedalus/core/src/auth/business/persistData'
import {
  ReferralBusiness,
  ReferralBusinessValues
} from '@daedalus/core/src/auth/business/referral/business'
import {
  setCustomAppData,
  setIsLoading
} from '@daedalus/core/src/auth/modules/slice'
import {parseQueryString} from '@daedalus/core/src/utils/url'

interface TierSuccessUpgradeType {
  referrerTier: string
  upgradeTier: string
  referrerAccountUserId: string
}

interface TierFailureUpgradeType {
  referrerTier?: string
  upgradeTier?: string
  referrerAccountUserId: string
}

interface PromoteUserParams {
  referrerId: string
  queryParams: URLSearchParams
  history: History
  trackTierUpgrade: {
    success: (params: TierSuccessUpgradeType) => void
    failure: (params: TierFailureUpgradeType) => void
  }
}

const REFERRER_QUERY_PARAM_KEY = 'referrer'

interface RemoveQueryString {
  queryParams: URLSearchParams
  queryParam: string
  history: History
}

const removeQueryString = ({
  queryParams,
  queryParam,
  history
}: RemoveQueryString) => {
  queryParams.delete(queryParam)
  history.replace({
    search: queryParams.toString()
  })
}

const parseDataToUser = (data: ReferMemberResponseData) => ({
  attributes: {
    'custom:app_data': `{"tier": ${JSON.stringify(data.referredTiers)}}`
  }
})

const useReferralTierUpgradeRequested = () => {
  const dispatch = useDispatch()

  return (referrerAccountUserId: string) => {
    dispatch(setIsLoading(true))
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ReferralMemberTierUpgrade,
        action: Action.Requested,
        payload: {
          referrerAccountUserId
        },
        team: Team.Retention
      })
    )
  }
}

const useReferralTierUpgrade = () => {
  const dispatch = useDispatch()

  const success = ({
    referrerTier,
    upgradeTier,
    referrerAccountUserId
  }: TierSuccessUpgradeType) => {
    dispatch(setIsLoading(false))
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ReferralMemberTierUpgrade,
        action: Action.Succeeded,
        payload: {
          referrerTier,
          upgradeTier,
          referrerAccountUserId
        },
        team: Team.Retention
      })
    )
  }

  const failure = ({
    referrerTier = null,
    upgradeTier = null,
    referrerAccountUserId
  }: TierFailureUpgradeType) => {
    dispatch(setIsLoading(false))
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ReferralMemberTierUpgrade,
        action: Action.Errored,
        payload: {
          referrerTier,
          upgradeTier,
          referrerAccountUserId
        },
        team: Team.Retention
      })
    )
  }

  return {success, failure}
}

const promoteUser = async ({
  referrerId,
  queryParams,
  history,
  trackTierUpgrade
}: PromoteUserParams) => {
  try {
    const data = await referMember(referrerId)
    const user = parseDataToUser(data)

    if (data.referredUpdated) {
      trackTierUpgrade.success({
        referrerTier: data.referrerTiers,
        upgradeTier: data.referredTiers,
        referrerAccountUserId: referrerId
      })
    }

    persistCognitoUserTierOnCookie(user)
    removeQueryString({
      queryParams,
      queryParam: REFERRER_QUERY_PARAM_KEY,
      history
    })
    return user
  } catch (error) {
    if (typeof Sentry !== 'undefined') {
      Sentry.captureException(error)
      trackTierUpgrade.failure({
        referrerAccountUserId: referrerId
      })
    }
  }
}

const PARTNERS_IDS: Record<ReferralBusinessValues, string> = {
  [ReferralBusiness.YourCampus]: Settings.get('YOUR_CAMPUS_PARTNER_ID')
}

const useReferrerId = () => {
  const {search} = useLocation()
  const queryString = parseQueryString(search)
  const partner = queryString?.partner as ReferralBusinessValues | undefined
  const referrerPartnerId = partner && PARTNERS_IDS[partner]
  const referrerIdFromQueryString = queryString[REFERRER_QUERY_PARAM_KEY] as
    | string
    | undefined

  return referrerIdFromQueryString || referrerPartnerId
}

export const useMemberReferral = () => {
  const dispatch = useDispatch()
  const {search} = useLocation()
  const history = useHistory()
  const queryParams = new URLSearchParams(search)
  const trackTierUpgradeRequested = useReferralTierUpgradeRequested()
  const trackTierUpgrade = useReferralTierUpgrade()
  const referrerId = useReferrerId()

  useEffect(() => {
    ;(async () => {
      if (referrerId) {
        trackTierUpgradeRequested(referrerId)

        const customAppData = await promoteUser({
          referrerId,
          queryParams,
          history,
          trackTierUpgrade
        })

        return customAppData && dispatch(setCustomAppData(customAppData))
      }
    })()
  }, [referrerId, history, search])
}
