import {createSelector} from '@reduxjs/toolkit'
import {QueryStatus} from '@reduxjs/toolkit/dist/query'
import {
  getAccommodationPageFiltersParameters,
  getAccommodationPageHotelId,
  getRoomsActiveFilters
} from 'modules/accommodation/selectors'
import {
  extractRegularOfferParams,
  extractSplitBookingParams,
  getRoomsSearchParamsFromApUrl,
  isVioOfferOrBundleSelected
} from 'modules/accommodation/utils'
import {getVclidCookie} from 'modules/cookies/selectors'
import {
  getAddress,
  getDeviceId,
  getDeviceLayout,
  getMeta
} from 'modules/meta/selectors'
import {
  getAllOffersOverlay,
  getHotelDetailsVisibility
} from 'modules/overlay/selectors'
import {
  getAnchorHotelId,
  getHotelOfferEntityOffers,
  getNumberOfRooms,
  getOptimizedRoomsConfig,
  getSearchId,
  getSearchParameters,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {
  getMetadata,
  getSapiFiltersFromDaedalusSearchParameters,
  getSearchParameter
} from 'modules/sapiSearch/utils'
import {
  getIsMultiRoomBundlesSearch,
  getIsOptimizeRooms
} from 'modules/search/selectors'
import {getUrlParams} from 'modules/search/selectors'
import {isEmpty} from 'ramda'
import {RootState} from 'store'
import {getIsAccommodationPage} from 'utils/getIsAccommodationPage'
import {getUserIpFromOffer} from 'utils/getUserIpFromOffer'
import {getSerializedUrlParamsWithoutTempParams} from 'utils/searchParams'

// Direct import of selector module needed instead of useBrand hook in selector context where hooks cannot be used.
// eslint-disable-next-line @vio/custom-rules/prefer-usebrand-hook
import {getIsBrandOffersLockedByDefault} from '@daedalus/core/src/_web/brand/modules/selectors'
import {getPersistedSapiLabel} from '@daedalus/core/src/_web/sapiLabel/business'
import {getEmailDomain} from '@daedalus/core/src/auth/business/authentication'
import {
  getIsAuthenticated,
  getUser,
  getUserId,
  selectIsEmployee,
  selectIsMemberPlus,
  selectUserEmail
} from '@daedalus/core/src/auth/modules/selectors'
import {getCugDealsParameter} from '@daedalus/core/src/offer/business/getCugDealsParameter'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {
  applyRoomsFilterMemo,
  FilteredRoomsData
} from '@daedalus/core/src/room/business/utils/filters'
import {
  GetOffersParams,
  GetRoomsParams,
  searchApi
} from '@daedalus/core/src/sapi/services/searchApi'
import {getUserTierForSapi} from '@daedalus/core/src/sapi/utils'

const getHotelIdParam = (_: unknown, hotelId: string) => hotelId

export const getCommonSearchParams = createSelector(
  [
    selectIsMemberPlus,
    getUser,
    getIsAuthenticated,
    getDeviceId,
    getIsBrandOffersLockedByDefault,
    getUrlParams,
    selectIsEmployee,
    getDeviceLayout
  ],
  (
    isMemberPlus,
    user,
    isAuthenticated,
    deviceId,
    isBrandOffersLockedByDefault,
    urlParams,
    isEmployee,
    deviceLayout
  ) => {
    const params = getSerializedUrlParamsWithoutTempParams(location.search)
    const userId = getUserId(user)
    const emailDomain = getEmailDomain(user?.attributes?.email)

    const cugDeals = getCugDealsParameter({
      isMemberPlus,
      isEmployee,
      urlParams: params,
      isBrandOffersLockedByDefault,
      isAppLockedDealAudience: deviceLayout === 'desktop'
    })
    const label = getPersistedSapiLabel()
    const tier = getUserTierForSapi({
      isAuthenticated,
      isMemberPlus,
      urlParams,
      isBrandOffersLockedByDefault
    })

    return {
      tier,
      cugDeals,
      label,
      userId,
      emailDomain,
      deviceId
    }
  }
)

/**
 * Generate base parameters for the offers query
 * Params are used to generate the cache key that is also used by selectors so don't add/change params externally
 */
export const getOffersBaseParameters = createSelector(
  [
    getHotelIdParam,
    getUrlParams,
    getCommonSearchParams,
    getSearchParameters,
    getAnchorHotelId,
    getMeta,
    getNumberOfRooms,
    getIsMultiRoomBundlesSearch,
    getIsOptimizeRooms,
    getVclidCookie
  ],
  (
    hotelId,
    urlParams,
    commonSearchParams,
    searchParameters,
    anchorHotelId,
    meta,
    numberOfRooms,
    isMultiRoomBundlesSearch,
    isOptimizeRooms,
    vclidCookie
  ): GetOffersParams => {
    const {rooms, checkIn, checkOut} = searchParameters || {}
    const isAnchor = hotelId === anchorHotelId
    const {includeTaxes, includeLocalTaxes} = meta
    const metadata = getMetadata(undefined, undefined, vclidCookie)
    const {profile: originId} = urlParams || {}

    return {
      shouldDispatchActions: true, // Enable tracking & syncing default dates w. searchbox
      promos: ['web2app'], // Enable fetching promos for appLocked logic to work
      rooms,
      checkIn,
      checkOut,
      originId,
      ...commonSearchParams,
      includeTaxes,
      includeLocalTaxes,
      numberOfRooms,
      includeRoomsInNightlyPrice: isMultiRoomBundlesSearch,
      optimizeRooms: isOptimizeRooms,
      isAnchor,
      hotelId,
      metadata
    }
  }
)

/**
 * Generate parameters for the top offers query
 * Params are used to generate the cache key that is also used by selectors so don't add/change params externally
 */
export const getTopOffersParameters = createSelector(
  [getOffersBaseParameters, getUrlParams],
  (baseOffersParams, urlParams): GetOffersParams => {
    const {freeCancellation, amenities, payLater} = urlParams
    const filters = {
      ...(amenities && {amenities}),
      ...(freeCancellation && {freeCancellation: freeCancellation === '1'}),
      ...(payLater && {payLater: payLater === '1'})
    }

    return {
      ...baseOffersParams,
      shouldUpdateOfferEntities: true,
      ...(!isEmpty(filters) && {filters})
    }
  }
)

/**
 * Generate parameters for the all offers query
 * Params are used to generate the cache key that is also used by selectors so don't add/change params externally
 */
export const getAllOffersParameters = (
  state: RootState,
  hotelId: string,
  useUrlParamsFilters?: boolean // Used to determine if we should use the filters from the URL params
): GetOffersParams => {
  const urlParams = getUrlParams(state)
  const getParameter = getSearchParameter(urlParams)
  const baseParams = getOffersBaseParameters(state, hotelId)

  const fullSearchSearchId = getSearchId(state)
  const topOffersSearchId = getTopOffersSearchId(state)

  const clickedOfferRate = getParameter('clickedOfferRate', false, 'number')
  // When clickedOfferRate is present, we want to ignore the esd and epv parameters
  const esd = clickedOfferRate ? undefined : getParameter('esd')
  const metadata = getMetadata(esd, undefined, getVclidCookie(state))

  const filtersAndSortConfig = getAccommodationPageFiltersParameters(state)
  const urlParamsFilters = getSapiFiltersFromDaedalusSearchParameters(urlParams)
  const filters = {
    ...filtersAndSortConfig.filters,
    ...(useUrlParamsFilters && urlParamsFilters)
  }

  return {
    ...baseParams,
    getAllOffers: true,

    // The "see all offers" search is not a new user-initiated search but just an extension, the same searchId is used to connect events
    searchId: topOffersSearchId || fullSearchSearchId,
    metadata,
    filters
  }
}

/**
 * Get clickedOffer data from the cheapest available bofh offer from hotel-offers entity
 * It may be a regular offer or split booing, depends on sbPosition in the displayed top-offers
 *
 * If there's no bofh offer available in hotel-offers entity - returns empty object
 */
export const getCheapestBofhTopOfferParams = createSelector(
  [getHotelOfferEntityOffers, getSplitBookingDetails, getAddress],
  (
    offers = [],
    {
      splitBookingPosition: sbOfferIndex,
      splitBookingBundle,
      splitBookingOffers,
      hasSplitBookingOffer,
      splitBookingType
    },
    metaAddress
  ) => {
    //skip adding params when vio offer has been selected
    if (isVioOfferOrBundleSelected()) return {}

    const isSbSingleFlowAvailable =
      hasSplitBookingOffer && splitBookingType === 'single_flow'
    const cheapestRegularOfferIndex = offers.findIndex(getIsBofhOffer)
    const isSbCheapest =
      isSbSingleFlowAvailable && sbOfferIndex <= cheapestRegularOfferIndex

    //get cheapest regular offer
    const regularOffer = offers.find(getIsBofhOffer)

    const noBofhOfferAvailable = !isSbSingleFlowAvailable && !regularOffer

    //skip adding params when there is no bofh offer in top-offers
    if (noBofhOfferAvailable) return {}

    //additional params to add
    const offerForIp = isSbCheapest ? splitBookingOffers[0].offer : regularOffer
    const clickedUserIp = getUserIpFromOffer(offerForIp, metaAddress)

    const offerParams = isSbCheapest
      ? extractSplitBookingParams(splitBookingBundle)
      : extractRegularOfferParams(regularOffer)
    return {
      ...offerParams,
      clickedUserIp
    }
  }
)
/**
 * Generate parameters for the rooms query
 * Params are used to generate the cache key that is also used by selectors so don't add/change params externally
 */
export const getHotelApiRoomsParameters = createSelector(
  [
    getHotelIdParam,
    getSearchParameters,
    getCommonSearchParams,
    getCheapestBofhTopOfferParams,
    getVclidCookie,
    selectUserEmail,
    getUser,
    getOptimizedRoomsConfig,
    getUrlParams
  ],
  (
    hotelId,
    searchParams,
    commonSearchParams,
    hotelCardTopOfferParams,
    vclidCookie,
    userEmail,
    user,
    rooms,
    urlParams
  ) => {
    //deconstructing undefined when called prematurely
    // - throwing an error for offers mounted on SRP via useHandleScrollToClickedOfferTarget
    //   (calls rooms internally) called in useHandleOfferClick
    //   would be good to avoid it somehow?
    const {checkIn, checkOut} = searchParams || {}

    const {cugDeals, tier, label} = commonSearchParams
    const metadata = getMetadata(undefined, undefined, vclidCookie)
    const emailDomain = getEmailDomain(userEmail)
    const userId = getUserId(user)
    const {profile: originId} = urlParams || {}
    return {
      tier,
      label,
      rooms,
      checkIn,
      checkOut,
      cugDeals: cugDeals.join(','),
      hotelId,
      metadata,
      emailDomain,
      userId,
      originId,
      ...hotelCardTopOfferParams,
      ...getRoomsSearchParamsFromApUrl()
    }
  }
)

/**
 * Select result from the RTKQ cache by the cache key for the current top offers request
 */
const getTopOffersResult = (state: RootState) => {
  const hotelId = getAccommodationPageHotelId(state)
  const params = getTopOffersParameters(state, hotelId)
  return searchApi.endpoints.getOffers.select(params)(state)
}

/**
 * Select result from the RTKQ cache by the cache key for the current all offers request
 */
const getAllOffersResult = (state: RootState) => {
  const hotelId = getAccommodationPageHotelId(state)
  const params = getAllOffersParameters(state, hotelId)
  return searchApi.endpoints.getOffers.select(params)(state)
}

/**
 * Select result from the RTKQ cache by the cache key for the current rooms request
 */
export const getRoomsResult = (state: RootState) => {
  const hotelId = getAccommodationPageHotelId(state)
  const params = getHotelApiRoomsParameters(state, hotelId)
  return searchApi.endpoints.getRooms.select(params as GetRoomsParams)(state)
}

const EMPTY_FILTER_RESULTS: Omit<
  FilteredRoomsData,
  'hotelId' | 'searchId' | 'anonymousId'
> = {
  rooms: [],
  availableFilters: {},
  totalResults: undefined,
  isSplitBookingAvailable: false,
  splitBooking: undefined
}
/**
 * Get rooms with filters applied for the current rooms request
 */
export const getFilteredRoomsData = createSelector(
  [getRoomsResult, getRoomsActiveFilters],
  (result, filters) =>
    result?.data
      ? applyRoomsFilterMemo(result.data, filters)
      : EMPTY_FILTER_RESULTS
)

/**
 * Get searchId for top offers (if available)
 */
const getTopOffersSearchId = (state: RootState) => {
  const {data} = getTopOffersResult(state)
  return data?.searchId
}

/**
 * Get searchId for all offers (if available)
 */
const getAllOffersSearchId = (state: RootState) => {
  const {data} = getAllOffersResult(state)
  return data?.searchId
}

/**
 * Get searchId for currently visible offers
 */
export const getCurrentOffersSearchId = (state: RootState) => {
  const isAllOffersOpen = Boolean(getAllOffersOverlay(state))
  const isAccommodationPage = getIsAccommodationPage()
  const isHotelDetailsOpen = getHotelDetailsVisibility(state)

  if (isAllOffersOpen) {
    return getAllOffersSearchId(state)
  }

  if (isAccommodationPage || isHotelDetailsOpen) {
    return getTopOffersSearchId(state)
  }

  return getSearchId(state)
}

/**
 * Returns searchId from either SAPI.search or SAPI.offersSearch, depends on the exp
 * @param state App root state
 * @returns search id
 */
export const getSearchIdFromConditionalSource = state => {
  const sapiSearchId = getSearchId(state)
  const currentSearchId = getCurrentOffersSearchId(state)
  const searchId = currentSearchId || sapiSearchId

  return searchId
}

/**
 * Override some properties of the analytics SearchContext for searchApi fetches
 */
export const getOffersSearchContext = (state: RootState) => {
  const {rooms, checkIn, checkOut} = getSearchParameters(state)
  return {
    ...getCommonSearchParams(state),
    rooms,
    checkIn,
    checkOut,
    searchId: getCurrentOffersSearchId(state),
    anchorHotelId: getAccommodationPageHotelId(state),
    isNoDates: false,
    isDefaultDates: false,
    placeId: null,
    isOffersOnlyFetch: true
  }
}

/**
 * Apply the override when relevant
 */
export const getSearchContextOffersOnlySearchOverride = (state: RootState) => {
  const isAccommodationPage = getIsAccommodationPage()
  const isHotelDetailsOpen = getHotelDetailsVisibility(state)

  if (isAccommodationPage || isHotelDetailsOpen) {
    const isUsingHotelApi = Boolean(getTopOffersSearchId(state))
    return isUsingHotelApi ? getOffersSearchContext(state) : {}
  }

  return {}
}

/**
 * Returns true if rooms list is empty.
 * Returns false when rooms is fetching or loading
 * Returns false when rooms are not empty
 */
export const getIsRoomsResultEmpty = createSelector(
  [getRoomsResult],
  result => result?.data?.rooms?.length === 0 && !result?.data?.splitBooking
)

export const getIsCheapestRoomOffer = (state: RootState, offerId: string) => {
  const rooms = getRoomsResult(state)
  const cheapestRoom = rooms?.data?.rooms?.[0]
  const cheapestOffer = cheapestRoom?.offers?.[0]
  const isCheapestOffer = cheapestOffer?.id === offerId
  return isCheapestOffer
}

/**
 * Returns true rooms result are not fetched yet
 */
export const getIsRoomsQueryLoading = createSelector(
  [getRoomsResult],
  result => result.status !== QueryStatus.fulfilled
)

export const getIsFilteredRoomsResultEmpty = createSelector(
  [getFilteredRoomsData],
  result => result?.rooms?.length === 0 && !result?.splitBooking
)
