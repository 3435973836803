import {
  updateLocation,
  UpdateLocationPayload
} from 'modules/common/actions/location'
import {INVALID_SEARCH} from 'modules/search/actions/invalidSearch'
import {history} from 'utils'
import {getHomeUrlWithUTMParams} from 'utils/utmParams'

import {MiddlewareType} from '..'
import {REDIRECT_EXTERNAL} from './actions/externalRedirect'

const middleware: MiddlewareType = () => next => action => {
  switch (action.type) {
    case REDIRECT_EXTERNAL: {
      window.location.replace(action.url)
      break
    }

    case INVALID_SEARCH: {
      const href = getHomeUrlWithUTMParams()
      window.location.href = href
      break
    }

    case updateLocation.type: {
      const {method, fullPath, state, delay}: UpdateLocationPayload =
        action.payload

      // Determine which method is used to update the location
      switch (method) {
        case 'navigate':
          if (delay) {
            setTimeout(() => {
              window.location.href = fullPath
            }, delay)
          } else {
            window.location.href = fullPath
          }
          break

        case 'push': {
          history.push(fullPath, state)
          break
        }

        case 'replace': {
          history.replace(fullPath, state)
          break
        }

        default: {
          break
        }
      }

      break
    }

    default: {
      break
    }
  }

  return next(action)
}

export default middleware
