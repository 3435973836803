import {memo, ReactElement, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {
  getAuthenticationFlow,
  getPasswordlessLoginSourceComponent,
  getUnlockHotelId,
  isPasswordlessLoginVisible,
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {
  cleanUpLoginErrors,
  hidePasswordlessLogin
} from '@daedalus/core/src/auth/modules/slice'
import {
  makeSocialLogin,
  SocialConnections
} from '@daedalus/core/src/auth/modules/thunks'
import {
  AuthenticationFlows,
  ShowPasswordlessLoginPayload,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {Brand} from '@daedalus/core/src/brand/types'

import {useLogIn} from '../../hooks/useLogIn'

const AUTHENTICATION_CLICK_TIMEOUT_BEFORE_NAVIGATING_AWAY = 500

export type HandleDesktopCodeSubmitProps = {
  codeOne: string
  codeTwo: string
  codeThree: string
  codeFour: string
}

export type HandleMobileCodeSubmitProps = {
  code: string
}

export type ChildrenProps = {
  isOpen: boolean
  authenticationFlow: AuthenticationFlows | null
  handleCloseOverlay: () => void
  handleSocialLoginClick: (
    socialConnection: SocialConnections,
    sourceComponent?: ShowPasswordlessLoginPayload['sourceComponent']
  ) => void
}

interface PasswordlessLoginPropsType {
  languageCode: string
  anonymousId: string
  brand: Brand
  children: (props: ChildrenProps) => ReactElement<ChildrenProps>
}

const PasswordlessLogin = ({
  anonymousId,
  children
}: PasswordlessLoginPropsType) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const logIn = useLogIn()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = useSelector(selectIsLoading)
  const isOpen = useSelector(isPasswordlessLoginVisible)
  const authenticationFlow = useSelector(getAuthenticationFlow)
  const unlockHotelId = useSelector(getUnlockHotelId)
  const sourceComponent = useSelector(getPasswordlessLoginSourceComponent)

  const {
    location: {search}
  } = history

  const handleCloseOverlay = () => {
    dispatch(cleanUpLoginErrors())
    dispatch(hidePasswordlessLogin())
  }

  const handleSocialLoginClick = (socialConnection: SocialConnections) => {
    setTimeout(
      () =>
        dispatch(
          makeSocialLogin({
            connection: socialConnection,
            unlockHotelId,
            sourceComponent
          } as {
            connection: SocialConnections
            unlockHotelId: string
            sourceComponent: SourceComponentType
          })
        ),
      AUTHENTICATION_CLICK_TIMEOUT_BEFORE_NAVIGATING_AWAY
    )
  }

  useEffect(() => {
    const susiQueryParam = 'susi'
    const queryParams = new URLSearchParams(location.search)
    const hasSusiInUrl = queryParams.get(susiQueryParam) === 'true'

    if (!isLoading && !isAuthenticated && hasSusiInUrl) {
      logIn({
        sourceComponent: SourceComponentType.Login,
        flow: AuthenticationFlows.SignUp,
        anonymousId
      })
      queryParams.set(susiQueryParam, 'false')
      return history.replace({search: queryParams.toString()})
    }

    if (isAuthenticated && hasSusiInUrl) {
      queryParams.delete(susiQueryParam)
      history.replace({search: queryParams.toString()})
    }
  }, [search, isLoading, isAuthenticated, anonymousId, history, logIn])

  return children({
    isOpen,
    authenticationFlow,
    handleCloseOverlay,
    handleSocialLoginClick
  })
}

export default memo<PasswordlessLoginPropsType>(PasswordlessLogin)
