import {createSelector} from '@reduxjs/toolkit'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

interface AclResponse {
  isCustomer: boolean
  aclDecision: string
}

const SAPI_BACKEND_URL = 'https://sapi.fih.io'

export const aclApi = createApi({
  reducerPath: 'acl',
  baseQuery: fetchBaseQuery({
    baseUrl: SAPI_BACKEND_URL
  }),
  endpoints: builder => ({
    fetchAcl: builder.query<AclResponse, string>({
      query: anonymousId => ({
        url: `user?anonymousId=${anonymousId}`,
        method: 'GET'
      })
    })
  })
})

export const {useFetchAclQuery} = aclApi

export const selectAclDecision = (
  state: unknown,
  anonymousId: string
): AclResponse => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - I will fix the state type soon @gabrielmanara
    (aclApi.endpoints.fetchAcl.select(anonymousId)(state)
      ?.data as unknown as AclResponse) ?? null
  )
}

export const selectIsReturningCustomer = createSelector(
  [selectAclDecision],
  result => Boolean(result?.isCustomer)
)
