import axios from 'axios'
import Settings from 'Settings'

import {getUserSessionService} from '@daedalus/core/src/auth/services'
import {UserTier} from '@daedalus/core/src/offer/business/privateDeals'

const axiosInstance = axios.create()
const MEMBER_REFERRAL_URL = `${Settings.get(
  'MEMBERSHIP_API_ENDPOINT'
)}referral/promote`

export interface ReferMemberResponseData {
  referredUpdated: boolean
  referredTiers: UserTier
  referrerTiers: UserTier
}

export const referMember = async (referrerId: string) => {
  const {accessToken} = await getUserSessionService()
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  }

  const {data} = await axiosInstance.post<ReferMemberResponseData>(
    MEMBER_REFERRAL_URL,
    JSON.stringify({referrerId}),
    config
  )

  return data
}
