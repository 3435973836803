import {defineMessages} from 'react-intl'
import {Hotel} from 'modules/sapiSearch/slice'
import {Lov, LovHit} from 'modules/search/slice'
import {clamp, sort} from 'ramda'
import {FacilityCategory, GroupedFacilities} from 'types/Hotel'
import {FacetType} from 'types/Search'

import {
  ContentHotel,
  TranslatedLOVItem
} from '@findhotel/sapi/dist/types/packages/core/src'

import {FacetGroupItem} from '../components/Filters/FacetFilter'

type HotelFacilityIds = Hotel['facilities']

export const MAX_MOBILE_POPULAR_FACILITIES = 10

/**
 * List of ranked facilities as described here:
 * https://docs.google.com/spreadsheets/d/1hKOQc8GNcj7EY696t5GpJduKIa1QAsK668Lepg5YTRk/edit#gid=0
 */
export const TOP_FACILITY_IDS = [
  6, // Parking
  9, // Airport shuttle
  8, // Pet Friendly
  299, // Free Wi-Fi
  5, // Restaurant
  61, // Hot Tub / Jacuzzi
  264, // Smoking Room
  7, // Swimming pool
  193, // Spa & Wellness Centre
  4, // Fitness Room/Gym
  221, // Pool Indoor
  50, // Kitchen
  211, // Private Bathroom
  18, // 24-Hour Reception
  63, // Casino
  35, // Sauna
  293, // Fireplace
  244, // Breakfast in the Room
  22, // Non-Smoking Rooms
  210, // En suite
  266, // Family Room
  40, // Refrigerator
  21, // Air Conditioned
  51, // Coffee / Tea Maker
  60, // Laundry service
  56, // TV
  17, // Elevator / Lift
  223, // Outdoor heated pool
  20, // Babysitting / Child Services
  191 // Massage
]

const POPULAR_FACILITIES = new Set(TOP_FACILITY_IDS.map(i => i.toString()))

export const FACILITY_GROUPS: FacetGroupItem[] = [
  {
    type: 'popular',
    facetIds: POPULAR_FACILITIES
  }
]

/**
 * Get hotel facilities sorted by most popular up to the limit provided, adding unsorted facilities at the end
 */
export const getHotelFacilitiesRanked = (
  hotelFacilities: TranslatedLOVItem[],
  topFacilityIds: HotelFacilityIds,
  limit: number
): TranslatedLOVItem[] => {
  const max = clamp(0, hotelFacilities.length, limit)
  const ranked: typeof hotelFacilities = []
  const unRanked: typeof hotelFacilities = []

  for (let index = 0; index < max; index += 1) {
    const facility = hotelFacilities[index]
    const {id} = facility
    const topIndex = topFacilityIds.indexOf(id)
    if (topIndex === -1) {
      unRanked.push(facility)
    } else {
      ranked[topIndex] = facility // Creates a sparse array with gaps
    }
  }
  /* eslint-enable */

  // Remove the gaps
  return [...ranked.filter(Boolean), ...unRanked]
}

/**
 * Returns the top 10 ranked facilities in order
 */
export const getRankedAvailableFacilities = (
  facilities: Hotel['facilities'] | undefined,
  listOfValues: Record<string, LovHit>
): LovHit[] => {
  if (!listOfValues || !facilities) return []

  const facilitiesAsLovHits = getAvailableFacilities(facilities, listOfValues)

  return getRankedFacilities(facilitiesAsLovHits)
}

/**
 * Returns the top 10 ranked facilities in order from a ContentHotel (with listOfValues already merged)
 */
export const getRankedFacilitiesFromContentHotel = (
  hotel: ContentHotel
): LovHit[] => {
  if (!hotel?.facilities) return []

  // Small remapping to match Hotel/ContentHotel types
  const facilitiesAsLovHits = hotel.facilities.map(
    (facility: TranslatedLOVItem): LovHit => ({
      ...facility,
      objectID: `Facility:${facility.id}`
    })
  )

  return getRankedFacilities(facilitiesAsLovHits)
}

/**
 * Returns the top 10 ranked facilities in order
 */
const getRankedFacilities = (facilities: LovHit[]): LovHit[] => {
  const rankedFacilities = facilities
    .filter(({id}) => TOP_FACILITY_IDS.includes(id))
    .sort(
      (a, b) => TOP_FACILITY_IDS.indexOf(a.id) - TOP_FACILITY_IDS.indexOf(b.id)
    )
    .slice(0, 10)

  return rankedFacilities
}

/**
 * Get hotel facilities from the list of values
 */
export const getAvailableFacilities = (
  facilities: HotelFacilityIds,
  listOfValues: Lov
): LovHit[] => {
  if (!listOfValues || !facilities) return []

  return facilities.map(
    (facilityId: number) => listOfValues[`Facility:${facilityId}`]
  )
}

/**
 * Get content hotel facilities from the list of values
 */
// Added as part of sapi4eva-hotel-descriptions
export const getAvailableFacilitiesContentHotel = (
  facilities: ContentHotel['facilities'],
  listOfValues: Lov
): LovHit[] => {
  if (!listOfValues || !facilities) return []

  return facilities.map(
    (facility: TranslatedLOVItem) => listOfValues[`Facility:${facility.id}`]
  )
}

// Parent categories to group facilities
export const facilityCategories: FacilityCategory[] = [
  {
    id: 'general',
    categories: [1, 3, 7, 9, 10, 14, 17]
  },
  {
    id: 'activities',
    categories: [2, 8, 11, 15, 18]
  },
  {
    id: 'internet',
    categories: [13]
  },
  {
    id: 'parking',
    categories: [19]
  },
  {
    id: 'services',
    categories: [4, 5, 6, 12, 16]
  }
]

export const facilityCategoryTitleMessages = defineMessages({
  general: {
    id: 'general',
    defaultMessage: 'General'
  },
  activities: {
    id: 'activities',
    defaultMessage: 'Activities'
  },
  services: {
    id: 'services',
    defaultMessage: 'Services'
  },
  internet: {
    id: 'internet',
    defaultMessage: 'Internet'
  },
  parking: {
    id: 'parking',
    defaultMessage: 'Parking'
  }
})

// A flat map of categoryID:parentID
const facilityParentCategoriesMap: Record<number, string> = {}
for (const parentCategory of facilityCategories) {
  for (const categoryId of parentCategory.categories) {
    facilityParentCategoriesMap[categoryId] = parentCategory.id
  }
}

/**
 * Get the parent category for a particular facility
 */
export const getParentCategoryId = (facility: LovHit) =>
  facilityParentCategoriesMap[facility.categoryID]

/**
 * Get facilities from a parent category
 */
export const getCategoryFacilities = (
  facilities: (LovHit | TranslatedLOVItem)[] | undefined,
  categories: number[]
): string[] | undefined => {
  if (!facilities) return undefined
  if (facilities.length === 0) return []
  const categoryFacilities = facilities.reduce((out, facility) => {
    return categories.includes(facility.categoryID)
      ? out.concat(facility.value)
      : out
  }, [])
  return categoryFacilities
}

/**
 * Get grouped facilities from a parent category
 */
export const getGroupedFacilities = (
  facilities: TranslatedLOVItem[],
  categories: FacilityCategory[]
): GroupedFacilities[] => {
  if (!facilities) return []
  const groupedFacilities = new Set<GroupedFacilities>()
  categories.forEach(item => {
    const groupedItems = getCategoryFacilities(facilities, item.categories)
    if (groupedItems && groupedItems.length > 0)
      groupedFacilities.add({categoryId: item.id, facilities: groupedItems})
  })
  return [...groupedFacilities]
}

/**
 * Get facets sorted alphabetically
 */
export const getFacetsSortedAlphabetically = (
  facets: FacetType[],
  getLabel: (facetId: string) => string
): FacetType[] => {
  try {
    if (!facets || facets?.length === 0) return []
    if (!getLabel) return facets

    return sort(
      (a: FacetType, b: FacetType) =>
        getLabel(a.id)?.localeCompare(getLabel(b.id)),
      facets ?? []
    )
  } catch (error) {
    console.error('getFacetsSortedAlphabetically Error', error)
    return []
  }
}
