import React, {useState} from 'react'
import {useDispatch} from 'react-redux'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

export const MobileIcon = () => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOnOpen = () => {
    setIsBottomSheetOpen(true)

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceAlertBanner,
        action: Action.Clicked,
        team: Team.Retention
      })
    )
  }

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        onKeyDown={() => handleOnOpen()}
        onClick={() => handleOnOpen()}
      >
        <Icon name="Information" size="sm" colorPath="content.neutral.c600" />
      </span>

      <BottomSheet
        isOpen={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}
        titleBarProps={{
          centerContent: (
            <FormattedMessageWrapper
              id="priceAlerts"
              defaultMessage="Price alerts"
            />
          )
        }}
      >
        <ContentWrapper paddingTop="s500" paddingBottom="s300">
          <Text variant="titleL">
            <FormattedMessageWrapper
              id="priceWatchPopUp.title"
              defaultMessage="Never miss a price drop"
            />
          </Text>
        </ContentWrapper>
        <ContentWrapper paddingBottom="s500">
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="priceAlertOnboardingDescription"
              defaultMessage="We actively monitor prices in the background and will notify you when the prices change."
            />
          </Text>
        </ContentWrapper>

        <ContentWrapper paddingTop="s400" paddingBottom="s400">
          <Button
            variant="secondary"
            fullWidth
            onClick={() => setIsBottomSheetOpen(false)}
          >
            <FormattedMessageWrapper
              id="priceAlertOnboardingConfirmation"
              defaultMessage="Got it"
            />
          </Button>
        </ContentWrapper>
      </BottomSheet>
    </>
  )
}
