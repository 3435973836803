import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SAW_PRICE_WATCH_OVERLAY} from 'components/PriceWatch/services'
import updateLocation from 'modules/common/actions/location'
import {getLocalTaxesIncluded, getTaxesIncluded} from 'modules/meta/selectors'
import {
  getHotel,
  getHotelHasOffers,
  getOfferEntity,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'
import {append, propEq, reject} from 'ramda'
import {AppThunk} from 'store'
import {
  getCheapestOfferTotalRate,
  getCheapestTopOfferByNightlyRate
} from 'utils/offers'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContextList,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {FlowType} from '@daedalus/core/src/priceWatch/analytics'
import {selectIsHoldOutGroup} from '@daedalus/shared/src/priceWatch/slice/selectors'

import {
  getHotelDetailsOverlayInfo,
  getHotelDetailsVisibility,
  getOverlayVisibility
} from './selectors'
import {HotelDetailsOverlayInfo, Overlay, OverlayType} from './types'

export type OverlayStateType = Overlay[]
export type StateType = {overlay: OverlayStateType}

export type GetStateType = () => OverlayStateType

export interface ShowStatelessOverlayPayload {
  type: OverlayType
}

export const initialState: OverlayStateType = []

const {actions, reducer} = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    showHistoryless: (
      state,
      {payload}: PayloadAction<ShowStatelessOverlayPayload>
    ) => {
      return [{type: payload.type}]
    },
    hideOverlay: state => {
      if (state.length > 0) history.back()
    },
    hideHistoryless: () => {
      return initialState
    },
    setOverlayState: (state, {payload}: PayloadAction<OverlayStateType>) => {
      if (payload.length === 0) return initialState
      return payload
    }
  }
})

/**
 * Common Overlay actions
 */
export const showOverlay =
  <Type extends OverlayType>({
    type,
    params,
    entity,
    component,
    analyticsContext,
    team = Team.Default,
    payload,
    locationParams = {},
    mergeWithCurrentParams
  }: {
    type: Type
    entity?: Entity
    params?: Omit<Extract<Overlay, {type: Type}>, 'type'> // Params will be enforced based on type, this relies on Overlay union correctness
    component?: string
    analyticsContext?: AnalyticsContextList
    team?: Team
    payload?: Record<string, unknown>
    locationParams?: Record<string, unknown>
    mergeWithCurrentParams?: boolean
  }): AppThunk =>
  (dispatch, getState): void => {
    const store = getState()
    const {overlay = []} = store
    const resetOverlayState = reject(propEq('type', type))(overlay)
    const updatedOverlayState = append({type, ...params}, resetOverlayState)

    dispatch(
      updateLocation({
        basePath: '',
        params: locationParams,
        state: {
          overlay: updatedOverlayState
        },
        mergeWithCurrentParams
      })
    )

    // While we transition to new events entity is optional
    // this check should be removed once all events have been moved across
    if (typeof entity !== 'undefined') {
      dispatch(
        trackEvent({
          category: Category.System,
          entity,
          action: Action.Displayed,
          component,
          analyticsContext,
          team,
          payload
        })
      )
    }
  }

export const showAPOverlay =
  <Type extends OverlayType>({
    params,
    analyticsContext,
    payload,
    locationParams = {}
  }: {
    params?: Omit<Extract<HotelDetailsOverlayInfo, {type: Type}>, 'type'>
    analyticsContext?: AnalyticsContextList
    payload?: Record<string, unknown>
    locationParams?: Record<string, unknown>
  }): AppThunk =>
  (dispatch, getState): void => {
    const store = getState()

    const isApOverlayOpen = getHotelDetailsVisibility(store)

    if (isApOverlayOpen) {
      return
    }

    dispatch(
      showOverlay({
        type: OverlayType.HotelDetails,
        entity: Entity.HotelDetails,
        team: Team.Select,
        params,
        locationParams,
        analyticsContext,
        payload
      })
    )
  }

export const overlayMiddleware = store => next => action => {
  if (actions.hideOverlay.match(action)) {
    const state = store.getState()

    const isHotelDetails = getOverlayVisibility(state, OverlayType.HotelDetails)
    const sawPriceWatchOverlay = Boolean(getCookie(SAW_PRICE_WATCH_OVERLAY))
    const {hotelId} = getHotelDetailsOverlayInfo(state)
    const hotelHasOffers = getHotelHasOffers(state, hotelId)
    const isAuthenticated = getIsAuthenticated(state)
    const isHoldOutGroup = selectIsHoldOutGroup(state)

    const hotel = getHotel(state, hotelId)

    const offerEntity = getOfferEntity(state, hotel?.objectID)
    const cheapestOffer = getCheapestTopOfferByNightlyRate(offerEntity?.offers)
    const localTaxesIncluded = getLocalTaxesIncluded(state)
    const taxesIncluded = getTaxesIncluded(state)
    const cheapestTotalRate = getCheapestOfferTotalRate(offerEntity?.offers)
    const shouldShowTotal = getShowTotalPrices(state)

    const rate = {
      baseRate: cheapestOffer?.rate.base,
      localTaxes: cheapestOffer?.rate.hotelFees,
      taxes: cheapestOffer?.rate.taxes,
      localTaxesIncluded,
      taxesIncluded,
      currency: cheapestOffer?.currency,
      shouldShowTotal,
      nightlyRate: cheapestOffer?.nightlyRate,
      totalRate: cheapestTotalRate
    }

    const TIMEOUT_BEFORE_SHOWING_OVERLAY = 500 // 500ms

    if (
      isHotelDetails &&
      !sawPriceWatchOverlay &&
      !isAuthenticated &&
      !isReactNativeWebView() &&
      !isHoldOutGroup &&
      hotelHasOffers
    ) {
      setTimeout(() => {
        store.dispatch(
          showOverlay({
            type: OverlayType.PriceWatchOverlay,
            params: {
              hotel,
              rate,
              flow: FlowType.AP_TO_SRP
            }
          })
        )
      }, TIMEOUT_BEFORE_SHOWING_OVERLAY)
    }
  }

  return next(action)
}

export default reducer

export const {showHistoryless, hideOverlay, hideHistoryless, setOverlayState} =
  actions
