import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {MobileOverlays} from 'components/MobileOverlays/MobileOverlays'
import {PreferencesOverlay} from 'components/PreferencesOverlay'
import {SplashScreenRemover} from 'components/SplashScreenRemover'
import TrackPageView from 'components/TrackPageView'
import {getSapiMetaOptions} from 'modules/meta/selectors'
import {SapiProvider} from 'modules/sapiSearch/SapiProvider'
import Settings from 'Settings'

import {Page} from '@daedalus/core/src/analytics/types/Events'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {collectSapiVariationsParam} from '@daedalus/core/src/experiments/sapi'
import {ProfileKey} from '@findhotel/sapi'

export const Preferences = () => {
  const sapiMetaOptions = useSelector(getSapiMetaOptions)
  const dataFile = useSelector(getDataFile)
  const variations = collectSapiVariationsParam(
    dataFile?.featureFlags.map(({key}) => key)
  )
  const generateOptions = useCallback(
    () => ({
      ...sapiMetaOptions,
      variations
    }),
    [sapiMetaOptions, variations]
  )

  return (
    <>
      <TrackPageView pageName={Page.Profile} />
      <SplashScreenRemover />
      <PreferencesOverlay forceOpen />

      {/* TODO (@Select): SAPI is required for watchlist overlays, can be removed when it is moved to a page */}
      <SapiProvider
        profileKey={Settings.get('CLIENT_SAPI_PROFILE_KEY') as ProfileKey}
        generateOptions={generateOptions}
      >
        <MobileOverlays />
      </SapiProvider>
    </>
  )
}
