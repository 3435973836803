import {GuestType} from 'types/Hotel'

import {toPercentage} from '@daedalus/core/src/utils/number'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src/types'

import {RATING_EXCLUDED_FROM_DISPLAY} from './constants'

type GuestTypeKey = keyof GuestType

const getTotalNumberOfGuests = (guestTypes: Partial<GuestType>): number =>
  Object.keys(guestTypes).reduce(
    (acc, key: GuestTypeKey) => acc + guestTypes[key],
    0
  )

export const calculateGuestTypePercentages = (
  guestTypes: GuestType
): Array<{key: GuestTypeKey; value: number}> => {
  const total = getTotalNumberOfGuests(guestTypes)

  return Object.keys(guestTypes).map((key: GuestTypeKey) => {
    return {key, value: toPercentage(guestTypes[key], total) || 0}
  })
}

export const getReviewAvailability = (hotel: ContentHotel) => {
  const hasReviews =
    Boolean(hotel?.reviewCount) && Boolean(hotel?.guestRating?.overall)

  const hasSentiments = (hotel?.sentiments || []).length > 0

  const hasGuestsTypes = getTotalNumberOfGuests(hotel?.guestType || {}) > 0

  const hasSummary =
    Object.keys(hotel?.guestRating || {}).filter(
      key => !RATING_EXCLUDED_FROM_DISPLAY.includes(key)
    ).length > 0

  const hasAnyReviewComponent =
    hasSentiments || hasSummary || hasGuestsTypes || hasReviews

  return {
    hasAnyReviewComponent,
    hasReviews,
    hasSentiments,
    hasGuestsTypes,
    hasSummary
  }
}
