import {IntlShape} from 'react-intl'
import {filterMessages} from 'components/Filters/filterMessages'
import {Hotel} from 'modules/sapiSearch/slice'
import {FilterUrlParams} from 'modules/search/types'
import {getFacetLabel} from 'utils/filters'

import {Lov} from '@daedalus/core/src/analytics/types/Events'

export function getMatchingFilters(
  filters: FilterUrlParams,
  hotel: Hotel,
  listOfValues: Lov,
  formatMessage: IntlShape['formatMessage'],
  starRatingLabel: string,
  currencySymbol = '$'
) {
  const matchingFilters: Record<string, boolean> = {}
  const offerLevelMatchedFilters: Record<string, boolean> = {}

  if (filters?.facilities) {
    for (const facility of filters.facilities) {
      const facilityName = getFacetLabel('Facility', listOfValues)(facility)
      matchingFilters[facilityName] = (hotel?.facilities || [])?.includes(
        parseInt(facility)
      )
    }
  }

  if (filters?.starRatings && hotel.starRating) {
    matchingFilters[starRatingLabel] = filters.starRatings.includes(
      hotel.starRating.toString()
    )
  }

  if (filters?.reviewScore) {
    const reviewScoreLabel = formatMessage(
      {
        id: 'guestRatingsWithValue',
        defaultMessage: '{value}+ rating'
      },
      {
        value: Number(filters.reviewScore).toFixed(1)
      }
    )
    matchingFilters[reviewScoreLabel] =
      parseInt(filters.reviewScore) <= hotel.guestRating.overall
  }

  if (filters?.themes) {
    for (const theme of filters.themes) {
      const themeLabel = getFacetLabel('Theme', listOfValues)(theme)
      matchingFilters[themeLabel] = (hotel?.themeIds || [])?.includes(
        parseInt(theme)
      )
    }
  }

  if (filters?.propertyTypes) {
    for (const property of filters.propertyTypes) {
      const propertyLabel = getFacetLabel(
        'PropertyType',
        listOfValues
      )(property)
      matchingFilters[propertyLabel] = filters?.propertyTypes.includes(
        String(hotel?.propertyTypeId)
      )
    }
  }

  // Offer level filters
  if (filters?.amenities) {
    for (const meal of filters.amenities) {
      const mealsLabel = formatMessage(filterMessages[meal])
      offerLevelMatchedFilters[mealsLabel] = true
    }
  }

  if (filters?.freeCancellation) {
    const freeCancellationLabel = formatMessage(
      filterMessages.freeCancellationTitle
    )
    offerLevelMatchedFilters[freeCancellationLabel] = true
  }

  if (filters?.priceMin && filters?.priceMax) {
    const priceLabel = `${currencySymbol}${filters?.priceMin} - ${currencySymbol}${filters?.priceMax}`

    offerLevelMatchedFilters[priceLabel] = true
  }

  if (filters?.sortField === 'privateDeals') {
    const gemDealsLabel = formatMessage(
      {id: 'privateDeals', defaultMessage: '{value} deals'},
      {value: 'Gems'}
    )
    offerLevelMatchedFilters[gemDealsLabel] = true
  }

  const offerLevelFilters = Object.entries(offerLevelMatchedFilters)
    .filter(([, value]) => value)
    .map(([key]) => key)
  const matchedFilters = Object.entries(matchingFilters)
    .filter(([, value]) => value)
    .map(([key]) => key)
  const unmatchedFilters = Object.entries(matchingFilters)
    .filter(([, value]) => !value)
    .map(([key]) => key)

  return {
    matchedFilters: [
      ...matchedFilters,
      ...(unmatchedFilters.length > 0 ? offerLevelFilters : [])
    ],
    unmatchedFilters
  }
}
