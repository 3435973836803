import React, {useContext} from 'react'
import LazyLoad from 'react-lazyload'
import {useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import placeHolderImage from 'images/placeholder-hotel-image.svg'
import {getHotel} from 'modules/sapiSearch/selectors'
import {imageProvider} from 'utils/imageProvider'
import {SelectedElement} from 'utils/selectedElement'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Image} from '@daedalus/atlas/Image'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'

import {GALLERY_WIDTH as GALLERY_WIDTH_EXPERIMENTAL} from '../ExperimentalNoOffersCard/constants'
import {useIsExperimentalNoOffersCardEnabled} from '../ExperimentalNoOffersCard/helpers'
import {GALLERY_WIDTH} from '../HotelCardLayout/HotelCardLayout'
import {GalleryLoading} from './GalleryLoading'

const LAZYLOAD_PLACEHOLDER_HEIGHT = 160
const LAZYLOAD_OFFSET = LAZYLOAD_PLACEHOLDER_HEIGHT * 8

export const Gallery = React.memo(function Gallery({
  isPriorityCard
}: {
  isPriorityCard: boolean
}) {
  const {hotelId} = useContext(HotelCardContext)
  const hotel = useSelector(state => getHotel(state, hotelId))
  const {brandMainHotelImageIndex} = useBrand()

  const isExperimentalNoOffersCardEnabled =
    useIsExperimentalNoOffersCardEnabled()

  if (!hotel) return <GalleryLoading />

  const primaryImage = hotel.imageURIs?.[brandMainHotelImageIndex || 0] ?? ''

  return (
    <LazyLoad
      once
      height={LAZYLOAD_PLACEHOLDER_HEIGHT}
      throttle={100}
      offset={LAZYLOAD_OFFSET}
    >
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100%"
        height="100%"
        width={`${isExperimentalNoOffersCardEnabled ? GALLERY_WIDTH_EXPERIMENTAL : GALLERY_WIDTH}px`}
        overflow="hidden"
      >
        <Box
          data-id={SelectedElement.Image}
          display="flex"
          flexDirection="column"
          flex="1"
          position="relative"
          justifyContent="center"
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            transition={isPriorityCard ? '' : 'opacity 500ms ease-in-out'}
          >
            <Image
              imageProvider={imageProvider}
              url={primaryImage}
              isResponsive
              size="mobileLarge"
              placeHolderImage={placeHolderImage}
              id={`HotelCardMobileImage-${hotelId}`}
            />
          </Box>
        </Box>
      </Box>
    </LazyLoad>
  )
})
