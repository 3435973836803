import React, {memo, useContext} from 'react'
import {HotelCardContext} from 'components/HotelCardContext'
import {useGetHotelAvailabilityForCurrentSearch} from 'hooks/useGetHotelAvailabilityForCurrentSearch'

import {Box} from '@daedalus/atlas/helpers/Box'

import {ComposedHotelCard} from './ComposedHotelCard/ComposedHotelCard'
import {ExperimentalNoOffersCard} from './ExperimentalNoOffersCard'
import {useIsExperimentalNoOffersCardEnabled} from './ExperimentalNoOffersCard/helpers'

export const HOTEL_CARD_MAX_WIDTH = 587

export const HotelCardMobileComponent = ({
  isPriorityCard
}: {
  isPriorityCard: boolean
}) => {
  const {isAnchor, hotelId} = useContext(HotelCardContext)
  useGetHotelAvailabilityForCurrentSearch({isAnchor, hotelId})

  const isExperimentalNoOffersCardEnabled =
    useIsExperimentalNoOffersCardEnabled()

  return (
    <Box
      data-id={isAnchor ? 'anchorHotel' : 'nonAnchorHotel'}
      maxWidth={`${HOTEL_CARD_MAX_WIDTH}px`}
      margin="0 auto"
      paddingBottom="s500"
    >
      {isExperimentalNoOffersCardEnabled ? (
        <ExperimentalNoOffersCard isPriorityCard={isPriorityCard} />
      ) : (
        <ComposedHotelCard isPriorityCard={isPriorityCard} />
      )}
    </Box>
  )
}

export const HotelCardMobile = memo(HotelCardMobileComponent)
