import {differenceInDays} from 'date-fns'

import {
  dateStringToMiddayDate,
  dateToMiddayDate
} from '@daedalus/core/src/utils/date'

/**
 * Determines whether the given `dateString` is yesterday
 */
export const isDateStringYesterday = (dateString: string): boolean => {
  const today = dateToMiddayDate(new Date())
  const date = dateStringToMiddayDate(dateString)

  return differenceInDays(date, today) === -1
}
