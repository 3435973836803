import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import GoogleStreetView from 'components/GoogleStreetView/GoogleStreetView'
import {
  StreetviewProvider,
  useStreetViewContext
} from 'components/GoogleStreetView/StreetviewProvider'
import {MapHotelCard} from 'components/Map/MapHotelCard'
import {OfferViewContext, OfferViewType} from 'context/offerViewContext'
import {getStreetViewOverlay} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {AccommodationFloatingHeader} from '@daedalus/shared/src/accommodation/AccommodationFloatingHeader'

import {AccommodationFooterContainer} from '../AccommodationView/Mobile/AccommodationFooterContainer'
import {HotelCardWrapper, OverlayContent} from './styles'

const StreetViewFooter = ({initialHotelId}: {initialHotelId: string}) => {
  const {selectedHotelId} = useStreetViewContext()

  if (selectedHotelId === initialHotelId) {
    return (
      <OfferViewContext.Provider value={OfferViewType.AP_FOOTER}>
        <AccommodationFooterContainer hotelId={selectedHotelId} />
      </OfferViewContext.Provider>
    )
  }

  return (
    <HotelCardWrapper>
      <MapHotelCard hotelId={selectedHotelId} openAccommodationPageOnClick />
    </HotelCardWrapper>
  )
}

export const StreetViewOverlay = () => {
  const dispatch = useDispatch()
  const streetViewOverlay = useSelector(getStreetViewOverlay)
  const streetViewOverlayIsActive = !!streetViewOverlay
  const handleHideOverlay = () => dispatch(hideOverlay())

  return (
    <Overlay
      enableBodyScrollLock
      isOpen={streetViewOverlayIsActive}
      shadeBackground={false}
      animationType={ANIMATION_TYPES.NONE}
    >
      <StreetviewProvider initialHotelId={streetViewOverlay?.hotelId}>
        <OverlayContent>
          {
            // This should be unmounted more gracefully on close
            streetViewOverlay?.hotelId && (
              <GoogleStreetView hotelId={streetViewOverlay?.hotelId} />
            )
          }
        </OverlayContent>
        <AccommodationFloatingHeader
          leftSlot={
            <Button
              variant="secondary"
              isRounded
              isFloating
              iconStart={<Icon name="Close" />}
              dataId="Close"
              onClick={handleHideOverlay}
              size="lg"
            />
          }
        />

        <StreetViewFooter initialHotelId={streetViewOverlay?.hotelId} />
      </StreetviewProvider>
    </Overlay>
  )
}
