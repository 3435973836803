import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {useSyncFilters} from 'components/Accommodation/AccommodationView/Desktop/useSyncFilters'
import {useCachedSearchTopOffers} from 'components/Accommodation/Offers/useCachedSearchTopOffers'
import Layout from 'components/Layouts'
import {SplashScreenRemover} from 'components/SplashScreenRemover'
import TrackPageView from 'components/TrackPageView'
import {useSyncStoreWithUrl} from 'hooks/useSyncStoreWithUrl'
import {
  ContentHotelProvider,
  useContentHotelContext
} from 'modules/accommodation/ContentHotelProvider'
import {resetAccommodationPageState} from 'modules/accommodation/slice'
import {getSapiMetaOptions} from 'modules/meta/selectors'
import {SapiProvider} from 'modules/sapiSearch/SapiProvider'
import {toggle} from 'opticks'
import Settings from 'Settings'
import {loadModule} from 'utils'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {
  TeamContextProvider,
  useDispatchTeamEvent
} from '@daedalus/core/src/analytics/components/TeamContext'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  HotelContext,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {collectSapiVariationsParam} from '@daedalus/core/src/experiments/sapi'
import {ProfileKey} from '@findhotel/sapi'

import {SearchBox as DesktopSearchBox} from '../../components/Accommodation/AccommodationView/Desktop/SearchBox'
import {useRedirectHome} from './useRedirectHome'

const Mobile = loadModule(
  async () =>
    import(
      /* webpackChunkName: "accommodationMobile" */
      './Mobile'
    ),
  {}
)

const Desktop = loadModule(
  async () =>
    import(
      /* webpackChunkName: "accommodationDesktop" */
      './Desktop'
    ),
  {}
)

export const convertHotelSlugToHotelId = (hotelSlug: string): string => {
  if (!hotelSlug) return ''

  const parts = hotelSlug.split('-')
  return parts[parts.length - 1]
}

interface AccommodationViewPathParams {
  hotelSlug?: string
}

const AccommodationViewContainer = () => {
  const sapiMetaOptions = useSelector(getSapiMetaOptions)
  const match = useRouteMatch()
  const {hotelSlug} = match.params as AccommodationViewPathParams
  const hotelId = convertHotelSlugToHotelId(hotelSlug)
  const dataFile = useSelector(getDataFile)
  const variations = collectSapiVariationsParam(
    dataFile?.featureFlags.map(({key}) => key)
  )

  useSyncStoreWithUrl()
  useRedirectHome({hotelId})
  useSyncFilters()

  const generateOptions = useCallback(
    () => ({
      ...sapiMetaOptions,
      pageSize: Settings.get('CLIENT_SEARCH_PAGE_SIZE'),
      variations
    }),
    [sapiMetaOptions, variations]
  )

  return (
    <TeamContextProvider team={Team.Select}>
      <TrackPageView pageName={Page.Accommodation} />
      <InjectPageDataId page={Page.Accommodation} />

      <SapiProvider
        profileKey={Settings.get('CLIENT_SAPI_PROFILE_KEY') as ProfileKey}
        generateOptions={generateOptions}
      >
        <ContentHotelProvider hotelId={hotelId}>
          <AccommodationView />
        </ContentHotelProvider>
      </SapiProvider>
    </TeamContextProvider>
  )
}

const AccommodationView = () => {
  const {isMobile, isDesktopMd, isDesktopLg} = useDeviceLayout()
  const searchBoxWithDestinationPicker = toggle(
    '8d14a737-rsp-v2-improvements',
    false,
    isDesktopMd || isDesktopLg
  )
  const {hotel} = useContentHotelContext()
  const {isComplete} = useCachedSearchTopOffers({hotelId: hotel?.objectID})
  const [isFirstLoad, setIsFirstLoad] = React.useState(true)
  const dispatchTeamEvent = useDispatchTeamEvent()
  const dispatch = useDispatch()

  useEffect(() => {
    if (hotel && isFirstLoad && isComplete) {
      const hotelId = hotel.objectID
      setIsFirstLoad(false)
      dispatchTeamEvent(
        trackEvent({
          category: Category.System,
          entity: Entity.HotelDetails,
          action: Action.Displayed
        })
      )
      // Added as part of events for sapi4eva-hotel-descriptions
      const {descriptions} = hotel
      const {version = 1} = descriptions || {}
      const hotelContext = {
        hotelId,
        isAIDescriptionEligible: version === 2
      } as HotelContext

      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.HotelDetails,
          action: Action.Viewed,
          analyticsContext: {
            [AnalyticsContext.HotelContext]: hotelContext
          }
        })
      )
      // END added as part of sapi4eva-hotel-descriptions

      dispatch(resetAccommodationPageState())
    }
  }, [hotel, isComplete, isFirstLoad, dispatch, dispatchTeamEvent])
  return (
    <>
      <SplashScreenRemover />
      <Layout
        hasHeaderFixedOnDesktop={searchBoxWithDestinationPicker}
        hasHeaderOnMobile={false}
        hasFooterOnMobile={false}
        hasSearchForm={!isMobile}
        customSearchBox={<DesktopSearchBox />}
      >
        {isMobile ? <Mobile /> : <Desktop />}
      </Layout>
    </>
  )
}

export default AccommodationViewContainer
