import React from 'react'
import {useSelector} from 'react-redux'
import {OfferPrice} from 'components/Offer/Shared/OfferPrice'
import {getShowTotalPriceToggle} from 'modules/sapiSearch/selectors'

import {OfferCompactLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface MainProps {
  hotelId: string
  isSplitBooking?: false
  offer: SearchOffer
  showAnchorPrice?: boolean
  isPrivateDeal?: boolean
  anchorPrice: number
  anchorPriceTotal: number
  variant: OfferCompactLayoutVariant
  showAppIcon?: boolean
  showLockIcon?: boolean
  isRequiredOneTimePasswordFlow?: boolean
}

interface SplitBookingProps {
  isSplitBooking: true
  hotelId: string
  offer?: never
  showAnchorPrice?: never
  isPrivateDeal?: never
  anchorPrice?: number
  anchorPriceTotal?: number
  variant?: never
  showAppIcon?: never
  showLockIcon?: never
  isRequiredOneTimePasswordFlow?: boolean
}

export const PriceComponent: React.FC<MainProps | SplitBookingProps> = ({
  anchorPriceTotal,
  anchorPrice,
  offer,
  showAnchorPrice,
  isPrivateDeal,
  hotelId,
  variant,
  isSplitBooking = false,
  showAppIcon = false,
  showLockIcon = false,
  isRequiredOneTimePasswordFlow
}) => {
  const showTotalPriceToggle = useSelector(getShowTotalPriceToggle)

  if (isSplitBooking) {
    return (
      <OfferPrice
        isSplitBooking
        showAnchorPrice={false}
        anchorPrice={anchorPrice}
        anchorPriceTotal={anchorPriceTotal}
        hotelId={hotelId}
        size="lg"
      />
    )
  }

  if (showTotalPriceToggle) {
    return (
      <OfferPrice
        showRowLayout={variant === 'CompactWithoutRoomName'}
        anchorPriceTotal={anchorPriceTotal}
        anchorPrice={anchorPrice}
        offer={offer}
        showAnchorPrice={showAnchorPrice}
        isPrivateDeal={isPrivateDeal}
        hotelId={hotelId}
        showAppIcon={showAppIcon}
        showLockIcon={showLockIcon}
        size="lg"
      />
    )
  }

  return (
    <OfferPrice
      offer={offer}
      showAnchorPrice={showAnchorPrice}
      isPrivateDeal={isPrivateDeal}
      hotelId={hotelId}
      anchorPrice={anchorPrice}
      shouldAlignOffer={false}
      size="lg"
      showAppIcon={showAppIcon}
      showLockIcon={showLockIcon}
      isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
      showRowLayout={variant === 'CompactWithoutRoomName'}
    />
  )
}
