import {loadModule} from 'utils'

const Search = loadModule(
  async () =>
    import(
      /* webpackMode: "eager" */
      /* webpackChunkName: "Search" */
      './SearchContainer'
    ),
  {}
)

export default Search
