import React from 'react'
import {useSelector} from 'react-redux'

import {Button} from '@daedalus/atlas/Button'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {getError} from '../../modules/selector'
import {DealFreezeCTASkelton} from './SkeletonLoading'

interface Props {
  isPageLoading: boolean
  handleCreateFreezeBooking: () => void
  ctaTitleText: React.ReactNode
}

export const FreezeCTA: React.FC<Props> = ({
  isPageLoading,
  handleCreateFreezeBooking,
  ctaTitleText
}) => {
  const {errored} = useSelector(getError)

  if (errored) return null

  if (isPageLoading)
    return (
      <Box display="flex" flexDirection="column" gap="s400" alignItems="center">
        <DealFreezeCTASkelton />
      </Box>
    )

  return (
    <Box display="flex" flexDirection="column" gap="s400" alignItems="center">
      <Text variant="labelS" colorPath="content.featured.c600">
        {ctaTitleText}
      </Text>
      <Button
        dataId="FreezeNowForFree"
        variant="primary"
        fullWidth
        onClick={handleCreateFreezeBooking}
        size="xl"
      >
        <FormattedMessageWrapper
          id="df.freezeOverlayButton"
          defaultMessage="Freeze Deal"
        />
      </Button>
    </Box>
  )
}
