import {useCallback, useEffect, useMemo, useRef} from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {differenceInDays, format, parseISO} from 'date-fns'
import {
  setSearchProgressPercentage,
  setSearchProgressPercentageIncrementally,
  setSearchProgressPercentageLinear
} from 'modules/progressBar/slice'
import {SearchProgressPercentage} from 'modules/progressBar/types'
import {invalidSearch} from 'modules/search/actions/invalidSearch'
import {useSaveUserSearchHistory} from 'modules/search/hooks/useSaveUserSearchHistory'
import {
  getForceSearch,
  getIsUserLocationSearch,
  getIsUserOrUrlLocationSearch,
  getUrlParams
} from 'modules/search/selectors'
import {setSearchData} from 'modules/search/slice'
import {SearchUrlParams as DaedalusSearchParameters} from 'modules/search/types'
import {
  setSearchBoxDestinationDisplayValue,
  setSearchBoxValues
} from 'modules/searchBox/slice'
import {setAudienceSegmentationAttributes} from 'opticks'
import {isEmpty, omit} from 'ramda'
import {ensureCheckInOnOrAfterToday, scrollTo} from 'utils'
import {isDateStringYesterday} from 'utils/dates'

import {removeCookie} from '@daedalus/core/src/_web/utils/cookies'
import {
  isReactNativeWebView,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {GeoLocationType as DaedalusGeoLocation} from '@daedalus/core/src/place/types'
import {SearchParameters} from '@daedalus/core/src/sapi/types'
import {getInitialSearchDates} from '@daedalus/core/src/searchParams/validateSearchParamDates'
// SAPI_TODO: better export
import {
  ApiSearchParameters as SapiSearchParameters,
  PlaceAnchor
} from '@findhotel/sapi/dist/types/packages/core/src/types'

import {SEARCH_PARAMS_TO_OMIT_FOR_SEARCHES} from './constants'
import {useSearchParams} from './hooks/useSearchParams'
import {useSapi} from './SapiProvider'
import {
  getHotelAvailabilityPricesComplete,
  getModuleState,
  getSearchParameters
} from './selectors'
import {
  anchorReceived,
  hotelsAvailabilityCompleted,
  hotelsAvailabilityReceived,
  hotelsAvailabilityRequested,
  hotelsReceived,
  ModuleState,
  moreHotelsRequested,
  newSearch,
  offersReceived,
  offersRequested,
  parametersReceived,
  searchCompleted,
  searchStarted,
  setNextPage
} from './slice'
import {useTrackSapiPreheat} from './useTrackSapiPreheat'
import {
  getSearchTypeFromSearchParameters,
  hotelOfferEntitiesHaveOffers
} from './utils'
// TODO: SAPI_TODO: @votsa [@findhotel.sapi >= 1] unit test || check another way of implementation
const isValidSapiSearchParameters = (searchParameters: SapiSearchParameters) =>
  !isEmpty(searchParameters) &&
  (searchParameters.hotelId !== undefined ||
    searchParameters.placeId !== undefined ||
    searchParameters.boundingBox !== undefined ||
    searchParameters.address !== undefined ||
    searchParameters.geolocation !== undefined)

const mapAreaMessages = defineMessages({
  mapArea: {
    id: 'mapArea',
    defaultMessage: 'Map area'
  }
})

export const userLocationMessages = defineMessages({
  userLocation: {
    id: 'nearMe',
    defaultMessage: 'Near me'
  },
  urlLocation: {
    id: 'nearMe',
    defaultMessage: 'Near me'
  }
})

export const useInitSearch = () => {
  const {searchFn} = useSapi()
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()
  const {updateLocation} = useUrlUpdater()
  const searchParameters = useSelector(getSearchParameters)
  const searchParams = useSearchParams(searchParameters)
  const urlParams = useSelector(getUrlParams)
  const saveSearchHistory = useSaveUserSearchHistory()
  const isUserLocationSearch = useSelector(getIsUserLocationSearch)
  const isUserOrUrlLocationSearch = useSelector(getIsUserOrUrlLocationSearch)

  useTrackSapiPreheat()

  const moduleState = useSelector(getModuleState)
  const hotelAvailabilityPricesComplete = useSelector(
    getHotelAvailabilityPricesComplete
  )

  // A new search may be triggered while one is in progress
  // The previous search's call backs will still be called, use latest searchId to reject them
  const currentSearchId = useRef(null)

  const runSearch = useCallback(
    async (searchParameters: SapiSearchParameters) => {
      if (searchFn === undefined) return

      dispatch(newSearch())
      removeCookie('X-Sapi-Preheat-Search-Id')

      const onParametersReceived = (searchParameters: SearchParameters) => {
        // TODO: SAPI_TODO: @votsa [@findhotel.sapi >= 1] Temp support for daedalus data format
        dispatch(
          // TODO: Search TS preexisting issue
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSearchBoxValues({
            ...searchParameters,
            // If geolocation is set, it means that we are doing a user location search. This should be updated in the search box.
            userLocationSearch:
              searchParameters?.geolocation !== undefined ? 1 : undefined
          } as DaedalusSearchParameters)
        )
        dispatch(parametersReceived({searchParameters}))
      }

      await searchFn(searchParameters, {
        onStart: response => {
          const {searchId} = response
          currentSearchId.current = searchId

          scrollTo(document.querySelector('html'), 0, 0)

          dispatch(searchStarted(response))
          dispatch(
            setSearchProgressPercentage(SearchProgressPercentage.SearchStarted)
          )
        },
        onAnchorReceived: response => {
          if (currentSearchId.current !== response.searchId) return

          const {anchor, searchParameters, searchId} = response

          // TODO: Search TS preexisting issue
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onParametersReceived(searchParameters)

          // TODO: SAPI_TODO: @votsa [@findhotel.sapi >= 1] Send undefined from sapi if no anchor found
          if (
            anchor.objectID === undefined &&
            searchParameters?.boundingBox === undefined
          ) {
            dispatch(invalidSearch())
            return
          }

          if (isUserLocationSearch) {
            const placeName = isUserOrUrlLocationSearch
              ? formatMessage(userLocationMessages.userLocation)
              : anchor?.navPathInfo?.city?.name

            const userLocationAnchor = {
              ...(anchor as PlaceAnchor),
              placeDisplayName: placeName,
              placeName
            }
            dispatch(
              setSearchBoxDestinationDisplayValue(
                userLocationAnchor.placeDisplayName
              )
            )

            dispatch(
              setSearchData({
                objectDisplayName: userLocationAnchor.placeDisplayName,
                _geoloc: userLocationAnchor._geoloc as DaedalusGeoLocation,
                placeCategory: (userLocationAnchor as PlaceAnchor)
                  .placeCategory,
                priceBucketWidth: userLocationAnchor.priceBucketWidth
              })
            )
            // TODO: Search TS preexisting issue
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(anchorReceived({...response, anchor: userLocationAnchor}))
            return
          }

          const isMapSearch = searchParameters?.boundingBox
          if (isMapSearch) {
            const placeName = formatMessage(mapAreaMessages.mapArea)

            const areaAnchor = {
              ...(anchor as PlaceAnchor),
              placeName,
              placeDisplayName: placeName
            }
            dispatch(
              setSearchBoxDestinationDisplayValue(areaAnchor.placeDisplayName)
            )

            dispatch(
              setSearchData({
                objectDisplayName: areaAnchor.placeDisplayName,
                _geoloc: areaAnchor._geoloc,
                placeCategory: areaAnchor.placeCategory,
                priceBucketWidth: areaAnchor.priceBucketWidth
              })
            )
            // TODO: Search TS preexisting issue
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dispatch(anchorReceived({...response, anchor: areaAnchor}))

            return
          }

          // TODO: SAPI_TODO: @votsa [@findhotel.sapi >= 1] Temp support for daedalus data format
          const displayValue =
            anchor.objectType === 'hotel'
              ? anchor.hotelName
              : anchor.placeName ?? anchor.placeDisplayName

          dispatch(setSearchBoxDestinationDisplayValue(displayValue))

          if (isReactNativeWebView()) {
            postMessageToWebView({
              type: RNWebViewMessageTypes.SET_USER_SEARCH_PARAMS,
              // TODO: Search TS preexisting issue
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              payload: {...searchParameters, displayValue}
            })
          }

          dispatch(
            setSearchData({
              objectDisplayName: displayValue,
              _geoloc: anchor._geoloc as DaedalusGeoLocation,
              placeCategory: (anchor as PlaceAnchor).placeCategory,
              priceBucketWidth: anchor.priceBucketWidth,
              geoDetails: (anchor as PlaceAnchor)?.geoDetails
            })
          )

          saveSearchHistory({
            searchParams: searchParameters as SearchParameters,
            searchData: anchor,
            searchId
          })

          dispatch(anchorReceived(response))
          dispatch(
            setSearchProgressPercentageLinear(
              SearchProgressPercentage.AnchorReceived
            )
          )
        },
        onHotelsReceived: response => {
          if (currentSearchId.current !== response.searchId) return

          dispatch(hotelsReceived(response))
          dispatch(offersRequested())
          dispatch(
            setSearchProgressPercentageLinear(
              SearchProgressPercentage.HotelsReceived
            )
          )
        },
        onOffersReceived: response => {
          if (currentSearchId.current !== response.searchId) return

          // TODO: Search TS preexisting issue
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(offersReceived(response))
          dispatch(
            setSearchProgressPercentageIncrementally(
              SearchProgressPercentage.OffersReceived
            )
          )
        },
        onComplete: response => {
          if (currentSearchId.current !== response.searchId) return

          dispatch(searchCompleted(response))

          if (
            isDateStringYesterday(searchParameters.checkIn) &&
            !hotelOfferEntitiesHaveOffers(response?.hotelOfferEntities)
          ) {
            // if offers are empty we remove dates from the url to kick off another search for SAPI to handle the default dates
            updateLocation({
              checkIn: undefined,
              checkOut: undefined
            })
          }
        }
      })
    },
    [
      searchFn,
      dispatch,
      isUserLocationSearch,
      saveSearchHistory,
      isUserOrUrlLocationSearch,
      formatMessage,
      updateLocation
    ]
  )

  // This is instead of doing a Deep equality check we pass a stringified version to the useEffect
  // this is added because we don't want to trigger runSearch when we change 'layout' in the URL
  // see: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const stringifiedUrlParams = JSON.stringify(
    omit(SEARCH_PARAMS_TO_OMIT_FOR_SEARCHES, urlParams)
  )
  const stringifiedCugDeals = JSON.stringify(searchParameters?.cugDeals)
  const tier = searchParameters?.tier
  const stringifiedGeolocation = JSON.stringify(searchParameters?.geolocation)
  const forceSearch = useSelector(getForceSearch)

  useEffect(() => {
    if (
      tier !== undefined &&
      searchParams &&
      isValidSapiSearchParameters(searchParams as SapiSearchParameters)
    ) {
      runSearch(searchParams as SapiSearchParameters)
    }
  }, [
    runSearch,
    stringifiedUrlParams,
    stringifiedCugDeals,
    tier,
    stringifiedGeolocation,
    forceSearch
  ])

  // TODO (@Search) [2021-08-30] Move segmentation to a separate component
  useEffect(() => {
    setAudienceSegmentationAttributes({
      searchType: getSearchTypeFromSearchParameters(searchParameters)
    })
  }, [searchParameters])

  // completes search progress bar when the search and availability requests are completed
  useEffect(() => {
    if (
      hotelAvailabilityPricesComplete &&
      moduleState == ModuleState.SearchCompleted
    )
      dispatch(
        setSearchProgressPercentage(SearchProgressPercentage.SearchCompleted)
      )
  }, [hotelAvailabilityPricesComplete, moduleState, dispatch])
}

const useLoadMoreResults = () => {
  const {currentSearch} = useSapi()
  const dispatch = useDispatch()

  return useCallback(() => {
    if (currentSearch !== undefined) {
      dispatch(moreHotelsRequested())

      // Due to performance issues, rendering the results will cause the page to be unresponsive, leaving the user in limbo
      // Hack it by setting a short timeout before changing offset and requesting results so we can render the loading state before rendering the changed results.
      window.setTimeout(() => {
        dispatch(setNextPage())

        currentSearch.loadMore()
      }, 1)
    }
  }, [dispatch, currentSearch])
}

const useIsSapiInitialized = () => {
  const {isInitialized} = useSapi()

  return isInitialized
}

const useGetHotelAvailability = ({hotelId}: {hotelId: string}) => {
  const dispatch = useDispatch()
  const {searchHotelAvailability} = useSapi()

  const searchParameters = useSelector(getSearchParameters)
  const {rooms, cugDeals, tier, originId} = searchParameters || {}

  const {checkIn, checkOut} = searchParameters || {}
  const nights =
    checkIn &&
    checkOut &&
    differenceInDays(
      parseISO(searchParameters.checkOut),
      parseISO(searchParameters.checkIn)
    )

  const {startDate, endDate} = useMemo(
    () => getInitialSearchDates(checkIn),
    [checkIn]
  )

  const availabilityCallbacks = useMemo(
    () => ({
      onStart: () => {
        dispatch(hotelsAvailabilityRequested())
      },
      onAvailabilityReceived: response => {
        dispatch(
          setSearchProgressPercentageIncrementally(
            SearchProgressPercentage.OffersReceived
          )
        )
        dispatch(hotelsAvailabilityReceived(response))
      },
      onComplete: response => {
        dispatch(hotelsAvailabilityCompleted(response))
      }
    }),
    [dispatch]
  )

  const initialParams = useMemo(
    () => ({
      nights,
      hotelIds: hotelId,
      rooms,
      originId: originId ?? '',
      cugDeals,
      tier: tier ?? ''
    }),
    [cugDeals, hotelId, nights, originId, rooms, tier]
  )

  const SAPI_VALID_DATE_FORMAT = 'yyyy-MM-dd'

  const getHotelAvailabilityForCurrentSearch = useCallback(async () => {
    const parameters = {
      startDate: format(startDate, SAPI_VALID_DATE_FORMAT),
      endDate: format(endDate, SAPI_VALID_DATE_FORMAT),
      ...initialParams
    }
    searchHotelAvailability(parameters, availabilityCallbacks)
  }, [
    availabilityCallbacks,
    endDate,
    initialParams,
    searchHotelAvailability,
    startDate
  ])

  const getMonthlyAvailability = useCallback(
    async (date: Date) => {
      const startDate = ensureCheckInOnOrAfterToday(
        new Date(date.getFullYear(), date.getMonth(), 1)
      )
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      const parameters = {
        startDate: format(startDate, SAPI_VALID_DATE_FORMAT),
        endDate: format(endDate, SAPI_VALID_DATE_FORMAT),
        ...initialParams
      }
      searchHotelAvailability(parameters, availabilityCallbacks)
    },
    [initialParams, searchHotelAvailability, availabilityCallbacks]
  )

  return {getMonthlyAvailability, getHotelAvailabilityForCurrentSearch}
}

const useSearch = () => ({
  isSapiInitialized: useIsSapiInitialized,
  initSearch: useInitSearch,
  loadMoreResults: useLoadMoreResults(),
  useGetHotelAvailability
})

export default useSearch
