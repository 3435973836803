import {ALL_DOMAINS} from 'config/localization/domains'
import {DomainType} from 'types/Localization'

export const getDomainConfig = (domain = ''): DomainType | null | undefined => {
  const domainLowerCased = domain.toLowerCase()
  const key = Object.keys(ALL_DOMAINS).find(domainKey =>
    domainLowerCased.endsWith(domainKey)
  )
  return key ? ALL_DOMAINS[key] : null
}
