import React from 'react'
import {searchParamToFacet} from 'modules/search/constants'
import {FilterUrlParam, FilterUrlParams} from 'modules/search/types'
import {equals, isEmpty, isNil, path} from 'ramda'
import {FacetType} from 'types/Search'

import {Lov} from '@daedalus/core/src/analytics/types/Events'
import {FilterId} from '@daedalus/core/src/search/types'

import {FacetGroup, FacetGroupItem} from './FacetFilter'
import {PopularFilter} from './PopularFilters/PopularFilters'

import type {FacetsAsArray} from '@daedalus/core/src/sapi/types'

/** Helper to build a dynamic data-id used to anchor the scroll item */
export const buildDataId = (key: string) => `all-filters-item-${key}`

/** Helper to get all available filter keys */
export const getAllFilterKeys = () => {
  const {
    reviewScore,
    starRatings,
    themes,
    facilities,
    propertyTypes,
    priceMin,
    priceMax
  } = searchParamToFacet

  return [
    'freeCancellation',
    reviewScore.param,
    starRatings.param,
    themes.param,
    facilities.param,
    propertyTypes.param,
    priceMin.param,
    priceMax.param,
    'sortField',
    'sortOrder',
    'amenities'
  ]
}

// SAPI only returns valid filters. This function allows users to remove filters
// that are already applied individually instead of clearing all of them
export const getValidFacets = (
  facets: FacetType[],
  appliedFilters: FilterUrlParam
) => {
  const facetsAreValid = !isNil(facets) && !isEmpty(facets)

  // if facets are empty and no filters are applied

  if (!facetsAreValid && !appliedFilters) {
    return []
  }
  // or facets are not empty (valid)
  if (facetsAreValid) return facets

  if (typeof appliedFilters === 'string') {
    // return facets array based on already applied filter
    return [{id: appliedFilters, value: +appliedFilters}]
  }

  // return facets array based on already applied filters
  return (appliedFilters as string[]).map((k: string) => {
    return {id: k, value: +k}
  })
}

/**
 * Group the facets based on the valid facets and facet groups provided.
 * @param validFacets - The valid facets to filter.
 * @param facetGroups - The facet groups to process.
 * @returns The grouped facets based on the facet groups.
 */
export const getValidFacetGroups = (
  validFacets: FacetType[],
  facetGroups: FacetGroupItem[]
): FacetGroup =>
  facetGroups?.reduce((acc, group) => {
    return {
      ...acc,
      [group.type]: validFacets?.filter(facet =>
        group?.facetIds?.has(facet?.id)
      )
    }
  }, {})

export const getFiltersLabel = (
  categoryType: string,
  facetId: string,
  listOfValues: Lov
) => {
  return path([`${categoryType}:${facetId}`, 'value'], listOfValues)
}

export interface IQuickFilter<T = object> {
  id: FilterId
  name: string | string[]
  Component: React.FC<T>
  activeCondition?: {
    name: string
    value: string | string[]
  }
}

export const isFacetValid = (
  facetId: string,
  facetKey: 'themeIds' | 'facilities',
  facets: FacetsAsArray
) => {
  return Boolean(facets?.[facetKey]?.find(({id}) => id === facetId))
}
export const isQuickFilterActive = (
  filter: PopularFilter,
  appliedFilters: FilterUrlParams
): boolean => {
  // If the filter has no active condition
  if (filter.activeCondition) {
    // If both the current value and the applied filter value are arrays
    if (
      Array.isArray(filter.activeCondition.value) &&
      Array.isArray(appliedFilters[filter.activeCondition.name])
    ) {
      // Check if the sets of values are equal
      return equals(
        new Set(appliedFilters[filter.activeCondition.name]),
        new Set(filter.activeCondition.value)
      )
    }

    // If the applied filter value is an array and we want to check for a single value
    if (Array.isArray(appliedFilters[filter.activeCondition.name])) {
      // Check if the value is included in the applied filter array
      return appliedFilters[filter.activeCondition.name].includes(
        filter.activeCondition.value
      )
    }

    // If the applied filter is a single value
    return (
      appliedFilters[filter.activeCondition.name] ===
      filter.activeCondition.value
    )
  }

  // If the filter has an active condition and its name is an array
  if (Array.isArray(filter.name)) {
    // Check if at least one of the filter names is truthy in applied filters
    return filter.name.some(filterName => appliedFilters[filterName])
  }

  // If the filter has an active condition and its name is a single value
  return Boolean(appliedFilters[filter.name])
}

export const getOrderedActiveQuickFilters = (
  quickFilters: PopularFilter[],
  appliedFilters: FilterUrlParams,
  prevFilters: string[]
) => {
  const newActiveFilters = [...prevFilters]

  for (const filter of quickFilters) {
    if (
      isQuickFilterActive(filter, appliedFilters) &&
      !prevFilters.includes(filter.id)
    ) {
      newActiveFilters.unshift(filter.id)
    }

    if (
      !isQuickFilterActive(filter, appliedFilters) &&
      prevFilters.includes(filter.id)
    ) {
      newActiveFilters.splice(newActiveFilters.indexOf(filter.id), 1)
    }
  }

  return newActiveFilters
}
