export type LocaleObjectType = {
  language: string
  region?: string
}
const languageExceptions = new Set([
  'en-GB',
  'pt-BR',
  'fr-CA',
  'zh-CN',
  'zh-HK',
  'zh-TW'
  // Added as part of 2a98dd4d-add-new-languages--v2
  // 'es-AR',
  // 'es-CL',
  // 'es-CO',
  // 'es-CR',
  // 'es-EC',
  // 'es-GT',
  // 'es-MX',
  // 'es-PA',
  // 'es-PY',
  // 'es-PE',
  // 'es-PR',
  // 'es-DO',
  // 'es-UY',
  // 'es-VE'
])
export const parseLocale = (
  locale?: string
): LocaleObjectType | null | undefined => {
  // Example: en-GB, nl -> { language: 'en', region: 'GB' }

  if (!locale) {
    return null
  }

  const [languageWithRegion, language, region] =
    locale.match(/([a-z]{2})(?:-([a-z]{2}))?/i) || []

  if (!language) {
    return null
  }

  const result: LocaleObjectType = {
    language: languageExceptions.has(languageWithRegion)
      ? languageWithRegion
      : language.toLowerCase()
  }

  if (region) {
    result.region = region.toUpperCase()
  }

  return result
}

export const normalizeLanguage = (
  languageCode: string
): string | null | undefined => {
  try {
    // TODO: Search TS preexisting issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canonicalLocales = Intl.getCanonicalLocales(languageCode)

    return canonicalLocales[0]
  } catch {
    return null
  }
}
