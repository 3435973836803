import {allPass, any, anyPass, is, isEmpty, prop} from 'ramda'

import {Lov, LovHit} from '@daedalus/core/src/analytics/types/Events'

/**
 * Map and fallback(if necessary) language values of an attribute.
 * Input 1:
 *   hitObject = { "en": "Dam Square", "ru": "Площадь Дам" }
 *
 * Output 1:
 *   "Площадь Дам"
 *
 * Input 2:
 *   hitObject = { "en": ["Some", "Place", "Name"], "ru": ["", "место", "название"] }
 *
 * Output 1:
 *   ["Some", "Place", "Name"]
 */
export const languageValueMapper =
  (languages: string[] = []) =>
  (
    // TODO: Disabled for enforcing lint, fix if possible
    // eslint-disable-next-line no-undef
    hitObject: TsToDo
  ) => {
    if (!hitObject) return ''

    const isEmptyStringOrWithEmptyArray = anyPass([
      allPass([is(String), isEmpty]),
      allPass([is(Array), any(isEmpty)])
    ])

    const result = languages.reduce((prev, curr) => {
      if (prev) return prev

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const hitObjectForLang = prop(curr, hitObject)
      return isEmptyStringOrWithEmptyArray(hitObjectForLang)
        ? prev
        : hitObjectForLang
    }, '')

    return result
  }

export const hitsToLov = (hits: LovHit[], languages: string[]): Lov => {
  const mapper = languageValueMapper(languages)
  const res = {}

  for (const hit of hits)
    res[hit.objectID] = {
      ...hit,
      value: mapper(hit.name || hit.value)
    }

  return res
}
