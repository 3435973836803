import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {logEvent} from 'middleware/analytics/utils/logEvent'
import {
  getCurrencyCode,
  getFallbackLanguages,
  getLanguageCode,
  getMeta
} from 'modules/meta/selectors'
import {setCurrencyExchangeRates} from 'modules/sapiSearch/utils'
import {getLandingQueryString} from 'modules/search/selectors'
import {setListOfValues} from 'modules/search/slice'
import {hitsToLov} from 'utils/algolia'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {
  generateSapiLabelFromBrowserContext,
  persistSapiLabel
} from '@daedalus/core/src/_web/sapiLabel/business'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  LovHit
} from '@daedalus/core/src/analytics/types/Events'
import {calculateTotalRate} from '@daedalus/core/src/offer/business/calculateTotalRate'
import {initSapiClient} from '@daedalus/core/src/sapi/services'
import {SearchApiError} from '@daedalus/core/src/sapi/services/searchApi'
import {ClientOptions, ProfileKey, SapiClient} from '@findhotel/sapi'
// SAPI_TODO: better export
import {HotelOfferEntity} from '@findhotel/sapi/dist/types/packages/core/src/types'

interface SapiProviderProps {
  profileKey: ProfileKey
  generateOptions: () => ClientOptions
  children: ReactNode
}

// SAPI_TODO: better types, probably import from sapi
interface CurrentSearch {
  loadOffers: (objectID: string) => Promise<HotelOfferEntity>
  loadMore: () => void
}

interface SapiContextProps {
  sapiClient: SapiClient | undefined
  searchFn: SapiClient['search']
  suggest: SapiClient['suggest']
  currentSearch: CurrentSearch
  searchHotelAvailability: SapiClient['hotelAvailability']
  isSapiContext: boolean // Allows children to detect whether they have a SapiProvider parent
}

/**
 * Creates logger to send individual algolia retry errors to Sentry and DD
 */
const useAlgoliaLogger = () => {
  const dispatch = useDispatch()

  const log = (message: string, args?: unknown) => {
    const messagePrefixed = `Algolia: ${message}`

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.Search,
        action: Action.Errored,
        analyticsContext: {
          [AnalyticsContext.ErrorContext]: {
            errorType: messagePrefixed,
            errorMessage: args as string
          }
        }
      })
    )
  }

  return {
    async debug(message: string, args?: unknown) {
      log(message, args)
    },
    async info(message: string, args?: unknown) {
      log(message, args)
    },
    async error(message: string, args?: unknown) {
      log(message, args)
    }
  }
}

const SapiContext = createContext<Partial<SapiContextProps>>({})

export const SapiProvider = ({
  profileKey,
  generateOptions,
  children
}: SapiProviderProps) => {
  const [sapiClient, setSapiClient] = useState<SapiClient>()
  const [currentSearch, setCurrentSearch] = useState<CurrentSearch>()
  const dispatch = useDispatch()
  const algoliaLogger = useAlgoliaLogger()
  const language = useSelector(getLanguageCode)
  // TODO: SAPI_TODO: @votsa [@findhotel.sapi >= 1: this should come from sapi
  const fallbackLanguages = useSelector(getFallbackLanguages)
  const currencyCode = useSelector(getCurrencyCode)
  const {brandCode, brandIsInternal} = useBrand()
  const {includeTaxes, includeLocalTaxes} = useSelector(getMeta)
  const landingQueryString = useSelector(getLandingQueryString)
  const [requestError, setRequestError] = useState<Error>(null)

  // Store the traffic source in a cookie for 10 minutes so RSP can use it
  useEffect(() => {
    const sapiLabel = generateSapiLabelFromBrowserContext(
      landingQueryString,
      !brandIsInternal && brandCode
    )
    if (sapiLabel) {
      persistSapiLabel(sapiLabel)
    }
  }, [landingQueryString, brandCode, brandIsInternal])

  useEffect(() => {
    if (requestError) {
      throw new SearchApiError(
        'SapiProvider: Error fetching offers',
        requestError
      )
    }
  }, [requestError])

  useEffect(() => {
    const options = generateOptions()

    async function createSapiClient() {
      try {
        const client = await initSapiClient(profileKey, {
          ...options,
          getTotalRate: rate =>
            calculateTotalRate(rate, includeTaxes, includeLocalTaxes),
          algoliaClientOptions: {
            timeouts: {
              connect: 3, // Connection timeout in seconds
              read: 2, // Read timeout in seconds
              write: 30 // Write timeout in seconds
            },
            logger: algoliaLogger
          },
          logger: {
            log(event) {
              logEvent(event.name, {value: event.value}, 'info')
            }
          },
          callbacks: {
            onConfigReceived: configs => {
              const lov = hitsToLov(configs.lov as LovHit[], [
                language,
                ...fallbackLanguages
              ])

              dispatch(setListOfValues({listOfValues: lov}))

              setCurrencyExchangeRates(
                configs.exchangeRates?.[currencyCode],
                configs.exchangeRates?.[currencyCode] /
                  configs.exchangeRates?.EUR
              )
            }
          }
        })
        setSapiClient(client)
      } catch (error) {
        if (typeof Sentry !== 'undefined') {
          Sentry.captureException(error)
        }

        dispatch(
          trackEvent({
            category: Category.System,
            entity: Entity.Search,
            action: Action.Errored,
            analyticsContext: {
              [AnalyticsContext.ErrorContext]: {
                errorType: 'SapiInitializationError',
                errorMessage: error.message
              }
            }
          })
        )

        setSapiClient(undefined)
      }
    }

    createSapiClient()
  }, [])

  const searchFn = useCallback(
    async (...args: Parameters<SapiClient['search']>) => {
      if (sapiClient !== undefined) {
        try {
          const search = await sapiClient.search(...args)
          setCurrentSearch(search)
          return search
        } catch (error) {
          setRequestError(error)
          dispatch(
            trackEvent({
              category: Category.System,
              entity: Entity.Search,
              action: Action.Errored,
              payload: {
                sourceComponent: 'SapiProvider'
              },
              analyticsContext: {
                [AnalyticsContext.ErrorContext]: {
                  errorType: 'InvalidSearch',
                  errorMessage: (error as Error).message
                }
              }
            })
          )
        }
      }
    },
    [sapiClient, dispatch]
  )

  const searchHotelAvailability = useCallback(
    async (...args: Parameters<SapiClient['hotelAvailability']>) => {
      if (sapiClient !== undefined) {
        try {
          const results = await sapiClient.hotelAvailability(...args)
          return results
        } catch (e) {
          // Temporary solution until we have a proper error handling callback
          const callbacks = args[1]
          const {onComplete} = callbacks
          onComplete({
            availability: {}
          })
        }
      }
    },
    [sapiClient]
  )

  return (
    <SapiContext.Provider
      value={{
        sapiClient,
        searchFn,
        currentSearch,
        // Added as part of 6beacd2c-perform-two-searches
        searchHotelAvailability,
        isSapiContext: true
      }}
    >
      {children}
    </SapiContext.Provider>
  )
}

export function useSapi() {
  const {
    sapiClient,
    currentSearch,
    searchFn,
    isSapiContext,
    searchHotelAvailability
  } = useContext(SapiContext)

  return {
    searchFn,
    currentSearch,
    suggest: sapiClient?.suggest,
    isInitialized: sapiClient !== undefined,
    isSapiContext,
    sapiClient,
    searchHotelAvailability
  }
}
