import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import {useSearchBox} from 'hooks/searchBox'
import {getIsRtlLanguage} from 'modules/meta/selectors'
import {
  getNumberOfRooms,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {getSelectedAvailabilityDate} from 'modules/search/selectors'
import {SearchTrigger} from 'modules/searchBox/types'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {SmoothScrollNavigation} from '@daedalus/atlas/experimental/SmoothScrollNavigation'
import {
  getAvailabilityStatus,
  getIsCheapestPrice
} from '@daedalus/core/src/_web/availability'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {AvailabilityHotelEntity} from '@daedalus/core/src/availability/types'
import {getCheckOutDate} from '@daedalus/core/src/searchParams/validateSearchParamDates'
import {dateToMiddayString} from '@daedalus/core/src/utils/date'
import {getRoundingStrategy} from '@daedalus/core/src/utils/number'

import {AvailabilityTile} from './AvailabilityTile'

interface Props {
  hotelId: string
  sourceComponentPrefix: string
  hotelAvailabilityPrices: AvailabilityHotelEntity
  datesToDisplay: string[]
  availabilitySearchComplete: boolean
}

export const AvailabilityPane = ({
  hotelId,
  sourceComponentPrefix,
  hotelAvailabilityPrices,
  datesToDisplay,
  availabilitySearchComplete
}: Props) => {
  const dispatch = useDispatch()
  const {checkIn, checkOut} = useSelector(getSearchParameters)
  const selectedAvailabilityDate = useSelector(getSelectedAvailabilityDate)
  const {isMobile} = useDeviceLayout()
  const {doSearch} = useSearchBox()
  const isRtlLanguage = useSelector(getIsRtlLanguage)

  const nights = differenceInDays(parseISO(checkOut), parseISO(checkIn))
  const numberOfRooms = useSelector(state => getNumberOfRooms(state, hotelId))

  const sourceComponentAvailabilityPane = `${sourceComponentPrefix}AvailabilityPane`

  useEffect(() => {
    if (datesToDisplay?.length > 0) {
      // Filter hotelAvailabilityPrices and leave only ones for the dates to display
      const pricesForDisplayedTiles: AvailabilityHotelEntity =
        datesToDisplay.reduce((acc, date) => {
          acc[date] = hotelAvailabilityPrices[date]
          return acc
        }, {})

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.AnchorHotelAvailablePane,
          action: Action.Displayed,
          payload: {
            hotelAvailabilityPrices: pricesForDisplayedTiles
          }
        })
      )
    }
  }, [JSON.stringify(datesToDisplay)])

  useEffect(() => {
    // scroll to selected date opening the AP
    setTimeout(() => {
      const element = document.querySelector(
        `[data-id='tile-${sourceComponentPrefix}-${selectedAvailabilityDate}']`
      )
      if (element) {
        element.scrollIntoView({
          block: 'nearest',
          inline: 'center'
        })
      }
    })
  }, [])

  const handleTileClick = useCallback(
    (date: string) => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.AvailabilityPaneTile,
          action: Action.Clicked,
          payload: {
            date,
            availabilityStatus: getAvailabilityStatus({
              hotelAvailabilityPrices,
              date,
              searchComplete: availabilitySearchComplete
            })
          },
          component: sourceComponentAvailabilityPane
        })
      )
      doSearch({
        newUrlParams: {
          checkIn: date,
          checkOut: dateToMiddayString(getCheckOutDate(date, nights))
        },
        searchTrigger: SearchTrigger.AvailabilityTile
      })
    },
    [
      availabilitySearchComplete,
      dispatch,
      hotelAvailabilityPrices,
      sourceComponentAvailabilityPane,
      doSearch,
      nights
    ]
  )

  return (
    <>
      <div data-id="AvailabilityPane">
        <SmoothScrollNavigation
          hideNavButtonsOnlyForMobileSm
          itemsGap="s300"
          isRtlLanguage={isRtlLanguage}
        >
          {datesToDisplay?.map(date =>
            hotelAvailabilityPrices[date]?.cheapestPriceRateBreakdown ? (
              <AvailabilityTile
                dataId={`tile-${sourceComponentPrefix}-${date}`}
                key={date}
                onClick={() => handleTileClick(date)}
                date={date}
                currency={hotelAvailabilityPrices[date].offers[0].currency}
                price={hotelAvailabilityPrices[date]?.cheapestNightlyRate}
                priceBreakdown={
                  hotelAvailabilityPrices[date]?.cheapestPriceRateBreakdown
                }
                roundingStrategy={getRoundingStrategy()}
                nights={nights}
                isSelected={isMobile && selectedAvailabilityDate === date}
                isCheapest={getIsCheapestPrice(
                  hotelAvailabilityPrices,
                  hotelAvailabilityPrices[date]?.cheapestNightlyRate,
                  datesToDisplay
                )}
                rooms={numberOfRooms}
              />
            ) : null
          )}
        </SmoothScrollNavigation>
      </div>
    </>
  )
}
