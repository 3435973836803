import segmentAnalytics from '@segment/analytics.js'
import {isEmpty} from 'ramda'

import {ProviderRedirectParams} from '../modules/actions'
import {AnalyticsSDKType, IntegrationsType} from '../types/AnalyticsSDK'
import {ExperimentContext, TrackEventProperties} from '../types/Events'

const analytics = segmentAnalytics as AnalyticsSDKType

export type MetaValue = (string & Record<string, unknown>) | null

const normalizeNullValues = function <
  Metadata extends
    | TrackEventProperties
    | ExperimentContext
    | ProviderRedirectParams
>(metadata?: Metadata) {
  if (metadata == undefined) metadata = {} as Metadata

  return Object.keys(metadata).reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (mem: Record<string, any>, k) {
      let v = (metadata as Metadata)[
        k as keyof (TrackEventProperties | ExperimentContext)
      ] as MetaValue
      if (
        (typeof v === 'string' && v === '') ||
        (typeof v === 'object' && isEmpty(v)) ||
        v === undefined
      ) {
        v = null
      }

      mem[k] = v
      return mem
    },
    {} as TrackEventProperties | ExperimentContext
  )
}

/**
 * Analytics helper functions object
 */
const AnalyticsUtils = {
  /** Default for log events */
  logEvents: false,
  /**
   * Set the log events default status
   * @param status - whether to log events
   */
  setLogEventsStatus: (status: boolean) => {
    AnalyticsUtils.logEvents = status
  },
  // TODO: Write unit tests
  /** Return if analytics is initialized */
  analyticsPlatformEnabled() {
    return Boolean(analytics?.initialized)
  },
  /**
   * Initialize analytics if possible
   * @param integrations - configuration for integrations
   * @param anonymousId
   */
  initialize(integrations: IntegrationsType, anonymousId: string) {
    analytics?.initialize?.(integrations)
    analytics?.user?.().anonymousId(anonymousId)
  },
  /**
   * Overrides the destination options for the Segment / EvAS API endpoint.
   * Used for sending events to a different endpoint, for instance while migrating to a new endpoint.
   * @remarks This method modifies internals of the Analytics.js implementation as there is no official API available.
   * @param apiHost - URL of the analytics endpoint (excluding https://)
   * @param apiKey - API key for the analytics endpoint
   * @param beacon - Whether to use the beacon transport method
   */
  overrideSegmentDestinationSettings(
    apiHost: string,
    apiKey: string,
    beacon: boolean = false
  ) {
    // @ts-expect-error: Property '_integrations' does not exist on type 'AnalyticsSDKType'.
    analytics._integrations['Segment.io'].options.apiHost = apiHost
    // @ts-expect-error: Property '_integrations' does not exist on type 'AnalyticsSDKType'.
    analytics._integrations['Segment.io'].options.apiKey = apiKey
    // @ts-expect-error: Property '_integrations' does not exist on type 'AnalyticsSDKType'.
    analytics._integrations['Segment.io'].options.beacon = beacon
  },

  /**
   * Track events
   * @param event - event name
   * @param metadata - extra event data
   */
  trackEvent(
    event: string,
    metadata?: TrackEventProperties | ExperimentContext | ProviderRedirectParams
  ) {
    const normalizedMetadata = normalizeNullValues(metadata) as
      | TrackEventProperties
      | ExperimentContext

    if (AnalyticsUtils.logEvents) {
      console.info(`[TRACK] ${event}`, normalizedMetadata)
    }

    return analytics?.track?.(event, normalizedMetadata)
  },

  /**
   * Used to link users and their actions to a recognizable `userId` and `traits` [Segment Docs](https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify)
   * @param userId - The user ID of a signed-in user. Do not pass an anonymousId as userId, since the user will incorrectly be considered to be signed in.
   * @param traits - A dictionary of traits you know about the user, like their email or name.
   * @param options - A dictionary of options. For example, enable or disable specific destinations for the call. Note: If you do not pass a `traits` object, pass an empty object (as an ‘{}’) before `options`.
   * @param callback - A function executed after a timeout of 300 ms, giving the browser time to make outbound requests first.
   */
  identify: analytics.identify,

  trackPageView: analytics?.page?.bind(analytics, 'Page'),

  user: analytics.user,

  /**
   * Segment persist userId and anonymousId. We need to clean up the userId after the user logout or change,
   * but we need to keep the anonymousId as it is. Calling `analytics.reset` will also update anonymousId,
   * which is not what we want. This function will reset the userId and keep the anonymousId value.
   * @param userId
   */
  resetAnalytics: (userId?: string) => {
    const anonymousId = analytics.user().anonymousId()
    const analyticsUserId = analytics.user().id()
    if (analyticsUserId && userId !== analyticsUserId) {
      analytics.reset()
      analytics.user().anonymousId(anonymousId)
    }
  }
}

export default AnalyticsUtils

export {isGoodBot} from './botDetection'
