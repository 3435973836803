import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {getSortParams} from 'modules/search/selectors'
import {SortUrlParams} from 'modules/search/types'
import {setSearchTrigger} from 'modules/searchBox/slice'
import {SearchTrigger} from 'modules/searchBox/types'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

type Component = 'GemsCarousel'

export const useSortBy = (component?: Component) => {
  const sortParams = useSelector(getSortParams)
  const {updateLocation} = useUrlUpdater()
  const dispatch = useDispatch()

  const handleChangeSort = React.useCallback(
    ({sortField, sortOrder}: SortUrlParams) => {
      const isChanged =
        sortParams.sortField !== sortField || sortParams.sortOrder !== sortOrder

      if (isChanged) {
        dispatch(setSearchTrigger(SearchTrigger.Sort))
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.SortPreference,
            action: Action.Submitted,
            payload: {sortField, sortOrder},
            component
          })
        )
      }

      updateLocation({
        sortField,
        sortOrder,
        noHostels: undefined,
        ...(isChanged ? {userSearch: '1'} : {})
      })
    },
    [sortParams, updateLocation, dispatch]
  )

  return {
    onChangeSort: handleChangeSort,
    sortParams
  }
}
