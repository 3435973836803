import React, {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {getCurrencyCode} from 'modules/meta/selectors'
import {
  getAnchorHotelCheapestOffer,
  getAnchorHotelYourChoiceOffer
} from 'modules/sapiSearch/selectors'
import {getClickedOfferRate} from 'modules/search/selectors'
import {toggle} from 'opticks'

import {useToastController} from '@daedalus/atlas/context/toastController'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {formatPriceValue} from '@daedalus/core/src/price/business/formatPrice'

import {calculateWeb2AppPriceDifference} from './utils'

interface MessageProps {
  isPriceDecrease: boolean
  isPriceIncrease: boolean
  priceDifference: number
  onPopUpClick: () => void
}

const Message: React.FC<MessageProps> = ({
  isPriceDecrease,
  isPriceIncrease,
  priceDifference,
  onPopUpClick
}) => {
  const currencyCode = useSelector(getCurrencyCode)
  const {locale} = useIntl()
  const formattedPrice = formatPriceValue({
    locale,
    value: priceDifference,
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return (
    <Box onClick={onPopUpClick}>
      <Text variant="bodyM">
        {isPriceDecrease && (
          <FormattedMessageWrapper
            id="priceDifferencePopUp.priceDropped"
            defaultMessage="The total price of this stay has dropped by {value} since we last checked."
            values={{value: formattedPrice}}
          />
        )}

        {isPriceIncrease && (
          <FormattedMessageWrapper
            id="priceDifferencePopUp.priceGoneUp"
            defaultMessage="The total price of this stay has gone up by {value} since we last checked."
            values={{value: formattedPrice}}
          />
        )}
      </Text>
    </Box>
  )
}

/**
 * Added as part of 366b54ed-price-difference-popup
 */
export const PriceDifferencePopUp: React.FC = () => {
  const {open, close} = useToastController()
  const dispatch = useDispatch()

  const [isVisible, setIsVisible] = useState(false)

  const anchorHotelYourChoiceOffer = useSelector(getAnchorHotelYourChoiceOffer)
  const anchorHotelCheapestOffer = useSelector(getAnchorHotelCheapestOffer)
  const clickedOfferRate = useSelector(getClickedOfferRate)

  const offer = anchorHotelYourChoiceOffer || anchorHotelCheapestOffer
  const {totalRate = 0} = offer || {}
  const {isPriceIncrease, isPriceDecrease, priceDifference} =
    calculateWeb2AppPriceDifference({clickedOfferRate, totalRate})

  const eventTrackPayload = useMemo(
    () => ({
      clickedOfferRate,
      offer,
      priceDifference
    }),
    [priceDifference, offer, clickedOfferRate]
  )

  const onCloseButtonClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.Web2AppPriceDifferencePopUp,
        action: Action.Closed,
        payload: eventTrackPayload
      })
    )
  }

  const onPopUpClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.Web2AppPriceDifferencePopUp,
        action: Action.Clicked,
        payload: eventTrackPayload
      })
    )
  }

  useEffect(() => {
    setIsVisible(
      (isPriceDecrease || isPriceIncrease) &&
        toggle('366b54ed-price-difference-popup', false, true)
    )
  }, [isPriceDecrease, isPriceIncrease])

  useEffect(() => {
    if (isVisible) {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.Web2AppPriceDifferencePopUp,
          action: Action.Displayed,
          payload: eventTrackPayload
        })
      )
    }
  }, [isVisible, eventTrackPayload, dispatch])

  useEffect(() => {
    if (isVisible) {
      open(
        <Message
          isPriceDecrease={isPriceDecrease}
          isPriceIncrease={isPriceIncrease}
          priceDifference={priceDifference}
          onPopUpClick={onPopUpClick}
        />,
        {
          icon: (
            <Icon
              name={isPriceDecrease ? 'CustomTrendingDown' : 'CustomTrendingUp'}
            />
          ),
          showCloseButton: true,
          singleToastMessage: true,
          autoClose: false,
          closeOnOutsideClick: true,
          onCloseButtonClick
        }
      )
    } else {
      close()
    }
    // Do not add close, onCloseButtonClick, onPopUpClick methods to the dependencies array. It can cause infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, isPriceIncrease, isPriceDecrease, priceDifference, dispatch])

  return null
}
