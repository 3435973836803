import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {getOverlayVisibilityState} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {OverlayType} from 'modules/overlay/types'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {messages} from '../messages'

/**
 * Bottom sheet used to display copy explaining split bookings
 */
export const SplitBookingInfoBottomSheet: React.FC = () => {
  const {formatMessage} = useIntl()
  const dispatch = useDispatch()

  const isOpen = useSelector(state =>
    getOverlayVisibilityState(state, OverlayType.SplitBookingInfoBottomSheet)
  )

  const onClose = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  return (
    <BottomSheet
      isOpen={isOpen}
      titleBarProps={{
        centerContent: formatMessage(messages.switchRoomsAndSave)
      }}
      onClose={onClose}
    >
      <ContentWrapper paddingY="s300">
        <Flex.Column gap="s500">
          <Text variant="bodyM">
            <FormattedMessageWrapper
              id="splitBooking.roomSwitchRequiredTooltipContent"
              defaultMessage="The cheapest way to stay here is to split your stay across two separate bookings."
            />
          </Text>
          <Button variant="secondary" size="xl" onClick={onClose} fullWidth>
            <FormattedMessageWrapper
              id="splitBooking.okButton"
              defaultMessage="Ok, got it"
            />
          </Button>
        </Flex.Column>
      </ContentWrapper>
    </BottomSheet>
  )
}
