import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getPaginationContext} from 'middleware/analytics/selectors'
import {
  getCurrentPageOffset,
  getIsLoadMoreResultsPending,
  getSearchId
} from 'modules/sapiSearch/selectors'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export interface ResultsEndSensorProps {
  isResultsEndVisibilitySensorEnabled: boolean
  onResultsEndView: () => void
}

const useResultsEndSensor = (): ResultsEndSensorProps => {
  const dispatch = useDispatch()
  const loadMoreResultsIsLoading = useSelector(getIsLoadMoreResultsPending)
  const pageOffset = useSelector(getCurrentPageOffset)
  const searchId = useSelector(getSearchId)
  const paginationContext = useSelector(getPaginationContext)

  const [
    isResultsEndVisibilitySensorEnabled,
    setResultsEndVisibilitySensorEnabled
  ] = useState(true)

  useEffect(() => {
    setResultsEndVisibilitySensorEnabled(true)
  }, [pageOffset, searchId])

  const handleResultsEndView = useCallback(() => {
    if (loadMoreResultsIsLoading) return

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.SearchResultsPageEnd,
        action: Action.Viewed,
        analyticsContext: {
          [AnalyticsContext.PaginationContext]: paginationContext
        }
      })
    )
    setResultsEndVisibilitySensorEnabled(false)
  }, [dispatch, loadMoreResultsIsLoading, paginationContext, searchId])

  return {
    isResultsEndVisibilitySensorEnabled,
    onResultsEndView: handleResultsEndView
  }
}

export default useResultsEndSensor
