import React, {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {GoogleOneTapWrapper} from 'components/GoogleOneTapWrapper'
import Layout from 'components/Layouts'
import TrackPageView from 'components/TrackPageView'
import {useSyncStoreWithUrl} from 'hooks/useSyncStoreWithUrl'
import {getSapiMetaOptions} from 'modules/meta/selectors'
import {SapiProvider} from 'modules/sapiSearch/SapiProvider'
import Settings from 'Settings'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import {collectSapiVariationsParam} from '@daedalus/core/src/experiments/sapi'
import {ProfileKey} from '@findhotel/sapi/dist/types/packages/core/src'

import {SearchViewContainer as Search} from './Search'

const SyncStore = () => {
  useSyncStoreWithUrl({disallowInvalidSearchParams: true})
  return null
}

const SearchView: React.FC = () => {
  const sapiMetaOptions = useSelector(getSapiMetaOptions)
  const dataFile = useSelector(getDataFile) as {
    featureFlags: Array<{key: string}>
  }

  const {brandHasFilters} = useBrand()

  const generateOptions = useCallback(
    () => ({
      ...sapiMetaOptions,
      pageSize: Settings.get('CLIENT_SEARCH_PAGE_SIZE'),
      variations: collectSapiVariationsParam(
        // TODO: @votsa: Opticks should expose Optimisely's getEnabledFeatures method
        // to retrieve only enabled experiments
        // instead of reading all featureFlags from datafile
        // https://github.com/FindHotel/opticks/pull/46
        dataFile?.featureFlags.map(({key}) => key)
      )
    }),
    [sapiMetaOptions, dataFile]
  )

  return (
    <>
      <SyncStore />
      <TrackPageView pageName={Page.Search} />
      <InjectPageDataId page={Page.Search} />

      <SapiProvider
        profileKey={Settings.get('CLIENT_SAPI_PROFILE_KEY') as ProfileKey}
        generateOptions={generateOptions}
      >
        <Layout
          hasFilters={brandHasFilters}
          hasSearchForm
          hasHeaderDividerOnMobile
          hasHeaderOnMobile
          hasFooterOnMobile={false}
          hasHeaderSearchSummary
          hasSearchProgressBar
        >
          <Search />
        </Layout>
      </SapiProvider>
      <GoogleOneTapWrapper />
    </>
  )
}

const SearchViewMemo = React.memo(SearchView)
export default SearchViewMemo
