import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'Rooms Offers simulation',
  value: 'Rooms',
  description: 'Scenarios for testing rooms-offers endpoint',
  routes: [
    {
      endpoint: EndpointNames.Rooms,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'withSplitBooking',
          displayName: 'Rooms offers with Split Booking',
          description: 'Rooms response also includes splitBooking object'
        },
        {
          value: 'withMergedSplitBooking',
          displayName: 'Rooms offers with merged Split Booking',
          description:
            'splitBooking object includes merged cancellationPenalties'
        }
      ]
    },
    {
      endpoint: EndpointNames.Rooms,
      method: RequestMethod.GET,
      page: Pages.AP,
      variations: [
        {
          value: 'withSplitBooking',
          displayName: 'Rooms offers with Split Booking',
          description: 'Rooms response also includes splitBooking object'
        },
        {
          value: 'clickedNotCheapestRegular',
          displayName: 'Clicked room offer is not the cheapest',
          description:
            'Rooms response includes clicked offer which is not the cheapest one'
        },
        {
          value: 'clickedNotCheapestSb',
          displayName: 'Clicked SB offer is not the cheapest',
          description:
            'Rooms response includes SB clicked offer which is not the cheapest one'
        },
        {
          value: 'withMergedSplitBooking',
          displayName: 'Rooms offers with merged Split Booking',
          description:
            'splitBooking object includes merged cancellationPenalties'
        },
        {
          value: 'soldOut',
          displayName: 'Rooms offers with no rooms availibility',
          description: 'No rooms found for the selected offers'
        }
      ]
    }
  ]
}
