import {useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {
  getAccommodationPageHotelId,
  getRoomsActiveFilters
} from 'modules/accommodation/selectors'
import {setRoomsFiltersLoading} from 'modules/accommodation/slice'
import updateLocation from 'modules/common/actions/location'
import {getOverlay} from 'modules/overlay/selectors'
import {getHotelApiRoomsParameters} from 'modules/searchApi/selectors'

import {getUrlParams} from '@daedalus/core/src/_web/utils/url'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {
  filtersToCacheKey,
  roomsFilterListToMap
} from '@daedalus/core/src/room/business/utils/filters'
import {RoomsFilterKey} from '@daedalus/core/src/room/business/utils/filters'
import {
  roomSearchStarted,
  roomSearchSucceeded
} from '@daedalus/core/src/sapi/services/searchApi/action'

export const useHotelRoomsFilters = () => {
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()
  const overlayState = useSelector(getOverlay)
  const activeRoomsFilters = useSelector(getRoomsActiveFilters)
  const hotelId = useSelector(getAccommodationPageHotelId)
  const params = useSelector(state =>
    getHotelApiRoomsParameters(state, hotelId)
  )
  const {pathname} = useLocation()
  const changeListenerRef = useRef<() => void>()

  /**
   * Set (or remove) multiple filters
   */
  const setRoomsFilters = (filters: RoomsFilterKey[]) => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.RoomOfferFilterPreference,
        action: Action.Submitted,
        payload: {
          allSelectedFilters: roomsFilterListToMap(filters),
          component: 'RoomsFilters'
        }
      })
    )
    const urlParams = getUrlParams()
    const newParams = {...urlParams, roomFilters: filters.join(',')}
    if (!filters.length) delete newParams.roomFilters

    dispatch(
      updateLocation({
        basePath: pathname,
        method: 'replace',
        mergeWithCurrentParams: false,
        params: newParams,
        state: {overlay: overlayState}
      })
    )

    // Filtering happens client side, so fire mock API request events once the change comes through the navigation + state change
    changeListenerRef.current = () => {
      dispatch(roomSearchStarted({}))
      dispatch(roomSearchSucceeded({params}))
    }

    // And fake loading state to give user UI feedback for the action
    dispatch(setRoomsFiltersLoading(true))
    setTimeout(() => {
      dispatch(setRoomsFiltersLoading(false))
    }, 300)
  }

  // Whenever filters state changes, trigger the listener from the last action
  const filtersCacheKey = filtersToCacheKey(activeRoomsFilters)
  useEffect(() => {
    changeListenerRef.current?.()
    changeListenerRef.current = undefined
  }, [filtersCacheKey])

  /**
   * Toggle a single filter
   */
  const toggleRoomsFilter = (filterId: RoomsFilterKey) => {
    const newFilters = new Set(activeRoomsFilters)
    newFilters.delete(filterId) || newFilters.add(filterId)

    setRoomsFilters(Array.from(newFilters))
  }

  return {
    activeRoomsFilters,
    setRoomsFilters,
    toggleRoomsFilter
  }
}
