import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  SearchDataPayload,
  setSearchData,
  syncStoreWithUrl,
  SyncStoreWithUrlPayload
} from 'modules/search/slice'
import {defaultTo} from 'ramda'
import {hasDefaultDates as hasMetaDefaultDates} from 'utils/trafficSource'

import {redirectUsersFromCallbackUrl} from '@daedalus/core/src/auth/modules/thunks'
import {DEFAULT_ROOMS} from '@daedalus/core/src/room/business/roomConfiguration/config'

import {
  DatePickerType,
  OpenState,
  SearchBoxAncestor,
  SearchBoxStateType,
  SearchBoxValues,
  SearchTrigger,
  SetSuggestionsPayload
} from './types'
import {getValidSearchBoxValues} from './utils'

const initialStateValues: SearchBoxValues = {
  rooms: DEFAULT_ROOMS
}

export const initialState: SearchBoxStateType = {
  ancestor: null,
  searchBoxDestinationDisplayValue: null,
  isDefaultDatesMessageVisible: null,
  isDatesVisible: true,
  isAnchorHotelUnavailable: null,
  openState: OpenState.Unset,
  isSearchBoxActive: false,
  openDatePickerType: null,
  values: initialStateValues,
  suggestions: [],
  searchTrigger: SearchTrigger.Submit,
  suggestionsSearchValue: ''
}

/**
 * Gets the open state of the search box
 * @param state - The SearchBox state
 * @param payload - The payload from the `syncStoreWithUrl` side effect
 * @returns The SearchBox `OpenState`
 */
const getOpenState = (
  state: SearchBoxStateType,
  payload: SyncStoreWithUrlPayload
): OpenState => {
  const {locationState = {}, urlParams} = payload
  const isUserSearch = urlParams?.userSearch === '1'

  if (urlParams.openSearchBox) {
    return OpenState.OpenByDefault
  }

  // Close searchbox when user comes from any home page widget
  if (
    [
      SearchTrigger.RecentSearches,
      SearchTrigger.ContinueSearchWidget,
      SearchTrigger.ExtendYourStayWidget,
      SearchTrigger.TopDealsTonightWidget
    ].includes(state.searchTrigger)
  ) {
    return OpenState.Closed
  }

  // TODO: (@search) - relook openstate logic once we clean up 15b632cb-rebrand-search
  // This is a hack so that we never set the searchbox to open on the home page by default which was
  // causing the searchbox to be OPEN_BY_DEFAULT when navigating to SRP
  if (
    !urlParams.hotelId &&
    !urlParams.placeId &&
    !urlParams.address &&
    !urlParams.userLocationSearch &&
    !urlParams.boundingBox
  ) {
    return OpenState.Closed
  }

  // SearchTrigger is set when triggering a search
  // We use it to determine whether the searchbox should be open afterwards
  const userHasOpenedSearchBox =
    [
      SearchTrigger.DatePicker,
      SearchTrigger.GuestPicker,
      SearchTrigger.Suggestions
    ].includes(state.searchTrigger) || locationState?.openDatePickerType
  if (userHasOpenedSearchBox) return OpenState.OpenByUser

  // Initially set to open but should be closed if it is a user search
  if (state.openState === OpenState.OpenByDefault && isUserSearch)
    return OpenState.Closed

  // Retain already initialized state
  if (state.openState !== OpenState.Unset) return state.openState

  // Determine initial/fallback state
  if (payload.isSearchBoxOpenByDefault) {
    return OpenState.OpenByDefault
  }
  return OpenState.Closed
}

/**
 * Returns if the default dates message is visible
 */
const getDefaultDatesMessageVisibility = (
  isDefaultDatesMessageVisible: boolean,
  isSearchBoxOpenByDefault: boolean
): boolean =>
  defaultTo(
    false,
    defaultTo(isSearchBoxOpenByDefault, isDefaultDatesMessageVisible)
  )

export const DEFAULT_ANCESTOR = SearchBoxAncestor.Page

const {actions, reducer} = createSlice({
  name: 'searchBox',
  initialState,
  reducers: {
    openSearchBox: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<SearchBoxAncestor>
    ) => {
      state.ancestor = payload
      state.openState = OpenState.OpenByUser
    },
    closeSearchBox: (state: SearchBoxStateType) => {
      state.ancestor = null
      state.openState = OpenState.Closed
      state.isDefaultDatesMessageVisible = false
      state.searchTrigger = SearchTrigger.Close
      state.isSearchBoxActive = false
    },
    setSearchBoxValue: (
      state: SearchBoxStateType,
      {
        payload
      }: PayloadAction<{
        name: string
        value: unknown
      }>
    ) => {
      const isDatesChange = ['checkIn', 'checkOut'].includes(payload.name)
      if (isDatesChange) {
        state.isDatesVisible = true
        state.isDefaultDatesMessageVisible = false
      }

      state.values[payload.name] = payload.value
    },
    setSearchBoxValues: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<SearchBoxValues>
    ) => {
      const searchBoxValues = getValidSearchBoxValues(payload)
      const changedKeys = Object.keys(searchBoxValues)
      const isDatesChange =
        changedKeys.includes('checkIn') || changedKeys.includes('checkOut')
      if (isDatesChange) {
        state.isDatesVisible = true
        state.isDefaultDatesMessageVisible = false
      }

      state.values = {
        ...state.values,
        ...searchBoxValues
      }
    },
    setSearchBoxDestinationDisplayValue: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<string>
    ) => {
      state.searchBoxDestinationDisplayValue = payload
    },
    setOpenDatePickerType: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<DatePickerType>
    ) => {
      state.openDatePickerType = payload
    },
    setIsSearchBoxActive: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<boolean>
    ) => {
      state.isSearchBoxActive = payload
    },
    setSearchTrigger: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<SearchTrigger>
    ) => {
      state.searchTrigger = payload
    },
    setSuggestions: (
      state: SearchBoxStateType,
      {payload}: PayloadAction<SetSuggestionsPayload>
    ) => {
      state.suggestions = payload.suggestions
      state.suggestionsSearchValue = payload.suggestionsSearchValue
    }
  },
  extraReducers: {
    [syncStoreWithUrl.type]: (
      state: SearchBoxStateType,
      action: PayloadAction<SyncStoreWithUrlPayload>
    ) => {
      const {urlParams, isMobile} = action.payload
      const openState = getOpenState(state, action.payload)

      const isDefaultDatesMessageVisible = getDefaultDatesMessageVisibility(
        state.isDefaultDatesMessageVisible,
        action.payload.isSearchBoxOpenByDefault
      )
      const searchUsesFallbackDates = !(
        urlParams.checkIn || urlParams.checkInDistance
      ) // Added for SAPI
      const isUserSearch = urlParams?.userSearch === '1'
      const hasUserDates = isUserSearch || !searchUsesFallbackDates
      const isMetaDefaultDates = hasMetaDefaultDates(urlParams)
      // this logic should only be applied on mobile, currently limited by the exp. audience
      // when cleaning up make sure the desktop behaviour is reviewed
      const isDatesVisible =
        state.isAnchorHotelUnavailable || isMetaDefaultDates || hasUserDates

      state.openState = openState
      // instead for showing and hiding the shadow as it should be shown whenever the search box is open
      state.isSearchBoxActive =
        ((openState === OpenState.OpenByDefault ||
          openState === OpenState.OpenByUser) &&
          isMobile) ||
        state.isSearchBoxActive

      state.isDefaultDatesMessageVisible = isDefaultDatesMessageVisible
      state.isDatesVisible = isDatesVisible
      state.ancestor = state.ancestor || DEFAULT_ANCESTOR
    },
    [setSearchData.type]: (
      state: SearchBoxStateType,
      action: PayloadAction<SearchDataPayload>
    ) => {
      state.searchBoxDestinationDisplayValue = action.payload.objectDisplayName
    },
    [redirectUsersFromCallbackUrl.fulfilled.type]: (
      state: SearchBoxStateType
    ) => {
      state.ancestor = null
      state.openState = OpenState.Closed
      state.isDefaultDatesMessageVisible = false
      state.searchTrigger = SearchTrigger.Close
      state.isSearchBoxActive = false
    }
  }
})

export const {
  setSearchBoxValue,
  setSearchBoxValues,
  setSearchBoxDestinationDisplayValue,
  setOpenDatePickerType,
  openSearchBox,
  closeSearchBox,
  setSuggestions,
  setSearchTrigger,
  setIsSearchBoxActive
} = actions

export default reducer
