import {loadModule} from 'utils'

export const AccommodationOverlayContentLoadable = loadModule(
  async () =>
    import(
      /* webpackChunkName: "AccommodationOverlayContent" */
      './AccommodationOverlayContent'
    ),
  {}
)
