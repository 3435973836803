import React from 'react'
import {useSelector} from 'react-redux'
import {OfferPrice} from 'components/Offer/Shared/OfferPrice'
import {GHAPreferredRateTotalPrice} from 'components/Offer/Shared/OfferPrice/GHAPreferredRateTotalPrice'
import {getCurrencyCode} from 'modules/meta/selectors'
import {getShowTotalPriceToggle} from 'modules/sapiSearch/selectors'
import {getIsAccommodationPage} from 'utils/getIsAccommodationPage'

import {getIsLockedDeal} from '@daedalus/core/src/auth/modules/selectors'
import {OfferSecondaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface MainProps {
  hotelId: string
  anchorPriceTotal: number
  anchorPrice: number
  offer: SearchOffer
  showSignInToUnlock: boolean
  variant: OfferSecondaryLayoutVariant
  isSplitBooking?: false
  isRequiredOneTimePasswordFlow?: boolean
  showGHAPreferredRateTotalPrice?: boolean
  showPriceIndication?: boolean
}

interface SplitBookingProps {
  isSplitBooking: true
  hotelId: string
  anchorPriceTotal?: never
  anchorPrice?: never
  offer?: never
  showSignInToUnlock?: never
  variant?: never
  isRequiredOneTimePasswordFlow?: boolean
  showGHAPreferredRateTotalPrice?: never
  showPriceIndication?: never
}

export const PriceComponent: React.FC<MainProps | SplitBookingProps> = ({
  hotelId,
  anchorPriceTotal,
  anchorPrice,
  offer,
  variant,
  isSplitBooking,
  isRequiredOneTimePasswordFlow,
  showGHAPreferredRateTotalPrice = false,
  showPriceIndication = false
}) => {
  const showTotalPriceToggle = useSelector(getShowTotalPriceToggle)
  const currencyCode = useSelector(getCurrencyCode)
  const isAccommodationPage = getIsAccommodationPage()
  const isLocked =
    useSelector(getIsLockedDeal(offer)) || isRequiredOneTimePasswordFlow

  if (isSplitBooking) {
    return (
      <div data-id="Price">
        <OfferPrice isSplitBooking size="sm" hotelId={hotelId} />
      </div>
    )
  }

  if (!showTotalPriceToggle) {
    return (
      <div data-id="Price">
        <OfferPrice
          showAnchorPrice={false}
          offer={offer}
          anchorPrice={anchorPrice}
          hotelId={hotelId}
          size="sm"
          showLockIcon={variant === 'SecondaryDefault'}
          showAppIcon={variant === 'SecondaryDefault'}
          showPriceIndication={isAccommodationPage}
          isPrivateDeal={isLocked}
        />
      </div>
    )
  }

  return (
    <div data-id="Price">
      <OfferPrice
        showAnchorPrice={false}
        anchorPriceTotal={anchorPriceTotal}
        anchorPrice={anchorPrice}
        offer={offer}
        hotelId={hotelId}
        size="sm"
        showLockIcon={variant === 'SecondaryDefault'}
        showAppIcon={variant === 'SecondaryDefault'}
        isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
        showPriceIndication={showPriceIndication}
        isPrivateDeal={isLocked}
      />
      {showGHAPreferredRateTotalPrice && (
        <GHAPreferredRateTotalPrice
          value={offer.totalRate}
          currency={currencyCode as string}
        />
      )}
    </div>
  )
}
