import React from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route} from 'react-router-dom'

import {useIsReferralProgramEnabled} from '@daedalus/atlas/hooks/useIsReferralProgramEnabled'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {selectIsLoading} from '@daedalus/core/src/auth/modules/selectors'

interface Props {
  component: React.ElementType
  path: string
}

export const PrivateReferralRoute = ({component: Component, path}: Props) => {
  const isLoading = useSelector(selectIsLoading)
  const {brand} = useBrand()
  const isReferralProgramEnabled = useIsReferralProgramEnabled(brand)

  if (isLoading) return null

  return (
    <Route
      path={path}
      render={({location}) =>
        isReferralProgramEnabled ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: location}
            }}
          />
        )
      }
    />
  )
}
