import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import {forceSearch} from 'modules/search/slice'
import {toggle} from 'opticks'

import {getCookie, removeCookie} from '@daedalus/core/src/_web/utils/cookies'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const trackSapiPreheat = (
  dispatch: Dispatch,
  anchorRequest: PerformanceEntry
) => {
  const isSapiPreheatEligible = getCookie('X-Sapi-Preheat-Eligible')

  if (!isSapiPreheatEligible) return false
  removeCookie('X-Sapi-Preheat-Eligible')

  // Track experiment decision made in the lambda
  toggle('763a0e4b-preheat-anchor-search')

  const preHeatUrl = getCookie('X-Sapi-Preheat-Url')
  if (!preHeatUrl) return false

  removeCookie('X-Sapi-Preheat-Url')

  dispatch(
    trackEvent({
      category: Category.System,
      entity: Entity.SapiPreheat,
      action: Action.Requested
    })
  )

  const sapiSearchParams = new URL(anchorRequest.name).searchParams
  const preHeatSearchParams = new URL(preHeatUrl).searchParams

  // Compare the search params and make sure they are the same
  const allParams = new Set([
    ...sapiSearchParams.keys(),
    ...preHeatSearchParams.keys()
  ])
  const mismatchedParams = []
  allParams.forEach(key => {
    if (preHeatSearchParams.get(key) !== sapiSearchParams.get(key)) {
      mismatchedParams.push(key)
    }
  })

  if (
    mismatchedParams.length > 0 ||
    sapiSearchParams.size !== preHeatSearchParams.size
  ) {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.SapiPreheat,
        action: Action.Mismatched,
        payload: {
          mismatchedParams,
          sapiUrl: anchorRequest.name,
          preHeatUrl
        }
      })
    )
    dispatch(forceSearch())
  }
}

/**
 * This hook listens for the first /anchor request and:
 * 1. Checks if a preheat was triggered by checking the sapi_preheat_url cookie
 * 2. Triggers System_SapiPreheat_Requested event
 * 3. If the parameters for the preheat and real request are different:
 *   a. Triggers System_SapiPreheat_Mismatch event
 *   b. Updates the searchId in searchParams to force trigger a new search
 */
export const useTrackSapiPreheat = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const getAnchorRequest = (entries: PerformanceEntryList) =>
      entries.find(entry => entry.name.includes('/anchor?'))

    // Check if the request already happened
    const anchorRequest = getAnchorRequest(
      window.performance.getEntriesByType('resource')
    )

    if (anchorRequest) {
      trackSapiPreheat(dispatch, anchorRequest)
      return
    }

    const observer = new PerformanceObserver(list => {
      const entries = list.getEntries()
      const anchorRequest = getAnchorRequest(entries)
      if (anchorRequest) {
        trackSapiPreheat(dispatch, anchorRequest)
        observer.disconnect()
      }
    })

    observer.observe({entryTypes: ['resource']})

    return () => {
      observer.disconnect()
    }
  }, [dispatch])
}
