import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getShareYourFeedbackVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {ToastProvider} from '@daedalus/atlas/context/toastController'

import {ShareYourFeedbackOverlay} from '../ShareYourFeedbackOverlay'

export const MobileOverlays = () => {
  const dispatch = useDispatch()
  const isShareFeedbackOverlayOpen = useSelector(getShareYourFeedbackVisibility)

  const handleHide = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  return (
    <ToastProvider>
      <ShareYourFeedbackOverlay
        isOpen={isShareFeedbackOverlayOpen}
        onClose={handleHide}
      />
    </ToastProvider>
  )
}
