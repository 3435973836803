import Settings from 'Settings'

import {runInIdleTime} from '@daedalus/core/src/_web/utils/performance/idleUntilUrgent'
import {dataDogLogEvent} from '@daedalus/core/src/analytics/components/DataDogClient'
import {experimentVariationsStore} from '@daedalus/core/src/experiments/components/OpticksProvider'

import {getFullStorySessionUrl} from './getFullStorySessionUrl'

export const logEvent = (
  event: string,
  metadata: Record<string, unknown> = {},
  level?: 'error' | 'info' | 'debug' | 'warn'
) => {
  const log = () => {
    const dataDogEnabled = Boolean(Settings.get('CLIENT_DD_CLIENT_TOKEN'))
    if (!dataDogEnabled) return

    const experiments = experimentVariationsStore.getAll(true)
    const fsSessionUrl = getFullStorySessionUrl()

    dataDogLogEvent(
      `Search.${event}`,
      {
        ...metadata,
        experiments,
        fsSessionUrl
      },
      level
    )
  }

  runInIdleTime(log)
}
