/**
 * [OffersV3]
 *
 * This file contains the old mapping logic between the new Offers Model V3 and
 * the old daedalus backend augmentation format.
 *
 * Added as part of [this epic](https://app.shortcut.com/findhotel/epic/62938/offers-v3-model-search-clients-migration)
 * as a temporary solution while we migrate all usages to the new models.
 * Should be deleted after the epic is completed.
 */

import {getMeta} from 'modules/meta/selectors'
import {getIsMultiRoomBundlesSearch} from 'modules/search/selectors'

import {
  MappingContext,
  OldHotelOfferEntity,
  sapiHotelOfferEntityToHotelOfferEntity as sapiHotelOfferEntityToHotelOfferEntityCore
} from '@daedalus/core/src/offer/business/offersMapping'
import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {BaseMappingContext} from '@daedalus/core/src/sapi/services/searchApi/utils'
import {
  HotelOfferEntity as SapiHotelOfferEntity,
  SearchResults
} from '@findhotel/sapi'

import {getSearchParameters, State} from './selectors'

export type {OldHotelOfferEntity} from '@daedalus/core/src/offer/business/offersMapping'

/**
 * Extend core offer mapping with local modifications for client
 */
export function sapiHotelOfferEntityToHotelOfferEntity(
  offerEntity: SapiHotelOfferEntity,
  context: MappingContext
): OldHotelOfferEntity | undefined {
  const mappedOfferEntity = sapiHotelOfferEntityToHotelOfferEntityCore(
    offerEntity,
    context
  )

  return {
    ...mappedOfferEntity,
    offers: mappedOfferEntity.offers
  }
}

/**
 * Base offer mapping options (enough for `core/searchApi`)
 */
export function getBaseMappingContextFromState(
  state: State
): BaseMappingContext {
  const {includeLocalTaxes, includeTaxes} = getMeta(state)
  const isMultiRoomBundlesSearch = getIsMultiRoomBundlesSearch(state)
  return {
    includeLocalTaxes,
    includeTaxes,
    includeRoomsInNightlyPrice: isMultiRoomBundlesSearch
  }
}

/**
 * Full offer mapping options including the searched itinerary
 */
export function getMappingContextFromState(state: State): MappingContext {
  const {checkIn, checkOut, rooms} = getSearchParameters(state)
  const {numberOfRooms} = roomsToConfigurationObject(rooms)
  return {
    ...getBaseMappingContextFromState(state),
    checkIn,
    checkOut,
    numberOfRooms
  }
}

/**
 * For use in sapiSearch middleware
 */
export function sapiToDaedalusModel(results: SearchResults, state: State) {
  if (!results?.hotelOfferEntities) {
    return {
      results,
      hotelOfferEntities: {}
    }
  }

  const context = getMappingContextFromState(state)

  const hotelOfferEntities = Object.fromEntries(
    Object.entries(results.hotelOfferEntities).map(
      ([hotelId, sapiHotelOfferEntity]) => {
        return [
          hotelId,
          sapiHotelOfferEntityToHotelOfferEntity(sapiHotelOfferEntity, context)
        ]
      }
    )
  )

  return {
    ...results,
    hotelOfferEntities
  }
}
