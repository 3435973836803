import {loadModule} from 'utils'

export const Referral = loadModule(
  async () =>
    import(
      /* webpackChunkName: "Referral" */
      './Referral'
    ),
  {}
)
