import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LocalizationConfig} from 'types/Localization'
import {MetaState} from 'types/State'

import {safelyGetStorageItem} from '@daedalus/core/src/_web/utils/persistence'
import {Page} from '@daedalus/core/src/analytics/types/Events'

import {getLocalizationConfig} from './getLocalizationConfig'

import type {DeviceCategory} from '@daedalus/core/src/utils/userAgent/types'

export const initialState: MetaState = {
  address: undefined,
  anonymousId: undefined,
  analyticsPageName: undefined,
  brandCode: undefined,
  currencyCode: undefined,
  deviceCategory: undefined,
  deviceLayout: undefined,
  devicePlatform: undefined,
  fallbackLanguages: undefined,
  includeLocalTaxes: undefined,
  includeTaxes: undefined,
  isRTLLanguage: undefined,
  languageCode: undefined,
  localeCode: undefined,
  trafficSource: undefined,
  userCountryCode: undefined,
  userCountryRegion: undefined,
  userMeasurementSystem: undefined,
  viewPortHeight: undefined,
  viewPortWidth: undefined,
  localizationIsInitialized: false,
  isAcquiringGeoCoordinates: false,
  showComparisonOffers: true,
  isReturningUser: false,
  firstVisit: '',
  userAgentDetails: undefined,
  deviceId: undefined,
  visitsCount: 0
}

interface SetDeviceLayout {
  deviceCategory: DeviceCategory
  viewPortHeight: number
  viewPortWidth: number
}

interface setAnalyticsPageName {
  page: Page
}

const {actions, reducer} = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setDeviceLayout: (state, {payload}: PayloadAction<SetDeviceLayout>) => {
      state.deviceLayout = payload.deviceCategory
      state.viewPortHeight = payload.viewPortHeight
      state.viewPortWidth = payload.viewPortWidth
    },
    setAnalyticsPageName: (
      state,
      {payload}: PayloadAction<setAnalyticsPageName>
    ) => {
      state.analyticsPageName = payload.page
    },
    setIsAcquiringGeoCoordinates: (
      state,
      {payload}: PayloadAction<boolean>
    ) => {
      state.isAcquiringGeoCoordinates = payload
    },
    setLocalizationConfig: (
      state,
      {payload}: PayloadAction<Partial<LocalizationConfig>>
    ) => {
      const includeTaxes = safelyGetStorageItem(localStorage, 'includeTaxes')
      const includeLocalTaxes = safelyGetStorageItem(
        localStorage,
        'includeLocalTaxes'
      )

      if (includeTaxes) {
        return {
          localizationIsInitialized: true,
          ...state,
          ...payload,
          includeLocalTaxes: includeLocalTaxes === '1',
          includeTaxes: includeTaxes === '1'
        }
      }

      return {
        localizationIsInitialized: true,
        ...state,
        ...payload
      }
    },
    setShowComparisonOffers: (
      state,
      {payload}: PayloadAction<{showComparisonOffers: boolean}>
    ) => ({
      ...state,
      showComparisonOffers: payload.showComparisonOffers
    }),
    setDeviceId: (state, {payload}: PayloadAction<{deviceId: string}>) => {
      state.deviceId = payload.deviceId
    },
    // Added as part of 2a98dd4d-add-new-languages--v2
    setLanguageCode: (state, {payload}: PayloadAction<string>) => {
      state.languageCode = payload
    },
    // Added as part of 30288e4c-include-taxes-in-california experiment
    setTaxConfigDangerously: (
      state,
      {
        payload
      }: PayloadAction<
        Pick<LocalizationConfig, 'includeLocalTaxes' | 'includeTaxes'>
      >
    ) => {
      state.includeLocalTaxes = payload.includeLocalTaxes
      state.includeTaxes = payload.includeTaxes
    }
  }
})

export const initializeLocalizationConfig = () => async dispatch => {
  const localizationConfig = await getLocalizationConfig(dispatch)
  dispatch(actions.setLocalizationConfig(localizationConfig))
}

export default reducer

export const {
  setDeviceLayout,
  setAnalyticsPageName,
  setLocalizationConfig,
  setIsAcquiringGeoCoordinates,
  setShowComparisonOffers,
  setDeviceId,
  setLanguageCode,
  setTaxConfigDangerously
} = actions
