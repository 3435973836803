import React from 'react'

import {usePriceWatchToggleWebLogic} from '../../hooks/usePriceWatchToggleWebLogic'
import {PriceWatchCTAParams} from '../../types'

export const WebComponentWrapper = ({...props}: PriceWatchCTAParams) => {
  const {handleClick, isToggleOn} = usePriceWatchToggleWebLogic(props)

  const {ctaComponent: CtaComponent, shouldPulse} = props

  if (!CtaComponent) return null
  return (
    <CtaComponent
      isToggleOn={isToggleOn}
      handleClick={handleClick}
      shouldPulse={shouldPulse}
    />
  )
}
