export const determineIsReturningUser = (
  firstVisit: string | null
): boolean => {
  if (!firstVisit) return false
  const firstVisitDate = new Date(firstVisit)
  const now = new Date()
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000) // 24 hours in milliseconds

  return firstVisitDate < twentyFourHoursAgo
}
