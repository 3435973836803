import {OfferClickedParams} from 'middleware/analytics/actions/trackOfferClicked'
import {
  getSearchParameters,
  State as SapiState
} from 'modules/sapiSearch/selectors'
import {getIsDefaultDatesSearch} from 'modules/search/selectors'
import Settings from 'Settings'
import {RootState} from 'store'

/**
 * These functions decorate events with properties specifically meant for remarketing tags in GTM
 * Props are namespaced to make the coupling explicit
 */
export const getRemarketingPageViewParams = (pageName: string) => {
  const enabledPages = new Set(['Home'])
  return enabledPages.has(pageName)
    ? {
        remarketingPageType: pageName.toLowerCase()
      }
    : {}
}

export const getSapiOfferClickedRemarketingParams = (
  state: RootState,
  params: OfferClickedParams
) => {
  const currencyExchangeRateEur = Settings.get('CURRENCY_EXCHANGE_RATE_EUR')
  const remarketingTotalValueEur = currencyExchangeRateEur
    ? params.calculatedTotalRate / currencyExchangeRateEur
    : null // currency exchange service can fail

  const isDefaultDatesSearch = getIsDefaultDatesSearch(state)
  const {checkIn, checkOut} = getSearchParameters(state as SapiState)
  const dates = isDefaultDatesSearch
    ? {}
    : {
        remarketingCheckIn: checkIn,
        remarketingCheckOut: checkOut
      }

  return {
    remarketingPageType: 'conversionintent',
    remarketingHotelId: params.hotelId,
    remarketingTotalValueEur,
    ...dates
  }
}
