import {SerializeQueryArgs} from '@reduxjs/toolkit/dist/query/defaultSerializeQueryArgs'
import {defaultSerializeQueryArgs} from '@reduxjs/toolkit/query'
import {omit} from 'ramda'

interface BaseQueryParams {
  useSingleCacheKey?: boolean
}

// Type guard to check if an object has `useSingleCacheKey`
const hasUseSingleCacheKey = (
  queryArgs: BaseQueryParams
): queryArgs is {useSingleCacheKey?: boolean} => {
  return queryArgs.useSingleCacheKey !== undefined
}

/**
 * Create a serializer that allows us to customize the cache behavior
 *
 * Serializer option: `ignoreKeys`
 * - Ignore these keys for ALL queries for this endpoint when generating a cache key
 * - Intended for custom transformations / side-effects
 *
 * Query hook option: `useSingleCacheKey`
 *  - Pass in query (hook) arguments to store data under a single key for the endpoint, regardless of query arguments
 *  - Use `select({useSingleCacheKey: true})` to retrieve the data for the last query call in selectors
 */
export const makeSerializeQueryArgs =
  <Args>({
    ignoreKeys
  }: {ignoreKeys?: string[]} = {}): SerializeQueryArgs<Args> =>
  ({queryArgs, endpointDefinition, endpointName}) => {
    if (!queryArgs) return endpointName

    // Type guard to check if `useSingleCacheKey` exists in queryArgs
    if (hasUseSingleCacheKey(queryArgs)) return endpointName

    const filteredQueryArgs = ignoreKeys
      ? omit(ignoreKeys, queryArgs)
      : queryArgs

    return defaultSerializeQueryArgs({
      endpointName,
      queryArgs: filteredQueryArgs,
      endpointDefinition
    })
  }
