/* eslint-disable @typescript-eslint/no-unused-vars */
import {getTrafficSourceFromState} from 'modules/meta/selectors'
import {parse} from 'qs'
import {toLower} from 'ramda'
import {createSelector} from 'reselect'
import {RootState} from 'store'

import {parseQueryString} from '@daedalus/core/src/utils/url'

const DA_TYPE_DEFAULT = 'default'
export const UTM_SOURCE_DEFAULT = 'unknown'

export const getTrafficSource = (
  utmSource: string | null | undefined,
  label: string | null | undefined
): string => {
  const labelParams = new URLSearchParams(label)

  const source = utmSource || labelParams.get('src') || UTM_SOURCE_DEFAULT
  return toLower(source)
}

const getQueryStringObject = createSelector(
  [(_state: RootState) => window.location.search],
  queryString => parse(queryString, {ignoreQueryPrefix: true})
)

export const getLandingTrafficSourceFromUrl = createSelector(
  [getQueryStringObject],
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({label, utm_source}) => getTrafficSource(utm_source, label)
)

const getTrafficMediumFromUrl = createSelector(
  [getQueryStringObject],
  ({utm_medium}) => utm_medium
)

const getReferralPageFromUrl = createSelector(
  [getQueryStringObject],
  ({referrer_page}) => referrer_page
)

const getIsSharedLink = createSelector(
  [getLandingTrafficSourceFromUrl],
  trafficSource => trafficSource === 'sharedlink'
)

export const getIsFromTripAdvisor = createSelector(
  [getLandingTrafficSourceFromUrl],
  trafficSource => trafficSource === 'tripadvisor'
)

export const getIsFromGha = createSelector(
  [getTrafficSourceFromState],
  trafficSource => trafficSource === 'gha'
)

export const getIsFromGVR = createSelector(
  [getTrafficSourceFromState],
  trafficSource => trafficSource === 'gha-vr'
)

export const hasDefaultDates = (urlParameters: {label?: string}): boolean => {
  const searchLabel = urlParameters.label || ''
  const {datype}: {datype?: string} = parseQueryString(searchLabel)

  return Boolean(datype?.toLowerCase() === DA_TYPE_DEFAULT)
}

interface CheckInParams {
  checkIn?: string
  checkInDistance?: number
}

export const hasDates = (searchParams: CheckInParams): boolean => {
  const {checkIn, checkInDistance} = searchParams

  return Boolean(checkIn || checkInDistance)
}
