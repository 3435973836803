import axios from 'axios'

import {setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {getDomainName} from '@daedalus/core/src/utils/url'

const axiosInstance = axios.create()

interface CancelPriceWatch {
  priceWatchId: string
  userId: string
  priceWatchURL: string
}
export const cancelPriceWatchAPI = async ({
  priceWatchId,
  userId,
  priceWatchURL
}: CancelPriceWatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const cancelPriceWatchURL = `${priceWatchURL}/cancel`

  const {data} = await axiosInstance.post<string>(
    cancelPriceWatchURL,
    JSON.stringify({
      priceWatchId,
      userId
    }),
    config
  )

  return data
}

export const PRICE_WATCH_BANNER_COOKIE_NAME = 'sawPriceWatchBanner'

export const setSawPriceWatchTooltipCookie = () => {
  setCookie(PRICE_WATCH_BANNER_COOKIE_NAME, 'true', {
    domain: getDomainName(window.location.hostname),
    expires: 7 // 7 days
  })
}
