import React from 'react'

import {Box} from '@daedalus/atlas/helpers/Box'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

export const TopOfferInfoSkeleton = () => (
  <Box
    display="flex"
    flexDirection="column"
    width="100%"
    justifyContent="flex-end"
  >
    <SkeletonLoader width={58} height={32} />
    <SkeletonLoader width={85} height={20} marginTop={4} />
    <SkeletonLoader width={201} height={34} marginTop={8} />
  </Box>
)
