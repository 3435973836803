import {Hotel} from 'modules/sapiSearch/slice'
import {Lov} from 'modules/search/slice'

import {
  ContentHotel,
  TranslatedLOVItem
} from '@findhotel/sapi/dist/types/packages/core/src'

const getLovItem = (
  listOfValues: Lov,
  kind: string,
  id: number
): TranslatedLOVItem =>
  listOfValues && id
    ? (listOfValues[`${kind}:${id}`] as TranslatedLOVItem)
    : undefined

const getLovItems = (
  listOfValues: Lov,
  kind: string,
  ids: number[]
): TranslatedLOVItem[] =>
  listOfValues && ids
    ? ids.map(id => getLovItem(listOfValues, kind, id)).filter(Boolean)
    : []

/**
 * Merge list of values into hotel to match ContentHotel data structure
 * Some type-casting is done to resolve discrepancies between legacy / SAPI types
 */
export const mergeListOfValues = (
  hotel: Hotel,
  listOfValues: Lov
): ContentHotel => {
  return {
    ...hotel,
    _geoloc: hotel._geoloc as unknown as ContentHotel['_geoloc'],
    displayAddress:
      hotel.displayAddress as unknown as ContentHotel['displayAddress'],
    facilities: getLovItems(listOfValues, 'Facility', hotel.facilities),
    themes: getLovItems(listOfValues, 'Theme', hotel.themeIds),
    sentiments: getLovItems(listOfValues, 'Sentiment', hotel.sentiments),
    propertyType: getLovItem(listOfValues, 'PropertyType', hotel.propertyTypeId)
  }
}
