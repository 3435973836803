import React from 'react'
import {HotelCard} from 'components/HotelCard'
import {HotelCardProvider} from 'components/HotelCardContext'
import {Hotel} from 'modules/sapiSearch/slice'

interface Props {
  hotelId: Hotel['objectID']
  shouldShowDivider?: boolean
  index: number
}

const SearchResultsListItemComponent: React.FC<Props> = ({
  hotelId,
  shouldShowDivider,
  index
}) => {
  return (
    <HotelCardProvider key={hotelId} hotelId={hotelId}>
      <HotelCard
        shouldShowDivider={shouldShowDivider}
        isPriorityCard={index === 0}
      />
    </HotelCardProvider>
  )
}

export const SearchResultsListItem = React.memo(SearchResultsListItemComponent)
