import React, {ReactNode, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {
  Source,
  trackSystemPageErrored
} from 'middleware/analytics/actions/trackSystemPageErrored'
import {DynamicOptionsLoadingProps} from 'next/dynamic'

import {CrashErrorMessageContainer} from '@daedalus/shared/src/search/CrashErrorMessage'

export type Props = DynamicOptionsLoadingProps & {
  loadingComponent?: ReactNode
}

const ImportLoader = ({error, loadingComponent, pastDelay}: Props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      dispatch(
        trackSystemPageErrored({
          payload: {
            source: Source.ImportLoader,
            message: error.message
          }
        })
      )
    }
  }, [error, dispatch])

  if (error) {
    return <CrashErrorMessageContainer />
  }

  if (pastDelay && loadingComponent) {
    return <>{loadingComponent}</>
  }

  return null
}

export default ImportLoader
