import load from 'react-loadable'

import type React from 'react'

export const loadModule = <Props>(
  loader: () => Promise<
    React.ComponentType<Props> | {default: React.ComponentType<Props>}
  >,
  options: Record<string, unknown>
) =>
  load<Props, object>({
    loader,
    loading: () => null,
    timeout: 10000,
    delay: 500,
    ...options
  })
