import {loadModule} from 'utils'

export const Membership = loadModule(
  async () =>
    import(
      /* webpackChunkName: "Membership" */
      './Membership'
    ),
  {}
)
