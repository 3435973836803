import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import isValid from 'date-fns/isValid'
import lastDayOfISOWeek from 'date-fns/lastDayOfISOWeek'
import parseISO from 'date-fns/parseISO'
import {curry, pipe} from 'ramda'

import {isRateCheckerFromUrl} from '../rateChecker/business'
import {
  dateStringToMiddayDate,
  dateToMiddayDate,
  ensureCheckInOnOrAfterToday,
  UTS_DATE_FORMAT
} from '../utils/date'
import {UrlParamsType} from '../utils/url/types/UrlParams'

const getDefaultDate = () => {
  const today = dateToMiddayDate(new Date())

  return lastDayOfISOWeek(addMonths(today, 1))
}

const dateStringOrDefault = (dateString?: string) => {
  if (!dateString) return dateToMiddayDate(new Date())
  const date = dateStringToMiddayDate(dateString)
  return isValid(date) ? date : getDefaultDate()
}

/**
 * Determines the earliest valid check-in date based on the given check-in date and the current time.
 * If the check-in date is before yesterday, default dates are returned.
 * If the check-in date is yesterday and it is currently early morning, yesterday's date is returned as the check-in date.
 * If the check-in date is yesterday and it is currently past early morning, today's date is returned.
 * If the check-in date is today or later, the user's check-in date is returned.
 *
 * @param checkIn - The check-in date to evaluate.
 * @returns The earliest valid check-in date.
 */
export const determineValidCheckInDate = (
  checkIn: Date,
  isCheckInForced = false
): Date => {
  const now = new Date()
  const today = dateToMiddayDate(now)
  const checkInDaysSinceToday = differenceInDays(checkIn, today)

  if (isCheckInForced) {
    return checkIn
  }

  if (checkInDaysSinceToday < -1) {
    return getDefaultDate()
  }

  return checkIn
}

/**
 * Returns a valid check-out date based on a valid check-in date, while ensuring that the length of stay is maintained.
 * @param checkIn - check-in date to be compared
 * @param validCheckIn - valid checkIn date from determineValidCheckInDate function
 * @param checkOut - check-out date to be compared
 * @returns A valid checkout date
 */
const ensureCheckOutAfterCheckIn = curry(
  (checkIn: Date, validCheckIn: Date, checkOut: Date) => {
    const originalLengthOfStay = differenceInDays(checkOut, checkIn)
    const today = dateToMiddayDate(new Date())

    // If the checkIn date is not valid or length of stay is less than one night or the checkIn date is before yesterday
    if (
      !originalLengthOfStay ||
      originalLengthOfStay < 1 ||
      differenceInDays(checkIn, today) < -1
    )
      return addDays(validCheckIn, 1)

    return addDays(validCheckIn, originalLengthOfStay)
  }
)

const formatDate = (date: Date): string => format(date, UTS_DATE_FORMAT)

export const validateSearchParamDates = <T extends Partial<UrlParamsType>>(
  searchParams: T
): T => {
  const {checkIn, checkOut} = searchParams
  const isRateChecker = isRateCheckerFromUrl(searchParams)
  if (!checkIn) return searchParams

  const validCheckIn = pipe(
    dateStringOrDefault,
    dateToMiddayDate,
    checkIn => determineValidCheckInDate(checkIn, isRateChecker),
    formatDate
  )(checkIn)

  const validCheckOut = pipe<
    [dateString?: string | undefined],
    Date,
    Date,
    Date,
    string
  >(
    dateStringOrDefault,
    dateToMiddayDate,
    ensureCheckOutAfterCheckIn(parseISO(checkIn), parseISO(validCheckIn)),
    formatDate
  )(checkOut || checkIn)

  return {
    ...searchParams,
    checkIn: validCheckIn,
    checkOut: validCheckOut
  }
}

// TODO (Core): Add unit tests and documentation for this function
export const getCheckOutDate = (checkIn: string, numberOfNights: number) => {
  const checkInDate = dateStringToMiddayDate(checkIn)
  const checkOutDate = new Date(checkInDate)
  checkOutDate.setDate(checkInDate.getDate() + numberOfNights)
  return checkOutDate
}

const SEARCH_RANGE = 14 // days

// TODO (Core): Add unit tests for this function
/**
 * Returns the initial search dates, based on the check in date.
 * If the check in date is further ahead than today + SEARCH_RANGE,
 * the search dates will be centered around the check in date.
 * Otherwise, the search dates will be from today.
 * @param checkIn
 * @returns {{startDate: Date, endDate: Date}}
 */
export const getInitialSearchDates = (checkIn: string) => {
  let startDate = null
  let endDate = null

  const today = dateToMiddayDate(new Date())
  const isCheckInPastMidSearchRange =
    differenceInDays(new Date(checkIn), addDays(today, SEARCH_RANGE)) > 0

  if (isCheckInPastMidSearchRange) {
    startDate = ensureCheckInOnOrAfterToday(
      addDays(new Date(checkIn), -SEARCH_RANGE / 2)
    )
    endDate = addDays(new Date(checkIn), SEARCH_RANGE / 2)
  } else {
    startDate = today
    endDate = addDays(today, SEARCH_RANGE)
  }

  return {
    startDate,
    endDate
  }
}
