import {useEffect, useState} from 'react'
import {useInView as useInViewLib} from 'react-intersection-observer'

import type {
  IntersectionOptions,
  InViewHookResponse
} from 'react-intersection-observer'

const loadIntersectionObserver = async () =>
  import(
    /* webpackChunkName: "IntersectionObserver" */
    'intersection-observer'
  )
const isUnitTestEnv = process.env.NODE_ENV === 'test'
const hasNativeIntersectionObserver =
  typeof IntersectionObserver !== 'undefined'
let isIntersectionObserverAvailable = hasNativeIntersectionObserver

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

/**
 * `react-intersection-observer` useInView hook with lazy polyfill.
 * This is added because on some browsers where IntersectionObserver is supported we are seeing errors that "IntersectionObserver is undefined",
 * probably because the IntersectionObserver feature is turned off in the Webkit Experimental Features menu.
 *
 * GitHub issue : https://github.com/thebuilder/react-intersection-observer/issues/495
 *
 * @returns useInView hook with the polyfilled intersection-observer if necessary
 */
export const useInView: typeof useInViewLib =
  hasNativeIntersectionObserver || isUnitTestEnv
    ? useInViewLib
    : (args?: IntersectionOptions) => {
        const [isIntersectionObserverReady, setIsIntersectionObserverReady] =
          useState<boolean>(isIntersectionObserverAvailable)

        const result = useInViewLib({
          ...args,
          skip: !isIntersectionObserverReady
        })

        useEffect(() => {
          if (isIntersectionObserverAvailable) {
            return
          }

          loadIntersectionObserver()
            .then(() => {
              isIntersectionObserverAvailable = true
              setIsIntersectionObserverReady(true)
            })
            .catch(() =>
              console.error('Failed to load intersection-observer polyfill')
            )
        }, [])

        const polyfilledResult: InViewHookResponse = {
          ...result,
          ref: noop
        }

        return isIntersectionObserverReady ? result : polyfilledResult
      }
