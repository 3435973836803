import React from 'react'
import {useSelector} from 'react-redux'
import {LocationDetails} from 'components/HotelCardLocationDetails'
import {getDistanceFromTarget} from 'modules/sapiSearch/selectors'
import {HotelType} from 'types/Hotel'
import {imageProvider} from 'utils/imageProvider'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Image} from '@daedalus/atlas/Image'
import {StarRating} from '@daedalus/atlas/StarRating'
import {ReviewRating} from '@daedalus/shared/src/search/ReviewRating'
import {ContentHotel} from '@findhotel/sapi'

import {BEST_DEAL_IMAGE_HEIGHT} from './styles'
import {
  ContentDetailsWrapper,
  ExitPopupContentWrapper,
  HotelName,
  ImageWrapper,
  StarRatingWrapper
} from './styles'

interface Props {
  hotel: ContentHotel | HotelType // added as part of 16b5e700-exit-popup-desktop-ap to support hotel types on both SRP and AP
}
export const ExitPopupContent = ({hotel}: Props) => {
  const imageURL = hotel?.imageURIs[0] ?? ''
  const {isMobile} = useDeviceLayout()

  const distanceFromTarget = useSelector(state =>
    getDistanceFromTarget(state, hotel?.objectID)
  )
  return (
    <ExitPopupContentWrapper>
      <ImageWrapper>
        <Image
          isResponsive
          hasSkeletonLoader
          size={isMobile ? 'mobileLarge' : 'extraLarge'}
          url={imageURL}
          borderRadius="lg"
          height={BEST_DEAL_IMAGE_HEIGHT}
          imageProvider={imageProvider}
        />
      </ImageWrapper>
      <StarRatingWrapper data-id="StarRating">
        <StarRating size="xs" rating={hotel.starRating} />
      </StarRatingWrapper>
      <Flex.Row justifyContent="space-between" gap="s400">
        <ContentDetailsWrapper>
          <HotelName data-id="HotelName">{hotel.hotelName}</HotelName>
          <LocationDetails
            placeName={hotel.placeDisplayName}
            distance={distanceFromTarget}
          />
        </ContentDetailsWrapper>
        <ReviewRating
          rating={hotel?.guestRating.overall}
          totalRatingCount={hotel.reviewCount}
          hotelId={hotel.objectID}
        />
      </Flex.Row>
    </ExitPopupContentWrapper>
  )
}
