import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setCookiesAcceptedByUser} from 'modules/cookies/slice'
import {getIsSyntheticTest} from 'modules/meta/selectors'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {CookieConsentBannerWithStore} from '@daedalus/shared/src/search/cookies'

const CookieBanner = () => {
  const dispatch = useDispatch()
  const {brandIsCookieConsentEnabled} = useBrand()
  const isSyntheticTest = useSelector(getIsSyntheticTest)

  const isCookieConsentGivenExternally = Boolean(
    window.osanoMobileAppIdentifier
  )

  const handleSetCookiesAcceptedByUser = useCallback(
    (acceptedByUser: boolean) => {
      dispatch(setCookiesAcceptedByUser(acceptedByUser))
    },
    []
  )

  if (
    !brandIsCookieConsentEnabled ||
    isCookieConsentGivenExternally ||
    isSyntheticTest
  ) {
    return null
  }

  return (
    <CookieConsentBannerWithStore onAccept={handleSetCookiesAcceptedByUser} />
  )
}

export default React.memo(CookieBanner)
