import React, {useCallback, useRef} from 'react'
import {useSelector} from 'react-redux'
import {facilityIconsById} from 'components/Accommodation/Facilities/FacilityIcon'
import {getFiltersLabel} from 'components/Filters/utils'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getListOfValues} from 'modules/search/selectors'

import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const filterKeys = ['facilities']
const elementName = 'quickFilterRestaurant'

interface Props {
  isActive?: boolean
  facilityId: string
}
export const FacilityQuickFilterPill: React.FC<Props> = ({
  isActive = false,
  facilityId
}) => {
  const listOfValues = useSelector(getListOfValues)

  const buttonRef = useRef<HTMLButtonElement>(null)

  const {trackFilterClick, submitFilters, getAppliedFilters} = useFiltersForm(
    filterKeys,
    elementName
  )

  const handlePillClick = useCallback(() => {
    if (isActive) buttonRef.current?.blur()

    const activeFilters = getAppliedFilters()

    const activeFacilities = activeFilters.facilities || []

    const updatedFilters = {
      facilities: isActive
        ? activeFacilities.filter(id => facilityId !== id)
        : [...activeFacilities, facilityId]
    }

    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })
    submitFilters(updatedFilters)
  }, [isActive, trackFilterClick, submitFilters, getAppliedFilters])

  if (isActive) return null

  return (
    <Pill
      dataId={`facilityQuickFilter:${facilityId}`}
      onClick={handlePillClick}
      isSelected={isActive}
      iconStart={<Icon name={facilityIconsById[facilityId]} />}
      iconEnd={isActive && <Icon name={'Close'} />}
    >
      {getFiltersLabel('Facility', facilityId, listOfValues)}
    </Pill>
  )
}
