import { cx } from '@linaria/core';
export const HEADER_HEIGHT = 58;
export const DAY_SIZE = 44;
export const HORIZONTAL_PICKER_MIN_WIDTH = 600;
export const HORIZONTAL_PICKER_MAX_WIDTH = 802;
const baseStyles = "bh6tlq8";
const monthsWrapperStyles = "m1x6gvez";
const monthCaptionStyles = "m1b9t11n";
const navButtonsStyles = "n1k4o7e3";
const weekDaysStyles = "w160l9q";
const monthDaysStyles = "m453c0l";
const dayCellStyles = "dz11t0o";
const weekendsStyles = "wtrlevl";
const disabledDaysStyles = "d1l9t2gn";
const selectedDaysStyles = "splyghj";
const startRangeDayStyles = "s19811as";
const endRangeDayStyles = "e1ccaf75";
const startEndRangeDayStyles = "s109mx0c";
export const baseStyledDatepickerClassNames = cx(baseStyles, navButtonsStyles, monthsWrapperStyles, monthCaptionStyles, weekDaysStyles, monthDaysStyles, dayCellStyles, disabledDaysStyles);
export const datePickerElementClassNames = cx(baseStyledDatepickerClassNames, weekendsStyles, selectedDaysStyles, startEndRangeDayStyles, startRangeDayStyles, endRangeDayStyles);

require("./styles.wyw-in-js.css!=!../../../../../node_modules/@wyw-in-js/webpack-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");