/**
 * A comparator function to sort an array of numbers in ascending order.
 *
 * @param number a - The first number to compare.
 * @param number b - The second number to compare.
 * @returns number A positive value if `a` is greater than `b`, a negative value if `a` is less than `b`, and 0 if they're equal.
 */
export const calculateAscendingNumberArray = (a: number, b: number) => {
  return a - b
}

/**
 * Checks if the numbers in an array are sequential without any gaps.
 * note: Doesn't cater to duplicate numbers in the array
 *
 * @param number[] arr - The array of numbers to check.
 * @returns boolean Returns `true` if the numbers are sequential, `false` otherwise.
 */
export const isSequential = (arr: number[]): boolean => {
  return !(Number(arr.find((x, i) => Number(arr[i + 1]) - Number(x) > 1)) + 1)
}

/**
 * A function to calculate buckets of a large array and return the elements for a specific bucket.
 *
 * @param unknown[] arr - The list to be divided into buckets.
 * @param number maxPerBucket - The maximum number of elements per bucket.
 * @param number bucketIndex - The page number to get the elements for.
 * @returns unknown[] The elements for the specified bucket.
 */
export const getElementsPerBucket = (
  arr: unknown[],
  maxPerBucket: number,
  bucketIndex: number
): unknown[] => {
  const startIndex: number = bucketIndex * maxPerBucket
  const endIndex: number = Math.min(startIndex + maxPerBucket, arr.length)

  return arr.slice(startIndex, endIndex)
}
