import {createSlice} from '@reduxjs/toolkit'

import {
  getPriceWatchV2Keys,
  parsePriceWatchKey,
  persistPriceWatch
} from '../store'
import {
  ParsePriceWatchKeyParams,
  PriceWatchData,
  PriceWatchEntry
} from '../types'

import type {PayloadAction} from '@reduxjs/toolkit'

interface PriceWatchState {
  trackings: PriceWatchData
  isHoldOutGroup: boolean
}

const initialState = {
  trackings: getPriceWatchV2Keys(),
  isHoldOutGroup: false
} as PriceWatchState

const {actions, reducer} = createSlice({
  name: 'priceWatch',
  initialState,
  reducers: {
    removePriceWatchEntry(
      state: PriceWatchState,
      action: PayloadAction<ParsePriceWatchKeyParams>
    ) {
      const priceWatchKey = parsePriceWatchKey(action.payload)
      delete state.trackings[priceWatchKey]
      persistPriceWatch(priceWatchKey)
    },
    disablePriceWatchEntry(
      state: PriceWatchState,
      action: PayloadAction<ParsePriceWatchKeyParams>
    ) {
      const priceWatchKey = parsePriceWatchKey(action.payload)
      const entry = state.trackings?.[priceWatchKey]
      const priceWatchEntryParams = {
        ...entry,
        enabled: false
      }
      state.trackings[priceWatchKey] = priceWatchEntryParams
      persistPriceWatch(priceWatchKey, priceWatchEntryParams)
    },
    addPriceWatchEntryFromParams(
      state: PriceWatchState,
      {payload}: PayloadAction<Omit<PriceWatchEntry, 'enabled'>>
    ) {
      const priceWatchKey = parsePriceWatchKey(payload)
      const rate = state.trackings?.[priceWatchKey]?.rate || payload?.rate

      const priceWatchEntryParams = {
        ...payload,
        rate,
        enabled: true
      }
      state.trackings[priceWatchKey] = priceWatchEntryParams
      persistPriceWatch(priceWatchKey, priceWatchEntryParams)
    },
    setHoldOutGroup(state: PriceWatchState, action: PayloadAction<boolean>) {
      state.isHoldOutGroup = action.payload
    }
  }
})

export default reducer

export const {
  disablePriceWatchEntry,
  removePriceWatchEntry,
  addPriceWatchEntryFromParams,
  setHoldOutGroup
} = actions
