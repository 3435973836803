import React, {useContext} from 'react'
import {MessageDescriptor, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'

import {Button} from '@daedalus/atlas/Button'

import {ExternalActionsContext} from '../../context/ExternalActionsContext'
import {getFreezeSelectedContext} from '../../modules/selector'
import {closeAllFreezeOverlays, closeFreezeOverlay} from '../../modules/slice'
import {ErrorCases} from './types'

interface Props {
  errorCase: ErrorCases
  ctaText: MessageDescriptor
}
export const ErrorButton: React.FC<Props> = ({errorCase, ctaText}) => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()
  const {openSearchBox, setIsSearchBoxActive} = useContext(
    ExternalActionsContext
  )

  const {offer} = useSelector(getFreezeSelectedContext)
  const {rspUrl} = offer || {}
  const formattedCtaText = formatMessage(ctaText)

  const handleChangeRoom = () => {
    if (rspUrl) {
      window.open(rspUrl, '_blank')
    }

    dispatch(closeFreezeOverlay())
  }

  const handleChangeDates = () => {
    dispatch(closeFreezeOverlay())
    dispatch(openSearchBox('page'))

    if (setIsSearchBoxActive) {
      dispatch(setIsSearchBoxActive(true))
    }

    return
  }

  const handleClick = () => {
    switch (errorCase) {
      case ErrorCases.Unavailability: {
        handleChangeRoom()
        return
      }

      case ErrorCases.LimitReached: {
        dispatch(closeFreezeOverlay())
        return
      }

      case ErrorCases.UnexpectedError: {
        dispatch(closeAllFreezeOverlays())
        return
      }

      case ErrorCases.SoldOut:
      default: {
        handleChangeDates()
      }
    }
  }

  return (
    <Button
      dataId={`FreezeErrorCta-${errorCase}`}
      variant="secondary"
      fullWidth
      onClick={handleClick}
    >
      {formattedCtaText}
    </Button>
  )
}
