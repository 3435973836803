import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {getCurrencyCode, getLanguageCode} from 'modules/meta/selectors'
import {getShowTotalPrices} from 'modules/sapiSearch/selectors'
import {setShowTotalPrices} from 'modules/search/slice'
import Settings from 'Settings'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {getEnabledCurrencies} from '@daedalus/core/src/localization/business/currency'
import {
  PreferencesWidget,
  SelectedElement
} from '@daedalus/shared/src/user/preferences/PreferencesWidget'

export const PreferencesWidgetWithStore = () => {
  const dispatch = useDispatch()
  const {navigateWithParameters} = useUrlUpdater()
  const currentCurrency = useSelector(getCurrencyCode)
  const currentLanguage = useSelector(getLanguageCode)
  const history = useHistory()
  const showTotalPrices = useSelector(getShowTotalPrices)

  const {
    location: {pathname}
  } = history

  const enabledCurrencies = getEnabledCurrencies(
    Settings.get('ENABLED_CURRENCIES')
  )

  const handlePreferencesChange = useCallback(
    async ({hasCurrencyChanged, hasLanguageChanged, currency, language}) => {
      if (hasLanguageChanged) {
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.LanguageChange,
            action: Action.Submitted
          })
        )
      }

      if (hasCurrencyChanged) {
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.CurrencyChange,
            action: Action.Submitted
          })
        )
      }

      const buildLanguageParams = () => {
        if (hasLanguageChanged) {
          return {
            lang: language,
            forceLanguageChange: 1 as BooleanUrlParam
          }
        }
      }

      const buildCurrencyParams = () => {
        if (hasCurrencyChanged) {
          return {
            currency,
            forceCurrencyChange: 1 as BooleanUrlParam,
            priceMin: undefined,
            priceMax: undefined,
            clickedOfferRate: undefined
          }
        }
      }

      setTimeout(() => {
        navigateWithParameters(
          {
            ...buildLanguageParams(),
            ...buildCurrencyParams()
          },
          {
            basePath: pathname
          }
        )
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
    },
    [dispatch, navigateWithParameters]
  )

  const handlePreferencesLinkClick = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.LanguageCurrencyPreferencesButton,
        action: Action.Clicked
      })
    )
  }, [dispatch])

  const handleSelectClick = useCallback(
    (selectedElement: SelectedElement) => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.LanguageCurrencyPreferencesDropdownMenu,
          action: Action.Clicked,
          payload: {
            selectedElement
          }
        })
      )
    },
    [dispatch]
  )

  const onChangePriceDisplay = useCallback(
    (showTotal: boolean) => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.PriceToggle,
          action: Action.Clicked
        })
      )
      dispatch(setShowTotalPrices(showTotal))
    },
    [dispatch]
  )

  return (
    <PreferencesWidget
      currentCurrency={currentCurrency}
      currentLanguage={currentLanguage}
      enabledCurrencies={enabledCurrencies}
      showPriceDisplaySetting
      showTotalPrices={showTotalPrices}
      onChangePriceDisplay={onChangePriceDisplay}
      onPreferencesChange={handlePreferencesChange}
      onPreferencesLinkClick={handlePreferencesLinkClick}
      onSelectClick={handleSelectClick}
    />
  )
}
