import {VIO_INTERMEDIARY_PROVIDER_CODE} from '@daedalus/core/src/offer/business/provider'
import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'

interface Props {
  isCheapest: boolean
  isBofhOffer?: boolean
}

export const getOfferVariant = ({
  /** Whether this offer is the cheapest in the current list */
  isCheapest,
  isBofhOffer
}: Props): OfferVariant => {
  if (isCheapest) {
    if (isBofhOffer) return OfferVariant.OnlyOnVio
    return OfferVariant.BestDeal
  }
  return OfferVariant.Popular
}

/** Determines whether this offer should be branded on the SRP and AP based on intermediary code and whether the book on brand functionality is enabled */
export const shouldOfferBeBranded = ({
  offerIntermediaryProviderCode,
  bookOnBrand,
  brandIsVio
}: {
  offerIntermediaryProviderCode: string
  bookOnBrand?: {
    enabled: boolean
  }
  brandIsVio: boolean
}): boolean => {
  return (
    bookOnBrand?.enabled &&
    offerIntermediaryProviderCode === VIO_INTERMEDIARY_PROVIDER_CODE &&
    brandIsVio === false
  )
}
