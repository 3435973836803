import React from 'react'
import {VioDealTagContainer} from 'components/Offer/Shared/VariantLabel/Tags/VioDealTagContainer/VioDealTagContainer'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {Tag} from '@daedalus/atlas/Tag'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface BaseProps {
  variant: OfferVariant
  anchorPrice?: number
  anchorPriceTotal?: number
  offer?: SearchOffer
  hasPadding?: boolean
}

export const VariantLabel: React.FC<BaseProps> = ({
  variant,
  anchorPrice,
  anchorPriceTotal,
  offer,
  hasPadding = false
}) => {
  switch (variant) {
    case OfferVariant.OnlyOnVio:
      return (
        <Box paddingBottom={hasPadding ? 's300' : 's000'}>
          <VioDealTagContainer
            anchorPrice={anchorPrice}
            anchorPriceTotal={anchorPriceTotal}
            offer={offer}
          />
        </Box>
      )

    case OfferVariant.MemberDeal:
      return (
        <Box paddingBottom={hasPadding ? 's300' : 's000'}>
          <Tag variant="info" iconStart={<Icon name="Sparkles" />}>
            <FormattedMessageWrapper
              id="offers.primary.variantLabel.memberdeal"
              defaultMessage="Member deal"
            />
          </Tag>
        </Box>
      )

    case OfferVariant.MemberPlusDeal:
      return (
        <Box paddingBottom={hasPadding ? 's300' : 's000'}>
          <Tag variant="info" iconStart={<Icon name="Sparkles" />}>
            <FormattedMessageWrapper
              id="plusDeal"
              defaultMessage="{tag} deal"
              values={{
                tag: 'Plus'
              }}
            />
          </Tag>
        </Box>
      )

    case OfferVariant.SplitBooking:
      return (
        <Box paddingBottom={hasPadding ? 's300' : 's000'}>
          <Tag variant="warning" iconEnd={<Icon name="Information" />}>
            <FormattedMessageWrapper
              id="splitBookingLabel"
              defaultMessage="Split booking"
            />
          </Tag>
        </Box>
      )

    default:
      return null
  }
}
