import {$PropertyType} from 'utility-types'

export const TRACK_PAGE_VIEW = 'TRACK_PAGEVIEW'
export const TRACK_CIO_PAGE_VIEW = 'TRACK_CIO_PAGE_VIEW'

export type PageViewParamsType = {
  currency?: string
  deviceCategory: string
  locale?: string
  userCountryCode: string
  trafficSource: string
  userId?: string
  checkIn?: string
  checkOut?: string
}

export type ActionType = {
  pageName: string
  params: PageViewParamsType | null | undefined
  type: typeof TRACK_PAGE_VIEW
}

type CIOPageViewParamsType = {
  id?: string
  currency: string
  locale: string
  checkIn?: number
  checkOut?: number
  pageUrl: string
  isUserSearch?: boolean
  hotelId?: string
  anonymousId: string
  rooms?: string
  hotelName?: string
  hotelAddress?: string
  hotelImageUrl?: string
  timezone: string
}

type CIOActionType = {
  pageName: string
  params: CIOPageViewParamsType | null | undefined
  type: typeof TRACK_CIO_PAGE_VIEW
}

export const trackPageView = (
  pageName: $PropertyType<ActionType, 'pageName'> = '',
  params: $PropertyType<ActionType, 'params'> = null
): ActionType => ({
  params,
  pageName,
  type: TRACK_PAGE_VIEW
})

export const trackCIOPageView = (
  pageName: $PropertyType<CIOActionType, 'pageName'> = '',
  params: $PropertyType<CIOActionType, 'params'> = null
): CIOActionType => ({
  params,
  pageName,
  type: TRACK_CIO_PAGE_VIEW
})
