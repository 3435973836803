import React, {FC, useCallback, useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {apOfferMountTypes, OfferViewContext} from 'context/offerViewContext'
import {useTrackOfferView} from 'hooks/analytics'
import {useOfferClickHandler} from 'hooks/useOfferClickHandler'
import {useShowAccommodationPage} from 'hooks/useShowAccommodationPage'
import {useSplitBookingRedirectLink} from 'hooks/useSplitBookingRedirectLink'
import {getMetaBrandHasRooms} from 'modules/accommodation/selectors'
import {getAddress} from 'modules/meta/selectors'
import {showSplitBookingDetails} from 'modules/overlay/actions'
import {getSplitBookingDetails} from 'modules/sapiSearch/selectors'
import {SearchOffer} from 'modules/sapiSearch/slice'
import {toggle} from 'opticks'
import {getUserIpFromOffer} from 'utils/getUserIpFromOffer'
import {v4 as uuidv4} from 'uuid'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {NoStyleLink} from '@daedalus/atlas/helpers/NoStyleLink'
import {trackProviderRedirect} from '@daedalus/core/src/analytics/modules/actions'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {SplitBookingBundle} from '@findhotel/sapi'

import {VIO_OFFER_CLICKED} from '../../../../constants'

interface Props {
  component: string
  hotelId: string
  hasInteraction?: boolean
}

const useHandleApNavigation = ({
  splitBookingBundle,
  offer,
  hotelId
}: {
  splitBookingBundle: SplitBookingBundle
  offer: SearchOffer
  hotelId: string
}) => {
  const metaAddress = useSelector(getAddress)
  const dispatch = useDispatch()

  const {showAccommodationPage} = useShowAccommodationPage()

  const navigateToAp = () => {
    const sourceIp = getUserIpFromOffer(offer, metaAddress)
    const redirectId = uuidv4()
    const locationParams = {
      clickedBundle: splitBookingBundle.bundleId,
      clickedBase: splitBookingBundle.totalRate.base,
      clickedTaxes: splitBookingBundle.totalRate.taxes,
      clickedFees: splitBookingBundle.totalRate.hotelFees,
      clickedProvider: offer.providerCode,
      redirectId
    }

    dispatch(
      trackProviderRedirect({
        redirectId,
        sourceIp,
        offerId: splitBookingBundle.offers[0].offer.id,
        bundleId: splitBookingBundle.bundleId,
        intermediaryProvider: offer.intermediaryProvider,
        offerUrl: splitBookingBundle.url
      })
    )

    showAccommodationPage({
      hotelId,
      locationParams,
      //analyticsContext, TODO - add this
      payload: {
        source: VIO_OFFER_CLICKED
      }
    })
  }

  return {
    navigateToAp
  }
}

export const SplitBookingOfferInteraction: FC<Props> = ({
  children,
  component,
  hotelId,
  hasInteraction = true
}) => {
  const dispatch = useDispatch()
  const offerView = useContext(OfferViewContext)
  const metaAddress = useSelector(getAddress)
  const {
    hasSplitBookingOffer,
    splitBookingType,
    splitBookingOffers,
    splitBookingOfferUrl,
    splitBookingPosition,
    splitBookingBundle
  } = useSelector(state => getSplitBookingDetails(state, hotelId))
  const brandHasRooms = useSelector(getMetaBrandHasRooms)
  const isMountedOnAp = apOfferMountTypes.includes(offerView)
  const {isMobile, isDesktopXs} = useDeviceLayout()
  const isMobileOrTablet = isMobile || isDesktopXs
  const shouldScrollToRooms = brandHasRooms && isMountedOnAp

  const {offer} = splitBookingOffers?.[0] || {}
  const {navigateToAp} = useHandleApNavigation({
    splitBookingBundle,
    offer,
    hotelId
  })
  const hasSingleFlowSBOffer =
    hasSplitBookingOffer && splitBookingType === 'single_flow'

  const splitBookingRedirectLink = useSplitBookingRedirectLink({
    splitBookingOfferUrl
  })

  const inViewRef = useTrackOfferView(offer, hotelId, component)
  const {handleClick: handleOfferClickEvents} = useOfferClickHandler({
    offerPosition: splitBookingPosition,
    isSplitBooking: true,
    component,
    hotelId,
    offer
  })

  const handleSBOfferClick = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      event.stopPropagation()

      if (hasSingleFlowSBOffer) {
        handleOfferClickEvents(event)
        const isStandaloneAPDisabled = toggle(
          '6cde7b20-bookingexp-standalone-ap-mobile-v5',
          isMobileOrTablet,
          false
        )
        // Prevents both AP navigation and scroll to rooms to happen at the same time
        if (!shouldScrollToRooms) {
          if (isStandaloneAPDisabled && brandHasRooms) {
            navigateToAp()
          } else {
            localStorage.setItem(
              'navigationToAccommodationPage',
              Date.now().toString()
            )
            setTimeout(() => {
              window.open(splitBookingRedirectLink)
            }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
          }
        }
      } else {
        dispatch(
          trackEvent({
            category: Category.User,
            action: Action.Clicked,
            entity: Entity.SplitBookingOffers
          })
        )

        dispatch(showSplitBookingDetails())
      }
    },
    [
      hasSingleFlowSBOffer,
      handleOfferClickEvents,
      isMobileOrTablet,
      navigateToAp,
      offer,
      metaAddress,
      dispatch,
      splitBookingBundle,
      splitBookingOffers,
      splitBookingRedirectLink
    ]
  )

  if (!hasInteraction) return <>{children}</>

  return (
    <NoStyleLink
      ref={hasSingleFlowSBOffer ? inViewRef : null}
      onClick={handleSBOfferClick}
    >
      {children}
    </NoStyleLink>
  )
}
