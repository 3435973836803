import React from 'react'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {defaultBrand} from '@daedalus/core/src/brand/config'
import {ProviderLogo} from '@daedalus/shared/src/search/ProviderLogo'

export const PrimaryRoomOfferLogo = () => {
  const {brand, bookOnBrandEnabled} = useBrand()
  return (
    <ContentWrapper paddingTop="s250" paddingBottom="s200">
      <ProviderLogo
        providerCode={
          bookOnBrandEnabled ? brand.providerCode : defaultBrand.providerCode
        }
        height={20}
      />
    </ContentWrapper>
  )
}
