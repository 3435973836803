import {CancellationPenalties} from '@findhotel/sapi'

import {CancellationPenalty} from '../types/offer'

export enum RefundType {
  freeCancellation,
  partialRefund,
  nonRefundable
}

/**
 * Determines if a booking has free cancellation by checking if the cancellation penalty amount is zero.
 * @param cancellationPenalty - The cancellation penalty details.
 * @returns boolean - Returns `true` if the cancellation penalty amount is zero, otherwise `false`.
 */
export const freeCancellationPenalty = (
  cancellationPenalty: CancellationPenalty | CancellationPenalties
): boolean => {
  const {amount} = cancellationPenalty
  return !Number(amount)
}

/**
 * Retrieves the latest cancellation deadline from a list of cancellation penalties.
 * @param  cancellationPenalties - An array of cancellation penalty objects.
 * @returns string | undefined - The last cancellation deadline if available, otherwise `undefined`.
 */
export const getLastCancellationDeadline = (
  cancellationPenalties: CancellationPenalty[]
) => {
  return cancellationPenalties[cancellationPenalties.length - 1]?.end
}

/**
 * Determines the relevant cancellation deadline based on the first cancellation penalty.
 *
 * - If the first penalty has a free cancellation, returns the `end` date.
 * - Otherwise, returns the `start` date.
 * @param cancellationPenalties - An array of cancellation penalty objects.
 * @returns string | undefined - The applicable cancellation deadline, or `undefined` if no penalties exist.
 */
export const getCancellationDeadline = (
  cancellationPenalties: CancellationPenalty[] | CancellationPenalties[]
): string | undefined => {
  const firstPenalty = cancellationPenalties?.[0]
  if (!firstPenalty) return

  return freeCancellationPenalty(firstPenalty)
    ? firstPenalty.end
    : firstPenalty.start
}

/**
 * Determines the refund type based on the cancellation penalties provided.
 *
 * - If there are no cancellation penalties, the booking is non-refundable.
 * - If the first penalty allows free cancellation, it is classified as free cancellation.
 * - Otherwise, it defaults to partial refund.
 * @param cancellationPenalties - An array of cancellation penalty objects.
 * @returns - The determined refund type.
 */
export const getRefundType = (cancellationPenalties: CancellationPenalty[]) => {
  if (!cancellationPenalties || cancellationPenalties.length === 0)
    return RefundType.nonRefundable

  const hasFreeCancellation = freeCancellationPenalty(
    cancellationPenalties?.[0]
  )

  if (hasFreeCancellation) return RefundType.freeCancellation

  return RefundType.partialRefund
}

/**
 * Factory function that check if the offer has free cancellation
 * @param cancellationPenalties - CancellationPenalty[]
 * @returns boolean
 */
export const getHasFreeCancellation = (
  cancellationPenalties: CancellationPenalty[]
): boolean => {
  const refundType = getRefundType(cancellationPenalties)
  return refundType === RefundType.freeCancellation
}

/**
 * Determines whether an offer is refundable based on its cancellation penalties.
 * An offer is considered refundable if it has either free cancellation or a partial refund.
 * @param cancellationPenalties - The list of cancellation penalties for the offer.
 * @returns boolean - Returns `true` if the offer is refundable, otherwise `false`.
 */
export const isOfferRefundable = (
  cancellationPenalties: CancellationPenalty[]
): boolean => {
  const refundType = getRefundType(cancellationPenalties)
  return refundType !== RefundType.nonRefundable
}

/**
 * Extracts the date and timezone information.
 *
 * - If the time component ends with 'Z', it is considered Zulu (UTC) time, and the timezone is set to '00:00'.
 * - If the time component contains a timezone offset (e.g., '+02:00' or '-05:00'), it is extracted separately.
 * - If no explicit timezone is found, it returns the original date with `undefined` timezone.
 * @param date - string.
 * @returns date: string; timeZone: string | undefined - An object containing the date without timezone and the extracted timezone.
 */
export const getDateTimeAndTimezone = (date: string) => {
  const splittedDate: readonly string[] = date.split('T')
  const time = splittedDate[1]
  const isZuluTime = time.slice(-1).toLowerCase() === 'z'

  if (isZuluTime) {
    const zuluTimeZone = time.slice(-1)
    return {
      date: date.replace(zuluTimeZone, ''),
      timeZone: '00:00'
    }
  }

  // the last 5 chars are the timezone
  const timeZone = time.slice(-6)
  // the timezone could be separated by '+' or '-'
  const timezoneSeparator = timeZone[0]
  if (['+', '-'].includes(timezoneSeparator)) {
    return {
      date: date.replace(timeZone, ''),
      timeZone
    }
  }

  return {
    date,
    timeZone: undefined
  }
}

/**
 * Formats a timezone string for better readability.
 *
 * - If the timezone is empty or '00:00' (UTC), it returns an empty string.
 * - If the timezone has a zero-minute offset (e.g., '+08:00'), it simplifies it to just the hour part (e.g., '+8').
 * - If the hour part has a leading zero (e.g., '+03:00'), it removes the leading zero (e.g., '+3').
 * - Otherwise, it returns the original formatted timezone.
 * @param timezone - A timezone string in the format '+HH:MM' or '-HH:MM'.
 * @returns string - A more readable timezone format.
 */
export const formatTimeZone = (timezone?: string) => {
  // timezone string example: +08:00
  if (!timezone || timezone === '00:00') return ''
  const operator = timezone[0]
  const timezoneNoOperator = timezone.slice(1)
  const hoursAndMinutes = timezoneNoOperator.split(':')
  // If the minutes part of the timezone is zero, remove that part for easier reading
  if (hoursAndMinutes[1] === '00') {
    if (hoursAndMinutes[0].startsWith('0'))
      return `${operator}${hoursAndMinutes[0][1]}`
    return `${operator}${hoursAndMinutes[0]}`
  }

  if (hoursAndMinutes[0].startsWith('0')) {
    return `${operator}${hoursAndMinutes[0][1]}:${hoursAndMinutes[1]}`
  }

  return `${operator}${timezoneNoOperator}`
}
