import addMinutes from 'date-fns/addMinutes'
import isPast from 'date-fns/isPast'
import isValid from 'date-fns/isValid'

export const isCookieAcceptedMoreThanFiveMinutesAgo = (
  date: string | undefined
) =>
  date
    ? isValid(new Date(date)) && isPast(addMinutes(new Date(date), 5))
    : false
