import {RoomCardVariants} from '../types'

interface GetRoomVariantParams {
  isCheapest: boolean
  isSelected: boolean
  isSplitBooking?: boolean
  isSbAvailable?: boolean
  forceDefault?: boolean
}

export const getRoomVariant = ({
  isCheapest,
  isSelected,
  isSplitBooking = false,
  forceDefault = false
}: GetRoomVariantParams): RoomCardVariants => {
  if (forceDefault) return RoomCardVariants.Default

  if (isSelected) {
    return RoomCardVariants.SelectedDeal
  }
  if (isCheapest) {
    if (isSplitBooking) return RoomCardVariants.SplitBookingCheapest
    else return RoomCardVariants.BestDeal
  }

  return RoomCardVariants.Default
}
