import {FilterUrlParam} from 'modules/search/types'
import {isNil, path} from 'ramda'

import {Lov} from '@daedalus/core/src/analytics/types/Events'

type ListOfValuesType = Lov | null | undefined

/**
 * Toggle filter
 */
export const toggleFilter = (
  searchParam: string,
  value: string | null | undefined
): string | null | undefined => {
  return searchParam !== value ? value : undefined
}

/**
 * Toggle multi filter (Array)
 */
export const toggleMultiFilter = (
  searchParam: string | number | string[] | number[],
  value: string | null | undefined
) => {
  // TODO: TS - missing type annotations for S and T
  const searchParamToUpdate = searchParam ? [].concat(searchParam) : []
  const index = searchParamToUpdate.indexOf(value)

  if (index === -1) {
    searchParamToUpdate.push(value)
  } else {
    searchParamToUpdate.splice(index, 1)
  }

  return searchParamToUpdate
}

/**
 * Force set multi filter (Array)
 */
export const forceSetMultiFilters = (
  searchParam: string | number | (string | number)[],
  values: (string | number)[]
): (string | number)[] => {
  if (Array.isArray(searchParam)) {
    const appliedParams = new Set<number | string>(searchParam)
    values.forEach(value => appliedParams.add(value))
    return [...appliedParams].filter(i => !isNil(i))
  } else {
    return [searchParam, ...values]
  }
}

/**
 * Force remove multi filter (Array)
 */
export const forceRemoveMultiFilters = (
  searchParam: string | number | (string | number)[],
  values: (string | number)[]
): (string | number)[] => {
  if (Array.isArray(searchParam)) {
    const appliedParams = new Set<number | string>(searchParam)
    values.forEach(element => appliedParams.delete(element))
    return [...appliedParams].filter(i => !isNil(i))
  } else {
    const singleParams = new Set<number | string>([searchParam])
    values.forEach(element => singleParams.delete(element))
    return [...singleParams].filter(i => !isNil(i))
  }
}

export const getFacetLabel =
  (type: string, listOfValues: ListOfValuesType) =>
  (facetId: string): string =>
    path([`${type}:${facetId}`, 'value'], listOfValues)

/**
 * Factory function that checks whether the facet is selected or not
 */

export const isFacetSelected = (
  filters: FilterUrlParam,
  facetId: string
): boolean => {
  if (!filters) return false
  if (Array.isArray(filters)) return filters.includes(facetId)
  return filters.toString() === facetId
}
