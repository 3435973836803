import {cookieSecuritySettings} from '../../_web/utils/cookies/cookieSecuritySettings'

const maxAge = 1 / 24 // 1 hour

export const geolocationConfig = {
  name: 'geolocation',
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}

export const geolocationSourceConfig = {
  name: 'geolocationSource',
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}
