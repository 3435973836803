import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {DateGuestOverlayContainer} from 'components/Accommodation/DateGuestInput'
import {FacilitiesBottomSheet} from 'components/Accommodation/Facilities/FacilitiesBottomSheet'
import {GuestReviewsOverlayPage} from 'components/Accommodation/GuestReviewsOverlay'
import {LightboxGalleryWithOffer} from 'components/Accommodation/LightboxGalleryWithOffer'
import {LocationOverlayMobile} from 'components/Accommodation/LocationOverlay'
import {RoomsDetailOverlayContainer} from 'components/Accommodation/RoomsDetailOverlayContainer'
import {StreetViewOverlay} from 'components/Accommodation/StreetViewOverlay'
import {OfferViewContext, OfferViewType} from 'context/offerViewContext'
import {getHotelDetailsOverlayInfo} from 'modules/overlay/selectors'
import {getHotel} from 'modules/sapiSearch/selectors'
import {getListOfValues} from 'modules/search/selectors'
import {getIsStreetViewEnabled} from 'utils/map'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

import {mergeListOfValues} from './utils'

interface Props {
  isAccommodationPageOpen: boolean
}
export const AccommodationOverlays: React.FC<Props> = ({
  isAccommodationPageOpen
}) => {
  const {isMobile} = useDeviceLayout()

  const {hotelId} = useSelector(getHotelDetailsOverlayInfo)
  const hotel = useSelector(state => getHotel(state, hotelId))
  const listOfValues = useSelector(getListOfValues)

  const isStreetViewEnabled = getIsStreetViewEnabled(hotel?.country)

  const contentHotel = useMemo(
    () => (hotel ? mergeListOfValues(hotel, listOfValues) : undefined),
    [hotel, listOfValues]
  )

  return (
    <OfferViewContext.Provider value={OfferViewType.AP_DETAILS_OVERLAY}>
      <LightboxGalleryWithOffer component="MobileHotelViewGalleryStaticOfferCTA" />
      <FacilitiesBottomSheet hotel={contentHotel} />
      <LocationOverlayMobile hotel={contentHotel} />
      {isStreetViewEnabled && isMobile && <StreetViewOverlay />}
      <DateGuestOverlayContainer />
      {isAccommodationPageOpen && (
        <>
          <RoomsDetailOverlayContainer />
          <GuestReviewsOverlayPage hotel={contentHotel} />
        </>
      )}
    </OfferViewContext.Provider>
  )
}
