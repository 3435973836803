import React from 'react'
import {useSelector} from 'react-redux'
import {getHotelDetailsVisibility} from 'modules/overlay/selectors'

import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {TeamContextProvider} from '@daedalus/core/src/analytics/components/TeamContext'
import {Page, Team} from '@daedalus/core/src/analytics/types/Events'

import {AccommodationOverlayContent} from './AccommodationOverlayContent'
import {AccommodationOverlays} from './AccommodationOverlays'

const HotelDetailsOverlay = () => {
  const isOpen = useSelector(getHotelDetailsVisibility)

  return (
    <TeamContextProvider team={Team.Select}>
      {isOpen && <InjectPageDataId page={Page.AccommodationOverlay} />}
      <Overlay
        animationType={ANIMATION_TYPES.NONE}
        enableContentBackgroundHack
        isOpen={isOpen}
        shadeBackground={false}
      >
        <AccommodationOverlayContent />
      </Overlay>
      {/* These have to be mounted alongside the hotel overlay to prevent overlay-order issues on page refresh */}
      <AccommodationOverlays isAccommodationPageOpen={isOpen} />
    </TeamContextProvider>
  )
}

export default HotelDetailsOverlay
