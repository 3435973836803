import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import {DesktopWeb2AppCta} from 'components/HotelCardDesktop/TopOfferInfo/DesktopWeb2AppCta'
import {HotelCardUnavailability} from 'components/HotelCardUnavailability'
import {PrimaryOffer} from 'components/Offer/Primary'
import {useOfferContainer} from 'hooks/useOfferContainer'
import {
  getAnchorComplete,
  getAppLockedDealConfigDesktop,
  getHotel,
  getHotelHasOffers,
  getOfferEntity,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {OfferPromoKey} from '@daedalus/core/src/offer/types/offer'

import {TopOfferInfoSkeleton} from './Skeleton'

export const TopOffer: React.FC = () => {
  const {hotelId, isAnchor} = useContext(HotelCardContext)
  const hotelHasOffers = useSelector(state => getHotelHasOffers(state, hotelId))

  const anchorComplete = useSelector(getAnchorComplete)
  const hotel = useSelector(state => getHotel(state, hotelId))
  const offerEntity = useSelector(state => getOfferEntity(state, hotelId))
  const isAppLockedHotelDesktop = useSelector(state =>
    getAppLockedDealConfigDesktop(state, hotelId)
  )

  const {anchorPrice, anchorPriceTotal, primaryOffer, offerVariant} =
    useOfferContainer({
      hotelId,
      offerEntity
    })

  const {
    hasSplitBookingOffer,
    splitBookingType,
    splitBookingPosition,
    showSplitBookingLoader,
    splitBookingOffers,
    splitBookingBundle
  } = useSelector(state => getSplitBookingDetails(state, hotelId))

  const hasSingleFlowSBOffer =
    hasSplitBookingOffer &&
    splitBookingType === 'single_flow' &&
    splitBookingPosition === 0

  const isOffersUnavailable = anchorComplete && !hotelHasOffers && isAnchor

  if (isOffersUnavailable || hasSingleFlowSBOffer) {
    if (showSplitBookingLoader) return <TopOfferInfoSkeleton />

    if (hasSplitBookingOffer)
      return (
        <PrimaryOffer
          isSplitBooking
          anchorPrice={anchorPrice}
          anchorPriceTotal={anchorPriceTotal}
          splitBookingType={splitBookingType}
          splitBookingOffers={splitBookingOffers}
          splitBookingBundle={splitBookingBundle}
          shouldShowTaxationAndNightly
          variant="PrimaryDefault"
          hotelId={hotelId}
          showPriceIndication
          sourceComponent="DesktopTopOffers"
        />
      )
    // Showing Unavailability
    return (
      <HotelCardUnavailability sourceComponent="HotelCardDesktopAvailabilityPane" />
    )
  }

  // Showing loading Skeleton
  if (!hotel || !hotelHasOffers) return <TopOfferInfoSkeleton />

  const web2appPromo = offerEntity.promos?.[OfferPromoKey.Web2App]

  return (
    <Box display="flex" flexDirection="column" justifyContent="stretch">
      <Box
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent="stretch"
      >
        <PrimaryOffer
          hasWeb2AppPromo={isAppLockedHotelDesktop}
          anchorPrice={anchorPrice}
          anchorPriceTotal={anchorPriceTotal}
          hotelId={hotelId}
          offer={primaryOffer}
          offerVariant={offerVariant}
          variant="PrimaryDefault"
          showPriceIndication
          shouldShowTaxationAndNightly
          hasInteraction
          sourceComponent="DesktopTopOffers"
        />
      </Box>
      {isAppLockedHotelDesktop && <DesktopWeb2AppCta offer={web2appPromo} />}
    </Box>
  )
}
