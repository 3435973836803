import {useCallback, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setCookiesVclid} from 'modules/cookies/slice'

import {setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {safelySetStorageItem} from '@daedalus/core/src/_web/utils/persistence'
import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'
import {
  getPersistedVclidValue,
  vclidCookieConfig
} from '@daedalus/core/src/analytics/utils/vclidCookie'
import {removeURLParameter} from '@daedalus/core/src/utils/url'

export const CaptureVioClickID = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    // parse the vclid
    const vclid = getVclidValue() || ''

    if (vclid) {
      // store vclid in cookie & session & redux store
      storeVclid(vclid).cookie().session().redux()
      removeVclidFromUrl()
    }
  }, [])

  const getVclidValue = () => {
    return (
      // return value from query param
      // or session storage or cookie storage
      getVclidFromQueryParam() || getPersistedVclidValue()
    )
  }

  const storeVclid = useCallback(
    value => {
      return {
        cookie() {
          const {secure, sameSite, maxAge: expires} = vclidCookieConfig.options
          setCookie(vclidCookieConfig.name, value, {
            expires,
            secure,
            sameSite
          })

          return this
        },
        session() {
          safelySetStorageItem(
            sessionStorage,
            vclidCookieConfig.name,
            value,
            'vclid failed'
          )
          return this
        },
        redux() {
          dispatch(setCookiesVclid(value))
          return this
        }
      }
    },
    [dispatch]
  )

  const getVclidFromQueryParam = () => {
    return getQueryStringObject()?.vclid || ''
  }

  const removeVclidFromUrl = () => {
    const newUrl = removeURLParameter(location.href, 'vclid')
    history.replaceState(null, '', newUrl)
  }

  return null
}
