import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

/**
 * Fire a tracking event once as soon as the page is scrolled
 */
export const useTrackPageScroll = () => {
  const [eventFired, setEventFired] = useState(false)
  const dispatch = useDispatch()

  const handleScroll = () => {
    if (!eventFired) {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.Page,
          action: Action.Scrolled
        })
      )
      setEventFired(true) // We only want to fire this event once
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])
}
