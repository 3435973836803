import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'

export const useOfferHasTag = (offerVariant: OfferVariant) => {
  switch (offerVariant) {
    case OfferVariant.BestDeal:
    case OfferVariant.MemberDeal:
    case OfferVariant.MemberPlusDeal:
    case OfferVariant.Popular:
    case OfferVariant.OnlyOnVio:
      return true

    default:
      return false
  }
}
