import React from 'react'
import {AnimatePresence, motion} from 'framer-motion'

import {Divider} from '@daedalus/atlas/Divider'

const variants = {
  hidden: {opacity: 0, scale: 0.95, transitionEnd: {display: 'none'}},
  visible: {opacity: 1, scale: 1, display: 'block'}
}

interface AnimatedDividerProps {
  inView: boolean
}

export const AnimatedDivider = ({inView}: AnimatedDividerProps) => {
  return (
    <AnimatePresence>
      {!inView && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          transition={{duration: 0.5}}
        >
          <Divider />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
