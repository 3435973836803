import React from 'react'
import {useSelector} from 'react-redux'
import {cx} from '@linaria/core'
import CookieBanner from 'components/CookieBanner'
import {Footer} from 'components/Footer'
import {MobileOverlays} from 'components/MobileOverlays/MobileOverlays'
import {getUserAgentDetails} from 'modules/meta/selectors'

import OpacityAnimation from '@daedalus/atlas/helpers/OpacityAnimation'

import {MobileHeader} from '../MobileHeader'
import {
  FooterWrapper,
  HeaderSpacer,
  HeaderWrapper,
  LayoutWrapper,
  MainContentWrapper
} from './mobileStyles'

type PropsType = {
  hasHeaderSearchSummary?: boolean
  hasHeaderShadow?: boolean
  hasHeaderDivider?: boolean
  hasSearchForm?: boolean
  hasHeader?: boolean
  hasHeaderMenu?: boolean
  hasFooter?: boolean
  headerRightContent?: React.ReactElement
  hasBackButton?: boolean
  headerTitle?: React.ReactElement
  hasSearchProgressBar?: boolean
}

export const MobileBaseLayout: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  hasHeaderSearchSummary = true,
  hasHeaderShadow = false,
  hasHeaderDivider = false,
  hasSearchForm = false,
  hasHeader = true,
  hasHeaderMenu = true,
  hasFooter = true,
  hasSearchProgressBar = false,
  headerRightContent,
  hasBackButton,
  headerTitle
}) => {
  const {isSafari} = useSelector(getUserAgentDetails)
  const mobileHeaderIsFixed = hasSearchForm

  return (
    <>
      <LayoutWrapper>
        <CookieBanner />
        {hasHeader && (
          <HeaderWrapper className={cx(mobileHeaderIsFixed && '__isFixed')}>
            <MobileHeader
              hasShadow={hasHeaderShadow}
              hasDivider={hasHeaderDivider}
              hasHeaderSearchSummary={hasHeaderSearchSummary}
              hasHeaderBottomContent={hasSearchForm}
              hasHeaderMenu={hasHeaderMenu}
              hasSearchProgressBar={hasSearchProgressBar}
              rightContent={headerRightContent}
              hasBackButton={hasBackButton}
              headerTitle={headerTitle}
            />
          </HeaderWrapper>
        )}
        <HeaderSpacer mobileHeaderIsFixed={mobileHeaderIsFixed} />
        <MainContentWrapper>{children}</MainContentWrapper>
        {hasFooter && (
          <FooterWrapper isSafari={isSafari}>
            <OpacityAnimation delay={500}>
              <Footer />
            </OpacityAnimation>
          </FooterWrapper>
        )}
      </LayoutWrapper>
      <MobileOverlays />
    </>
  )
}
