import {createAction} from '@reduxjs/toolkit'

import {AuthenticationMethod, SourceComponentType} from '../types/Auth'
import {User} from '../types/Cognito'

/**
 * A timeout that gives the analytics systems to send the request before the application
 * sends the user away.
 */
export const TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY = 50

/**
 * Fires an action to tracks that the user clicked on authentication buttons inside the overlay
 */
export const trackUserAuthClick = createAction(
  'authentication/trackUserAuthClick',
  ({
    sourceComponent,
    authenticationMethod,
    analyticsData
  }: {
    sourceComponent: string
    authenticationMethod: AuthenticationMethod
    analyticsData?: Record<string, unknown>
  }) => ({
    payload: {
      sourceComponent,
      authenticationMethod,
      analyticsData
    }
  })
)

/**
 * Fires an action to tracks places where user open the auth overlay with a SignUp button
 */
export const trackSignUpClick = createAction<SourceComponentType>(
  'authentication/trackSignUpClick'
)

/**
 * Fires an action to tracks places where user open the auth overlay with a Login button
 */
export const trackLoginClick = createAction<SourceComponentType>(
  'authentication/trackLoginClick'
)

/**
 * Fires an action that tells the application that the user information is ready
 * for identification on our systems
 * @param user - The user {@link User}
 */
export const identifyAuthenticatedUser = createAction<User>(
  'authentication/identifyAuthenticatedUser'
)
