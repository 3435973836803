import {TRACK_PAGE_VIEW} from 'middleware/analytics/actions/trackPageView'
import handleFatalError from 'modules/common/actions/errors'
import {getAnonymousId} from 'modules/meta/selectors'
import {showPopupOnExit} from 'modules/popupOnExit/slice'
import {syncStoreWithUrl} from 'modules/search/slice'
import {UTM_SOURCE_DEFAULT} from 'utils/trafficSource'

import {FullStory} from '@daedalus/core/src/analytics/types/FullStory'

import {MiddlewareType} from '..'

declare let FS: FullStory

const getFullStoryObject = () => {
  return typeof FS === 'undefined' ? undefined : FS
}

const middleware: MiddlewareType = store => next => action => {
  const state = store.getState()

  // eslint-disable-next-line default-case
  switch (action.type) {
    case TRACK_PAGE_VIEW: {
      getFullStoryObject()?.identify(getAnonymousId(state), {
        pageName: action.pageName
      })
      break
    }

    case handleFatalError.type: {
      getFullStoryObject()?.setUserVars({sawFatalError_bool: true})
      break
    }

    case showPopupOnExit.type: {
      getFullStoryObject()?.setUserVars({popupOnExitVisible_bool: true})
      break
    }

    case syncStoreWithUrl.type: {
      const isUserSearch = action.payload.urlParams?.userSearch === '1'
      const isTrafficSourceUnknown =
        state.meta.trafficSource === UTM_SOURCE_DEFAULT

      if (isUserSearch && isTrafficSourceUnknown) {
        getFullStoryObject()?.setUserVars({directTrafficSearching_bool: true})
      }

      break
    }
  }

  return next(action)
}

export default middleware
