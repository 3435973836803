import {TabId} from 'components/HotelDetailsTabs'
import {Hotel} from 'modules/sapiSearch/slice'
import {setOpenDatePickerType} from 'modules/searchBox/slice'
import {AppThunk} from 'store'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {Placement} from '@daedalus/core/src/deepLinking/types'
import {Room} from '@daedalus/core/src/room/types/room'

import {setOverlayState, showOverlay} from './slice'
import {DateGuestPickerSource, OverlayType} from './types'

type LightboxGalleryParams = {
  initialImageIndex: number
  hotelId: Hotel['objectID']
  images: Hotel['imageURIs']
  team?: Team
}

interface ShowPreferencesType {
  isInviteFriendsTab: boolean
}

/**
 * Date picker Overlay for the SRP
 */
export const showDatePicker =
  (type: DatePickerType, team: Team = Team.Default): AppThunk =>
  dispatch => {
    dispatch(setOpenDatePickerType(type))
    dispatch(
      showOverlay({
        type: OverlayType.DatePicker,
        entity: Entity.DatePicker,
        component: type,
        team
      })
    )
  }

/**
 * Date picker for the Hotel page has a different type to allow for different behaviour
 */
export const showHotelDatePicker =
  (
    type: DatePickerType,
    team: Team = Team.Default,
    source: DateGuestPickerSource
  ): AppThunk =>
  dispatch => {
    dispatch(setOpenDatePickerType(type))
    dispatch(
      showOverlay({
        type: OverlayType.HotelDatePicker,
        entity: Entity.DatePicker,
        component: type,
        team,
        payload: {source},
        params: {
          source
        }
      })
    )
  }

/**
 * Guest picker for the SRP
 */
export const showGuestPicker = (team: Team = Team.Default) =>
  showOverlay({
    type: OverlayType.GuestPicker,
    entity: Entity.GuestPicker,
    team
  })

/**
 * Guest picker for the Hotel page has a different type to allow for different behaviour
 */
export const showHotelGuestPicker = (
  team: Team = Team.Default,
  source: DateGuestPickerSource
) =>
  showOverlay({
    type: OverlayType.HotelGuestPicker,
    entity: Entity.GuestPicker,
    team,
    payload: {source},
    params: {
      source
    }
  })

/**
 * Destination picker
 */
export const showDestinationPicker = () =>
  showOverlay({
    type: OverlayType.DestinationPicker,
    entity: Entity.DestinationPicker
  })

/**
 * Lightbox gallery
 */
export const showLightboxGallery =
  ({
    initialImageIndex,
    hotelId,
    images,
    team
  }: LightboxGalleryParams): AppThunk =>
  dispatch => {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.FullScreenImageGallery,
        action: Action.Displayed,
        team
      })
    )
    dispatch(
      showOverlay({
        type: OverlayType.LightboxGalleryOverlay,
        params: {
          initialImageIndex,
          hotelId,
          images
        },
        team
      })
    )
  }

/**
 * Filters
 */
export const showFilters = () =>
  showOverlay({type: OverlayType.Filters, entity: Entity.FilterOptions})

/**
 * Hotel Details
 */
export const setHotelDetailsOverlayState = (
  hotelId: string,
  tabId: TabId,
  imageIndex: number
) => {
  return setOverlayState([
    {
      type: OverlayType.HotelDetails,
      hotelId,
      activeTab: tabId,
      initialImageIndex: imageIndex
    }
  ])
}

/**
 * Hotel Facilities
 */
export const showHotelFacilities = ({hotelId}: {hotelId: string}) =>
  showOverlay({
    type: OverlayType.HotelFacilities,
    params: {
      hotelId
    }
  })

/**
 * Location
 */
export const showLocationOverlay = (): AppThunk => dispatch => {
  dispatch(
    showOverlay({
      type: OverlayType.LocationOverlay
    })
  )
}

/**
 * Preferences
 */
export const showPreferences = ({isInviteFriendsTab}: ShowPreferencesType) =>
  showOverlay({
    type: OverlayType.Preferences,
    entity: Entity.PreferencesOverlay,
    team: Team.Retention,
    payload: {
      isInviteFriendsTab
    }
  })

/**
 * SortBy
 */
export const showSortBy = () =>
  showOverlay({type: OverlayType.SortBy, entity: Entity.SortByOverlay})

/**
 * Price Filter
 */
export const showPriceFilter = () =>
  showOverlay({
    type: OverlayType.PriceFilter,
    entity: Entity.PriceFilterOverlay
  })

/**
 * Property Type Filter
 */

export const showPropertyTypeFilter = () =>
  showOverlay({
    type: OverlayType.PropertyTypeFilter,
    entity: Entity.PropertyTypeFilterOverlay
  })

/**
 * Review Score Filter
 */
export const showReviewScoreFilter = () =>
  showOverlay({
    type: OverlayType.ReviewScoreFilter,
    entity: Entity.ReviewScoreFilterOverlay
  })

/**
 * Facilities Filter
 */

export const showFacilitiesFilter = () =>
  showOverlay({
    type: OverlayType.FacilitiesFilter,
    entity: Entity.FacilitiesFilterOverlay
  })

/**
 * VerticalGalleryOverlay
 */
export const showVerticalGalleryOverlay = () =>
  showOverlay({
    type: OverlayType.VerticalGalleryOverlay,
    team: Team.Select,
    entity: Entity.ImageGallery
  })

/**
 * Split Booking
 */
export const showSplitBookingDetails = () =>
  showOverlay({
    type: OverlayType.SplitBookingDetails
  })

/**
 * Split Booking Info
 */
export const showSplitBookingInfoBottomSheet = () =>
  showOverlay({
    type: OverlayType.SplitBookingInfoBottomSheet
  })

/**
 * Room details overlay
 */
export const showRoomDetailsOverlay = ({
  roomId,
  hotelId
}: {
  roomId: Room['id']
  hotelId: Hotel['objectID']
}) =>
  showOverlay({
    type: OverlayType.RoomDetailsOverlay,
    params: {roomId, hotelId}
  })

/**
 * Desktop availability popup
 */

export const showAvailabilityPopup = dispatch => {
  dispatch(
    showOverlay({
      type: OverlayType.AvailabilityPopup
    })
  )
}

/**
 * Web2App Popup
 */
export const showWeb2AppPopupOverlay = ({
  hotelId,
  placement
}: {
  hotelId?: string
  placement: Placement
}) =>
  showOverlay({
    type: OverlayType.Web2AppPopup,
    params: {
      hotelId,
      placement
    }
  })

/**
 * Guest Reviews
 */
export const showGuestReviewsOverlay = () =>
  showOverlay({
    type: OverlayType.GuestReviews
  })

/**
 * Star Ratings Filter
 */
export const showStarRatingsFilter = () =>
  showOverlay({
    type: OverlayType.StarRatingsFilter,
    entity: Entity.StarRatingsFilterOverlay
  })
