import {GeoLocationType} from '../../place/types'

const DEGREES_180 = 180
const MINUTES_IN_DEGREE = 60
const MILES_IN_NAUTICAL_MILE = 1.1515

const calculateRadian = (value: number): number => {
  return (Math.PI * value) / DEGREES_180
}

/**
 * Calculate the distance between two geolocation points in miles.
 * @param source - first geolocation
 * @param target - second geolocation
 * @returns number - distance in miles, use convertMilesToKm to get kilometers
 */
export const calculateDistance = (
  source: Partial<GeoLocationType>,
  target: Partial<GeoLocationType>
) => {
  if (!source.lat || !target.lat || !source.lon || !target.lon) return 0
  if (source.lat === target.lat && source.lon === target.lon) return 0

  const sourceLatRadian = calculateRadian(source.lat)
  const targetLatRadian = calculateRadian(target.lat)

  const theta = source.lon - target.lon
  const thetaRadian = calculateRadian(theta)

  let dist =
    Math.sin(sourceLatRadian) * Math.sin(targetLatRadian) +
    Math.cos(sourceLatRadian) *
      Math.cos(targetLatRadian) *
      Math.cos(thetaRadian)

  if (dist > 1) {
    dist = 1
  }

  dist = Math.acos(dist)
  dist = (dist * DEGREES_180) / Math.PI
  dist = dist * MINUTES_IN_DEGREE * MILES_IN_NAUTICAL_MILE

  return dist
}
