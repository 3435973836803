import {useDebugValue} from 'react'
import {useSelector} from 'react-redux'
import {getAllOffersOverlay} from 'modules/overlay/selectors'
import {
  getAnchorComplete,
  getAnchorHotelId,
  getIsSearchCompleted,
  getModuleState,
  getOfferEntity,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {HotelOfferEntity, ModuleState} from 'modules/sapiSearch/slice'
import {getTopOffersParameters} from 'modules/searchApi/selectors'
import {RootState} from 'store'

import {selectIsLoading} from '@daedalus/core/src/auth/modules/selectors'
import {
  GetOffersParams,
  OffersState,
  useGetOffersQuery
} from '@daedalus/core/src/sapi/services/searchApi'
import {SearchParameters} from '@daedalus/core/src/sapi/types'

interface ReturnValue {
  offerEntity?: HotelOfferEntity
  isComplete: boolean
  searchedParameters: GetOffersParams | SearchParameters
}

/**
 * Provide offers for the AP Offers component
 * - Reuses SRP data when params are unchanged
 * - Fetches new offers when params are changed on AP
 * - @bookingCoreExperience [sc-132198] Remove completely and rely only on offers result if
 *  6cde7b20-bookingexp-standalone-ap-mobile-v5 wins
 */
export const useCachedSearchTopOffers = ({hotelId}): ReturnValue => {
  const isAnchor = hotelId === useSelector(getAnchorHotelId)
  const isAnchorComplete = useSelector(getAnchorComplete)
  const isSapiSearchCompleted =
    useSelector(getIsSearchCompleted) || (isAnchor && isAnchorComplete)
  const isAuthLoading = useSelector(selectIsLoading)

  const searchParameters = useSelector((state: RootState) =>
    getTopOffersParameters(state, hotelId)
  )

  // Skip fetching when overlay is open to A) avoid SAPI state conflicts and B) improve performance for the overlay
  const isAllOffersOverlayOpen = !!useSelector(getAllOffersOverlay)

  // If sapi search is not available we get data from core/searchApi then
  const isSapiSearchActive = useSelector(getModuleState) !== ModuleState.Idle
  const {data} = useGetOffersQuery(searchParameters, {
    skip:
      !hotelId ||
      isSapiSearchActive ||
      isAllOffersOverlayOpen ||
      isAuthLoading ||
      !!process.env.STORYBOOK // We cannot intercept websockets with MSW (yet)
  })

  const sapiOfferEntity = useSelector((state: RootState) =>
    getOfferEntity(state, hotelId)
  )

  const sapiSearchParameters = useSelector(getSearchParameters)

  const value: ReturnValue = isSapiSearchActive
    ? {
        offerEntity: sapiOfferEntity,
        isComplete: isSapiSearchCompleted,
        searchedParameters: sapiSearchParameters
      }
    : {
        offerEntity: data?.offerEntity,
        isComplete: data?.status === OffersState.Completed,
        searchedParameters: searchParameters
      }

  useDebugValue(value)

  return value
}
