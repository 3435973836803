import * as React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {FacetFilterPopover} from 'components/FiltersPopovers/FacetFilterPopover/FacetFilterPopover'
import {getFacets} from 'modules/sapiSearch/selectors'
import {searchParamToFacet} from 'modules/search/constants'
import {getListOfValues} from 'modules/search/selectors'

import {FacetGroupItem, RenderType} from '../../Filters/FacetFilter'

const POPULAR_THEMES = new Set([
  '1', // Beach / Coast
  '2', // City
  '9', // Family
  '14', // Spa / Relaxation
  '10', // Romantic
  '8', // Luxury
  '19', // Skiing / Winter sports
  '3', // Budget / Backpacker
  '7' // Food / Dining
])

export const THEMES_GROUPS: FacetGroupItem[] = [
  {
    type: 'popular',
    facetIds: POPULAR_THEMES
  }
]

interface Props {
  isActive?: boolean
}

export const ThemesFilterPopover: React.FC<Props> = ({isActive = false}) => {
  const {formatMessage} = useIntl()
  const facets = useSelector(getFacets) ?? {}
  const listOfValues = useSelector(getListOfValues)

  return (
    <FacetFilterPopover
      isActive={isActive}
      dataId="themesFilter"
      labelMessage={formatMessage(filterMessages.themes)}
      facetLabel="Theme"
      listOfValues={listOfValues}
      facets={facets[searchParamToFacet.themes.facet]}
      renderType={RenderType.List}
      facetGroups={THEMES_GROUPS}
      facetKey={searchParamToFacet.themes.param}
    />
  )
}
