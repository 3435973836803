import React from 'react'
import {OfferBox} from 'components/OfferBox/OfferBox'

import {Box} from '@daedalus/atlas/helpers/Box'

import {GalleryLoading} from './Gallery/GalleryLoading'
import {HotelCardLayout} from './HotelCardLayout/HotelCardLayout'
import {HOTEL_CARD_MAX_WIDTH} from './HotelCardMobile'
import {PropertySummaryLoading} from './PropertySummary/PropertySummaryLoading'

export const HotelCardMobileLoading = () => {
  return (
    <Box
      maxWidth={`${HOTEL_CARD_MAX_WIDTH}px`}
      margin="0 auto"
      paddingBottom="s500"
    >
      <HotelCardLayout
        isTopOfferMemberDeal={false}
        onClick={() => ({})}
        gallerySlot={<GalleryLoading />}
        propertySummarySlot={<PropertySummaryLoading />}
        basicPropertyDetailsSlot={null}
        filtersMatchSlot={null}
        watchButtonSlot={null}
        offersSlot={
          <OfferBox
            isLoading
            withHotelCard
            primaryOfferVariant="PrimaryMultiColumn"
          />
        }
      />
    </Box>
  )
}
