import React from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {MobileOverlayLayout} from 'components/Layouts/MobileOverlayLayout'
import {showPreferences} from 'modules/overlay/actions'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getUrlParams} from '@daedalus/core/src/_web/utils/url'

interface UserLayoutProps {
  children: React.ReactNode
  headerContent: React.ReactNode
  endContent?: JSX.Element
}

export const UserLayout = ({
  children,
  headerContent,
  endContent
}: UserLayoutProps) => {
  const {isMobile} = useDeviceLayout()
  const dispatch = useDispatch()
  const params = getUrlParams()
  const history = useHistory()
  const shouldHideBackButton = params?.isBottomTabNavigation === '1'

  const backButtonClick = () => {
    const directLink = history.length === 1
    // If the user came from a direct link, we redirect to the home page and don't show the preferences
    !directLink && dispatch(showPreferences({isInviteFriendsTab: false}))
    // Change the route after the exit animation completes
    setTimeout(() => (directLink ? history.replace('/') : history.go(-2)), 300)
  }

  if (isMobile) {
    return (
      <MobileOverlayLayout
        headerContent={headerContent}
        endContent={endContent}
        onBackButtonClick={!shouldHideBackButton && backButtonClick}
      >
        {children}
      </MobileOverlayLayout>
    )
  }
  return <>{children}</>
}
