import {isRejectedWithValue} from '@reduxjs/toolkit'
import {setTaxConfigDangerously} from 'modules/meta/slice'
import {
  anchorReceived,
  hotelsReceived,
  HotelsReceivedPayload
} from 'modules/sapiSearch/slice'
import {setSearchBoxValues} from 'modules/searchBox/slice'
import {toggle} from 'opticks'

import {
  logTimeEnd,
  logTimeStart
} from '@daedalus/core/src/_web/utils/logging/performanceTimer'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {getTaxDisplayLogic} from '@daedalus/core/src/localization/business/countries'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'
import {offersSearchStarted} from '@daedalus/core/src/sapi/services/searchApi/action'

import {MiddlewareType} from '..'

const middleware: MiddlewareType = store => next => async action => {
  const {dispatch, getState} = store

  // Sync default dates from SAPI back to search box
  if (offersSearchStarted.match(action)) {
    const {checkIn, checkOut, rooms} = action.payload
    dispatch(
      setSearchBoxValues({
        checkIn,
        checkOut,
        rooms
      })
    )
  }

  if (searchApi.endpoints.getUserReviews.matchPending(action)) {
    logTimeStart(Entity.ReviewsFetch)
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ReviewsFetch,
        action: Action.Requested,
        payload: {
          hotelId: action.meta.arg.originalArgs.hotelId
        }
      })
    )
    return next(action)
  }

  if (searchApi.endpoints.getUserReviews.matchFulfilled(action)) {
    const requestHotelId = action.meta.arg.originalArgs.hotelId
    const timingResult = logTimeEnd(Entity.ReviewsFetch)

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ReviewsFetch,
        action: Action.Succeeded,
        payload: {
          hotelId: requestHotelId,
          reviewsCount: action.payload.reviews.length,
          timing: timingResult
        }
      })
    )
    return next(action)
  }

  if (
    searchApi.endpoints.getUserReviews.matchRejected(action) &&
    isRejectedWithValue(action)
  ) {
    const timingResult = logTimeEnd(Entity.ReviewsFetch)
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ReviewsFetch,
        action: Action.Errored,
        payload: {
          error: action.payload,
          timing: timingResult
        }
      })
    )
    return next(action)
  }

  // Added as part of 30288e4c-include-taxes-in-california experiment
  if (anchorReceived.match(action) || hotelsReceived.match(action)) {
    const {hotelEntities, offset} = action.payload as Pick<
      HotelsReceivedPayload,
      'offset' | 'hotelEntities'
    >

    const {meta} = getState()

    const hasPropertyInCalifornia =
      Object.values(hotelEntities).findIndex(
        hotel => hotel?.navPathInfo?.adminDivision?.name === 'California'
      ) > -1
    const isUserFromCalifornia =
      meta.userCountryCode === 'US' && meta.userCountryRegion === 'CA'

    if (
      offset === 0 &&
      (hasPropertyInCalifornia || isUserFromCalifornia) &&
      toggle('30288e4c-include-taxes-in-california', false, true)
    ) {
      dispatch(
        setTaxConfigDangerously({includeLocalTaxes: true, includeTaxes: true})
      )
    } else {
      // Setting the default values
      dispatch(
        setTaxConfigDangerously(getTaxDisplayLogic(meta.userCountryCode))
      )
    }
  }

  return next(action)
}
export default middleware
