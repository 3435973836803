import {UserAgent} from './userAgent'

import type {DeviceCategory} from '../../../utils/userAgent/types'
import type {DeviceDetails, DevicePlatform, UserAgentDetails} from './types'

/**
 * Get the device platform according to the user agent string
 * @param userAgentDetails - optional parsed user agent object, `navigator.userAgent` will be used as a fallback
 * @returns DevicePlatform - the device platform (eg. 'android' or 'windows')
 */
export const getDevicePlatform = (
  userAgentDetails?: UserAgentDetails
): DevicePlatform => {
  const userAgent =
    userAgentDetails || new UserAgent().parse(navigator.userAgent)
  if (userAgent.isiPad || userAgent.isiPhone || userAgent.isiPod) return 'iOS'
  if (userAgent.isAndroid) return 'android'
  if (userAgent.isWindows) return 'windows'
  if (userAgent.isMac) return 'mac'
  if (userAgent.isLinux || userAgent.isLinux64) return 'linux'
  if (userAgent.isChromeOS) return 'chromeOS'
  return 'unknown'
}

/**
 * Is the device an Apple device according to the user agent string (`navigator.userAgent`)
 */
export const isApple = () => {
  const devicePlatform = getDevicePlatform()
  return ['iOS', 'mac'].includes(devicePlatform)
}

/**
 * Get the device category according to the user agent string
 * @param userAgentDetails - optional parsed user agent object, `navigator.userAgent` will be used as a fallback
 * @returns DeviceCategory - `"mobile" | "tablet" | "desktop"`
 */
export const getDeviceCategory = (
  userAgentDetails?: UserAgentDetails
): DeviceCategory => {
  // check tablet before mobile, because for express-useragent tablet is mobile
  const userAgent =
    userAgentDetails || new UserAgent().parse(navigator.userAgent)

  if (userAgent.isTablet) return 'tablet'
  if (userAgent.isMobile) return 'mobile'
  return 'desktop'
}

/**
 * Get the parsed user agent details, device platform and device category from the user agent string
 * The user agent will be grabbed from `navigator.userAgent`
 */
export const getUserDeviceDetails = (): DeviceDetails => {
  const userAgentDetails = new UserAgent().parse(navigator.userAgent)
  return {
    devicePlatform: getDevicePlatform(userAgentDetails),
    deviceCategory: getDeviceCategory(userAgentDetails),
    userAgentDetails
  }
}
