import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'Search simulations',
  value: 'Search',
  description:
    'Scenarios for the Search information endpoint, including the hotel name, address, and images (distinct from the offers endpoint, this one is called when asking for more results (both offers and hotels)',
  routes: [
    {
      endpoint: EndpointNames.Search,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'noStaticLinks',
          displayName: 'No static links for this search',
          description:
            'The search does not provide static links in case of unavailability'
        },
        {
          value: 'noResultsOnNextPage',
          displayName: 'No results on next page of search results',
          description:
            'No more results on the next page of the search results page'
        },
        {
          value: 'snapshot',
          displayName: 'Snapshot Search',
          description: 'A fully mocked response of the search results'
        }
      ]
    },
    {
      endpoint: EndpointNames.AlternativeAvailability,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'noStaticLinks',
          displayName: 'No static links for this search',
          description:
            'The search does not provide static links in case of unavailability'
        }
      ]
    },
    {
      endpoint: EndpointNames.Offers,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'snapshot',
          displayName: 'Snapshot Offers',
          description: 'A fully mocked response of the search results offers'
        },
        {
          value: 'oneTimePassword',
          displayName: 'One Time Password Flow',
          description:
            'One Time Password Flow, Please make sure you always pass these toggles ab_ea3355fc-feature-sms-flow=b&ab_feature-web-to-app-mobile-anchor=a&ab_feature-web-to-app-mobile-non-anchor=a&ab_feature-web-to-app-desktop=a'
        }
      ]
    },
    {
      endpoint: EndpointNames.SapiAnchor,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'snapshot',
          displayName: 'Snapshot SAPI Anchor',
          description:
            'A fully mocked response of the search results anchor hotel'
        }
      ]
    },
    {
      endpoint: EndpointNames.User,
      method: RequestMethod.GET,
      page: Pages.SRP,
      variations: [
        {
          value: 'oneTimePassword',
          displayName: 'One Time Password Flow',
          description:
            'One Time Password Flow, Please make sure you always pass these toggles ab_ea3355fc-feature-sms-flow=b&ab_feature-web-to-app-mobile-anchor=a&ab_feature-web-to-app-mobile-non-anchor=a&ab_feature-web-to-app-desktop=a'
        }
      ]
    }
  ]
}
