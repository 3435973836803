import React from 'react'
import {useSelector} from 'react-redux'
import {getCurrencyCode} from 'modules/meta/selectors'

import {Icon} from '@daedalus/atlas/Icon'
import {Tag} from '@daedalus/atlas/Tag'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {VioTagDetails} from '@daedalus/core/src/tag'
import {VioDealTag} from '@daedalus/shared/src/privateDeal/VioDealTag'

export const PRIMARY_ROOM_OFFER_TAG_DATA_ID = 'primaryRoomOfferTag'

interface Props {
  isClicked: boolean
  vioTagDetails: VioTagDetails
}

export const PrimaryRoomOfferTag = ({isClicked, vioTagDetails}: Props) => {
  const currencyCode = useSelector(getCurrencyCode)
  const {isVioTagVisible, savingType, savingPrice, savingPercentage} =
    vioTagDetails

  if (isClicked)
    return (
      <Tag
        variant="info"
        iconStart={<Icon name="ArrowDown" size="sm" />}
        dataId={PRIMARY_ROOM_OFFER_TAG_DATA_ID}
      >
        <FormattedMessageWrapper
          id="offerBox.selectedDeal"
          defaultMessage="Selected deal"
        />
      </Tag>
    )

  if (isVioTagVisible) {
    return (
      <VioDealTag
        savingPercentage={savingPercentage}
        message={{
          id: 'vioLabelFinal',
          defaultMessage: 'Only on Vio'
        }}
        savingType={savingType}
        savingPrice={savingPrice}
        currencyCode={currencyCode}
        dataId={PRIMARY_ROOM_OFFER_TAG_DATA_ID}
      />
    )
  }
  return (
    <Tag
      variant="success"
      iconStart={<Icon name="Party" size="sm" />}
      dataId={PRIMARY_ROOM_OFFER_TAG_DATA_ID}
    >
      <FormattedMessageWrapper
        id="bestAvailableDeal"
        defaultMessage="Best available deal"
      />
    </Tag>
  )
}
