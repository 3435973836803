import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {isVioOfferOrBundleSelected} from 'modules/accommodation/utils'
import updateLocation from 'modules/common/actions/location'

import {mapSearchToRoomsFilters} from '@daedalus/core/src/room/business/utils/filters'
import {parseQueryString} from '@daedalus/core/src/utils/url'

import type {FilterUrlParams} from 'modules/search/types'

const getFilterParams = (search: string) => {
  return parseQueryString(search) as {
    roomFilters?: string
    freeCancellation?: BooleanUrlParam
    amenities?: string
  }
}

export const useSyncFilters = () => {
  const dispatch = useDispatch()
  const {pathname, search} = useLocation()
  const isVioOfferSelected = isVioOfferOrBundleSelected()

  const {
    roomFilters: activeRoomsFilters,
    freeCancellation,
    amenities
  } = getFilterParams(search)

  useEffect(() => {
    const activeSearchFilters = {freeCancellation, amenities}
    const hasUnappliedSearchFilter = mapSearchToRoomsFilters(
      activeSearchFilters
    ).some(filterKey => !activeRoomsFilters?.includes(filterKey))
    const newRoomfilters = mapSearchToRoomsFilters(activeSearchFilters)
    // Users might land on AP with filters from SRP such as freeCancellation, this applies them to room filters too
    if (hasUnappliedSearchFilter) {
      dispatch(
        updateLocation({
          basePath: pathname,
          method: 'replace',
          mergeWithCurrentParams: true,
          params: {roomFilters: newRoomfilters.join(',')}
        })
      )
    }
  }, [])
  useEffect(() => {
    // Syncing room filters with top offers for hotel-card-clickers causes requesting rooms-offers again
    if (!isVioOfferSelected) return
    // Applies room filters to url with SRP filters to update search result for top offers
    const newSearchFilters: FilterUrlParams = {
      amenities: activeRoomsFilters?.includes('breakfastIncluded')
        ? ['breakfast']
        : undefined,
      freeCancellation: activeRoomsFilters?.includes('freeCancellation')
        ? '1'
        : undefined,
      payLater: activeRoomsFilters?.includes('payLater') ? '1' : undefined
    }
    dispatch(
      updateLocation({
        basePath: pathname,
        method: 'replace',
        mergeWithCurrentParams: true,
        params: newSearchFilters
      })
    )
  }, [dispatch, pathname, activeRoomsFilters])
}
