import React, {useState} from 'react'
import Script from 'next/script'
import {toggle} from 'opticks'

import {isReactNativeWebView} from '../../../native'
import {useIdentifyOnCustomerIo} from '../../hooks/useIdentifyOnCustomerIo'
import {Action, Category, Entity} from '../../types/Events'
import {dataDogLogEvent} from '../DataDogClient/index'

interface LoadCIOParams {
  customerIoSiteId: string
}

enum State {
  Idle = 'Idle',
  Loading = 'Loading',
  Ready = 'Ready'
}

/**
 * Load & init CustomerIO in a Next.js app
 */
export const InitializeCustomerIo = ({customerIoSiteId}: LoadCIOParams) => {
  const [state, setState] = useState(State.Idle)
  useIdentifyOnCustomerIo(state === State.Ready)

  const shouldEnableInAppMessaging =
    isReactNativeWebView() && toggle('feature-in-app-message', false, true)

  return (
    <Script
      onReady={() => setState(State.Ready)}
      onError={() => {
        dataDogLogEvent(
          `${Category.System}_${Entity.CustomerIoScript}_${Action.Errored}`,
          {}
        )
      }}
      id="customerio"
    >
      {`
        var _cio = _cio || []
        ;(function () {
          var a, b, c
          a = function (f) {
            return function () {
              _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)))
            }
          }
          b = ['load', 'identify', 'sidentify', 'track', 'page']
          for (c = 0; c < b.length; c++) {
            _cio[b[c]] = a(b[c])
          }
          var t = document.createElement('script')
          t.async = true
          t.id = 'cio-tracker'
          t.setAttribute('data-site-id', '${customerIoSiteId}')
          t.setAttribute('data-use-array-params', 'true')
          t.setAttribute('data-use-in-app', ${shouldEnableInAppMessaging ? 'true' : 'false'})
          t.src = 'https://assets.customer.io/assets/track.js'
          document.head.appendChild(t)
        })();
    `}
    </Script>
  )
}
