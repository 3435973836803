import React from 'react'

import {usePriceWatchToggleWebViewLogic} from '../../hooks/usePriceWatchToggleWebViewLogic'
import {PriceWatchCTAParams} from '../../types'

export const WebViewComponentWrapper = ({...props}: PriceWatchCTAParams) => {
  const {handleClick, isToggleOn} = usePriceWatchToggleWebViewLogic(props)
  const {ctaComponent: CtaComponent, shouldPulse} = props

  if (!CtaComponent) return null
  return (
    <CtaComponent
      shouldPulse={shouldPulse}
      isToggleOn={isToggleOn}
      handleClick={handleClick}
    />
  )
}
