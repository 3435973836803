import React from 'react'
import {MinimalHotelCard} from 'components/MinimalHotelCard'
import hotelPlaceholderImage from 'images/placeholder-hotel-image.svg'

import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'
import {Bookings} from '@daedalus/core/src/api-types/bovio/response/bookings'
import {roomsToString} from '@daedalus/core/src/room/business/roomConfiguration'
import {useGetHotelQuery} from '@daedalus/core/src/sapi/services/searchApi'

export const Booking = ({
  hotelId,
  checkIn,
  checkOut,
  rooms,
  fullWidth,
  onClick
}: Pick<Bookings, 'hotelId' | 'checkIn' | 'checkOut' | 'rooms'> & {
  fullWidth: boolean
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}) => {
  const {
    data: hotel,
    isSuccess,
    isFetching,
    isError,
    isLoading
  } = useGetHotelQuery({hotelId})

  const roomsString = roomsToString(rooms)

  if (isError) return null

  if (!isSuccess || isFetching || isLoading)
    return <SkeletonLoader height={88} width={227} />

  return (
    <MinimalHotelCard
      fullWidth={fullWidth}
      onClick={onClick}
      placeholderImage={hotelPlaceholderImage}
      title={hotel.hotelName}
      checkIn={checkIn}
      checkOut={checkOut}
      imageUrl={hotel.imageURIs[0]}
      rooms={roomsString}
    />
  )
}
