// TODO (Core): This import will be fixed when utils/url is migrated
import {generateEncodedSapiLabel} from '../../../sapiLabel/business'
import {parseQueryString} from '../../../utils/url'
import {getAnonymousId} from '../../anonymousId'
import {getPersistedSapiLabel} from '.'

/**
 * Generates a SAPI label based on the provided landing query string and brand code.
 * @param landingQueryString - The query string from the landing page URL.
 * @param partnerId - The brand code, which is determined by the instance of the website that is making requests to SAPI.
 */
export const generateSapiLabelFromBrowserContext = (
  landingQueryString: string,
  partnerId: string | undefined
) => {
  const anonymousId = getAnonymousId()
  const hasPersistedSapiLabel = !!getPersistedSapiLabel()
  const landingParameters = parseQueryString(landingQueryString) as Record<
    string,
    string
  >

  if (!anonymousId) return // type guard

  return generateEncodedSapiLabel({
    landingParameters,
    anonymousId,
    partnerId,
    hasPersistedSapiLabel
  })
}
