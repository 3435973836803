import {useRef} from 'react'
import {useSelector} from 'react-redux'
import {useCheckPriceWatchMismatch} from 'components/PriceWatch/hooks/useCheckPriceWatchMismatch'
import {getOverlayVisibility} from 'modules/overlay/selectors'
import {OverlayType} from 'modules/overlay/types'
import {
  getAnchorHotelId,
  getIsSearchCompleted
} from 'modules/sapiSearch/selectors'
import {getClickedOfferRate} from 'modules/search/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

import PriceUpdateBottomSheet from './PriceUpdateBottomSheet/PriceUpdateBottomSheet'
import {PriceWatchBottomSheetLoadable} from './PriceWatchBottomSheet'
import {PriceWatchPopUpLoadable} from './PriceWatchPopUp'

export const PriceWatchOverlays = () => {
  const hasShownPriceMismatchBottomSheet = useRef(false)

  const anchorHotelId = useSelector(getAnchorHotelId)
  const {isMobile} = useDeviceLayout()
  const clickedOfferRate = useSelector(getClickedOfferRate)
  const isSearchCompleted = useSelector(getIsSearchCompleted)

  const {isPriceDecrease, isPriceIncrease, currentTopPrice} =
    useCheckPriceWatchMismatch({
      hotelId: anchorHotelId,
      clickedOfferRate
    })

  /**
   * PriceUpdateBottomSheet would pop up intermittently to the guest
   * until the request finishes loading, so it shows a last time.
   * isSearchCompleted grants that we only show this bottomsheet once
   * sapi request is finished.
   */
  const shouldShowPriceMismatchBottomSheet =
    isSearchCompleted &&
    (isPriceDecrease || isPriceIncrease) &&
    !hasShownPriceMismatchBottomSheet.current &&
    isMobile

  const isPriceWatchOverlayShown = useSelector(state =>
    getOverlayVisibility(state, OverlayType.PriceWatchOverlay)
  )

  if (!isPriceWatchOverlayShown && !shouldShowPriceMismatchBottomSheet) {
    return null
  }

  if (isPriceWatchOverlayShown) {
    return isMobile ? (
      <PriceWatchBottomSheetLoadable />
    ) : (
      <PriceWatchPopUpLoadable />
    )
  }

  if (shouldShowPriceMismatchBottomSheet) {
    hasShownPriceMismatchBottomSheet.current = true

    return (
      <PriceUpdateBottomSheet
        isIncrease={isPriceIncrease}
        isOpen={shouldShowPriceMismatchBottomSheet}
        alertPrice={clickedOfferRate}
        currentPrice={currentTopPrice}
      />
    )
  }
}
