import React from 'react'

import {OfferLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'

import {CompactDefaultLayout} from './CompactDefaultLayout'
import {CompactWithoutRoomNameLayout} from './CompactWithoutRoomNameLayout'
import {PrimaryDefaultLayout} from './PrimaryDefaultLayout'
import {PrimaryMultiColumnFullWidthCTALayout} from './PrimaryMultiColumnFullWidthCTALayout'
import {PrimaryMultiColumnLayout} from './PrimaryMultiColumnLayout'
import {SecondaryDefaultLayout} from './SecondaryDefaultLayout'
import {SecondaryInlineLayout} from './SecondaryInlineLayout'
import {SecondaryWithoutRoomNameLayout} from './SecondaryWithoutRoomNameLayout'

export interface OfferLayoutBaseProps {
  priceComponent: React.ReactNode
  providerLogoComponent: React.ReactNode
  isLoading?: boolean
  ctaComponent?: React.ReactNode
  roomNameComponent?: React.ReactNode
  roomLabelsComponent?: React.ReactNode
  tagComponent?: React.ReactNode
  yourChoiceComponent?: React.ReactNode
  className?: string
  dataId?: string
}

export interface OfferLayoutLoadingProps {
  isLoading: true
  priceComponent?: never
  providerLogoComponent?: never
  ctaComponent?: never
  roomNameComponent?: never
  roomLabelsComponent?: never
  tagComponent?: never
  yourChoiceComponent?: never
  className?: never
  dataId?: string
}

interface Props extends OfferLayoutBaseProps {
  variant: OfferLayoutVariant
}

interface LoadingProps extends OfferLayoutLoadingProps {
  variant: OfferLayoutVariant
}

export const OfferLayout: React.FC<Props | LoadingProps> = ({
  variant = 'PrimaryDefault',
  dataId = 'Offer',
  ...args
}) => {
  switch (variant) {
    case 'PrimaryDefault':
      return <PrimaryDefaultLayout dataId={dataId} {...args} />
    case 'PrimaryMultiColumn':
      return <PrimaryMultiColumnLayout dataId={dataId} {...args} />
    case 'PrimaryMultiColumnFullWidthCTA':
      return <PrimaryMultiColumnFullWidthCTALayout dataId={dataId} {...args} />
    case 'SecondaryDefault':
      return <SecondaryDefaultLayout dataId={dataId} {...args} />
    case 'SecondaryWithoutRoomName':
      return <SecondaryWithoutRoomNameLayout dataId={dataId} {...args} />
    // Added as part of 70766997-desktop-hotel-card-secondary-offers
    case 'SecondaryInline':
      return <SecondaryInlineLayout dataId={dataId} {...args} />
    case 'CompactDefault':
      return <CompactDefaultLayout dataId={dataId} {...args} />
    case 'CompactWithoutRoomName':
      return <CompactWithoutRoomNameLayout dataId={dataId} {...args} />
    default:
      return <PrimaryDefaultLayout dataId={dataId} {...args} />
  }
}
