import {useContext} from 'react'
import {useSelector} from 'react-redux'
import {apOfferMountTypes, OfferViewContext} from 'context/offerViewContext'
import {getMetaBrandHasRooms} from 'modules/accommodation/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

export const useShouldOfferLeadToRooms = (isBofhOffer = true) => {
  const {isMobile} = useDeviceLayout()
  const offerView = useContext(OfferViewContext)
  const brandHasRooms = useSelector(getMetaBrandHasRooms)

  const isOfferMountSupported = apOfferMountTypes.includes(offerView)
  // If offer is mounted in go-to-rooms type place
  return isBofhOffer && isMobile && isOfferMountSupported && brandHasRooms
}
