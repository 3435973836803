import {createSelector} from '@reduxjs/toolkit'
import {isEmpty} from 'ramda'

import {AvailabilityHotelEntity} from '../../../availability/types'
import {ColorPriceCalendarState} from './slice'

export interface State {
  colorPriceCalendar: ColorPriceCalendarState
}

/**
 * Retrieves the hotel availability prices from the ColorPriceCalendar state.
 * @param state - The application state.
 * @param month - The month of the request, e.g. '2025-01'.
 * @returns The availability hotel entity containing the hotel availability prices.
 */
export const getMonthAvailabilityPrices = (
  state: State,
  month: string
): AvailabilityHotelEntity =>
  state?.colorPriceCalendar[month]?.availabilityPrices

/**
 * Retrieves the status of the hotel availability prices request from the ColorPriceCalendar state.
 * @param state - The application state.
 * @param month - The month of the request, e.g. '2025-01'.
 * @returns The status of the request.
 */
export const getMonthAvailabilityStatus = (state: State, month: string) =>
  state?.colorPriceCalendar[month]?.status

/**
 * Gets whether some availability prices have been returned.
 * @param state - The application state.
 * @returns Whether some availability prices have been returned.
 */
export const getHasSomeAvailabilityPrices = (state: State) => {
  const months = Object.keys(state?.colorPriceCalendar)
  return months.some(
    month => !isEmpty(state?.colorPriceCalendar[month]?.availabilityPrices)
  )
}

/**
 * Retrieves the hotel availability prices from the ColorPriceCalendar state.
 * @param state - The application state.
 * @returns The fetched availability months.
 */
const getFetchedAvailabilityAllMonthsValue = (state: State) =>
  state?.colorPriceCalendar

export const getFetchedAvailabilityAllMonths = createSelector(
  [getFetchedAvailabilityAllMonthsValue],
  colorPriceCalendar => Object.keys(colorPriceCalendar)
)
