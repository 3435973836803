import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {isEmpty, pickBy} from 'ramda'

import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {
  BaseOfferContext,
  CheckoutOfferContext,
  HotelContext,
  LeadContext,
  PriceContext,
  RoomOfferContext,
  SearchOfferContext
} from '@daedalus/core/src/analytics/types/Events'
import {
  Booking,
  Source
} from '@daedalus/core/src/api-types/bovio/response/booking'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {Hotel, Room, RoomOffer} from '@findhotel/sapi'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src/types/types'

import {
  DealFreezeError,
  DealFreezeErrorSource,
  PayloadErrorType
} from '../components/ErrorMessage/types'
import {dealFreezeApi, DealFreezeEndpoints} from '../services/dealFreezeApi'
import {ComponentSource} from '../types'
import {
  BookingCreationStatus,
  BookingCreationStatusResponse
} from '../types/bookingCreation'
import {OfferCheckResponse, OfferCheckStatuses} from '../types/offer'
import {findExactMatchedOffer} from '../utils/offer'

export type DealFreezeAnalyticsContext = {
  offerContext?:
    | CheckoutOfferContext
    | SearchOfferContext
    | RoomOfferContext
    | null
  leadContext?: LeadContext
  priceContext?: PriceContext
  hotelContext?: HotelContext
}

export type SearchDetail = {
  checkIn: string
  checkOut: string
  roomsSplit: string
}

export interface DealFreezeSelectedOffer {
  id: string | null
  providerCode: string | null
  rspUrl?: string
  // Offer from SRP/AP; used in SAPI room request to get the exact offer with the same price rate
  searchOffer?: SearchOffer | null
}

export enum ApplicationNames {
  Search = 'search',
  Checkout = 'checkout'
}

export interface ApplicationSate {
  searchId: string | null
  countryCode?: string | null
  currency?: string | null
  languageCode: string | null
  bookingManagementUrl?: string
  checkoutUrl?: string
}

export type State = {
  isOverlayOpen: boolean
  isConfirmationOverlayOpen: boolean
  api: {
    boVioProfile: string | null
    boVioEndpoint: string | null
    userToken: string | null | undefined
    clientUserAgent: string | null
    xSapiVariations: string | null
    xRequestId?: string | null
    vclid?: string | null
  }
  application: ApplicationSate
  data: {
    // Offer and hotel that the deal freeze button was shown for and that user clicked on
    selectedOffer: DealFreezeSelectedOffer
    selectedHotel: Hotel | ContentHotel | null
    searchDetail: SearchDetail | null
    // SAPI rooms: Obtained directly from SAPI or, if feasible, passed as props to DealFreezeButton.
    rooms: Room[] | undefined
    sapiMatchedOffer: RoomOffer | undefined
    component: ComponentSource | string | null | undefined
  }
  offerCheck: {
    response: OfferCheckResponse | null | undefined
    isLoading: boolean
    isOfferEligible: boolean | null
  }
  frozenBookings: Booking[]
  analytics: {
    trackedOffers: string[]
  }
  error: DealFreezeError
  offersWithError: string[]
  bookingStatus: BookingCreationStatusResponse | null
  analyticsContext?: DealFreezeAnalyticsContext | null
  isUserEligible: boolean
  isDealFreezeEnabled: boolean
  firstEligibleDealFreezeRoomId: string | null
}

export const initialState: State = {
  isOverlayOpen: false,
  isConfirmationOverlayOpen: false,
  api: {
    boVioProfile: null,
    boVioEndpoint: null,
    userToken: null,
    xRequestId: null,
    xSapiVariations: null,
    clientUserAgent: null
  },
  application: {
    searchId: null,
    countryCode: null,
    currency: null,
    languageCode: null
  },
  data: {
    selectedOffer: {
      id: null,
      providerCode: null,
      rspUrl: undefined,
      searchOffer: null
    },
    selectedHotel: null,
    searchDetail: null,
    rooms: undefined,
    sapiMatchedOffer: undefined,
    component: null
  },
  offerCheck: {
    isLoading: false,
    isOfferEligible: null,
    response: null
  },
  frozenBookings: [],
  analytics: {
    trackedOffers: []
  },
  error: {
    errored: false
  },
  offersWithError: [],
  analyticsContext: null,
  bookingStatus: null,
  isUserEligible: false,
  // this is responsible for keeping track of whether the deal freeze feature is enabled or not
  isDealFreezeEnabled: false,
  firstEligibleDealFreezeRoomId: null
}

const {actions, reducer} = createSlice({
  name: 'dealFreeze',
  initialState,
  reducers: {
    openFreezeOverlay: (
      state,
      action: PayloadAction<{
        offer: DealFreezeSelectedOffer
        hotel: Hotel | ContentHotel
        searchDetail: SearchDetail
        room?: Room[] | undefined
        component: ComponentSource | string
      }>
    ) => {
      state.offerCheck = initialState.offerCheck
      state.isOverlayOpen = true
      const matchedOffer = findExactMatchedOffer(
        action.payload.room,
        action.payload.offer.id
      )
      state.data = {
        selectedOffer: action.payload.offer,
        selectedHotel: action.payload.hotel,
        searchDetail: action.payload.searchDetail,
        rooms: action.payload.room,
        sapiMatchedOffer: matchedOffer,
        component: action.payload.component
      }
    },
    setSapiRoom: (
      state,
      action: PayloadAction<{rooms: Room[]; offerId: string}>
    ) => {
      state.data.rooms = action.payload.rooms
      state.data.sapiMatchedOffer = findExactMatchedOffer(
        action.payload.rooms,
        action.payload.offerId
      )
    },
    openFreezeConfirmationOverlay: (
      state,
      action: PayloadAction<BookingCreationStatusResponse>
    ) => {
      state.isConfirmationOverlayOpen = true
      state.isOverlayOpen = false
      state.bookingStatus = action.payload
    },
    closeFreezeOverlay: state => {
      state.isOverlayOpen = false
      state.error = {errored: false}
    },
    closeFreezeConfirmationOverlay: state => {
      state.isConfirmationOverlayOpen = false
    },
    closeAllFreezeOverlays: state => {
      state.isOverlayOpen = false
      state.isConfirmationOverlayOpen = false
      state.error = initialState.error
    },
    resetData: state => {
      state.offerCheck = initialState.offerCheck
      state.data = {
        ...initialState.data,
        searchDetail: state.data.searchDetail
      }
      state.bookingStatus = initialState.bookingStatus
      state.error = initialState.error
      state.analyticsContext = initialState.analyticsContext
      state.data.component = initialState.data.component
    },
    setUserFreezeEligibility: (state, action: PayloadAction<boolean>) => {
      state.isUserEligible = action.payload
    },
    setDealFreezeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDealFreezeEnabled = action.payload
    },
    setAnalyticsContext: (
      state,
      action: PayloadAction<DealFreezeAnalyticsContext>
    ) => {
      const offerContext = action.payload.offerContext as BaseOfferContext

      const offerContextWithFreezeProperties = {
        ...offerContext,
        chargeType: [Source.DealFreeze],
        paymentExpiryAt: undefined
      }

      const payload = {
        ...action.payload,
        offerContext: offerContextWithFreezeProperties
      }

      state.analyticsContext = pickBy(
        value => value !== undefined && !isEmpty(value),
        payload
      ) as DealFreezeAnalyticsContext
    },
    setAnalyticsLeadContext: (
      state,
      action: PayloadAction<{redirectId: string; providerRateType?: string}>
    ) => {
      const leadContext: LeadContext = {
        redirectId: action.payload.redirectId,
        anonymousId: getAnonymousId() as string,
        checkIn: state.data.searchDetail?.checkIn as string,
        checkOut: state.data.searchDetail?.checkOut as string,
        rooms: state.data.searchDetail?.roomsSplit as string,
        hotelId: state.data.selectedHotel?.objectID as string,
        searchId: state.application.searchId as string,
        locale: state.application.languageCode as string,
        providerCode: state.data.selectedOffer?.providerCode as string,
        currency: state.application.currency as string,
        providerRateType: action.payload.providerRateType || ''
      }
      state.analyticsContext = {
        ...state.analyticsContext,
        leadContext
      }
    },
    setOfferViewTracked: (state, action: PayloadAction<{offerId: string}>) => {
      /**
       * On the Search Results Page (SRP), opening the Accommodation Page (AP) does not trigger a redirection,
       * resulting in the state and trackedOffers list being retained. It's necessary to track the same offer
       * whether it's viewed on the AP or the SRP. By including the page name in the `trackId`, we can
       * distinguish and track the same offerId across these different pages.
       */
      const trackId = `${action.payload.offerId}`
      if (!state.analytics.trackedOffers.includes(trackId)) {
        state.analytics.trackedOffers = [
          ...state.analytics.trackedOffers,
          trackId
        ]
      }
    },
    setApiContext(state, action: PayloadAction<State['api']>) {
      state.api = action.payload
    },
    setApplication: (state, action: PayloadAction<State['application']>) => {
      state.application = action.payload
    },
    setError: (state, action: PayloadAction<DealFreezeError>) => {
      state.error = action.payload
    },
    setFirstEligibleDealFreezeRoom: (
      state,
      action: PayloadAction<State['firstEligibleDealFreezeRoomId']>
    ) => {
      state.firstEligibleDealFreezeRoomId = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        dealFreezeApi.endpoints.checkUserLimit.matchFulfilled,
        (state, action) => {
          const isEligible = action.payload?.remainingFreezes > 0
          state.isUserEligible = isEligible
        }
      )
      .addMatcher(
        dealFreezeApi.endpoints?.retrieveBooking.matchFulfilled,
        (state, action) => {
          const requestIdHeader = action.payload?.httpRequestId
          if (requestIdHeader && !state.api.xRequestId) {
            state.api.xRequestId = requestIdHeader
          }
        }
      )
      .addMatcher(
        dealFreezeApi.endpoints.getFrozenBookings.matchFulfilled,
        (state, action) => {
          state.frozenBookings = action.payload as Booking[]
        }
      )
      .addMatcher(dealFreezeApi.endpoints.offerCheck.matchPending, state => {
        state.offerCheck.isLoading = true
      })
      .addMatcher(
        dealFreezeApi.endpoints.getBookingStatus.matchFulfilled,
        (state, action) => {
          // Update booking status
          state.bookingStatus = action.payload
          if (action?.payload?.status === BookingCreationStatus.Error) {
            const {details} =
              (action?.payload?.error as unknown as {details: string}) || {}

            state.error = {
              errored: true,
              message: 'Get booking status failed',
              endpoint: DealFreezeEndpoints.GetBookingStatus,
              errorDetails: details,
              component: DealFreezeErrorSource.ConfirmationOverlay
            }
          }
        }
      )
      .addMatcher(
        dealFreezeApi.endpoints.getBookingStatus.matchRejected,
        state => {
          state.bookingStatus = {
            error: null,
            status: BookingCreationStatus.Error,
            id: '',
            links: [],
            threeDomainSecure: null
          }
        }
      )
      .addMatcher(
        dealFreezeApi.endpoints.offerCheck.matchFulfilled,
        (state, action) => {
          const isEligible =
            action.payload?.status === OfferCheckStatuses.available
          state.offerCheck = {
            response: action.payload,
            isLoading: false,
            isOfferEligible: isEligible
          }
          if (!isEligible && state.data.selectedOffer.id)
            state.offersWithError = [
              ...state.offersWithError,
              state.data.selectedOffer.id
            ]
          if (state.analyticsContext?.offerContext) {
            state.analyticsContext.offerContext.paymentExpiryAt = {
              freezeExpiryAt: action.payload?.expiresAt
            }
          }

          // set error state if offer is not available
          if (action.payload.status === OfferCheckStatuses.price_mismatch) {
            state.error = {
              errored: true,
              message: 'Price mismatch',
              endpoint: DealFreezeEndpoints.OfferCheck,
              payloadErrorType: PayloadErrorType.PriceMismatch
            }
          }
        }
      )
      .addMatcher(dealFreezeApi.endpoints.offerCheck.matchRejected, state => {
        if (state.data.selectedOffer.id)
          state.offersWithError = [
            ...state.offersWithError,
            state.data.selectedOffer.id
          ]
        state.offerCheck = {
          response: null,
          isLoading: false,
          isOfferEligible: null
        }
      })
      .addMatcher(
        action =>
          dealFreezeApi.endpoints.createBooking.matchRejected(action) ||
          dealFreezeApi.endpoints.offerCheck.matchRejected(action) ||
          dealFreezeApi.endpoints.getBookingStatus.matchRejected(action) ||
          dealFreezeApi.endpoints.retrieveBooking.matchRejected(action),
        (state, action) => {
          const endpoint = action.meta.arg?.endpointName
          const {message, type, details} = (action.payload?.data ||
            {}) as Record<string, string>

          const component = state.isOverlayOpen
            ? DealFreezeErrorSource.FreezeOverlay
            : DealFreezeErrorSource.ConfirmationOverlay

          state.error = {
            errored: true,
            message,
            endpoint,
            payloadErrorType: type,
            errorDetails: details,
            component
          }
        }
      )
  }
})

export const {
  openFreezeOverlay,
  closeFreezeOverlay,
  openFreezeConfirmationOverlay,
  closeFreezeConfirmationOverlay,
  closeAllFreezeOverlays,
  resetData,
  setUserFreezeEligibility,
  setSapiRoom,
  setDealFreezeEnabled,
  setAnalyticsContext,
  setAnalyticsLeadContext,
  setOfferViewTracked,
  setApiContext,
  setApplication,
  setError,
  setFirstEligibleDealFreezeRoom
} = actions

export default reducer
