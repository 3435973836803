import axios from 'axios'

import {getUserSessionService} from '@daedalus/core/src/auth/services'

import {InitOneTimePassword} from '../types'

const axiosInstance = axios.create()

interface SendOneTimePassword extends Omit<InitOneTimePassword, 'verifyUrl'> {
  password?: string
}

export const sendOneTimePassword = async ({
  brandCode = 'vio',
  languageCode = 'en',
  password,
  phoneNumber,
  oneTimePasswordUrl
}: SendOneTimePassword) => {
  const {accessToken} = await getUserSessionService(true)
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  }

  const {data} = await axiosInstance.post<string>(
    oneTimePasswordUrl,
    JSON.stringify({
      brandCode,
      languageCode,
      password,
      phoneNumber
    }),
    config
  )

  return data
}

interface OneTimePasswordIsValid {
  brandCode: InitOneTimePassword['brandCode']
  phoneNumber: InitOneTimePassword['phoneNumber']
  password: string
  verifyUrl: string
}

export const oneTimePasswordIsValid = async ({
  brandCode = 'vio',
  password,
  phoneNumber,
  verifyUrl
}: OneTimePasswordIsValid) => {
  try {
    const {accessToken} = await getUserSessionService()
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }

    await axiosInstance.post<string>(
      verifyUrl,
      JSON.stringify({
        brandCode,
        password,
        phoneNumber
      }),
      config
    )

    return true
  } catch (error) {
    console.error('Validation failed:', error)
    return false
  }
}
