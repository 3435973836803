import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CarouselWidgetsContext} from 'components/CarouselWidgets/context/CarouselWidgetsContext'
import {HighlightedTitle} from 'components/Home/HighlightedTitle'
import {MinimalHotelCard} from 'components/MinimalHotelCard'
import {useNavigateToSearchWithTrigger} from 'hooks/useNavigateToSearch'
import hotelPlaceholderImage from 'images/placeholder-hotel-image.svg'
import mapPlaceholderImage from 'images/placeholder-map-image.svg'
import {getIsRtlLanguage} from 'modules/meta/selectors'
import {SearchTrigger} from 'modules/searchBox/types'
import {pick} from 'ramda'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {SmoothScrollNavigation} from '@daedalus/atlas/experimental/SmoothScrollNavigation'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {FlattenSearchHistory} from '@daedalus/core/src/searchHistory'

import {Title} from '../components/Title'
import {DESTINATION_KEYS, ITINERARY_KEYS} from '../utils/constants'

export const RecentSearchHistory = () => {
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()
  const isRtlLanguage = useSelector(getIsRtlLanguage)
  const {searchHistory, searchIds} = useContext(CarouselWidgetsContext)
  const navigateToSearch = useNavigateToSearchWithTrigger(
    SearchTrigger.RecentSearches
  )

  useEffect(() => {
    if (searchHistory.length) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Carousel,
          action: Action.Displayed,
          component: 'RecentSearches',
          payload: {
            totalNumber: searchIds?.length,
            searchItem: pick(
              [...DESTINATION_KEYS, ...ITINERARY_KEYS],
              searchHistory
            ),
            searches: searchIds
          },
          team: Team.Retention
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(searchHistory)])

  const onClick = (item: FlattenSearchHistory, index: number) => {
    const {hotelId, placeId, checkIn, checkOut, rooms, address} = item
    const searchItem = {
      ...(hotelId && {hotelId}),
      ...(placeId && {placeId}),
      ...(address && {address}),
      checkIn,
      checkOut,
      rooms
    }
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.CarouselCard,
        action: Action.Clicked,
        component: 'RecentSearches',
        payload: {
          totalNumber: searchHistory.length,
          index,
          searchItem
        },
        team: Team.Retention
      })
    )

    navigateToSearch({...searchItem, userSearch: 1, homeSearch: 1})
  }

  if (!searchHistory.length) {
    return null
  }

  return (
    <div>
      <Title variant="titleXL">
        <HighlightedTitle source="RecentSearchHistory" />
      </Title>
      <SmoothScrollNavigation
        isRtlLanguage={isRtlLanguage}
        hasSidePaddings={isMobile}
      >
        {searchHistory.map((item, index) => (
          <MinimalHotelCard
            onClick={() => onClick(item, index)}
            imageUrl={item.imageUrl}
            title={
              item.objectType === 'hotel' ? item.hotelName : item.placeName
            }
            checkIn={item.checkIn}
            checkOut={item.checkOut}
            rooms={item.rooms}
            placeholderImage={
              item.objectType === 'hotel'
                ? hotelPlaceholderImage
                : mapPlaceholderImage
            }
            testId={`recent-searches-item-${index}`}
            fullWidth={isMobile}
            key={`recent-searches-${item?.historyId}`}
          />
        ))}
      </SmoothScrollNavigation>
    </div>
  )
}
