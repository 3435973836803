import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {PrimaryRoomOffer} from 'components/Accommodation/Offers/PrimaryRoomOffer'
import SpinnerImportLoader from 'components/Loaders/SpinnerImportLoader'
import {getLocationVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {loadModule} from 'utils'

import {Divider} from '@daedalus/atlas/Divider'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Stack} from '@daedalus/atlas/helpers/Stack'
import {Modal} from '@daedalus/shared/src/accommodation/Modal'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src'

import {LeftInfoWrapper, OverlayContent} from '../styles'
import {LocationOverlayHotelInfo} from './LocationOverlayHotelInfo'

import type {LocationMapRendererProps} from 'components/Accommodation/LocationOverlay/LocationMapRenderer/LocationMapRenderer'

type PropsType = {
  hotel: ContentHotel | undefined
  hasOffer: boolean
  isLoading: boolean
}

const LocationMapRenderer = loadModule<LocationMapRendererProps>(
  async () =>
    import(
      /* webpackChunkName: "LocationMapRenderer" */
      'components/Accommodation/LocationOverlay/LocationMapRenderer/LocationMapRenderer'
    ),
  {loading: SpinnerImportLoader}
)

export const LocationOverlayDesktop = ({
  hotel,
  isLoading,
  hasOffer
}: PropsType) => {
  const locationOverlayIsActive = useSelector(getLocationVisibility)
  const dispatch = useDispatch()
  const handleHideOverlay = () => dispatch(hideOverlay())

  return (
    <Modal
      hasShadeBackground
      isOpen={locationOverlayIsActive}
      onClose={handleHideOverlay}
    >
      {locationOverlayIsActive && hotel && (
        <OverlayContent>
          <LocationMapRenderer
            lat={hotel._geoloc.lat}
            lon={hotel._geoloc.lon}
          />
          <LeftInfoWrapper>
            <Stack space="s300">
              <ContentWrapper paddingX="s500" paddingY="s500">
                <LocationOverlayHotelInfo hotel={hotel} />
              </ContentWrapper>

              {(hasOffer || isLoading) && (
                <div>
                  <Divider />
                  <ContentWrapper padding="s400">
                    <PrimaryRoomOffer
                      hotelId={hotel.objectID}
                      component="DesktopLocationOverlay"
                      shouldCloseOverlayOnClick
                    />
                  </ContentWrapper>
                </div>
              )}
            </Stack>
          </LeftInfoWrapper>
        </OverlayContent>
      )}
    </Modal>
  )
}
