import Settings from 'Settings'
import {getLogEvents, setLogEvents} from 'utils/persistence'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {runInIdleTime} from '@daedalus/core/src/_web/utils/performance/idleUntilUrgent'
import AnalyticsUtils from '@daedalus/core/src/analytics/utils'

const initializeAnalytics = (anonymousId: string) => {
  const ANALYTICS_ENDPOINT = Settings.get('CLIENT_ANALYTICS_ENDPOINT')
  const API_KEY = Settings.get('CLIENT_ANALYTICS_API_KEY')
  const GTM_CONTAINER_ID = Settings.get('CLIENT_GTM_ID')

  const integrations = {
    'Segment.io': {
      apiKey: API_KEY,
      apiHost: ANALYTICS_ENDPOINT,
      /* 31e7d7cf-analytics-sendBeacon 
      Default to false and let team specific events override this.
      Once we migrate all events away from the legacy endpoint, we can use the toggle.
      */
      beacon: false
    },
    'Google Tag Manager': {
      containerId: GTM_CONTAINER_ID,
      // This prevents double page view tracking
      trackCategorizedPages: false
    }
  }

  // Switch for easier debugging
  const logEventsParam = new URLSearchParams(window.location.search).get(
    'logEvents'
  )
  if (logEventsParam) setLogEvents(logEventsParam)
  const logEvents = getLogEvents() === '1'

  AnalyticsUtils.setLogEventsStatus(logEvents)
  AnalyticsUtils.initialize(integrations, anonymousId)

  runInIdleTime(() => {
    /** Meta pixel cookies. Helpful for retargeting.
     * https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
     */
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')
    AnalyticsUtils.identify(undefined, {fbp, fbc})
  })
}

export default initializeAnalytics
