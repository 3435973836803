import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {
  FilterKey,
  FilterSource
} from 'components/Accommodation/OffersFilters/constants'
import {getSelectedFiltersMap} from 'components/Accommodation/OffersFilters/getSelectedFilters'
import {getAccommodationPageOfferFilters} from 'modules/accommodation/selectors'
import updateLocation from 'modules/common/actions/location'
import {getOverlay} from 'modules/overlay/selectors'
import {getUrlParams} from 'modules/search/selectors'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export const useHotelOverlayOffersFilters = () => {
  const dispatch = useDispatch()
  const overlayState = useSelector(getOverlay)
  const activeOffersFilters = useSelector(getAccommodationPageOfferFilters)
  const urlParams = useSelector(getUrlParams)
  const {pathname} = useLocation()
  /**
   * Set (or remove) multiple filters
   */
  const setFilters = (filters: FilterKey[], source?: FilterSource) => {
    if (source)
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.OfferFilterPreference,
          action: Action.Submitted,
          payload: {
            allSelectedFilters: getSelectedFiltersMap(filters),
            appliedFiltersFrom: source
          }
        })
      )
    const newParams = {...urlParams, offerFilters: filters.join(',')}
    if (!filters.length) delete newParams.offerFilters
    dispatch(
      updateLocation({
        basePath: pathname,
        method: 'replace',
        mergeWithCurrentParams: false,
        params: newParams,
        state: {overlay: overlayState}
      })
    )
  }

  /**
   * Toggle a single filter
   */
  const toggleFilter = (filterId: FilterKey, source: FilterSource) => {
    const newFilters = new Set(activeOffersFilters)
    newFilters.delete(filterId) || newFilters.add(filterId)
    setFilters(Array.from(newFilters), source)
  }

  return {
    activeOffersFilters,
    setFilters,
    toggleFilter
  }
}
