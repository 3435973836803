import {loadModule} from 'utils'

import type {Props} from './StarRatingBottomSheetContent'

export const StarRatingBottomSheetContentLoadable = loadModule<Props>(
  async () =>
    import(
      /* webpackChunkName: "StarRatingBottomSheetContent" */
      './StarRatingBottomSheetContent'
    ),
  {}
)
