import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {useShowAccommodationPage} from './useShowAccommodationPage'

export const useShowHotelDetailsFromUrlParams = () => {
  const dispatch = useDispatch()
  const {showAccommodationPage} = useShowAccommodationPage()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const hotelId = params.get('hotelId')
    if (!hotelId) return

    const shouldShowHotelDetails = params.get('showHotelDetails') === '1'

    if (shouldShowHotelDetails) {
      params.delete('showHotelDetails')
      const newUrl = `${location.pathname}?${params.toString()}`
      window.history.replaceState({}, '', newUrl)
      showAccommodationPage({hotelId})
    }
  }, [dispatch])
}
