import React from 'react'
import {useSelector} from 'react-redux'
import {
  SplitBookingBundleType,
  SplitBookingOfferInfo
} from 'components/Offer/types'
import {useSplitBookingSavingsMessage} from 'components/SplitBookings/helpers'
import {useIsRequiredOneTimePasswordFlow} from 'hooks/useIsRequiredOneTimePasswordFlow'
import {getCurrencyCode} from 'modules/meta/selectors'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'
import {getShouldShowYourChoiceTag} from 'modules/search/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Box} from '@daedalus/atlas/helpers/Box'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {OfferTagLabel, RateChecker} from '@daedalus/core/src/offer/types/offer'
import {OfferSecondaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {OfferLayout} from '@daedalus/shared/src/search/OfferLayout'
import {SplitBookingBundle} from '@findhotel/sapi'

import {useShowTotalPriceGhaOrGvrPreferredRate} from '../hooks/useShowTotalPriceGhaOrGvrPreferredRate'
import {OfferInteraction} from '../Shared/OfferInteraction'
import {GHAPreferredRateTotalPrice} from '../Shared/OfferPrice/GHAPreferredRateTotalPrice'
import {SplitBookingOfferInteraction} from '../Shared/SplitBookingOfferInteraction'
import {YourChoiceLabel} from '../Shared/YourChoiceLabel'
import {CtaComponent} from './CtaComponent'
import {PriceComponent} from './PriceComponent'
import {ProviderLogoComponent} from './ProviderLogoComponent'
import {RoomLabelsComponent} from './RoomLabelsComponent'
import {YourChoiceAndFreeCancelationLabels} from './YourChoiceAndFreeCancelationLabels'

interface MainProps {
  isLoading?: false
  variant: OfferSecondaryLayoutVariant
  offer: SearchOffer
  anchorPriceTotal: number
  anchorPrice: number
  hasRoomLabels?: boolean
  showSignInToUnlock?: boolean
  showPriceIndication?: boolean
  hotelId: string
  sourceComponent?: string
  offerPosition?: number
  hasInteraction: boolean
  isSplitBooking?: false
  splitBookingType?: never
  splitBookingOffers?: never
  splitBookingBundle?: never
}

interface SplitBookingProps {
  isLoading?: false
  variant: OfferSecondaryLayoutVariant
  isSplitBooking: boolean
  splitBookingType: SplitBookingBundleType
  splitBookingOffers: SplitBookingOfferInfo[]
  splitBookingBundle: SplitBookingBundle
  hasInteraction?: false
  offer?: never
  hasRoomLabels?: never
  showSignInToUnlock?: never
  showPriceIndication?: never
  hotelId: string
  anchorPriceTotal?: never
  anchorPrice?: never
  sourceComponent?: string
  offerPosition?: never
}

interface LoadingProps {
  variant: OfferSecondaryLayoutVariant
  isLoading: true
  hasInteraction?: false
  offer?: never
  hasRoomLabels?: never
  showSignInToUnlock?: never
  showPriceIndication?: never
  hotelId?: never
  anchorPriceTotal?: never
  anchorPrice?: never
  isSplitBooking?: never
  splitBookingType?: never
  splitBookingOffers?: never
  splitBookingBundle?: never
  sourceComponent?: never
  offerPosition?: never
}

export const SecondaryOffer: React.FC<
  MainProps | SplitBookingProps | LoadingProps
> = ({
  variant,
  offer,
  splitBookingOffers,
  splitBookingBundle,
  hasRoomLabels = true,
  showSignInToUnlock = false,
  showPriceIndication = false,
  hotelId,
  anchorPriceTotal,
  anchorPrice,
  isLoading = false,
  isSplitBooking,
  splitBookingType,
  hasInteraction = true,
  offerPosition = 0,
  sourceComponent
}) => {
  const {isMobile} = useDeviceLayout()
  const currencyCode = useSelector(getCurrencyCode)
  const savingsMessage = useSplitBookingSavingsMessage({hotelId})
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const shouldShowYourChoice = useSelector(
    getShouldShowYourChoiceTag(
      isSplitBooking &&
        splitBookingType === 'single_flow' &&
        splitBookingOffers.length
        ? splitBookingOffers[0].offer
        : offer,
      isSplitBooking
    )
  )

  const isRequiredOneTimePasswordFlow = useIsRequiredOneTimePasswordFlow({
    offer,
    isAppLockedDeal
  })

  const shouldShowGhaOrGvrPreferredRateTotalPrice =
    useShowTotalPriceGhaOrGvrPreferredRate(
      offer,
      hotelId,
      isRequiredOneTimePasswordFlow
    )

  const isYourChoice = hasOfferTag(
    splitBookingBundle || offer,
    OfferTagLabel.IsYourChoice
  )

  if (isLoading) {
    return <OfferLayout variant={variant} isLoading />
  }

  if (isSplitBooking) {
    return (
      <SplitBookingOfferInteraction
        component={sourceComponent}
        hotelId={hotelId}
      >
        <Box
          display="inline"
          data-id={isYourChoice && RateChecker.GHAYourChoiceId}
        >
          <OfferLayout
            variant={variant}
            priceComponent={<PriceComponent isSplitBooking hotelId={hotelId} />}
            providerLogoComponent={
              <ProviderLogoComponent
                isSplitBooking
                splitBookingType={splitBookingType}
                variant={variant}
                savingsMessage={savingsMessage}
                splitBookingOffers={splitBookingOffers}
              />
            }
            roomLabelsComponent={
              <RoomLabelsComponent
                isSplitBooking
                variant={variant}
                splitBookingType={splitBookingType}
                isYourChoice={shouldShowYourChoice}
                savingsMessage={savingsMessage}
              />
            }
            ctaComponent={<CtaComponent variant={variant} hotelId={hotelId} />}
            yourChoiceComponent={
              shouldShowYourChoice && variant === 'SecondaryWithoutRoomName' ? (
                <YourChoiceLabel />
              ) : null
            }
          />
        </Box>
      </SplitBookingOfferInteraction>
    )
  }

  return (
    <OfferInteraction
      anchorPriceTotal={anchorPriceTotal}
      hotelId={hotelId}
      offer={offer}
      component={sourceComponent}
      offerPosition={offerPosition}
      hasInteraction={hasInteraction}
    >
      <Box
        display="inline"
        data-id={isYourChoice && RateChecker.GHAYourChoiceId}
      >
        <OfferLayout
          variant={variant}
          priceComponent={
            <PriceComponent
              hotelId={hotelId}
              anchorPriceTotal={anchorPriceTotal}
              anchorPrice={anchorPrice}
              offer={offer}
              showSignInToUnlock={showSignInToUnlock}
              variant={variant}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
              showGHAPreferredRateTotalPrice={
                isMobile && shouldShowGhaOrGvrPreferredRateTotalPrice
              }
              showPriceIndication={showPriceIndication}
            />
          }
          providerLogoComponent={
            <ProviderLogoComponent
              variant={variant}
              isAppLockedDeal={isAppLockedDeal}
              offer={offer}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
            />
          }
          roomLabelsComponent={
            <RoomLabelsComponent
              variant={variant}
              hasRoomLabels={hasRoomLabels}
              showSignInToUnlock={showSignInToUnlock}
              offer={offer}
              hotelId={hotelId}
              isAppLockedDeal={isAppLockedDeal}
            />
          }
          ctaComponent={
            <CtaComponent
              variant={variant}
              hotelId={hotelId}
              offer={offer}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
            />
          }
          yourChoiceComponent={
            shouldShowYourChoice && (
              <>
                {!isMobile && shouldShowGhaOrGvrPreferredRateTotalPrice && (
                  <>
                    <GHAPreferredRateTotalPrice
                      value={offer.totalRate}
                      currency={currencyCode as string}
                      hasUnderline
                    />
                  </>
                )}
                <YourChoiceAndFreeCancelationLabels
                  offer={offer}
                  shouldShowFreeCancellation={
                    !isMobile && shouldShowGhaOrGvrPreferredRateTotalPrice
                  }
                />
              </>
            )
          }
        />
      </Box>
    </OfferInteraction>
  )
}
