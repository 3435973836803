import {createSlice} from '@reduxjs/toolkit'

import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface GemsBottomSheetState {
  hasDiscount?: boolean
  marketPrice?: number
  yourPrice?: number
  percentage?: number
  offer?: SearchOffer
  offerPosition?: number
  hotelId?: string
  isBoVio?: boolean
}

export const initialState: GemsBottomSheetState = {
  hasDiscount: undefined,
  marketPrice: undefined,
  yourPrice: undefined,
  percentage: undefined,
  offer: undefined,
  offerPosition: undefined,
  hotelId: undefined,
  isBoVio: undefined
}

const {actions, reducer} = createSlice({
  name: 'gemsBottomSheet',
  initialState,
  reducers: {
    setGemsBottomSheet: (state, {payload}) => ({
      ...state,
      ...payload
    }),
    handleOnClose: state => ({
      ...state,
      isOpen: false
    })
  }
})

export default reducer

export const {setGemsBottomSheet, handleOnClose} = actions
