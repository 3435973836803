import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {v4 as uuid} from 'uuid'

import {setAnalyticsLeadContext} from '../../modules/slice'
import {LeadContextRequiredData} from './type'

interface Props extends LeadContextRequiredData {
  offerId: string
}

/**
 * In a normal booking flow, when a user clicks on a deal, they are redirected via
 * a redirection service. This service generates a 'redirectId' and forwards it to analytics.
 * However, in the freeze flow on SRP/AP, there isn't
 * a redirection happening. Consequently, we need to generate the 'redirectId' here and send it
 * to analytics ourselves.
 *
 * Additionally, there is no 'leadContext' in the client package, and 'redirectId' is essential
 * for creating one. The following code provides a workaround to handle this situation.
 */
export const useCreateAnalyticsLeadContext = ({
  offerId,
  intermediaryProvider,
  providerRateType,
  trackProviderRedirect
}: Props) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    if (!trackProviderRedirect || !intermediaryProvider) return
    const redirectId = uuid()
    dispatch(
      trackProviderRedirect({
        offerId: offerId,
        intermediaryProvider: intermediaryProvider,
        redirectId
      })
    )
    dispatch(setAnalyticsLeadContext({redirectId, providerRateType}))
  }, [
    trackProviderRedirect,
    intermediaryProvider,
    dispatch,
    offerId,
    providerRateType
  ])
}
