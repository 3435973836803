import {SearchType} from 'modules/sapiSearch/utils'
import {length} from 'ramda'
import {getUserSearchHistory} from 'utils/persistence'

import {
  SuggestionItemDataType,
  SuggestionSectionDataType
} from '@daedalus/core/src/autocomplete/types'
import {Suggestion} from '@findhotel/sapi'

const MAX_HISTORY_FOR_AREAS = 3

/**
 * Map user search history to suggestion item
 */
const mapHistoryItemToSuggestion = ({
  historyId,
  searchParams,
  searchData
}): SuggestionItemDataType => {
  const {
    objectDisplayName,
    objectID,
    objectType,
    objectFullName,
    objectPlaceTypeName,
    placeType,
    objectParentName,
    hotelName,
    placeName
  } = searchData
  const {checkIn, checkOut, rooms, address} = searchParams

  return {
    historyId,
    isUserSearchHistory: true,
    objectName:
      objectFullName ?? objectDisplayName ?? hotelName ?? placeName ?? address, // To support SAPI format
    objectDisplayName,
    objectPlaceTypeName,
    objectParentName,
    placeType,
    objectID: objectID.replace(`${objectType}:`, ''),
    objectType,
    checkIn,
    checkOut,
    rooms
  }
}

/**
 * Build suggestions from user search history
 */
export const buildUserSuggestions = (
  searchType: SearchType = SearchType.Unknown
): SuggestionSectionDataType[] => {
  const suggestions = []
  const userSearchHistory =
    searchType === SearchType.Area
      ? getUserSearchHistory().slice(0, MAX_HISTORY_FOR_AREAS)
      : getUserSearchHistory().slice(1)

  if (length(userSearchHistory)) {
    const historySuggestions = userSearchHistory.map(mapHistoryItemToSuggestion)

    suggestions.push({
      type: 'userSearchHistorySuggestions',
      suggestions: historySuggestions
    })
  }

  return suggestions
}

export const buildSapiSuggestions = (
  response: Suggestion[],
  shouldLoadHistory: boolean,
  hideApiSuggestionsForEmptySearch: boolean,
  searchType: SearchType = SearchType.Unknown
): SuggestionSectionDataType[] => {
  const suggestions: SuggestionSectionDataType[] = []

  if (shouldLoadHistory) {
    // Keep the current search in the history for Area searches
    const userSearchHistory =
      searchType === SearchType.Area || searchType === SearchType.Location
        ? getUserSearchHistory().slice(0, MAX_HISTORY_FOR_AREAS)
        : getUserSearchHistory().slice(1)

    if (length(userSearchHistory)) {
      const historySuggestions = userSearchHistory.map(history =>
        mapHistoryItemToSuggestion(history)
      )

      suggestions.push({
        type: 'userSearchHistorySuggestions',
        suggestions: historySuggestions
      })
    }
  }

  if (!hideApiSuggestionsForEmptySearch) {
    if (length(response)) {
      suggestions.push({
        type: 'apiSuggestions',
        suggestions: response.map(item => {
          return {
            isUserSearchHistory: false,
            objectID: item.objectID,
            objectName: item.highlightValue,
            objectDisplayName: item.value,
            objectParentName: item.placeDisplayName,
            objectPlaceTypeName: item.placeTypeName,
            objectType: item.objectType,
            filters: item.filters,
            checkIn: item.checkIn,
            checkOut: item.checkOut,
            rooms: item.rooms,
            isFreeTextSearch:
              item.objectType !== 'address' &&
              (Boolean(item.filters) ||
                Boolean(item.checkIn) ||
                Boolean(item.checkOut))
          }
        })
      })
    }
  }

  return suggestions
}
