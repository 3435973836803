import {ReactNode} from 'react'
import {useSelector} from 'react-redux'
import {useDebugPanelToggles} from '@vio/debug-panel'
import {withUrlQueryString} from 'components/data/withUrlQueryString'
import {
  getAnonymousId,
  getCurrencyCode,
  getDeviceCategory,
  getDevicePlatform,
  getLocaleCode,
  getUserCountryCode
} from 'modules/meta/selectors'
import {adjust, curry, fromPairs, map, pickBy, toPairs} from 'ramda'
import {getTrafficSource, hasDates, hasDefaultDates} from 'utils/trafficSource'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {getUrlParams} from '@daedalus/core/src/_web/utils/url'
import {getUserDeviceDetails} from '@daedalus/core/src/_web/utils/userAgent'
import {useAddFeatureFlagTracking} from '@daedalus/core/src/analytics/hooks/useAddFeatureFlagTracking'
import {
  ExperimentPackageName,
  useTrackServerABTestSource
} from '@daedalus/core/src/analytics/hooks/useTrackServerABTestSource'
import {
  selectHasVioEmail,
  selectIsAuthenticated,
  selectIsEmployee,
  selectIsMemberPlus
} from '@daedalus/core/src/auth/modules/selectors'
import {useOpticks} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {useOpticksAudienceSegmentation} from '@daedalus/core/src/experiments/hooks/useOpticksAudienceSegmentation'
import {
  getMobileAppVersion,
  isReactNativeWebView
} from '@daedalus/core/src/native'
import {isMetaProfile} from '@daedalus/core/src/profile'
import {parseQueryString} from '@daedalus/core/src/utils/url'

import type {AudienceSegmentationAttributes} from '@daedalus/core/src/experiments/types'

type PropsType = {
  queryString: string
  forcedToggles?: Record<string, string>
  children: ReactNode
}

// MapKeys :: (String -> String) -> Object -> Object

const mapKeys = curry((fn, object) =>
  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  fromPairs(map(adjust(0, fn), toPairs(object)))
)

/***
  Uses special experiment flags in the URL to force a side of an A/B test.
  Looks for ab_experimentId=experimentVariation, and returns an object in the
  form of { experimentId: experimentId }.
  For example ab_foo=a&ab_bar=b would yield: { foo: false, bar: true }
*/
const getForcedTogglesFromSearchQuery = (
  url: string
): Record<string, string | null | undefined> => {
  const FORCE_PREFIX = 'ab_'
  const isForceToggleParameter = (__: string, key: string) =>
    key.startsWith(FORCE_PREFIX)
  const removeForcePrefix = (k: string) => k.split(FORCE_PREFIX)[1]

  const forcedToggleParameters = pickBy(
    isForceToggleParameter,
    parseQueryString(url)
  )

  return mapKeys(removeForcePrefix, forcedToggleParameters)
}

const useSegmentationAttributes = (
  queryString: string
): AudienceSegmentationAttributes => {
  const searchParameters = parseQueryString(queryString)
  const {brandCode} = useBrand()
  const {utm_source, profile} = searchParameters

  return {
    application: 'search',
    brandCode,
    deviceCategory: useSelector(getDeviceCategory),
    devicePlatform: useSelector(getDevicePlatform),
    hasLandingDates: hasDates(searchParameters),
    hasPrefilledDates: hasDefaultDates(searchParameters),
    hasVioEmail: useSelector(selectHasVioEmail),
    isEmployee: useSelector(selectIsEmployee),
    isMetaTrafficProfile: isMetaProfile(profile as string),
    locale: useSelector(getLocaleCode),
    trafficSource: getTrafficSource(utm_source as string, undefined),
    userCountry: useSelector(getUserCountryCode)
  }
}

const urlParams = getUrlParams()

const InitializeABTesting = ({
  queryString,
  children,
  forcedToggles
}: PropsType) => {
  const Opticks = useOpticks()
  const anonymousId = useSelector(getAnonymousId)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isMemberPlus = useSelector(selectIsMemberPlus)
  const currency = useSelector(getCurrencyCode)
  const isWebView = isReactNativeWebView()

  const mobileAppVersion = getMobileAppVersion()

  const {applyLocalstorageToggles} = useDebugPanelToggles({
    params: location.search
  })

  const opticksAttributes = useSegmentationAttributes(queryString)
  // Added as part of feature-static-srp flag
  const {userAgentDetails} = getUserDeviceDetails()

  const audienceSegmentationAttributes: AudienceSegmentationAttributes = {
    ...opticksAttributes,
    isAuthenticated,
    isMemberPlus,
    isWebView,
    currency,
    mobileAppVersion,
    profile: typeof urlParams.profile === 'string' ? urlParams.profile : '',
    pathName: location.pathname,
    // Added as part of feature-static-srp flag
    userAgent: userAgentDetails.source
  }

  useOpticksAudienceSegmentation(audienceSegmentationAttributes)

  useTrackServerABTestSource(ExperimentPackageName.Search)
  useAddFeatureFlagTracking()

  if (anonymousId && Opticks) {
    Opticks.setUserId(anonymousId)
    Opticks.setAudienceSegmentationAttributes(audienceSegmentationAttributes)

    const forcedTogglesFromQuery = getForcedTogglesFromSearchQuery(queryString)

    const allForcedToggles = applyLocalstorageToggles({
      ...forcedToggles,
      ...forcedTogglesFromQuery
    })

    if (allForcedToggles) {
      Opticks.forceToggles(allForcedToggles)
    }

    // Test to make sure our AB testing infrastructure is working as intended
    Opticks.toggle('a-a-experiment')

    return children
  }

  return null
}

export default withUrlQueryString(InitializeABTesting)
