import {useEffect} from 'react'

import {
  MessageListenerTypes,
  NotificationStatusTypes,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {safeJsonParse} from '@daedalus/core/src/utils/object'

interface MessageData {
  data: {
    type: string
    payload: {
      status: NotificationStatusTypes
      hotelId: string
      flowType?: string
    }
  }
}

interface EventListener {
  (evt: Event): void
}

type UsePriceWatchHandleTurnOnPriceWatchOnApp = {
  initPriceWatch: (flowType?: string) => void
  hotelId?: string
}

export const useHandleEnablePriceWatchOnNativeApp = ({
  initPriceWatch,
  hotelId
}: UsePriceWatchHandleTurnOnPriceWatchOnApp) => {
  useEffect(() => {
    const messageListener = (
      nativeEvent: MessageEvent<MessageData['data']>
    ) => {
      // NOTE: Repeated initialization of usePriceWatchToggleWebViewLogic causes multiple watchers on USER_ENABLED_PRICE_WATCH_ON_NATIVE, triggering initPriceWatch multiple times.
      nativeEvent.stopImmediatePropagation()
      const parsedEvent = safeJsonParse<MessageData['data']>(nativeEvent.data)

      if (
        parsedEvent.type !==
        RNWebViewMessageTypes.USER_ENABLED_PRICE_WATCH_ON_NATIVE
      )
        return

      // NOTE: for the SRPScroll scenario, the card is not rendered and then it won't have the hotelId to compare with and init the price watch
      if (parsedEvent.payload.hotelId !== hotelId) return

      if (parsedEvent.payload.status === NotificationStatusTypes.GRANTED) {
        initPriceWatch(parsedEvent.payload.flowType)
      }
    }

    // Android sends via document
    // IOS sends via window
    ;[document, window].forEach(receiver =>
      receiver.addEventListener(
        MessageListenerTypes.POST_MESSAGE,
        messageListener as EventListener
      )
    )

    postMessageToWebView({
      type: RNWebViewMessageTypes.GET_PRICE_WATCH_PUSH_NOTIFICATION_STATUS
    })

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[document, window].forEach(receiver =>
        receiver.removeEventListener(
          MessageListenerTypes.POST_MESSAGE,
          messageListener as EventListener
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initPriceWatch, hotelId])
}
