import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {differenceInDays, parseISO} from 'date-fns'
import {
  getAnchorComplete,
  getHotelHasOffers,
  getSearchParameters,
  State as SapiSearchState
} from 'modules/sapiSearch/selectors'
import useSapiSearch from 'modules/sapiSearch/useSapiSearch'
import {toggle} from 'opticks'

interface Params {
  isAnchor: boolean
  hotelId: string
}

export const useGetHotelAvailabilityForCurrentSearch = ({
  isAnchor,
  hotelId
}: Params) => {
  const {useGetHotelAvailability} = useSapiSearch()
  const searchParameters = useSelector(getSearchParameters)
  const {checkIn, checkOut} = searchParameters || {}
  const {getHotelAvailabilityForCurrentSearch} = useGetHotelAvailability({
    hotelId
  })
  const anchorComplete = useSelector(getAnchorComplete)
  const hotelHasOffers = useSelector((state: SapiSearchState) =>
    getHotelHasOffers(state, hotelId)
  )

  const shouldFetchData =
    toggle('feature-static-srp', isAnchor, false) &&
    toggle('feature-hotel-availability', false, true)

  const isOffersUnavailable = anchorComplete && !hotelHasOffers && isAnchor

  const nights =
    checkIn &&
    checkOut &&
    differenceInDays(
      parseISO(searchParameters.checkOut),
      parseISO(searchParameters.checkIn)
    )

  useEffect(() => {
    // We request availability only if the hotel has no offers and the anchor is complete
    if (isOffersUnavailable && nights > -1 && shouldFetchData) {
      getHotelAvailabilityForCurrentSearch()
    }
  }, [isOffersUnavailable, shouldFetchData])
}
