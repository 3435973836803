import React, {memo, ReactElement, ReactNode, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {usePriceWatchMobileAppBottomSheet} from 'components/PriceWatch/hooks/usePriceWatchMobileAppBottomSheet'
import {usePriceWatchWebOverlayTrigger} from 'components/PriceWatch/hooks/usePriceWatchWebOverlayTrigger'
import {
  getParametrizedHotelContext,
  getSearchContext
} from 'middleware/analytics/selectors'
import {
  getCurrencyCode,
  getLocaleCode,
  getLocalTaxesIncluded,
  getTaxesIncluded
} from 'modules/meta/selectors'
import {showOverlay} from 'modules/overlay/slice'
import {OverlayType} from 'modules/overlay/types'
import {
  getAbsoluteHotelPosition,
  getHotelIdsToDisplayIncludingAnchor,
  getNumberOfGuests,
  getNumberOfRooms,
  getOfferEntity,
  getSearchParametersCheckIn,
  getSearchParametersCheckOut,
  getSearchParametersRooms,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'
import {toggle} from 'opticks'
import Settings from 'Settings'
import {
  getCheapestOfferTotalRate,
  getCheapestTopOfferByNightlyRate
} from 'utils/offers'

import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {PriceWatchCTA} from '@daedalus/shared/src/priceWatch/components/PriceWatchCTA'
import {PriceWatchCTAParams} from '@daedalus/shared/src/priceWatch/types'

import {ALERTS_ROUTE} from '../../constants'

import type {ContentHotel, Hotel} from '@findhotel/sapi'
interface HoldOutGroupWithExperimentProps {
  holdOutElement: ReactNode | null
  element: ReactNode | null
}

const HoldOutGroupWithExperiment = ({
  holdOutElement,
  element
}: HoldOutGroupWithExperimentProps) => {
  return toggle(
    'a87807d8-price-watch-toggle-holdout',
    holdOutElement || null,
    () => element
  ) as ReactElement
}

const HoldOutGroupWithExperimentMemo = memo(HoldOutGroupWithExperiment)

interface Props {
  hotel?: Hotel | ContentHotel | undefined
  holdOutElement: ReactNode
  ctaComponent: PriceWatchCTAParams['ctaComponent']
  shouldPulse?: boolean
  searchIsCompleted: boolean
  componentName: string
}

const MINIMUM_NUMBER_OF_HOTELS = 6

export const PriceWatchCTAWrapper = ({
  hotel,
  holdOutElement,
  searchIsCompleted,
  ctaComponent,
  shouldPulse = true,
  componentName
}: Props) => {
  const dispatch = useDispatch()
  const {openPriceWatchOverlayOnScroll} = usePriceWatchWebOverlayTrigger()
  const {openPriceWatchBottomSheetOnScroll} =
    usePriceWatchMobileAppBottomSheet()

  const priceWatchURL = `${Settings.get('MEMBERSHIP_API_ENDPOINT')}price-watch`
  const currencyCode = useSelector(getCurrencyCode)
  const locale = useSelector(getLocaleCode)
  const localTaxesIncluded = useSelector(getLocalTaxesIncluded)
  const taxesIncluded = useSelector(getTaxesIncluded)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const hotelContext = getParametrizedHotelContext(hotel?.objectID)
  const checkIn = useSelector(getSearchParametersCheckIn)
  const checkOut = useSelector(getSearchParametersCheckOut)
  const rooms = useSelector(getSearchParametersRooms)

  const roomsNumber = useSelector(state =>
    getNumberOfRooms(state, hotel?.objectID)
  )
  const guests = useSelector(getNumberOfGuests)
  const offerEntity = useSelector(state =>
    getOfferEntity(state, hotel?.objectID)
  )
  const isOnHotelDetailsOverlay = window.history.state?.state?.overlay?.some(
    overlay => overlay.type === OverlayType.HotelDetails
  )

  const cheapestOffer = getCheapestTopOfferByNightlyRate(offerEntity?.offers)
  const cheapestTotalRate = getCheapestOfferTotalRate(offerEntity?.offers)
  const shouldShowTotal = useSelector(getShowTotalPrices)

  const rate = {
    baseRate: cheapestOffer?.rate.base,
    localTaxes: cheapestOffer?.rate.hotelFees,
    taxes: cheapestOffer?.rate.taxes,
    nightlyRate: cheapestOffer?.nightlyRate,
    totalRate: cheapestTotalRate,
    localTaxesIncluded,
    taxesIncluded,
    currency: cheapestOffer?.currency,
    shouldShowTotal: shouldShowTotal
  }

  const shouldShowCta = hotel && cheapestOffer && searchIsCompleted

  const setWebBottomSheetOpen = () => {
    dispatch(
      showOverlay({
        type: OverlayType.PriceWatchOverlay,
        params: {
          hotel,
          rate,
          flow: componentName
        }
      })
    )
  }

  const numberOfHotels = useSelector(getHotelIdsToDisplayIncludingAnchor).length
  const hotelPosition = useSelector(state =>
    getAbsoluteHotelPosition(state, hotel?.objectID)
  )

  const hotelPositionTrigger = hotelPosition + 1 === numberOfHotels

  const {ref: ToggleRef, inView} = useInView({
    threshold: 1,
    skip: !hotelPositionTrigger
  })

  useEffect(() => {
    if (
      hotelPositionTrigger &&
      inView &&
      numberOfHotels >= MINIMUM_NUMBER_OF_HOTELS
    ) {
      if (isReactNativeWebView()) {
        openPriceWatchBottomSheetOnScroll()
        return
      }

      openPriceWatchOverlayOnScroll()
    }
  }, [
    hotelPositionTrigger,
    inView,
    openPriceWatchOverlayOnScroll,
    openPriceWatchBottomSheetOnScroll
  ])

  if (!shouldShowCta) return null

  return (
    <HoldOutGroupWithExperimentMemo
      holdOutElement={holdOutElement}
      element={
        <span ref={ToggleRef}>
          <PriceWatchCTA
            checkIn={checkIn}
            checkOut={checkOut}
            hotel={hotel}
            rooms={rooms}
            roomsNumber={roomsNumber}
            currency={currencyCode}
            locale={locale}
            rate={rate}
            priceWatchURL={priceWatchURL}
            getSearchContext={getSearchContext}
            hotelContext={hotelContext}
            isAuthenticated={Boolean(isAuthenticated)}
            roomName={cheapestOffer?.roomName}
            ctaComponent={ctaComponent}
            componentName={componentName}
            shouldRedirectToAp={isOnHotelDetailsOverlay}
            guests={guests}
            setWebBottomSheetOpen={setWebBottomSheetOpen}
            shouldPulse={shouldPulse}
            alertsManagementUrl={ALERTS_ROUTE}
          />
        </span>
      }
    />
  )
}
