// Added as part of 52078012-speech-to-search
import {SearchUrlParams} from 'modules/search/types'
import {isNil, reject} from 'ramda'

import {Suggestion} from '@findhotel/sapi'

export type SpeechToSearchState =
  | 'recording'
  | 'transcription'
  | 'without-suggestion-result'
  | 'searching'
  | 'without-permission'
  | 'initial'
  | 'silence'

export interface SpeechToSearchDetails {
  text: string
  hint?: string
  state: SpeechToSearchState
}

export const getSearchTypeParameter = (type: string, value: string) => {
  switch (type) {
    case 'place':
      return {placeId: value}
    case 'address':
      return {address: value}
    case 'hotel':
      return {hotelId: value}
    default:
      return {}
  }
}

export const getFilterValue = (
  value:
    | [
        {
          id: number
          value: string
        }
      ]
    | string
    | number
    | undefined
) => {
  if (Array.isArray(value)) {
    return value.map(({id}) => `${id}`)
  } else if (value) {
    return [`${value}`]
  } else {
    return undefined
  }
}

export const isValidFreeTextSearch = (params: Partial<SearchUrlParams>) =>
  Boolean(params.placeId || params.hotelId) || params.userLocationSearch === 1

export const getSearchUrlParams = (
  suggestion: Partial<Suggestion> | undefined
): Partial<SearchUrlParams> => {
  if (!suggestion) return {}
  const {filters, objectID, objectType, checkIn, checkOut, rooms} = suggestion
  const params: Partial<SearchUrlParams> = {
    ...getSearchTypeParameter(objectType, objectID),
    userLocationSearch: objectType === 'nearby' ? 1 : undefined,
    checkIn: checkIn ?? undefined,
    checkOut: checkOut ?? undefined,
    priceMax: filters?.priceMax ? Number(filters.priceMax) : undefined,
    priceMin: filters?.priceMin ? Number(filters.priceMin) : undefined,
    starRatings: getFilterValue(filters?.starRating),
    facilities: getFilterValue(filters?.facilities),
    themes: getFilterValue(filters?.themes),
    propertyTypes: getFilterValue(filters?.propertyTypes),
    rooms: rooms ?? undefined
  }
  return reject(isNil, params)
}

const getSpeechToSearchState2 = ({
  isMicrophoneAvailable = false,
  listening = false,
  transcript = '',
  isVoiceDetected = false
}): SpeechToSearchState => {
  if (!isMicrophoneAvailable) return 'without-permission'
  else if (!listening && !transcript) {
    if (!isVoiceDetected && !listening && !transcript) return 'silence'
    return 'without-suggestion-result'
  } else if (!listening && transcript) return 'searching'
  else if (listening && transcript) return 'transcription'
  else if (listening) return 'recording'
}

export const getSpeechToSearchDetails = ({
  isMicrophoneAvailable = false,
  listening = false,
  transcript = '',
  isVoiceDetected = false
}): SpeechToSearchDetails => {
  const state = getSpeechToSearchState2({
    isMicrophoneAvailable,
    isVoiceDetected,
    listening,
    transcript
  })
  switch (state) {
    case 'without-permission':
      return {
        text: 'Allow microphone access to search with voice',
        hint: 'Go to your browser settings and allow access to microphone',
        state
      }
    case 'silence':
      return {
        text: 'Didn’t hear that. Try again',
        hint: 'Tap the microphone to try again',
        state
      }
    case 'transcription':
      return {
        text: transcript + '...',
        state
      }
    case 'searching':
      return {
        text: transcript,
        state
      }
    case 'without-suggestion-result':
      return {
        text: `Oops.. that didn't work. Please try again.`,
        state
      }
    default:
      return {
        text: 'Speak now',
        state
      }
  }
}
