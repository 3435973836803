import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  useSearchBox,
  useSearchBoxDatePicker,
  useSearchBoxGuestPicker
} from 'hooks/searchBox'
import {getAccommodationPageHotelId} from 'modules/accommodation/selectors'
import {
  getDateGuestPickerOverlay,
  getIsHotelDatePickerOpen,
  getIsHotelGuestPickerOpen,
  getOverlay
} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {
  DateGuestPickerSource,
  HotelDatePickerOverlayInfo,
  HotelGuestPickerOverlayInfo,
  OverlayType
} from 'modules/overlay/types'
import {
  getOpenDatePickerType,
  getSearchBoxValues
} from 'modules/searchBox/selectors'
import {closeSearchBox, setSearchBoxValues} from 'modules/searchBox/slice'
import {SearchBoxValues, SearchTrigger} from 'modules/searchBox/types'

import {ANIMATION_TYPES} from '@daedalus/atlas/Overlay'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {ConfigureI18nContext} from '@daedalus/core/src/localization/components/ConfigureI18n'
import {
  roomsSplitToString,
  roomsToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {
  RoomConfigType,
  RoomSplitType
} from '@daedalus/core/src/room/types/RoomConfiguration'
import {WithColorPriceDayData} from '@daedalus/core/src/search/modules/ColorPriceCalendar/components/WithColorPriceDayData'
import {usePreviousValue} from '@daedalus/core/src/utils/react/hooks/usePreviousValue'
import {DatePickerOverlay} from '@daedalus/shared/src/search/DatePickerOverlay'
import {GuestRoomConfigOverlay} from '@daedalus/shared/src/search/GuestRoomConfig/GuestRoomConfigOverlay'

// Hook that triggers a search *after* the picker overlay closes (and it's browser history entry is removed)
// this allows us to replace the AP and ensure browser-back goes directly to SRP
const useDeferredSearch = () => {
  const overlay = useSelector(getOverlay)
  const currentHotelId = useSelector(getAccommodationPageHotelId)
  const isOpenedDatePicker = useSelector(getIsHotelDatePickerOpen)
  const isOpenedGuestPicker = useSelector(getIsHotelGuestPickerOpen)

  const pendingSearchRef = useRef<null | SearchTrigger>(null)
  const pickerIsOpen = isOpenedDatePicker || isOpenedGuestPicker
  const pickerWasClosed = usePreviousValue(pickerIsOpen) && !pickerIsOpen

  const setSearchPending = useCallback((value: null | SearchTrigger) => {
    pendingSearchRef.current = value
  }, [])

  const {doSearch} = useSearchBox()
  useEffect(() => {
    if (pickerWasClosed && pendingSearchRef.current) {
      doSearch({
        searchTrigger: pendingSearchRef.current,
        searchOptions: {
          method: 'replace',
          state: {}
        },
        newUrlParams: {
          placeId: undefined,
          boundingBox: undefined,
          userLocationSearch: undefined,
          hotelId: currentHotelId
        }
      })

      setSearchPending(null)
    }
  }, [overlay, pickerWasClosed, setSearchPending, doSearch])

  return setSearchPending
}

const searchTriggerMap = {
  [DateGuestPickerSource.AccommodationPageSearch]: {
    [OverlayType.HotelDatePicker]: SearchTrigger.AccommodationPageDatePicker,
    [OverlayType.HotelGuestPicker]: SearchTrigger.AccommodationPageGuestPicker
  },
  [DateGuestPickerSource.HotelViewRoomsSection]: {
    [OverlayType.HotelDatePicker]:
      SearchTrigger.AccommodationPageRoomsDatePicker,
    [OverlayType.HotelGuestPicker]:
      SearchTrigger.AccommodationPageRoomsGuestPicker
  }
}

export const DateGuestOverlayContainer = () => {
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()

  const {type, source} = useSelector(getDateGuestPickerOverlay) as
    | HotelDatePickerOverlayInfo
    | HotelGuestPickerOverlayInfo

  const searchTrigger = searchTriggerMap[source]?.[type]

  const {monthNames, weekDayNamesShort, firstDayOfWeek} =
    useContext(ConfigureI18nContext)

  const isOpenedDatePicker = useSelector(getIsHotelDatePickerOpen)
  const isOpenedGuestPicker = useSelector(getIsHotelGuestPickerOpen)
  const openedDatePickerType = useSelector(getOpenDatePickerType)

  const {checkIn, checkOut, rooms, optimizeRooms} =
    useSelector(getSearchBoxValues)

  const previousIsOpen = usePreviousValue(
    isOpenedDatePicker || isOpenedGuestPicker
  )
  const [initialValues, setInitialValues] =
    useState<Pick<SearchBoxValues, 'checkIn' | 'checkOut' | 'rooms'>>()
  useEffect(() => {
    const isOpen = isOpenedDatePicker || isOpenedGuestPicker
    if (!previousIsOpen && isOpen) {
      setInitialValues({
        checkIn,
        checkOut,
        rooms
      })
    }
  }, [isOpenedDatePicker, isOpenedGuestPicker])

  useEffect(() => {
    const isOpen = isOpenedDatePicker || isOpenedGuestPicker
    if (previousIsOpen && !isOpen) {
      dispatch(setSearchBoxValues(initialValues))
    }
  }, [isOpenedDatePicker, isOpenedGuestPicker])

  const {roomsSplit} = roomsToConfigurationObject(rooms)

  const {onDateChange, onDatePickerDayClick, onDatePickerOpen} =
    useSearchBoxDatePicker()
  const {
    onGuestRoomsChange,
    onGuestPickerOptionInfoDisplayed,
    onGuestConfigPreferenceClick,
    onGuestOptimizeRoomsChange
  } = useSearchBoxGuestPicker()
  const setPendingSearch = useDeferredSearch()

  const onGuestPickerOverlaySubmit = useCallback(
    (roomsSplit: RoomSplitType[]): void => {
      const rooms = roomsSplitToString(roomsSplit)

      dispatchTeamEvent(
        trackEvent({
          category: Category.User,
          entity: Entity.GuestPickerSubmitButton,
          action: Action.Clicked
        })
      )

      onGuestRoomsChange(rooms)

      setPendingSearch(searchTrigger)
      dispatch(hideOverlay())
      dispatch(closeSearchBox())
    },
    [
      dispatch,
      dispatchTeamEvent,
      setPendingSearch,
      onGuestRoomsChange,
      searchTrigger
    ]
  )

  const onDatePickerOverlaySubmit = useCallback((): void => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.DatePickerSubmitButton,
        action: Action.Clicked
      })
    )

    setPendingSearch(searchTrigger)
    dispatch(hideOverlay())
    dispatch(closeSearchBox())
  }, [dispatch, dispatchTeamEvent, setPendingSearch, searchTrigger])

  const closeDatePicker = () => {
    dispatch(hideOverlay())
  }

  const closeGuestPicker = () => {
    dispatch(hideOverlay())
  }

  const handleGuestOptimizeRoomsChange = useCallback(
    (isOptimizeRooms: boolean): void => {
      onGuestOptimizeRoomsChange(isOptimizeRooms)
    },
    [onGuestOptimizeRoomsChange]
  )

  const handleGuestRoomsChange = useCallback(
    (roomsSplit: RoomSplitType[]): void => {
      const rooms = roomsSplitToString(roomsSplit)
      onGuestRoomsChange(rooms)
    },
    [onGuestRoomsChange]
  )

  return (
    <>
      <DatePickerOverlay
        isOpen={isOpenedDatePicker}
        checkIn={checkIn}
        checkOut={checkOut}
        firstDayOfWeek={firstDayOfWeek}
        months={monthNames}
        openedDatePickerType={openedDatePickerType}
        weekdaysShort={weekDayNamesShort}
        onChange={onDateChange}
        onOpen={onDatePickerOpen}
        onCheckOutSelected={() => console.log}
        onDayClick={onDatePickerDayClick}
        onSubmit={onDatePickerOverlaySubmit}
        onClose={closeDatePicker}
        animationType={ANIMATION_TYPES.NONE}
        DayInnerContainer={WithColorPriceDayData}
      />
      <GuestRoomConfigOverlay
        isOpen={isOpenedGuestPicker}
        roomsSplit={roomsSplit}
        onClose={closeGuestPicker}
        onRoomsChange={handleGuestRoomsChange}
        onOptimizeRoomsChange={handleGuestOptimizeRoomsChange}
        onSubmit={onGuestPickerOverlaySubmit}
        onInfoOpen={onGuestPickerOptionInfoDisplayed}
        onGuestConfigOptionClick={onGuestConfigPreferenceClick}
        roomConfigType={
          optimizeRooms ? RoomConfigType.Optimized : RoomConfigType.Manual
        }
        animationType={ANIMATION_TYPES.NONE}
      />
    </>
  )
}
