import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useConditionalEffect} from 'hooks/useConditionalEffect'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getPriceFilterVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {searchParamToFacet} from 'modules/search/constants'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {PriceFilterBottomSheetContentLoadable} from './Loadable'

const filterKeys = [
  searchParamToFacet.priceMin.param,
  searchParamToFacet.priceMax.param
]

export const PriceFilterBottomSheet = () => {
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()
  const {formatMessage} = useIntl()
  const {
    submitFilters,
    getFilters,
    clearFilters,
    resetFilters,
    calculateIfFiltersChanged,
    toggleFilters
  } = useFiltersForm(filterKeys, 'price')

  const {priceMin, priceMax} = getFilters()
  const isOpen = useSelector(getPriceFilterVisibility)

  const handleApply = () => {
    if (calculateIfFiltersChanged(getFilters())) {
      submitFilters()
    } else {
      dispatch(hideOverlay())
    }
  }

  const handleClear = () => {
    if (priceMax || priceMin) {
      clearFilters()
    } else {
      dispatch(hideOverlay())
    }
  }

  const handleClose = () => {
    dispatch(hideOverlay())
    resetFilters()
  }

  useConditionalEffect(
    async () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "PriceFilterBottomSheetContent" */
        './PriceFilterBottomSheetContent'
      ),
    isMobile
  )

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={handleClose}
      ignoreSnapPoints
      titleBarProps={{
        centerContent: formatMessage(filterMessages.nightlyPrice)
      }}
      bottomBarContent={
        <Flex.Row
          justifyContent="space-between"
          data-id="priceFilterBottomSheetButtons"
        >
          <Button
            dataId="clearFiltersButton"
            onClick={handleClear}
            variant="secondary"
          >
            <FormattedMessageWrapper id="clear" defaultMessage="Clear" />
          </Button>
          <Button dataId="applyFiltersButton" onClick={handleApply}>
            <FormattedMessageWrapper id="apply" defaultMessage="Apply" />
          </Button>
        </Flex.Row>
      }
    >
      {isOpen && (
        <PriceFilterBottomSheetContentLoadable
          priceMin={priceMin}
          priceMax={priceMax}
          toggleFilters={toggleFilters}
        />
      )}
    </BottomSheet>
  )
}
