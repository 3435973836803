import {Values} from '../../../utils/object/types/Values'

export enum ReferralBusiness {
  YourCampus = 'yourcampus'
}

/**
 * Given a specific business, return if the user should be upgraded to plus
 * This was implemented as part of the Referral Program
 * where some business links can upgrade users to plus
 * @param business the business to be checked, ex: 'goseek'
 */
export const shouldUpgradeToPlusOnBusinessReferral = (business: string) =>
  Object.values(ReferralBusiness).includes(business as ReferralBusiness)

export type ReferralBusinessValues = Values<typeof ReferralBusiness>
