import {setCookie} from '../../utils/cookies'
import {getFirstVisit} from '../userFirstVisit'
import {lastVisitTimestampConfig, visitsCountConfig} from './cookieConfig'
import {getLastVisitTimestamp} from './getLastVisitTimestamp'
import {getVisitsCount} from './getVisitsCount'

export const persistVisitsCount = (): void => {
  const visitsCount = getVisitsCount()
  const now = new Date()
  const firstVisit = getFirstVisit()
  const firstVisitDate = new Date(firstVisit ? firstVisit : now)
  const lastVisitTimestamp = getLastVisitTimestamp()
  const lastVisitDate = new Date(
    lastVisitTimestamp ? lastVisitTimestamp : firstVisitDate
  )
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)
  const initialVisits = firstVisit ? 1 : 0
  let count = visitsCount || initialVisits

  // We only set the cookie if the last visit was more than 24 hours ago or if the user is landing for the first time ever
  if (
    lastVisitDate < twentyFourHoursAgo ||
    (!lastVisitTimestamp && !firstVisit)
  ) {
    count++
    const {
      secure,
      sameSite,
      domain,
      maxAge: expires
    } = visitsCountConfig.options
    setCookie(visitsCountConfig.name, count.toString(), {
      expires,
      secure,
      sameSite,
      domain
    })
    setCookie(lastVisitTimestampConfig.name, now.toISOString(), {
      expires,
      secure,
      sameSite,
      domain
    })
  }
}
