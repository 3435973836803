import React, {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {FrozenDealToast} from 'components/FrozenDealToast'
import {HostelsAlert} from 'components/HostelsAlert'
import {MLosAlert} from 'components/MLosAlert'
import {PriceDifferencePopUp} from 'components/PriceDifferencePopUp'
import {SearchResultAlert} from 'components/SearchResultAlert'
import {SplitBookingInfoBottomSheet} from 'components/SplitBookings/SplitBookingInfoBottomSheet'
import {OfferViewContext, OfferViewType} from 'context/offerViewContext'
import {useConditionalEffect} from 'hooks/useConditionalEffect'
import {
  getAnchorHotelId,
  getIsSearchCompleted
} from 'modules/sapiSearch/selectors'
import {getHotelId} from 'modules/search/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

import {isNativeWebView} from '../../utils/webview'
import {AnchorHotel} from './SearchResultsAnchorHotel'
import {SearchResultsControls} from './SearchResultsControls'
import {SearchResultsList, throttledLazyLoadCheck} from './SearchResultsList'
import useResultsEndSensor from './useResultsEndSensor'

const HostelsAlertWrapped: React.FC = () => {
  const {updateLocation} = useUrlUpdater()
  return <HostelsAlert onChange={updateLocation} />
}

export const SearchResults: React.FC = () => {
  const {isResultsEndVisibilitySensorEnabled, onResultsEndView} =
    useResultsEndSensor()

  const {isMobile, isDesktopXs} = useDeviceLayout()
  const hotelIdFromUrl = useSelector(getHotelId)
  const anchorHotelIdFromState = useSelector(getAnchorHotelId)
  const isSearchCompleted = useSelector(getIsSearchCompleted)
  const isWebView = isNativeWebView()

  /**
   * We need hotelIdFromUrl as that one is available before the search is complete.
   * Without that, we don't know if we need to show the title until the first response
   * from SAPI is available and that means showing the title later, which makes the whole
   * layout shift.
   *
   * As soon as we have the response from SAPI, we can use anchorHotelIdFromState as they can differ
   * sometimes when searching by area / near me.
   */
  const anchorHotelId = anchorHotelIdFromState || hotelIdFromUrl
  const isAnchorScenario = Boolean(anchorHotelId)

  const onVisibilityChange = useCallback(
    isVisible => {
      if (isVisible) onResultsEndView()
    },
    [onResultsEndView]
  )

  useEffect(() => {
    throttledLazyLoadCheck()
  })

  useConditionalEffect(
    async () =>
      import(
        /* webpackChunkName: "AccommodationOverlayContent" */
        /* webpackPrefetch: true */
        'components/HotelDetailsOverlay/Mobile/AccommodationOverlayContent/AccommodationOverlayContent'
      ),
    isMobile
  )

  const isMobileHotelCard = isMobile || isDesktopXs
  return (
    <VisibilitySensor
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // It will be fixed once this PR, https://github.com/joshwnj/react-visibility-sensor/pull/180, has been merged
      partialVisibility="bottom"
      active={isResultsEndVisibilitySensorEnabled}
      onChange={onVisibilityChange}
    >
      <div>
        <MLosAlert />
        <SearchResultAlert />
        {isWebView && isSearchCompleted && <PriceDifferencePopUp />}
        <HostelsAlertWrapped />
        <SearchResultsControls />
        {isAnchorScenario && <AnchorHotel anchorHotelId={anchorHotelId} />}
        <OfferViewContext.Provider value={OfferViewType.SRP}>
          <SearchResultsList />
        </OfferViewContext.Provider>
        {isMobileHotelCard && <SplitBookingInfoBottomSheet />}
        <FrozenDealToast />
      </div>
    </VisibilitySensor>
  )
}
