import {SearchResults} from '@findhotel/sapi/dist/types/packages/core/src/types/search'

import {CombinedHotelAndOffer} from '../../types'

export const getHotelIdsForHotelsWithOffers = ({
  hotelOfferEntities,
  hotelIds
}: SearchResults) => {
  return hotelIds.filter(
    hotelId => hotelOfferEntities?.[hotelId]?.offers?.length
  )
}

export const filterHotelsWithoutOffers = (searchResults: SearchResults) => {
  const hotelIdsForHotelsWithOffers =
    getHotelIdsForHotelsWithOffers(searchResults)

  const hotelObj = {
    ...searchResults,
    hotelEntities: {},
    hotelOfferEntities: {},
    hotelIds: []
  }

  hotelIdsForHotelsWithOffers.forEach(id => {
    hotelObj.hotelEntities[id] = searchResults.hotelEntities[id]
    hotelObj.hotelOfferEntities[id] = searchResults.hotelOfferEntities[id]
    hotelObj.hotelIds.push(id)
  })

  return hotelObj
}

export const combineHotelDetailsAndOffers = ({
  hotelIds = [],
  hotelEntities = {},
  hotelOfferEntities = {}
}: SearchResults): CombinedHotelAndOffer =>
  hotelIds.reduce((result, hotelId) => {
    result[hotelId] = {
      hotelEntity: {...hotelEntities[hotelId]},
      offerEntity: {...hotelOfferEntities?.[hotelId]}
    }
    return result
  }, {})
