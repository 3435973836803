import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useGetCheapestRoom} from 'hooks/useGetCheapestRoom'
import {getRoomContext} from 'middleware/analytics/selectors'
import {getUserCountryCode} from 'modules/meta/selectors'
import {
  getRoomDetailOverlay,
  getRoomDetailOverlayVisibility
} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {
  getNumberOfGuests,
  getNumberOfRooms,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {getFilteredRoomsData} from 'modules/searchApi/selectors'
import {RootState} from 'store'
import {imageProvider} from 'utils/imageProvider'

import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {RoomDetailsOverlay} from '@daedalus/shared/src/accommodation/RoomDetailsOverlay'

import {CompactRoomOffer} from '../Rooms/CompactRoomOffer/CompactRoomOffer'
import {RoomDetailsMountComponent} from '../Rooms/RoomCard/constants'
import {useTrackRoomGallerySwipe} from '../Rooms/RoomCard/useTrackRoomGallerySwipe'
import {RoomDateGuestInfo} from '../Rooms/RoomDateGuestInfo'
import {RoomOffersList} from '../Rooms/RoomOffersList'
import {getRoomVariant} from '../Rooms/RoomVariant/getRoomVariant/getRoomVariant'
import {RoomDetailsVariant} from '../Rooms/RoomVariant/RoomDetailsVariant'
import {FOOTER_OFFER_COMPONENT} from './constants'
import {OverlayCompactOfferFooter} from './OverlayCompactOfferFooter'

export const RoomsDetailOverlayContainer = () => {
  const {ref: offersSectionRef, inView: offersSectionInView} = useInView()
  const roomsDetailsOverlayState = useSelector(getRoomDetailOverlay)
  const isRoomsDetailsOverlayOpen = useSelector(getRoomDetailOverlayVisibility)
  const {hotelId, roomId} = roomsDetailsOverlayState || {}

  const {checkIn, checkOut} = useSelector(getSearchParameters) || {}
  const checkInDate = dateStringToMiddayDate(checkIn)
  const checkOutDate = dateStringToMiddayDate(checkOut)
  const numberOfRooms = useSelector((state: RootState) =>
    getNumberOfRooms(state, hotelId)
  )
  const numberOfGuests = useSelector(getNumberOfGuests)
  const {rooms = [], splitBooking} = useSelector(getFilteredRoomsData) || {}
  const cheapestRoom = useGetCheapestRoom(rooms)

  const roomIndex = rooms?.findIndex(room => room.id === roomId)
  const room = rooms[roomIndex]
  const dispatch = useDispatch()

  const trackGallerySwipe = useTrackRoomGallerySwipe({
    room,
    component: RoomDetailsMountComponent.RoomDetailsOverlay
  })

  const roomContext = useSelector(state => getRoomContext(state, roomId))
  const userCountry = useSelector(getUserCountryCode)

  useEffect(() => {
    if (isRoomsDetailsOverlayOpen)
      dispatch(
        trackEvent({
          category: Category.System,
          action: Action.Displayed,
          entity: Entity.RoomDetailsOverlay,
          team: Team.Select,
          page: Page.Accommodation,
          component: RoomDetailsMountComponent.RoomDetailsOverlay,
          analyticsContext: {
            [AnalyticsContext.RoomContext]: roomContext
          }
        })
      )
  }, [isRoomsDetailsOverlayOpen, dispatch])

  if (!room) return null

  const isSplitBookingAvailable = Boolean(splitBooking)

  const stickyFooter = room ? (
    <OverlayCompactOfferFooter
      offer={room.offers[0]}
      room={room}
      isFooterVisible={!offersSectionInView}
    >
      <CompactRoomOffer
        hotelId={hotelId}
        offer={room.offers[0]}
        roomContext={roomContext}
        room={room}
        component={FOOTER_OFFER_COMPONENT}
      />
    </OverlayCompactOfferFooter>
  ) : null

  return (
    <RoomDetailsOverlay
      isOpen={isRoomsDetailsOverlayOpen}
      room={room}
      onClose={() => dispatch(hideOverlay())}
      imageProvider={imageProvider}
      onGallerySwipe={trackGallerySwipe}
      stickyFooterSlot={stickyFooter}
      userCountry={userCountry}
      roomCardSlot={
        <>
          <RoomDateGuestInfo
            checkIn={checkInDate}
            checkOut={checkOutDate}
            numberOfRooms={numberOfRooms}
            numberOfGuests={numberOfGuests}
          />
          <div ref={offersSectionRef}>
            <RoomDetailsVariant
              offerCount={room.offers.length}
              variant={getRoomVariant({
                isCheapest: cheapestRoom == room,
                isSelected: room.offers.some(offer => offer.isClicked),
                forceDefault: isSplitBookingAvailable
              })}
            >
              <RoomOffersList
                roomContext={roomContext}
                room={room}
                hotelId={hotelId}
                component={RoomDetailsMountComponent.RoomDetailsOverlay}
              />
            </RoomDetailsVariant>
          </div>
        </>
      }
    />
  )
}
