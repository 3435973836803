import React, {useContext, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import {HotelCardDesktop} from 'components/HotelCardDesktop'
import {HotelCardMobile} from 'components/HotelCardMobile'
import {useIsExperimentalNoOffersCardEnabled} from 'components/HotelCardMobile/ExperimentalNoOffersCard/helpers'
import {
  getAnchorComplete,
  getHotel,
  getHotelHasOffers,
  getHotelsHaveStaticPosition,
  getIsSearchCompleted
} from 'modules/sapiSearch/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  HotelContext,
  Page
} from '@daedalus/core/src/analytics/types/Events'

import {HotelCardLoading} from './HotelCardLoading'

/**
 * Component responsible for rendering either the desktop or mobile hotel card
 */
interface Props {
  /** Whether to show the skeleton loader */
  isLoading?: boolean
  /** Whether to show the divider between the cards */
  shouldShowDivider?: boolean
  /** Whether the card is a priority card (card in the first position of the list) */
  isPriorityCard?: boolean
}

export const HotelCard: React.FC<Props> = ({
  isLoading,
  shouldShowDivider = true,
  isPriorityCard = false
}) => {
  const dispatch = useDispatch()

  const hotelsHaveStaticPosition = useSelector(getHotelsHaveStaticPosition)
  const {isAnchor, hotelId} = useContext(HotelCardContext)
  const hasOffers = useSelector(state => getHotelHasOffers(state, hotelId))
  const isAnchorCompleted = useSelector(getAnchorComplete)
  const isSearchCompleted = useSelector(getIsSearchCompleted)
  const hotel = useSelector(state => getHotel(state, hotelId))

  const isExperimentalNoOffersCardEnabled =
    useIsExperimentalNoOffersCardEnabled()

  const shouldHideAnchorHotel = useMemo(
    () => isAnchorCompleted && !hotel && isAnchor,
    [isAnchorCompleted, hotel, isAnchor]
  )

  const showLoading = useMemo(() => {
    if (isLoading || !hotelId) return true
    if (
      !hasOffers &&
      !isAnchor &&
      !hotelsHaveStaticPosition &&
      !isSearchCompleted
    )
      return true

    return false
  }, [hotelsHaveStaticPosition, isAnchor, isLoading, hasOffers, hotelId])

  useEffect(() => {
    if (shouldHideAnchorHotel) {
      const hotelContext = {
        hotelId
      } as HotelContext
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.HotelCard,
          action: Action.Hidden,
          page: Page.Search,
          component: 'HotelCard',
          analyticsContext: {
            [AnalyticsContext.HotelContext]: hotelContext
          }
        })
      )
    }
  }, [shouldHideAnchorHotel, dispatch, hotelId])

  const {isMobile, isDesktopXs} = useDeviceLayout()

  if (showLoading && !isExperimentalNoOffersCardEnabled)
    return <HotelCardLoading />

  if (shouldHideAnchorHotel) return null

  if (isMobile || isDesktopXs)
    return <HotelCardMobile isPriorityCard={isPriorityCard} />

  // Showing Hotel Card Desktop view
  return (
    <HotelCardDesktop
      shouldShowDivider={shouldShowDivider}
      isPriorityCard={isPriorityCard}
    />
  )
}
