import {append, identity, max, min, pick, reduce, sortBy} from 'ramda'

import {AvailabilityHotelEntity} from '../../availability/types'
import {getStringDateMonth} from '../../utils/date'
import {percentile} from '../../utils/number'

export const INITIAL_FETCH_WAIT_TIME = 4000 // ms
export const INITIAL_FETCH_WAIT_TIME_FOR_NO_DATES_TRAFFIC = 0 // ms
export const MAX_DATES_TO_SHOW_DESKTOP = 9 // days
export const MAX_DATES_TO_SHOW_MOBILE = 4 // days

export enum PRICE_TYPE {
  HIGH = 'high',
  NEUTRAL = 'neutral',
  LOW = 'low'
}

const getPrices = (hotelAvailabilityPrices: AvailabilityHotelEntity) => {
  const prices = Object.keys(hotelAvailabilityPrices).map(date => {
    return hotelAvailabilityPrices[date]?.cheapestNightlyRate
  })
  return prices
}

const getMinMaxPrices = (
  hotelAvailabilityPrices: AvailabilityHotelEntity
): {minPrice: number; maxPrice: number} => {
  const prices = getPrices(hotelAvailabilityPrices)
  const minPrice = reduce(min, Infinity, prices) as number
  const maxPrice = reduce(max, -Infinity, prices) as number
  return {minPrice, maxPrice}
}

const CHEAP_PRICE_PERCENTILE = 25
const EXPENSIVE_PRICE_PERCENTILE = 75

const getCurrentMonthPrices = (
  currentDate: string,
  hotelAvailabilityPrices: AvailabilityHotelEntity
) => {
  const currentMonth = getStringDateMonth(currentDate)

  return Object.keys(hotelAvailabilityPrices).reduce((acc: number[], d) => {
    if (currentMonth === getStringDateMonth(d)) {
      return append(hotelAvailabilityPrices[d]?.cheapestNightlyRate, acc)
    }
    return acc
  }, [])
}

export const calculatePriceType = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  price: number,
  date: string
) => {
  const monthPrices = getCurrentMonthPrices(date, hotelAvailabilityPrices)
  const percentileValue = percentile(monthPrices, price)

  const priceType =
    percentileValue < CHEAP_PRICE_PERCENTILE
      ? PRICE_TYPE.LOW
      : percentileValue < EXPENSIVE_PRICE_PERCENTILE
        ? PRICE_TYPE.NEUTRAL
        : PRICE_TYPE.HIGH

  return priceType
}

export const getIsCheapestPrice = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  price: number,
  dates?: string[]
) => {
  const selectedDatesAvailabilityPrices = dates
    ? pick(dates, hotelAvailabilityPrices)
    : hotelAvailabilityPrices
  const {minPrice} = getMinMaxPrices(selectedDatesAvailabilityPrices)

  return Boolean(price === minPrice)
}

const sortAlphabetically = sortBy(identity)

/**
 * Returns the dates to display in the availability pane.
 * Pick dates before and after the check in date, if there are enough available dates.
 * Otherwise, pick dates after the check in date.
 * @param hotelAvailabilityPrices - available prices for the hotel
 * @param checkIn - check in date
 * @param maxDatesToShow - maximum number of dates to show
 * @returns string[] - dates to display
 */
export const getDatesToDisplay = (
  hotelAvailabilityPrices: AvailabilityHotelEntity,
  checkIn: string,
  maxDatesToShow: number
): string[] => {
  const hotelAvailabilityDates = Object.keys(hotelAvailabilityPrices)
  const dates = [...new Set([...hotelAvailabilityDates, checkIn])]
  const sortedDates = sortAlphabetically(dates)
  const checkInIndex = sortedDates.findIndex(d => d === checkIn)
  const datesToDisplay: string[] = []

  Array.from(Array(maxDatesToShow), (_, i) => {
    const index = i + 1
    const nextDate = sortedDates[checkInIndex + index]
    const previousDate = sortedDates[checkInIndex - index]

    // add half the dates before the check in date, if there are enough available dates
    if (previousDate && datesToDisplay.length <= maxDatesToShow / 2) {
      datesToDisplay.push(previousDate)
    }
    // and fill the rest of the array with dates after the check in date
    if (nextDate && datesToDisplay.length < maxDatesToShow) {
      datesToDisplay.push(nextDate)
    }
  })

  return sortAlphabetically(datesToDisplay)
}

export const getAvailabilityStatus = ({
  hotelAvailabilityPrices,
  date,
  searchComplete
}: {
  hotelAvailabilityPrices?: AvailabilityHotelEntity
  date: string
  searchComplete: boolean
}) => {
  const isDateAvailable =
    hotelAvailabilityPrices && Boolean(hotelAvailabilityPrices[date])
  if (isDateAvailable)
    return calculatePriceType(
      hotelAvailabilityPrices,
      hotelAvailabilityPrices[date]?.cheapestNightlyRate,
      date
    )
  if (!searchComplete) return 'loading'
  return 'unavailable'
}
