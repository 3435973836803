export const RATING_EXCLUDED_FROM_DISPLAY = ['overall', 'pricing']
export const RATING_PRIORITY_ORDER = [
  'facilities',
  'cleanliness',
  'rooms',
  'service',
  'location',
  'dining'
]

export enum GuestReviewLayout {
  DEFAULT = 'layoutConfigDefault',
  FULL = 'layoutConfigFull'
}
