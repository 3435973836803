import React from 'react'
import {useSelector} from 'react-redux'
import {OfferCTA} from 'components/Offer/Shared/OfferCTA'
import {SplitBookingBundleType} from 'components/Offer/types'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {getIsLockedDeal} from '@daedalus/core/src/auth/modules/selectors'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface Props {
  hotelId: string
  offer: SearchOffer
  showArrowCTA?: boolean
  splitBookingType?: SplitBookingBundleType
  isRequiredOneTimePasswordFlow?: boolean
}

export const CtaComponent = ({
  hotelId,
  offer,
  showArrowCTA = false,
  splitBookingType,
  isRequiredOneTimePasswordFlow
}: Props) => {
  const isPrivate = useSelector(getIsLockedDeal(offer))
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  if (showArrowCTA) {
    return (
      <Box
        data-id="ArrowIcon"
        hasMirror
        marginLeft="s200"
        color={
          isPrivate || isAppLockedDeal || isRequiredOneTimePasswordFlow
            ? 'content.special.c600'
            : 'content.neutral.c600'
        }
      >
        <Icon name="ChevronRight" size="sm" />
      </Box>
    )
  }

  return (
    <Box data-id="OfferCta" whiteSpace="nowrap" marginLeft="s400">
      <OfferCTA
        showLockIcon={isAppLockedDeal || isRequiredOneTimePasswordFlow}
        isAppLocked={isAppLockedDeal}
        offer={offer}
        splitBookingType={splitBookingType}
        isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
      />
    </Box>
  )
}
