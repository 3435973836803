import React from 'react'
import {useSelector} from 'react-redux'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {getIsLockedDeal} from '@daedalus/core/src/auth/modules/selectors'
import {OfferSecondaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface Props {
  hotelId: string
  offer?: SearchOffer
  variant: OfferSecondaryLayoutVariant
  isRequiredOneTimePasswordFlow?: boolean
}

export const CtaComponent = ({
  hotelId,
  offer,
  variant,
  isRequiredOneTimePasswordFlow
}: Props) => {
  const isPrivateDeal = useSelector(getIsLockedDeal(offer))

  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isSecondaryWithoutRoomName = variant === 'SecondaryWithoutRoomName'

  return (
    <Box
      data-id="ArrowIcon"
      hasMirror
      marginLeft={isSecondaryWithoutRoomName ? 's100' : 's200'}
      color={
        isSecondaryWithoutRoomName
          ? 'content.neutral.c950'
          : 'content.neutral.c600'
      }
    >
      <Icon
        name="ChevronRight"
        size={isSecondaryWithoutRoomName ? 'xs' : 'sm'}
        colorPath={
          isAppLockedDeal || isPrivateDeal || isRequiredOneTimePasswordFlow
            ? 'content.special.c500'
            : 'content.neutral.c600'
        }
      />
    </Box>
  )
}
