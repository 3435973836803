import {setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {getDomainName} from '@daedalus/core/src/utils/url'

export const SAW_PRICE_WATCH_OVERLAY = 'sawPriceWatchOverlay'
export const SAW_PRICE_WATCH_BOTTOM_SHEET_OVERLAY =
  'sawPriceWatchBottomSheetOverlay'
export const SAW_ALERT_MANAGEMENT_ONBOARDING_BANNER =
  'sawAlertManagementOnboardingBanner'

export const setSawPriceWatchOverlay = () => {
  setCookie(SAW_PRICE_WATCH_OVERLAY, 'true', {
    domain: getDomainName(window.location.hostname),
    expires: 7 // 7 days
  })
}

export const setSawPriceWatchBottomSheetOverlay = () => {
  setCookie(SAW_PRICE_WATCH_BOTTOM_SHEET_OVERLAY, 'true', {
    domain: getDomainName(window.location.hostname),
    expires: 365 * 100 // approximately 100 years ;) no option for forever
  })
}

export const setSawPriceWatchOnboardingBanner = () => {
  setCookie(SAW_ALERT_MANAGEMENT_ONBOARDING_BANNER, 'true', {
    domain: getDomainName(window.location.hostname),
    expires: 365 * 100 // approximately 100 years ;) no option for forever
  })
}
