import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {getHotelIdsToDisplayIncludingAnchor} from 'modules/sapiSearch/selectors'

import {StreetviewMarker} from './StreetviewMarker'
import {useStreetViewContext} from './StreetviewProvider'

import type {Location} from './types'
import type {ContentHotel} from '@findhotel/sapi'

const DISPLAY_OTHER_HOTELS = false // Hardcoded for the test, we may reuse this if we want to enable streetview browsing

interface Props {
  currentHotelId: string
  currentLocation: ContentHotel['_geoloc']
  setPositionAndPov: (loc: Location) => void
}

export const StreetviewMarkers = ({
  currentHotelId,
  currentLocation,
  setPositionAndPov
}: Props) => {
  const {selectedHotelId, setSelectedHotelId} = useStreetViewContext()

  const allHotelIds = useSelector(getHotelIdsToDisplayIncludingAnchor)
  const hotelIds = DISPLAY_OTHER_HOTELS
    ? allHotelIds.slice(0, 10)
    : [currentHotelId]

  const onClick = useCallback(
    (hotel: ContentHotel) => {
      setPositionAndPov(hotel._geoloc)
      setSelectedHotelId(hotel.objectID)
    },
    [setPositionAndPov]
  )

  return (
    <>
      {hotelIds.map(hotelId => (
        <StreetviewMarker
          key={hotelId}
          hotelId={hotelId}
          currentLocation={currentLocation}
          isAnchor={hotelId === currentHotelId}
          isSelected={hotelId === selectedHotelId}
          onClick={onClick}
        />
      ))}
    </>
  )
}
