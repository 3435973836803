import {useSelector} from 'react-redux'
import {getHotel} from 'modules/sapiSearch/selectors'
import {Hotel} from 'modules/sapiSearch/slice'
import {toggle} from 'opticks'

import {useGetHotelQuery} from '@daedalus/core/src/sapi/services/searchApi'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src'

export const useGetHotelDetails = (hotelId: string): Hotel | ContentHotel => {
  const hotel = useSelector(state => getHotel(state, hotelId))
  const AIGeneratedHotelDescriptionOnBSide = toggle(
    'sapi4eva-hotel-descriptions',
    false,
    true
  )

  // get the hotel's details from API instead of hotelEntities
  const {data} = useGetHotelQuery(
    {
      hotelId
    },
    {
      skip: !AIGeneratedHotelDescriptionOnBSide || !hotelId
    }
  )

  return AIGeneratedHotelDescriptionOnBSide ? data : hotel
}
