import React from 'react'
import {useSelector} from 'react-redux'
import {HotelCardProvider} from 'components/HotelCardContext'
import {
  getHotelDetailsOverlayInfo,
  getHotelDetailsVisibility
} from 'modules/overlay/selectors'
import {getAnchorHotelId} from 'modules/sapiSearch/selectors'

import {HotelDetailsOverlay} from './Loadable'

const HotelDetailsOverlayDesktop = () => {
  const isOpen = useSelector(getHotelDetailsVisibility)
  const {hotelId} = useSelector(getHotelDetailsOverlayInfo)
  const anchorHotelId = useSelector(getAnchorHotelId)

  return (
    isOpen && (
      <HotelCardProvider hotelId={hotelId} isAnchor={hotelId === anchorHotelId}>
        <HotelDetailsOverlay isOpen={isOpen} />
      </HotelCardProvider>
    )
  )
}

export default HotelDetailsOverlayDesktop
