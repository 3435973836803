import {h64} from 'xxhashjs'

import {META_TRAFFIC_PROFILE} from '../../profile'
import {calculateDateDifference} from '../../utils/date'

export interface MaidLandingParameters {
  profile?: string
  hotelId?: string
  checkIn?: string
  checkOut?: string
}

/**
 * This function generates a unique identifier (maid) based on the landing query string parameters.
 * If the profile is equal to META_TRAFFIC_PROFILE, the maid is generated using the hotelId, checkIn date, and the number of nights.
 * Otherwise, the maid is generated using the anonymousId.
 *
 * This logic is duplicated in the sapi-preheat lambda function. Please also update it there.
 * @param props -
 * @param props.anonymousId -
 * @param props.landingParameters -
 * @returns string - The generated maid.
 */
export const generateMaid = ({
  anonymousId,
  landingParameters
}: {
  anonymousId: string
  landingParameters: MaidLandingParameters
}): string => {
  const {profile, hotelId, checkIn, checkOut} = landingParameters
  const numberOfNights = calculateDateDifference(
    new Date(String(checkIn)),
    new Date(String(checkOut))
  )

  if (profile === META_TRAFFIC_PROFILE)
    return xxhash64(`${hotelId}:${checkIn}:${numberOfNights}`)

  return xxhash64(String(anonymousId))
}

/**
 *  This function generates a hash based on the xxhash algorithm.
 * @param value - The value to be hashed.
 * @returns string - The generated hash.
 */
export const xxhash64 = (value: string): string =>
  h64().update(value).digest().toString()
