import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {FacetFilterPopover} from 'components/FiltersPopovers/FacetFilterPopover/FacetFilterPopover'
import {getFacets} from 'modules/sapiSearch/selectors'
import {searchParamToFacet} from 'modules/search/constants'
import {getListOfValues} from 'modules/search/selectors'
import {FACILITY_GROUPS} from 'utils/facilities'

import {RenderType} from '../../Filters/FacetFilter'

interface Props {
  isActive?: boolean
}

export const FacilitiesFilterPopover: React.FC<Props> = ({
  isActive = false
}) => {
  const {formatMessage} = useIntl()
  const facets = useSelector(getFacets) ?? {}
  const listOfValues = useSelector(getListOfValues)
  return (
    <FacetFilterPopover
      isActive={isActive}
      dataId="facilitiesFilter"
      labelMessage={formatMessage(filterMessages.facilities)}
      facetLabel="Facility"
      facets={facets[searchParamToFacet.facilities.facet]}
      listOfValues={listOfValues}
      renderType={RenderType.Card}
      facetGroups={FACILITY_GROUPS}
      facetKey={searchParamToFacet.facilities.param}
    />
  )
}
