import React, {useCallback, useContext, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DestinationPickerOverlay} from 'components/DestinationPickerOverlay'
import {
  OnDestinationChangeParams,
  RecentSearchParams,
  useSearchBox,
  useSearchBoxDatePicker,
  useSearchBoxDestinationPicker,
  useSearchBoxGuestPicker
} from 'hooks/searchBox'
import {useDatePickerTiming} from 'hooks/useDatePickerTiming'
import {getDevicePlatform, getMeta} from 'modules/meta/selectors'
import {
  showDatePicker as openDatePickerOverlay,
  showGuestPicker as openGuestPickerOverlay
} from 'modules/overlay/actions'
import {
  getIsDatePickerOpen,
  getIsDestinationPickerOpen,
  getIsGuestPickerOpen
} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {useSapi} from 'modules/sapiSearch/SapiProvider'
import {
  getAnchorComplete,
  getHotelIdsForPriceColorGuideCalendars,
  getNumberOfGuests,
  getSearchParameters,
  getSearchType,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'
import {areSapiSearchParamsValid, SearchType} from 'modules/sapiSearch/utils'
import {getIsMultiRoomBundlesSearch} from 'modules/search/selectors'
import {
  getIsDatesVisible,
  getIsSearchBoxOpen,
  getOpenDatePickerType,
  getSearchBoxAncestor,
  getSearchBoxSearchType,
  getSearchBoxValues
} from 'modules/searchBox/selectors'
import {setSuggestions} from 'modules/searchBox/slice'
import {SearchBoxAncestor, SearchTrigger} from 'modules/searchBox/types'
import {toggle} from 'opticks'
import {equals} from 'ramda'
import {buildSapiSuggestions} from 'services/search/Suggests'
import {dateStringToMiddayDate, getDestinationParameter} from 'utils'

import {TRANSITION_DURATION} from '@daedalus/atlas/Overlay'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {ConfigureI18nContext} from '@daedalus/core/src/localization/components/ConfigureI18n'
import {
  roomsSplitToString,
  roomsToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {
  RoomConfigType,
  RoomSplitType
} from '@daedalus/core/src/room/types/RoomConfiguration'
import {WithColorPriceDayData} from '@daedalus/core/src/search/modules/ColorPriceCalendar/components/WithColorPriceDayData'
import {useColorPriceCalendar} from '@daedalus/core/src/search/modules/ColorPriceCalendar/hooks'
import {getHasSomeAvailabilityPrices} from '@daedalus/core/src/search/modules/ColorPriceCalendar/selectors'
import {
  DatePickerOverlay,
  Props as DatePickerOverlayProps
} from '@daedalus/shared/src/search/DatePickerOverlay'
import {
  GuestRoomConfigOverlay,
  Props as GuestRoomConfigOverlayProps
} from '@daedalus/shared/src/search/GuestRoomConfig/GuestRoomConfigOverlay'

import {FakeSearchFormWithStore} from '../../FakeSearchFormWithStore'

const DatePickerOverlayContainer = (
  props: Omit<DatePickerOverlayProps, 'isOpen' | 'openedDatePickerType'>
) => {
  const isOpen = useSelector(getIsDatePickerOpen)
  const openedDatePickerType = useSelector(
    getOpenDatePickerType
  ) as DatePickerType

  return (
    <DatePickerOverlay
      {...props}
      isOpen={isOpen}
      openedDatePickerType={openedDatePickerType}
    />
  )
}

const GuestRoomConfigOverlayContainer = (
  props: Omit<GuestRoomConfigOverlayProps, 'isOpen'>
) => {
  const isOpen = useSelector(getIsGuestPickerOpen)
  return <GuestRoomConfigOverlay {...props} isOpen={isOpen} />
}

export interface Props {
  ancestor?: SearchBoxAncestor
  openGuestPickerOnCheckOutChange?: boolean
}

export const SearchBoxMobile = ({
  openGuestPickerOnCheckOutChange = true,
  ancestor
}: Props) => {
  const destinationInputRef = useRef<HTMLInputElement>(null)
  const {
    datePickerReadyTimerStart,
    datePickerReadyTimerEnd,
    datePickerLoadedTimerEnd
  } = useDatePickerTiming()

  const dispatch = useDispatch()
  const {monthNames, weekDayNamesShort, firstDayOfWeek} =
    useContext(ConfigureI18nContext)

  const isAndroid = useSelector(getDevicePlatform) === 'android'
  const isOpenedDestinationPicker = useSelector(getIsDestinationPickerOpen)
  const isDatesVisible = useSelector(getIsDatesVisible)
  const searchBoxIsOpen = useSelector(getIsSearchBoxOpen)
  const activeAncestor = useSelector(getSearchBoxAncestor)
  const anchorComplete = useSelector(getAnchorComplete)
  const hotelIdsForPriceColorCalendar = useSelector(
    getHotelIdsForPriceColorGuideCalendars
  )
  const isOpen = searchBoxIsOpen && ancestor === activeAncestor
  const {checkIn, checkOut, rooms, optimizeRooms, hotelId} =
    useSelector(getSearchBoxValues)
  const {roomsSplit} = roomsToConfigurationObject(rooms)
  const [visibleCheckIn, visibleCheckOut] = isDatesVisible
    ? [checkIn, checkOut]
    : [undefined, undefined]
  const searchParameters = useSelector(getSearchParameters)
  const numberOfGuests = useSelector(getNumberOfGuests)
  const sapiSearchParamsAreValid = areSapiSearchParamsValid(searchParameters)
  const searchType = useSelector(getSearchType)
  const showTotalPrices = useSelector(getShowTotalPrices)
  const {includeLocalTaxes, includeTaxes} = useSelector(getMeta)
  const isMultiRoomBundlesSearch = useSelector(getIsMultiRoomBundlesSearch)
  const pricingContext = {
    includeTaxes,
    includeLocalTaxes,
    includeRoomsInNightlyPrice: isMultiRoomBundlesSearch
  }
  const initialDatePickerValues = {
    checkIn: searchParameters?.checkIn,
    checkOut: searchParameters?.checkOut
  }
  const initialGuestPickerValues = {
    rooms: searchParameters?.rooms,
    optimizeRooms: searchParameters?.optimizeRooms
  }

  const searchBoxSearchType = useSelector(getSearchBoxSearchType)

  const {doSearch} = useSearchBox()

  const {
    onGuestRoomsChange,
    onGuestOptimizeRoomsChange,
    onGuestPickerReset,
    onGuestPickerOptionInfoDisplayed,
    onGuestConfigPreferenceClick
  } = useSearchBoxGuestPicker(initialGuestPickerValues)

  const {
    onDateChange,
    onDatePickerDayClick,
    onDatePickerOpen,
    onDatePickerReset
  } = useSearchBoxDatePicker(initialDatePickerValues)

  const {
    onRecentSearchSelected,
    destinationHasError,
    destinationErrorMessage,
    onDestinationChange,
    setDestinationHasError,
    setDestinationErrorMessage,
    destinationPickerInputDisplayValue,
    onNearbyPropertiesSuggestionSelected
  } = useSearchBoxDestinationPicker()

  const hasSomeAvailabilityPrices = useSelector(getHasSomeAvailabilityPrices)
  const {requestOneMonthAvailability, requestTwoMonthsAvailability} =
    useColorPriceCalendar({
      searchParameters,
      pricingContext
    })

  const isColorPriceDatePickerEnabled =
    numberOfGuests <= 2 && hasSomeAvailabilityPrices

  const handleOpenGuestPicker = useCallback((): void => {
    dispatch(openGuestPickerOverlay())
  }, [dispatch])

  const handleSuggestionSelected = useCallback((): void => {
    setTimeout(() => {
      dispatch(openDatePickerOverlay(DatePickerType.CheckIn))
    }, TRANSITION_DURATION) // Wait for previous overlay to close
  }, [dispatch])

  const handleDatePickerDayClick = useCallback(
    (...args) => {
      // eslint-disable-next-line prefer-spread
      onDatePickerDayClick.apply(null, args)
      datePickerReadyTimerStart()
    },
    [onDatePickerDayClick, datePickerReadyTimerStart]
  )

  const handleDatePickerMount = useCallback(() => {
    datePickerLoadedTimerEnd()
  }, [datePickerLoadedTimerEnd])

  const handleDatePickerDOMChange = useCallback(() => {
    datePickerReadyTimerEnd()
  }, [datePickerReadyTimerEnd])

  const hideSearchBoxOverlay = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  const onDatePickerClose = useCallback((): void => {
    hideSearchBoxOverlay()
    onDatePickerReset()
  }, [hideSearchBoxOverlay, onDatePickerReset])

  const onDatePickerOverlaySubmit = useCallback((): void => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DatePickerSubmitButton,
        action: Action.Clicked
      })
    )

    // show validation if hotel, place or area search is not defined
    if (!sapiSearchParamsAreValid) {
      setDestinationHasError(true)
      setDestinationErrorMessage(
        destinationPickerInputDisplayValue
          ? 'destinationPickerUnknownMessage'
          : 'destinationPickerEmptyMessage'
      )
      return
    }

    const hasDatesChanged =
      !equals(visibleCheckIn, searchParameters.checkIn) ||
      !equals(visibleCheckOut, searchParameters.checkOut)

    // search only if dates have changed
    if (hasDatesChanged) {
      doSearch({
        searchTrigger: SearchTrigger.DatePicker,
        searchOptions: {method: 'replace'}
      })
    }
    // otherwise just close the overlay
    else {
      dispatch(hideOverlay())
    }
  }, [destinationPickerInputDisplayValue, dispatch, doSearch])

  const onGuestPickerOverlaySubmit = useCallback(
    (roomsSplit: RoomSplitType[]): void => {
      const rooms = roomsSplitToString(roomsSplit)
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.GuestPickerSubmitButton,
          action: Action.Clicked
        })
      )
      doSearch({
        newUrlParams: {
          rooms
        },
        searchTrigger: SearchTrigger.GuestPicker,
        searchOptions: {method: 'replace'}
      })
    },
    [dispatch, doSearch]
  )

  const handleDestinationSuggestionSelected = useCallback(
    ({displayValue, type, value}: OnDestinationChangeParams): void => {
      onDestinationChange({displayValue, type, value})
      const parameters = {
        placeId: undefined,
        hotelId: undefined,
        boundingBox: undefined,
        userLocationSearch: undefined,
        ...getDestinationParameter(type, value)
      }
      doSearch({
        newUrlParams: parameters,
        searchTrigger: SearchTrigger.Suggestions,
        searchOptions: {method: 'replace'}
      })
    },
    [doSearch, onDestinationChange]
  )

  const handleRecentSearchSelected = useCallback(
    (recentSearch: RecentSearchParams): void => {
      onRecentSearchSelected({
        recentSearch,
        searchOptions: {method: 'replace'}
      })
    },
    [onRecentSearchSelected]
  )

  const {suggest} = useSapi()

  const handleSuggestionsRequested = useCallback(
    async (value: string, shouldLoadUserSearchHistory: boolean) => {
      const response = await suggest(value)

      const mappedResponse = buildSapiSuggestions(
        response,
        shouldLoadUserSearchHistory,
        !value,
        searchType
      )

      dispatch(
        setSuggestions({
          suggestions: mappedResponse,
          suggestionsSearchValue: value
        })
      )
    },
    [suggest, dispatch, searchType]
  )

  const handleGuestChange = useCallback(
    (roomsSplit: RoomSplitType[]): void => {
      const rooms = roomsSplitToString(roomsSplit)
      onGuestRoomsChange(rooms)
    },
    [onGuestRoomsChange]
  )

  const handleGuestOptimizeRoomsChange = useCallback(
    (isOptimizeRooms?: boolean): void => {
      onGuestOptimizeRoomsChange(isOptimizeRooms)
    },
    [onGuestOptimizeRoomsChange]
  )

  const handleGuestPickerOverlayClose = useCallback((): void => {
    hideSearchBoxOverlay()
    onGuestPickerReset()
  }, [hideSearchBoxOverlay, onGuestPickerReset])

  const handleNearbyPropertiesSuggestionSelected = useCallback(() => {
    setTimeout(() => {
      dispatch(openDatePickerOverlay(DatePickerType.CheckIn))
    }, TRANSITION_DURATION)
    onNearbyPropertiesSuggestionSelected()
  }, [onNearbyPropertiesSuggestionSelected, dispatch])

  const handleDestinationPickerClick = () => {
    destinationInputRef?.current?.focus?.()
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    // pre-fetch availability for the hotel searches
    if (searchBoxSearchType === SearchType.Hotel) {
      toggle(
        'a8fj1jd3-color-price-calendar-2',
        () => null,
        () => {
          requestTwoMonthsAvailability(dateStringToMiddayDate(checkIn), [
            hotelId
          ])
        }
      )
    }
  }, [requestTwoMonthsAvailability, hotelId, checkIn, searchBoxSearchType])

  useEffect(() => {
    // pre-fetch availability for the non-hotel searches
    if (searchBoxSearchType !== SearchType.Hotel && anchorComplete) {
      toggle(
        'a8fj1jd3-color-price-calendar-2',
        () => null,
        () => {
          requestTwoMonthsAvailability(
            dateStringToMiddayDate(checkIn),
            hotelIdsForPriceColorCalendar
          )
        }
      )
    }
  }, [
    requestTwoMonthsAvailability,
    anchorComplete,
    hotelIdsForPriceColorCalendar,
    searchBoxSearchType,
    checkIn
  ])

  return (
    <>
      {isOpen && (
        <FakeSearchFormWithStore
          onDestinationPickerClick={handleDestinationPickerClick}
        />
      )}
      <DestinationPickerOverlay
        isOpen={isOpenedDestinationPicker}
        displayValue={destinationPickerInputDisplayValue}
        hasError={destinationHasError}
        errorMessage={destinationErrorMessage}
        onSuggestionsRequested={handleSuggestionsRequested}
        onChange={handleDestinationSuggestionSelected}
        onSuggestionSelected={handleSuggestionSelected}
        onRecentSearchSelected={handleRecentSearchSelected}
        onClose={hideSearchBoxOverlay}
        onNearbyPropertiesSuggestionSelected={
          handleNearbyPropertiesSuggestionSelected
        }
        destinationInputRef={destinationInputRef}
      />
      <DatePickerOverlayContainer
        virtualize={isAndroid}
        checkIn={visibleCheckIn}
        checkOut={visibleCheckOut}
        firstDayOfWeek={firstDayOfWeek}
        months={monthNames}
        weekdaysShort={weekDayNamesShort}
        onDOMChange={handleDatePickerDOMChange}
        onMount={handleDatePickerMount}
        onChange={onDateChange}
        onOpen={onDatePickerOpen}
        onCheckOutSelected={
          openGuestPickerOnCheckOutChange ? handleOpenGuestPicker : null
        }
        onDayClick={handleDatePickerDayClick}
        onSubmit={onDatePickerOverlaySubmit}
        onClose={onDatePickerClose}
        showTotalPrices={showTotalPrices}
        shouldShowPrices={searchBoxSearchType === SearchType.Hotel}
        onMonthView={date =>
          requestOneMonthAvailability(date, hotelIdsForPriceColorCalendar)
        }
        isColorPriceDatePickerEnabled={isColorPriceDatePickerEnabled}
        DayInnerContainer={WithColorPriceDayData}
      />
      <GuestRoomConfigOverlayContainer
        roomsSplit={roomsSplit}
        onClose={handleGuestPickerOverlayClose}
        onSubmit={onGuestPickerOverlaySubmit}
        onRoomsChange={handleGuestChange}
        onOptimizeRoomsChange={handleGuestOptimizeRoomsChange}
        onInfoOpen={onGuestPickerOptionInfoDisplayed}
        onGuestConfigOptionClick={onGuestConfigPreferenceClick}
        roomConfigType={
          optimizeRooms ? RoomConfigType.Optimized : RoomConfigType.Manual
        }
      />
    </>
  )
}
