import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {setSearchTrigger} from 'modules/searchBox/slice'
import {SearchTrigger} from 'modules/searchBox/types'

const DEFAULT_NAVIGATION_OPTIONS = {mergeWithCurrentParams: false}

/**
 * Helper to navigate to a search while setting a search trigger
 */
export const useNavigateToSearchWithTrigger = (
  searchTrigger: SearchTrigger
) => {
  const dispatch = useDispatch()
  const {updateLocation} = useUrlUpdater()

  return useCallback<typeof updateLocation>(
    (params, options = DEFAULT_NAVIGATION_OPTIONS) => {
      dispatch(setSearchTrigger(searchTrigger))
      updateLocation(params, options)
    },
    [updateLocation, dispatch, searchTrigger]
  )
}
