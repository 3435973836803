import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {getIsUserLocationSearch} from 'modules/search/selectors'
import {GeolocationPermissions} from 'utils/geolocation'

import {getPersistedGeolocation} from '@daedalus/core/src/utils/geolocation'

export const useRedirectToHomeIfGeolocationFails = () => {
  const [permissionStatus, setPermissionStatus] = useState(undefined)
  const isUserLocationSearch = useSelector(getIsUserLocationSearch)
  const {changeLocation} = useUrlUpdater()

  const handlePermissionStatusChange = ev => {
    const newPermissionState = ev.target.state
    setPermissionStatus(newPermissionState)
  }

  useEffect(() => {
    const cookieGeolocation = getPersistedGeolocation()

    // If no geolocation cookies were set and customer denied the device's geolocation
    if (
      permissionStatus === GeolocationPermissions.DENIED &&
      !cookieGeolocation &&
      isUserLocationSearch
    ) {
      changeLocation({}, {basePath: '/'})
    }
  }, [permissionStatus, isUserLocationSearch])

  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions
        .query({name: 'geolocation'})
        .then(permissionStatus => {
          setPermissionStatus(permissionStatus.state)
          permissionStatus.onchange = handlePermissionStatusChange
        })
    }
  }, [])
}
