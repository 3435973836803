import React from 'react'
import {useSelector} from 'react-redux'

import {getFreezeSelectedContext} from '../../modules/selector'
import {findRoomByOfferId} from '../../utils/offer'
import {HotelCard} from '../HotelCard'

export const SimplifiedHotelCard: React.FC = () => {
  const {hotel, searchDetail, rooms, sapiMatchedOffer} = useSelector(
    getFreezeSelectedContext
  )
  const {checkIn, checkOut, roomsSplit} = searchDetail || {}
  const {hotelName, starRating, imageURIs} = hotel || {}

  const room = findRoomByOfferId(rooms || [], sapiMatchedOffer?.id)

  return (
    <>
      <HotelCard
        hotelName={hotelName as string}
        checkIn={checkIn}
        checkOut={checkOut}
        roomsSplit={roomsSplit}
        imageUrl={imageURIs?.[0] as string}
        rating={starRating}
        room={room}
        offer={sapiMatchedOffer}
        shouldShowPayNow
      />
    </>
  )
}
