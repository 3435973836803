import {pick} from 'ramda'

import {SearchBoxValue, SearchBoxValues} from './types'

type SearchBoxValueKeys = keyof SearchBoxValues

const searchBoxValueKeys: SearchBoxValueKeys[] = [
  'boundingBox',
  'checkIn',
  'checkOut',
  'hotelId',
  'placeId',
  'rooms',
  'userLocationSearch',
  'optimizeRooms',
  'address'
]

export const getValidSearchBoxValues = (urlParams): SearchBoxValues =>
  pick(searchBoxValueKeys, urlParams)

/**
 * Clean the search values by removing NaN and other falsy values
 * before passing it on to sapi
 * @param values
 */
export const cleanSearchBoxValues = (
  values: SearchBoxValues
): SearchBoxValues => {
  const cleanedSearchObject = Object.keys(values).reduce((acc, key) => {
    const value = values[key]
    if (Array.isArray(value)) {
      const isValueFalsyOrNaN = value.some(item => !isValidValue(item))
      const cleanedArray = isValueFalsyOrNaN ? [] : value
      if (cleanedArray.length > 0) {
        return {...acc, [key]: cleanedArray}
      }
    } else if (isValidValue(value)) {
      return {...acc, [key]: value}
    }
    return acc
  }, {})
  return cleanedSearchObject
}
const isValidValue = (value: SearchBoxValue) => {
  return value && !value.toString().includes('NaN')
}
