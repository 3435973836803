import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface AccommodationGalleryState {
  isOverlayOpen: boolean
  overlaySelectedImage: string | undefined
}
export interface AccommodationState {
  hotelId: string
  searchId: string
  overlayExperimentIsInitialized: boolean
  isTopOfferVisible: boolean
  isRoomsViewExpanded: boolean
  scrollToApTarget: string
  lightbox: AccommodationGalleryState
  searchBoxPopover: 'datePicker' | 'guestPicker'
  isRoomsFiltersLoading: boolean
  isStaticMapImageLoaded: boolean
  isRoomsFirstImageLoaded: boolean
}

export const initialState: AccommodationState = {
  hotelId: null,
  searchId: null,
  overlayExperimentIsInitialized: false,
  isRoomsViewExpanded: false,
  scrollToApTarget: null,
  isTopOfferVisible: undefined,
  lightbox: {
    isOverlayOpen: false,
    overlaySelectedImage: undefined
  },
  searchBoxPopover: null,
  isRoomsFiltersLoading: false,
  isStaticMapImageLoaded: false,
  isRoomsFirstImageLoaded: false
}

const {reducer, actions} = createSlice({
  name: 'accommodation',
  initialState,
  reducers: {
    resetAccommodationPageState: state => {
      state.isRoomsViewExpanded = false
      state.scrollToApTarget = null
    },
    setTopOfferVisibility: (state, {payload}) => {
      state.isTopOfferVisible = payload.value
    },
    setScrollToApTarget: (state, {payload}) => {
      state.scrollToApTarget = payload.target
    },
    setLightboxOverlayVisibility: (
      state,
      {payload}: PayloadAction<boolean>
    ) => {
      state.lightbox.isOverlayOpen = payload
    },
    setLightboxSelectedImage: (
      state,
      {payload}: PayloadAction<string | undefined>
    ) => {
      state.lightbox.overlaySelectedImage = payload
    },
    expandRoomsView: state => {
      state.isRoomsViewExpanded = true
    },
    collapseRoomsView: state => {
      state.isRoomsViewExpanded = false
    },
    setAccommodationSearchBoxPopover: (
      state,
      {payload}: PayloadAction<AccommodationState['searchBoxPopover']>
    ) => {
      state.searchBoxPopover = payload
    },
    setRoomsFiltersLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.isRoomsFiltersLoading = payload
    },
    setStaticMapImageLoaded: (state, {payload}: PayloadAction<boolean>) => {
      state.isStaticMapImageLoaded = payload
    },
    setRoomsFirstImageLoaded: (state, {payload}: PayloadAction<boolean>) => {
      state.isRoomsFirstImageLoaded = payload
    }
  }
})

/**
 * Fires an action to tracks updates to AP
 */
export const markAccommodationPageLoad = createAction('accommodation/markLoad')

export const {
  resetAccommodationPageState,
  setTopOfferVisibility,
  setLightboxOverlayVisibility,
  setLightboxSelectedImage,
  setAccommodationSearchBoxPopover,
  expandRoomsView,
  collapseRoomsView,
  setScrollToApTarget,
  setRoomsFiltersLoading,
  setStaticMapImageLoaded,
  setRoomsFirstImageLoaded
} = actions

export default reducer
