import {
  getMetaBrandHasRooms,
  getSelectedOfferIdEventPayload
} from 'modules/accommodation/selectors'
import {markAccommodationPageLoad} from 'modules/accommodation/slice'
import {
  getAnonymousId,
  getCurrencyCode,
  getLocaleCode
} from 'modules/meta/selectors'
import {
  getAnchorHotelId,
  getIsAnchorHotelAvailable,
  getSearchId,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {
  anchorReceived,
  hotelsAvailabilityCompleted,
  hotelsAvailabilityReceived,
  hotelsReceived,
  offersReceived,
  searchCompleted,
  searchStarted,
  setNextPage
} from 'modules/sapiSearch/slice'
import {INVALID_SEARCH} from 'modules/search/actions/invalidSearch'
import {getHotelId, getPlaceId} from 'modules/search/selectors'
import {
  getFilteredRoomsData,
  getIsRoomsResultEmpty
} from 'modules/searchApi/selectors'
import {toggle} from 'opticks'
import {isEmpty, once, path} from 'ramda'
import {Dispatch} from 'redux'
import Settings, {SettingsType} from 'Settings'

// Direct import of selector module needed instead of useBrand hook in non-React context where hooks cannot be used.
// eslint-disable-next-line @vio/custom-rules/prefer-usebrand-hook
import {
  getBrand,
  getBrandName
} from '@daedalus/core/src/_web/brand/modules/selectors'
import {
  logTimeEnd,
  logTimeStart
} from '@daedalus/core/src/_web/utils/logging/performanceTimer'
import {runInIdleTime} from '@daedalus/core/src/_web/utils/performance/idleUntilUrgent'
import {yieldToMain} from '@daedalus/core/src/_web/utils/performance/yieldToMain'
import {
  TRACK_ERROR_TRIGGERED,
  TRACK_EXPERIMENT_SERVED,
  TRACK_USER_ACTION,
  trackEvent,
  TrackProviderRedirectParams
} from '@daedalus/core/src/analytics/modules/actions'
import {trackProviderRedirect} from '@daedalus/core/src/analytics/modules/actions'
import {customIdentify} from '@daedalus/core/src/analytics/modules/middleware/customIdentify'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  Team,
  TrackEventPayload,
  TrackEventProperties
} from '@daedalus/core/src/analytics/types/Events'
import {FullStory} from '@daedalus/core/src/analytics/types/FullStory'
import AnalyticsUtils, {isGoodBot} from '@daedalus/core/src/analytics/utils'
import {
  createDataPipelineEventName,
  removeSearchResultsContext
} from '@daedalus/core/src/analytics/utils/trackEventHelpers'
import {
  getCognitoIsMemberPlus,
  getUser,
  getUserEmail,
  getUserId,
  getUserIdentifiableTraits
} from '@daedalus/core/src/auth/modules/selectors'
import {identifyAuthenticatedUser} from '@daedalus/core/src/auth/modules/slice'
import {fetchOptimizelyDataFile} from '@daedalus/core/src/experiments/modules/actions'
import {colorPriceCalendarAvailabilityCompleted} from '@daedalus/core/src/search/modules/ColorPriceCalendar/slice'
import {getTimezone} from '@daedalus/core/src/utils/date'

import {MiddlewareType} from '..'
import {
  trackAccommodationPageLoad,
  trackAccommodationPageReady,
  trackInteractiveLocationMapBoxDisplayed,
  trackInteractiveLocationMapBoxLoaded,
  trackMapBoxLoaded,
  trackMapViewDisplayed,
  trackStaticLocationMapBoxDisplayed,
  trackStaticLocationMapBoxLoaded,
  trackSystemSearchSucceeded
} from './actions/trackEvent'
import {
  getOfferClickedMetaData,
  TRACK_LOCKED_OFFER_CLICKED,
  TRACK_OFFER_CLICKED
} from './actions/trackOfferClicked'
import {TRACK_CIO_PAGE_VIEW, TRACK_PAGE_VIEW} from './actions/trackPageView'
import initializeAnalytics from './initializeAnalytics'
import {
  getEventProperties,
  getPaginationContext,
  getProviderRedirectParams,
  getSearchContext,
  getSearchResultsContext
} from './selectors'
import {getTrackHotelsSearchedParamsForDD} from './utils'
import {logEvent} from './utils/logEvent'
import {
  getRemarketingPageViewParams,
  getSapiOfferClickedRemarketingParams
} from './utils/remarketing'

declare let FS: FullStory

const getFullStoryObject = () => {
  return typeof FS === 'undefined' ? undefined : FS
}

const trackFullStoryEvent = (
  eventName: string,
  eventProperties: Record<string, unknown>
) => {
  getFullStoryObject()?.event(eventName, eventProperties)
}

/**
 * Reset the Segment analytics endpoint to the default endpoint after the endpoint was set to the team endpoint.
 * This is to avoid the analytics data to be sent to the wrong endpoint. All events that do not belong to a specific
 * team will be sent to the endpoint which is set by the following function.
 */
const resetSegmentEndpoint = () => {
  const ANALYTICS_ENDPOINT = Settings.get('CLIENT_ANALYTICS_ENDPOINT')
  const API_KEY = Settings.get('CLIENT_ANALYTICS_API_KEY')
  AnalyticsUtils.overrideSegmentDestinationSettings(
    ANALYTICS_ENDPOINT,
    API_KEY,
    false //31e7d7cf-analytics-sendBeacon default to false for the legacy endpoint
  )
}

/**
 * In order to send each team events to a different endpoint, we need to override the segment endpoint by the team
 * endpoint which is defined in the environment variables. This function overrides the Segment endpoint with the
 * team endpoint.
 * @param - team the team that the event is being sent by
 */
const setSegmentTeamEndpoint = (team: Team) => {
  const teamName = team.toUpperCase()
  const TEAM_ANALYTICS_ENDPOINT = Settings.get(
    `CLIENT_ANALYTICS_${teamName}_ENDPOINT` as keyof SettingsType
  ) as string
  const TEAM_ANALYTICS_API_KEY = Settings.get(
    `CLIENT_ANALYTICS_${teamName}_API_KEY` as keyof SettingsType
  ) as string

  if (!TEAM_ANALYTICS_ENDPOINT || !TEAM_ANALYTICS_API_KEY) {
    throw new Error(
      `Missing analytics endpoint and API key environment variable for team: ${team}`
    )
  }

  AnalyticsUtils.overrideSegmentDestinationSettings(
    TEAM_ANALYTICS_ENDPOINT,
    TEAM_ANALYTICS_API_KEY,
    toggle('31e7d7cf-analytics-sendBeacon', false, true)
  )
}

const trackAnalyticsEvent = (
  eventName: string,
  metadata: TrackEventProperties
) => {
  try {
    const {team = Team.Default, ...metadataWithoutTeam} = metadata
    setSegmentTeamEndpoint(team)
    AnalyticsUtils.trackEvent(eventName, metadataWithoutTeam)
    resetSegmentEndpoint()
  } catch (error) {
    console.error(error)
  }
}

const trackPageView = function (...args) {
  runInIdleTime(() => {
    AnalyticsUtils.trackPageView?.(...args)
  })
  const [pageName] = args as [string]
  logEvent(`PageView.${pageName}`)
}

const METRICS_WITH_EMPTY_SEARCH_CONTEXT = ['FetchOptimizelyDataFile']

const getTrackTimingEventPayload = (
  metric,
  duration,
  entity = Entity.Search,
  team = Team.Default
) => ({
  category: Category.System,
  entity,
  action: Action.PerformanceMeasured,
  analyticsContext: {
    [AnalyticsContext.PerformanceMeasuredContext]: {
      metric,
      value: duration,
      unit: 'ms'
    },
    ...(METRICS_WITH_EMPTY_SEARCH_CONTEXT.includes(metric) && {
      [AnalyticsContext.SearchContext]: {}
    })
  },
  team
})

export const trackTimingEvent = (
  dispatch: Dispatch,
  metric: string,
  fromMetric: string,
  entity: Entity = Entity.Search,
  team = Team.Default,
  shouldResetStartMark = false,
  payload?: TrackEventPayload['payload']
) => {
  const metricName = `${entity}-${metric}`
  const duration = logTimeEnd(metricName, fromMetric, shouldResetStartMark)
  if (!duration) return
  const event = getTrackTimingEventPayload(metric, duration, entity, team)

  dispatch(trackEvent({...event, payload}))
}

export const trackPageReadyTiming = once(
  (dispatch: Dispatch, entity: Entity) => {
    trackTimingEvent(dispatch, 'Ready', 'responseStart', entity)
  }
)

export const trackPageLoadedTiming = once(
  (dispatch: Dispatch, entity: Entity) => {
    trackTimingEvent(dispatch, 'Loaded', 'responseStart', entity)
  }
)

const trackPageViewTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'PageView', 'responseStart')
})

const trackHotelsSearchedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'HotelsSearched', `PageView-start`)
  trackTimingEvent(dispatch, 'HotelsSearchedAbsoluteTime', 'responseStart')
})

const trackFirstHotelsReceivedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'FirstHotelsReceived', 'HotelsSearched-start')
  trackTimingEvent(dispatch, 'FirstHotelsReceivedAbsoluteTime', 'responseStart')
})
const trackFirstAnchorHotelReceivedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'FirstAnchorHotelReceived', 'HotelsSearched-start')
  trackTimingEvent(
    dispatch,
    'FirstAnchorHotelReceivedAbsoluteTime',
    'responseStart'
  )
})

const trackFirstAnchorOffersReceivedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'FirstAnchorOffersReceived',
    'HotelsSearched-start'
  )
  trackTimingEvent(
    dispatch,
    'FirstAnchorOffersReceivedAbsoluteTime',
    'responseStart'
  )
})

const trackFirstOffersReceivedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'FirstOffersReceived', 'HotelsSearched-start')
  trackTimingEvent(dispatch, 'FirstOffersReceivedAbsoluteTime', 'responseStart')
})

const trackAllAnchorOffersReceivedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'AllAnchorOffersReceived', 'HotelsSearched-start')
  trackTimingEvent(
    dispatch,
    'AllAnchorOffersReceivedAbsoluteTime',
    'responseStart'
  )
})

const trackAllOffersReceivedTiming = (dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'AllOffersReceived', 'HotelsSearched-start')
}

const trackAbsoluteAllOffersReceivedTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'AllOffersReceivedAbsoluteTime', 'responseStart')
})

const trackMapBoxLoadedTiming = (dispatch: Dispatch) => {
  trackTimingEvent(dispatch, 'MapBoxLoaded', `MapView-start`, Entity.MapView)
}

const trackAnchorHotelFirstAvailableDatesTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'AnchorHotelFirstAvailableDates',
    'HotelsSearched-start'
  )
  trackTimingEvent(
    dispatch,
    'AnchorHotelFirstAvailableDatesAbsoluteTime',
    'responseStart'
  )
})

const trackAnchorHotelAllAvailableDatesTiming = once((dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'AnchorHotelAllAvailableDates',
    'HotelsSearched-start'
  )
  trackTimingEvent(
    dispatch,
    'AnchorHotelAllAvailableDatesAbsoluteTime',
    'responseStart'
  )
})

const trackStaticLocationMapBoxLoadedTiming = (
  dispatch: Dispatch,
  team: Team
) => {
  trackTimingEvent(
    dispatch,
    'StaticLocationMapBoxLoaded',
    `StaticLocationMapView-start`,
    Entity.MapView,
    team
  )
}

const trackInteractiveLocationMapBoxLoadedTiming = (
  dispatch: Dispatch,
  team: Team
) => {
  trackTimingEvent(
    dispatch,
    'InteractiveLocationMapBoxLoaded',
    `InteractiveLocationMapView-start`,
    Entity.MapView,
    team
  )
}

/* eslint-disable complexity */
const middleware: MiddlewareType = store => next => async action => {
  const state = store.getState()
  const anonymousId = getAnonymousId(state)
  const {dispatch} = store
  const brand = getBrand(state)
  const cio = customerIo(brand)

  if (!AnalyticsUtils.analyticsPlatformEnabled()) {
    initializeAnalytics(anonymousId)
  }

  if (
    !AnalyticsUtils.analyticsPlatformEnabled() ||
    isGoodBot(navigator.userAgent)
  ) {
    return next(action)
  }

  switch (action.type) {
    case TRACK_PAGE_VIEW: {
      await yieldToMain()

      const parameters = {
        ...action.params,
        ...getRemarketingPageViewParams(action.pageName),
        currency: getCurrencyCode(state),
        locale: getLocaleCode(state),
        // TODO: Deprecate
        timestampFirstByte: path(
          ['performance', 'timing', 'responseStart'],
          window
        )
      }

      trackPageView(action.pageName, parameters)
      logTimeStart('PageView')
      trackPageViewTiming(dispatch)

      if (action.pageName === Page.Search) {
        // SRP Ready - START
        logTimeStart('SRPReady')
      }

      break
    }

    case TRACK_CIO_PAGE_VIEW: {
      await yieldToMain()

      cio.trackPageView({
        pageName: action.pageName,
        userId: action.params.id,
        anonymousId,
        ...action.params,
        timezone: getTimezone()
      })

      break
    }

    case TRACK_OFFER_CLICKED: {
      await yieldToMain()

      const offerClickedMetaData = getOfferClickedMetaData(state, action.params)
      const offerClickedRemarketingParams =
        getSapiOfferClickedRemarketingParams(state, action.params)

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AnalyticsUtils.trackEvent('OfferClicked', {
        ...offerClickedMetaData,
        ...offerClickedRemarketingParams
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logEvent('OfferClicked', offerClickedMetaData)
      break
    }

    case TRACK_LOCKED_OFFER_CLICKED: {
      const offerClickedMetaData = getOfferClickedMetaData(state, action.params)

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logEvent('LockedOfferClicked', offerClickedMetaData)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AnalyticsUtils.trackEvent('LockedOfferClicked', offerClickedMetaData)

      break
    }

    case searchStarted.type: {
      const hotelsSearchedParameters = getTrackHotelsSearchedParamsForDD(
        state,
        action
      )

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logEvent('HotelsSearched', hotelsSearchedParameters)
      logTimeStart('HotelsSearched')
      trackHotelsSearchedTiming(dispatch)

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Requested,
          payload: {
            ...getSelectedOfferIdEventPayload(state)
          },
          analyticsContext: {
            [AnalyticsContext.PaginationContext]: getPaginationContext(state),
            [AnalyticsContext.SearchContext]: getSearchContext(
              state,
              action.payload.searchId
            )
          }
        })
      )
      break
    }

    case setNextPage.type: {
      // Treat SAPI page changes as a new search from tracking perspective
      const hotelsSearchedParameters = getTrackHotelsSearchedParamsForDD(
        state,
        {
          payload: {
            searchId: getSearchId(state),
            searchParameters: getSearchParameters(state)
          }
        }
      )

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logEvent('HotelsSearched', hotelsSearchedParameters)
      logTimeStart('HotelsSearched')

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Requested,
          analyticsContext: {
            [AnalyticsContext.PaginationContext]: getPaginationContext(state)
          }
        })
      )
      break
    }

    case anchorReceived.type: {
      if (action.payload.anchorHotelId) {
        trackFirstAnchorHotelReceivedTiming(dispatch)
      }
      break
    }

    case hotelsReceived.type: {
      trackFirstHotelsReceivedTiming(dispatch)
      break
    }

    case offersReceived.type: {
      const anchorHotelId = getAnchorHotelId(state)
      const {hotelOfferEntities, status} = action.payload
      const anchorHotel = hotelOfferEntities?.[anchorHotelId]

      if (anchorHotel) {
        trackFirstAnchorOffersReceivedTiming(dispatch)
      }

      if (
        hotelOfferEntities &&
        Object.keys(hotelOfferEntities)?.some(key => key !== anchorHotelId)
      ) {
        trackFirstOffersReceivedTiming(dispatch)
      }

      if (status?.anchorComplete) {
        trackAllAnchorOffersReceivedTiming(dispatch)
      }
      break
    }

    case searchCompleted.type: {
      trackAllOffersReceivedTiming(dispatch)
      trackAbsoluteAllOffersReceivedTiming(dispatch)

      dispatch(
        trackSystemSearchSucceeded({
          searchResultsContext: getSearchResultsContext(state)
        })
      )

      break
    }

    case trackSystemSearchSucceeded.type: {
      await yieldToMain()
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Succeeded,
          payload: {
            isAnchorHotelAvailable: getIsAnchorHotelAvailable(state),
            ...getSelectedOfferIdEventPayload(state)
          },
          analyticsContext: {
            [AnalyticsContext.SearchResultsContext]:
              action.payload?.searchResultsContext ||
              getSearchResultsContext(state)
          }
        })
      )
      break
    }

    case INVALID_SEARCH: {
      const hotelId = getHotelId(state)
      const placeId = getPlaceId(state)

      if (hotelId) {
        dispatch(
          trackEvent({
            category: Category.System,
            entity: Entity.Search,
            action: Action.Errored,
            payload: {hotelId},
            analyticsContext: {
              [AnalyticsContext.ErrorContext]: {
                errorType: 'InvalidSearch',
                errorMessage: 'HotelNotFound'
              }
            }
          })
        )
      }

      if (placeId) {
        dispatch(
          trackEvent({
            category: Category.System,
            entity: Entity.Search,
            action: Action.Errored,
            payload: {placeId},
            analyticsContext: {
              [AnalyticsContext.ErrorContext]: {
                errorType: 'InvalidSearch',
                errorMessage: 'PlaceNotFound'
              }
            }
          })
        )
      }

      break
    }

    case identifyAuthenticatedUser.type: {
      const user = getUser(state)
      const userId = getUserId(user)
      const userEmail = getUserEmail(user)

      if (!userId) return

      if (userEmail && typeof window !== 'undefined' && window.dataLayer) {
        // It is implemented purely for GTM use-case please prevent duplicating this logic for other use-cases
        window.dataLayer?.push({
          event: 'UserIdentified',
          email: userEmail
        })
      }

      const cognitoTraits = getUserIdentifiableTraits(state)
      const memberPlus = getCognitoIsMemberPlus(user)

      const brandName = getBrandName(state)

      AnalyticsUtils.identify(userId, {
        memberPlus,
        user: cognitoTraits
      })
      try {
        await customIdentify({
          anonymousId,
          userId,
          customerDataEndpoint: Settings.get(
            'CLIENT_ANALYTICS_CUSTOMER_DATA_ENDPOINT'
          ),
          customerDataApiKey: Settings.get(
            'CLIENT_ANALYTICS_CUSTOMER_DATA_API_KEY'
          ),
          cognitoTraits,
          brandName
        })
      } catch (error) {
        logEvent(
          'CustomIdentifyEventFailed',
          {
            errorType: error?.message
          },
          'info'
        )
      }

      break
    }

    case TRACK_USER_ACTION: {
      const {
        params: {action: eventAction, category, label, meta}
      } = action

      const metadata = {
        action: eventAction,
        category,
        label,
        meta
      }
      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AnalyticsUtils.trackEvent('UserAction', metadata)

      logEvent('UserAction', metadata)

      break
    }

    case TRACK_ERROR_TRIGGERED: {
      const {
        event: {errorType, errorMessage}
      } = action

      const metadata = {
        errorType,
        errorMessage
      }

      runInIdleTime(() => {
        // TODO: Search TS preexisting issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        AnalyticsUtils.trackEvent('ErrorTriggered', metadata)
      })

      logEvent('ErrorTriggered', metadata)

      break
    }

    case TRACK_EXPERIMENT_SERVED: {
      const {experimentId, variationId, isActive} = action
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Experiment,
          action: Action.Served,
          analyticsContext: {
            [AnalyticsContext.ExperimentContext]: {
              experimentId,
              variationId,
              isActive
            }
          }
        })
      )
      break
    }

    /** Log pending request for optimizely data file */
    case fetchOptimizelyDataFile.pending.type: {
      logTimeStart('FetchOptimizelyDataFile')
      break
    }

    /** Log success for optimizely data file */
    case fetchOptimizelyDataFile.fulfilled.type: {
      trackTimingEvent(
        dispatch,
        'FetchOptimizelyDataFile',
        'FetchOptimizelyDataFile-start'
      )
      break
    }

    /** Log error for optimizely data file */
    case fetchOptimizelyDataFile.rejected.type: {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.FetchOptimizelyDataFile,
          action: Action.Errored,
          analyticsContext: {
            [AnalyticsContext.ErrorContext]: {
              errorType: 'FetchOptimizelyDataFileFailed',
              errorMessage: action.error?.message
            },
            [AnalyticsContext.SearchContext]: {}
          }
        })
      )

      trackTimingEvent(
        dispatch,
        'FetchOptimizelyDataFile',
        'FetchOptimizelyDataFile-start'
      )

      break
    }

    /**
     * This is the new formalized way to track events
     * and should be used for all event tracking.
     */
    case trackEvent.type: {
      await yieldToMain()

      const payload = action.payload as TrackEventPayload

      runInIdleTime(() => {
        const topLevelEventName = createDataPipelineEventName(payload)
        const metadata = getEventProperties(state, payload)
        trackAnalyticsEvent(topLevelEventName, metadata)
      })

      runInIdleTime(() => {
        const metadata = getEventProperties(state, payload)
        const ddMetaData = removeSearchResultsContext(metadata)

        // TODO: Search TS preexisting issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        logEvent(metadata.name, ddMetaData)
      })

      runInIdleTime(() => {
        const topLevelEventName = createDataPipelineEventName(payload)
        const metadata = getEventProperties(state, payload)
        const fsMetaData = removeSearchResultsContext(metadata)
        // TODO: Search TS preexisting issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackFullStoryEvent(topLevelEventName, fsMetaData)
      })

      break
    }

    case trackMapViewDisplayed.type: {
      logTimeStart('MapView')

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.MapView,
          action: Action.Displayed
        })
      )

      break
    }

    case trackMapBoxLoaded.type: {
      trackMapBoxLoadedTiming(dispatch)

      break
    }

    // static map location
    case trackStaticLocationMapBoxDisplayed.type: {
      logTimeStart('StaticLocationMapView')

      break
    }

    case trackStaticLocationMapBoxLoaded.type: {
      trackStaticLocationMapBoxLoadedTiming(dispatch, action.payload.team)

      break
    }

    // dynamic map location
    case trackInteractiveLocationMapBoxDisplayed.type: {
      logTimeStart('InteractiveLocationMapView')
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.MapView,
          action: Action.Displayed,
          team: action.payload?.team
        })
      )

      break
    }

    case trackInteractiveLocationMapBoxLoaded.type: {
      trackInteractiveLocationMapBoxLoadedTiming(dispatch, action.payload.team)

      break
    }

    case trackProviderRedirect.type: {
      const params = getProviderRedirectParams(
        action.payload as TrackProviderRedirectParams
      )(state)

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AnalyticsUtils.trackEvent('ProviderRedirected', params)
      break
    }

    case hotelsAvailabilityReceived.type: {
      trackAnchorHotelFirstAvailableDatesTiming(dispatch)
      break
    }

    case hotelsAvailabilityCompleted.type: {
      trackAnchorHotelAllAvailableDatesTiming(dispatch)
      break
    }

    case colorPriceCalendarAvailabilityCompleted.type: {
      const availableDates = !isEmpty(action.payload.mappedResponse)
        ? Object.keys(Object.values(action.payload.mappedResponse)[0])
        : []

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.ColorPriceCalendarAvailability,
          action: Action.Succeeded,
          payload: {
            startDate: action.payload.parameters.startDate,
            endDate: action.payload.parameters.endDate,
            availableDates
          }
        })
      )

      break
    }

    case trackAccommodationPageReady.type: {
      const startDate = localStorage.getItem('navigationToAccommodationPage')
      if (!startDate) return
      const start = Number(startDate)
      const now = Date.now()
      const isAPOverlay = location.pathname.includes('/Search')
      const entity = isAPOverlay ? Entity.APOverlay : Entity.APStandalone
      dispatch(
        trackEvent(getTrackTimingEventPayload('PageReady', now - start, entity))
      )
      break
    }

    case trackAccommodationPageLoad.type: {
      const startDate = localStorage.getItem('navigationToAccommodationPage')
      if (!startDate) return
      const start = Number(startDate)
      const now = Date.now()
      const isAPOverlay = location.pathname.includes('/Search')
      const entity = isAPOverlay ? Entity.APOverlay : Entity.APStandalone
      if (!isAPOverlay) {
        // Just to have the experiment side in the metrics
        toggle('c04d87a7-preheat-sapi-rooms', false, true)
      }
      dispatch(
        trackEvent(getTrackTimingEventPayload('PageLoad', now - start, entity))
      )
      localStorage.removeItem('navigationToAccommodationPage')
      break
    }

    case markAccommodationPageLoad.type: {
      const {isRoomsFirstImageLoaded, isStaticMapImageLoaded} =
        state.accommodation
      if (!isStaticMapImageLoaded) break
      const brandHasRooms = getMetaBrandHasRooms(state)

      const unavailableRooms = getIsRoomsResultEmpty(state)
      const {rooms} = getFilteredRoomsData(state)
      const roomsWithImages = rooms.filter(room =>
        Boolean(room.images?.[0]?.url)
      )
      const hasRooms = brandHasRooms || unavailableRooms

      const hasImageLoadedOrNoImages =
        isRoomsFirstImageLoaded ||
        (rooms.length && !roomsWithImages.length) ||
        !hasRooms

      if (hasImageLoadedOrNoImages && isStaticMapImageLoaded)
        dispatch(trackAccommodationPageLoad())
      break
    }

    default: {
      break
    }
  }

  return next(action)
}
/* eslint-enable complexity */

export default middleware
