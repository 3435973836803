import {loadModule} from 'utils'

import type {SortByMobileContentProps} from './SortByMobileContent'

export const SortByMobileContentLoadable = loadModule<SortByMobileContentProps>(
  async () =>
    import(
      /* webpackChunkName: "SortByMobileContent" */
      './SortByMobileContent'
    ),
  {}
)
