import {useDispatch, useSelector} from 'react-redux'

import {trackEvent} from '../../analytics/modules/actions'
import {Action, Category, Entity, Team} from '../../analytics/types/Events'
import {getUser} from '../../auth/modules/selectors'
import {getCognitoCustomAppData} from '../../auth/modules/selectors.shared'
import {useFetchAclQuery} from '../services/index'

export const FetchAclDecision = ({anonymousId}: {anonymousId: string}) => {
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const userAppData = getCognitoCustomAppData(user)

  // Calls should be skipped when users is not authenticated, or if user is authenticated and contains phone_verified
  const shouldSkipCall = Boolean(
    !user || !anonymousId || userAppData?.phone_verified
  )

  const {data} = useFetchAclQuery(anonymousId, {skip: shouldSkipCall})

  if (data) {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.ACL,
        action: Action.Served,
        team: Team.Retention,
        payload: {
          isCustomer: data.isCustomer,
          aclDecision: data.aclDecision
        }
      })
    )
  }

  return null
}
