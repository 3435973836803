import React from 'react'
import {VariantLabel} from 'components/Offer/Shared/VariantLabel'
import {SplitBookingBundleType} from 'components/Offer/types'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface MainProps {
  isSplitBooking?: false
  splitBookingType?: never
  hotelId: string
  offer: SearchOffer
  anchorPrice: number
  anchorPriceTotal: number
  offerVariant: OfferVariant
  hotelHasMoreThanOneOffer: boolean
  hasTag: boolean
}

interface SplitBookingProps {
  isSplitBooking: true
  splitBookingType: SplitBookingBundleType
  hasTag: boolean
  hotelId?: never
  offer?: never
  anchorPrice?: never
  anchorPriceTotal?: never
  offerVariant?: never
  hotelHasMoreThanOneOffer?: never
}

export const TagComponent: React.FC<MainProps | SplitBookingProps> = ({
  offerVariant,
  offer,
  anchorPrice,
  anchorPriceTotal,
  splitBookingType,
  hotelHasMoreThanOneOffer = false,
  hasTag = true
}) => {
  const {isMobile} = useDeviceLayout()

  if (!hasTag) {
    return null
  }

  if (splitBookingType === 'split_flow') {
    return <VariantLabel hasPadding variant={OfferVariant.SplitBooking} />
  }

  const hasBestDealTag = !isMobile && hotelHasMoreThanOneOffer
  if (offerVariant === OfferVariant.BestDeal && !hasBestDealTag) {
    return null
  }

  if (offerVariant !== OfferVariant.Popular) {
    return (
      <VariantLabel
        hasPadding
        variant={offerVariant}
        offer={offer}
        anchorPrice={anchorPrice}
        anchorPriceTotal={anchorPriceTotal}
      />
    )
  }

  return null
}
