import {useCallback, useEffect} from 'react'
import {useSelector} from 'react-redux'

import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'

import {useLogIn} from './useLogIn'

const susiQueryParam = 'showSusi'
const showSusi = '1'

export const getHasSusiInUrl = () => {
  const queryParams = new URLSearchParams(location.search)
  return queryParams.get(susiQueryParam) === showSusi
}

export const removeSusiQueryParam = () => {
  const urlObject = new URL(location.href)
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('showSusi')
  urlObject.search = searchParams.toString()
  history.replaceState(history.state, '', urlObject.toString())
}

export const useSUSIBottomSheet = () => {
  const logIn = useLogIn()
  const isLoading = useSelector(selectIsLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const getSusiInUrl = useCallback(getHasSusiInUrl, [])
  const hasSusiInUrl = getSusiInUrl()

  useEffect(() => {
    if (!isLoading && !isAuthenticated && hasSusiInUrl) {
      logIn({
        sourceComponent: SourceComponentType.Login,
        flow: AuthenticationFlows.UnlockOverlay,
        anonymousId: getAnonymousId() as string
      })
    }
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    if (!isLoading && isAuthenticated && hasSusiInUrl) {
      removeSusiQueryParam()
    }
  }, [isLoading, isAuthenticated])
}
