import {SearchOffer} from 'modules/sapiSearch/slice'
import {stringify} from 'qs'
import {pickBy} from 'ramda'

import {SplitBookingBundle} from '@findhotel/sapi'

import {RoomOfferUrlError} from './errors'

import type {BaseUrlCheckoutParams} from '@daedalus/core/src/utils/url/types/UrlParams'

export const getClickedBundleId = (): string => {
  const search = new URLSearchParams(location.search)
  return search.get('clickedBundle')
}

export const getClickedOfferId = (): string => {
  const search = new URLSearchParams(location.search)
  return search.get('clickedOffer')
}

export const isVioOfferOrBundleSelected = (): boolean => {
  return Boolean(getClickedOfferId() || getClickedBundleId())
}

type RoomsSearchParams = Partial<{
  bundleId: string
  clickedOfferBaseRate: number
  clickedOfferId: string
  clickedOfferTaxes: number
  clickedOfferHotelFees: number
  providerCode: string
}>

export const getRoomsSearchParamsFromApUrl = (): RoomsSearchParams => {
  const search = new URLSearchParams(location.search)

  const bundleId = search.get('clickedBundle')
  const clickedOfferId = search.get('clickedOffer')
  const providerCode = search.get('clickedProvider')
  const clickedBase = search.get('clickedBase')
  const clickedFees = search.get('clickedFees')
  const clickedTaxes = search.get('clickedTaxes')

  const clickedOfferBaseRate =
    clickedBase !== null ? Number(search.get('clickedBase')) : null
  const clickedOfferHotelFees =
    clickedFees !== null ? Number(search.get('clickedFees')) : null
  const clickedOfferTaxes =
    clickedTaxes !== null ? Number(search.get('clickedTaxes')) : null

  const clickedOffer = pickBy(prop => prop !== null, {
    bundleId,
    clickedOfferBaseRate,
    clickedOfferId,
    clickedOfferTaxes,
    clickedOfferHotelFees,
    providerCode
  })
  return bundleId || clickedOfferId ? clickedOffer : undefined
}

interface GetCheckoutLinkParams {
  /**
   * The checkout URL received from the SAPI offer links
   */
  checkoutUrl: string
  /**
   * The params to be used in the checkout URL. SAPI rooms offer already have some params by default but we need to extend them with additional data
   */
  paymentPageParams: Partial<BaseUrlCheckoutParams>
}

/**
 *   Returns single url string composed of an object of params, used for both SB and regular room offers.
 *   Preserves existing query parameters from the base URL and extends them with additional parameters.
 * @param checkoutUrl - The checkout URL received from the SAPI offer links
 * @param paymentPageParams - additional params to be added to the checkout URL
 */
export const getCheckoutLink = ({
  checkoutUrl,
  paymentPageParams
}: GetCheckoutLinkParams): string => {
  try {
    const baseUrl = new URL(checkoutUrl)
    const existingParams = Object.fromEntries(baseUrl.searchParams.entries())

    const mergedParams = {
      ...paymentPageParams,
      ...existingParams
    }

    const searchParams = stringify(mergedParams)

    return new URL(
      `${baseUrl.origin}${baseUrl.pathname}?${searchParams}`
    ).toString()
  } catch (error) {
    Sentry.captureException(
      new RoomOfferUrlError('Room offer URL is invalid', error),
      {extra: {checkoutUrl, paymentPageParams}}
    )
    return undefined
  }
}

export const extractSplitBookingParams = (
  splitBookingBundle: SplitBookingBundle
) => {
  if (!splitBookingBundle) return {}
  return {
    bundleId: splitBookingBundle.bundleId,
    clickedOfferBaseRate: splitBookingBundle.totalRate.base,
    clickedOfferTaxes: splitBookingBundle.totalRate.taxes,
    clickedOfferHotelFees: splitBookingBundle.totalRate.hotelFees,
    providerCode: splitBookingBundle?.offers[0].offer.providerCode
  }
}

export const extractRegularOfferParams = (offer: SearchOffer) => {
  if (!offer) return {}
  return {
    clickedOfferId: offer.id,
    clickedOfferBaseRate: offer.rate.base,
    clickedOfferTaxes: offer.rate.taxes,
    clickedOfferHotelFees: offer.rate.hotelFees,
    providerCode: offer.providerCode
  }
}
