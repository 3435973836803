import {useMemo} from 'react'

import {BrandCode} from '../brand/types'
import {
  applyRoundingStrategy,
  RoundingStrategyVariants,
  toDecreasePercentage
} from '../utils/number'

const SAVING_THRESHOLD = 5
const ALLOWED_CURRENCIES_TO_SHOW_PRICE = [
  'EUR',
  'USD',
  'GBP',
  'AUD',
  'CHF',
  'KWD',
  'SGD',
  'NZD',
  'BHD',
  'CAD'
]
export type VioTagSavingType = 'percentage' | 'number'

interface Props {
  price: number
  anchorPrice: number
  currencyCode: string
  isVioOffer: boolean
  brandCode: BrandCode
}

export interface VioTagDetails {
  savingPrice: number
  savingPercentage: number
  isVioTagVisible: boolean
  savingType: 'number' | 'percentage'
}

export const useVioTag = ({
  price = 0,
  anchorPrice = 0,
  currencyCode = '',
  isVioOffer = true,
  brandCode
}: Partial<Props>): VioTagDetails => {
  const roundedPrice =
    applyRoundingStrategy(RoundingStrategyVariants.round, price) ?? 0
  const roundedAnchorPrice =
    applyRoundingStrategy(RoundingStrategyVariants.round, anchorPrice) ?? 0
  const savingPercentage =
    toDecreasePercentage(
      roundedPrice,
      roundedAnchorPrice,
      RoundingStrategyVariants.floor
    ) ?? 0

  const isVioTagVisible: boolean = useMemo(
    () =>
      savingPercentage > SAVING_THRESHOLD && brandCode === 'vio' && isVioOffer,
    [savingPercentage, brandCode, isVioOffer]
  )

  const savingType: VioTagSavingType = useMemo(() => {
    if (
      savingPercentage > SAVING_THRESHOLD &&
      ALLOWED_CURRENCIES_TO_SHOW_PRICE.includes(currencyCode)
    )
      return 'number'
    return 'percentage'
  }, [savingPercentage, currencyCode])

  return {
    savingPrice: roundedAnchorPrice - roundedPrice,
    savingPercentage,
    isVioTagVisible,
    savingType
  }
}
