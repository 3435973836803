import * as React from 'react'
import {ErrorBoundary, FallbackProps} from 'react-error-boundary'
import {useDispatch} from 'react-redux'
import debounce from 'lodash/debounce'
import {
  Source,
  trackSystemPageErrored
} from 'middleware/analytics/actions/trackSystemPageErrored'
import handleFatalError from 'modules/common/actions/errors'

import {ErrorBoundaryFallback} from '@daedalus/shared/src/search/ErrorBoundaryFallback'

import {DELAY_TRACK_ERROR_EVENT_TIME} from './constants'

interface Props {
  children?: React.ReactNode
}

const ErrorBoundaryFallbackWithOnClick: React.FC<FallbackProps> = props => {
  const navigateToHomepage = React.useCallback(() => {
    window.location.href = '/'
  }, [])
  return <ErrorBoundaryFallback onClick={navigateToHomepage} {...props} />
}

export const ErrorBoundaryWrapper: React.FC<Props> = ({children}) => {
  const dispatch = useDispatch()

  const handleTrackSystemPageErrored = React.useCallback(
    (message: string) => {
      dispatch(
        trackSystemPageErrored({
          payload: {
            source: Source.AppErrorBoundary,
            message
          }
        })
      )
    },
    [dispatch]
  )

  const handleFatalErrorAction = (error: Error, errorInfo: React.ErrorInfo) => {
    dispatch(
      handleFatalError({
        error: error.message,
        errorInfo: errorInfo.componentStack
      })
    )
  }

  const debouncedTrackError = React.useMemo(
    () => debounce(handleTrackSystemPageErrored, DELAY_TRACK_ERROR_EVENT_TIME),
    [handleTrackSystemPageErrored]
  )

  const errorHandler = (error: Error, errorInfo: React.ErrorInfo) => {
    handleFatalErrorAction(error, errorInfo)
    debouncedTrackError(error.message)
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallbackWithOnClick}
      onError={errorHandler}
    >
      {children}
    </ErrorBoundary>
  )
}
