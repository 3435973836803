import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {lastDayOfMonth, startOfMonth} from 'date-fns'
import addMonths from 'date-fns/addMonths'

import {
  AvailabilityResults,
  AvailabilitySearchParameters,
  SapiClient
} from '@findhotel/sapi'

import {calculateAverageAvailabilitySapi} from '../../../availability/utils/calculateAverageAvailability'
import {
  MappingContext,
  sapiAvailabilityToDaedalusModel
} from '../../../offer/business/offersMapping'
import {numberOfRooms} from '../../../room/business/roomConfiguration'
import {getSapiClient} from '../../../sapi/services'
import {SearchParameters} from '../../../sapi/types'
import {dateFormat, ensureCheckInOnOrAfterToday} from '../../../utils/date'
import {getFetchedAvailabilityAllMonths} from './selectors'
import {
  colorPriceCalendarAvailabilityCompleted,
  colorPriceCalendarAvailabilityReceived,
  colorPriceCalendarAvailabilityRequested,
  setAvailabilityError
} from './slice'

const SAPI_VALID_DATE_FORMAT = 'yyyy-MM-dd'

interface PricingContext {
  includeTaxes: boolean
  includeLocalTaxes: boolean
  includeRoomsInNightlyPrice: boolean
}

interface ColorPriceCalendarParameters {
  searchParameters: SearchParameters
  pricingContext: PricingContext
}

const getSapiAvailability = async (
  availabilityParameters: Parameters<SapiClient['hotelAvailability']>,
  onError?: (error: Error) => void
) => {
  try {
    const [parameters, callbacks] = availabilityParameters
    const sapiClient = await getSapiClient()

    await sapiClient.hotelAvailability(parameters, callbacks)

    return
  } catch (error) {
    onError?.(error as Error)
  }
}

/**
 * Custom hook for fetching and managing color price calendar data.
 *
 * @param parameters - The parameters needed to fetch the color price calendar data.
 * @returns - An object containing the request function, the availability prices, the completion status and the error.
 */
export const useColorPriceCalendar = ({
  searchParameters,
  pricingContext
}: ColorPriceCalendarParameters) => {
  const dispatch = useDispatch()
  const fetchedAvailabilityMonths = useSelector(getFetchedAvailabilityAllMonths)

  const {rooms, cugDeals, tier, originId, checkIn, checkOut} =
    searchParameters || {}
  const {includeTaxes, includeLocalTaxes, includeRoomsInNightlyPrice} =
    pricingContext || {}

  const initialParams = useMemo(() => {
    return {
      nights: 1,
      rooms,
      originId: originId ?? '',
      cugDeals,
      tier: tier ?? ''
    }
  }, [cugDeals, originId, rooms, tier])

  const getAvailabilityCallbacks = useCallback(
    (parameters: AvailabilitySearchParameters) => {
      const mappingContext: MappingContext = {
        checkIn,
        checkOut,
        numberOfRooms: numberOfRooms(rooms),
        includeTaxes,
        includeLocalTaxes,
        includeRoomsInNightlyPrice
      }

      return {
        onStart: () => {
          dispatch(
            colorPriceCalendarAvailabilityRequested({
              parameters
            })
          )
        },
        onAvailabilityReceived: (response: AvailabilityResults) => {
          const averagedResponse = calculateAverageAvailabilitySapi(response)
          const mappedResponse = sapiAvailabilityToDaedalusModel(
            averagedResponse,
            mappingContext,
            true
          )

          dispatch(
            colorPriceCalendarAvailabilityReceived({
              parameters,
              mappedResponse
            })
          )
        },
        onComplete: (response: AvailabilityResults) => {
          const averagedResponse = calculateAverageAvailabilitySapi(response)
          const mappedResponse = sapiAvailabilityToDaedalusModel(
            averagedResponse,
            mappingContext,
            true
          )

          dispatch(
            colorPriceCalendarAvailabilityCompleted({
              parameters,
              mappedResponse
            })
          )
        }
      }
    },
    [
      dispatch,
      checkIn,
      checkOut,
      rooms,
      includeTaxes,
      includeLocalTaxes,
      includeRoomsInNightlyPrice
    ]
  )

  const onError = useCallback(
    (error: Error, parameters: AvailabilitySearchParameters) => {
      dispatch(
        setAvailabilityError({error: new Error('Error', error), parameters})
      )
    },
    [dispatch]
  )

  const requestOneMonthAvailability = useCallback(
    (date: Date, hotelIds: string[]) => {
      const startDate = dateFormat(
        ensureCheckInOnOrAfterToday(startOfMonth(date)),
        SAPI_VALID_DATE_FORMAT
      )

      const hasFetchedAvailability = fetchedAvailabilityMonths.includes(
        startDate.substring(0, 7)
      )

      if (hotelIds.length && !hasFetchedAvailability) {
        const endDate = dateFormat(lastDayOfMonth(date), SAPI_VALID_DATE_FORMAT)
        const parameters: AvailabilitySearchParameters = {
          startDate,
          endDate,
          hotelIds,
          ...initialParams
        }

        const availabilityCallbacks = getAvailabilityCallbacks(parameters)

        getSapiAvailability([parameters, availabilityCallbacks], error =>
          onError(error, parameters)
        )
      }
    },
    [
      getAvailabilityCallbacks,
      initialParams,
      onError,
      fetchedAvailabilityMonths
    ]
  )

  const requestTwoMonthsAvailability = useCallback(
    (date: Date, hotelIds: string[]) => {
      requestOneMonthAvailability(date, hotelIds)
      requestOneMonthAvailability(addMonths(date, 1), hotelIds)
    },
    [requestOneMonthAvailability]
  )

  return {
    requestOneMonthAvailability,
    requestTwoMonthsAvailability
  }
}
