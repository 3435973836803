import React from 'react'
import {useSelector} from 'react-redux'
import {useSearchBox} from 'hooks/searchBox'
import {getUrlParams as getSearchUrlParams} from 'modules/search/selectors'
import {getHotelApiRoomsParameters} from 'modules/searchApi/selectors'
import {SearchTrigger} from 'modules/searchBox/types'
import {generateAccommodationPageURL} from 'utils/url'

import {getUrlParams} from '@daedalus/core/src/_web/utils/url'

import {SearchForm} from './SearchForm'

export type UpdateSearch = (updateParams: {
  checkIn: string
  checkOut: string
  rooms: string
  searchTrigger: SearchTrigger
}) => void

export const SearchBox = () => {
  const {checkIn, checkOut, hotelId} = useSelector(getSearchUrlParams)

  const {rooms} = useSelector(state =>
    getHotelApiRoomsParameters(state, hotelId)
  )

  const {doSearch} = useSearchBox()

  const updateSearch: UpdateSearch = ({
    checkIn,
    checkOut,
    rooms,
    searchTrigger
  }) => {
    const params = getUrlParams()
    const redirectId = params?.redirectId as string
    doSearch({
      newUrlParams: {
        checkIn,
        checkOut,
        rooms,
        redirectId
      },
      searchTrigger,
      searchOptions: {
        basePath: generateAccommodationPageURL(hotelId, '')
      }
    })
  }

  return (
    <SearchForm
      checkIn={checkIn}
      checkOut={checkOut}
      rooms={rooms}
      updateSearch={updateSearch}
    />
  )
}
