import {uniqBy} from 'ramda'

import {UserSearchHistoryItem} from './types'

/**
 * Filters out unique searches from the user's search history.
 * Uniqueness is determined based on the presence of either
 * `hotelId` or `placeId` in the search parameters.
 *
 * @param searchHistory - An array of {@link UserSearchHistoryItem}.
 * @returns An array consisting of unique {@link UserSearchHistoryItem} objects based on `hotelId` or `placeId`.
 */
const selectUniqueSearches = (searchHistory: UserSearchHistoryItem[]) => {
  const getUniqueIdentifier = ({
    searchParams: {hotelId, placeId, address}
  }: UserSearchHistoryItem) => hotelId || placeId || address

  return uniqBy(getUniqueIdentifier, searchHistory)
}

export {selectUniqueSearches}
