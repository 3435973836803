import {SplitBookingDetails} from 'components/Offer/types'
import {isSmsLockedOffer} from 'hooks/useIsRequiredOneTimePasswordFlow'
import {listDisplayedTagsForOffer} from 'modules/analytics/utils'
import {AppLockedDealConfig} from 'modules/sapiSearch/selectors'
import {HotelOfferEntity, SearchOffer} from 'modules/sapiSearch/slice'
import {getTopOffers, getTotalPrice} from 'modules/sapiSearch/utils'
import {isEmpty} from 'ramda'
import {
  getCheapestTopOfferIndex,
  getOfferPositionFromEntity
} from 'utils/offers'

import {SearchOfferContext} from '@daedalus/core/src/analytics/types/Events'
import {Source} from '@daedalus/core/src/api-types/bovio/response/booking'
import {getHasFreeCancellation} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {
  calculateChargeDate,
  hasChargeLaterTag
} from '@daedalus/core/src/offer/business/chargeLater'
import {getMealsAmenities} from '@daedalus/core/src/offer/business/meal'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {isPrivateDeal} from '@daedalus/core/src/offer/business/privateDeals'
import {OfferTagLabel} from '@daedalus/core/src/offer/types/offer'
import {DeviceCategory} from '@daedalus/core/src/utils/userAgent/types'

export interface SplitBookingDataProps {
  splitBookingPosition: number
  isSplitBooking: boolean
  offerPositionInsideBundle: 'a' | 'b'
}

/**
 * Factory function that return split booking state and position from raw data
 * Also, it returns the bundleId and the split booking data for single flow split booking
 */
const getSplitBookingContext = (
  offerId: string,
  hotelOfferEntity: HotelOfferEntity,
  splitBookingDetails: SplitBookingDetails
) => {
  const {hasSplitBookingOffer, splitBookingType, splitBookingOffers} =
    splitBookingDetails || {}
  const {splitBooking} = hotelOfferEntity || {}
  const sbOfferIndex = splitBookingOffers?.findIndex(
    ({offer}) => offer.id === offerId
  )

  if (hasSplitBookingOffer && sbOfferIndex >= 0) {
    const hasFreeCancellation =
      getHasFreeCancellation(
        splitBookingOffers[0].offer?.cancellationPenalties
      ) &&
      getHasFreeCancellation(splitBookingOffers[1].offer?.cancellationPenalties)
    return {
      isSplitBooking: true,
      bundleId: splitBooking?.bundleId,
      splitBooking: {
        ...splitBooking,
        splitBookingType,
        hasFreeCancellation
      }
    }
  }

  return {
    isSplitBooking: false
  }
}

/**
 * Build/compute offer context from raw data
 */
export const buildOfferContext = (
  offerId: string,
  sourceComponent: string,
  hotelOfferEntity: HotelOfferEntity,
  offer: SearchOffer,
  numberOfRooms: number,
  shouldSeeOffersUnlocked: boolean,
  isOTAMode: boolean,
  appLockedDealConfig: AppLockedDealConfig,
  splitBookingDetails: SplitBookingDetails,
  deviceLayout: DeviceCategory
): SearchOfferContext => {
  if (!offer || !hotelOfferEntity) return

  const {offerPosition, offerPositionFromOneWithSuffix} =
    getOfferPositionFromEntity(
      offerId,
      hotelOfferEntity,
      splitBookingDetails,
      sourceComponent,
      deviceLayout
    )

  const {isSplitBooking, bundleId, splitBooking} = getSplitBookingContext(
    offerId,
    hotelOfferEntity,
    splitBookingDetails
  )

  const totalPrice = getTotalPrice(offer.rate, Number(numberOfRooms))
  const topOffers = getTopOffers(isOTAMode, hotelOfferEntity)

  const cheapestOfferIndex = getCheapestTopOfferIndex(topOffers)

  const {feedID, providerRateType} = offer.metadata
  const providerFeedId = isEmpty(feedID) ? undefined : feedID
  const rateType = isEmpty(providerRateType) ? undefined : providerRateType
  const isCheapest = cheapestOfferIndex === offerPosition
  const isPrivateDealOffer = isPrivateDeal(offer, shouldSeeOffersUnlocked)
  const isAnchorPriceOffer = hasOfferTag(
    offer,
    OfferTagLabel.IsAnchorPriceOffer
  )
  const isTopOffer = hasOfferTag(offer, OfferTagLabel.IsTopOffer)
  const isCheapestRAA = hasOfferTag(offer, OfferTagLabel.IsCheapest)
  const meals = getMealsAmenities(offer)
  const hasFreeCancellation = getHasFreeCancellation(
    offer?.cancellationPenalties
  )

  const displayedTags = listDisplayedTagsForOffer({
    isCheapestOffer: isCheapest,
    isFirstOfferInList: offerPosition === 0
  })

  const isChargeLaterEligible = hasChargeLaterTag(offer)

  const chargeDate = calculateChargeDate(offer.cancellationPenalties)
  const chargeType = isChargeLaterEligible
    ? [Source.ChargeLater]
    : [Source.Default]

  const paymentExpiryAt = {
    ...(isChargeLaterEligible && {chargeExpiryAt: chargeDate?.toISOString()})
  }

  const isSmsLocked = isSmsLockedOffer(
    offer,
    appLockedDealConfig?.isAppLockedDeal
  )

  return {
    offerId,
    roomName: offer.roomName,
    meals,
    displayPricePerRoomPerNight: offer.nightlyRate,
    totalPrice,
    rateBreakdown: {
      baseRate: offer.rate.base,
      localTaxes: offer.rate.hotelFees,
      taxes: offer.rate.taxes
    },
    offerPosition: offerPositionFromOneWithSuffix,
    osoOfferPosition: isSplitBooking
      ? offerPositionFromOneWithSuffix
      : offer.osoOfferPosition,
    offerTags: offer.tags,
    availableRooms: offer.availableRooms,
    canPayLater: offer.package.canPayLater || isChargeLaterEligible,
    chargeType,
    hasFreeCancellation,
    isAnchorPriceOffer,
    isCheapest,
    isCheapestRAA,
    isPrivateDeal: isPrivateDealOffer,
    isSharedRoom: offer['isSharedRoom'],
    isStatic: false,
    isTopOffer,
    providerCode: offer.providerCode,
    // TODO: This field does not exist in the offer model (neither V3 and the old one)
    // and is always undefined. Should I clean it up?
    cancellationPolicy: offer['cancellationPolicy'],
    cancellationPenalties: offer.cancellationPenalties,
    bookURI: offer.url,
    cug: offer.accessTier ? [offer.accessTier] : null,
    providerFeedId,
    proxyProviderCode: offer.intermediaryProvider,
    providerRateType: rateType,
    displayedTags,
    numberOfRooms,
    isAppExclusiveRate: appLockedDealConfig?.isAppLockedDeal,
    originalAccessTier: offer.metadata?.originalAccessTier,
    ...(isChargeLaterEligible && {paymentExpiryAt}),
    sourceComponent,
    // Split booking
    isSplitBooking,
    bundleId,
    splitBooking,
    isSmsLocked
  }
}
