import {ShareType} from 'components/ShareButton/constants'
import {SEARCH_ROUTE} from 'constants/index'
import {toLower} from 'ramda'

const trackedSourceTypes = new Set([
  ShareType.Email,
  ShareType.WhatsApp,
  ShareType.ShareLink
])

export type SearchProps = {
  hotelId: string
  checkIn: string
  checkOut: string
  rooms: string
  component: string
}
export type ParamsTypes = SearchProps & {
  shareType: ShareType
}

export const createHotelShareUrl = ({
  hotelId,
  checkIn,
  checkOut,
  shareType,
  rooms,
  component = 'hoteldetails'
}: ParamsTypes) => {
  if (!hotelId) return null
  const {origin} = new URL(window.location.href)
  const params = new URLSearchParams({
    hotelId,
    checkIn,
    checkOut,
    rooms,
    showHotelDetails: '1',
    utm_medium: 'referral',
    utm_campaign: toLower(component),
    // we only want to add utm_source for specific share types
    ...(trackedSourceTypes.has(shareType) && {utm_source: toLower(shareType)})
  })
  return `${origin}${SEARCH_ROUTE}?${params.toString()}`
}
