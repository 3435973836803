import React, {lazy, ReactNode, Suspense, useEffect} from 'react'

import {AnimationType} from '@daedalus/atlas/Overlay'
import {OverlayPage} from '@daedalus/atlas/OverlayPage'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {
  RoomConfigType,
  RoomSplitType
} from '@daedalus/core/src/room/types/RoomConfiguration'

export interface Props {
  roomConfigType?: RoomConfigType
  isOpen: boolean
  roomsSplit: RoomSplitType[]
  submitButtonMessage?: ReactNode
  onSubmit: (roomsSplit: RoomSplitType[], optimizeRooms?: boolean) => void
  onClose: () => void
  onRoomsChange: (roomsSplit: RoomSplitType[]) => void
  onOptimizeRoomsChange: (isOptimizeRooms: boolean) => void
  onInfoOpen?: () => void
  onGuestConfigOptionClick?: (optionSelected: RoomConfigType) => void
  animationType?: AnimationType
}

const GuestRoomConfigOverlayContent = lazy(
  () =>
    import(
      /* webpackChunkName: "GuestRoomConfigOverlayContent" */
      './GuestRoomConfigOverlayContent'
    )
)

export const GuestRoomConfigOverlay = ({
  isOpen,
  roomsSplit,
  submitButtonMessage,
  onSubmit,
  onClose,
  onRoomsChange,
  onOptimizeRoomsChange,
  onInfoOpen,
  onGuestConfigOptionClick,
  roomConfigType = RoomConfigType.Optimized,
  animationType
}: Props) => {
  useEffect(() => {
    import(
      /* webpackChunkName: "GuestRoomConfigOverlayContent" */
      /* webpackPrefetch: true */
      './GuestRoomConfigOverlayContent'
    )
  }, [])

  return (
    <OverlayPage
      isOpen={isOpen}
      titleBarProps={{
        centerContent: (
          <FormattedMessageWrapper
            dataId="guestsRooms"
            id="guestsRooms"
            defaultMessage="Guests and rooms"
          />
        )
      }}
      onClose={onClose}
      animationType={animationType}
    >
      {isOpen && (
        <Suspense fallback={null}>
          <GuestRoomConfigOverlayContent
            onGuestConfigOptionClick={onGuestConfigOptionClick}
            onInfoOpen={onInfoOpen}
            onOptimizeRoomsChange={onOptimizeRoomsChange}
            onRoomsChange={onRoomsChange}
            onSubmit={onSubmit}
            roomConfigType={roomConfigType}
            roomsSplit={roomsSplit}
            submitButtonMessage={submitButtonMessage}
          />
        </Suspense>
      )}
    </OverlayPage>
  )
}
