import {matchPath} from 'react-router-dom'

import {ACCOMMODATION_ROUTE, SEARCH_ROUTE} from '../constants'

/**
 * AP search behavior should always be used on the AP, use this util in selectors etc.
 */
export const getIsAccommodationPage = () => {
  const matchSearch = matchPath(location.pathname, {path: SEARCH_ROUTE})
  if (matchSearch) return false

  const matchAccommodation = matchPath(location.pathname, {
    path: ACCOMMODATION_ROUTE
  })
  return Boolean(matchAccommodation)
}
