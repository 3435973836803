import {loadModule} from 'utils'

import type {ReviewScoreBottomSheetContentProps} from './ReviewScoreBottomSheetContent'

export const ReviewScoreBottomSheetContentLoadable =
  loadModule<ReviewScoreBottomSheetContentProps>(
    async () =>
      import(
        /* webpackChunkName: "ReviewScoreBottomSheetContent" */
        './ReviewScoreBottomSheetContent'
      ),
    {}
  )
