import {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getRoomContext} from 'middleware/analytics/selectors'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'

import {RoomDetailsMountComponent} from './constants'

import type {Room} from '@daedalus/core/src/room/types/room'

type PropsTypes = {
  room: Room
  component: RoomDetailsMountComponent
}

export const useTrackRoomGallerySwipe = ({room, component}: PropsTypes) => {
  const dispatch = useDispatch()
  const roomContext = useSelector(state => getRoomContext(state, room?.id))
  const trackGallerySwipe = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        action: Action.Scrolled,
        entity: Entity.RoomImageGallery,
        team: Team.Select,
        component,
        analyticsContext: {
          [AnalyticsContext.RoomContext]: roomContext
        }
      })
    )
  }, [dispatch, roomContext, component])

  return trackGallerySwipe
}
