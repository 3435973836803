import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getReviewScoreFilterVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'
import {searchParamToFacet} from 'modules/search/constants'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import {Flex} from '@daedalus/atlas/helpers/Flex'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReviewScoreBottomSheetContentLoadable} from './Loadable'

const filterKeys = [searchParamToFacet.reviewScore.param]

export const ReviewScoreBottomSheet = () => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()
  const {toggleFilters, getFilters, submitFilters, resetFilters, clearFilters} =
    useFiltersForm(filterKeys)

  const {reviewScore} = getFilters()

  const isOpen = useSelector(getReviewScoreFilterVisibility)

  const handleApply = useCallback(() => {
    submitFilters()
  }, [submitFilters])

  const handleClear = () => {
    if (reviewScore) {
      clearFilters()
    } else {
      dispatch(hideOverlay())
    }
  }

  const handleClose = () => {
    dispatch(hideOverlay())
    resetFilters()
  }

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={handleClose}
      titleBarProps={{
        centerContent: formatMessage(filterMessages.reviewScore)
      }}
      bottomBarContent={
        <Flex.Row
          justifyContent="space-between"
          data-id="reviewScoreBottomSheetButtons"
        >
          <Button
            dataId="clearFiltersButton"
            onClick={handleClear}
            variant="secondary"
          >
            <FormattedMessageWrapper id="clear" defaultMessage="Clear" />
          </Button>
          <Button dataId="applyFiltersButton" onClick={handleApply}>
            <FormattedMessageWrapper id="apply" defaultMessage="Apply" />
          </Button>
        </Flex.Row>
      }
    >
      {isOpen && (
        <ReviewScoreBottomSheetContentLoadable
          reviewScore={reviewScore}
          toggleFilters={toggleFilters}
        />
      )}
    </BottomSheet>
  )
}
