import React from 'react'
import {ThemeType} from 'types/Theme'

import {ANIMATION_TYPES, Overlay} from '@daedalus/atlas/Overlay'
import {Pill, PillVariant} from '@daedalus/atlas/Pill'
import {Popover, PopoverPlacement} from '@daedalus/atlas/Popover'

import {FilterDisplayContent} from './FilterDisplayContent'
import {overlayStyles} from './styles'

export enum DisplayType {
  Popover = 'popover',
  Overlay = 'overlay'
}

export interface FilterDisplayContainerProps {
  /** The type of container to render */
  type?: DisplayType
  /** The pill label */
  label: React.ReactNode
  /** The header title of the popover / overlay */
  title: React.ReactNode
  /** The header subtitle of the popover / overlay */
  subtitle?: React.ReactNode
  /** Whether the pill is in active state */
  isActive: boolean
  /** The children to render within the popover / overlay */
  children: React.ReactNode
  /** The icon that should be rendered in the pill */
  icon?: React.ReactElement
  /** If an arrow should be rendered in the pill */
  showArrow?: boolean
  /** The count that should be rendered in the pill */
  count?: number
  /** Callback fired on clear button click */
  onClear: () => void
  /** Callback fired on apply button click */
  onApply: () => void
  /** Callback fired when the popover / overlay closes */
  onClose?: () => void
  /** Callback fired on click of the pill */
  onPillClick?: () => void
  /** Element identification */
  dataId?: string
  /** Pill variant */
  pillVariant?: PillVariant
  /** Padding size to add to the bottom. Default is `s600` */
  paddingBottom?: keyof ThemeType['layout']['spacing']
}

/**
 * FilterDisplayContainer is a visual component that allows you to pass a filter body to show inside a popover or overlay.
 *
 * It uses a FilterPill component as a trigger, and has built-in controls to Clear and Apply the values of the filter.
 * State for the form is not managed by this component, you should do that in your own component.
 */
export const FilterDisplayContainer: React.FC<FilterDisplayContainerProps> = ({
  type = DisplayType.Popover,
  label,
  title,
  subtitle,
  children,
  icon,
  dataId = '',
  isActive,
  showArrow = true,
  count,
  onClear,
  onApply,
  onClose,
  onPillClick,
  pillVariant,
  paddingBottom = 's600'
}) => {
  const pillRef = React.useRef<HTMLButtonElement>()
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClear = React.useCallback(() => {
    onClear()
  }, [onClear])

  const handleOpenClose = React.useCallback(() => {
    setIsOpen(!isOpen)
    if (onClose && isOpen) onClose()
  }, [onClose, setIsOpen, isOpen])

  const handlePillClick = React.useCallback(() => {
    handleOpenClose()
    if (onPillClick) onPillClick()
  }, [handleOpenClose, onPillClick])

  const handleApply = React.useCallback(() => {
    onApply()
    setIsOpen(false)
  }, [onApply, setIsOpen])

  const handleOutsideClick = React.useCallback(
    (e: MouseEvent) => {
      /**
       * We close the Popover if we click anywhere except the Pill component itself
       */
      if (pillRef.current && !pillRef.current.contains(e.target as Node)) {
        handleOpenClose()
      }
    },
    [pillRef, handleOpenClose]
  )

  const content = (
    <FilterDisplayContent
      dataId={dataId}
      title={title || label}
      subtitle={subtitle}
      type={type}
      onClose={handleOpenClose}
      onClear={handleClear}
      onApply={handleApply}
      paddingBottom={paddingBottom}
    >
      {children}
    </FilterDisplayContent>
  )

  return (
    <div>
      <Pill
        ref={pillRef}
        variant={pillVariant}
        isSelected={isActive}
        dataId={dataId}
        iconStart={icon}
        showArrow={showArrow}
        count={count}
        onClick={handlePillClick}
      >
        {label}
      </Pill>

      {type === DisplayType.Popover && (
        <Popover
          isOpen={isOpen}
          placement={PopoverPlacement.BottomLeft}
          anchorRef={pillRef}
          verticalOffset={16}
          onOutsideClick={handleOutsideClick}
          dataId={`popover-${dataId}`}
        >
          {content}
        </Popover>
      )}

      {type === DisplayType.Overlay && (
        <Overlay
          isOpen={isOpen}
          customStyles={overlayStyles}
          animationType={ANIMATION_TYPES.FADE_IN_OUT}
          onClose={handleOpenClose}
          onShadeBackgroundClick={handleOpenClose}
        >
          {content}
        </Overlay>
      )}
    </div>
  )
}
