import React from 'react'
import {SplitBookingOfferInfo} from 'components/Offer/types'

import {Box} from '@daedalus/atlas/helpers/Box'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {OfferLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {OfferSecondaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {OfferLogo} from '../OfferLogo'
import {shouldOfferBeBranded} from '../utils'

type ProviderLogoVariants = Exclude<
  OfferLayoutVariant,
  OfferSecondaryLayoutVariant
>

interface MainProps {
  variant: ProviderLogoVariants
  offer: SearchOffer
  isAppLockedDeal: boolean
  isSplitBooking?: false
  splitBookingOffers?: never[]
  isRequiredOneTimePasswordFlow?: boolean
}

interface SplitBookingProps {
  variant: ProviderLogoVariants
  isSplitBooking: true
  isAppLockedDeal?: never
  splitBookingOffers: SplitBookingOfferInfo[]
  offer?: never
  isRequiredOneTimePasswordFlow?: boolean
}

export const ProviderLogoComponent: React.FC<MainProps | SplitBookingProps> = ({
  isSplitBooking = false,
  splitBookingOffers = [],
  offer,
  variant,
  isAppLockedDeal,
  isRequiredOneTimePasswordFlow
}) => {
  const {brand, brandCode, brandIsVio} = useBrand()

  if (isSplitBooking) {
    const firstOffer = splitBookingOffers[0]?.offer
    const secondOffer = splitBookingOffers[1]?.offer

    const isSameProvider =
      firstOffer.intermediaryProvider === secondOffer.intermediaryProvider

    const isFirstOfferBranded = shouldOfferBeBranded({
      offerIntermediaryProviderCode: firstOffer?.intermediaryProvider,
      bookOnBrand: brand.bookOnBrand,
      brandIsVio
    })

    const isSecondOfferBranded = shouldOfferBeBranded({
      offerIntermediaryProviderCode: secondOffer?.intermediaryProvider,
      bookOnBrand: brand.bookOnBrand,
      brandIsVio
    })

    return (
      <Box
        data-id="Logo"
        display="flex"
        flexDirection="column"
        gap="s200"
        marginTop="s200"
        marginBottom={variant !== 'PrimaryDefault' ? 's200' : '0'}
      >
        <OfferLogo
          providerCodeOverride={
            (isAppLockedDeal || isFirstOfferBranded) && brandCode
          }
          offer={firstOffer}
          isSplitBooking
          isPrimaryOffer
        />
        {!isSameProvider && (
          <OfferLogo
            offer={secondOffer}
            isSplitBooking
            isPrimaryOffer
            providerCodeOverride={isSecondOfferBranded && brandCode}
          />
        )}
      </Box>
    )
  }

  const {intermediaryProvider} = offer || {}

  const isBrandedDeal = shouldOfferBeBranded({
    offerIntermediaryProviderCode: intermediaryProvider,
    bookOnBrand: brand.bookOnBrand,
    brandIsVio
  })

  return (
    <Box
      data-id="Logo"
      display="flex"
      flexDirection="column"
      gap="s200"
      marginTop="s200"
      marginBottom={variant !== 'PrimaryDefault' ? 's200' : '0'}
    >
      <OfferLogo
        offer={offer}
        isPrimaryOffer
        providerCodeOverride={(isAppLockedDeal || isBrandedDeal) && brandCode}
        isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
      />
    </Box>
  )
}
