export type RoomSplitType = {
  adults: number
  children: number[]
}

export type UniqueRoomSplitType = {
  adults: number
  children: number[]
  id: string
}

export type RoomConfigurationType = {
  numberOfAdults: number
  numberOfChildren: number
  numberOfRooms: number
  roomsSplit: RoomSplitType[]
}

export interface Room {
  bedTypeName: string
  boardType: string
  confirmationId: string
  firstName: string
  lastName: string
  name: string
  smoking: boolean
  specialRequest: string
  guests: Guests
  id: string
}

export interface Guests {
  adults: number
  childAges: number[]
  children: number
}

export enum RoomConfigType {
  Optimized = 'optimized',
  Manual = 'manual'
}
