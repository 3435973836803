import React from 'react'

import {Avatar, AvatarType} from '@daedalus/atlas/Avatar'
import {getUserName} from '@daedalus/core/src/auth/business/authentication'
import {User} from '@daedalus/core/src/auth/types/Cognito'

type Props = {
  user: User | null
}

export const UserAvatar = ({user}: Props) => {
  const picture = user?.attributes?.picture

  return (
    <Avatar
      picture={picture}
      type={picture ? AvatarType.Picture : AvatarType.Icon}
      name={getUserName(user || null)}
      borderColorPath="border.special.c300"
    />
  )
}
