import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'
import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  BaseOfferContext,
  Category,
  Entity,
  Page
} from '@daedalus/core/src/analytics/types/Events'
import {Source} from '@daedalus/core/src/api-types/bovio/response/booking'
import {getIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {Room as RoomType} from '@daedalus/core/src/room/types/room'
import {Hotel, Room} from '@findhotel/sapi'
import {ContentHotel} from '@findhotel/sapi/dist/types/packages/core/src/types/types'

import {useLogIn} from '../../../authentication/hooks/useLogIn'
import {
  DealFreezeAnalyticsContext,
  openFreezeOverlay,
  SearchDetail,
  setAnalyticsContext
} from '../../modules/slice'
import {ComponentSource} from '../../types'
import {LeadContextRequiredData} from './type'
import {useCreateAnalyticsLeadContext} from './useCreateAnalyticsLeadContext'
import {useTrackOfferView} from './useTrackButtonView'

export interface Props {
  offerId: string
  hotel: Hotel | ContentHotel
  searchDetail: SearchDetail
  providerCode: string
  /**
   * This property should be included wherever the SAPI room is available, such as in the RSP and AP room contexts.
   */
  room?: Room | RoomType
  /**
   * This value should be passed in from `client` package
   */
  rspUrl?: string
  analyticsContext: DealFreezeAnalyticsContext
  /**
   * To be provided from `client` package. It is used to receive price rates, which are essential for creating parameters
   * for SAPI room requests. For more details, refer to the `useGetSapiRoom` hook.
   **/
  searchOffer?: SearchOffer
  /**
   * Required from `client` package to be used to simulate redirect event and create leadContext
   */
  leadContextRequiredData?: LeadContextRequiredData
  fullWidth?: boolean
  /**
   * specifies which component CTA is rendered in
   */
  component: ComponentSource | string
}

export const DealFreezeButton: React.FC<Props> = ({
  room,
  fullWidth,
  offerId,
  hotel,
  searchDetail,
  providerCode,
  rspUrl,
  analyticsContext,
  searchOffer,
  leadContextRequiredData,
  component
}) => {
  const dispatch = useDispatch()
  const hotelId = hotel?.objectID
  const isAuthenticated = useSelector(getIsAuthenticated)

  const logIn = useLogIn()
  const {ref, inView} = useInView({triggerOnce: true, threshold: 0.5})
  const createAnalyticsLeadContext = useCreateAnalyticsLeadContext({
    ...leadContextRequiredData,
    offerId
  })
  useTrackOfferView({offerId, inView, analyticsContext, component})

  const anonymousId = getAnonymousId() as string

  const openOverlay = () => {
    dispatch(setAnalyticsContext(analyticsContext))
    const offer = {id: offerId, rspUrl, providerCode, searchOffer}
    dispatch(
      openFreezeOverlay({
        offer,
        hotel,
        searchDetail,
        room: room && [room as Room],
        component
      })
    )
    createAnalyticsLeadContext()
  }

  const openSignInOverlay = () => {
    logIn({
      anonymousId,
      sourceComponent: SourceComponentType.DealFreezeButton,
      unlockHotelId: hotelId,
      flow: AuthenticationFlows.DealFreeze
    })
  }

  const trackOfferClick = useCallback(() => {
    const {offerContext, priceContext, leadContext, hotelContext} =
      analyticsContext
    const offerContextWithChargeType = {
      ...offerContext,
      chargeType: [Source.DealFreeze]
    }
    const availableAnalyticsContexts = {
      ...(offerContext && {
        [AnalyticsContext.OfferContext]:
          offerContextWithChargeType as BaseOfferContext
      }),
      ...(priceContext && {[AnalyticsContext.PriceContext]: priceContext}),
      ...(leadContext && {[AnalyticsContext.LeadContext]: leadContext}),
      ...(hotelContext && {[AnalyticsContext.HotelContext]: hotelContext})
    }

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DealFreezeOfferButton,
        action: Action.Clicked,
        analyticsContext: {...availableAnalyticsContexts},
        page: Page.Accommodation,
        component
      })
    )
  }, [analyticsContext, component, dispatch])

  const onButtonClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    event.stopPropagation()

    trackOfferClick()

    if (!isAuthenticated) {
      openSignInOverlay()
      return
    }

    openOverlay()
  }

  return (
    <Button
      dataId="FreezeDeal"
      ref={ref}
      iconEnd={<Icon name={'Ice'} />}
      fullWidth={fullWidth}
      onClick={onButtonClick}
      variant="special"
    >
      <FormattedMessageWrapper
        id="dealFreeze.freezeDeal"
        defaultMessage="Freeze deal"
      />
    </Button>
  )
}
