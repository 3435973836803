import {RoomArea} from '../../types/room'

export const IMPERIAL_UNIT = 'ft²'
export const METRIC_UNIT = 'm²'

export const getRoomAreaWithUnits = (
  roomArea: RoomArea | undefined,
  userCountry: string | undefined
) => {
  if (!roomArea || Object.keys(roomArea).length === 0) return null

  const {squareFeet, squareMeters} = roomArea
  const isImperialRoomAreaCountry = userCountry
    ? ['US', 'UK', 'CA', 'LR', 'MM'].includes(userCountry)
    : false

  const areaUnit = isImperialRoomAreaCountry ? IMPERIAL_UNIT : METRIC_UNIT
  const areaValue = areaUnit === IMPERIAL_UNIT ? squareFeet : squareMeters
  const roundedArea = Math.round(areaValue as number)
  return `${roundedArea} ${areaUnit}`
}
