import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {
  UpdateLocationOptionsType,
  useUrlUpdater
} from 'components/data/WithUrlUpdater'
import {useSapi} from 'modules/sapiSearch/SapiProvider'
import {getSearchParametersForSearchBox} from 'modules/sapiSearch/selectors'
import {userLocationMessages} from 'modules/sapiSearch/useSapiSearch'
import {SearchType} from 'modules/sapiSearch/utils'
import {
  getIsFsmr,
  getIsUserOrUrlLocationSearch,
  getPersistedBaseUrlParams,
  getPersistedUrlParams,
  getSearchDisplayName
} from 'modules/search/selectors'
import {getSerializedLandingQuery} from 'modules/search/selectors'
import {
  LocationSearchType,
  setUserLocationSearchType,
  userSearchedFromHome
} from 'modules/search/slice'
import {SearchUrlParams} from 'modules/search/types'
import {
  getIsSearchBoxOpen,
  getSearchBoxAncestor,
  getSearchBoxDestinationDisplayValue,
  getSearchBoxSearchType,
  getSearchBoxValues
} from 'modules/searchBox/selectors'
import {
  setIsSearchBoxActive,
  setOpenDatePickerType,
  setSearchBoxDestinationDisplayValue,
  setSearchBoxValue,
  setSearchBoxValues,
  setSearchTrigger,
  setSuggestions
} from 'modules/searchBox/slice'
import {
  DatePickerType,
  SearchBoxAncestor,
  SearchBoxValues,
  SearchTrigger
} from 'modules/searchBox/types'
import {
  cleanSearchBoxValues,
  getValidSearchBoxValues
} from 'modules/searchBox/utils'
import {equals, isEmpty} from 'ramda'
import {buildSapiSuggestions} from 'services/search/Suggests'
import {getDestinationIds, getDestinationParameter} from 'utils/searchParams'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {logTimeStart} from '@daedalus/core/src/_web/utils/logging/performanceTimer'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {RoomConfigType} from '@daedalus/core/src/room/types/RoomConfiguration'
import {clearPriceColorCalendarAvailability} from '@daedalus/core/src/search/modules/ColorPriceCalendar/slice'

import {useSetDeviceGeolocationSearchParams} from './useSetDeviceGeolocationSearchParams'

interface DoSearchParams {
  newUrlParams?: Partial<SearchUrlParams>
  searchTrigger?: SearchTrigger
  searchOptions?: UpdateLocationOptionsType
  isFreeTextSearch?: boolean
}

export type SearchLocationType = 'hotel' | 'place' | 'area' | 'address'
export interface RecentSearchParams {
  type?: Omit<SearchLocationType, 'area'>
  value: string
  checkIn: string
  checkOut: string
  rooms: string
}

export type RecentSearchSelectParams = {
  additionalValues?: Partial<SearchBoxValues>
  recentSearch: RecentSearchParams
  searchOptions?: UpdateLocationOptionsType
  searchTrigger?: SearchTrigger
}

export enum OpenedFormControl {
  NONE = 'none',
  DATE_PICKER = 'datePicker',
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
  GUEST = 'guest',
  DESTINATION = 'destination'
}

export type OnDestinationChangeParams = {
  displayValue: string
  type: Omit<SearchLocationType, 'area'>
  value: string
}

interface InitialDatePickerValues {
  checkIn?: string
  checkOut?: string
}

interface InitialGuestPickerValues {
  rooms?: string
  optimizeRooms?: BooleanUrlParam | boolean
}

/**
 * Checks if the specified ancestor's search box is open
 * @param ancestor - The search box ancestor to check
 */
const useIsSearchBoxOpenByAncestor = (ancestor: SearchBoxAncestor): boolean => {
  const {isMobile} = useDeviceLayout()
  const searchBoxIsOpen = useSelector(getIsSearchBoxOpen)
  const activeAncestor = useSelector(getSearchBoxAncestor)

  const isPageSearchBoxOpen = searchBoxIsOpen && activeAncestor === ancestor
  return isMobile && isPageSearchBoxOpen
}

/**
 * Checks if the "page" ancestor's search box is open
 */
export const useIsPageSearchBoxOpen = (): boolean =>
  useIsSearchBoxOpenByAncestor(SearchBoxAncestor.Page)

/**
 * @returns A set of utils and values for managing the destination picker
 */
export const useSearchBoxDestinationPicker = () => {
  const dispatch = useDispatch()
  const {doSearch} = useSearchBox()
  const {formatMessage} = useIntl()
  const {setDeviceGeolocationSearchParams} =
    useSetDeviceGeolocationSearchParams()

  const [destinationHasError, setDestinationHasError] = useState<boolean>(false)
  const [destinationErrorMessage, setDestinationErrorMessage] =
    useState<string>('')
  const {suggest} = useSapi()
  const searchType = useSelector(getSearchBoxSearchType)
  const isAreaSearch = searchType === SearchType.Area
  const isUserOrUrlLocationSearch = useSelector(getIsUserOrUrlLocationSearch)
  const isUserLocationSearch =
    searchType === SearchType.Location && isUserOrUrlLocationSearch

  const searchBoxDestinationDisplayValue = useSelector(
    getSearchBoxDestinationDisplayValue
  )
  const searchDisplayName = useSelector(getSearchDisplayName)
  const destinationPickerInputDisplayValue =
    isAreaSearch || isUserLocationSearch
      ? '' // if it's an area or user location search leave the input empty
      : searchBoxDestinationDisplayValue
  const destinationDisplayValue =
    searchBoxDestinationDisplayValue !== searchDisplayName &&
    searchBoxDestinationDisplayValue !== ''
      ? searchBoxDestinationDisplayValue // if the search box value is different to the search display name, use it
      : searchDisplayName // otherwise, use the search display name

  const isReactNative = isReactNativeWebView()

  /**
   * Creates a new destination search params object from the passed in params and clears the destination error message, if necessary.
   * Updates the search box values with the destination search params object. Also updates the destination display value and search box path.
   * @param OnDestinationChangeParams -
   */
  const handleDestinationChange = useCallback(
    ({displayValue, type, value}: OnDestinationChangeParams): void => {
      const parameters = {
        placeId: undefined,
        hotelId: undefined,
        address: undefined,
        boundingBox: undefined,
        userLocationSearch: undefined,
        ...getDestinationParameter(type as string, value)
      }

      if (
        destinationHasError &&
        (parameters.placeId ||
          parameters.hotelId ||
          displayValue ||
          parameters.address)
      ) {
        setDestinationHasError(false)
      }

      dispatch(setSearchBoxValues(parameters))
      dispatch(setSearchBoxDestinationDisplayValue(displayValue))
      dispatch(clearPriceColorCalendarAvailability())
    },
    [destinationHasError, dispatch]
  )

  /**
   * Creates a new search params object from the passed in recent Search object and additionalValues.
   * Updates the search box values with the new search params object. Also updates the destination display value and search box path.
   * Starts a new search with the new search params object and the passed in searchOptions.
   * @param RecentSearchSelectParams -
   */
  const handleRecentSearchSelect = useCallback(
    ({
      additionalValues,
      recentSearch,
      searchOptions,
      searchTrigger = SearchTrigger.RecentSearches
    }: RecentSearchSelectParams): void => {
      const {type, value, checkIn, checkOut, rooms} = recentSearch
      const parameters = {
        placeId: undefined,
        hotelId: undefined,
        boundingBox: undefined,
        userLocationSearch: undefined,
        checkIn,
        checkOut,
        rooms,
        ...getDestinationParameter(type as string, value),
        ...additionalValues
      }

      dispatch(setSearchBoxValues(parameters))
      dispatch(setIsSearchBoxActive(false))

      doSearch({
        newUrlParams: {
          ...parameters
        },
        searchTrigger,
        searchOptions
      })
    },
    [dispatch, doSearch]
  )

  /**
   * Dispatches a new clicked event
   */
  const handleDestinationPickerInputClick = useCallback((): void => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.DestinationPickerInput,
        action: Action.Clicked
      })
    )
  }, [dispatch])

  /**
   * Makes a new SAPI request for suggestions based on the passed in value and maps the response to match our requirements.
   * Dispatches a setSuggestions event with the mapped response.
   * @param value -
   * @param shouldLoadUserSearchHistory -
   */
  const handleSuggestionsRequested = useCallback(
    async (value: string, shouldLoadUserSearchHistory: boolean) => {
      const response = await suggest(value)

      const mappedResponse = buildSapiSuggestions(
        response,
        shouldLoadUserSearchHistory,
        !value,
        searchType
      )

      dispatch(
        setSuggestions({
          suggestions: mappedResponse,
          suggestionsSearchValue: value
        })
      )
    },
    [suggest, dispatch, searchType]
  )

  /**
   * Dispatches a new clicked event for the nearby properties option, gets the geoposition and sets it to the store and optionally updates the URL with the user location search value.
   */
  const handleNearbyPropertiesSelect = useCallback(
    async (shouldTriggerSearch = true) => {
      const newParams: Partial<SearchUrlParams> = {
        userLocationSearch: 1,
        placeId: undefined,
        hotelId: undefined,
        boundingBox: undefined
      }

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.DestinationPickerNearbyOption,
          action: Action.Clicked
        })
      )

      dispatch(setSearchBoxValues(newParams))

      if (isReactNative) {
        dispatch(setUserLocationSearchType(LocationSearchType.IpLocation))
      } else {
        setDeviceGeolocationSearchParams()
      }

      if (shouldTriggerSearch) {
        doSearch({
          newUrlParams: newParams,
          searchTrigger: SearchTrigger.UserLocation
        })
      } else {
        // If we don't want to update the location, we need set the search display name so that the searchbox shows "current location"
        dispatch(
          setSearchBoxDestinationDisplayValue(
            formatMessage(userLocationMessages.userLocation)
          )
        )
      }
    },
    [
      dispatch,
      doSearch,
      setDeviceGeolocationSearchParams,
      isReactNative,
      formatMessage
    ]
  )

  return {
    onRecentSearchSelected: handleRecentSearchSelect,
    onDestinationChange: handleDestinationChange,
    onDestinationPickerInputClick: handleDestinationPickerInputClick,
    destinationHasError,
    destinationErrorMessage,
    destinationPickerInputDisplayValue,
    destinationDisplayValue,
    setDestinationErrorMessage,
    setDestinationHasError,
    onSuggestionsRequested: handleSuggestionsRequested,
    onNearbyPropertiesSuggestionSelected: handleNearbyPropertiesSelect
  }
}

/**
 * @returns A set of utils and values for managing the date picker
 */
export const useSearchBoxDatePicker = (
  initialDatePickerValues?: InitialDatePickerValues
) => {
  const dispatch = useDispatch()
  const teamEventDispatch = useDispatchTeamEvent()
  const {checkIn, checkOut} = useSelector(getSearchBoxValues)

  const {checkIn: initialCheckInDate, checkOut: initialCheckOutDate} =
    initialDatePickerValues || {}

  const [initialCheckInState, setInitialCheckInState] =
    useState(initialCheckInDate)
  const [initialCheckOutState, setInitialCheckOutState] =
    useState(initialCheckOutDate)

  useEffect(() => {
    setInitialCheckInState(initialCheckInDate)
    setInitialCheckOutState(initialCheckOutDate)
  }, [
    setInitialCheckInState,
    setInitialCheckOutState,
    initialCheckInDate,
    initialCheckOutDate
  ])

  /**
   * Saves the new user dates to local storage. Updates the search box params with the new checkInDate and checkOutDate.
   * @param checkInDate -
   * @param checkOutDate -
   */
  const handleDateChange = useCallback(
    (checkInDate: string, checkOutDate: string): void => {
      const dates = {
        checkIn: checkInDate,
        checkOut: checkOutDate
      }

      dispatch(setSearchBoxValues(dates))
    },
    [dispatch]
  )

  /**
   * Dispatches a new clicked event
   * @param name - The selected date type
   * @param value - The selected date value
   */
  const handleDatePickerDayClick = useCallback(
    (name: string, value: string): void => {
      teamEventDispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.DatePickerDay,
          action: Action.Clicked,
          component: name,
          payload: {date: value}
        })
      )
    },
    [teamEventDispatch]
  )

  /**
   * Dispatches an action to open the specified date picker
   * @param type - The date picker type
   */
  const handleDatePickerOpen = useCallback(
    (type: DatePickerType) => {
      dispatch(setOpenDatePickerType(type))
    },
    [dispatch]
  )

  /**
   * Dispatches an action to close the specified date picker
   */
  const handleDatePickerClose = useCallback(() => {
    dispatch(setOpenDatePickerType(undefined))
  }, [dispatch])

  /**
   * Dispatches a new clicked event
   * @param name - The selected date type
   */
  const handleDatePickerInputClick = useCallback(
    (name: string): void => {
      teamEventDispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.DatePickerInput,
          action: Action.Clicked,
          component: name
        })
      )
    },
    [teamEventDispatch]
  )
  /**
   * Sets the initial state to the current search box values
   */
  const handlesDatePickerSubmit = useCallback(() => {
    setInitialCheckInState(checkIn)
    setInitialCheckOutState(checkOut)
  }, [checkIn, checkOut])

  /**
   * Resets the date picker values to the initial state
   */
  const handleDatePickerReset = useCallback(() => {
    dispatch(
      setSearchBoxValues({
        checkIn: initialCheckInState,
        checkOut: initialCheckOutState
      })
    )
  }, [dispatch, initialCheckInState, initialCheckOutState])

  return {
    onDatePickerClose: handleDatePickerClose,
    onDateChange: handleDateChange,
    onDatePickerDayClick: handleDatePickerDayClick,
    onDatePickerOpen: handleDatePickerOpen,
    onDatePickerInputClick: handleDatePickerInputClick,
    onDatePickerSubmit: handlesDatePickerSubmit,
    onDatePickerReset: handleDatePickerReset
  }
}

/**
 * @returns A set of utils and values for managing the guest picker
 */
export const useSearchBoxGuestPicker = (
  initialGuestPickerValues?: InitialGuestPickerValues
) => {
  const dispatch = useDispatch()
  const {rooms, optimizeRooms} = useSelector(getSearchBoxValues)

  const {
    rooms: initialRoomsParam,
    optimizeRooms: initialOptimizeRoomsFromParams
  } = initialGuestPickerValues || {}

  const [initialRoomsState, setInitialRoomsState] = useState(initialRoomsParam)
  const [initialOptimizeRoomsState, setInitialOptimizeRoomsState] = useState(
    initialOptimizeRoomsFromParams
  )

  useEffect(() => {
    setInitialRoomsState(initialRoomsParam)
    setInitialOptimizeRoomsState(initialOptimizeRoomsFromParams)
  }, [
    setInitialRoomsState,
    setInitialOptimizeRoomsState,
    initialRoomsParam,
    initialOptimizeRoomsFromParams
  ])

  /**
   * Updates the search box params with the new rooms value.
   * @param rooms - The new rooms value (in string format)
   */
  const handleGuestRoomsChange = useCallback(
    (rooms: string) => {
      dispatch(
        setSearchBoxValue({
          name: 'rooms',
          value: rooms
        })
      )
    },
    [dispatch]
  )

  /**
   * Updates the search box params with the new optimizeRooms value.
   * @param optimizeRooms - Whether the rooms for this search should be optimized for price
   */
  const handleGuestOptimizeRoomsChange = useCallback(
    (optimizeRooms: boolean): void => {
      dispatch(
        setSearchBoxValue({
          name: 'optimizeRooms',
          value: optimizeRooms
        })
      )
    },
    [dispatch]
  )

  /**
   * Dispatches a new clicked event
   */
  const handleGuestPickerClick = useCallback((): void => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.GuestPickerInput,
        action: Action.Clicked
      })
    )
  }, [dispatch])

  /**
   * Dispatches a new submit event and sets the initial state to the current search box values
   */
  const handleGuestPickerSubmit = useCallback((): void => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.GuestPickerSubmitButton,
        action: Action.Clicked
      })
    )

    setInitialRoomsState(rooms)
    setInitialOptimizeRoomsState(optimizeRooms)
  }, [dispatch, rooms, optimizeRooms])

  /**
   * Resets the guest picker values to the initial state
   */
  const handleGuestPickerReset = useCallback((): void => {
    dispatch(
      setSearchBoxValues({
        rooms: initialRoomsState,
        optimizeRooms: initialOptimizeRoomsState
      })
    )
  }, [dispatch, initialRoomsState, initialOptimizeRoomsState])

  /**
   * Dispatches a new display event
   */
  const handleGuestPickerDisplayed = useCallback((): void => {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.GuestPicker,
        action: Action.Displayed
      })
    )
  }, [dispatch])

  /**
   * Dispatches a new guest picker info display event
   */
  const handleGuestPickerOptionInfoDisplayed = useCallback((): void => {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.OptimizeRoomsInfo,
        action: Action.Displayed
      })
    )
  }, [dispatch])

  /**
   * Dispatches a new guest config preference click event
   */
  const handleGuestConfigPreferenceClick = useCallback(
    (selectedOption: RoomConfigType): void => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.GuestConfigPreference,
          action: Action.Clicked,
          payload: {selectedOption}
        })
      )
    },
    [dispatch]
  )

  return {
    onGuestRoomsChange: handleGuestRoomsChange,
    onGuestOptimizeRoomsChange: handleGuestOptimizeRoomsChange,
    onGuestPickerClick: handleGuestPickerClick,
    onGuestPickerSubmit: handleGuestPickerSubmit,
    onGuestPickerReset: handleGuestPickerReset,
    onGuestPickerDisplayed: handleGuestPickerDisplayed,
    onGuestPickerOptionInfoDisplayed: handleGuestPickerOptionInfoDisplayed,
    onGuestConfigPreferenceClick: handleGuestConfigPreferenceClick
  }
}

/**
 * @returns The doSearch util, which is used for making a search
 */
export const useSearchBox = (isOpen = true) => {
  const {changeLocation} = useUrlUpdater()

  const cachedSearchParameters = useSelector(getSearchParametersForSearchBox)
  const persistedUrlParams = useSelector(getPersistedUrlParams)
  const persistedBaseUrlParams = useSelector(getPersistedBaseUrlParams)
  const serializedLandingQuery = useSelector(getSerializedLandingQuery)
  const searchIsFsmr = useSelector(getIsFsmr)
  const dispatch = useDispatch()
  const teamEventDispatch = useDispatchTeamEvent()

  const searchBoxValues = useSelector(getSearchBoxValues)

  const {
    hotelId,
    placeId,
    checkIn,
    checkOut,
    boundingBox,
    rooms,
    userLocationSearch,
    optimizeRooms,
    address
  } = searchBoxValues

  /**
   * Creates a new URL params object with the values from the searchbox, manual `newUrlParams` and persisted params
   * Removes any falsy values from the URL params object
   * Sets the search trigger and updates the location with the new set of URL params and the passed in searchOptions.
   * @param DoSearchParams -
   */
  const doSearch = useCallback(
    ({
      newUrlParams = {},
      searchTrigger,
      searchOptions,
      isFreeTextSearch
    }: DoSearchParams): void => {
      const hasSearchBoxValuesChanged = !equals(
        searchBoxValues,
        cachedSearchParameters
      )

      const hasDestinationChanged =
        !isEmpty(newUrlParams) &&
        !equals(
          getDestinationIds(cachedSearchParameters),
          getDestinationIds(newUrlParams)
        )

      // kick off a new search only if values in the search box change
      // or if the destination only changes (recent search)
      if (
        hasSearchBoxValuesChanged ||
        hasDestinationChanged ||
        isFreeTextSearch
      ) {
        const landingParamsToRestore = getLandingParamsThatShouldBeRestored(
          serializedLandingQuery,
          searchBoxValues
        )

        const newOptimizeRooms =
          optimizeRooms === undefined ? searchIsFsmr : optimizeRooms

        const searchItem = {
          hotelId: isFreeTextSearch ? undefined : hotelId,
          placeId: isFreeTextSearch ? undefined : placeId,
          address:
            isFreeTextSearch || newUrlParams.placeId || newUrlParams.hotelId
              ? undefined
              : address,
          checkIn,
          checkOut,
          rooms
        }

        const finalUrlParams = {
          // destructured values from search box store so that we can pop them into the url in the order we prefer
          ...searchItem,
          boundingBox,
          userLocationSearch,
          optimizeRooms: newOptimizeRooms,
          // if the url params have changed we can always assume the user has initiated the search
          userSearch: '1',
          // params that should be persisted between searches
          ...(isFreeTextSearch ? persistedBaseUrlParams : persistedUrlParams),
          ...landingParamsToRestore,
          // finally add any new url params that should get added to the search (e.g homeSearch)
          ...newUrlParams
        }

        // remove falsy values from the url params
        const cleanedUrlParams: SearchBoxValues =
          // TODO: Search TS preexisting issue
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cleanSearchBoxValues(finalUrlParams)

        logTimeStart('search')
        dispatch(setSearchTrigger(searchTrigger))
        dispatch(clearPriceColorCalendarAvailability())
        changeLocation(cleanedUrlParams, {
          ...searchOptions
        })
      }
    },
    [
      searchBoxValues,
      cachedSearchParameters,
      changeLocation,
      dispatch,
      serializedLandingQuery,
      isOpen
    ]
  )

  /**
   * Dispatches a new clicked event when user presses the search button on home page
   */
  const handleSearchButtonClick = useCallback((): void => {
    const searchItem = {
      hotelId,
      placeId,
      address,
      checkIn,
      checkOut,
      rooms
    }

    dispatch(userSearchedFromHome())

    teamEventDispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.SearchBoxSearchButton,
        action: Action.Clicked,
        payload: {
          searchItem
        }
      })
    )
  }, [
    address,
    checkIn,
    checkOut,
    dispatch,
    hotelId,
    placeId,
    rooms,
    teamEventDispatch
  ])

  return {
    doSearch,
    onSearchButtonClick: handleSearchButtonClick
  }
}

/**
 * If the user is making the same search they landed with,
 * we want to restore all the original search params.
 *
 * This is mostly to keep meta pricing for the user,
 * and avoid showing different offers in this second search.
 */
function getLandingParamsThatShouldBeRestored(
  serializedLandingQuery: Record<string, string>,
  searchBoxValues: SearchBoxValues
): Record<string, string> {
  const validLandingQueryValuesWithFsmr = {
    ...getValidSearchBoxValues(serializedLandingQuery),
    fsmr: serializedLandingQuery.fsmr
  }
  const validSearchBoxValues = cleanSearchBoxValues(searchBoxValues)

  const isLandingSearch = equals(
    validLandingQueryValuesWithFsmr,
    validSearchBoxValues
  )

  return isLandingSearch ? serializedLandingQuery : {}
}
