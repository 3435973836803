import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useGenerateAppDownloadUrl from 'hooks/useGenerateAppDownloadUrl'
import {
  getDevicePlatform,
  getDeviceType,
  getLanguageCode
} from 'modules/meta/selectors'

import {Flex} from '@daedalus/atlas/helpers/Flex'
import {NoStyleLink} from '@daedalus/atlas/helpers/NoStyleLink'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {Placement} from '@daedalus/core/src/deepLinking/types'
import {
  AppleAppStoreBadge,
  GooglePlayBadge
} from '@daedalus/shared/src/search/footer/AppButtonsWidget/AppButtonsWidget'

export const GetTheAppButtons = ({
  alwaysShowButtons = false,
  placement
}: {
  alwaysShowButtons?: boolean
  placement: Placement
}) => {
  const dispatch = useDispatch()
  const deviceType = useSelector(getDeviceType)
  const devicePlatform = useSelector(getDevicePlatform)
  const lang = useSelector(getLanguageCode) as string
  const showButtons = alwaysShowButtons || deviceType === 'desktop'
  const {generateAppStoreDeepLink, generatePlayStoreDeepLink} =
    useGenerateAppDownloadUrl()
  const appStoreDownloadUrl = generateAppStoreDeepLink(placement)
  const playStoreDownloadUrl = generatePlayStoreDeepLink(placement)

  const handleGetAppClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      const targetElement = event.target as HTMLElement
      const link = targetElement.closest('[data-id]') as HTMLLinkElement
      const {href} = link

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.AppInstallPopUpStoreLinks,
          action: Action.Clicked,
          payload: {
            selectedElement: link?.dataset?.id
          }
        })
      )

      setTimeout(() => {
        window.open(href, '_blank')
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
    },
    [dispatch]
  )

  return (
    <Flex.Row gap="s500">
      {(showButtons || devicePlatform === 'iOS') && (
        <NoStyleLink
          onClick={handleGetAppClick}
          href={appStoreDownloadUrl}
          data-id="AppleAppStoreBadge"
        >
          <AppleAppStoreBadge lang={lang} />
        </NoStyleLink>
      )}
      {(showButtons || devicePlatform === 'android') && (
        <NoStyleLink
          onClick={handleGetAppClick}
          href={playStoreDownloadUrl}
          data-id="GooglePlayBadge"
        >
          <GooglePlayBadge lang={lang} />
        </NoStyleLink>
      )}
    </Flex.Row>
  )
}
