import {useSelector} from 'react-redux'

import {getIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {getPersistedConfiguration} from '@daedalus/core/src/offer/services/offerConfigurationApi/store'
import {Offer as OfferType} from '@daedalus/core/src/offer/types/offer'
import {Offer} from '@findhotel/sapi'

import {RootState} from '../../type'
import {
  getFrozenBookingByHotelId,
  getFrozenBookingByItinerary,
  getIsDealFreezeEnabled,
  getIsUserEligibleForDealFreeze,
  getOfferEncounteredError
} from '../modules/selector'
import {isOfferEligibleForDealFreeze} from '../utils/OfferEligibility'

interface Props {
  offer: Offer | OfferType
  checkIn: string
  checkOut: string
  currentDate: string
  isOfferAppLocked?: boolean
  hotelId?: string
}

export const useShouldShowDealFreezeButton = ({
  offer,
  checkIn,
  checkOut,
  currentDate,
  isOfferAppLocked = false,
  hotelId
}: Props) => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isDealFreezeEnabled = useSelector(getIsDealFreezeEnabled)
  const isUserEligibleForDealFreeze = useSelector(
    getIsUserEligibleForDealFreeze
  )
  const frozenHotel = useSelector((state: RootState) =>
    getFrozenBookingByHotelId(state, hotelId)
  )
  const isBofhOffer = getIsBofhOffer(offer as Offer)

  const frozenItinerary = useSelector((state: RootState) =>
    getFrozenBookingByItinerary(state, checkIn, checkOut, hotelId)
  )
  const offerEncounteredError = useSelector((state: RootState) =>
    getOfferEncounteredError(state, offer?.id)
  )
  const configuration = getPersistedConfiguration()
  const dealFreezeConfiguration = configuration.dealFreeze
  if (isOfferAppLocked) return false
  if (!dealFreezeConfiguration) return false

  if (offerEncounteredError) return false
  // Unauthenticated users should be able to see the button. When they authenticate, their eligibility will be checked via the backend API.
  const eligibilityStatus = !isAuthenticated || isUserEligibleForDealFreeze

  if (!isDealFreezeEnabled) return false

  /**
   *  For RSP: if there are frozen rooms with the same hotelId and the same dates,
   *  we refrain from showing the button entirely for that offer.
   */
  if (frozenItinerary) return false
  if (frozenHotel && isBofhOffer) return true

  return (
    isOfferEligibleForDealFreeze(
      offer,
      checkIn,
      currentDate,
      dealFreezeConfiguration
    ) && eligibilityStatus
  )
}
