import updateLocation from 'modules/common/actions/location'
import {Dispatch} from 'redux'

import {LayoutType, MapDisplayAction} from '../types'

export enum MapButtonPosition {
  Floating = 'Floating',
  Static = 'Static'
}

export const showMap = (dispatch: Dispatch) => {
  dispatch(
    updateLocation({
      params: {layout: LayoutType.MAP},
      method: 'push'
    })
  )
}

const showList = (dispatch: Dispatch) => {
  dispatch(
    updateLocation({
      params: {layout: LayoutType.LIST},
      method: 'push'
    })
  )
}

export const resetLayoutToDefault = (dispatch: Dispatch) => {
  dispatch(
    updateLocation({
      params: {layout: undefined},
      method: 'replace'
    })
  )
}

/**
 * Dispatches the correct event to hide, show, expand or minimize the map
 * @param requestedAction - an action that we want the map to do
 * @param withSplitView - whether the split view should be shown for relevant situations
 */
export const changeMapLayout =
  (requestedAction: MapDisplayAction, withSplitView: boolean) =>
  (dispatch: Dispatch) => {
    switch (requestedAction) {
      case MapDisplayAction.EXPAND:
        showMap(dispatch)
        break

      case MapDisplayAction.MINIMIZE:
        resetLayoutToDefault(dispatch)
        break

      case MapDisplayAction.HIDE:
        showList(dispatch)
        break

      case MapDisplayAction.SHOW:
        if (withSplitView) resetLayoutToDefault(dispatch)
        if (!withSplitView) showMap(dispatch)
        break

      default:
        break
    }
  }
