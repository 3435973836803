import React from 'react'
import {GetStaticProps} from 'next'
import dynamic from 'next/dynamic'

import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
/* useBrand cannot be used here because:
 * 1. This is a Next.js page with getStaticProps/getStaticPaths which run during build time
 * 2. React hooks cannot be used in server-side functions
 * 3. We need brand information during SSR before React hydration
 */
// eslint-disable-next-line @vio/custom-rules/prefer-usebrand-hook
import {
  brands,
  defaultBrand,
  defaultBrandCode,
  getBrandByCode
} from '@daedalus/core/src/brand/config'
import {Brand} from '@daedalus/core/src/brand/types'

export interface PageProps {
  brand: Brand
}

/**
 * Note that all app providers are loaded client side to ease the migration from CRA.
 * To move to SSR this will need to be decoupled.
 */
const AppProviders = dynamic(
  () =>
    import(
      /* webpackMode: "eager" */
      '../AppProviders'
    ),
  {
    ssr: false
  }
)

const AppRoutes = dynamic(
  () =>
    import(
      /* webpackMode: "eager" */
      '../AppRoutes'
    ),
  {
    ssr: false
  }
)

const Page = (props: PageProps) => {
  let {brand} = props

  // We're abusing how getStaticPaths work, and sometimes when
  // doing client side navigation brand will be lost.
  if (!brand) {
    if (typeof window !== 'undefined') {
      brand = getBrandByCode(getBrandCodeFromUrlOrCookie(window._env.APP_ENV))
    }
    brand = defaultBrand
  }

  return (
    <>
      <AppProviders>
        <AppRoutes />
      </AppProviders>

      {/* Note: we are rendering this at build time so don't use other conditions besides page props! (until we move to server render) */}
      {brand?.code === defaultBrandCode ? (
        <div id="splash_screen">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt="Vio.com"
            className="logo"
            src="/static/vio-logo-compact.svg"
          />
          <span className="loader"></span>
        </div>
      ) : null}
    </>
  )
}

export default Page

/**
 * Gets called at build time
 * It generates all the page slugs we want pages for (ie. one per brand)
 */
export async function getStaticPaths() {
  const paths = Object.values(brands).map(brand => {
    return {
      params: {
        slug: brand.code === defaultBrandCode ? [] : [`index-${brand.code}`]
      }
    }
  })

  return {paths, fallback: false} // { fallback: false } means other routes should 404.
}

/**
 * Also gets called at build time
 * It generates the pageProps for each page based on the params from ^
 * These will be available to `_app.tsx` and also the route itself
 */
export const getStaticProps: GetStaticProps<PageProps> = async ({params}) => {
  const brandCode = params.slug?.length
    ? params.slug[0].split('-')[1]
    : undefined

  const brand = getBrandByCode(brandCode) || null

  return {props: {brand}}
}
