/**
 * This is a vendorized & modified version of the `express-useragent` package to solve build issues in modern versions of Next.
 * Source here: https://github.com/biggora/express-useragent/tree/master
 *
 * Changes from source:
 * - converted to class syntax
 * - removed bonus exports
 * - removed `express` specific code & the `testNginxGeoIP` method
 * - dropped the `reset` method, we don't use it
 * - removed unnessecary escape characters in regexes (lint fix)
 * - misc. type fixes
 *
 * !! Here be dragons.
 * All other logic is left unchanged, however cursed. Fixing small quirks will turn into a full rewrite quickly due to the structure.
 * In that case we should re-review our actual requirements and consider other options.
 */

/* Mostly a copy of external code */
/* eslint-disable jsdoc/require-jsdoc */

import {UserAgentDetails} from './types'

export type {UserAgentDetails}

const BOTS = [
  '\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/',
  'ad\\smonitoring',
  'adsbot',
  'apex',
  'applebot',
  'archive.org_bot',
  'baiduspider',
  'bingbot',
  'chromeheadless',
  'cloudflare',
  'cloudinary',
  'crawler',
  'curl',
  'petalbot',
  'leikibot',
  'sirdatabot',
  'discordbot',
  'duckduckbot',
  'embedly',
  'exabot',
  'facebookexternalhit',
  'facebot',
  'flipboard',
  'google',
  'googlebot',
  'gsa-crawler',
  'gurujibot',
  'guzzlehttp',
  'heritrix',
  'ia_archiver',
  'insights',
  'linkedinbot',
  'ltx71',
  'mediapartners',
  'msnbot',
  'odklbot',
  'phantom\\.js',
  'phantomjs',
  'pingdom',
  'pinterest',
  'python',
  'rtlnieuws',
  'skypeuripreview',
  'slackbot',
  'slurp',
  'spbot',
  'telegrambot',
  'test\\scertificate',
  'testing',
  'tiabot',
  'tumblr ',
  'twitterbot',
  'vkshare',
  'web\\sscraper',
  'wget',
  'yandexbot',
  'yandeximages',
  'whatsapp',
  'orangebot',
  'smtbot',
  'qwantify',
  'mj12bot',
  'ahrefsbot',
  'seznambot',
  'panscient.com',
  'duckduckgo-favicons-bot',
  'uptimerobot',
  'semrushbot',
  'postman',
  'dotbot',
  'zoominfobot',
  'ifttt',
  'sogou',
  'ru_bot',
  'researchscan',
  'nimbostratus-bot',
  'slack-imgproxy',
  'node-superagent',
  'go-http-client',
  'jersey',
  'dataprovider.com',
  'github-camo',
  'dispatch',
  'checkmarknetwork',
  'screaming frog',
  'whatweb',
  'daum',
  'netcraftsurveyagent',
  'mojeekbot',
  'surdotlybot',
  'springbot'
]
const IS_BOT_REGEXP = new RegExp('^.*(' + BOTS.join('|') + ').*$')

const VERSIONS = {
  Edge: /(?:edge|edga|edgios|edg)\/([\d\w.-]+)/i,
  Firefox: /(?:firefox|fxios)\/([\d\w.-]+)/i,
  IE: /msie\s([\d.]+[\d])|trident\/\d+\.\d+;.*[rv:]+(\d+\.\d)/i,
  Chrome: /(?:chrome|crios)\/([\d\w.-]+)/i,
  Chromium: /chromium\/([\d\w.-]+)/i,
  Safari: /(version|safari)\/([\d\w.-]+)/i,
  Opera: /version\/([\d\w.-]+)|OPR\/([\d\w.-]+)/i,
  Ps3: /([\d\w.-]+)\)\s*$/i,
  Psp: /([\d\w.-]+)\)?\s*$/i,
  Amaya: /amaya\/([\d\w.-]+)/i,
  SeaMonkey: /seamonkey\/([\d\w.-]+)/i,
  OmniWeb: /omniweb\/v([\d\w.-]+)/i,
  Flock: /flock\/([\d\w.-]+)/i,
  Epiphany: /epiphany\/([\d\w.-]+)/i,
  WinJs: /msapphost\/([\d\w.-]+)/i,
  PhantomJS: /phantomjs\/([\d\w.-]+)/i,
  AlamoFire: /alamofire\/([\d\w.-]+)/i,
  UC: /ucbrowser\/([\d\w.]+)/i,
  Facebook: /FBAV\/([\d\w.]+)/i,
  WebKit: /applewebkit\/([\d\w.]+)/i,
  Wechat: /micromessenger\/([\d\w.]+)/i,
  Electron: /Electron\/([\d\w.]+)/i
}

const BROWSERS = {
  YaBrowser: /yabrowser/i,
  Edge: /edge|edga|edgios|edg/i,
  Amaya: /amaya/i,
  Konqueror: /konqueror/i,
  Epiphany: /epiphany/i,
  SeaMonkey: /seamonkey/i,
  Flock: /flock/i,
  OmniWeb: /omniweb/i,
  Chromium: /chromium/i,
  Chrome: /chrome|crios/i,
  Safari: /safari/i,
  IE: /msie|trident/i,
  Opera: /opera|OPR\//i,
  PS3: /playstation 3/i,
  PSP: /playstation portable/i,
  Firefox: /firefox|fxios/i,
  WinJs: /msapphost/i,
  PhantomJS: /phantomjs/i,
  AlamoFire: /alamofire/i,
  UC: /UCBrowser/i,
  Facebook: /FBA[NV]/
}

const OS = {
  Windows10: /windows nt 10\.0/i,
  Windows81: /windows nt 6\.3/i,
  Windows8: /windows nt 6\.2/i,
  Windows7: /windows nt 6\.1/i,
  UnknownWindows: /windows nt 6\.\d+/i,
  WindowsVista: /windows nt 6\.0/i,
  Windows2003: /windows nt 5\.2/i,
  WindowsXP: /windows nt 5\.1/i,
  Windows2000: /windows nt 5\.0/i,
  WindowsPhone81: /windows phone 8\.1/i,
  WindowsPhone80: /windows phone 8\.0/i,
  OSXCheetah: /os x 10[._]0/i,
  OSXPuma: /os x 10[._]1(\D|$)/i,
  OSXJaguar: /os x 10[._]2/i,
  OSXPanther: /os x 10[._]3/i,
  OSXTiger: /os x 10[._]4/i,
  OSXLeopard: /os x 10[._]5/i,
  OSXSnowLeopard: /os x 10[._]6/i,
  OSXLion: /os x 10[._]7/i,
  OSXMountainLion: /os x 10[._]8/i,
  OSXMavericks: /os x 10[._]9/i,
  OSXYosemite: /os x 10[._]10/i,
  OSXElCapitan: /os x 10[._]11/i,
  MacOSSierra: /os x 10[._]12/i,
  MacOSHighSierra: /os x 10[._]13/i,
  MacOSMojave: /os x 10[._]14/i,
  Mac: /os x/i,
  Linux: /linux/i,
  Linux64: /linux x86_64/i,
  ChromeOS: /cros/i,
  Wii: /wii/i,
  PS3: /playstation 3/i,
  PSP: /playstation portable/i,
  iPad: /\(iPad.*os (\d+)[._](\d+)/i,
  iPhone: /\(iPhone.*os (\d+)[._](\d+)/i,
  iOS: /ios/i,
  Bada: /Bada\/(\d+)\.(\d+)/i,
  Curl: /curl\/(\d+)\.(\d+)\.(\d+)/i,
  Electron: /Electron\/(\d+)\.(\d+)\.(\d+)/i
}

const PLATFORM = {
  Windows: /windows nt/i,
  WindowsPhone: /windows phone/i,
  Mac: /macintosh/i,
  Linux: /linux/i,
  Wii: /wii/i,
  Playstation: /playstation/i,
  iPad: /ipad/i,
  iPod: /ipod/i,
  iPhone: /iphone/i,
  Android: /android/i,
  Blackberry: /blackberry/i,
  Samsung: /samsung/i,
  Curl: /curl/i,
  Electron: /Electron/i,
  iOS: /^ios-/i
}

export const BASE_AGENT_DETAILS: UserAgentDetails = {
  isYaBrowser: false,
  isAuthoritative: true,
  isMobile: false,
  isMobileNative: false,
  isTablet: false,
  isiPad: false,
  isiPod: false,
  isiPhone: false,
  isiPhoneNative: false,
  isAndroid: false,
  isAndroidNative: false,
  isBlackberry: false,
  isOpera: false,
  isIE: false,
  isEdge: false,
  isIECompatibilityMode: false,
  isSafari: false,
  isFirefox: false,
  isWebkit: false,
  isChrome: false,
  isKonqueror: false,
  isOmniWeb: false,
  isSeaMonkey: false,
  isFlock: false,
  isAmaya: false,
  isPhantomJS: false,
  isEpiphany: false,
  isDesktop: false,
  isWindows: false,
  isLinux: false,
  isLinux64: false,
  isMac: false,
  isChromeOS: false,
  isBada: false,
  isSamsung: false,
  isRaspberry: false,
  isBot: false,
  isCurl: false,
  isAndroidTablet: false,
  isWinJs: false,
  isKindleFire: false,
  isSilk: false,
  isCaptive: false,
  isSmartTV: false,
  isUC: false,
  isFacebook: false,
  isAlamoFire: false,
  isElectron: false,
  isWindowsPhone: false,
  isWechat: false,
  silkAccelerated: false,
  browser: 'unknown',
  version: 'unknown',
  os: 'unknown',
  platform: 'unknown',
  source: ''
}

export class UserAgent {
  public Agent: UserAgentDetails

  constructor() {
    this.Agent = {...BASE_AGENT_DETAILS}
  }

  getBrowser(_source: string) {
    switch (true) {
      case BROWSERS.YaBrowser.test(_source):
        this.Agent.isYaBrowser = true
        return 'YaBrowser'
      case BROWSERS.AlamoFire.test(_source):
        this.Agent.isAlamoFire = true
        return 'AlamoFire'
      case BROWSERS.Edge.test(_source):
        this.Agent.isEdge = true
        return 'Edge'
      case BROWSERS.PhantomJS.test(_source):
        this.Agent.isPhantomJS = true
        return 'PhantomJS'
      case BROWSERS.Konqueror.test(_source):
        this.Agent.isKonqueror = true
        return 'Konqueror'
      case BROWSERS.Amaya.test(_source):
        this.Agent.isAmaya = true
        return 'Amaya'
      case BROWSERS.Epiphany.test(_source):
        this.Agent.isEpiphany = true
        return 'Epiphany'
      case BROWSERS.SeaMonkey.test(_source):
        this.Agent.isSeaMonkey = true
        return 'SeaMonkey'
      case BROWSERS.Flock.test(_source):
        this.Agent.isFlock = true
        return 'Flock'
      case BROWSERS.OmniWeb.test(_source):
        this.Agent.isOmniWeb = true
        return 'OmniWeb'
      case BROWSERS.Opera.test(_source):
        this.Agent.isOpera = true
        return 'Opera'
      case BROWSERS.Chromium.test(_source):
        this.Agent.isChrome = true
        return 'Chromium'
      case BROWSERS.Facebook.test(_source):
        this.Agent.isFacebook = true
        return 'Facebook'
      case BROWSERS.Chrome.test(_source):
        this.Agent.isChrome = true
        return 'Chrome'
      case BROWSERS.WinJs.test(_source):
        this.Agent.isWinJs = true
        return 'WinJs'
      case BROWSERS.IE.test(_source):
        this.Agent.isIE = true
        return 'IE'
      case BROWSERS.Firefox.test(_source):
        this.Agent.isFirefox = true
        return 'Firefox'
      case BROWSERS.Safari.test(_source):
        this.Agent.isSafari = true
        return 'Safari'
      case BROWSERS.PS3.test(_source):
        return 'ps3'
      case BROWSERS.PSP.test(_source):
        return 'psp'
      case BROWSERS.UC.test(_source):
        this.Agent.isUC = true
        return 'UCBrowser'
      default:
        if (_source.indexOf('Dalvik') !== -1) {
          return 'unknown'
        }

        if (
          _source.indexOf('Mozilla') !== 0 &&
          /^([\d\w\-.]+)\/[\d\w.-]+/i.test(_source)
        ) {
          this.Agent.isAuthoritative = false
          return RegExp.$1
        }
        return 'unknown'
    }
  }

  getBrowserVersion(_source: string) {
    let regex
    switch (this.Agent.browser) {
      case 'Edge':
        if (VERSIONS.Edge.test(_source)) {
          return RegExp.$1
        }
        break
      case 'PhantomJS':
        if (VERSIONS.PhantomJS.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Chrome':
        if (VERSIONS.Chrome.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Chromium':
        if (VERSIONS.Chromium.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Safari':
        if (VERSIONS.Safari.test(_source)) {
          return RegExp.$2
        }
        break
      case 'Opera':
        if (VERSIONS.Opera.test(_source)) {
          return RegExp.$1 ? RegExp.$1 : RegExp.$2
        }
        break
      case 'Firefox':
        if (VERSIONS.Firefox.test(_source)) {
          return RegExp.$1
        }
        break
      case 'WinJs':
        if (VERSIONS.WinJs.test(_source)) {
          return RegExp.$1
        }
        break
      case 'IE':
        if (VERSIONS.IE.test(_source)) {
          return RegExp.$2 ? RegExp.$2 : RegExp.$1
        }
        break
      case 'ps3':
        if (VERSIONS.Ps3.test(_source)) {
          return RegExp.$1
        }
        break
      case 'psp':
        if (VERSIONS.Psp.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Amaya':
        if (VERSIONS.Amaya.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Epiphany':
        if (VERSIONS.Epiphany.test(_source)) {
          return RegExp.$1
        }
        break
      case 'SeaMonkey':
        if (VERSIONS.SeaMonkey.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Flock':
        if (VERSIONS.Flock.test(_source)) {
          return RegExp.$1
        }
        break
      case 'OmniWeb':
        if (VERSIONS.OmniWeb.test(_source)) {
          return RegExp.$1
        }
        break
      case 'UCBrowser':
        if (VERSIONS.UC.test(_source)) {
          return RegExp.$1
        }
        break
      case 'Facebook':
        if (VERSIONS.Facebook.test(_source)) {
          return RegExp.$1
        }
        break
      default:
        if (this.Agent.browser !== 'unknown') {
          regex = new RegExp(
            this.Agent.browser + '[\\/ ]([\\d\\w\\.\\-]+)',
            'i'
          )
          if (regex.test(_source)) {
            return RegExp.$1
          }
        } else {
          this.testWebkit()
          if (this.Agent.isWebkit && VERSIONS.WebKit.test(_source)) {
            return RegExp.$1
          }
          return 'unknown'
        }
    }
  }

  getWechatVersion(_source: string) {
    if (VERSIONS.Wechat.test(_source)) {
      return RegExp.$1
    }

    return 'unknown'
  }

  getOS(_source: string) {
    switch (true) {
      case OS.WindowsVista.test(_source):
        this.Agent.isWindows = true
        return 'Windows Vista'
      case OS.Windows7.test(_source):
        this.Agent.isWindows = true
        return 'Windows 7'
      case OS.Windows8.test(_source):
        this.Agent.isWindows = true
        return 'Windows 8'
      case OS.Windows81.test(_source):
        this.Agent.isWindows = true
        return 'Windows 8.1'
      case OS.Windows10.test(_source):
        this.Agent.isWindows = true
        return 'Windows 10.0'
      case OS.Windows2003.test(_source):
        this.Agent.isWindows = true
        return 'Windows 2003'
      case OS.WindowsXP.test(_source):
        this.Agent.isWindows = true
        return 'Windows XP'
      case OS.Windows2000.test(_source):
        this.Agent.isWindows = true
        return 'Windows 2000'
      case OS.WindowsPhone81.test(_source):
        this.Agent.isWindowsPhone = true
        return 'Windows Phone 8.1'
      case OS.WindowsPhone80.test(_source):
        this.Agent.isWindowsPhone = true
        return 'Windows Phone 8.0'
      case OS.Linux64.test(_source):
        this.Agent.isLinux = true
        this.Agent.isLinux64 = true
        return 'Linux 64'
      case OS.Linux.test(_source):
        this.Agent.isLinux = true
        return 'Linux'
      case OS.ChromeOS.test(_source):
        this.Agent.isChromeOS = true
        return 'Chrome OS'
      case OS.Wii.test(_source):
        return 'Wii'
      case OS.PS3.test(_source):
        return 'Playstation'
      case OS.PSP.test(_source):
        return 'Playstation'
      case OS.OSXCheetah.test(_source):
        this.Agent.isMac = true
        return 'OS X Cheetah'
      case OS.OSXPuma.test(_source):
        this.Agent.isMac = true
        return 'OS X Puma'
      case OS.OSXJaguar.test(_source):
        this.Agent.isMac = true
        return 'OS X Jaguar'
      case OS.OSXPanther.test(_source):
        this.Agent.isMac = true
        return 'OS X Panther'
      case OS.OSXTiger.test(_source):
        this.Agent.isMac = true
        return 'OS X Tiger'
      case OS.OSXLeopard.test(_source):
        this.Agent.isMac = true
        return 'OS X Leopard'
      case OS.OSXSnowLeopard.test(_source):
        this.Agent.isMac = true
        return 'OS X Snow Leopard'
      case OS.OSXLion.test(_source):
        this.Agent.isMac = true
        return 'OS X Lion'
      case OS.OSXMountainLion.test(_source):
        this.Agent.isMac = true
        return 'OS X Mountain Lion'
      case OS.OSXMavericks.test(_source):
        this.Agent.isMac = true
        return 'OS X Mavericks'
      case OS.OSXYosemite.test(_source):
        this.Agent.isMac = true
        return 'OS X Yosemite'
      case OS.OSXElCapitan.test(_source):
        this.Agent.isMac = true
        return 'OS X El Capitan'
      case OS.MacOSSierra.test(_source):
        this.Agent.isMac = true
        return 'macOS Sierra'
      case OS.MacOSHighSierra.test(_source):
        this.Agent.isMac = true
        return 'macOS High Sierra'
      case OS.MacOSMojave.test(_source):
        this.Agent.isMac = true
        return 'macOS Mojave'
      case OS.Mac.test(_source):
        // !('ontouchend' in document);
        // navigator.maxTouchPoints > 1
        this.Agent.isMac = true
        return 'OS X'
      case OS.iPad.test(_source):
        // 'ontouchend' in document;
        this.Agent.isiPad = true
        return _source.match(OS.iPad)?.[0].replace('_', '.') || 'unknown'
      case OS.iPhone.test(_source):
        //  'ontouchend' in document;
        this.Agent.isiPhone = true
        return _source.match(OS.iPhone)?.[0].replace('_', '.') || 'unknown'
      case OS.Bada.test(_source):
        this.Agent.isBada = true
        return 'Bada'
      case OS.Curl.test(_source):
        this.Agent.isCurl = true
        return 'Curl'
      case OS.iOS.test(_source):
        this.Agent.isiPhone = true
        return 'iOS'
      case OS.Electron.test(_source):
        this.Agent.isElectron = true
        return 'Electron'
      default:
        return 'unknown'
    }
  }

  getPlatform(_source: string) {
    switch (true) {
      case PLATFORM.Windows.test(_source):
        return 'Microsoft Windows'
      case PLATFORM.WindowsPhone.test(_source):
        this.Agent.isWindowsPhone = true
        return 'Microsoft Windows Phone'
      case PLATFORM.Mac.test(_source):
        return 'Apple Mac'
      case PLATFORM.Curl.test(_source):
        return 'Curl'
      case PLATFORM.Electron.test(_source):
        this.Agent.isElectron = true
        return 'Electron'
      case PLATFORM.Android.test(_source):
        this.Agent.isAndroid = true
        return 'Android'
      case PLATFORM.Blackberry.test(_source):
        this.Agent.isBlackberry = true
        return 'Blackberry'
      case PLATFORM.Linux.test(_source):
        return 'Linux'
      case PLATFORM.Wii.test(_source):
        return 'Wii'
      case PLATFORM.Playstation.test(_source):
        return 'Playstation'
      case PLATFORM.iPad.test(_source):
        this.Agent.isiPad = true
        return 'iPad'
      case PLATFORM.iPod.test(_source):
        this.Agent.isiPod = true
        return 'iPod'
      case PLATFORM.iPhone.test(_source):
        this.Agent.isiPhone = true
        return 'iPhone'
      case PLATFORM.Samsung.test(_source):
        this.Agent.isSamsung = true
        return 'Samsung'
      case PLATFORM.iOS.test(_source):
        return 'Apple iOS'
      default:
        return 'unknown'
    }
  }

  testCompatibilityMode() {
    if (this.Agent.isIE) {
      if (/Trident\/(\d)\.0/i.test(this.Agent.source)) {
        const tridentVersion = parseInt(RegExp.$1, 10)
        const version = parseInt(this.Agent.version, 10)
        if (version === 7 && tridentVersion === 7) {
          this.Agent.isIECompatibilityMode = true
          this.Agent.version = '11.0'
        }

        if (version === 7 && tridentVersion === 6) {
          this.Agent.isIECompatibilityMode = true
          this.Agent.version = '10.0'
        }

        if (version === 7 && tridentVersion === 5) {
          this.Agent.isIECompatibilityMode = true
          this.Agent.version = '9.0'
        }

        if (version === 7 && tridentVersion === 4) {
          this.Agent.isIECompatibilityMode = true
          this.Agent.version = '8.0'
        }
      }
    }
  }

  testSilk() {
    switch (true) {
      case new RegExp('silk', 'gi').test(this.Agent.source):
        this.Agent.isSilk = true
        break
      default:
    }

    if (/Silk-Accelerated=true/gi.test(this.Agent.source)) {
      this.Agent.silkAccelerated = true
    }
    return this.Agent.isSilk ? 'Silk' : false
  }

  testKindleFire() {
    switch (true) {
      case /KFOT/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire'
      case /KFTT/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HD'
      case /KFJWI/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HD 8.9'
      case /KFJWA/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HD 8.9 4G'
      case /KFSOWI/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HD 7'
      case /KFTHWI/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HDX 7'
      case /KFTHWA/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HDX 7 4G'
      case /KFAPWI/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HDX 8.9'
      case /KFAPWA/gi.test(this.Agent.source):
        this.Agent.isKindleFire = true
        return 'Kindle Fire HDX 8.9 4G'
      default:
        return false
    }
  }

  testCaptiveNetwork() {
    switch (true) {
      case /CaptiveNetwork/gi.test(this.Agent.source):
        this.Agent.isCaptive = true
        this.Agent.isMac = true
        this.Agent.platform = 'Apple Mac'
        return 'CaptiveNetwork'
      default:
        return false
    }
  }

  testMobile() {
    switch (true) {
      case this.Agent.isWindows:
      case this.Agent.isLinux:
      case this.Agent.isMac:
      case this.Agent.isChromeOS:
        this.Agent.isDesktop = true
        break
      case this.Agent.isAndroid:
      case this.Agent.isSamsung:
        this.Agent.isMobile = true
        break
      default:
    }
    switch (true) {
      case this.Agent.isiPad:
      case this.Agent.isiPod:
      case this.Agent.isiPhone:
      case this.Agent.isBada:
      case this.Agent.isBlackberry:
      case this.Agent.isAndroid:
      case this.Agent.isWindowsPhone:
        this.Agent.isMobile = true
        this.Agent.isDesktop = false
        break
      default:
    }
    if (/mobile|^ios-/i.test(this.Agent.source)) {
      this.Agent.isMobile = true
      this.Agent.isDesktop = false
    }
    if (/dalvik/i.test(this.Agent.source)) {
      this.Agent.isAndroidNative = true
      this.Agent.isMobileNative = true
    }
    if (/scale/i.test(this.Agent.source)) {
      this.Agent.isiPhoneNative = true
      this.Agent.isMobileNative = true
    }
  }

  testTablet() {
    switch (true) {
      case this.Agent.isiPad:
      case this.Agent.isAndroidTablet:
      case this.Agent.isKindleFire:
        this.Agent.isTablet = true
        break
    }
    if (/tablet/i.test(this.Agent.source)) {
      this.Agent.isTablet = true
    }
  }

  testBot() {
    const isBot = IS_BOT_REGEXP.exec(this.Agent.source.toLowerCase())
    if (isBot) {
      this.Agent.isBot = isBot[1]
    } else if (!this.Agent.isAuthoritative) {
      this.Agent.isBot = /bot/i.test(this.Agent.source)
    }
  }

  testSmartTV() {
    this.Agent.isSmartTV = new RegExp(
      'smart-tv|smarttv|googletv|appletv|hbbtv|pov_tv|netcast.tv',
      'gi'
    ).test(this.Agent.source.toLowerCase())
  }

  testAndroidTablet() {
    if (this.Agent.isAndroid && !/mobile/i.test(this.Agent.source)) {
      this.Agent.isAndroidTablet = true
    }
  }

  testWebkit() {
    if (
      this.Agent.browser === 'unknown' &&
      /applewebkit/i.test(this.Agent.source)
    ) {
      this.Agent.browser = 'Apple WebKit'
      this.Agent.isWebkit = true
    }
  }

  testWechat() {
    if (/micromessenger/i.test(this.Agent.source)) {
      this.Agent.isWechat = true
      this.Agent.version = this.getWechatVersion(this.Agent.source)
    }
  }

  parse(source: string) {
    const ua = new UserAgent()
    ua.Agent.source = source.replace(/^\s*/, '').replace(/\s*$/, '')
    ua.Agent.os = ua.getOS(ua.Agent.source)
    ua.Agent.platform = ua.getPlatform(ua.Agent.source)
    ua.Agent.browser = ua.getBrowser(ua.Agent.source)
    ua.Agent.version = ua.getBrowserVersion(ua.Agent.source) || 'unknown'
    ua.testBot()
    ua.testSmartTV()
    ua.testMobile()
    ua.testAndroidTablet()
    ua.testTablet()
    ua.testCompatibilityMode()
    ua.testSilk()
    ua.testKindleFire()
    ua.testCaptiveNetwork()
    ua.testWebkit()
    ua.testWechat()
    return ua.Agent
  }
}
