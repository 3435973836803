import isBefore from 'date-fns/isBefore'

import {dateStringToMiddayDate, dateToMiddayDate} from '../utils/date'
import {UserSearchHistoryItem} from './types'

export const removeOutdatedUserSearchHistory = (
  searchHistory: UserSearchHistoryItem[]
): UserSearchHistoryItem[] =>
  searchHistory.filter(
    ({searchParams: {checkIn = ''}}) =>
      !isBefore(dateStringToMiddayDate(checkIn), dateToMiddayDate(new Date()))
  )
