import {useContext} from 'react'
import {useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import {PriceWatchCTAWrapper} from 'components/PriceWatchCTAWrapper/PriceWatchCTAWrapper'
import {getHotel, getIsSearchPending} from 'modules/sapiSearch/selectors'
import {toggle} from 'opticks'

import {PriceWatchBell} from '@daedalus/shared/src/priceWatch/components/PriceWatchBell'

import {Gallery} from '../Gallery/Gallery'
import {GalleryWithFetchPriority} from '../Gallery/GalleryWithFetchPriority'
import {CheapestOffer} from './CheapestOffer'
import {ExperimentalNoOffersCardLayout} from './Layout'
import {PropertyDetails} from './PropertyDetails'

interface Props {
  /** Whether the card is a priority card */
  isPriorityCard: boolean
}

export const ExperimentalNoOffersCard = ({isPriorityCard}: Props) => {
  const {hotelId} = useContext(HotelCardContext)

  const hotel = useSelector(state => getHotel(state, hotelId))
  const isSearchPending = useSelector(getIsSearchPending)

  return (
    <ExperimentalNoOffersCardLayout
      gallerySlot={toggle(
        '1ec91b00-mobile-hotel-card-lcp-image-fetchpriority',
        <Gallery isPriorityCard={isPriorityCard} />,
        <GalleryWithFetchPriority isPriorityCard={isPriorityCard} />
      )}
      galleryButtonSlot={
        <PriceWatchCTAWrapper
          hotel={hotel}
          ctaComponent={PriceWatchBell}
          componentName="PriceWatchBell"
          searchIsCompleted={!isSearchPending}
          holdOutElement={null}
        />
      }
      propertyDetailsSlot={<PropertyDetails />}
      offerSlot={<CheapestOffer />}
    />
  )
}
