import {createSelector} from 'reselect'

import {RootState} from '../../testutils/configureStore'
import {parsePriceWatchKey} from '../store'
import {ParsePriceWatchKeyParams} from '../types'

export const selectTrackings = (state: RootState) => state.priceWatch.trackings

export const selectPriceWatchEntry = (params: ParsePriceWatchKeyParams) =>
  createSelector([selectTrackings], trackings => {
    const priceWatchKey = parsePriceWatchKey(params)
    return trackings[priceWatchKey]
  })

export const selectIsHoldOutGroup = (state: RootState) =>
  state.priceWatch.isHoldOutGroup
