import React from 'react'

import {useToastController} from '@daedalus/atlas/context/toastController'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Link} from '@daedalus/atlas/Link'
import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

export const FROZEN_DEAL_PARAM = 'frozenDeal'

interface MessageProps {
  myBookingsUrl: string
}

const Message: React.FC<MessageProps> = ({myBookingsUrl = ''}) => (
  <Box>
    <Text variant="bodyS">
      <FormattedMessageWrapper
        id="df.dealFrozenAndSaved"
        defaultMessage="Deal frozen and saved to <MyBookings></MyBookings>"
        values={{
          MyBookings: () => (
            <Link variant="inverse" href={myBookingsUrl}>
              <FormattedMessageWrapper
                id="myBookings"
                defaultMessage="My bookings"
              />
            </Link>
          )
        }}
      />
    </Text>
  </Box>
)

interface Props {
  myBookingsUrl: string
}

// useFrozenDealToast hook encapsulates the logic for showing and hiding the frozen deal toast that
// is shown on the DealFreeze confirmation overlay and on the SRP.
export const useFrozenDealToast = ({myBookingsUrl}: Props) => {
  const {open, close} = useToastController()

  const showFrozenDealToast = () =>
    open(<Message myBookingsUrl={myBookingsUrl} />, {
      showCloseButton: true,
      singleToastMessage: true,
      autoClose: false,
      closeOnOutsideClick: true
    })

  const closeFrozenDealToast = () => close()

  return {
    showFrozenDealToast,
    closeFrozenDealToast
  }
}
