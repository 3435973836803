import {defineMessages} from 'react-intl'

interface Message {
  id: string
  defaultMessage: string
}

interface Props {
  placeCategory: number
  fromUserLocation?: boolean
  geoDetailsFeatureType?: string
  geoDetailsShortName?: string
}

const KM_IN_MILE = 1.609344
const FROM_TARGET_CENTER_CATEGORIES = [3, 4, 5, 7]
const FROM_TARGET_CATEGORIES = [0, 6, 8]

export const messages = defineMessages({
  distanceAsMiles: {
    id: 'distanceAsMiles',
    defaultMessage: 'miles'
  },
  distanceAsKm: {
    id: 'distanceAsKm',
    defaultMessage: 'km'
  },
  distanceFromTarget: {
    id: 'distanceFromTarget',
    defaultMessage: '{distance} {unit} from {targetName}'
  },
  distanceFromTargetCenter: {
    id: 'distanceFromTown',
    defaultMessage: '{distance} {unit} from {targetName} center'
  },
  distanceFromYourLocation: {
    id: 'distanceFromYourLocation',
    defaultMessage: '{distance} {unit} from your location'
  }
})

/**
 * Returns a message descriptor indicating the distance message based on the place category or user's location.
 *
 * @param placeCategory - A number representing the type of place.
 *  - `0`, `6`, `8` represent an "Airport"
 *  - `3`, `5` represent a "Subcity"
 *  - `4`, `7` represents a "Town-village"
 * @param fromUserLocation - An optional boolean indicating if the distance is from the user's location.
 *  - If `true`, the function will return `messages.distanceFromYourLocation`.
 *
 * These params a provided by the `geoDetails` object when the user searches for an address.
 * @param geoDetailsFeatureType - A string representing the type of the feature.
 * @param geoDetailsShortName - A string representing a shorter version of the address.
 *
 * @returns DistanceFromProps | null - Returns an object with id and defaultMessage based on the specified place category or
 *  `fromUserLocation` flag. Returns `null` if the place category does not match any known types.
 *
 * @example
 * // Returns messages.distanceFromYourLocation
 * getDistanceFromMessage(4, true);
 *
 * // Returns null if placeCategory is not recognized
 * getDistanceFromMessage(-1);
 */
export const getDistanceFromMessage = ({
  fromUserLocation,
  geoDetailsFeatureType,
  geoDetailsShortName,
  placeCategory
}: Props): Message | null => {
  if (fromUserLocation) return messages.distanceFromYourLocation
  if (geoDetailsShortName) {
    return geoDetailsFeatureType === 'address'
      ? messages.distanceFromTarget
      : messages.distanceFromTargetCenter
  }

  if (FROM_TARGET_CATEGORIES.includes(placeCategory))
    return messages.distanceFromTarget

  if (FROM_TARGET_CENTER_CATEGORIES.includes(placeCategory))
    return messages.distanceFromTargetCenter

  return null
}

export const convertMilesToKm = (distance: number): number => {
  return distance * KM_IN_MILE
}
