import {useSelector} from 'react-redux'
import useGenerateAppDownloadUrl from 'hooks/useGenerateAppDownloadUrl'
import {useIsRoomOfferAppLocked} from 'hooks/useIsRoomOfferAppLocked'
import {RoomOfferUrlError} from 'modules/accommodation/errors'
import {
  getCheckoutLink,
  getRoomsSearchParamsFromApUrl
} from 'modules/accommodation/utils'
import {getCurrencyCode, getMeta} from 'modules/meta/selectors'
import {getShowTotalPrices} from 'modules/sapiSearch/selectors'
import {getLandingTrafficSource} from 'modules/search/selectors'
import {getSearchIdFromConditionalSource} from 'modules/searchApi/selectors'

import {Page} from '@daedalus/core/src/analytics/types/Events'
import {getLinkByType} from '@daedalus/core/src/offer/business/getLinkByType'
import {getOfferPerks} from '@daedalus/core/src/offer/business/getOfferPerks'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {
  getDisplayPrice,
  getTotalPrice
} from '@daedalus/core/src/price/business/price'
import {BaseUrlCheckoutParams} from '@daedalus/core/src/utils/url/types/UrlParams'

const captureSentryError = (offer: Offer) => {
  if (typeof Sentry !== 'undefined')
    Sentry.captureException(
      new RoomOfferUrlError(
        'Checkout link was not found in the SAPI offer',
        {}
      ),
      {extra: {offer}}
    )
}

interface RoomOfferUrlParams {
  hotelId: string
  roomId: string
  offer: Offer
  redirectId: string
}

export const useRoomOfferUrl = ({
  hotelId,
  roomId,
  offer,
  redirectId
}: RoomOfferUrlParams) => {
  const landingTrafficSource = useSelector(getLandingTrafficSource)
  const meta = useSelector(getMeta)
  const searchId = useSelector(getSearchIdFromConditionalSource)
  const currencyCode = useSelector(getCurrencyCode)
  const {isRoomOfferAppLocked, appDownloadLinkId: placement} =
    useIsRoomOfferAppLocked({
      offerId: offer.id
    })
  const selectedOffer = getRoomsSearchParamsFromApUrl()
  const offerPerks = getOfferPerks(
    offer?.cancellationPenalties,
    offer?.services
  )
  const showTotalPrices = useSelector(getShowTotalPrices)
  const {generateAppLockedDealDeepLink} = useGenerateAppDownloadUrl(Page.Search)

  const appDownloadUrl = generateAppLockedDealDeepLink(placement, {
    hotelId,
    clickedOfferRate: getTotalPrice(getDisplayPrice(offer.prices)),
    currencyCode
  })
  if (isRoomOfferAppLocked) return appDownloadUrl

  if (!offer?.id) return undefined

  const checkoutUrl = getLinkByType(offer.links, ActionLinkTypes.CHECKOUT)
  if (!checkoutUrl) {
    captureSentryError(offer)
    return undefined
  }

  const paymentPageParams: Partial<BaseUrlCheckoutParams> = {
    // Meta
    anonymousId: meta.anonymousId,
    deviceType: meta.deviceCategory,
    searchId,
    utm_source: landingTrafficSource,

    // Link analytics between search-checkout by passing the same value as the ProviderRedirected event
    redirectId,

    // From room & room offer
    roomId,
    // Clicked room offer id
    offerId: offer.id,
    // Clicked SRP offer id (needed to make correct rooms request on PP)
    oid: selectedOffer?.clickedOfferId,
    providerRateType: offer.providerRateType,
    providerCode: offer?.providerCode,
    baseRate: selectedOffer?.clickedOfferBaseRate?.toString(),
    tax: selectedOffer?.clickedOfferTaxes?.toString(),
    localTax: selectedOffer?.clickedOfferHotelFees?.toString(),
    cug: offer.cug[0],
    offerPerks,
    // Enable offer-matching instead of relying on offer.id on payment page
    isDirectToPayment: '1',
    // Added as part of 7276492s-alternative-offers-on-offer-check-soldout In order to show the total prices on alternative offers
    showTotalPrices: showTotalPrices ? '1' : '0'
  }

  return getCheckoutLink({checkoutUrl, paymentPageParams})
}
