import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  getShowTotalPrices,
  getShowTotalPriceToggle
} from 'modules/sapiSearch/selectors'
import {setShowTotalPrices} from 'modules/search/slice'
import {getSearchBoxValues} from 'modules/searchBox/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {TotalPriceToggleDesktop} from './Desktop/TotalPriceToggleDesktop'
import {TotalPriceToggleMobile} from './Mobile/TotalPriceToggleMobile'

export const TotalPriceToggle = () => {
  const {isMobile} = useDeviceLayout()

  const dispatch = useDispatch()
  const showTotalPrices = useSelector(getShowTotalPrices)
  const showTotalPriceToggle = useSelector(getShowTotalPriceToggle)
  const {rooms, optimizeRooms} = useSelector(getSearchBoxValues)
  const hasMultipleRooms = optimizeRooms || rooms?.length > 1 ? true : false

  const handleChange = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceToggle,
        action: Action.Clicked
      })
    )
    dispatch(setShowTotalPrices(!showTotalPrices))
  }, [dispatch, showTotalPrices])

  useEffect(() => {
    if (!showTotalPriceToggle) return

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.PriceToggle,
        action: Action.Displayed
      })
    )
  }, [dispatch, showTotalPriceToggle])

  if (!showTotalPriceToggle) {
    return null
  }

  const totalPriceMessage = hasMultipleRooms ? (
    <FormattedMessageWrapper
      id="includeEverythingInPriceForRooms"
      defaultMessage="Include all nights, {rooms} tax & fees in price"
      values={{rooms: 'rooms,'}}
    />
  ) : (
    <FormattedMessageWrapper
      id="includeEverythingInPrice"
      defaultMessage="Include all nights, {rooms} tax & fees in price"
      values={{rooms: ''}}
    />
  )

  if (isMobile) {
    return (
      <TotalPriceToggleMobile
        checked={showTotalPrices}
        onChange={handleChange}
        totalPriceMessage={totalPriceMessage}
      />
    )
  }

  return (
    <TotalPriceToggleDesktop
      checked={showTotalPrices}
      onChange={handleChange}
      totalPriceMessage={totalPriceMessage}
    />
  )
}
