import {isEmpty, isNil} from 'ramda'

import {
  availableCountries,
  defaultCountry
} from '@daedalus/core/src/localization/business/countries'
import {AvailableCountryType} from '@daedalus/core/src/localization/types/Country'
import {logAndCaptureErrorMessage} from '@daedalus/core/src/utils/logging/errorHandlers'

type CountryCodeType = string | string[]

export const getCountry = (
  countryCode: CountryCodeType | null | undefined
): AvailableCountryType => {
  if (isNil(countryCode) || isEmpty(countryCode)) {
    logAndCaptureErrorMessage('Missing country code', {level: 'error'})
    return defaultCountry
  }

  const existentCountryCode = Array.isArray(countryCode)
    ? countryCode[0]
    : countryCode

  // Temporary fix as Skyscanner is sending UK instead of GB.
  // You can remove it whenever you need to update this file as
  // it will already be fixed.
  const sanitizedCountryCode =
    existentCountryCode.toUpperCase() === 'UK'
      ? 'GB'
      : existentCountryCode.toUpperCase()

  const country = availableCountries[sanitizedCountryCode]

  if (!country) {
    logAndCaptureErrorMessage(
      `Unknown country code: ${existentCountryCode.toUpperCase()}`,
      {level: 'error'}
    )
  }
  return country || defaultCountry
}
