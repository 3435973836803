import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import isValidDate from 'date-fns/isValid'
import parse from 'date-fns/parse'
import parseIso from 'date-fns/parseISO'
import startOfToday from 'date-fns/startOfToday'
import startOfTomorrow from 'date-fns/startOfTomorrow'
import {formatInTimeZone} from 'date-fns-tz'
import {curry, is} from 'ramda'

// TSTODO: Opaque type
export type MiddayDate = Date

/** Based on Unicode Technical Standard #35. Used in `date-fns@^2.0.0`. */
export const UTS_DATE_FORMAT = 'yyyy-MM-dd'
/** Based on the ISO 8601 standard for representing a date format */
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
/** Based on the ISO 8601 standard for representing a time format */
export const TIME_FORMAT = 'HH:mm:ss'
/** Custom date time format */
export const DATE_TIME_FORMAT = UTS_DATE_FORMAT + ' ' + TIME_FORMAT
/** Miliseconds per day */
const MS_PER_DAY = 1000 * 60 * 60 * 24

// TODO (Core): Add unit tests and documentation for this function
export function now(): number {
  return Date.now()
}

/**
 * Utility function to extract date part from datetime string.
 * 2022-05-12T23:59:00.000-08:00 -> 2022-05-12.
 * @param dateTime - datetime string to get the date string from
 * @returns date string
 */
export const getDatePart = (dateTime: string) => dateTime.split('T')[0]

/**
 * Formats the date to string according to the supplied date format
 * @param date - The date to be formatted
 * @param dateFormat - The expected format of the string
 * @returns The date formatted as a  string
 */
export const dateFormat = (date: Date, dateFormat: string) =>
  isValidDate(date) ? format(date, dateFormat) : ''

// Inspired by https://github.com/gpbl/react-day-picker/issues/473
// The middayUTC approach prevents timezone conversion issues in most cases. It doesn't cover all
// edge cases, but since the algorithm is the same as used to come up with the
// date string we're converting, it shouldn't introduce new issues.
// Since upgrading to date-fns v2, using the midday UTC is not effective anymore, because we now pass
// around the date object, not the date string value. Using the midday local time does work.

/**
 * Converts the date string to a Date with the time portion set to midday local time.
 * This should result in _less_ timezone issues.
 *
 * @param dateString - String to be converted to Date. Expected format: `yyyy-MM-dd`
 * @returns Date set to midday local time
 * @example
 * ```typescript
 * dateStringToMiddayDate('2018-11-26') // 2018-11-26 12:00:00
 * ```
 */
export const dateStringToMiddayDate = (dateString: string): MiddayDate =>
  parseIso(`${dateString}T12:00:00`)

/**
 * Adjusts the date so the time portion of it is midday local time.
 * This should result in _less_ timezone issues.
 *
 * @param date - Date to be converted to the midday local time
 * @returns Date set to midday local time
 * @example
 * ```typescript
 * dateToMiddayDate(Date('2018-11-26 18:26:43')) // 2018-11-26 12:00:00
 * ```
 */
export const dateToMiddayDate = (date: Date): MiddayDate => {
  const dateString = dateFormat(date, UTS_DATE_FORMAT)
  return dateStringToMiddayDate(dateString)
}

/**
 * Adjusts the date so the time portion of it is midday local time, then converts it to a date string.
 * This should result in _less_ timezone issues.
 *
 * @param date - Date to be converted to the midday local time
 * @returns Date string from the midday local date. Formatted as: `yyyy-MM-dd`
 * @example
 * ```typescript
 * dateToMiddayString(Date('2018-11-26 18:26:43')) // 2018-11-26
 * ```
 */
export const dateToMiddayString = (date: Date): string =>
  dateFormat(dateToMiddayDate(date), UTS_DATE_FORMAT)

/**
 * Converts the passed in date string to a date.
 * Retrun that date, unless it is invalid, otherwise returns today's date.
 * @param dateString - Date string to be converted
 * @returns Date based on the passed in string, or today's date
 */
export const dateStringToDateOrToday = (dateString?: string): Date => {
  const today = dateToMiddayDate(new Date())
  if (!dateString) return today

  const date = dateStringToMiddayDate(dateString)
  return isValidDate(date) ? date : today
}

// TODO (Core): Make this more generic by removing the mention of checkIn
/**
 * Compares whether the passed in checkIn Date is on or after today. If not, return today's Date.
 * @param checkIn - CheckIn date to compare to today
 * @returns Passed in checkIn Date, or today's Date
 */
export const ensureCheckInOnOrAfterToday = (checkIn: Date): Date => {
  const today = dateToMiddayDate(new Date())
  return differenceInDays(checkIn, today) >= 0 ? checkIn : today
}

// TODO (Core): Make this more generic by removing the mention of checkIn and checkOut
/**
 * Compares whether the checkOut Date is after the checkIn Date.
 * If not, return the Date for one  day after checkIn.
 * @param checkIn - CheckIn Date to be compared
 * @param checkOut - CheckOut Date to be compared
 * @returns The checkOut Date, or the Date for one day after checkIn
 */
export const ensureCheckOutAfterCheckIn = curry(
  (checkIn: Date, checkOut: Date): Date =>
    differenceInDays(checkOut, checkIn) > 0 ? checkOut : addDays(checkIn, 1)
)

// TODO (Core): Make this more generic by removing the mention of checkIn and checkOut
/**
 * Return a time formatted string according to this format: HH:mm.
 * If time is a negative number, return an empty string.
 * @param time - Time value to be formatted.
 * @returns - Formatted time string (HH:mm), or an empty string
 */
export const formatCheckInOutTime = (time: string | number): string => {
  if (is(Number, time)) {
    if (time <= 0) {
      return ''
    }

    return `${time}:00`
  }

  return `${time}`
}

/**
 * Based in a provided date, return the difference from today
 * @param date - date string like '2024-08-13'
 * @returns - Difference in days
 */
export const getDistanceToToday = (date: string) =>
  Math.abs(
    differenceInDays(dateStringToMiddayDate(date), dateToMiddayDate(new Date()))
  )

/**
 * Returns a Date object when provided with a time in the format HH:mm
 * ie.: 12:00, 16:35
 * This is useful when using localization functions that expect a full Date() object
 * @param timeString - The string that represents a 24-hour time
 * @returns - A Date object on which the time portion is timeString
 */
export const timeStringToDate = (timeString: string): Date => {
  return parse(timeString, 'HH:mm', new Date())
}

/**
 * Returns a Date string after adding the days provided
 * ie.: 2022-07-23
 * @param date - The string that represents a date
 * @param days - The days to be added to the date
 * @returns - A Date string after adding the days provided
 */
export const addDaysToDate = (date: string, days: number): string => {
  const unformattedDate = new Date(date)
  unformattedDate.setDate(unformattedDate.getDate() + days)
  return unformattedDate.toISOString().split('T')[0]
}

/**
 * Returns a Date string after substracting the days provided
 * ie.: 2022-07-23
 * @param date - The string that represents a date
 * @param days - The days to be substracted to the date
 * @returns - A Date string after substracting the days provided
 */

export const substractDaysToDate = (date: string, days: number): string => {
  const unformattedDate = new Date(date)
  unformattedDate.setDate(unformattedDate.getDate() - days)
  return unformattedDate.toISOString().split('T')[0]
}

/**
 * Returns the current local date and time
 */
export const getLocalDeviceDateTime = (): string => {
  const today = new Date()

  return dateFormat(today, DATE_TIME_FORMAT)
}

/**
 * Returns the current local date (without time)
 */
export const getLocalDeviceDate = (): string => {
  const today = new Date()

  return dateFormat(today, UTS_DATE_FORMAT)
}

/**
 * Returns unix epoch date in seconds from value
 * @param string - The string that represents a date
 * @returns - Unix epoch time in seconds
 * @example
 * ```
 * getEpochSeconds('2023-02-27') // 1677456000
 * ```
 */
export const getEpochSeconds = (date: string) => date && Date.parse(date) / 1000

/**
 * Returns current user's timezone in regional format (e.g. America/Sao_Paulo)
 * @returns string
 * @example
 * ```
 * getTimezone() // Europe/Amsterdam
 * ```
 */

export const getTimezone = (): string =>
  // eslint-disable-next-line new-cap
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const convertToDifferentTimeZone = (date: string, timeZone: string) => {
  const formatted = formatInTimeZone(
    new Date(date),
    timeZone,
    `yyyy-MM-dd'T'HH:mm:ss.SSSXXX`
  )

  return formatted
}

export const getLengthOfStay = (checkIn: string, checkOut: string) => {
  const checkInDate = dateStringToMiddayDate(checkIn)
  const checkOutDate = dateStringToMiddayDate(checkOut)
  return differenceInDays(checkOutDate, checkInDate)
}

/**
 * Returns today's date in ISO date format (YYYY-MM-DD)
 * @returns string
 */
export const today = () => formatISO(startOfToday(), {representation: 'date'})

/**
 * Returns tomorrow's date in ISO date format (YYYY-MM-DD)
 * @returns string
 */
export const tomorrow = () =>
  formatISO(startOfTomorrow(), {representation: 'date'})

/**
 * Returns day after tomorrow's date in ISO date format (YYYY-MM-DD)
 * @returns string
 */
export const twoDaysFromNow = () =>
  formatISO(addDays(startOfTomorrow(), 1), {representation: 'date'})

/**
 * Returns the amount of nights between two dates
 * @param date1 - The initial date
 * @param date2 - The final date
 * @returns number
 */
export const calculateDateDifference = (date1: Date, date2: Date) => {
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY))
}

// TODO (Core): Add unit tests and documentation for this function
export const getStringDateMonth = (date: string) => date.substring(5, 7)
