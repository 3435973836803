import {useEffect, useState} from 'react'

import {
  MessageListenerTypes,
  NotificationStatusTypes,
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'
import {safeJsonParse} from '@daedalus/core/src/utils/object/index'

interface MessageData {
  data: {
    type: string
    payload: NotificationStatusTypes
  }
}

interface EventListener {
  (evt: Event): void
}

export const usePriceWatchAppPushNotifications = () => {
  const [hasPushNotificationEnabled, setHasPushNotificationEnabled] =
    useState(false)

  useEffect(() => {
    const messageListener = (
      nativeEvent: MessageEvent<MessageData['data']>
    ) => {
      const parsedEvent = safeJsonParse<MessageData['data']>(nativeEvent.data)

      if (parsedEvent.type === RNWebViewMessageTypes.APP_NOTIFICATION_STATUS) {
        setHasPushNotificationEnabled(
          parsedEvent?.payload === NotificationStatusTypes.GRANTED
        )
      }
    }

    // Android sends via document
    // IOS sends via window
    ;[document, window].forEach(receiver =>
      receiver.addEventListener(
        MessageListenerTypes.POST_MESSAGE,
        messageListener as EventListener
      )
    )

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;[document, window].forEach(receiver =>
        receiver.removeEventListener(
          MessageListenerTypes.POST_MESSAGE,
          messageListener as EventListener
        )
      )
    }
  }, [])

  const requestPushNotificationPermission = (hotelId?: string) => {
    postMessageToWebView({
      type: RNWebViewMessageTypes.REQUEST_PRICE_WATCH_NOTIFICATION_PERMISSION,
      payload: {
        hotelId
      }
    })
  }

  return {
    hasPushNotificationEnabled,
    requestPushNotificationPermission
  }
}
