import {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {
  getQueryStringObject,
  getUrlParams
} from '@daedalus/core/src/_web/utils/url'
import {
  getIsAuthenticated,
  getUser
} from '@daedalus/core/src/auth/modules/selectors'
import {getBovioApiKey} from '@daedalus/core/src/booking/utils'
import {checkIsBrandVio} from '@daedalus/core/src/brand/utils'
import {useGetBoVioConfiguration} from '@daedalus/core/src/offer/services/offerConfigurationApi/useGetBoVioConfiguration'

import {
  getApiContext,
  getApplication,
  getIsDealFreezeEnabled
} from '../modules/selector'
import {setDealFreezeEnabled} from '../modules/slice'
import {isCheckInDateValid} from '../utils/OfferEligibility'

const SUPPORTED_LANGUAGES = ['en', 'en-GB']
type Args = {
  appEnv: string
}
/**
 * Custom hook to determine if the DealFreeze feature should be enabled based on the application state,
 * user data and check-in date.
 * @returns boolean indicating if DealFreeze is enabled
 */
export const useDealFreezeEnabled = ({appEnv}: Args): boolean => {
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()
  const {currency, languageCode} = useSelector(getApplication)
  const {boVioEndpoint} = useSelector(getApiContext)
  const {brand} = useBrand()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const user = useSelector(getUser)
  const isDealFreezeEnabled = useSelector(getIsDealFreezeEnabled)
  const boVioProfile = getBovioApiKey({
    brandCode: brand.code,
    appEnv,
    urlParams: getQueryStringObject()
  })
  const isVioBrand = useMemo(() => checkIsBrandVio(brand), [brand])

  const configuration = useGetBoVioConfiguration({
    boVioUrl: boVioEndpoint,
    boVioProfile
  })

  const dealFreezeConfiguration = useMemo(() => {
    return configuration?.dealFreeze || {}
  }, [configuration])
  const {checkIn} = getUrlParams()

  const hasUserRequiredData = useMemo(() => {
    const {given_name, family_name, email} = user?.attributes || {}
    return Boolean(given_name && family_name && email)
  }, [user])

  /**
   * If the user is not authenticated, there is no need to validate their data (name and email).
   * When they click on DF, it will open the SUSI flow instead.
   */
  const shouldUserSeeDealFreeze = !isAuthenticated || hasUserRequiredData

  const isCheckInValid = useMemo(() => {
    return (
      Boolean(checkIn) &&
      isCheckInDateValid(
        checkIn as string,
        new Date(),
        dealFreezeConfiguration.offerCheckInDayDistanceStartLimit,
        dealFreezeConfiguration.offerCheckInDayDistanceEndLimit
      )
    )
  }, [checkIn, dealFreezeConfiguration])

  useEffect(() => {
    const shouldEnableDealFreeze = Boolean(
      isVioBrand &&
        currency &&
        dealFreezeConfiguration.enabled &&
        SUPPORTED_LANGUAGES.includes(languageCode || '') &&
        isCheckInValid &&
        shouldUserSeeDealFreeze
    )
    dispatch(setDealFreezeEnabled(shouldEnableDealFreeze))
  }, [
    isMobile,
    isVioBrand,
    currency,
    dealFreezeConfiguration.enabled,
    isCheckInValid,
    shouldUserSeeDealFreeze,
    dispatch,
    languageCode
  ])

  return isDealFreezeEnabled
}
