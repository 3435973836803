import React from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route} from 'react-router-dom'

import {
  selectIsAuthenticated,
  selectIsLoading
} from '@daedalus/core/src/auth/modules/selectors'

type PropsType = {component: React.ElementType; path: string}

export const PrivateRoute = ({component: Component, path}: PropsType) => {
  const isLoading = useSelector(selectIsLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (isLoading) return null

  return (
    <Route
      path={path}
      render={({location}) =>
        isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: location}
            }}
          />
        )
      }
    />
  )
}
