import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getLanguageCode} from 'utils/localization/language'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const GOOGLE_TRANSLATE_CLASSNAMES = ['translated-ltr', 'translated-rtl']
const DOCUMENT_ATTRIBUTES_TO_WATCH = ['class', 'lang']

/**
 * Tracks the usage of Google Translate on the page. Meant to be temporary to understand
 * how our users are using the feature.
 */
export const TrackGoogleTranslateUsage = () => {
  const languageCode = useSelector(getLanguageCode)
  const dispatch = useDispatch()
  useEffect(() => {
    const trackUsage = () => {
      // Google translate adds one of these classes to the body element.
      const isTranslated = GOOGLE_TRANSLATE_CLASSNAMES.some(className =>
        document.documentElement.classList.contains(className)
      )

      // Google translate updates the lang attribute to the target language.
      const lang = document.documentElement.getAttribute('lang')

      if (isTranslated) {
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.Page,
            action: Action.Translated,
            payload: {from: languageCode, to: lang}
          })
        )
      }

      return isTranslated
    }

    // Is user has translate enabled by default, we already track it on page load.
    // No need to observe for mutations.
    if (!trackUsage()) {
      const observer = new MutationObserver(trackUsage)
      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: DOCUMENT_ATTRIBUTES_TO_WATCH
      })

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return null
}
