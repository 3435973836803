import React from 'react'
import {CompactOfferList} from 'components/OfferList/CompactOfferList/CompactOfferList'
import {HotelOfferEntity} from 'modules/sapiSearch/slice'

import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'
import {PopUp} from '@daedalus/atlas/PopUp'
import {BOFH_PROXY_PROVIDER_CODE} from '@daedalus/core/src/offer/business/isBofhOffer'
import {ContentHotel} from '@findhotel/sapi'

import {ExitPopupContent} from './ExitPopupContent'
import {ExitPopupHeader} from './ExitPopupHeader'
import {ExitPopupSkeleton} from './ExitPopupSkeleton'
import {MODAL_WIDTH, PopupOffersContainer} from './styles'
import {useExitPopup} from './useExitPopup'

interface Props {
  hotel: ContentHotel | undefined
  offerEntity?: HotelOfferEntity
  isLoading: boolean
}

// added as part of 16b5e700-exit-popup-desktop-ap
export const ExitPopupAccommodationPage = ({
  hotel,
  offerEntity,
  isLoading
}: Props) => {
  const offersToShow = offerEntity?.offers?.filter(
    offer => offer.intermediaryProvider !== BOFH_PROXY_PROVIDER_CODE
  )

  const {closePopup, isOpen} = useExitPopup({
    page: 'Accommodation',
    isEnabled: Boolean(offersToShow?.length)
  })

  if (isLoading) {
    return (
      <PopUp
        width={MODAL_WIDTH}
        isOpen={isOpen}
        closeAction={closePopup}
        headerVariant="inverse"
        titleBarProps={{
          centerContent: <ExitPopupHeader variant="loading" />,
          centerAligned: false
        }}
      >
        <ExitPopupSkeleton />
      </PopUp>
    )
  }

  const {anchorPriceNightly, anchorPrice} = offerEntity.topOfferData

  return (
    <PopUp
      width={MODAL_WIDTH}
      isOpen={isOpen}
      noPaddings
      headerVariant="inverse"
      titleBarProps={{
        centerContent: <ExitPopupHeader variant="accommodationPage" />,
        centerAligned: true
      }}
      closeAction={closePopup}
    >
      <>
        <ExitPopupContent hotel={hotel} />
        <Box paddingLeft="s600" paddingRight="s600">
          <Divider />
        </Box>
        <PopupOffersContainer>
          <CompactOfferList
            offers={offersToShow}
            isLoading={false}
            hotelId={hotel.objectID}
            anchorPrice={anchorPriceNightly}
            anchorPriceTotal={anchorPrice}
            sourceComponent="ExitPopupAccommodationPage"
            showFooter={false}
            showArrowCta={false}
          />
        </PopupOffersContainer>
      </>
    </PopUp>
  )
}
