import React, {useContext} from 'react'
import {HotelCardContext} from 'components/HotelCardContext'
import {SecondaryOfferList} from 'components/OfferList/SecondaryOfferList'
import {toggle} from 'opticks'

import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'

import {BaseHotelCard} from './BaseHotelCard'
import {GalleryLoading} from './Gallery/GalleryLoading'
import {PropertySummaryLoading} from './PropertySummary/PropertySummaryLoading'
import {TopOfferInfoSkeleton} from './TopOfferInfo/Skeleton'

export const HotelCardDesktopLoading = () => {
  const {isAnchor, isHotelDetailsOverlay} = useContext(HotelCardContext)
  const showDivider = !isHotelDetailsOverlay && !isAnchor

  return (
    <Box
      marginRight="auto"
      marginBottom={isAnchor ? 's500' : 's000'}
      data-id={isAnchor ? 'anchorHotel' : 'nonAnchorHotel'}
    >
      <BaseHotelCard
        isAnchor={isAnchor}
        galleryComponent={<GalleryLoading />}
        propertySummaryComponent={<PropertySummaryLoading />}
        topOfferComponent={<TopOfferInfoSkeleton />}
        availabilityPaneComponent={null}
        offerListComponent={
          <>
            <Divider sideMargin="s500" />
            {toggle(
              '70766997-desktop-hotel-card-secondary-offers',
              <Box
                paddingTop="s300"
                paddingBottom="s300"
                paddingLeft="s400"
                paddingRight="s400"
              >
                <SecondaryOfferList isLoading variant="horizontal" />
              </Box>,
              <SecondaryOfferList isLoading variant="verticalInline" />
            )}
          </>
        }
      />
      {showDivider && (
        <Box width="100%" paddingTop="s300" paddingBottom="s300">
          <Divider sideMargin="s400" />
        </Box>
      )}
    </Box>
  )
}
