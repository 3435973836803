import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useConditionalEffect} from 'hooks/useConditionalEffect'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getSortByVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {SortByMobileContentLoadable} from './Loadable'

const filterKeys = ['sortField', 'sortOrder']

export const SortByMobile: React.FC = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getSortByVisibility)
  const {isMobile} = useDeviceLayout()
  const {
    getFilters,
    toggleFilters,
    submitFilters,
    resetFilters,
    calculateIfFiltersChanged
  } = useFiltersForm(filterKeys)

  const filters = getFilters()
  const {sortField, sortOrder} = filters

  useConditionalEffect(
    async () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "SortByMobileContent" */
        './SortByMobileContent'
      ),
    isMobile
  )

  useEffect(() => {
    if (!isOpen) {
      resetFilters()
    } else {
      toggleFilters({
        ...filters,
        sortField: sortField,
        sortOrder: sortOrder
      })
    }
  }, [isOpen])

  const handleSubmit = useCallback(() => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.SortButton,
        action: Action.Submitted,
        payload: {sortField, sortOrder}
      })
    )

    if (calculateIfFiltersChanged(filters)) {
      submitFilters()
    } else {
      handleOverlayClose()
    }
  }, [dispatch, submitFilters, sortField, sortOrder])

  const handleOverlayClose = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  return (
    <BottomSheet
      titleBarProps={{
        centerContent: (
          <FormattedMessageWrapper id="sortBy" defaultMessage="Sort by" />
        )
      }}
      bottomBarContent={
        <Button fullWidth onClick={handleSubmit}>
          <FormattedMessageWrapper id="apply" defaultMessage="Apply" />
        </Button>
      }
      isOpen={isOpen}
      onClose={handleOverlayClose}
    >
      {isOpen && (
        <SortByMobileContentLoadable
          toggleFilters={toggleFilters}
          filters={filters}
        />
      )}
    </BottomSheet>
  )
}
