import {useEffect, useState} from 'react'
import {getHasGeolocationPermission} from 'utils/geolocation'

import {isReactNativeWebView} from '@daedalus/core/src/native'

const useHasGeolocationPermission = () => {
  const [permitted, setPermitted] = useState<boolean>()

  const isReactNative = isReactNativeWebView()

  useEffect(() => {
    if (navigator.permissions && !isReactNative) {
      const setPermissionStatus = async () => {
        const hasPermission = await getHasGeolocationPermission()
        setPermitted(hasPermission)
      }
      setPermissionStatus()
    }
  }, [permitted])

  return permitted
}

export default useHasGeolocationPermission
