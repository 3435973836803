import {createAction} from '@reduxjs/toolkit'

export interface FatalError {
  error?: string
  errorInfo?: string
}

export interface FatalErrorAction {
  type: string
  payload: FatalError
}

const handleFatalError = createAction(
  'error/fatalError',
  ({error = 'Unknown error', errorInfo}: FatalError) => ({
    payload: {
      error,
      errorInfo
    }
  })
)

export default handleFatalError
