import {isEmpty, isNil} from 'ramda'

import {FlattenSearchHistory, UserSearchHistoryItem} from '.'

/**
 * Flattens and transforms an array of {@link UserSearchHistoryItem} objects into an array of {@link FlattenSearchHistory} objects.
 *
 * @param searchHistory - Array of {@link UserSearchHistoryItem} items.
 * @returns An array of {@link FlattenSearchHistory} objects. Each object combines search parameters and search data into a single structure. If the input is nil or empty, returns an empty array.
 */
export const flattenSearchHistory = (
  searchHistory: UserSearchHistoryItem[]
): FlattenSearchHistory[] => {
  if (isNil(searchHistory) || isEmpty(searchHistory)) return []

  return searchHistory.map(item => {
    const {searchParams, searchData, historyId, searchId} = item
    const {
      hotelId,
      placeId,
      checkIn,
      checkOut,
      rooms,
      cugDeals,
      tier,
      address
    } = searchParams || {}
    const {hotelImageUrl, hotelName, placeName, objectType, navPathInfo} =
      searchData || {}

    return {
      address,
      checkIn,
      checkOut,
      city: navPathInfo?.city?.name,
      cugDeals,
      historyId,
      hotelId,
      hotelName,
      imageUrl: hotelImageUrl,
      objectType,
      placeId,
      placeName: address || placeName,
      rooms,
      searchId,
      tier
    }
  })
}

export const selectSearchHistoryIds = (searchHistory: FlattenSearchHistory[]) =>
  searchHistory.map(({searchId}) => searchId || 'unknown')
