import ALL_LANGUAGES from '@daedalus/core/src/localization/services/locale'
import {LanguageType} from '@daedalus/core/src/localization/types/LanguageType'

const languageCodeMapping = {
  zh: 'zh-Hans',
  'zh-HK': 'zh-Hant-HK',
  'zh-TW': 'zh-Hant-TW'
}

const getLanguageCode = (language: LanguageType) =>
  language.code.startsWith('zh')
    ? languageCodeMapping[language.code]
    : language.code

const getLanguageObject = (language: LanguageType) => {
  return {
    '@type': 'Language',
    name: language.code.startsWith('zh')
      ? languageCodeMapping[language.code]
      : language.code,
    alternateName: language.name
  }
}

export const organizationMicrodata = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': 'https://www.vio.com/#organization',
  name: 'Vio.com',
  alternateName: 'Vio',
  description:
    "Vio.com is a Dutch-founded travel website and app that has served value-seeking travelers since 2016. With over 100 million users annually, Vio.com helps travelers find better value on every stay. Offering savings of up to 45% on accommodations worldwide, it caters to a variety of preferences, from budget-friendly motels to luxury resorts. Vio.com's 24/7 customer service, coupled with a Trustpilot rating of 4.3/5 based on over 16,000 reviews, reflects its commitment to excellent customer experiences.",
  url: 'https://www.vio.com',
  logo: {
    '@type': 'ImageObject',
    '@id': 'https://www.vio.com/#/schema/logo/image/',
    url: 'https://www.vio.com/_next/static/media/vio-logo.507ab3da.svg'
  },
  image: 'https://www.vio.com/_next/static/media/vio-logo.507ab3da.svg',
  email: 'support@vio.com',
  address: {
    '@type': 'PostalAddress',
    addressRegion: 'Noord-Holland',
    addressLocality: 'Amsterdam',
    streetAddress: 'Nieuwe Looiersdwarsstraat 17',
    postalCode: '1017 TZ',
    addressCountry: 'NL'
  },
  founder: {
    '@type': 'Person',
    name: 'Oz Har Adir'
  },
  foundingLocation: {
    '@type': 'Place',
    name: 'Amsterdam'
  },
  foundingDate: '2016',
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    email: 'support@vio.com',
    availableLanguage: ALL_LANGUAGES.map(getLanguageCode)
  },
  sameAs: [
    'https://www.instagram.com/vio.com.travel/',
    'https://www.facebook.com/viodotcom',
    'https://www.linkedin.com/company/viodotcom/',
    'https://x.com/viodotcom',
    'https://www.trustpilot.com/review/vio.com',
    'https://www.glassdoor.com/Overview/Working-at-Vio-com-EI_IE940798.11,18.htm',
    'https://medium.com/@viodotcom1'
  ],
  areaServed: {
    '@type': 'Place',
    name: 'Worldwide'
  },
  knowsLanguage: ALL_LANGUAGES.map(getLanguageObject),
  brand: [
    {
      '@type': 'Brand',
      name: 'Vio.com',
      alternateName: 'Vio',
      description:
        'Vio.com is a registered trademark. Vio.com is a super-powered tool travelers use, to get better deals every stay. We help +100M visitors a year across the globe access better accommodation deals. Launched in 2016 as FindHotel, we have scaled within the online travel industry by creating a unique booking experience.'
    },
    {
      '@type': 'Brand',
      name: 'FindHotel',
      alternateName: [
        'Find Hotel',
        'FindHotel B.V.',
        'FindHotel.net',
        'FindHotel BV'
      ],
      description:
        'FindHotel is a registered trademark owned by Vio.com. FindHotel travel platform established in 2016, was rebranded to Vio.com in December 2022. FindHotel has helped millions of travelers secure the best accommodation deals. The rebranding reflects the company’s continued mission to innovate and offer enhanced services for travelers worldwide.'
    }
  ],
  owns: [
    {
      '@type': 'WebSite',
      url: 'https://www.vio.com',
      name: 'Vio.com'
    },
    {
      '@type': 'WebSite',
      url: 'https://findhotel.net',
      name: 'FindHotel',
      alternateName: ['Find Hotel', 'FindHotel.net']
    },
    {
      '@type': 'WebSite',
      url: 'https://dealbase.com',
      name: 'DealBase',
      alternateName: ['Deal Base', 'DealBase.com']
    },
    {
      '@type': 'WebSite',
      url: 'https://travelpost.com',
      name: 'TravelPost',
      alternateName: ['Travel Post', 'TravelPost.com']
    },
    {
      '@type': 'WebSite',
      url: 'https://goseek.com',
      name: 'goSeek',
      alternateName: ['Go Seek', 'goSeek.com']
    }
  ],
  knowsAbout: [
    'https://www.wikidata.org/wiki/Q27686',
    'https://www.wikidata.org/wiki/Q61509',
    'https://www.wikidata.org/wiki/Q209645',
    'https://www.wikidata.org/wiki/Q187972',
    'https://www.wikidata.org/wiki/Q148',
    'https://www.wikidata.org/wiki/Q230830',
    'https://www.wikidata.org/wiki/Q17124635'
  ],
  keywords: ['cheap hotels', 'hotel', 'find hotel']
}

export const websiteMicrodata = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  '@id': 'https://www.vio.com/#website',
  url: 'https://www.vio.com',
  name: 'Vio.com',
  description:
    'Vio.com helps travelers find better accommodation deals globally. Access exclusive deals and a unique booking experience!',
  publisher: {
    '@type': 'Organization',
    '@id': 'https://www.vio.com/#organization'
  },
  inLanguage: ALL_LANGUAGES.map(getLanguageCode)
}
