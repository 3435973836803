import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {MOSAIC_FIRST_ROW_ID} from 'components/Accommodation/MosaicGallery/constants'
import {useCachedSearchTopOffers} from 'components/Accommodation/Offers/useCachedSearchTopOffers'
import {AccommodationPageNavSections} from 'modules/accommodation/types'
import {getClickedOfferId} from 'modules/accommodation/utils'
import {getStreetViewOverlay} from 'modules/overlay/selectors'
import {getFilteredRoomsData} from 'modules/searchApi/selectors'

import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'

import {useOfferContainer} from './useOfferContainer'
import {useShouldOfferLeadToRooms} from './useShouldOfferLeadToRooms'

const LAST_CTA_TO_BOTTOM_DISTANCE = 100

const FIRST_CTA_TO_TOP_DISTANCE = 450

export const useIsAccommodationFooterVisible = ({
  hotelId
}: {
  hotelId: string
}) => {
  const {offerEntity} = useCachedSearchTopOffers({hotelId})
  const streetViewOverlay = useSelector(getStreetViewOverlay)
  const streetViewOverlayIsActive = !!streetViewOverlay
  const selectedOfferId = getClickedOfferId()
  const {primaryOffer} = useOfferContainer({
    offerEntity,
    offerId: selectedOfferId,
    hotelId
  })
  const isBofhOffer = getIsBofhOffer(primaryOffer)

  const shouldSeeRooms = useShouldOfferLeadToRooms(isBofhOffer)

  const {totalResults} = useSelector(getFilteredRoomsData)

  const {ref: firstGalleryRowRef, inView: isFirstGalleryRowVisible} = useInView(
    {
      initialInView: true
    }
  )
  const {ref: roomsSectionRef, inView: isRoomSectionInView} = useInView({
    initialInView: false,
    rootMargin: `-${LAST_CTA_TO_BOTTOM_DISTANCE}px 0px -${FIRST_CTA_TO_TOP_DISTANCE}px 0px`
  })

  const isFooterVisibleASide = !(
    isRoomSectionInView ||
    isFirstGalleryRowVisible ||
    (shouldSeeRooms && totalResults === 0)
  )

  const isFooterVisible = streetViewOverlayIsActive
    ? true
    : isFooterVisibleASide

  const roomsSectionElement = document.querySelector(
    `[data-scrollname=${AccommodationPageNavSections.Rooms}]`
  )
  const firstGalleryRowElement = document.querySelector(
    `#${MOSAIC_FIRST_ROW_ID}`
  )
  useEffect(() => {
    if (firstGalleryRowElement) {
      firstGalleryRowRef(firstGalleryRowElement)
    }
    if (roomsSectionElement) {
      roomsSectionRef(roomsSectionElement)
    }
  }, [
    firstGalleryRowElement,
    roomsSectionElement,
    firstGalleryRowRef,
    roomsSectionRef
  ])

  return isFooterVisible
}
