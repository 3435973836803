import {createAction} from '@reduxjs/toolkit'
import {SEARCH_ROUTE} from 'constants/index'
import {History} from 'history'
import {SearchUrlParams} from 'modules/search/types'
import url from 'utils/url'

import {parseQueryString} from '@daedalus/core/src/utils/url'

// TODO (@Search) - Convert this to an enum
export type UpdateMethod = 'navigate' | 'push' | 'replace'

// TODO (@search) - Fix the params type for all location update functions
export interface UpdateLocationParams {
  basePath?: string
  mergeWithCurrentParams?: boolean
  method?: UpdateMethod
  params?: SearchUrlParams
  sanitize?: boolean
  state?: History.LocationState
  delay?: number
}

export interface UpdateLocationPayload {
  state?: History.LocationState
  fullPath: string
  method: UpdateMethod
  delay?: number
}

/**
 * Converts old `nfh` params to new `daedalus` params
 */
const sanitizeSearchParams = (searchParams: Record<string, unknown>) => {
  return {
    ...searchParams,
    checkIn: searchParams.checkin || searchParams.checkIn,
    checkOut: searchParams.checkout || searchParams.checkOut,
    hotelId: searchParams.hotelID || searchParams.hotelId,
    placeId: searchParams.placeID || searchParams.placeId,
    checkin: undefined,
    checkout: undefined,
    hotelID: undefined,
    placeID: undefined
  }
}

export const updateLocation = createAction(
  'location/update',
  ({
    params,
    basePath = SEARCH_ROUTE,
    state,
    mergeWithCurrentParams = true,
    method = 'push',
    sanitize = true,
    delay
  }: UpdateLocationParams) => {
    let path: string
    if (mergeWithCurrentParams) {
      // Merge new params with current search params
      let searchParams =
        typeof window !== 'undefined'
          ? parseQueryString(window.location.search)
          : {}

      if (sanitize) {
        searchParams = sanitizeSearchParams(searchParams)
      }

      path = url.stringify({
        ...searchParams,
        ...params
      })
    } else {
      // Fully change location without preserving current search params
      path = url.stringify(params as Record<string, unknown>)
    }

    const fullPath = `${basePath}?${path}`

    return {
      payload: {
        state,
        fullPath,
        method,
        delay
      }
    }
  }
)

export default updateLocation
