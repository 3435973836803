import {AvailabilityResults} from '@findhotel/sapi'

/**
 *
 * Calculate the average availability for all hotels in the given availability results object (from SAPI, before mapping), creating a new availability results object with a single key called 'average' that contains the averaged out rates for availability for all hotels, all the offers and all dates.
 *
 * @example
 *
 * ```
 * calculateAverageAvailabilitySapi(response)
 * ```
 *
 * @param availability - The availabillity results object from SAPI without mapping
 *
 * @returns The availability results object with the average availability for all hotels, all offers and dates in the given availability object
 */
export const calculateAverageAvailabilitySapi = (
  availability: AvailabilityResults
): AvailabilityResults => {
  const key = 'average'
  const hotelIds = Object.keys(availability)

  if (hotelIds.length <= 1) {
    return availability
  }
  const allDates = new Set(Object.values(availability).flatMap(Object.keys))

  return Array.from(allDates).reduce(
    (averageAccumulator, date) => {
      const dataForDate = hotelIds
        .map(hotelId => availability[hotelId][date])
        .filter(Boolean)
      const allOffers = dataForDate.flatMap(data => data.offers)
      const allCheapestRatesForDate = dataForDate.map(data => data.cheapestRate)

      const summedCheapestRates = allCheapestRatesForDate.reduce(
        (acc, rate) => ({
          base: acc.base + rate.base,
          taxes: acc.taxes + rate.taxes,
          hotelFees: acc.hotelFees + rate.hotelFees
        }),
        {base: 0, taxes: 0, hotelFees: 0}
      )

      const ratesCount = allCheapestRatesForDate.length
      const averagedCheapestRates = {
        base: summedCheapestRates.base / ratesCount,
        taxes: summedCheapestRates.taxes / ratesCount,
        hotelFees: summedCheapestRates.hotelFees / ratesCount
      }

      return {
        [key]: {
          ...averageAccumulator[key],
          [date]: {
            offers: allOffers,
            hotelId: key,
            cheapestRate: averagedCheapestRates
          }
        }
      }
    },
    {[key]: {}}
  )
}
