import {useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {useOfferTrackingContextWithFallback} from 'context/offerTracking'
import {getOfferContext} from 'middleware/analytics/selectors'
import {OldHotelOfferEntity} from 'modules/sapiSearch/offersMapping'
import {SearchOffer} from 'modules/sapiSearch/slice'

import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export interface useTrackDisplayedProps {
  hotelId: string
  offer: SearchOffer
  sourceComponent: string
  offerEntity?: OldHotelOfferEntity
}

export const useTrackDisplayed = ({
  hotelId,
  offer,
  sourceComponent,
  offerEntity = undefined
}: useTrackDisplayedProps) => {
  const trackedOnceRef = useRef(false)
  const dispatchTeamEvent = useDispatchTeamEvent()

  // Allow AP context to overwrite default SAPI selectors
  const {offerContext} = useOfferTrackingContextWithFallback({
    offer,
    offerEntity,
    offerContext: useSelector(state =>
      getOfferContext(state, hotelId, offer?.id, sourceComponent)
    )
  })

  useEffect(() => {
    if (!offer || trackedOnceRef.current) return
    trackedOnceRef.current = true
    dispatchTeamEvent(
      trackEvent({
        category: Category.System,
        entity: Entity.HotelDetailsStaticOfferCTA,
        action: Action.Displayed,
        component: sourceComponent,
        analyticsContext: {
          [AnalyticsContext.OfferContext]: offerContext
        }
      })
    )
  }, [sourceComponent, dispatchTeamEvent, offerContext, offer])
}
