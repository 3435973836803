/**
 * `PriceTypes` categorize the price information received from the backend.
 * Sometimes we might want to receive the same price in different formats (currency conversion for instance),
 * to be able to achieve a desired UX for the user
 */
enum PriceTypes {
  /**
   * The currency the user will be charged in his/her Credit Card
   * */
  CHARGEABLE_CURRENCY = 'chargeable_currency',
  /**
   * The currency the user will be charged in his/her Credit Card.
   * The difference from `CHARGEABLE_CURRENCY` is that this one is used in case
   * you want to compare the chargeable currency from two different systems.
   * Example
   *
   * `ORIGINAL_CHARGEABLE_CURRENCY !== CHARGEABLE_CURRENCY`, the user will experience
   * a difference in prices going from Search to the Room Selection Page
   * @deprecated BoFH API won't return this on offer-check endpoint
   * */
  ORIGINAL_CHARGEABLE_CURRENCY = 'original_chargeable_currency',
  /**
   * The currency the user will see displayed in the UI.
   * It's not the currency charged in the user's Credit Card
   * Example:
   * A user is browsing the website seeing rates displayed in `BRL`, but the provider
   * doesn't support this currency and it will charge the user in `USD`.
   * In this scenario the `CHARGEABLE_CURRENCY` is USD and the `USER_CURRENCY` is `BRL`
   */
  USER_CURRENCY = 'user_currency',
  /**
   * Applies the same principle as `ORIGINAL_CHARGEABLE_CURRENCY` but to `USER_CURRENCY`
   * @deprecated BoFH API won't return this on offer-check endpoint
   */
  ORIGINAL_USER_CURRENCY = 'original_user_currency',
  /**
   * The currency the user's Credit Card will be charged
   * */
  CHARGEABLE_PRICE = 'chargeable_price',
  /**
   * The currency the user will see displayed in the UI.
   * It's not the currency charged in the user's Credit Card
   * Example:
   * A user is browsing the website seeing rates displayed in `BRL`, but the provider
   * doesn't support this currency and it will charge the user in `USD`.
   * In this scenario the `CHARGEABLE_PRICE` is USD and the `USER_PRICE` is `BRL`
   */
  DISPLAY_PRICE = 'display_price',

  /**
   * The price in the property's currency. This price is added to cover when
   * the property's price is different from the user's currency and the chargeable currency.
   * hotel_price shows this price in offer-check and hotel_currency in the booking retrieval.
   * Example:
   * A user is checking a property with `ARS` currency, user currency is in `BRL`,
   * but the provider doesn't support this currency and it will charge the user in `USD`.
   */
  HOTEL_PRICE = 'hotel_price',
  HOTEL_CURRENCY = 'hotel_currency'
}

export default PriceTypes
