import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import Settings from 'Settings'

import {AppRatings} from './appRatings.type'

export const appRatingsApi = createApi({
  reducerPath: 'appRatingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: Settings.get('MEMBERSHIP_CACHED_API_ENDPOINT')
  }),
  endpoints: builder => ({
    getAppRatings: builder.query<AppRatings, void>({
      query: () => `app-scraper/ratings`
    })
  })
})

export const {useGetAppRatingsQuery} = appRatingsApi
