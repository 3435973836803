import React, {FC} from 'react'
import useRedirectLink from 'hooks/useRedirectLink'

import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {OfferLinkWrapper} from './styles'

interface Props {
  addNoReferrer: string
  anchorPriceTotal: number
  className: string
  dataId: string
  handleClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  hotelId: string
  inViewRef: (node?: Element) => void
  offer: SearchOffer
  onHover?: () => void
}

export const OfferLink: FC<Props> = ({
  addNoReferrer,
  anchorPriceTotal,
  children,
  className,
  dataId,
  handleClick,
  hotelId,
  inViewRef,
  offer,
  onHover
}) => {
  const href = useRedirectLink({
    anchorPriceTotal,
    offer,
    hotelId
  })

  return (
    <OfferLinkWrapper
      ref={inViewRef}
      className={className}
      href={href}
      target="_blank"
      rel={`noopener ${addNoReferrer}`}
      data-id={dataId}
      onClick={handleClick}
      onMouseEnter={onHover}
    >
      {children}
    </OfferLinkWrapper>
  )
}
