import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {OverlayStateType, setOverlayState} from 'modules/overlay/slice'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {usePreviousValue} from '@daedalus/core/src/utils/react/hooks/usePreviousValue'

/**
 * Syncs overlay state from browser history state
 */
export const useSyncOverlayState = () => {
  const dispatch = useDispatch()
  const location = useLocation<{overlay?: OverlayStateType}>()
  const prevLocation = usePreviousValue(location) ?? location

  useEffect(() => {
    const overlay = location.state?.overlay || []
    const prevOverlay = prevLocation?.state?.overlay || []
    const component = prevOverlay[prevOverlay.length - 1]?.type

    dispatch(setOverlayState(overlay))

    if (prevOverlay.length > overlay.length) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.OverlayClose,
          action: Action.Succeeded,
          component
        })
      )
    }
  }, [dispatch, location.state, prevLocation.state])
}
