/*global google */
import type {GoogleLocation, Location} from './types'

// MAFH! https://stackoverflow.com/a/3208794
/**
 * Computes the angle between two geolocation points so we know what to set in pov.heading
 */
export function computeAngle(startPoint: Location, endPoint: Location): number {
  const DEGREE_PER_RADIAN = 57.2957795
  const RADIAN_PER_DEGREE = 0.017453

  const dlat = endPoint.lat - startPoint.lat
  const dlng = endPoint.lon - startPoint.lon
  // We multiply dlng with cos(endLat), since the two points are very closeby,
  // so we assume their cos values are approximately equal.
  const yaw =
    Math.atan2(dlng * Math.cos(endPoint.lat * RADIAN_PER_DEGREE), dlat) *
    DEGREE_PER_RADIAN
  return wrapAngle(yaw)
}

function wrapAngle(angle) {
  if (angle >= 360) {
    angle -= 360
  } else if (angle < 0) {
    angle += 360
  }
  return angle
}

export const vioToGoogleLatLng = ({lat, lon}: Location): GoogleLocation => ({
  lat,
  lng: lon
})

export const googleToVioLatLng = ({
  lat,
  lng
}: google.maps.LatLng): Location => ({
  lat: lat(),
  lon: lng()
})
