import React from 'react'

import {CurrencyDisplayVariant} from '@daedalus/core/src/price/business/formatPrice'

import {FeeTypes} from '.'
import PriceLine from './PriceLine'
import {getTaxesAndFeesPopupHeaderText} from './TaxesAndFeesMessages'

interface PriceBreakdownProps {
  currencyCode: string
  baseRate?: number
  taxes: number
  hotelFeesTotal: number
  showFeesDescriptions: boolean
  onInfoPopupOpen?: () => void
  isPriceLoading?: boolean
  currencyDisplayVariant?: CurrencyDisplayVariant
}

const PriceBreakdown = ({
  currencyCode,
  taxes,
  showFeesDescriptions,
  hotelFeesTotal,
  isPriceLoading = false,
  onInfoPopupOpen,
  currencyDisplayVariant
}: PriceBreakdownProps) => {
  const showTaxes = Boolean(taxes && taxes > 0)

  const totalTaxes = taxes + hotelFeesTotal

  return (
    <>
      {showTaxes ? (
        <PriceLine
          label={getTaxesAndFeesPopupHeaderText(FeeTypes.TaxesFees)}
          isPriceLoading={isPriceLoading}
          showFeesDescriptions={showFeesDescriptions}
          price={totalTaxes}
          currencyCode={currencyCode}
          onInfoClick={() => onInfoPopupOpen?.()}
          currencyDisplayVariant={currencyDisplayVariant}
        />
      ) : null}
    </>
  )
}

export default PriceBreakdown
