import React from 'react';
import { v4 } from 'uuid';
import { Text } from '@daedalus/atlas/Text';
interface Props {
  filters: string[];
}
const unmatchedFilter = "u1gwrmro";
const MiddleDot = () => <span> · </span>;
const UnmatchedFiltersComponent = ({
  filters
}: Props) => {
  return <>
      {filters.map((filter, index) => <>
          <Text colorPath="content.neutral.c500" variant="bodyXS" key={`${filter}-${index}-${v4()}`} tag="span" className={unmatchedFilter}>
            {filter}
          </Text>
          {filters.length !== index + 1 && <MiddleDot />}
        </>)}
    </>;
};
export const UnmatchedFilters = React.memo(UnmatchedFiltersComponent);

require("./UnmatchedFilters.wyw-in-js.css!=!../../../../../../node_modules/@wyw-in-js/webpack-loader/lib/outputCssLoader.js?cacheProvider=!./UnmatchedFilters.tsx");