import {
  getHighestVisiblePrice,
  getLengthOfStay,
  getSearchParameters,
  State as SapiState
} from 'modules/sapiSearch/selectors'
import {getSearchTypeFromSearchParameters} from 'modules/sapiSearch/utils'
import {getSearchIdFromConditionalSource} from 'modules/searchApi/selectors'
import {RootState} from 'store'
import {SelectedElement} from 'utils/selectedElement'

import {Source} from '@daedalus/core/src/api-types/bovio/response/booking'
import {PAY_AT_PROPERTY_METHOD} from '@daedalus/core/src/offer/business/offers'

export const TRACK_OFFER_CLICKED = 'TRACK_OFFER_CLICKED'
export const TRACK_LOCKED_OFFER_CLICKED = 'TRACK_LOCKED_OFFER_CLICKED'
export const BLANK_SPACE_ELEMENTS = new Set([
  'BofhOffer',
  'ProviderOffer',
  'Offer'
])

export interface OfferClickedParams {
  absoluteHotelPosition: number
  anchorPrice: number
  availableRooms: number | null | undefined
  bookURI: string
  calculatedTotalRate: number
  canPayLater: boolean
  currency: string
  hasAnchorPrice: boolean
  hasFreeCancellation: boolean
  hotelId: string
  hotelPosition: number
  integrationType: string
  isAnchorPriceOffer: boolean
  isCheapest: boolean
  isPrivateDeal: boolean
  isSharedRoom: boolean
  isTopOffer: boolean
  meals: string[]
  nightlyRate: number
  nights: number
  offerId: string
  offerPosition: number
  providerCode: string
  providerHotelId: string
  proxyProviderCode: string
  rateBreakdown: {baseRate: number; localTaxes: number; taxes: number}
  selectedElement?: SelectedElement
  sourceComponent: string
  yourChoice?: boolean
  checkIn?: string
  checkOut?: string
  highestVisiblePrice?: number
  hotelHasGreatDeal?: boolean
  pageNumber?: number
  pageOffset?: number
  pageLength?: number
  chargeType?: Source[] | [typeof PAY_AT_PROPERTY_METHOD]
}

type OfferClickedMetaDataType = {
  checkIn: string
  checkOut: string
  nights: number
  rooms: string
  searchId: string | null | undefined
  searchType: string
  highestVisiblePrice?: number
}

/* eslint-disable */
// prettier-ignore
type OfferClickedTrackingParamsType = OfferClickedParams & OfferClickedMetaDataType;

/* eslint-enable */

export type OfferClickedActionType = {
  params: OfferClickedParams
  type: string
}

export const increasePositionIndexes = (
  params: OfferClickedParams
): OfferClickedParams => {
  const {offerPosition} = params

  if (typeof offerPosition !== 'number') {
    return params
  }

  return {
    ...params,
    offerPosition: offerPosition + 1
  }
}

const getOfferClickedMetaDataFromState = (
  state: RootState,
  hotelId: OfferClickedParams['hotelId']
): OfferClickedMetaDataType => {
  const searchParameters = getSearchParameters(state)
  const {rooms} = searchParameters

  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    rooms,
    nights: getLengthOfStay(state as SapiState),
    searchId: getSearchIdFromConditionalSource(state as SapiState),
    searchType: getSearchTypeFromSearchParameters(searchParameters),
    highestVisiblePrice: getHighestVisiblePrice(state as SapiState, hotelId)
  }
}

export const getOfferClickedMetaData = (
  state: RootState,
  params: OfferClickedParams
): OfferClickedTrackingParamsType => {
  const offerClickedMetaData = getOfferClickedMetaDataFromState(
    state,
    params.hotelId
  )

  return {
    ...params,
    ...offerClickedMetaData
  }
}

export const trackOfferClicked = (
  params: OfferClickedParams
): OfferClickedActionType => ({
  type: TRACK_OFFER_CLICKED,
  params: increasePositionIndexes(params)
})

export const trackLockedOfferClicked = (
  params: OfferClickedParams
): OfferClickedActionType => ({
  type: TRACK_LOCKED_OFFER_CLICKED,
  params: increasePositionIndexes(params)
})
