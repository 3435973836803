import addDays from 'date-fns/addDays'
import {SearchLocationType} from 'hooks/searchBox'
import {SearchUrlParams} from 'modules/search/types'
import {cleanSearchBoxValues} from 'modules/searchBox/utils'
import QueryString from 'qs'
import {omit, pipe, props} from 'ramda'
import {Except} from 'type-fest'

import {validateSearchParamDates} from '@daedalus/core/src/searchParams/validateSearchParamDates'
import {
  dateStringToMiddayDate,
  dateToMiddayString
} from '@daedalus/core/src/utils/date'

import url from './url'

const TEMPORARY_URL_PARAMS = [
  'scrollAnchorId',
  'anonymousId',
  'openHotelDetails',
  'clickedOffer',
  'clickedBundle',
  'clickedBase',
  'clickedTaxes',
  'clickedFees',
  'clickedProvider',
  'redirectId',
  'clickedUserIp',
  'enablePriceWatch',
  'componentPriceWatch',
  'priceWatchId'
]

/**
 * Remove trailing non-digit symbols from the rooms param
 * @param searchParams
 */
const sanitizeRoomsParam = (searchParams: SearchUrlParams): SearchUrlParams => {
  const {rooms, ...rest} = searchParams
  if (!rooms) return searchParams
  return {
    ...rest,
    rooms: rooms.replace(/\D*$/, '')
  }
}

type ValidSearchParams = Pick<
  SearchUrlParams,
  'placeId' | 'hotelId' | 'boundingBox' | 'userLocationSearch' | 'address'
>

/**
 * Validates if search parameters have all required parameters
 * @param searchParams
 */
export const isValidSearchParams = (searchParams: ValidSearchParams) => {
  return Boolean(
    searchParams.placeId ??
      searchParams.hotelId ??
      searchParams.boundingBox ??
      searchParams.userLocationSearch ??
      searchParams.address
  )
}

interface DestinationIds {
  hotelId?: string
  placeId?: string
}

/**
 * Get the destination ids from the search params
 * @param params
 */
export const getDestinationIds: (params: DestinationIds) => string[] = props([
  'hotelId',
  'placeId'
])

/**
 * Get the destination parameter in the correct format
 * @param type
 * @param value
 */
export const getDestinationParameter = (
  type: Omit<SearchLocationType, 'area'>,
  value: string | null | undefined
): Record<string, unknown> => {
  switch (type) {
    case 'hotel': {
      return {hotelId: value}
    }

    case 'place': {
      return {placeId: value}
    }

    case 'address': {
      return {address: value}
    }

    default: {
      return {}
    }
  }
}

type SerializedUrlParamsWithoutTempParams = Except<
  Record<string, QueryString.ParsedQs>,
  'scrollAnchorId' | 'anonymousId' | 'openHotelDetails'
>

const convertMultiValueFiltersToArr = obj => {
  const multiValueFilters = [
    'facilities',
    'themes',
    'propertyTypes',
    'amenities',
    'starRatings'
  ]

  return Object.keys(obj).reduce((acc, key) => {
    acc[key] =
      multiValueFilters.includes(key) && !Array.isArray(obj[key])
        ? [obj[key]]
        : obj[key]
    return acc
  }, {})
}

/**
 * Gets the current url params with temporary params omitted
 * @param queryString - The query string to serialize
 * @returns The serialized URL params
 */
export const getSerializedUrlParamsWithoutTempParams = (
  queryString?: string
): SerializedUrlParamsWithoutTempParams => {
  const omitTemporaryParams = omit(TEMPORARY_URL_PARAMS)

  return pipe(
    url.parse,
    omitTemporaryParams,
    convertMultiValueFiltersToArr
  )(queryString)
}

/**
 * Replace the value of the `rooms` param with the value of the `fsmr` param.
 * The `fsmr` param follows the same format as the `rooms` param (e.g. 2|2|1) and is
 * used to perform multi-room searches.
 * We also always pass the `optimizeRooms` param
 * @param urlParams - The URL params with or without `fsmr`
 * @returns The URL params with the value of `rooms` replaced with the value of `fsmr`
 * if it exists and the `fsmr` param removed
 */
export const replaceRoomsWithFsmr = (
  urlParams: SearchUrlParams
): SearchUrlParams => {
  const {fsmr, userSearch} = urlParams
  if (!fsmr || userSearch) return urlParams
  return {
    ...urlParams,
    rooms: fsmr,
    optimizeRooms: '1'
  }
}

/**
 * Adds `optimizeRooms` to the URL params if `fsmr` does not exist
 * @param urlParams - The URL params with or without `fsmr`
 * @returns The URL params
 */
export const forceOptimizeRooms = (
  urlParams: SearchUrlParams
): SearchUrlParams => {
  const {fsmr, userSearch, optimizeRooms} = urlParams
  if (optimizeRooms === undefined) {
    if (fsmr || userSearch) return urlParams
    return {
      ...urlParams,
      optimizeRooms: '1'
    }
  }
  return {
    ...urlParams,
    optimizeRooms
  }
}

/**
 * Replace the value of the `checkOut` param by extending it to match
 * the number of days from the `mlos` param.
 * @param urlParams - The URL params with or without `mlos`
 * @returns The URL params with the new value for `checkOut` if the `mlos` param exists
 */
export const replaceCheckOutWithMlos = (
  urlParams: SearchUrlParams
): SearchUrlParams => {
  const {mlos, checkIn, ...rest} = urlParams
  const shouldUseMlos = mlos && checkIn

  if (!shouldUseMlos) {
    return {
      ...rest,
      checkIn
    }
  }

  const mlosCheckOut = pipe(
    checkIn => dateStringToMiddayDate(checkIn),
    checkIn => addDays(checkIn, mlos),
    checkIn => dateToMiddayString(checkIn)
  )(checkIn)

  return {
    ...urlParams,
    checkOut: mlosCheckOut
  }
}

/**
 * 1. Augments URL params from the query string
 * 2. Validates augmented URL params
 * 3. Clean NaN and other falsy values from the URL params
 * @param queryString - The query string (`location.search`)
 * @param extraParams - Any extra params that should be returned in the validated object
 * @returns - The augmented, validated and cleanded URL params
 */
export const augmentAndValidateUrlParams = (
  queryString: string,
  extraParams?: SearchUrlParams
) => {
  const augmentedUrlParams = pipe(
    getSerializedUrlParamsWithoutTempParams,
    forceOptimizeRooms,
    replaceRoomsWithFsmr, // TODO (Find): move this logic and param to SAPI
    replaceCheckOutWithMlos // TODO (Find): move this logic and param to SAPI
  )(queryString)
  // do not reorder functions above

  const validatedUrlParams = pipe(
    validateSearchParamDates,
    sanitizeRoomsParam,
    cleanSearchBoxValues
  )({
    rooms: '2',
    ...extraParams,
    ...augmentedUrlParams,
    profile: extraParams?.profile || augmentedUrlParams.profile
  })
  return validatedUrlParams
}
