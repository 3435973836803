import React from 'react'
import {CompactOffer} from 'components/Offer/Compact'
import {getOfferVariant} from 'components/Offer/Shared/utils'
import {
  SplitBookingBundleType,
  SplitBookingOfferInfo
} from 'components/Offer/types'
import {getCheapestTopOfferIndex} from 'utils/offers'

import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {Hotel} from '@findhotel/sapi'

interface MainProps {
  offerLayoutVariant?: 'CompactDefault' | 'CompactWithoutRoomName'
  isLoading?: false
  offers: SearchOffer[]
  hotelId: Hotel['objectID']
  splitBookingPosition?: number
  showSplitBookingLoader?: boolean
  splitBookingOffers?: SplitBookingOfferInfo[]
  anchorPrice: number
  anchorPriceTotal: number
  sourceComponent: string
  hasRoomLabels?: boolean
  showArrowCta?: boolean
  splitBookingType?: SplitBookingBundleType
  showFooter?: boolean
}

interface LoadingProps {
  isLoading: true
  offers?: never
  hotelId?: never
  splitBookingPosition?: never
  showSplitBookingLoader?: never
  splitBookingOffers?: never
  anchorPrice?: never
  anchorPriceTotal?: never
  sourceComponent?: never
  hasRoomLabels?: never
  showArrowCta?: never
  splitBookingType?: SplitBookingBundleType
  showFooter?: never
}

type Props = MainProps | LoadingProps

export const DividerWithMargin = () => (
  <Box marginTop="s500" marginBottom="s500">
    <Divider />
  </Box>
)

export const CompactOfferList: React.FC<MainProps | LoadingProps> = ({
  offers,
  isLoading,
  hotelId,
  anchorPrice,
  anchorPriceTotal,
  splitBookingOffers,
  sourceComponent,
  splitBookingPosition = 0,
  hasRoomLabels = false,
  showArrowCta = true,
  splitBookingType,
  showFooter = true
}) => {
  if (isLoading) {
    return (
      <Box data-id="loader" paddingTop="s500" paddingBottom="s500">
        <CompactOffer isLoading variant="CompactDefault" />
        <DividerWithMargin />
        <CompactOffer isLoading variant="CompactDefault" />
        <DividerWithMargin />
        <CompactOffer isLoading variant="CompactDefault" />
        <DividerWithMargin />
        <CompactOffer isLoading variant="CompactDefault" />
        <DividerWithMargin />
        <CompactOffer isLoading variant="CompactDefault" />
      </Box>
    )
  }

  const hasSplitBooking = splitBookingOffers?.length === 2

  const topOffers = offers.slice(0, splitBookingPosition)
  const restOfOffers = offers.slice(splitBookingPosition)

  return (
    <>
      {topOffers.map((offer, index) => (
        <CompactOfferListItem
          showArrowCta={showArrowCta}
          key={offer.id}
          offers={offers}
          index={index}
          hotelId={hotelId}
          anchorPrice={anchorPrice}
          anchorPriceTotal={anchorPriceTotal}
          sourceComponent={sourceComponent}
          hasRoomLabels={hasRoomLabels}
        />
      ))}
      {hasSplitBooking && (
        <>
          <CompactOffer
            isSplitBooking
            showArrowCTA={showArrowCta}
            hasTag={false}
            splitBookingType={splitBookingType}
            splitBookingOffers={splitBookingOffers}
            hasRoomLabels={true}
            hotelId={hotelId}
            variant="CompactDefault"
            anchorPrice={anchorPrice}
            anchorPriceTotal={anchorPriceTotal}
            sourceComponent={sourceComponent}
          />
          <DividerWithMargin />
        </>
      )}
      {restOfOffers.map((offer, index) => (
        <CompactOfferListItem
          showArrowCta={showArrowCta}
          key={offer.id}
          offers={offers}
          index={splitBookingPosition + index}
          hotelId={hotelId}
          anchorPrice={anchorPrice}
          anchorPriceTotal={anchorPriceTotal}
          sourceComponent={sourceComponent}
          hasRoomLabels={hasRoomLabels}
        />
      ))}
      {showFooter && (
        <>
          <DividerWithMargin />
          <Box
            display="flex"
            padding="s300"
            justifyContent="center"
            alignItems="center"
            backgroundColor="background.neutral.c000"
          >
            <Text variant="bodyXS" colorPath="content.neutral.c600">
              <FormattedMessageWrapper
                id="noMoreDeals"
                defaultMessage="No more deals to show"
              />
            </Text>
          </Box>
        </>
      )}
    </>
  )
}

interface CompactOfferListItemProps
  extends Omit<Props, 'splitBookingOffers' | 'splitBookingType'> {
  index: number
  showArrowCta: boolean
}

const CompactOfferListItem: React.FC<CompactOfferListItemProps> = ({
  offers,
  index,
  hotelId,
  anchorPrice,
  anchorPriceTotal,
  sourceComponent,
  hasRoomLabels,
  showArrowCta
}) => {
  const offer = offers[index]
  const isLastOffer = index === offers.length - 1

  const isCheapest = Boolean(getCheapestTopOfferIndex(offers) === index)
  const isBofhOffer = getIsBofhOffer(offer)
  const offerVariant = getOfferVariant({isCheapest, isBofhOffer})

  return (
    <>
      <CompactOffer
        showArrowCTA={showArrowCta}
        hasInteraction
        variant="CompactDefault"
        offerVariant={offerVariant}
        anchorPrice={anchorPrice}
        anchorPriceTotal={anchorPriceTotal}
        offer={offer}
        hotelId={hotelId}
        hasRoomLabels={hasRoomLabels}
        offerPosition={index}
        sourceComponent={sourceComponent}
      />
      {!isLastOffer && <DividerWithMargin />}
    </>
  )
}
