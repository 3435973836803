import {checkIsBrandVio} from '../../../brand/utils'

import type {Brand} from '../../../brand/types'

/**
 * Given a brand object tells us if displaying rooms is part of user flow
 * @param brand - The brand object
 * @returns boolean - Whether rooms are enabled
 */
export const getBrandHasRooms = (brand: Brand) =>
  brand.bookOnBrand?.enabled || checkIsBrandVio(brand)
