import React from 'react'

import {Brand} from '@daedalus/core/src/brand/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {FeeTypes} from '.'

export const getTaxesAndFeesPopupHeaderText = (
  taxesAndFeesPopupState: number
) => {
  switch (taxesAndFeesPopupState) {
    case FeeTypes.LocalTax:
      return (
        <FormattedMessageWrapper
          id="rate.localTax"
          defaultMessage="Local tax"
        />
      )
    case FeeTypes.ResortFee:
      return (
        <FormattedMessageWrapper
          id="rate.resortFee"
          defaultMessage="Resort fee"
        />
      )
    case FeeTypes.OtherFee:
      return (
        <FormattedMessageWrapper
          id="rate.otherFee"
          defaultMessage="Other local taxes and fees"
        />
      )
    case FeeTypes.PayAtProperty:
      return (
        <FormattedMessageWrapper
          id="paymentSchedule.payAtProperty"
          defaultMessage="Pay at property"
        />
      )
    case FeeTypes.ToCompleteTheBooking:
      return (
        <FormattedMessageWrapper
          id="paymentSchedule.toCompleteTheBooking"
          defaultMessage="To complete the booking"
        />
      )
    default:
    case FeeTypes.TaxesFees:
      return (
        <FormattedMessageWrapper
          id="rate.taxesFees"
          defaultMessage="Taxes and fees"
        />
      )
  }
}

export const getTaxesAndFeesPopupBodyText = (
  taxesAndFeesPopupState: number,
  brandName: Brand['name']
) => {
  switch (taxesAndFeesPopupState) {
    case FeeTypes.LocalTax:
      return (
        <FormattedMessageWrapper
          id="taxesAndFeesPopupHeaderText.localTax"
          defaultMessage="A mandatory local tax will be collected by the property either at check-in or check-out."
        />
      )
    case FeeTypes.ResortFee:
      return (
        <FormattedMessageWrapper
          id="taxesAndFeesPopupHeaderText.resortFee"
          defaultMessage="This property charges a mandatory resort fee that will be collected either at check-in or check-out. This fee may cover access to certain amenities and services offered by the property."
        />
      )
    case FeeTypes.OtherFee:
      return (
        <FormattedMessageWrapper
          id="taxesAndFeesPopupHeaderText.otherFee"
          defaultMessage="Mandatory local taxes and/or fees will be collected by the property either at check-in or check-out."
        />
      )
    case FeeTypes.PayAtProperty:
      return (
        <FormattedMessageWrapper
          id="taxesAndFeesPopupHeaderText.payAtPropertyFee"
          defaultMessage="Charges payable at the property are mandatory taxes set by local authorities and/or property imposed fees. These will be collected by the property at check-in or check-out, regardless of where you book."
        />
      )
    case FeeTypes.ToCompleteTheBooking:
      return (
        <FormattedMessageWrapper
          id="taxesAndFeesPopupHeaderText.toCompleteBooking"
          defaultMessage="What you will need to pay to complete the booking of the Frozen Deal."
        />
      )
    default:
    case FeeTypes.TaxesFees:
      return (
        <FormattedMessageWrapper
          id="taxesAndFeesPopupHeaderText.taxesAndFees"
          defaultMessage="Taxes and fees are generally recovery charges {brandName} pays to its partners (e.g. hotels)."
          values={{
            brandName
          }}
        />
      )
  }
}
