import {CugDeals, UserTier} from '@findhotel/sapi'

import {SuggestionItemDataType} from '../autocomplete/types'
import {Anchor, SearchParameters} from '../sapi/types'

export const SearchLocation = {
  Hotel: 'hotel',
  Place: 'place',
  Area: 'area',
  Address: 'address',
  UserLocationSearch: 'userLocationSearch'
} as const

// NOTE: export this type from core and deprecate other ones
export type SearchLocationType =
  (typeof SearchLocation)[keyof typeof SearchLocation]

export type UserSearchHistoryItem = {
  historyId: string
  searchParams: SearchParameters
  searchData: Anchor & {
    hotelImageUrl?: string
    hotelName?: string
    placeName?: string
  }
  searchId: string
}

export interface ExtendedSuggestionItem
  extends Omit<SuggestionItemDataType, 'objectPlaceTypeName'> {
  numberOfGuest?: number
  numberOfRooms?: number
  stayDates?: string
  imageUrl?: string
  objectPlaceTypeName: 'history'
}

export interface FlattenSearchHistory {
  // NOTE: hotelId is a maybe because we don't have it for places
  hotelId?: string
  historyId: string
  placeId?: string
  imageUrl?: string
  hotelName?: string
  placeName?: string
  objectType: Omit<SearchLocationType, 'area'>
  checkIn: string
  checkOut: string
  /**
   * a string representing the number of rooms and guests in each room in the format "number_of_adults:child1_age, child2_age|..."
   * For example, "2:10,12|1:5" means 2 adults and 2 children (10 and 12 years old) in the first room and 1 adult and 1 child (5 years old) in the second room.
   */
  rooms: string
  city?: string
  cugDeals?: CugDeals
  tier?: UserTier
  address?: string
  searchId: string
}
