import {MY_BOOKINGS_PATH} from '@daedalus/core/src/booking/constants'

interface BookingManagementUrlParams {
  hostUrl: string
  bookingId: string
  anonymousId?: string
}

export const getBookingManagementUrl = ({
  hostUrl,
  bookingId,
  anonymousId
}: BookingManagementUrlParams): string => {
  const params = new URLSearchParams()
  params.append('bookingId', bookingId)
  if (anonymousId) params.append('anonymousId', anonymousId)

  return `${hostUrl}/bookings?${params.toString()}`
}

interface MyBookingsURLParams {
  hostUrl: string
  anonymousId?: string
}

export const getMyBookingsUrl = ({
  hostUrl,
  anonymousId
}: MyBookingsURLParams): string => {
  const params = new URLSearchParams()
  if (anonymousId) params.append('anonymousId', anonymousId)

  return `${hostUrl}${MY_BOOKINGS_PATH}?${params.toString()}`
}
