import {stringify} from 'qs'

interface GetCallbackUrlParams {
  hotelId: string
  checkIn: string
  checkOut: string
  rooms: string
  shouldRedirectToAp?: boolean
  component?: string
}

/**
 * Utility function to build the params to enable the price watch after authenticating
 * When on SRP: www.vio.com/Hotel/Search?hotelId=1209184&checkIn=2024-08-22&checkOut=2024-08-23&rooms=2&enablePriceWatch=1209184
 * When on AP: www.vio.com/Hotel/Search?hotelId=1209184&checkIn=2024-08-22&checkOut=2024-08-23&rooms=2&enablePriceWatch=1209184&showHotelDetails=1
 */
export const getCallbackUrl = ({
  hotelId,
  checkIn,
  checkOut,
  rooms,
  shouldRedirectToAp,
  component
}: GetCallbackUrlParams) => {
  const defaultPriceWatchParams = {
    enablePriceWatch: hotelId,
    ...(component && {componentPriceWatch: component})
  }

  const {origin, pathname, search} = new URL(window.location.href)

  if (shouldRedirectToAp) {
    const parsedQueryString = {
      ...defaultPriceWatchParams,
      hotelId: hotelId,
      checkIn,
      checkOut,
      rooms,
      showHotelDetails: '1'
    }

    return `${origin}${pathname}${stringify(parsedQueryString, {
      addQueryPrefix: true
    })}`
  }

  return `${origin}${pathname}${search}&${stringify(defaultPriceWatchParams)}`
}
