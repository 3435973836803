import React from 'react'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {DestinationPickerNoResultsWrapper} from './styles'
import {Props} from './SuggestionsList'

export const NoResults = ({listType}: Pick<Props, 'listType'>) => {
  return (
    <DestinationPickerNoResultsWrapper listType={listType}>
      <ContentWrapper paddingY="s500">
        <Text variant={listType === 'popover' ? 'titleM' : 'titleL'}>
          <FormattedMessageWrapper
            id="suggestionList.noResultsTitle"
            defaultMessage="No results found"
          />
        </Text>
      </ContentWrapper>
      <ContentWrapper paddingBottom="s500">
        <Text
          align="center"
          variant={listType === 'popover' ? 'bodyS' : 'bodyM'}
        >
          <FormattedMessageWrapper
            id="suggestionList.noResultsDescription"
            defaultMessage="We couldn’t find any matches."
          />
          <br />
          <FormattedMessageWrapper
            id="suggestionList.noResultsHint"
            defaultMessage="Try using fewer keywords."
          />
        </Text>
      </ContentWrapper>
    </DestinationPickerNoResultsWrapper>
  )
}
