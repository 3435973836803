import {useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import updateLocationAction, {
  UpdateMethod
} from 'modules/common/actions/location'
import {SearchUrlParams} from 'modules/search/types'

import {ensureBooleanUrlParams} from './ensureBooleanUrlParams'

export type UpdateLocationOptionsType = {
  mergeWithCurrentParams?: boolean
  method?: UpdateMethod
  state?: Record<string, unknown>
  basePath?: string
}

export type UpdateLocationCallbackType = (
  params: SearchUrlParams,
  options?: UpdateLocationOptionsType
) => void

interface UrlUpdateFunctions {
  changeLocation: UpdateLocationCallbackType
  navigateWithParameters: UpdateLocationCallbackType
  updateLocation: UpdateLocationCallbackType
}

export const useUrlUpdater = (): UrlUpdateFunctions => {
  const dispatch = useDispatch()

  /**
   * Update current location while preserving existing search params
   */
  const updateLocation = useCallback(
    (
      params: SearchUrlParams,
      options: UpdateLocationOptionsType = {}
    ): void => {
      dispatch(
        updateLocationAction({
          params: ensureBooleanUrlParams(params),
          ...options
        })
      )
    },
    [dispatch]
  )

  /**
   * While preserving existing search params, navigate to new page
   */
  const navigateWithParameters = useCallback(
    (
      params: SearchUrlParams,
      options: UpdateLocationOptionsType = {}
    ): void => {
      updateLocation(params, {
        ...options,
        mergeWithCurrentParams: true,
        method: 'navigate'
      })
    },
    [updateLocation]
  )

  /**
   * Fully change location
   */
  const changeLocation = useCallback(
    (
      params: SearchUrlParams,
      options: UpdateLocationOptionsType = {}
    ): void => {
      updateLocation(params, {
        ...options,
        mergeWithCurrentParams: false
      })
    },
    [updateLocation]
  )

  return useMemo(
    () => ({
      updateLocation,
      navigateWithParameters,
      changeLocation
    }),
    [updateLocation, navigateWithParameters, changeLocation]
  )
}
