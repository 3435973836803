import React, {useCallback, useRef} from 'react'
import {useSelector} from 'react-redux'
import {themeIconsById} from 'components/Filters/PopularFilters/PopularFilters'
import {getFiltersLabel} from 'components/Filters/utils'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getListOfValues} from 'modules/search/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const filterKeys = ['themes']
const elementName = 'quickFilterThemes'

interface Props {
  isActive?: boolean
  themeId: string
}
export const ThemeQuickFilterPill: React.FC<Props> = ({
  isActive = false,
  themeId
}) => {
  const listOfValues = useSelector(getListOfValues)
  const {isMobile} = useDeviceLayout()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const {trackFilterClick, submitFilters, getAppliedFilters} = useFiltersForm(
    filterKeys,
    elementName
  )

  const handlePillClick = useCallback(() => {
    if (isActive) buttonRef.current?.blur()

    const activeFilters = getAppliedFilters()

    const activeThemes = activeFilters.themes || []

    const updatedFilters = {
      themes: isActive
        ? activeThemes.filter(id => themeId !== id)
        : [...activeThemes, themeId]
    }

    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })
    submitFilters(updatedFilters)
  }, [isActive, trackFilterClick, submitFilters, getAppliedFilters])

  if (!isMobile && isActive) return null

  return (
    <Pill
      dataId={`facilityQuickFilter:${themeId}`}
      onClick={handlePillClick}
      isSelected={isActive}
      iconStart={<Icon name={themeIconsById[themeId]} />}
      iconEnd={isActive && <Icon name={'Close'} />}
    >
      {getFiltersLabel('Theme', themeId, listOfValues)}
    </Pill>
  )
}
