import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHandlePriceDetailsClick} from 'components/Accommodation/Rooms/RoomOffer/useHandlePriceDetailsClick'
import {RoomOfferPrice} from 'components/Accommodation/Rooms/RoomOfferPrice/RoomOfferPrice'
import {getOfferContextFromRoomContext} from 'middleware/analytics/contexts/roomContext'
import {getRoomContext} from 'middleware/analytics/selectors'

import {AnalyticsContext} from '@daedalus/core/src/analytics/types/Events'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {Room} from '@daedalus/core/src/room/types/room'

interface PrimaryRegularOfferPriceProps {
  hotelId: string
  room: Room
  offer: Offer
  component: string
  isVioTagVisible?: boolean
}
export const PrimaryRegularOfferPrice = ({
  hotelId,
  room,
  offer,
  component,
  isVioTagVisible = false
}: PrimaryRegularOfferPriceProps) => {
  const roomContext = useSelector(state => getRoomContext(state, room?.id))
  const offerContext =
    roomContext &&
    offer &&
    getOfferContextFromRoomContext(roomContext, offer.id)

  const analyticsContext = useMemo(() => {
    if (!(roomContext && offerContext)) return null

    return {
      [AnalyticsContext.RoomContext]: roomContext,
      [AnalyticsContext.OfferContext]: offerContext
    }
  }, [offerContext, roomContext])

  const handlePriceDetailsClick = useHandlePriceDetailsClick({
    hotelId,
    room,
    offer,
    component,
    analyticsContext
  })
  return (
    <RoomOfferPrice
      hotelId={hotelId}
      offer={offer}
      onClickPriceDetails={handlePriceDetailsClick}
      isVioTagVisible={isVioTagVisible}
    />
  )
}
