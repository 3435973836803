import React from 'react'
import TrackPageView from 'components/TrackPageView'
import {Account} from 'components/User/Account'
import {TopBarSubmitButton} from 'components/User/Account/PersonalDetailsForm'
import {UserLayout} from 'components/User/UserLayout'

import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

export const AccountRoute = () => (
  <UserLayout
    headerContent={
      <FormattedMessageWrapper
        id="account.editAccount"
        defaultMessage="Edit Account"
      />
    }
    endContent={<TopBarSubmitButton />}
  >
    <TrackPageView pageName={Page.Account} />
    <InjectPageDataId page={Page.Account} />
    <Account />
  </UserLayout>
)
