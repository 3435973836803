import {safeBase64Url} from '../../_web/utils/url'
import {ImageProvider} from '../types/ImageProvider'

type FactoryConfigs = {
  secret: string
  url: string
}

/**
 * This image provider will use imgproxy to process images according to our necessities
 * Passing the configuration
 * @param config necessary configuration in order for the imgproxy service to work
 * @returns an image provider method of the type ImageProvider
 */
export const imageProviderImgProxyFactory =
  (config: FactoryConfigs): ImageProvider =>
  (url, size, processingOptions = {}) => {
    const [imageWidth, imageHeight] = size
    const defaultProcessingOptions = {
      rs: `fit:${imageWidth}:${imageHeight}:0:0`,
      dpr: 1
    }

    const extraProcessingOptions = {
      ...defaultProcessingOptions,
      ...processingOptions
    }

    const paramsForProcessingOptions = Object.keys(
      extraProcessingOptions
    ).reduce((acc, processingParam) => {
      return (acc += `${processingParam}:${
        (extraProcessingOptions as Record<string, string | number>)[
          processingParam
        ]
      }/`)
    }, '')

    const imageSrc = `${config.url}${safeBase64Url(
      `${btoa(config.secret)}|/${paramsForProcessingOptions}${safeBase64Url(
        url
      )}.webp`
    )}`

    return imageSrc
  }
