import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'

import {Alert} from '@daedalus/atlas/Alert'
import {Icon} from '@daedalus/atlas/Icon'

import {getError} from '../../modules/selector'
import {ErrorCases} from './types'
import {useGetErrorMessageAndCase} from './useGetErrorMessageAndCase'

export const ErrorAlert: React.FC = () => {
  const {formatMessage} = useIntl()
  const error = useSelector(getError)

  const {title, errorCase} = useGetErrorMessageAndCase({
    error
  })

  const textMessage = useMemo(() => {
    if (
      [
        ErrorCases.SoldOut,
        ErrorCases.Unavailability,
        ErrorCases.PriceMismatch
      ].includes(errorCase)
    ) {
      return formatMessage(title)
    }

    return null
  }, [errorCase, formatMessage, title])

  return (
    <>
      {textMessage && (
        <Alert
          alignIcon="flex-start"
          variant="warning"
          icon={<Icon name="WarningTriangle" size="sm" />}
        >
          {textMessage}
        </Alert>
      )}
    </>
  )
}
