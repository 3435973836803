import {isNil} from 'ramda'
export type ReturnType = string | number | unknown[] | boolean
export type AskedType = 'string' | 'number' | 'array' | 'boolean'

const types = {
  string: (val: number): string => {
    let res = ''

    try {
      res = val.toString()
    } catch {} // eslint-disable-line no-empty

    return res
  },

  number: (val: string): number => {
    const num = Number.parseFloat(val)
    return Number.isNaN(num) ? 0 : num
  },

  array: (val: unknown): unknown[] => {
    if (isNil(val)) return []
    if (Array.isArray(val)) return val
    if (typeof val !== 'string') return [val]
    return val.split(',').map(x => x.trim())
  },

  boolean: (val: unknown) => {
    if (val === '0') return false

    return Boolean(val)
  }
}

const typeCast = (
  val: unknown,
  type: AskedType
): ReturnType | null | undefined => {
  const fn = types[type]

  if (typeof fn !== 'function') throw new Error('cannot cast to ' + type)

  // TODO: Search TS preexisting issue
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return fn(val)
}

export default typeCast
