import {useDispatch, useSelector} from 'react-redux'
import {useCachedSearchTopOffers} from 'components/Accommodation/Offers/useCachedSearchTopOffers'
import {showOverlay} from 'modules/overlay/slice'
import {OverlayType} from 'modules/overlay/types'
import {getSearchParameters} from 'modules/sapiSearch/selectors'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContextList,
  Category,
  Entity,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {Room} from '@daedalus/core/src/room/types/room'

type Props = {
  hotelId: string
  room: Room
  offer: Offer
  component: string
  analyticsContext: AnalyticsContextList
}

export const useHandlePriceDetailsClick = ({
  hotelId,
  room,
  offer,
  component,
  analyticsContext
}: Props) => {
  const dispatch = useDispatch()
  const {offerEntity} = useCachedSearchTopOffers({
    hotelId
  })
  const {checkIn, checkOut, rooms, optimizeRooms} =
    useSelector(getSearchParameters)

  return () => {
    const roomsString = optimizeRooms ? offerEntity?.roomsConfig : rooms
    const roomsConfig = roomsToConfigurationObject(roomsString)
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceDetailButton,
        action: Action.Clicked,
        team: Team.Select,
        page: Page.Accommodation,
        component,
        analyticsContext
      })
    )

    dispatch(
      showOverlay({
        type: OverlayType.RoomOfferPriceBreakdownOverlay,
        entity: Entity.PriceDetail,
        component,
        analyticsContext,
        params: {
          checkIn,
          checkOut,
          roomsConfig,
          roomName: room.name,
          offer
        }
      })
    )
  }
}
