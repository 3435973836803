import dynamic, {DynamicOptions, Loader} from 'next/dynamic'

class ModuleLoadError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(originalError: Error | any) {
    super(`${originalError?.message || originalError}`)
    this.name = 'ModuleLoadError'
    this.stack = originalError.stack
  }
}

// taken from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
function retry<Exports extends object>(
  fn: () => Promise<Exports>,
  {retries = 3, interval = 500, exponentialBackoff = true} = {}
) {
  return new Promise<Exports>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retries === 1) {
            if (typeof Sentry !== 'undefined') {
              Sentry.captureException(new ModuleLoadError(error))
            }
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
            exponentialBackoff
          }).then(resolve, reject)
        }, interval)
      })
  })
}

const dynamicWithOptions = <Props extends object>(
  loader: () => Loader<Props>,
  options: DynamicOptions = {}
) => {
  const dynamicOptions = {
    // Note: these options are undocumented but next/dynamic is built on top of react-loadable and does support them (at time of writing)
    timeout: 1000,
    delay: 500,
    ...options
  }
  // @ts-expect-error - all the generic types don't play nice
  return dynamic<Props>(() => retry(loader), dynamicOptions)
}

export default dynamicWithOptions
