import React from 'react'
import {useIntl} from 'react-intl'

import {Icon} from '@daedalus/atlas/Icon'
import {Tooltip, TooltipPlacement} from '@daedalus/atlas/Tooltip'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

export const DesktopIcon = () => {
  const {formatMessage} = useIntl()

  return (
    <Tooltip
      placement={TooltipPlacement.Top}
      title={formatMessage({
        id: 'priceWatchTooltip.title',
        defaultMessage: 'Never miss a price drop'
      })}
      content={
        <FormattedMessageWrapper
          id="priceAlertOnboardingDescription"
          defaultMessage="We actively monitor prices in the background and will notify you when the prices change."
        />
      }
    >
      <Icon name="Information" size="sm" colorPath="content.neutral.c600" />
    </Tooltip>
  )
}
