import {DomainsType} from 'types/Localization'

/* eslint-disable */
// prettier-ignore
export const ALL_DOMAINS: DomainsType = {
  // etrip
  'etrip.ch': { countryCode: 'CH', currencyCode: 'CHF', locale: 'de' },
  'etrip.es': { countryCode: 'ES', currencyCode: 'EUR', locale: 'es' },
  'etrip.nl': { countryCode: 'NL', currencyCode: 'EUR', locale: 'nl' },

  // etrip.net
  'au.etrip.net': { countryCode: 'AU', currencyCode: 'AUD', locale: 'en' },
  'br.etrip.net': { countryCode: 'BR', currencyCode: 'BRL', locale: 'pt-BR' },
  'ca.etrip.net': { countryCode: 'CA', currencyCode: 'CAD', locale: null },
  'cz.etrip.net': { countryCode: 'CZ', currencyCode: 'CZK', locale: 'cs' },
  'de.etrip.net': { countryCode: 'DE', currencyCode: 'EUR', locale: 'de' },
  'ee.etrip.net': { countryCode: 'EE', currencyCode: 'EUR', locale: 'et' },
  'fr.etrip.net': { countryCode: 'FR', currencyCode: 'EUR', locale: 'fr' },
  'gr.etrip.net': { countryCode: 'GR', currencyCode: 'EUR', locale: 'el' },
  'hu.etrip.net': { countryCode: 'HU', currencyCode: 'EUR', locale: 'hu' },
  'it.etrip.net': { countryCode: 'IT', currencyCode: 'EUR', locale: 'it' },
  'lv.etrip.net': { countryCode: 'LV', currencyCode: 'EUR', locale: null },
  'pl.etrip.net': { countryCode: 'PL', currencyCode: 'PLN', locale: 'pl' },
  'pt.etrip.net': { countryCode: 'PT', currencyCode: 'EUR', locale: 'pt' },
  'ru.etrip.net': { countryCode: 'RU', currencyCode: 'RUB', locale: 'ru' },
  'si.etrip.net': { countryCode: 'SI', currencyCode: 'EUR', locale: 'sl' },
  'sk.etrip.net': { countryCode: 'SK', currencyCode: 'EUR', locale: 'sk' },
  'tr.etrip.net': { countryCode: 'TR', currencyCode: 'TRY', locale: 'tr' },
  'us.etrip.net': { countryCode: 'US', currencyCode: 'USD', locale: 'en' },

  // findhotel
  'findhotel.ae': { countryCode: 'AE', currencyCode: 'AED', locale: 'ar' },
  'findhotel.co.il': { countryCode: 'IL', currencyCode: 'ILS', locale: 'he' },
  'findhotel.co.nz': { countryCode: 'NZ', currencyCode: 'NZD', locale: 'en' },
  'findhotel.cz': { countryCode: 'CZ', currencyCode: 'CZK', locale: 'cs' },
  'findhotel.de': { countryCode: 'DE', currencyCode: 'EUR', locale: 'de' },
  'findhotel.es': { countryCode: 'ES', currencyCode: 'EUR', locale: 'es' },
  'findhotel.fi': { countryCode: 'FI', currencyCode: 'EUR', locale: 'fi' },
  'findhotel.fr': { countryCode: 'FR', currencyCode: 'EUR', locale: 'fr' },
  'findhotel.gr': { countryCode: 'GR', currencyCode: 'EUR', locale: 'el' },
  'findhotel.hk': { countryCode: 'HK', currencyCode: 'HKD', locale: 'zh-HK' },
  'findhotel.hu': { countryCode: 'HU', currencyCode: 'EUR', locale: 'hu' },
  'findhotel.in': { countryCode: 'IN', currencyCode: 'INR', locale: 'en' },
  'findhotel.jp': { countryCode: 'JP', currencyCode: 'JPY', locale: 'ja' },
  'findhotel.kr': { countryCode: 'KR', currencyCode: 'KRW', locale: 'ko' },
  'findhotel.it': { countryCode: 'IT', currencyCode: 'EUR', locale: 'it' },
  'findhotel.mx': { countryCode: 'MX', currencyCode: 'MXN', locale: 'es' },
  'findhotel.ng': { countryCode: 'NG', currencyCode: 'USD', locale: 'en' },
  'findhotel.nl': { countryCode: 'NL', currencyCode: 'EUR', locale: 'nl' },
  'findhotel.no': { countryCode: 'NO', currencyCode: 'NOK', locale: 'no' },
  'findhotel.pl': { countryCode: 'PL', currencyCode: 'PLN', locale: 'pl' },
  'findhotel.pt': { countryCode: 'PT', currencyCode: 'EUR', locale: 'pt' },
  'findhotel.ru': { countryCode: 'RU', currencyCode: 'RUB', locale: 'ru' },
  'findhotel.se': { countryCode: 'SE', currencyCode: 'SEK', locale: 'sv' },
  'findhotel.sg': { countryCode: 'SG', currencyCode: 'SGD', locale: 'en' },
  'findhotel.si': { countryCode: 'SI', currencyCode: 'EUR', locale: 'sl' },
  'findhotel.tw': { countryCode: 'TW', currencyCode: 'TWD', locale: 'zh-TW' },

  // Other
  'hotelkereses.hu': { countryCode: 'HU', currencyCode: 'EUR', locale: 'hu' },

  // No specific by country
  'etrip.net': { countryCode: null, currencyCode: null, locale: null },
  'findhotel.io': { countryCode: null, currencyCode: null, locale: null },
  'findhotel.net': { countryCode: null, currencyCode: null, locale: null },
  'find-hotel-rooms.net': { countryCode: null, currencyCode: null, locale: null },
  'finnhotel.com': { countryCode: null, currencyCode: null, locale: null },
  'hotelsifter.com': { countryCode: null, currencyCode: null, locale: null }
};
/* eslint-enable */
