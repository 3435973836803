import React from 'react'
import {useIntl} from 'react-intl'
import {filterMessages} from 'components/Filters/filterMessages'
import {StarRatingFilter} from 'components/Filters/StarRatingFilter'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {searchParamToFacet} from 'modules/search/constants'

import {FilterDisplayContainer} from '../../FilterDisplayContainer/FilterDisplayContainer'

const filterKeys = [searchParamToFacet.starRatings.param]

interface Props {
  isActive?: boolean
}

export const StarRatingFilterPopover: React.FC<Props> = ({
  isActive = false
}) => {
  const {formatMessage} = useIntl()
  const {
    submitFilters,
    getFilters,
    toggleMultiFilter,
    clearFilters,
    resetFilters,
    trackFilterClick,
    getAppliedStarsLabel
  } = useFiltersForm(filterKeys)

  const starRatingFilter = getFilters().starRatings

  const appliedStarsLabel = getAppliedStarsLabel()

  return (
    <FilterDisplayContainer
      dataId="starRatingFilter"
      label={appliedStarsLabel}
      title={formatMessage(filterMessages.hotelStarClass)}
      isActive={isActive}
      onClear={clearFilters}
      onApply={submitFilters}
      onClose={resetFilters}
      onPillClick={trackFilterClick}
    >
      <StarRatingFilter value={starRatingFilter} onChange={toggleMultiFilter} />
    </FilterDisplayContainer>
  )
}
