import {pathOr, propOr} from 'ramda'

type PriceBucketType = {id: string; value: number}
type PriceBucketsType = PriceBucketType[]
type PriceBucketsObjectType = Record<string, number>

export const getFirstBucketId = (buckets: PriceBucketsType): number =>
  pathOr(0, [0, 'id'], buckets)

export const getPriceBucketValueByIndex = (
  index: number,
  priceBucketObj: PriceBucketsObjectType
) => {
  const value = priceBucketObj[`bucket-${index}`]

  if (!value) {
    const prevBucketIndex = index < 1 ? 0 : index - 1
    const prevBucketValue: number = propOr(
      0,
      `bucket-${prevBucketIndex}`,
      priceBucketObj
    )
    const nextBucketValue: number = propOr(
      0,
      `bucket-${index + 1}`,
      priceBucketObj
    )

    return Math.floor((prevBucketValue + nextBucketValue) / 2) || 1
  }

  return value
}

export const generateContinuousPriceBuckets = (
  priceBuckets: PriceBucketsType,
  lastBucketIndex: number
): PriceBucketsType => {
  const priceBucketObj = priceBuckets.reduce((acc, bucket) => {
    acc[`bucket-${bucket.id}`] = bucket.value
    return acc
  }, {})

  const result = []

  for (let i = 0; i <= lastBucketIndex; i++) {
    result.push({
      id: i,
      value: getPriceBucketValueByIndex(i, priceBucketObj)
    })
  }

  return result
}

export const valueToValidNumberOrEmptyString = (
  value: string,
  currentValue: number
): number | null => {
  const newValue = Number(value.replace('+', ''))

  if (!value) return null
  if (isNaN(newValue)) return currentValue
  return newValue
}

export const generatePriceInputDisplayValue = (
  inputValue: number,
  name: string,
  upperBound: number
): string => {
  if (!inputValue) return ''

  const displayValue = inputValue.toString()

  if (
    name === 'priceMax' &&
    upperBound &&
    (!inputValue || inputValue >= upperBound)
  ) {
    return `${upperBound}+`
  }

  return displayValue
}
