import {RootState} from 'store'

export const isCookieBannerVisible = (state: RootState): boolean =>
  !state.cookies?.acceptedByUser

export const getVclidCookie = (state: RootState): string =>
  state.cookies?.vclid || ''

export const selectIsCookiesAcceptedByUser = (state: RootState) =>
  state.cookies.acceptedByUser
