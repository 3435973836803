import React from 'react'
import {HotelCardDesktopLoading} from 'components/HotelCardDesktop/HotelCardDesktopLoading'
import {HotelCardMobileLoading} from 'components/HotelCardMobile/HotelCardMobileLoading'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

export const HotelCardLoading = () => {
  const {isMobile, isDesktopXs} = useDeviceLayout()

  if (isMobile || isDesktopXs) {
    return <HotelCardMobileLoading />
  }

  return <HotelCardDesktopLoading />
}
