import {FilterKey} from 'components/Accommodation/OffersFilters/constants'
import {getMeta} from 'modules/meta/selectors'
import {getHotelDetailsOverlayInfo} from 'modules/overlay/selectors'
import {getSerializedLandingQuery, getUrlParams} from 'modules/search/selectors'
import {createSelector} from 'reselect'
import {RootState} from 'store'

//  Direct import of selector module needed instead of useBrand hook in selector context where hooks cannot be used.
// eslint-disable-next-line @vio/custom-rules/prefer-usebrand-hook
import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import {getBrandHasRooms} from '@daedalus/core/src/room/business/utils/getBrandHasRooms'
import {getPriceSortConfigFromTaxConfig} from '@daedalus/core/src/sapi/utils'

import {AccommodationState} from './slice'

const getAccommodationState = (state: RootState): AccommodationState =>
  state.accommodation

const getLandingQueryStringSelectedOfferId = createSelector(
  [getSerializedLandingQuery],
  ({selectedOfferId}): string => selectedOfferId
)

const getQueryStringSelectedOfferId = createSelector(
  [getUrlParams],
  ({selectedOfferId}) => selectedOfferId
)

export const getSelectedOfferIdEventPayload = createSelector(
  [getLandingQueryStringSelectedOfferId, getQueryStringSelectedOfferId],
  (landingValue, currentValue) => {
    // Should return {selectedOfferId} when landing and current query string params are matching and have values
    const shouldAddParamToPayload =
      currentValue && landingValue === currentValue
    return {
      ...(shouldAddParamToPayload ? {selectedOfferId: landingValue} : {})
    }
  }
)

/**
 * Get active AP filters from overlay state
 */

export const getAccommodationPageOfferFilters = createSelector(
  [getUrlParams],
  ({offerFilters}) => (offerFilters?.split(',') || []) as FilterKey[]
)

/**
 * Checks whether accommodation params are different.
 * All extraneous parameters in the main search params are ignored.
 */
export const getAccommodationParamsChanged = createSelector(
  [getAccommodationPageOfferFilters],
  filters => !!filters.length
)

/**
 * Get active AP filters as SAPI parameters
 */
export const getAccommodationPageFiltersParameters = createSelector(
  [getAccommodationPageOfferFilters, getMeta],
  (filters, meta) =>
    filters.reduce(
      (acc, cur) => {
        if (cur === 'priceSort') {
          return {
            ...acc,
            offersSort: getPriceSortConfigFromTaxConfig(meta)
          }
        }
        if (cur === 'breakfastIncluded') {
          return {
            ...acc,
            filters: {
              ...acc.filters,
              amenities: ['breakfast']
            }
          }
        }
        return {
          ...acc,
          filters: {
            ...acc.filters,
            [cur]: true
          }
        }
      },
      {filters: {}}
    )
)

export const getHotelIdFromOverlay = createSelector(
  getHotelDetailsOverlayInfo,
  hotelDetailsOverlay => hotelDetailsOverlay.hotelId
)

/**
 * Returns hotel ID for the currently viewed AP.
 * For overlay mode - takes it from the overlay state
 * For standalone mode - takes it from the queryString params, hotelId is always the ID from AP path hotelSlug
 */
export const getAccommodationPageHotelId = createSelector(
  [getHotelIdFromOverlay, getUrlParams],
  (overlayHotelId, {hotelSlug, hotelId}) =>
    overlayHotelId || (hotelSlug ? hotelId : null)
)
/**
 * Get accommodation lightbox overlay state
 */
export const getIsLightboxOverlayVisible = createSelector(
  [getAccommodationState],
  accommodation => accommodation.lightbox.isOverlayOpen
)

/**
 * Get accommodation lightbox overlay initialized image
 */
export const getLightboxSelectedImage = createSelector(
  [getAccommodationState],
  accommodation => accommodation.lightbox.overlaySelectedImage
)

export const getAccommodationSearchBoxPopover = createSelector(
  [getAccommodationState],
  accommodation => accommodation.searchBoxPopover
)

export const getScrollToTarget = createSelector(
  [getAccommodationState],
  accommodation => accommodation.scrollToApTarget
)

export const isRoomsViewExpanded = createSelector(
  [getAccommodationState],
  accommodation => accommodation.isRoomsViewExpanded
)

export const getIsRoomsFiltersLoading = (state: RootState): boolean =>
  state.accommodation.isRoomsFiltersLoading

// A stable reference prevents memoization issues down the line
const EMPTY_FILTERS = []

/**
 * Get active rooms filters from overlay state
 */
export const getRoomsActiveFilters = (state: RootState) => {
  if (!state?.search?.queryString) return []
  const {roomFilters} = getUrlParams(state)
  return roomFilters?.split(',') || EMPTY_FILTERS
}

/**
 * Returns the hotelEntity corresponding the opened AP
 */
export const getCurrentHotelOfferEntity = (state: RootState) =>
  state.sapiSearch.hotelOfferEntities?.[state.accommodation.hotelId]

/**
 * Returns if any Rooms Filters are selected
 */
export const getHasActiveRoomFilter = createSelector(
  [getRoomsActiveFilters],
  activeRoomsFilters => Boolean(activeRoomsFilters.length)
)

export const getMetaBrandHasRooms = createSelector([getBrand], brand =>
  getBrandHasRooms(brand)
)
