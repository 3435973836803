/**
 * Metro (React Native) will import value.native.ts, other bundlers will pick the regular value.ts
 * https://reactnative.dev/docs/platform-specific-code#native-specific-extensions-ie-sharing-code-with-nodejs-and-web
 */
import value from './value'

/**
 * Determine whether the code is running in a React Native environment
 * Do NOT use this in web or shared code, it does not target webviews
 *
 * - prefer `isMobileApp()` to target both React Native and Webviews (for shared code between web/native)
 * - or `isReactNativeWebView()` to target webviews specifically
 */
export const isReactNative: boolean = value
