import {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getSelectedFiltersMap} from 'components/Accommodation/OffersFilters/getSelectedFilters'
import {
  apOfferMountTypes,
  OfferViewType,
  useOfferViewContext
} from 'context/offerViewContext'
import {useHandleScrollToClickedOfferTarget} from 'hooks/useHandleScrollToClickedOfferTarget'
import {useIsRequiredOneTimePasswordFlow} from 'hooks/useIsRequiredOneTimePasswordFlow'
import {useOfferCheckInCheckoutDates} from 'hooks/useOfferCheckInCheckoutDates'
import {useShouldOfferLeadToRooms} from 'hooks/useShouldOfferLeadToRooms'
import {useShowAccommodationPage} from 'hooks/useShowAccommodationPage'
import noop from 'lodash/noop'
import {
  BLANK_SPACE_ELEMENTS,
  OfferClickedParams,
  trackLockedOfferClicked,
  trackOfferClicked
} from 'middleware/analytics/actions/trackOfferClicked'
import {
  getHotelContext,
  getPaginationContext,
  getParametrizedHotelContext,
  getParametrizedHotelInfoContext
} from 'middleware/analytics/selectors'
import {
  getAccommodationPageOfferFilters,
  getMetaBrandHasRooms
} from 'modules/accommodation/selectors'
import updateLocation from 'modules/common/actions/location'
import {
  getAddress,
  getAnonymousId,
  getCurrencyCode,
  getDeviceType,
  getLocaleCode,
  getLocalTaxesIncluded,
  getTaxesIncluded,
  getUserCountryCode
} from 'modules/meta/selectors'
import {OverlayType} from 'modules/overlay/types'
import {hidePopupOnExit} from 'modules/popupOnExit/slice'
import {
  getAppLockedDealConfig,
  getHotelAddress,
  getHotelCity,
  getHotelImageURIs,
  getHotelName,
  getHotelOfferEntity,
  getHotelStarRating,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {SearchOffer} from 'modules/sapiSearch/slice'
import {getIsUserSearch} from 'modules/search/selectors'
import {toggle} from 'opticks'
import {getTimezone} from 'utils'
import {getUserIpFromOffer} from 'utils/getUserIpFromOffer'
import {imageProvider} from 'utils/imageProvider'
import {
  getOfferPositionFromEntity,
  getParamFromOfferBookURI
} from 'utils/offers'
import {SelectedElement} from 'utils/selectedElement'
import {getIsFromGha} from 'utils/trafficSource'
import {v4 as uuidv4} from 'uuid'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {SIZES} from '@daedalus/atlas/Image'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useTrackingTeam} from '@daedalus/core/src/analytics/components/TeamContext'
import {trackProviderRedirect} from '@daedalus/core/src/analytics/modules/actions'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  SearchOfferContext,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  getIsLockedDeal,
  getIsPrivateDeal,
  selectShouldSeeOffersUnlocked,
  selectUserId
} from '@daedalus/core/src/auth/modules/selectors'
import {
  AuthenticationFlows,
  SourceComponentType
} from '@daedalus/core/src/auth/types/Auth'
import {useOpticks} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {getHasFreeCancellation} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {hasChargeLaterTag} from '@daedalus/core/src/offer/business/chargeLater'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {getMealsAmenities} from '@daedalus/core/src/offer/business/meal'
import {
  getChargeType,
  hasOfferTag
} from '@daedalus/core/src/offer/business/offers'
import {OfferTagLabel} from '@daedalus/core/src/offer/types/offer'
import {getEpochSeconds} from '@daedalus/core/src/utils/date'
import {parseQueryString} from '@daedalus/core/src/utils/url'
import {useLogIn} from '@daedalus/shared/src/authentication/hooks/useLogIn'
import {setVisibility} from '@daedalus/shared/src/authentication/OneTimePassword/slice'

import {VIO_OFFER_CLICKED} from '../../../../constants'

interface Props {
  hotelId: string
  sourceComponent: string
  offer: SearchOffer
  isSplitBooking?: boolean
  logIn?: ReturnType<typeof useLogIn>
}

interface HandleOfferClickParams {
  offer: SearchOffer
  selectedElementId: string | undefined
  offerContext: SearchOfferContext
  offerPosition: number
  isDiscount?: boolean
}

interface QueryParams {
  checkIn?: string
  checkOut?: string
  rooms?: string
}

type HandleOfferClick = (params: HandleOfferClickParams) => void

const SRP_COMPONENTS = [OfferViewType.SRP_BEST_DEAL_MODAL, OfferViewType.SRP]

export const useHandleOfferClick = ({
  hotelId,
  sourceComponent,
  offer,
  isSplitBooking,
  logIn = noop
}: Props): HandleOfferClick => {
  const dispatch = useDispatch()
  const anonymousId = useSelector(getAnonymousId)
  const userId = useSelector(selectUserId)
  const currency = useSelector(getCurrencyCode)
  const locale = useSelector(getLocaleCode)
  const isUserSearch = useSelector(getIsUserSearch)
  const localTaxesIncluded = useSelector(getLocalTaxesIncluded)
  const taxesIncluded = useSelector(getTaxesIncluded)
  const brandHasRooms = useSelector(getMetaBrandHasRooms)
  const {brand, brandIsVio} = useBrand()
  const offerView = useOfferViewContext()
  const {onOfferClick} = useHandleScrollToClickedOfferTarget({offerView})
  const isBofhOffer = getIsBofhOffer(offer)
  const shouldGoToRooms = useShouldOfferLeadToRooms(isBofhOffer)
  const metaAddress = useSelector(getAddress)
  const activeOffersFilters = useSelector(getAccommodationPageOfferFilters)
  const {showAccommodationPage} = useShowAccommodationPage()
  const {isMobile, isDesktopXs} = useDeviceLayout()
  const deviceType = useSelector(getDeviceType)
  const isGHA = useSelector(getIsFromGha)
  const isMobileOrTablet = isMobile || isDesktopXs

  const isLocked = useSelector(getIsLockedDeal(offer)) && !isSplitBooking
  const isPrivate = useSelector(getIsPrivateDeal(offer)) && !isSplitBooking

  // Events will be tracked by the team set in context *except* for locked offers, these are always owned by Retention
  const team = useTrackingTeam()
  const shouldSeeOffersUnlocked = useSelector(selectShouldSeeOffersUnlocked)
  const {isAppLockedDeal, appDownloadLinkId} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isRequiredOneTimePasswordFlow = useIsRequiredOneTimePasswordFlow({
    offer,
    isAppLockedDeal
  })

  const {offerCheckIn, offerCheckOut} = useOfferCheckInCheckoutDates({
    sourceComponent
  })
  const hotelContext = useSelector(state => getHotelContext(state, hotelId))
  const paginationContext = useSelector(getPaginationContext)
  const address = useSelector(state => getHotelAddress(state, hotelId))
  const hotelName = useSelector(state => getHotelName(state, hotelId))
  const hotelCity = useSelector(state => getHotelCity(state, hotelId))
  const imageURIs = useSelector(state => getHotelImageURIs(state, hotelId))
  const starRating = useSelector(state => getHotelStarRating(state, hotelId))
  const userCountryCode = useSelector(getUserCountryCode)

  const splitBookingDetails = useSelector(state =>
    getSplitBookingDetails(state, hotelId)
  )
  const {hasSplitBookingOffer, splitBookingType} = splitBookingDetails
  const hotelOfferEntity = useSelector(state =>
    getHotelOfferEntity(state, hotelId)
  )
  const isHotelDetailsOverlayOpen = window.history.state?.state?.overlay?.some(
    overlay => overlay.type === OverlayType.HotelDetails
  )

  const hasSingleFlowSBOffer =
    hasSplitBookingOffer && splitBookingType === 'single_flow'

  const Opticks = useOpticks()

  const handleOfferClick = useCallback(
    ({
      offer,
      selectedElementId,
      offerContext: originalOfferContext,
      // This offerPosition is deprecated and should be removed in the future
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      offerPosition: deprecatedOfferPosition,
      isDiscount
    }: HandleOfferClickParams): void => {
      const cio = customerIo(brand)
      const isMountedOnAp = apOfferMountTypes.includes(offerView)
      const {checkIn, checkOut, rooms}: QueryParams = parseQueryString(
        location.search
      )
      const {calculatedTotalRate, id, yourChoice = null, ...offerProps} = offer
      const isChargeLaterEligible = hasChargeLaterTag(offer)
      const hotelImageUrl = imageURIs?.[0]
        ? imageProvider(imageURIs[0], SIZES['large'])
        : undefined

      if (Opticks)
        Opticks.setAudienceSegmentationAttributes({
          isVioClicker: isBofhOffer
        })

      const selectedElement =
        !selectedElementId || BLANK_SPACE_ELEMENTS.has(selectedElementId)
          ? SelectedElement.BlankSpace
          : (selectedElementId as SelectedElement)

      const isCheapest = hasOfferTag(offer, OfferTagLabel.IsCheapest)
      const isTopOffer = hasOfferTag(offer, OfferTagLabel.IsTopOffer)
      const meals = getMealsAmenities(offer)
      const hasFreeCancellation = getHasFreeCancellation(
        offer?.cancellationPenalties
      )

      const offerContext = {
        ...originalOfferContext,
        checkIn: offerCheckIn,
        checkOut: offerCheckOut
      }

      // Correct offer position for the legacy user click event
      const {offerPosition} = getOfferPositionFromEntity(
        offer?.id,
        hotelOfferEntity,
        splitBookingDetails,
        isHotelDetailsOverlayOpen,
        deviceType
      )

      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const trackingParams: OfferClickedParams = {
        ...offerProps,
        offerPosition,
        absoluteHotelPosition: hotelContext?.hotelPosition,
        anchorPrice: offerContext?.anchorPrice,
        highestVisiblePrice: hotelContext?.highestVisiblePrice,
        hotelHasGreatDeal: hotelContext?.hotelTags.includes('price_drop'),
        hotelId,
        hotelPosition: hotelContext?.hotelPosition,
        pageNumber: paginationContext?.pageNumber,
        pageOffset: paginationContext?.pageOffset,
        pageLength: paginationContext?.pageLength,
        calculatedTotalRate: Math.round(calculatedTotalRate * 100) / 100,
        isPrivateDeal: isPrivate,
        offerId: id,
        selectedElement,
        sourceComponent,
        yourChoice,
        // add back V2 mapping
        bookURI: offer.url,
        rateBreakdown: {
          baseRate: offer.rate.base,
          localTaxes: offer.rate.hotelFees,
          taxes: offer.rate.taxes
        },
        canPayLater: offer.package.canPayLater || isChargeLaterEligible,
        chargeType: getChargeType(offer),
        isCheapest,
        isTopOffer,
        meals,
        hasFreeCancellation,
        checkIn: offerCheckIn,
        checkOut: offerCheckOut
      }

      if (isRequiredOneTimePasswordFlow) {
        dispatch(setVisibility(true))
        return
      }

      if (isAppLockedDeal) {
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.AppExclusiveOffer,
            action: Action.Clicked,
            component: sourceComponent,
            payload: {
              bannerId: appDownloadLinkId,
              selectedElement,
              isDiscount
            },
            analyticsContext: {
              [AnalyticsContext.HotelContext]: hotelContext,
              [AnalyticsContext.OfferContext]: offerContext
            },
            team
          })
        )
        return
      }

      if (isLocked) {
        dispatch(trackLockedOfferClicked(trackingParams))
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.LockedOffer,
            action: Action.Clicked,
            component: sourceComponent,
            analyticsContext: {
              [AnalyticsContext.HotelContext]: hotelContext,
              [AnalyticsContext.OfferContext]: offerContext
            },
            payload: {
              selectedElement,
              isPriceVisible: true,
              isLogoVisible: isLocked,
              isDiscount
            },
            team: Team.Retention
          })
        )

        if (typeof logIn === 'function') {
          logIn({
            anonymousId,
            flow: AuthenticationFlows.UnlockOverlay,
            sourceComponent: SourceComponentType.UnlockOverlay,
            unlockHotelId: hotelId
          })

          return
        }

        dispatch(
          updateLocation({
            params: {scrollAnchorId: hotelId, anonymousId},
            method: 'push'
          })
        )
      } else {
        // To not create conflicts with ProviderRedirect events when user clicks on 'View rooms' button on AP
        if (!shouldGoToRooms) {
          localStorage.setItem(
            'navigationToAccommodationPage',
            Date.now().toString()
          )
          dispatch(trackOfferClicked(trackingParams))
        }

        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.Offer,
            action: Action.Clicked,
            component: sourceComponent,
            payload: {
              selectedElement,
              isDiscount,
              filterApplied: !!activeOffersFilters.length,
              allSelectedFilters: getSelectedFiltersMap(activeOffersFilters)
            },
            analyticsContext: {
              [AnalyticsContext.HotelContext]: hotelContext,
              [AnalyticsContext.OfferContext]: offerContext
            },
            team
          })
        )

        if (brandIsVio)
          cio.trackEvent({
            name: `${Category.User}_${Entity.Offer}_${Action.Clicked}`,
            pageName: Page.Search,
            userId,
            anonymousId,
            hotelId,
            starRating,
            hotelName,
            hotelCity,
            hotelAddress: address,
            offerId: id,
            rate: {
              baseRate: offer.rate.base,
              localTaxes: offer.rate.hotelFees,
              taxes: offer.rate.taxes,
              totalRate: Math.round(calculatedTotalRate * 100) / 100,
              localTaxesIncluded,
              taxesIncluded
            },
            id: userId,
            checkIn: getEpochSeconds(checkIn),
            checkOut: getEpochSeconds(checkOut),
            pageUrl: window.location.href,
            bookURI: offer.url,
            rooms,
            roomName: offer.roomName,
            currency,
            country: userCountryCode,
            locale,
            isUserSearch,
            providerCode: offerContext?.providerCode,
            proxyProviderCode: offerContext?.proxyProviderCode,
            hotelImageUrl,
            timezone: getTimezone()
          })
        if (isBofhOffer && shouldGoToRooms) {
          onOfferClick(offer)
          return
        }
        if (isBofhOffer && isMobileOrTablet) {
          /**
           * moving this piece of logic here should also handle lockedDeals and getTheApp offer buttons
           * because cause previously this was called before it was even checked
           * - make sure putting it here, below doesn't break booking-flow-redesign requirements
           *
           * comment to be removed before deploy
           */
          const isRedirectVioLeader = toggle(
            '6cde7b20-bookingexp-standalone-ap-mobile-v5',
            brandHasRooms,
            false
          )

          if (isRedirectVioLeader && !isMountedOnAp && !isSplitBooking) {
            const analyticsContext = {
              [AnalyticsContext.HotelContext]:
                getParametrizedHotelContext(hotelId),
              [AnalyticsContext.HotelInfoContext]:
                getParametrizedHotelInfoContext(hotelId)
            }
            const redirectId = uuidv4()
            const sourceIp = getUserIpFromOffer(offer, metaAddress)
            dispatch(
              trackProviderRedirect({
                offerId: offer.id,
                intermediaryProvider: offer.intermediaryProvider,
                offerUrl: offer.url,
                sourceIp,
                redirectId
              })
            )
            const checkIn = getParamFromOfferBookURI(offer.url, 'checkIn')
            const checkOut = getParamFromOfferBookURI(offer.url, 'checkOut')
            /* clicking on Offer in SRP adds clicked offer details to the URL */
            const locationParams = SRP_COMPONENTS.includes(offerView)
              ? {
                  clickedOffer: offer.id,
                  clickedBase: offer.rate.base,
                  clickedTaxes: offer.rate.taxes,
                  clickedFees: offer.rate.hotelFees,
                  clickedProvider: offer.providerCode,
                  redirectId,
                  ...(checkIn && checkOut ? {checkIn, checkOut} : {})
                }
              : {redirectId}
            if (offerView === OfferViewType.SRP_BEST_DEAL_MODAL) {
              dispatch(hidePopupOnExit())
            }
            showAccommodationPage({
              hotelId,
              locationParams,
              analyticsContext,
              payload: {
                source: VIO_OFFER_CLICKED
              }
            })
          }
        }
      }
    },
    [
      anonymousId,
      offerCheckIn,
      offerCheckOut,
      currency,
      dispatch,
      hotelContext,
      hotelId,
      brandIsVio,
      shouldSeeOffersUnlocked,
      isUserSearch,
      locale,
      logIn,
      paginationContext,
      sourceComponent,
      team,
      userId,
      showAccommodationPage,
      Opticks,
      activeOffersFilters,
      appDownloadLinkId,
      brand,
      deviceType,
      hotelOfferEntity,
      isAppLockedDeal,
      isMobileOrTablet,
      isRequiredOneTimePasswordFlow,
      isSplitBooking,
      localTaxesIncluded,
      metaAddress,
      offerView,
      onOfferClick,
      shouldGoToRooms,
      splitBookingDetails,
      taxesIncluded,
      address,
      hasSingleFlowSBOffer,
      hotelName,
      imageURIs,
      isGHA,
      isHotelDetailsOverlayOpen,
      starRating,
      isBofhOffer
    ]
  )

  return handleOfferClick
}
