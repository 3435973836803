import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {setLanguageCode} from 'modules/meta/slice'
import {toggle} from 'opticks'
import {persistLanguageCode} from 'utils/localization/language'

interface Props {
  languageCode: string
  children: React.ReactElement
}

const NEW_LANGUAGES = [
  'bn',
  'fil',
  'hi',
  'mr',
  'es-AR',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-EC',
  'es-GT',
  'es-MX',
  'es-PA',
  'es-PY',
  'es-PE',
  'es-PR',
  'es-DO',
  'es-UY',
  'es-VE'
]
// Added as part of 2a98dd4d-add-new-languages--v2
export const NewLanguagesABTestOverrides: React.FC<Props> = ({
  children,
  languageCode
}) => {
  const isASide = toggle('2a98dd4d-add-new-languages--v2', true, false)
  const dispatch = useDispatch()
  const isNewLanguage = NEW_LANGUAGES.includes(languageCode)

  useEffect(() => {
    if (isNewLanguage && isASide) {
      const nextLanguage = languageCode.startsWith('es-') ? 'es' : 'en'
      dispatch(setLanguageCode(nextLanguage))
      persistLanguageCode(nextLanguage)
    }
  }, [isASide, dispatch, isNewLanguage, languageCode])

  if (isNewLanguage && isASide) {
    return null
  }

  return children
}
