import {useDispatch, useSelector} from 'react-redux'
import {getAnonymousId} from 'modules/meta/selectors'
import {showAPOverlay} from 'modules/overlay/slice'
import {
  getAnchorHotelId,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import {toggle} from 'opticks'
import {omit} from 'ramda'
import {generateAccommodationPageURL, stringify} from 'utils/url'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {getUrlParams} from '@daedalus/core/src/_web/utils/url'
import {AnalyticsContextList} from '@daedalus/core/src/analytics/types/Events'
import {TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY} from '@daedalus/core/src/auth/modules/actions'
import {mapSearchToRoomsFilters} from '@daedalus/core/src/room/business/utils/filters'

import {useFiltersForm} from './useFiltersForm'

const filterKeys = ['freeCancellation', 'amenities']

const TEMPORARY_VIO_CLICKER_PARAMS = [
  'clickedOffer',
  'clickedBase',
  'clickedTaxes',
  'clickedFees',
  'clickedProvider',
  'clickedUserIp',
  'redirectId'
]

type ShowAccommodationPage = (params: {
  hotelId: string
  params?: Record<string, unknown>
  payload?: Record<string, unknown>
  analyticsContext?: AnalyticsContextList
  locationParams?: Record<string, unknown>
}) => void

export const useShowAccommodationPage = () => {
  const dispatch = useDispatch()
  const {getAppliedFilters} = useFiltersForm(filterKeys)
  const appliedFilters = getAppliedFilters()
  const activeRoomsFilters = mapSearchToRoomsFilters(appliedFilters)
  const {isMobile} = useDeviceLayout()
  const anchorHotelId = useSelector(getAnchorHotelId)
  const anonymousId = useSelector(getAnonymousId)
  const {checkIn, checkOut} = useSelector(getSearchParameters) || {}

  const showAccommodationPage: ShowAccommodationPage = ({
    hotelId,
    params,
    payload,
    analyticsContext,
    locationParams
  }) => {
    localStorage.setItem('navigationToAccommodationPage', Date.now().toString())
    const currentUrlParams = omit(
      TEMPORARY_VIO_CLICKER_PARAMS,
      getUrlParams()
    ) as Record<string, string>
    const simulateParam: string = currentUrlParams.simulate
    const isRedirectToStandaloneAP =
      toggle('6cde7b20-bookingexp-standalone-ap-mobile-v5', false, true) &&
      isMobile

    // don't inject roomsFilters into url for desktop hotel-details overlay case
    const shouldAddRoomsFilters = isMobile || isRedirectToStandaloneAP

    const isAnchor = hotelId === anchorHotelId

    const locationParamsWithPersistedFilters = {
      ...(isRedirectToStandaloneAP && {
        isAnchorHotelSelectedInSRP: isAnchor ? '1' : '0',
        anonymousId,
        // add fallback dates if not present in url params
        checkIn,
        checkOut
      }),
      ...currentUrlParams,
      ...locationParams,
      ...(activeRoomsFilters.length && shouldAddRoomsFilters
        ? {roomFilters: activeRoomsFilters.join(',')}
        : {}),
      ...(simulateParam?.includes('SplitBooking')
        ? {
            simulate: 'Rooms.withMergedSplitBooking'
          }
        : {})
    }
    const searchParams = stringify(locationParamsWithPersistedFilters)

    if (isRedirectToStandaloneAP) {
      const accommodationPageURL = generateAccommodationPageURL(
        hotelId,
        searchParams,
        isAnchor
      )
      setTimeout(() => {
        window.open(accommodationPageURL, '_blank')
      }, TRACKING_TIMEOUT_BEFORE_NAVIGATING_AWAY)
    } else {
      dispatch(
        showAPOverlay({
          params: {...params, hotelId},
          locationParams: locationParamsWithPersistedFilters,
          analyticsContext,
          payload
        })
      )
    }
  }

  return {showAccommodationPage}
}
