import React from 'react'
import {useSelector} from 'react-redux'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Tag} from '@daedalus/atlas/Tag'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {getFirstEligibleDealFreezeRoom} from '@daedalus/shared/src/dealFreeze/modules/selector'
import {getUserAlreadyFrozeADealCookie} from '@daedalus/shared/src/dealFreeze/services/cookies'

interface Props {
  roomId: string
}

export const DealFreezeBanner: React.FC<Props> = ({roomId}) => {
  const firstEligibleDealFreezeRoomId = useSelector(
    getFirstEligibleDealFreezeRoom
  )

  const isUserAlreadyFrozeADeal = getUserAlreadyFrozeADealCookie()

  if (firstEligibleDealFreezeRoomId === roomId && !isUserAlreadyFrozeADeal)
    return (
      <Box
        display="flex"
        padding="s400"
        borderColor="border.neutral.c100"
        borderRadius="lg"
        borderStyle="solid"
        flexDirection="column"
        gap="s250"
        marginTop="s500"
      >
        <Box display="flex" gap="s250" alignItems="center">
          <Text variant="titleS">
            <FormattedMessageWrapper
              id="df.bannerFreezeDeal"
              defaultMessage="Freeze a deal"
            />
          </Text>
          <Tag variant="info" size="sm">
            <FormattedMessageWrapper
              id="df.bannerNewTag"
              defaultMessage="New"
            />
          </Tag>
        </Box>
        <Text variant="bodyXS" colorPath="content.neutral.c600">
          <FormattedMessageWrapper
            id="df.bannerSubtitle"
            defaultMessage="Secure the price and availability, no card details required."
          />
        </Text>
      </Box>
    )
}
