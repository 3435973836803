import React from 'react'
import {useSelector} from 'react-redux'
import {
  SplitBookingBundleType,
  SplitBookingOfferInfo
} from 'components/Offer/types'
import {useIsRequiredOneTimePasswordFlow} from 'hooks/useIsRequiredOneTimePasswordFlow'
import {getCurrencyCode} from 'modules/meta/selectors'
import {
  getAppLockedDealConfig,
  getCheapestPriceRateBreakdown,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'

import {Text} from '@daedalus/atlas/Text'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {OfferCompactLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {useVioTag} from '@daedalus/core/src/tag'
import {OfferLayout} from '@daedalus/shared/src/search/OfferLayout'

import {OfferInteraction} from '../Shared/OfferInteraction'
import {ProviderLogoComponent} from '../Shared/ProviderLogoComponent'
import {RoomLabelsComponent} from '../Shared/RoomLabelsComponent'
import {SplitBookingOfferInteraction} from '../Shared/SplitBookingOfferInteraction'
import {CtaComponent} from './CtaComponent'
import {PriceComponent} from './PriceComponent'
import {TagComponent} from './TagComponent'

interface MainProps {
  isLoading?: false
  variant: OfferCompactLayoutVariant
  hotelId: string
  offer: SearchOffer
  anchorPrice: number
  anchorPriceTotal?: number
  offerVariant: OfferVariant
  hasRoomLabels?: boolean
  hasTag?: boolean
  showArrowCTA?: boolean
  isSplitBooking?: false
  splitBookingType?: never
  hotelHasMoreThanOneOffer?: boolean
  sourceComponent: string
  offerPosition: number
  hasInteraction: boolean
  splitBookingOffers?: never
}

interface SplitBookingProps {
  isLoading?: false
  variant: OfferCompactLayoutVariant
  hotelId: string
  hasRoomLabels?: boolean
  hasTag?: boolean
  shouldSeeOffersUnlocked?: boolean
  showArrowCTA?: boolean
  isSplitBooking: true
  splitBookingType?: SplitBookingBundleType
  splitBookingOffers?: SplitBookingOfferInfo[]
  hotelHasMoreThanOneOffer?: boolean
  sourceComponent?: string
  offerPosition?: never
  hasInteraction?: never
  offer?: never
  anchorPrice?: number
  anchorPriceTotal?: number
  offerVariant?: never
}

interface LoadingProps {
  isLoading: true
  variant: OfferCompactLayoutVariant
  hotelId?: never
  offer?: never
  anchorPrice?: never
  anchorPriceTotal?: never
  offerVariant?: never
  hasRoomLabels?: never
  hasTag?: never
  shouldSeeOffersUnlocked?: never
  showArrowCTA?: never
  isSplitBooking?: never
  splitBookingType?: never
  splitBookingOffers?: never
  hotelHasMoreThanOneOffer?: never
  sourceComponent?: never
  offerPosition?: never
  hasInteraction?: never
}

export const CompactOffer: React.FC<
  MainProps | SplitBookingProps | LoadingProps
> = ({
  variant,
  isLoading,
  hotelId,
  offer,
  offerVariant,
  anchorPrice,
  anchorPriceTotal = 0,
  hasRoomLabels = false,
  hasTag = true,
  showArrowCTA = false,
  isSplitBooking = false,
  splitBookingType,
  splitBookingOffers,
  hotelHasMoreThanOneOffer = false,
  sourceComponent,
  offerPosition,
  hasInteraction = true
}) => {
  const currencyCode = useSelector(getCurrencyCode)
  const cheapestPriceRateBreakdown = useSelector(state =>
    getCheapestPriceRateBreakdown(state, hotelId)
  )

  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isRequiredOneTimePasswordFlow = useIsRequiredOneTimePasswordFlow({
    offer,
    isAppLockedDeal
  })

  const showTotalPrices = useSelector(getShowTotalPrices)
  const isBofhOffer = getIsBofhOffer(offer)
  const {brandCode} = useBrand()
  const vioTagDetails = useVioTag({
    price: showTotalPrices ? offer?.totalRate : offer?.nightlyRate,
    anchorPrice: showTotalPrices ? anchorPriceTotal : anchorPrice,
    currencyCode,
    isVioOffer: isBofhOffer,
    brandCode
  })

  // The tag is only visible for the cheapest offer in the list
  // and for offers cheaper than the cheapest offer
  const isVioTagVisible =
    vioTagDetails.isVioTagVisible &&
    offerVariant === OfferVariant.OnlyOnVio &&
    offer?.nightlyRate <= cheapestPriceRateBreakdown?.nightlyRate

  if (isLoading) return <OfferLayout variant={variant} isLoading />

  if (isSplitBooking) {
    return (
      <SplitBookingOfferInteraction
        component={sourceComponent}
        hotelId={hotelId}
      >
        <OfferLayout
          variant={variant}
          tagComponent={
            <TagComponent
              isSplitBooking
              splitBookingType={splitBookingType}
              hasTag={hasTag}
            />
          }
          providerLogoComponent={
            <ProviderLogoComponent
              isSplitBooking
              splitBookingOffers={splitBookingOffers}
              variant={variant}
            />
          }
          roomLabelsComponent={
            <>
              <RoomLabelsComponent
                isSplitBooking
                hasRoomLabels={hasRoomLabels}
                splitBookingOffers={splitBookingOffers}
                splitBookingType={splitBookingType}
                hotelId={hotelId}
              />
              {splitBookingType === 'single_flow' && (
                <Text variant="bodyXS" colorPath="content.neutral.c600">
                  <FormattedMessageWrapper
                    id="roomSwitchRequired"
                    defaultMessage="Room switch required"
                  />
                </Text>
              )}
            </>
          }
          priceComponent={
            <PriceComponent
              isSplitBooking
              hotelId={hotelId}
              anchorPrice={anchorPrice}
              anchorPriceTotal={anchorPriceTotal}
            />
          }
          ctaComponent={
            <CtaComponent
              splitBookingType={splitBookingType}
              hotelId={hotelId}
              showArrowCTA={showArrowCTA}
              offer={offer}
            />
          }
        />
      </SplitBookingOfferInteraction>
    )
  }

  return (
    <OfferInteraction
      anchorPriceTotal={anchorPriceTotal}
      hotelId={hotelId}
      offer={offer}
      component={sourceComponent}
      offerPosition={offerPosition}
      hasInteraction={hasInteraction}
    >
      <OfferLayout
        variant={variant}
        tagComponent={
          isVioTagVisible && (
            <TagComponent
              hotelId={hotelId}
              offer={offer}
              anchorPrice={anchorPrice}
              anchorPriceTotal={anchorPriceTotal}
              offerVariant={offerVariant}
              hotelHasMoreThanOneOffer={hotelHasMoreThanOneOffer}
              hasTag={hasTag}
            />
          )
        }
        providerLogoComponent={
          <ProviderLogoComponent
            offer={offer}
            variant={variant}
            isAppLockedDeal={isAppLockedDeal}
            isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
          />
        }
        roomLabelsComponent={
          <RoomLabelsComponent
            hasRoomLabels={hasRoomLabels}
            offer={offer}
            hotelId={hotelId}
          />
        }
        priceComponent={
          <PriceComponent
            hotelId={hotelId}
            offer={offer}
            showAnchorPrice={isVioTagVisible}
            isPrivateDeal={isVioTagVisible}
            anchorPrice={anchorPrice}
            anchorPriceTotal={anchorPriceTotal}
            variant={variant}
            showAppIcon={showArrowCTA}
            showLockIcon={showArrowCTA}
            isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
          />
        }
        ctaComponent={
          <CtaComponent
            hotelId={hotelId}
            showArrowCTA={showArrowCTA}
            offer={offer}
            isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
          />
        }
      />
    </OfferInteraction>
  )
}
