import React from 'react'
import {injectIntl, IntlShape} from 'react-intl'
import {isSavingsDeal} from 'utils/offers'

import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {ProviderLogo} from '@daedalus/shared/src/search/ProviderLogo'

type PropsType = {
  anchorPrice: number
  currency: string
  intl: IntlShape
  totalPrice: number
  brandCode: string
}

const RedirectTotalSavedComponent = ({
  brandCode,
  totalPrice,
  anchorPrice,
  currency,
  intl: {formatNumber}
}: PropsType) => {
  const priceDifference = anchorPrice - totalPrice
  const showSavedPriceMessage = isSavingsDeal(anchorPrice, totalPrice)

  const formattedPrice = formatNumber(priceDifference, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return (
    <Flex.Column justifyContent="center" alignItems="center" gap="s500">
      <ProviderLogo variant="logo" height={30} providerCode={brandCode} />
      {!showSavedPriceMessage ? (
        <Text variant="titleM">
          <FormattedMessageWrapper
            id="providerRedirect.foundBetterValue"
            defaultMessage="You found a <span>better value</span> stay"
            values={{
              span: chunks => (
                <Text
                  as="span"
                  variant="titleM"
                  colorPath="content.special.c600"
                >
                  {chunks}
                </Text>
              )
            }}
          />
        </Text>
      ) : (
        <>
          <Text variant="titleXL">
            <FormattedMessageWrapper
              id="providerRedirect.hasSavedYou"
              defaultMessage="has saved you"
            />
          </Text>
          <Text variant="title4XL" colorPath="content.special.c600">
            {formattedPrice}
          </Text>
        </>
      )}
    </Flex.Column>
  )
}

export const RedirectTotalSaved = injectIntl(RedirectTotalSavedComponent)
