import React from 'react'
import {defineMessage, useIntl} from 'react-intl'

import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'

import {hasRemainingMessage} from '../../utils/userLimits'

const messages = {
  remainingDeals: [
    defineMessage({
      id: 'df.noMoreDealRemaining',
      defaultMessage: 'You’ve frozen your last deal'
    }),
    defineMessage({
      id: 'df.oneMoreDealRemaining',
      defaultMessage: 'You can freeze one more deal'
    }),
    defineMessage({
      id: 'df.twoMoreDealsRemaining',
      defaultMessage: 'You can freeze two more deals'
    })
  ],
  description: defineMessage({
    id: 'df.remainingDescription',
    defaultMessage: 'Secure up to three deals at once – totally free.'
  })
}

interface Props {
  remainingDeals?: number | null
}
export const RemainingBooking: React.FC<Props> = ({remainingDeals}) => {
  const {formatMessage} = useIntl()

  if (
    remainingDeals === undefined ||
    remainingDeals === null ||
    !hasRemainingMessage(remainingDeals)
  )
    return null

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      width="100%"
      gap="s300"
      marginBottom="s400"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        paddingTop="s100"
        paddingBottom="s100"
      >
        <Icon name="Information" />
      </Box>
      <Box flex="1">
        <Text variant="titleM">
          {messages.remainingDeals?.[remainingDeals] &&
            formatMessage(messages.remainingDeals[remainingDeals])}
        </Text>
        <Text variant="bodyS">{formatMessage(messages.description)}</Text>
      </Box>
    </Box>
  )
}
