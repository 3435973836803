/**
 * Get a safe bottom spacing for mobile - adding a bugfix to safe-area-inset-bottom
 * On iOS Safari<14 and Chrome a tap on the bottom 44px of the screen will first trigger showing the browser navbar
 * By increasing the safe-area we can avoid this dead zone
 * @returns string - CSS value
 */
const getSafeBottomSpacingValue = ({
  spacing,
  isSafari
}: {
  spacing: 0 | string
  isSafari: boolean
}): string => {
  const bottomSafariMultiplier = isSafari ? 1.6 : 1

  return `calc(
    ${spacing} + env(safe-area-inset-bottom) *
    ${bottomSafariMultiplier}
  )`
}

export default getSafeBottomSpacingValue
