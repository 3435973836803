import {WHITELISTED_REDIRECT_URLS} from 'config/whitelistedRedirectUrls'
import {filterParamNames} from 'modules/search/constants'
import qs from 'qs'
import {mergeRight, pipe, propEq} from 'ramda'

import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'
import {isRateCheckerFromUrl} from '@daedalus/core/src/rateChecker/business'
import {parseQueryString} from '@daedalus/core/src/utils/url'
import {regex} from '@daedalus/core/src/utils/validation'

export const stringify = <T extends object>(query: T) =>
  qs.stringify(query, {
    arrayFormat: 'repeat',
    encodeValuesOnly: true
  })

const updateParameters = (
  queryString: string,
  parameters: Record<string, string>
) => {
  return stringify(mergeRight(parseQueryString(queryString), parameters))
}

/**
 * Generate a function to check whether a parameter is set to "1" in the search string
 * @example
 * ```
 * const forceLanguageChange = isParameterEnabled('forceLanguageChange')(history.location.search)
 * ```
 */
const isUrlParameterEnabled = (name: string) =>
  pipe(parseQueryString, propEq(name, '1'))

export const isUrlWhitelisted = (url: string): boolean => {
  const domain = new URL(url).hostname
  return WHITELISTED_REDIRECT_URLS.some(substr => regex(substr, domain))
}

/**
 * Check if the queryString contains the fsmr parameter (used to perform multi-room searches)
 * @param queryString: string
 * @returns boolean
 */
export const isFsmrInQueryString = (queryString: string): boolean => {
  const {fsmr} = parseQueryString(queryString)
  return Boolean(fsmr)
}

const getIsRateCheckerFromQueryString = (): boolean => {
  const urlParams = getQueryStringObject()
  const isRateChecker = isRateCheckerFromUrl(urlParams)
  return isRateChecker
}

/**
 * Generates an Accommodation Page URL based on the provided hotelId and searchParams.
 * @param  hotelId - The ID of the hotel.
 * @param searchParams - The URL query parameters as a string.
 * @returns The generated AP URL.
 */
export const generateAccommodationPageURL = (
  hotelId: string,
  searchParams: string,
  isAnchorHotel?: boolean
): string => {
  // Create a URLSearchParams object from the provided searchParams string
  const params = new URLSearchParams(searchParams)

  // Get the hotelId from either the provided hotelId or the 'hotelId' parameter in searchParams
  const id: string = hotelId || params.get('hotelId')

  // Remove 'hotelId' and 'placeId' parameters from the searchParams
  params.delete('hotelId')
  params.delete('placeId')

  // If no valid id is found, return an empty string
  if (!id) return ''

  // If there are no additional query parameters, return a simple URL without query parameters
  if (!params.toString()) return `/Hotel/${id}`

  if (isAnchorHotel) {
    filterParamNames.forEach(name => params.delete(name))
    return `/Hotel/${id}?${params.toString()}`
  }
  // Construct the final URL with both the hotelId and modified query parameters
  return `/Hotel/${id}?${params.toString()}`
}

const url = {
  /**
   * @deprecated Use core functions from daedalus/core/src/utils/url
   */
  parse: parseQueryString,
  stringify,
  updateParameters,
  isUrlParameterEnabled,
  isUrlWhitelisted,
  getIsRateCheckerFromQueryString
}

export default url
