import {CookieConfig} from 'types/Cookie'

import {getCookie, setCookie} from '../../utils/cookies'
import userCityCookieConfig from './cookieConfig'

const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

export const getPersistedUserCity = () => getCookie(userCityCookieConfig.name)

export const persistUserCity = (userCity: string) => {
  setCookie(
    userCityCookieConfig.name,
    userCity,
    getCookieOptions(userCityCookieConfig)
  )
}
