import * as React from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {TIERS} from 'components/SortBy/constants'
import {getFilterKey, getSortByLabels} from 'components/SortBy/helpers'
import {showSortBy} from 'modules/overlay/actions'
import {getUrlParams} from 'modules/search/selectors'

import {Pill} from '@daedalus/atlas/Pill'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  isActive?: boolean
}

export const SortByDisplayContainer = ({isActive = false}: Props) => {
  const dispatch = useDispatch()
  const {sortField, sortOrder} = useSelector(getUrlParams)
  const {formatMessage} = useIntl()
  const {brandIsVio} = useBrand()
  const sortLabels = getSortByLabels()

  const handleShowSortBy = () => {
    dispatch(showSortBy())
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.SortButton,
        action: Action.Clicked
      })
    )
  }

  const tier = brandIsVio ? TIERS.Gems : TIERS.PrivateDeal

  const content = () => {
    if (sortField) {
      return formatMessage(
        sortLabels[getFilterKey(sortField, sortOrder)?.key],
        {
          tier
        }
      )
    } else {
      return <FormattedMessageWrapper id="sort" defaultMessage="Sort" />
    }
  }

  return (
    <>
      <Pill
        dataId="sortByFilter"
        isSelected={Boolean(isActive)}
        showArrow
        fullWidth={false}
        onClick={handleShowSortBy}
      >
        {content()}
      </Pill>
    </>
  )
}
