import {reject} from 'ramda'

import {isReactNativeWebView} from '../../native'
import {hasUnlockPlusTokenInUrl} from '../../offer/business/privateDeals'

import type {FacetsAsArray} from '../types'
import type {
  Anchor,
  HotelOfferEntity,
  Offer,
  PlaceAnchor,
  SearchResults
} from '@findhotel/sapi'

/**
 * Returns string value for the `tier` parameter.
 */
interface GetUserTierForSapi {
  isMemberPlus: boolean
  isAuthenticated: boolean
  urlParams?: {token?: string}
  isBrandOffersLockedByDefault?: boolean
}
export const getUserTierForSapi = ({
  isMemberPlus,
  isAuthenticated,
  urlParams,
  isBrandOffersLockedByDefault = true
}: GetUserTierForSapi) => {
  const tier = isAuthenticated ? 'member' : ''

  const hasUnlockPlusToken = urlParams && hasUnlockPlusTokenInUrl(urlParams)
  const isReactNative = isReactNativeWebView()
  const shouldForcePlus =
    isMemberPlus ||
    hasUnlockPlusToken ||
    isReactNative ||
    !isBrandOffersLockedByDefault
  return shouldForcePlus ? 'plus' : tier
}

export const applyRateControl = (
  offerEntity: HotelOfferEntity,
  visibleTopOffersLimit: number
): HotelOfferEntity => {
  if (offerEntity?.offers) {
    const offers = reject(
      (offer: Offer) => Boolean(offer?.accessTier),
      offerEntity.offers
    )
    const lengthDifference = offerEntity.offers.length - offers.length

    const availableOffersCount =
      offerEntity.availableOffersCount - lengthDifference

    const hasMoreOffers = availableOffersCount > visibleTopOffersLimit

    return {
      ...offerEntity,
      availableOffersCount,
      // [OffersV3] Type mismatch during migration
      hasMoreOffers,
      offers
    } as HotelOfferEntity
  }

  return offerEntity
}

/**
 * Transform facets data into arrays which is easier to work with in rendering.
 * @param facets - Facets data as supplied by SAPI - see https://github.com/FindHotel/sapi#response-1
 * @returns object - Facets data with each property converted to an array
 * @example
 * ```typescript
 * const input = {
 *   "facilities": {
 *     "1": 147
 *   }
 * }
 *
 * const output = {
 *   "facilities": [
 *     { "id": "1", "value": 147 }
 *   ]
 * }
 * ```
 */
export const transformFacetsToArray = (
  facets: undefined | SearchResults['facets']
): undefined | FacetsAsArray => {
  if (!facets) return

  return Object.keys(facets).reduce((prevFacetResult, currentFacetKey) => {
    const facet = facets[currentFacetKey]

    prevFacetResult[currentFacetKey] = Object.keys(facet).reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr,
          value: facet[curr]
        }
      ],
      [] as FacetsAsArray[string]
    )

    return prevFacetResult
  }, {} as FacetsAsArray)
}

/**
 * Type guard to discern PlaceAnchor (includes place, geolocation, area, address) from HotelAnchor
 * @param anchor - search results anchor data
 * @returns boolean
 */
export const isPlaceAnchor = (anchor: Anchor): anchor is PlaceAnchor =>
  anchor.objectType !== 'hotel'

/**
 * Get the place category from place anchor data (or undefined for hotel anchor) in a
 * @param anchor - search results anchor data
 * @returns placeCategory - number, see https://github.com/FindHotel/sapi-backend/blob/89c452445e4d4064f10bdb47e71262fe1e455b2e/internal/anchor/place_category.go#L3-L14
 */
export const getAnchorPlaceCategory = (anchor: Anchor) =>
  isPlaceAnchor(anchor) ? anchor.placeCategory : undefined

/**
 * Determines SAPI sorting strategy based on given tax config flags
 * @param param0 - tax config flags
 * @returns sapi sorting strategy name
 */
export const getPriceSortConfigFromTaxConfig = ({
  includeLocalTaxes,
  includeTaxes
}: {
  includeLocalTaxes: boolean
  includeTaxes: boolean
}) => {
  if (includeLocalTaxes && includeTaxes) {
    return 'price:total_asc'
  }

  if (!includeLocalTaxes && includeTaxes) {
    return 'price:with_tax_asc'
  }

  if (!includeLocalTaxes && !includeTaxes) {
    return 'price:base_asc'
  }

  return undefined
}
