import {loadModule} from 'utils'

import type {Props} from '../PropertyTypeFilterBottomSheet/PropertyTypeFilterBottomSheetContent'

export const PropertyTypeFilterBottomSheetContentLoadable = loadModule<Props>(
  async () =>
    import(
      /* webpackChunkName: "PropertyTypeFilterBottomSheetContent" */
      './PropertyTypeFilterBottomSheetContent'
    ),
  {}
)
