import {mapEventChannel} from 'events/map'
import {SearchType} from 'modules/sapiSearch/utils'

const STREET_VIEW_UNAVAILABLE_COUNTRIES = [
  'CY',
  'LB',
  'SY',
  'IQ',
  'IR',
  'IL',
  'JO',
  'SA',
  'KW',
  'QA',
  'BH',
  'AE',
  'OM',
  'YE',
  'DZ',
  'AO',
  'BJ',
  'BW',
  'IO',
  'BF',
  'BI',
  'CV',
  'CM',
  'CF',
  'TD',
  'KM',
  'CG',
  'CD',
  'CI',
  'DJ',
  'EG',
  'GQ',
  'ER',
  'SZ',
  'ET',
  'TF',
  'GA',
  'GM',
  'GH',
  'GN',
  'GW',
  'KE',
  'LS',
  'LR',
  'LY',
  'MG',
  'MW',
  'ML',
  'MR',
  'MU',
  'YT',
  'MA',
  'MZ',
  'NA',
  'NE',
  'NG',
  'RE',
  'RW',
  'SH',
  'ST',
  'SN',
  'SC',
  'SL',
  'SO',
  'ZA',
  'SS',
  'SD',
  'TZ',
  'TG',
  'TN',
  'UG',
  'EH',
  'ZM',
  'ZW',
  'PK'
]

/**
 * Gets the language used for the map
 * @param languageCode - The language code
 * @returns The language to use on the map
 */
export const getMapLanguage = (languageCode: string) => {
  /* Hong Kong and Taiwan use traditional chinese */
  if (['zh-hk', 'zh-tw'].includes(languageCode.toLowerCase())) {
    return 'zh-Hant'
  }

  /* Mainland China uses simplified chinese */
  if (languageCode === 'zh') {
    return 'zh-Hans'
  }

  if (languageCode.includes('-')) {
    return languageCode.split('-')[0]
  }

  return languageCode
}

/**
 * Checks if the selected hotel should be updated
 * @param firstHotelIdIsAvailable - Whether the first hotelId is available
 * @param firstHotelId - The id of the first hotel
 * @param selectedHotelId - The id of the selected hotel
 * @param selectedMarkerIsSetByUser - Whether the marker has been set by the user
 * @param isDesktop - Whether the user is on Desktop
 * @returns Whether the selected hotel should be updated
 */
export const shouldSelectedHotelBeUpdated = (
  firstHotelIdIsAvailable: boolean,
  firstHotelId: string,
  selectedHotelId: string,
  anchorHotelId: string,
  selectedMarkerIsSetByUser: boolean,
  isDesktop: boolean
) => {
  if (isDesktop) {
    return false
  } // Don't update if the user is on desktop

  if (selectedMarkerIsSetByUser) {
    return false
  } // Don't update again if the marker was changed by the user

  if (firstHotelIdIsAvailable) {
    return true
  } // Only update once we know the first hotel is available

  if (firstHotelId !== selectedHotelId && firstHotelId === anchorHotelId) {
    return true
  } // Update if first hotel is the anchor hotel and it's not the selected hotel

  if (!firstHotelId) {
    return true
  } // Or if there is no hotel in the list to select

  return false
}

/**
 * Gets the first hotel id
 * @param searchType - The type of search
 * @param anchorHotelId - The hotel id of the anchor hotel
 * @param hotelIds - Array of hotel ids
 * @returns The first hotel id
 */
export const getFirstHotelId = (
  searchType: SearchType,
  anchorHotelId: string,
  hotelIds: string[]
) => {
  if (searchType === SearchType.Hotel) {
    return anchorHotelId
  }

  return hotelIds[0]
}

/**
 * Emits the event that is registered at the useMap hook
 * Makes double zoom-out and performs the area search
 */
export const showNearestResults = () => {
  mapEventChannel.emit('showNearestResults')
}

export const getIsStreetViewEnabled = (hotelCountry: string): boolean => {
  if (!hotelCountry) return false
  return !STREET_VIEW_UNAVAILABLE_COUNTRIES.includes(hotelCountry)
}
