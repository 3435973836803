import {loadModule} from 'utils'

import type {HotelDetailsTabsProps} from './HotelDetailsTabs'

export enum TabId {
  Photos = 'photos',
  Details = 'details',
  Location = 'location',
  Reviews = 'reviews',
  Deals = 'deals'
}

export const HotelDetailsTabs = loadModule<HotelDetailsTabsProps>(
  async () =>
    import(
      /* webpackChunkName: "HotelDetailsTabs" */
      './HotelDetailsTabs'
    ),
  {}
)
