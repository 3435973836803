import {MutableRefObject, useCallback, useEffect, useState} from 'react'

interface Info {
  /** Element height */
  height: number
  /** Element width */
  width: number
  /** Element */
  el: HTMLElement
}

/**
 * Returns the info including height, width and el of referenced element.
 * When the element resizes, the new object with height, width and el is returned.
 * @returns an object with height and width and el (as the element)
 * @example
 * ```jsx
 * const {elRef, info} = useElementSize()
 * console.log(info) // {width: 128, height:360, el: <div>Hello world!</div> }
 * return (
 *  <div ref={elRef}>Hello world!</div>
 * )
 * ```
 */
export function useElementSize<T extends HTMLElement>() {
  const [size, setSize] = useState<Info>({height: 0, width: 0, el: null})
  const [element, setElement] = useState<T>(null)

  const elementRef = useCallback((node: T) => {
    if (node) {
      setElement(node)
    }
  }, []) as unknown as MutableRefObject<T>

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setSize({
          height: entry.contentRect.height,
          width: entry.contentRect.width,
          el: element
        })
      })
    })

    if (element) {
      resizeObserver.observe(element)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [element])

  return {elementRef, size}
}
