import React from 'react'

import {Stack} from '@daedalus/atlas/helpers/Stack'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

export const InfoMessage = () => (
  <Stack space="s300">
    <Text variant="bodyS">
      <FormattedMessageWrapper
        dataId="optimizeForBestPriceDescription"
        id="optimizeForBestPriceDescription"
        defaultMessage="'Optimize for best price' configures the number of rooms and guests per room automatically."
      />
    </Text>
    <br />
    <Text variant="bodyS">
      <FormattedMessageWrapper
        dataId="configureRoomsManuallyDescription"
        id="configureRoomsManuallyDescription"
        defaultMessage="'Configure manually' lets you choose the number of rooms and guests per room yourself."
      />
    </Text>
  </Stack>
)
