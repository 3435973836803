import {useEffect} from 'react'

/**
 * IMPORTANT NOTICE: It is strongly advised to avoid using this hook within your code.
 * If you insist on using it, please ensure that this hook is implemented only once per page.
 * @param enabled - enable the hook
 * @param callbackFn - function that should call while user click on back button
 */
export const useHistoryBack = (enabled: boolean, callbackFn: () => void) => {
  useEffect(() => {
    if (!enabled) return

    if (!history.state?.isHistoryBack) {
      history.replaceState({isHistoryBack: true}, '')
      history.pushState(null, '')
      console.info('Injected exit modal') // This functionality is very opaque so this could help spot issues and regressions (ie. running too often or in the wrong moments)
    }

    const onPopState = ({state}: PopStateEvent) => {
      if (state?.isHistoryBack) {
        callbackFn()
        removeEventListener('popstate', onPopState)
      }
    }

    addEventListener('popstate', onPopState)

    return () => {
      removeEventListener('popstate', onPopState)
    }
  }, [callbackFn, enabled])
}
