import {IpInfoErrorResponse, IpInfoResponse} from 'types/IpInfo'

type Body = IpInfoResponse | IpInfoErrorResponse

/**
 * Fetch country and other data via user's IP
 */
export const fetchIpInfo = async (): Promise<IpInfoResponse> => {
  const response = await fetch('/api/ip-info') // Do not replace with Axios, preload will not work due to mismatched request headers

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  let body: Body
  try {
    body = await response.json()
  } catch {
    // Body is not (valid) JSON
    throw new Error(response.statusText)
  }

  return body as IpInfoResponse
}
