import {SearchParamsType} from 'types/Search'

import {Page} from '../analytics/types/Events'

export enum OneLinkURLs {
  /** Deep links to mobile app SRP if installed, otherwise redirects to device's app store */
  WEB_TO_APP_OFFERS = 'https://app.vio.com/v0HW',
  /** Deep links to iOS App Store, or opens mobile app home page if installed */
  APP_STORE = 'https://viodotcom-ios.onelink.me/99Nl',
  /** Deep links to Google Play Store, or opens mobile app home page if installed */
  PLAY_STORE = 'https://viodotcom-android.onelink.me/szki',
  /** Smart routing: Opens appropriate app store or mobile app home page based on user's device */
  GENERAL = 'https://viodotcom.onelink.me/Wtf2'
}

export enum Placement {
  // SRP
  AnchorAppLockedDeal = 'app_exclusive_offer',
  NonAnchorAppLockedDeal = 'app_exclusive_offer_non_anchors',
  WebToAppDesktopAnchor = 'web_to_app_desktop',
  WebToAppDesktopNonAnchor = 'web_to_app_desktop_non_anchors',

  // AP/RSP
  AccommodationRoomOffer = 'app_exclusive_offer_rooms',
  WebToAppDesktopAccommodationRoomOffer = 'web_to_app_desktop_rooms',

  // Booking-management
  BookingManagementBanner = 'booking_management_banner',

  // HP
  HomePageBottomSheet = 'homepage_bottomsheet',
  HomePageHeroSection = 'homepage_hero',
  HomePageAppSection = 'homepage_app',
  HomePageSavingsSection = 'homepage_savings',

  // General
  Footer = 'footer',
  MenuItem = 'menuitem'
}

/** @deprecated Use BooleanUrlParam from global types (types/global.d.ts) */
export type BooleanUrlParam = '0' | '1' | 0 | 1

// Either both or none
type ClickedOfferRateParams =
  | {
      clickedOfferRate: number | undefined
      currencyCode: string
    }
  | {
      clickedOfferRate?: never
      currencyCode?: never
    }

export type DeepLinkCustomSearchParams = {
  checkIn?: string
  checkOut?: string
  hotelId?: string
  placeId?: string
  rooms?: string
  userLocationSearch?: BooleanUrlParam
  address?: string
  esd?: string | undefined
  epv?: string | undefined
  vclid?: string
  countryCode?: string
} & ClickedOfferRateParams

export type DeepLinkSearchParams = {
  anonymousId?: string
  searchId?: string
  optimizeAnchorRooms?: BooleanUrlParam
  optimizeRooms?: BooleanUrlParam
  showTotal?: BooleanUrlParam
} & DeepLinkCustomSearchParams

export enum SearchType {
  hotelSearch = 'hotelSearch',
  placeSearch = 'placeSearch',
  userLocationSearch = 'userLocationSearch',
  unknown = 'unknown'
}

type WebsiteParams = Partial<SearchParamsType>

export type AppsFlyerDeepLinkData = {
  af_sub1?: string
  af_sub2?: string
  af_sub3?: string
  af_sub4?: string
  af_sub5?: string
  deep_link_value: Page | string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub1?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub2?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub3?: SearchType
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub4?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub5?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub6?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub7?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub8?: string
  deep_link_sub9?: string
  /** @deprecated in favour of deep_link_sub9 */
  deep_link_sub10?: string
  // TODO: remove DeepLinkSearchParams from here when we stop the support of old deep links
} & DeepLinkSearchParams &
  WebsiteParams
