import dompurify from 'dompurify'

/**
 * Sanitizes HTML and prevents XSS attacks. You can feed `sanitizeHtml` with string full of dirty HTML and it will return a string with clean HTML
 *
 * @param html - HTML string to sanitize
 *
 * @returns A sanitized HTML
 */
export const sanitizeHtml = (html: string) => {
  return dompurify.sanitize(html)
}
