import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {getFacetsSortedAlphabetically} from 'utils/facilities'

import {FacetFilterProps, FacetGroup, FacetGroupTitles} from '..'
import {FacetFilterList} from '../FacetFilterList/FacetFilterList'

interface FacetsRenderGroupProps extends FacetFilterProps {
  groups: FacetGroup
  groupTitles?: FacetGroupTitles
}

export const FacetsRenderGroup: React.FC<FacetsRenderGroupProps> = ({
  searchParamName,
  getLabel,
  onChange,
  columns,
  filters,
  contractedMessage,
  groups,
  groupTitles
}) => {
  const {formatMessage} = useIntl()

  const groupKeys = useMemo(
    () => Object.keys(groups).filter(key => groups[key]?.length > 0),
    [groups]
  )

  return (
    <div>
      {groupKeys?.map(key => (
        <FacetFilterList
          key={key}
          title={groupTitles?.[key] ? formatMessage(groupTitles[key]) : ''}
          facets={getFacetsSortedAlphabetically(
            groups[key],
            getLabel as (T: string) => string
          )}
          filters={filters}
          searchParamName={searchParamName}
          getLabel={getLabel}
          columns={columns}
          contractedMessage={contractedMessage}
          onChange={onChange}
        />
      ))}
    </div>
  )
}
