import {HotelOfferEntity} from '@findhotel/sapi'

import {calculateTotalRate} from '../offer/business/calculateTotalRate'
import {OldHotelOfferEntity} from '../offer/business/offersMapping'
import {sortOffersByPrice} from '../offer/utils/sortOffers'
import {SearchData, SearchState} from '../sapi/services/searchApi'
import {getLengthOfStay} from '../utils/date'

export const getHotelPrices = ({
  offerEntity,
  checkIn,
  checkOut,
  includeTaxes,
  includeLocalTaxes
}: {
  offerEntity: HotelOfferEntity | OldHotelOfferEntity | undefined
  checkIn: string
  checkOut: string
  includeTaxes: boolean
  includeLocalTaxes: boolean
}) => {
  if (!offerEntity || !offerEntity?.offers?.length)
    return {price: undefined, anchorPrice: undefined}

  const {offers} = offerEntity
  const firstOffer = offers[0]

  const sortedOffers = sortOffersByPrice(offerEntity.offers)
  const mostExpensiveOffer = sortedOffers[sortedOffers.length - 1]

  const lengthOfStay = getLengthOfStay(checkIn, checkOut)

  const anchorPrice = mostExpensiveOffer?.rate
    ? calculateTotalRate(
        mostExpensiveOffer?.rate,
        includeTaxes,
        includeLocalTaxes
      ) / lengthOfStay
    : undefined

  const price =
    calculateTotalRate(firstOffer.rate, includeTaxes, includeLocalTaxes) /
    lengthOfStay

  return {price, anchorPrice}
}

export const isAnchorComplete = (data?: SearchData) =>
  data?.status?.anchorComplete

export const isHotelsReceived = (data?: SearchData) =>
  data?.searchState &&
  [SearchState.OffersPending, SearchState.Completed].includes(data.searchState)

export const isComplete = (data?: SearchData) =>
  data?.searchState === SearchState.Completed
