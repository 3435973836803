import {CookieConfig} from 'types/Cookie'

import {cookieSecuritySettings} from '../../utils/cookies/cookieSecuritySettings'

export const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

const firstVisitConfig = {
  name: 'firstVisit',
  options: {
    maxAge: 365 * 2, // 2 years
    ...cookieSecuritySettings(true)
  }
}

export default firstVisitConfig
