import React from 'react'
import {useSelector} from 'react-redux'
import TrackPageView from 'components/TrackPageView'
import {Membership} from 'components/User/Membership'
import {UserLayout} from 'components/User/UserLayout'
import {useMemberReferral} from 'hooks/useMemberReferral'
import {getAnonymousId} from 'modules/meta/selectors'

import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import {useReferralInfo} from '@daedalus/core/src/_web/referral/hooks/useReferralInfo'
import InjectPageDataId from '@daedalus/core/src/analytics/components/InjectPageDataId'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

export const MembershipRoute = () => {
  const anonymousId = useSelector(getAnonymousId)
  const brand = useSelector(getBrand)

  const {referrerAccountUserId, isReferrerSusi} = useReferralInfo()
  const trackingParams = {
    isReferrerSusi,
    referrerAccountUserId
  }

  useMemberReferral()

  return (
    <UserLayout
      headerContent={
        <FormattedMessageWrapper
          id="membership.membershipTitle"
          defaultMessage="Membership"
        />
      }
    >
      <TrackPageView
        pageName={Page.Membership}
        // TODO: Search TS preexisting issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        trackingParams={trackingParams}
      />
      <InjectPageDataId page={Page.Membership} />
      <Membership brand={brand} anonymousId={anonymousId} />
    </UserLayout>
  )
}
