import React from 'react';
import { FormattedNumber } from 'react-intl';
import { cx } from '@linaria/core';
import { Text } from '@daedalus/atlas/Text';
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage';
interface AppLockedDealTotalPriceProps {
  value: number;
  currency: string;
  hasUnderline?: boolean;
  textVariant?: 'bodyXS' | 'labelXS';
}
const textClass = "t942qln";
export const GHAPreferredRateTotalPrice = ({
  value,
  currency,
  hasUnderline = false,
  textVariant = 'bodyXS'
}: AppLockedDealTotalPriceProps) => <Text variant={textVariant} colorPath="content.neutral.c600" as="div" className={cx(textClass, hasUnderline && '__underline')}>
    <FormattedNumber maximumFractionDigits={0} value={value} style="currency" currency={currency} />
    <span>&nbsp;</span>
    <FormattedMessageWrapper id="total" defaultMessage="total" />
  </Text>;

require("./GHAPreferredRateTotalPrice.wyw-in-js.css!=!../../../../../../../node_modules/@wyw-in-js/webpack-loader/lib/outputCssLoader.js?cacheProvider=!./GHAPreferredRateTotalPrice.tsx");