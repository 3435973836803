import {TabId} from 'components/HotelDetailsTabs'

export enum SelectedElement {
  BlankSpace = 'BlankSpace',
  ReviewRating = 'ReviewRating',
  Location = 'Location',
  CheckInOutInfo = 'CheckInOutInfo',
  AmenityTags = 'AmenityTags',
  ShowAllDeals = 'ShowAllDeals',
  Image = 'Image',
  ArrowIcon = 'ArrowIcon',
  AvailableRooms = 'AvailableRooms',
  Logo = 'Logo',
  OfferCTA = 'OfferCTA',
  Price = 'Price',
  PrivateDealCTA = 'PrivateDealCTA',
  RoomLabels = 'RoomLabels',
  RoomName = 'RoomName',
  YourChoiceLabel = 'YourChoiceLabel'
}

export const selectedElementToTabIdMapping = {
  [SelectedElement.Location]: TabId.Location,
  [SelectedElement.ReviewRating]: TabId.Reviews,
  [SelectedElement.CheckInOutInfo]: TabId.Details,
  [SelectedElement.AmenityTags]: TabId.Details,
  [SelectedElement.ShowAllDeals]: TabId.Deals
}
