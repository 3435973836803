import * as React from 'react'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

import {DesktopBaseLayout} from './BaseDesktop'
import {MobileBaseLayout} from './BaseMobile'

type PropsType = {
  hasHeaderSearchSummary?: boolean
  hasHeaderOnMobile?: boolean
  hasHeaderMenuOnMobile?: boolean
  hasHeaderShadowOnMobile?: boolean
  hasHeaderDividerOnMobile?: boolean
  hasHeaderFixedOnDesktop?: boolean
  hasFooterOnMobile?: boolean
  hasSearchProgressBar?: boolean
  hasSearchForm?: boolean
  displayFixedFullScreen?: boolean
  headerRightContent?: React.ReactElement
  hasMobileHeaderBackButton?: boolean
  mobileHeaderTitle?: React.ReactElement
  hasFilters?: boolean
  customSearchBox?: React.ReactElement
}

const Layout: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  hasHeaderSearchSummary = false,
  hasHeaderOnMobile = true,
  hasHeaderMenuOnMobile = true,
  hasHeaderShadowOnMobile = false,
  hasHeaderDividerOnMobile = false,
  hasHeaderFixedOnDesktop = true,
  hasSearchProgressBar = false,
  hasFooterOnMobile = true,
  hasSearchForm = false,
  headerRightContent,
  hasMobileHeaderBackButton,
  mobileHeaderTitle,
  hasFilters = false,
  customSearchBox
}) => {
  const {isMobile} = useDeviceLayout()

  if (isMobile) {
    return (
      <MobileBaseLayout
        hasHeaderSearchSummary={hasHeaderSearchSummary}
        hasSearchForm={hasSearchForm}
        hasHeader={hasHeaderOnMobile}
        hasHeaderMenu={hasHeaderMenuOnMobile}
        hasHeaderShadow={hasHeaderShadowOnMobile}
        hasHeaderDivider={hasHeaderDividerOnMobile}
        hasSearchProgressBar={hasSearchProgressBar}
        hasFooter={hasFooterOnMobile}
        headerRightContent={headerRightContent}
        hasBackButton={hasMobileHeaderBackButton}
        headerTitle={mobileHeaderTitle}
      >
        {children}
      </MobileBaseLayout>
    )
  }

  return (
    <DesktopBaseLayout
      hasSearchForm={hasSearchForm}
      hasFilters={hasFilters}
      hasFixedHeader={hasHeaderFixedOnDesktop}
      hasSearchProgressBar={hasSearchProgressBar}
      customSearchBox={customSearchBox}
    >
      {children}
    </DesktopBaseLayout>
  )
}

export default Layout
