export class RoomOfferUrlError extends Error {
  originalMessage: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, originalError: Error | any) {
    super(`${message} - ${originalError?.message || originalError}`)
    this.name = 'RoomOfferUrlError'
    this.stack = originalError.stack
    this.originalMessage = originalError?.message || originalError
  }
}
