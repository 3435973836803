import subHours from 'date-fns/subHours'

import {CancellationPenalties} from '@findhotel/sapi'

import {getPersistedConfiguration} from '../services/offerConfigurationApi/store'
import {CancellationPenalty, GenericOffer} from '../types/offer'
import {getCancellationDeadline} from './cancellationPenalties'

/**
 * Checks if an offer is marked with a Charge Later tag
 * @param offer - The offer object.
 * @returns True if the offer has the Charge Later tag, false otherwise.
 */
export const hasChargeLaterTag = (offer: GenericOffer): boolean => {
  return Boolean(offer?.tags?.includes('charge_later'))
}

/**
 * Calculates the charge date based on the cancellation penalties.
 *
 * @param cancellationPenalties - Array of CancellationPenalty or CancellationPenalties objects.
 * @returns The calculated charge date as a Date object.
 */
export const calculateChargeDate = (
  cancellationPenalties: CancellationPenalty[] | CancellationPenalties[]
) => {
  const configuration = getPersistedConfiguration()
  const chargeLaterConfiguration = configuration.chargeLater
  const {autoChargeLeadHours} = chargeLaterConfiguration || {}

  if (!autoChargeLeadHours) return undefined

  const cancellationDeadline = getCancellationDeadline(cancellationPenalties)
  const cancellationDeadlineDate =
    cancellationDeadline && new Date(cancellationDeadline)
  return cancellationDeadlineDate
    ? subHours(cancellationDeadlineDate, autoChargeLeadHours)
    : undefined
}
