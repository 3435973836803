import React, {useContext} from 'react'

/**
 * This context tells `useHandleOfferClick` where an offer is rendered so that on-click behaviour can be adjusted as needed (eg. close all offers to reach AP rooms)
 */
export enum OfferViewType {
  SRP = 'srp',
  SRP_BEST_DEAL_MODAL = 'srp-best-deal-modal',
  AP_DESKTOP = 'ap-desktop',
  AP_OVERLAY = 'ap-overlay',
  SPLIT_FLOW_OVERLAY = 'split-flow-overlay',
  AP_DETAILS_OVERLAY = 'ap-details-overlay',
  AP_FOOTER = 'ap-footer'
}

export const apOfferMountTypes = [
  OfferViewType.AP_DETAILS_OVERLAY,
  OfferViewType.AP_OVERLAY,
  OfferViewType.AP_FOOTER,
  OfferViewType.AP_DESKTOP
]

export const OfferViewContext = React.createContext<OfferViewType>(
  OfferViewType.SRP
)

/**
 * Get OfferViewContext by OfferViewContext
 * Note this is optional, when the provider doesn't exist, it will simple be empty
 */
export const useOfferViewContext = () => {
  const value = useContext<OfferViewType>(OfferViewContext)
  return value
}
