import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type ProgressBarStateType = {
  searchProgressPercentage: number
}

export const initialState: ProgressBarStateType = {
  searchProgressPercentage: 0
}

const {actions, reducer} = createSlice({
  name: 'progressBar',
  initialState,
  reducers: {
    setSearchProgressPercentage(
      state: ProgressBarStateType,
      {payload}: PayloadAction<number>
    ) {
      state.searchProgressPercentage = payload
    },
    // The value of the progress bar should be set only if it is greater than the current value
    // to avoid the progress bar from going backwards
    setSearchProgressPercentageLinear(
      state: ProgressBarStateType,
      {payload}: PayloadAction<number>
    ) {
      if (state.searchProgressPercentage < payload)
        state.searchProgressPercentage = payload
    },
    // The value of the progress bar should be set incrementally until it reaches the desired value
    setSearchProgressPercentageIncrementally(
      state: ProgressBarStateType,
      {payload}: PayloadAction<number>
    ) {
      if (state.searchProgressPercentage < payload) {
        const diff = payload - state.searchProgressPercentage
        // the bigger the difference, the bigger the increment. the increment will never be bigger than the difference
        const increment = diff * 0.3

        state.searchProgressPercentage += increment
      }
    }
  }
})

export const {
  setSearchProgressPercentage,
  setSearchProgressPercentageLinear,
  setSearchProgressPercentageIncrementally
} = actions

export default reducer
