import {isBefore} from 'date-fns'
import {assocPath, pipe} from 'ramda'

import {
  dateStringToMiddayDate,
  dateToMiddayDate,
  today,
  tomorrow
} from '../utils/date'
import {UserSearchHistoryItem} from './types'

type Value = number | string | boolean | Date

const isExpired = (date: string) =>
  isBefore(dateStringToMiddayDate(date), dateToMiddayDate(new Date()))

const updateSearchParam = (
  param: string,
  value: Value,
  obj: UserSearchHistoryItem
) => assocPath(['searchParams', param], value, obj)

const updateCheckIn = (searchHistoryItem: UserSearchHistoryItem) =>
  updateSearchParam('checkIn', today(), searchHistoryItem)

const updateCheckOut = (searchHistoryItem: UserSearchHistoryItem) =>
  updateSearchParam('checkOut', tomorrow(), searchHistoryItem)

export const updateExpiredDates = (searchHistory: UserSearchHistoryItem[]) =>
  searchHistory.map(searchHistoryItem =>
    isExpired(searchHistoryItem.searchParams.checkIn)
      ? pipe(updateCheckIn, updateCheckOut)(searchHistoryItem)
      : searchHistoryItem
  )
