import React, {memo, useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Histogram} from 'components/Histogram'
import {getCurrencyCode, getIsRtlLanguage} from 'modules/meta/selectors'
import {getFacets} from 'modules/sapiSearch/selectors'
import {PRICE_BUCKETS_COUNT} from 'modules/search/constants'
import {getPriceBucketWidth} from 'modules/search/selectors'
import {FilterUrlParams} from 'modules/search/types'
import {prop} from 'ramda'
import {Handle, HandleProps, Range} from 'rc-slider'
import Settings from 'Settings'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {getEnabledCurrencies} from '@daedalus/core/src/localization/business/currency'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {PricingBreakdown} from '@findhotel/sapi'

import {PriceInput} from './PriceInput/PriceInput'
import {
  generateContinuousPriceBuckets,
  getFirstBucketId
} from './PriceInput/utils'
import {
  InputsSeparator,
  InputsWrapper,
  InputWrapper,
  RangeSliderStylesProvider,
  RangeSliderWrapper
} from './styles'

interface Props {
  onChange: (filters: Pick<FilterUrlParams, 'priceMin' | 'priceMax'>) => void
  priceMax: number | null | undefined
  priceMin: number | null | undefined
}

const MIN_MAX_SLIDER_DISTANCE = 2

const PRICE_BUCKET_NAME: PricingBreakdown = 'medianRateBkt'
const PRICE_BUCKET_FACET = `pricing.${PRICE_BUCKET_NAME}`

const PriceFilterComponent = (props: Props) => {
  const {priceMin, priceMax, onChange} = props

  const facets = useSelector(getFacets) || {}

  const priceBucketWidth = useSelector(getPriceBucketWidth)

  const currencyCode = useSelector(getCurrencyCode)
  const enabledCurrencies = getEnabledCurrencies(
    Settings.get('ENABLED_CURRENCIES')
  )
  const currency = enabledCurrencies.find(
    currency => currency.code === currencyCode
  )
  const currencySymbol = prop('symbol', currency)
  const currencyExchangeRate = Settings.get('CURRENCY_EXCHANGE_RATE', 1)

  const [[minFilter, maxFilter], setFilterValue] = useState<
    Array<number | null | undefined>
  >([priceMin, priceMax])

  const priceBuckets = facets[PRICE_BUCKET_FACET] ?? []

  const continuousPriceBuckets = generateContinuousPriceBuckets(
    priceBuckets,
    PRICE_BUCKETS_COUNT
  )

  const firstBucketId = getFirstBucketId(continuousPriceBuckets)

  const priceBucketWidthInCurrency = Math.round(
    priceBucketWidth * currencyExchangeRate
  )
  const lowerBound = Math.round(firstBucketId * priceBucketWidthInCurrency)
  const upperBound = Math.round(
    lowerBound +
      priceBucketWidthInCurrency * (PRICE_BUCKETS_COUNT - firstBucketId)
  )

  // When opening the overlay, the first render has no values
  useEffect(() => {
    setFilterValue([priceMin, priceMax])
  }, [priceMin, priceMax])

  const isRtlLanguage = useSelector(getIsRtlLanguage)

  const handleSubmit = useCallback(() => {
    onChange({
      priceMin: minFilter,
      priceMax: maxFilter
    })
  }, [maxFilter, minFilter, onChange])

  const handleRangeChange = useCallback((value: number[]) => {
    const [min, max] = value
    setFilterValue([min, max])
  }, [])

  const handleInputChange = useCallback(
    (name: string, value: number) => {
      const rangeValue =
        name === 'priceMin'
          ? [Number(value), priceMax || upperBound]
          : [priceMin || lowerBound, Number(value)]

      setFilterValue(rangeValue)

      const [priceMinValue, priceMaxValue] = rangeValue

      onChange({
        priceMin: priceMinValue,
        priceMax: priceMaxValue
      })
    },
    [onChange, priceMax, priceMin, lowerBound, upperBound]
  )

  if (facets) {
    const priceMinValue = Number(minFilter || lowerBound)
    const priceMaxValue = Number(maxFilter || upperBound)

    const priceMinLowerBound = lowerBound
    const priceMaxUpperBound = upperBound

    const priceRangeValue = [priceMinValue, priceMaxValue]
    const priceMinUpperBound = priceMaxValue - priceBucketWidthInCurrency
    const priceMaxLowerBound = priceMinValue + priceBucketWidthInCurrency
    // We should not show the last bucket on the histogram
    const histogramValues = continuousPriceBuckets.slice(0, -1)
    const histogramValue = [
      priceMinValue,
      priceMaxValue - priceBucketWidthInCurrency
    ]

    return (
      <>
        <Histogram
          values={histogramValues}
          value={histogramValue}
          step={priceBucketWidthInCurrency}
          height="38px"
        />
        <RangeSliderWrapper data-body-scroll-lock-ignore>
          <RangeSliderStylesProvider>
            <Range
              min={lowerBound}
              max={upperBound}
              value={priceRangeValue}
              pushable={priceBucketWidth * MIN_MAX_SLIDER_DISTANCE}
              allowCross={false}
              step={priceBucketWidthInCurrency}
              reverse={isRtlLanguage}
              handle={(handleProps: HandleProps) => (
                <Handle {...handleProps}>
                  <Icon name="Drag" size="xs" />
                </Handle>
              )}
              onChange={handleRangeChange}
              onAfterChange={handleSubmit}
            />
          </RangeSliderStylesProvider>
        </RangeSliderWrapper>
        <InputsWrapper justifyContent="space-between">
          <InputWrapper gap="s200">
            <Text variant="bodyS">
              <FormattedMessageWrapper id="min" defaultMessage="Min" />
            </Text>

            <PriceInput
              name="priceMin"
              value={priceMinValue}
              currencySymbol={currencySymbol}
              lowerBound={priceMinLowerBound}
              upperBound={priceMinUpperBound}
              onChange={handleInputChange}
            />
          </InputWrapper>
          <InputsSeparator alignItems="flex-end">-</InputsSeparator>
          <InputWrapper gap="s200">
            <Text variant="bodyS">
              <FormattedMessageWrapper id="max" defaultMessage="Max" />
            </Text>

            <PriceInput
              name="priceMax"
              value={priceMaxValue}
              currencySymbol={currencySymbol}
              lowerBound={priceMaxLowerBound}
              upperBound={priceMaxUpperBound}
              onChange={handleInputChange}
            />
          </InputWrapper>
        </InputsWrapper>
      </>
    )
  }

  return (
    <FormattedMessageWrapper
      id="noFiltersAvailable"
      defaultMessage="No filters available for your search"
    />
  )
}

export const PriceFilter = memo(PriceFilterComponent)
