import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Anchor, SearchParameters} from '@daedalus/core/src/sapi/types'

import {getHotel} from '../../sapiSearch/selectors'
import saveUserSearchHistory from '../actions/saveUserSearchHistory'

interface SetSearchHistory {
  searchParams: SearchParameters
  searchData: Anchor
  searchId: string
}

type UseSaveUserSearchHistory = (searchHistory: SetSearchHistory) => void

export const useSaveUserSearchHistory = (): UseSaveUserSearchHistory => {
  const [searchHistory, setSearchHistory] = useState<Partial<SetSearchHistory>>(
    {}
  )
  const {searchParams, searchData, searchId} = searchHistory || {}
  const dispatch = useDispatch()
  const {hotelId} = searchParams || {}
  const hotel = useSelector(state => getHotel(state, hotelId))
  const imageURI = hotel?.imageURIs?.[0]

  useEffect(() => {
    const shouldSaveSearchHistory = searchData && (!hotelId || imageURI)
    if (shouldSaveSearchHistory) {
      dispatch(
        saveUserSearchHistory({
          searchParams,
          searchData: {
            ...searchData,
            hotelImageUrl: imageURI
          },
          searchId
        })
      )
    }
  }, [dispatch, searchParams, searchData, imageURI])

  return setSearchHistory
}
