import {parse} from 'qs'
import {isNil, path} from 'ramda'
import Settings from 'Settings'
import {RootState} from 'store'
import {HotelType} from 'types/Hotel'
import {getCheapestTopOfferNightlyRate} from 'utils/offers'

import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '@daedalus/core/src/_web/utils/persistence'

import {initialState as defaultState, SearchStateType} from './slice'

export const calculatePredictedVsCheapestRateDelta = (
  hit: HotelType
): number | null | undefined => {
  const cheapestRate = getCheapestTopOfferNightlyRate(hit)
  const predictedRate = path(['pricing', 'minRate'], hit) as number
  const exchangeRate = Settings.get('CURRENCY_EXCHANGE_RATE') || 1

  if (!cheapestRate || !predictedRate) return null

  return (cheapestRate - predictedRate * exchangeRate) / cheapestRate
}

export const calculateGreatDealBoundary = (
  hits: HotelType[]
): number | null | undefined => {
  const AUGMENTED_HOTELS_PERCENTAGE = 20

  const deltas = hits
    .map(calculatePredictedVsCheapestRateDelta)
    .filter(i => Boolean(i))
    .sort()

  return deltas
    .slice(0, Math.ceil((deltas.length * AUGMENTED_HOTELS_PERCENTAGE) / 100))
    .pop()
}

/**
 * Augments the initial search state with landing (url) params
 * and merges them on top of the default state from the reducer.
 * Additional state can also be passed in.
 */
export const augmentSearchState = (
  initialState?: Partial<RootState>
): SearchStateType => {
  const queryString: string = window?.location?.search || ''
  const landingQueryString = isNil(initialState?.search?.landingQueryString)
    ? queryString
    : initialState?.search?.landingQueryString

  const {showTotal} = parse(landingQueryString, {
    ignoreQueryPrefix: true
  })

  const {referrer} = document
  const {search} = initialState || {}

  // Allow changing price settings with URL parameters
  const showTotalPrices = showTotal
    ? showTotal === '1'
    : safelyGetStorageItem(localStorage, 'showTotalPrices') === 'true'

  if (showTotal) {
    safelySetStorageItem(
      localStorage,
      'showTotalPrices',
      showTotalPrices.toString()
    )
  }

  return {
    ...defaultState,
    ...search,
    showTotalPrices,
    isLandingExperience: true,
    isHomePageLandingExperience: landingQueryString === '' ? true : false,
    landingQueryString,
    landingReferrer: referrer
  }
}
