import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  ErrorContext,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {ErrorDetails} from '@daedalus/core/src/booking/types/ErrorDetails'

import {
  getAnalyticsContext,
  getComponent,
  getFreezeSelectedContext,
  getOfferEligibility
} from '../../modules/selector'
import {getErrorPayload} from './getErrorPayload'
import {DealFreezeError, ErrorCases} from './types'

export interface TrackErrorProps {
  error: DealFreezeError
  errorCase?: ErrorCases
}

export const useTrackApiError = ({error, errorCase}: TrackErrorProps) => {
  const {
    message,
    errorDetails,
    endpoint,
    component: errorSource,
    payloadErrorType
  } = error

  const dispatch = useDispatch()
  const {sapiMatchedOffer} = useSelector(getFreezeSelectedContext)
  const {response: offerCheckResponse} = useSelector(getOfferEligibility)

  const {leadContext, offerContext, priceContext} =
    useSelector(getAnalyticsContext)
  const payload = getErrorPayload({
    sapiOffer: sapiMatchedOffer,
    offerCheckResponse,
    error
  })
  const component = useSelector(getComponent)

  useEffect(() => {
    if (!errorCase) return

    const availableAnalyticsContexts = {
      ...(offerContext && {[AnalyticsContext.OfferContext]: offerContext}),
      ...(priceContext && {[AnalyticsContext.PriceContext]: priceContext}),
      ...(leadContext && {[AnalyticsContext.LeadContext]: leadContext})
    }

    const errorContext: ErrorContext = {
      errorType: errorCase,
      errorMessage: message,
      errorCode: payloadErrorType,
      errorDetails: errorDetails as ErrorDetails,
      endpoint
    }

    dispatch(
      trackEvent({
        category: Category.System,
        action: Action.Errored,
        entity: Entity.DealFreeze,
        ...(component && {component}),
        analyticsContext: {
          ...availableAnalyticsContexts,
          [AnalyticsContext.ErrorContext]: errorContext
        },
        payload: {error_source: errorSource, ...payload},
        team: Team.Select
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCase])
}
