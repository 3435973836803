import React, {useCallback, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {getShowFreeCancellationFilter} from 'modules/search/selectors'

import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const filterKeys = ['freeCancellation']

interface Props {
  isActive?: boolean
}

export const FreeCancellationPill: React.FC<Props> = ({isActive = false}) => {
  const {formatMessage} = useIntl()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const showFreeCancellationFilter = useSelector(getShowFreeCancellationFilter)

  const {submitFilters, trackFilterClick} = useFiltersForm(filterKeys)

  const handlePillClick = useCallback(() => {
    buttonRef.current?.blur()

    const updatedFilters = {
      freeCancellation: (isActive ? undefined : '1') as BooleanUrlParam
    }

    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })
    submitFilters(updatedFilters)
  }, [isActive, trackFilterClick, submitFilters])

  if (!showFreeCancellationFilter) return null

  return (
    <Pill
      ref={buttonRef}
      isSelected={isActive}
      dataId="freeCancellationFilter"
      onClick={handlePillClick}
      iconStart={<Icon name="CalendarCheck4" />}
      iconEnd={isActive && <Icon name={'Close'} />}
    >
      {formatMessage(filterMessages.freeCancellationTitle)}
    </Pill>
  )
}
