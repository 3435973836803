import {SELECTOR_MAX_CACHE_ENTRIES} from 'config/selectorCaching'
import {
  getAccommodationPageHotelId,
  getAccommodationParamsChanged,
  getCurrentHotelOfferEntity,
  getRoomsActiveFilters
} from 'modules/accommodation/selectors'
import {getHotelTags} from 'modules/analytics/selectors'
import {
  getAnalyticsPageName,
  getAnonymousId,
  getCurrencyCode,
  getDeviceCategory,
  getDeviceLayout,
  getFirstVisit,
  getIsReturningUser,
  getLocaleCode,
  getUserAgentDetails,
  getUserCountryCode,
  getUserCountryRegion,
  getViewPortHeight,
  getViewPortWidth,
  getVisitsCount
} from 'modules/meta/selectors'
import {getLocalTaxesIncluded} from 'modules/meta/selectors'
import {getTaxesIncluded} from 'modules/meta/selectors'
import {
  getHotelDetailsVisibility,
  getLocationVisibility
} from 'modules/overlay/selectors'
import {
  getAbsoluteHotelPosition,
  getAnchor,
  getAnchorHotelHasPrivateDeal,
  getAnchorHotelHasYourChoice,
  getAppLockedDealConfig,
  getCurrentPageLength,
  getCurrentPageNumber,
  getCurrentPageOffset,
  getDistanceFromTarget,
  getHighestVisiblePrice,
  getHotel,
  getHotelIds,
  getHotelIdsRaw,
  getHotelIdsToDisplay,
  getHotelIdsToDisplayIncludingAnchor,
  getHotelIndicesWithPrivateDeal,
  getHotelOfferEntity,
  getHotelRegularPriceRange,
  getHotelsHaveStaticPosition,
  getNumberOfRooms,
  getOffer,
  getOfferEntity,
  getRequestedOffset,
  getRequestedResultsCount,
  getSearchId,
  getSearchParameters as getSapiSearchParameters,
  getSearchType,
  getShowTotalPrices,
  getSplitBookingDetails,
  getTotalCountOfResults,
  getUnavailableHotelsPositions,
  State
} from 'modules/sapiSearch/selectors'
import {Hotel, SearchOffer} from 'modules/sapiSearch/slice'
import {
  getAnchorPrice,
  getSearchLocationFromSapiObject,
  getTopOffersWithSplitBookings
} from 'modules/sapiSearch/utils'
import {
  geIsNoDatesSearch,
  getClickId,
  getFiltersFromUrlParams,
  getFreeTextSearchString, // Added as part of 52078012-speech-to-search
  getIsDefaultDatesSearch,
  getIsFsmr,
  getIsHomeSearch,
  getIsMapOpen,
  getIsOptimizeRooms,
  getIsOTAMode,
  getIsUserLocationSearch,
  getIsUserSearch,
  getLandingQueryString,
  getLayout,
  getListOfValues,
  getMlos,
  getUrlParams,
  getUserLocationSearchType
} from 'modules/search/selectors'
import {
  getFilteredRoomsData,
  getSearchContextOffersOnlySearchOverride
} from 'modules/searchApi/selectors'
import {
  getSearchBoxDestinationDisplayValue,
  getSearchBoxSearchTrigger
} from 'modules/searchBox/selectors'
import {SearchTrigger} from 'modules/searchBox/types'
import {add, isEmpty, isNil, map, mapObjIndexed, reject} from 'ramda'
import {createSelectorCreator, defaultMemoize} from 'reselect'
import {RootState} from 'store'
import {isDateStringYesterday} from 'utils/dates'
import {getRankedAvailableFacilities} from 'utils/facilities'
import {getIsAccommodationPage} from 'utils/getIsAccommodationPage'
import {getLanguageTrackingInfo} from 'utils/localization/language'
import {
  getCheapestTopOfferByNightlyRate,
  getHotelIdFromOfferBookURIPath,
  getIsDirectSupplyFromOfferUrl,
  getParamFromOfferBookURI
} from 'utils/offers'
import url from 'utils/url'

import {getPersistedSapiLabel} from '@daedalus/core/src/_web/sapiLabel/business'
import {getPreferredColorScheme} from '@daedalus/core/src/_web/utils/browser/getBrowserDarkMode'
import {
  ProviderRedirectParams,
  TrackProviderRedirectParams
} from '@daedalus/core/src/analytics/modules/actions'
import {
  AnalyticsContext,
  AnalyticsContextPayload,
  HotelContext,
  HotelInfoContext,
  HotelMetaData,
  MarketingContext,
  Page,
  PaginationContext,
  ParametrizedHotelContext,
  ParametrizedHotelInfoContext,
  SearchContext,
  SearchOfferContext,
  SearchResultsContext,
  TrackEventPayload,
  TrackEventProperties,
  UserParametersContext
} from '@daedalus/core/src/analytics/types/Events'
import {increasePositionIndexByOne} from '@daedalus/core/src/analytics/utils/trackEventHelpers'
import {getPersistedVclidValue} from '@daedalus/core/src/analytics/utils/vclidCookie'
import {
  getIsAuthenticated,
  getIsPrivateDeal,
  getShouldSeeOffersUnlocked
} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'
import {getLocalDeviceDateTime} from '@daedalus/core/src/utils/date'
import {convertMilesToKm} from '@daedalus/core/src/utils/distanceFrom'
import {getIsPhoneNumberVerified} from '@daedalus/shared/src/authentication/OneTimePassword/slice/selectors'

import {buildOfferContext} from './contexts/offerContext'
import {buildRoomContext, buildRoomOfferContext} from './contexts/roomContext'
import {getFullStorySessionUrl} from './utils/getFullStorySessionUrl'

import type {Room} from '@daedalus/core/src/room/types/room'

/**
 * Creates a selector with multiple cache entries to let selectors with parameters (ie. hotelId or offerId) get cached properly
 */
const createSelector = createSelectorCreator(defaultMemoize, {
  maxSize: SELECTOR_MAX_CACHE_ENTRIES
})

/**
 * Shortcut to access the state
 */
const getStateParam = (state: State) => state

/**
 * Shortcut to access the hotelId
 */
const getHotelIdParam = (_, hotelId: Hotel['objectID']) => hotelId

/**
 * Returns the track event payload along with default event properties
 * @param state - redux state object
 * @param trackEventPayload - payload to augment the default event properties
 */
export const getEventProperties = (
  state: RootState,
  trackEventPayload: TrackEventPayload
): TrackEventProperties => {
  // Check if a context is a parametrized, and fetch it from the state
  const expandParametrizedContext = (
    analyticsContext: AnalyticsContextPayload
  ) => {
    // Check if the context is parameterized
    if (Boolean(analyticsContext) && 'contextType' in analyticsContext) {
      switch (analyticsContext.contextType) {
        case AnalyticsContext.HotelContext:
          return getHotelContext(state, analyticsContext.hotelId)
        case AnalyticsContext.HotelInfoContext:
          return getHotelInfoContext(state, analyticsContext.hotelId)
      }
    }

    return analyticsContext
  }

  const fullStorySessionUrl = getFullStorySessionUrl()

  const searchContext = trackEventPayload?.analyticsContext?.[
    AnalyticsContext.SearchContext
  ]
    ? trackEventPayload.analyticsContext[AnalyticsContext.SearchContext]
    : {
        ...getSearchContext(state),
        ...getSearchContextOffersOnlySearchOverride(state)
      }

  const marketingContext =
    trackEventPayload?.analyticsContext?.[AnalyticsContext.MarketingContext] ||
    getMarketingContext(state)

  const userParametersContext = {
    ...(trackEventPayload?.analyticsContext?.[
      AnalyticsContext.UserParametersContext
    ] || getUserParametersContext(state)),
    fullStorySessionUrl
  }

  const eventProps = {
    ...trackEventPayload,
    name: `${trackEventPayload.category}_${trackEventPayload.entity}_${trackEventPayload.action}`,
    page: trackEventPayload.page ?? getAnalyticsPageName(state),
    analyticsContext: {
      [AnalyticsContext.MarketingContext]: marketingContext,
      [AnalyticsContext.SearchContext]: searchContext,
      [AnalyticsContext.UserParametersContext]: userParametersContext,
      ...getAccommodationContext(state),
      ...mapObjIndexed(
        expandParametrizedContext,
        trackEventPayload.analyticsContext
      )
    }
  }

  return eventProps
}

/**
 * Returns the marketing context
 * @param state - redux state object
 */
export const getMarketingContext = createSelector(
  [getLandingQueryString],
  (landingQueryString): MarketingContext => {
    const {
      label,
      utm_campaign: utmCampaign,
      utm_medium: utmMedium,
      utm_source: utmSource
    } = url.parse(landingQueryString) as {
      label: string
      utm_campaign: string
      utm_medium: string
      utm_source: string
    }

    const context = {label, utmCampaign, utmMedium, utmSource}

    if (isEmpty(reject(isNil, context))) return undefined

    return context
  }
)

/**
 * Returns the user parameter context
 * @param state - redux state object
 */
export const getUserParametersContext = createSelector(
  [
    getDeviceCategory,
    getUserCountryCode,
    getUserCountryRegion,
    getViewPortHeight,
    getViewPortWidth,
    getAnonymousId,
    getLocalDeviceDateTime,
    getPreferredColorScheme,
    getIsReturningUser,
    getFirstVisit,
    isReactNativeWebView,
    getLocaleCode,
    getCurrencyCode,
    getVisitsCount,
    getIsAuthenticated,
    getIsPhoneNumberVerified
  ],
  (
    deviceType,
    userCountry,
    userCountryRegion,
    viewPortHeight,
    viewPortWidth,
    anonymousId,
    deviceTime,
    preferredColorScheme,
    isReturningUser,
    firstVisit,
    isReactNativeWebView,
    locale,
    currency,
    visitsCount,
    isAuthenticated,
    isSmsProvided
  ): UserParametersContext => ({
    deviceType,
    userCountry,
    userCountryRegion,
    viewPortHeight,
    viewPortWidth,
    anonymousId,
    deviceTime,
    preferredColorScheme,
    isReturningUser,
    firstVisit,
    isReactNativeWebView,
    locale,
    currency,
    visitDayCount: visitsCount,
    isUserLoggedIn: isAuthenticated,
    isSmsProvided,
    ...getLanguageTrackingInfo()
  })
)

/**
 * Returns the search type augmented to include user location type of search
 * @param state - redux state object
 */
const getAnalyticsSearchType = createSelector(
  [getSearchType, getIsUserLocationSearch, getUserLocationSearchType],
  (searchParamsSearchType, isUserLocationSearch, userLocationSearchType) => {
    if (isUserLocationSearch) {
      return userLocationSearchType
    }

    return searchParamsSearchType
  }
)

/**
 * Returns the search context
 * @param state - redux state object
 * @param searchIdParam - optional param to be able to pass in the searchId directly for when it is not available in the store yet
 */
export const getSearchContext = createSelector(
  [
    (_, searchIdParam?: string) => searchIdParam,
    getUrlParams,
    getFiltersFromUrlParams,
    getSapiSearchParameters,
    getIsDefaultDatesSearch,
    geIsNoDatesSearch,
    getCurrencyCode,
    getLocaleCode,
    getSearchId,
    getIsUserSearch,
    getIsHomeSearch,
    getIsFsmr,
    getIsOptimizeRooms,
    getIsMapOpen,
    getHotelDetailsVisibility,
    getAccommodationParamsChanged,
    getLayout,
    getShowTotalPrices,
    getAnalyticsSearchType,
    getSearchBoxSearchTrigger,
    getTaxesIncluded,
    getLocalTaxesIncluded,
    getAnchor,
    getMlos,
    getLandingQueryString,
    getAnalyticsPageName,
    getHotelsHaveStaticPosition,
    getFreeTextSearchString, // Added as part of 52078012-speech-to-search
    getSearchBoxDestinationDisplayValue
  ],
  (
    searchIdParam,
    urlParams,
    filtersFromUrlParams,
    sapiSearchParameters,
    isDefaultDates,
    isNoDates,
    currency,
    locale,
    searchIdFromState,
    isUserSearch,
    isHomeSearch,
    isFsmr,
    isOptimizeRooms,
    isMapOpen,
    isHotelDetailsOpen,
    isOffersOnlyFetch,
    layout,
    showTotalPrices,
    searchType,
    searchTrigger,
    includeTaxes,
    includeLocalTaxes,
    sapiSearchAnchor,
    mlos,
    landingQueryString,
    analyticsPageName,
    hotelsHaveStaticPosition,
    freeTextSearchString, // Added as part of 52078012-speech-to-search
    searchBoxDestinationDisplayValue
  ): SearchContext | undefined => {
    const searchId = searchIdParam ?? searchIdFromState
    const searchParameters = sapiSearchParameters ?? urlParams
    const filtersFromRequestUrlParametersOrUndefined = isEmpty(
      filtersFromUrlParams
    )
      ? undefined
      : filtersFromUrlParams
    const filters =
      sapiSearchParameters?.filters ??
      filtersFromRequestUrlParametersOrUndefined

    const searchWillRetriggerIfNoOffers = isDateStringYesterday(
      searchParameters.checkIn
    )

    const {numberOfAdults, numberOfChildren} = roomsToConfigurationObject(
      searchParameters.rooms
    )

    const searchLocation = getSearchLocationFromSapiObject(sapiSearchAnchor)

    const {checkIn: metaCheckIn, checkOut: metaCheckOut} = url.parse(
      landingQueryString
    ) as {
      checkIn: string
      checkOut: string
    }

    if (isEmpty(searchParameters)) return undefined

    return {
      checkIn: searchParameters.checkIn,
      checkOut: searchParameters.checkOut,
      isDefaultDates: isDefaultDates && !isNoDates,
      isNoDates,
      currency,
      locale,
      rooms: searchParameters.rooms,
      numberOfAdults,
      numberOfChildren,
      anchorHotelId: searchParameters.hotelId,
      placeId: searchParameters.placeId,
      searchId,
      isUserSearch,
      isHomeSearch,
      isFsmr,
      isOptimizeRooms,
      isMapOpen,
      layout,
      isHotelDetailsOpen:
        analyticsPageName === Page.Accommodation ? true : isHotelDetailsOpen,
      sortField: searchParameters.sortField,
      sortOrder: searchParameters.sortOrder,
      filters,
      cugDeals: searchParameters['cugDeals'],
      mapAreaSearchCoordinates: searchParameters.boundingBox,
      searchWillRetriggerIfNoOffers,
      isOffersOnlyFetch,
      priceDisplay: showTotalPrices ? 'total_price' : 'nightly_price',
      searchType,
      searchTrigger,
      includeTaxes,
      includeLocalTaxes,
      searchLocation,
      mlos,
      metaCheckIn: mlos ? metaCheckIn : undefined,
      metaCheckOut: mlos ? metaCheckOut : undefined,
      address: searchParameters.address,
      hotelsHaveStaticPosition,
      freeTextSearchString, // Added as part of 52078012-speech-to-search
      searchBoxDestinationDisplayValue
    }
  }
)

export const getParametrizedHotelContext = (
  hotelId: Hotel['objectID']
): ParametrizedHotelContext => ({
  contextType: AnalyticsContext.HotelContext,
  hotelId
})

export const getParametrizedHotelInfoContext = (
  hotelId: Hotel['objectID']
): ParametrizedHotelInfoContext => ({
  contextType: AnalyticsContext.HotelInfoContext,
  hotelId
})

const getSplitBookingHotelContext = createSelector(
  [getSplitBookingDetails, getOfferEntity],
  (splitBookingDetails, offerEntity) => {
    const {
      splitBookingPosition,
      splitBookingBundle,
      hasSplitBookingOffer,
      offerPositionToRemove,
      splitBookingType
    } = splitBookingDetails

    const splitBooking = offerEntity?.splitBooking
    const isHiddenSplitBooking = !hasSplitBookingOffer && Boolean(splitBooking)

    if (!splitBooking) return {}
    return {
      splitBooking: {
        splitBookingType,
        splitBookingPosition: hasSplitBookingOffer
          ? splitBookingPosition
          : null,
        bundleId: splitBookingBundle?.bundleId,
        isHidden: isHiddenSplitBooking,
        offerPositionToRemove,
        offers:
          splitBooking?.offers?.map(sbOffer => ({
            checkIn: sbOffer.checkIn,
            checkOut: sbOffer.checkOut,
            offer: sbOffer.offer
          })) ?? [],
        tags: splitBooking?.tags,
        totalRate: {
          baseRate: splitBooking?.totalRate?.base,
          localTaxes: splitBooking?.totalRate?.hotelFees,
          taxes: splitBooking?.totalRate?.taxes
        },
        url: splitBooking?.url
      }
    }
  }
)

/**
 * Returns the hotel context
 * @param state - redux state object
 * @param hotelId - id of the hotel you require the hotel context for
 */
export const getHotelContext = createSelector(
  [
    getHotelIdParam,
    getAbsoluteHotelPosition,
    getHotelRegularPriceRange,
    getHighestVisiblePrice,
    getHotelTags,
    getHotelOfferEntity,
    getLocationVisibility,
    getHotel,
    getDistanceFromTarget,
    getSplitBookingHotelContext,
    getOfferEntity
  ],
  (
    hotelId,
    absoluteHotelPosition,
    regularPriceRange,
    highestVisiblePrice,
    hotelTags,
    hotelOfferEntity,
    isHotelMapOpen,
    hotelEntity,
    distanceFromTarget,
    splitBookingDetails,
    offerEntity
  ): HotelContext => {
    const absoluteHotelPositionFromOne = increasePositionIndexByOne(
      absoluteHotelPosition
    )
    const cheapestOffer = getCheapestTopOfferByNightlyRate(
      hotelOfferEntity?.offers
    )
    const cheapestPrice = cheapestOffer?.nightlyRate

    const distanceFromSearchedLocation = distanceFromTarget
      ? convertMilesToKm(distanceFromTarget)
      : undefined

    const {promos = {}} = offerEntity || {}

    return {
      hotelId,
      highestVisiblePrice,
      hotelPosition: absoluteHotelPositionFromOne,
      regularPriceRange,
      hotelTags,
      cheapestPrice,
      isHotelMapOpen,
      indexedDiscountModifier: hotelEntity?.indexedDiscountModifier,
      discount: hotelOfferEntity?.discount,
      distanceFromSearchedLocation,
      isPartiallyMatched: hotelEntity?.partiallyMatched,
      ...splitBookingDetails,
      promos: promos
    }
  }
)

/**
 * Returns the offer context
 * @param state - redux state object
 * @param hotelId - id of the hotel containing the offer you require the offer context for
 * @param offerId - id of the offer you require the offer context for
 */
export const getOfferContext = createSelector(
  [
    (_, __, offerId: SearchOffer['id']) => offerId,
    (_, __, ___, sourceComponent) => sourceComponent,
    getHotelOfferEntity,
    getOffer,
    getNumberOfRooms,
    getShouldSeeOffersUnlocked,
    getIsOTAMode,
    getAppLockedDealConfig,
    getSplitBookingDetails,
    getDeviceLayout
  ],
  buildOfferContext
)

/**
 * Returns the offer context of each of the hotel's top offers
 * @param state - redux state object
 * @param hotelId - id of the hotel you require the hotel context for
 * @returns An array of `SearchOfferContext` objects. Returns an empty array if there are no top offers.
 */
export const getTopOffersOfferContexts = createSelector(
  [
    getStateParam,
    getHotelIdParam,
    getHotelOfferEntity,
    getIsOTAMode,
    getSplitBookingDetails,
    getDeviceCategory
  ],
  (
    state,
    hotelId,
    hotelOfferEntity,
    isOTAMode,
    splitBookingDetails,
    deviceCategory
  ): SearchOfferContext[] => {
    const offers = getTopOffersWithSplitBookings(
      isOTAMode,
      hotelOfferEntity,
      splitBookingDetails,
      deviceCategory
    )

    return (
      offers?.map(offer => getOfferContext(state, hotelId, offer.id, '')) || []
    )
  }
)

/**
 * Returns a list of hotel meta data for all hotels
 * @param state - redux state object
 */
const getHotelsMetaData = createSelector(
  [getStateParam, getHotelIdsToDisplayIncludingAnchor],
  (state, hotelIdsIncludingAnchor): HotelMetaData[] => {
    return hotelIdsIncludingAnchor.map(hotelId => ({
      ...getHotelContext(state, hotelId),
      topOffers: getTopOffersOfferContexts(state, hotelId)
    }))
  }
)

/**
 * Returns the pagination context
 * @param state - redux state object
 */
export const getPaginationContext = createSelector(
  [getCurrentPageNumber, getCurrentPageOffset, getCurrentPageLength],
  (pageNumber, pageOffset, pageLength): PaginationContext => {
    return {
      pageNumber,
      pageOffset,
      pageLength
    }
  }
)

/**
 * Returns the search results context
 * @param state - redux state object
 */
export const getSearchResultsContext = createSelector(
  [
    getHotelIdsRaw,
    getHotelIds,
    getHotelIndicesWithPrivateDeal,
    getAnchorHotelHasPrivateDeal,
    getAnchorHotelHasYourChoice,
    getHotelIdsToDisplay,
    getRequestedResultsCount,
    getRequestedOffset,
    getTotalCountOfResults,
    getUnavailableHotelsPositions,
    getHotelsMetaData,
    getPaginationContext
  ],
  (
    hotelIdsRaw,
    hotelIds,
    hotelIndexesWithPrivateDeals,
    anchorHotelHasPrivateDeal,
    anchorHotelHasYourChoice,
    hotelIdsToDisplay,
    requestedResultsCount,
    requestedOffset,
    totalCountOfResults,
    unavailableHotelsPositions,
    hotelsMetaData,
    paginationContext
  ): SearchResultsContext => {
    const hotelIndexesWithPrivateDealsFromOne = map(
      add(1),
      hotelIndexesWithPrivateDeals
    )
    return {
      algoliaNbHotels: hotelIdsRaw?.length ?? 0,
      algoliaTotalNbHotels: totalCountOfResults,
      anchorHotelHasPrivateDeal,
      anchorHotelHasYourChoice,
      hotelIndexesWithPrivateDeals: hotelIndexesWithPrivateDealsFromOne,
      hotelsMetaData,
      nbHotels: hotelIds?.length ?? 0,
      pageLength: hotelIdsToDisplay?.length ?? 0,
      staticHotelsLength: requestedResultsCount,
      staticHotelsOffset: requestedOffset,
      unavailableHotels: unavailableHotelsPositions,
      ...paginationContext
    }
  }
)
export const getHotelFromRtkQuery = createSelector(
  [getStateParam, getHotelIdParam],
  (state: RootState, hotelId) =>
    searchApi.endpoints.getHotel.select({hotelId})(state).data
)

/**
 * Returns the hotel info context
 * @param state - redux state object
 * @param hotelId - id of the hotel you require the hotel info context for
 */
export const getHotelInfoContext = createSelector(
  [getHotelIdParam, getHotel, getListOfValues],
  (hotelId, hotelEntity, listOfValues): HotelInfoContext => {
    if (!hotelEntity) return undefined

    const topAmenities = getRankedAvailableFacilities(
      hotelEntity.facilities,
      listOfValues
    )
    return {
      hotelId,
      hotelName: hotelEntity.hotelName,
      address: hotelEntity.displayAddress,
      checkInTime: hotelEntity.checkInTime,
      checkOutTime: hotelEntity.checkOutTime,
      country: hotelEntity.country,
      lastBooked: hotelEntity.lastBooked,
      reviewCount: hotelEntity.reviewCount,
      starRating: hotelEntity.starRating,
      guestRating: hotelEntity.guestRating,
      guestType: hotelEntity.guestType,
      numberOfImages: hotelEntity.imageURIs?.length ?? 0,
      imageURIs: hotelEntity.imageURIs,
      topAmenities
    }
  }
)

const getRoomIdParam = (_, roomId: Room['id']) => roomId
const getRoomsListParam = (_, __, roomsList?: Room[]) => roomsList

/**
 * Returns a room and it's index on the rooms list by id
 * @param state - redux state object
 * @param roomId - room id
 * @param roomsList - list of rooms, pass optionally to avoid re-fetching the rooms from the state
 */
const getRoomById = createSelector(
  [getRoomIdParam, getRoomsListParam, getFilteredRoomsData],
  (roomId, roomsList, currentStateRoomsData) => {
    if (!roomId) return {room: null, roomIndex: -1}
    const {rooms: currentStateRooms = []} = currentStateRoomsData
    const rooms = roomsList ? roomsList : currentStateRooms
    const roomIndex = rooms.findIndex(queryRoom => queryRoom.id === roomId)
    const room = rooms[roomIndex]
    return {room, roomIndex}
  }
)
/**
 * Returns concatenated isPrivate for offers of a room, which will not create a new object on state change
 * @param state - redux state object
 * @param roomId - room id
 * @param roomsList - list of rooms, pass optionally to avoid re-fetching the rooms from the state
 */
const getIsRoomOffersPrivateConcatenated = createSelector(
  [getStateParam, getRoomById],
  (state: RootState, roomsData) => {
    const {room} = roomsData
    return (
      room?.offers?.map(offer => getIsPrivateDeal(offer)(state))?.join(',') ||
      ''
    )
  }
)
/**
 * Returns the hotel room context
 * @param state - redux state object
 * @param roomId - room id
 * @param roomsList - list of rooms, pass optionally to avoid re-fetching the rooms from the state
 */
export const getRoomContext = createSelector(
  [getRoomById, getIsRoomOffersPrivateConcatenated, getCurrentHotelOfferEntity],
  (roomData, isRoomOffersPrivateString, offerEntity) => {
    const {room, roomIndex} = roomData
    if (!room) return null
    const roomPosition = roomIndex + 1
    const anchorPrice = getAnchorPrice(offerEntity)
    const offerContexts = room?.offers?.map((offer, index) => {
      const isPrivateDeal =
        isRoomOffersPrivateString?.split(',')[index] === 'true'
      return buildRoomOfferContext(
        offer,
        index,
        room,
        roomPosition,
        isPrivateDeal,
        anchorPrice
      )
    })
    return buildRoomContext(room, roomPosition, offerContexts)
  }
)
/**
 * Returns params required by ProviderRedirected event without empty values
 */
export const getProviderRedirectParams =
  ({
    offerId,
    bundleId,
    offerUrl,
    intermediaryProvider,
    sourceIp,
    redirectId
  }: TrackProviderRedirectParams) =>
  (state: RootState): ProviderRedirectParams => {
    const searchId = getSearchId(state)
    const label = getPersistedSapiLabel()
    const vclid = getPersistedVclidValue()
    const click_id = getClickId(state)
    const isHotelDetailsOpen = getHotelDetailsVisibility(state)
    const locale = getLocaleCode(state)
    const user_agent = getUserAgentDetails(state)?.source
    const source_ip = sourceIp

    const is_direct_supply = getIsDirectSupplyFromOfferUrl(offerUrl)
    const redirectUrl = new URL(location.href)

    if (isHotelDetailsOpen)
      redirectUrl.searchParams.set('showHotelDetails', '1')

    return {
      ...(click_id ? {click_id} : {}),
      // to be aligned with analytics, label should be URI encoded
      ...(label ? {label: encodeURIComponent(label)} : {}),
      ...(source_ip ? {source_ip} : {}),
      ...(user_agent ? {user_agent} : {}),
      ...(vclid ? {vclid} : {}),
      ...(typeof is_direct_supply === 'boolean' ? {is_direct_supply} : {}),
      locale,
      ...(offerId ? {offer_id: offerId} : {}),
      ...(bundleId ? {bundle_id: bundleId} : {}),
      provider_code: intermediaryProvider,
      redirect_id: redirectId,
      redirect_url: redirectUrl.toString(),
      search_id: searchId,
      check_in: getParamFromOfferBookURI(offerUrl, 'checkIn'),
      check_out: getParamFromOfferBookURI(offerUrl, 'checkOut'),
      device_type: getParamFromOfferBookURI(offerUrl, 'deviceType'),
      country_code: getParamFromOfferBookURI(offerUrl, 'userCountry'),
      hotel_id: getHotelIdFromOfferBookURIPath(offerUrl)
    }
  }

const getAPHotelInfoContext = createSelector(
  [getHotelIdParam, getHotelFromRtkQuery, getListOfValues],
  (hotelId, hotel, listOfValues): HotelInfoContext => {
    if (!hotel) return undefined
    const facilities = hotel.facilities.map(({id}) => id)
    const topAmenities = getRankedAvailableFacilities(facilities, listOfValues)
    return {
      hotelId,
      hotelName: hotel.hotelName,
      address: hotel.displayAddress,
      checkInTime: hotel.checkInTime,
      checkOutTime: hotel.checkOutTime,
      country: hotel.country,
      lastBooked: hotel.lastBooked,
      reviewCount: hotel.reviewCount,
      starRating: hotel.starRating,
      guestRating: hotel.guestRating,
      guestType: hotel.guestType,
      numberOfImages: hotel.imageURIs?.length ?? 0,
      imageURIs: hotel.imageURIs,
      topAmenities
    }
  }
)

/**
 * Get hotel context for the hotel details / accommodation overlay
 * This context will be added to all events fired from the view
 */
const getAccommodationContext = (
  state: RootState
): TrackEventProperties['analyticsContext'] => {
  const hotelId = getAccommodationPageHotelId(state)
  return hotelId
    ? {
        [AnalyticsContext.HotelContext]: getHotelContext(state, hotelId),
        [AnalyticsContext.HotelInfoContext]:
          getHotelInfoContext(state, hotelId) ||
          getAPHotelInfoContext(state, hotelId)
      }
    : {}
}

/**
 * Determines if a rooms search was manually triggered by the user
 * @returns {boolean} True if search was manually triggered via filters or search box interactions on accommodation page
 */
export const getIsRoomsSearchManuallyTriggered = createSelector(
  [getRoomsActiveFilters, getSearchBoxSearchTrigger],
  (filters, trigger) => {
    const isManuallyTriggeredBySearchBox = [
      SearchTrigger.GuestPicker,
      SearchTrigger.DatePicker
    ].includes(trigger)

    const isStandaloneAp = getIsAccommodationPage()
    const hasFilters = filters.length > 0

    return hasFilters || (isManuallyTriggeredBySearchBox && isStandaloneAp)
  }
)
