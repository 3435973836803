import React from 'react'

import {Box} from '@daedalus/atlas/helpers/Box'
import {NoStyleButton} from '@daedalus/atlas/helpers/NoStyleButton'
import {Icon} from '@daedalus/atlas/Icon'
import {User} from '@daedalus/core/src/auth/types/Cognito'
import {UserAvatar} from '@daedalus/shared/src/user/account/UserAvatar'

type Props = {
  onShowPreferences?: () => void
  shouldShowUserAvatar: boolean
  user?: User
}

export const MobileHeaderMenu = ({
  onShowPreferences,
  shouldShowUserAvatar,
  user
}: Props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <NoStyleButton
        onClick={onShowPreferences}
        data-id="ShowPreferencesButton"
      >
        {shouldShowUserAvatar && user ? (
          <UserAvatar user={user} />
        ) : (
          <Icon name="Menu" size="md" />
        )}
      </NoStyleButton>
    </Box>
  )
}
