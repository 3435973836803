import {loadModule} from 'utils'

import type {FacilitiesFilterBottomSheetContentProps} from './FacilitiesFilterBottomSheetContent'

export const FacilitiesFilterBottomSheetContentLoadable =
  loadModule<FacilitiesFilterBottomSheetContentProps>(
    async () =>
      import(
        /* webpackChunkName: "FacilitiesFilterBottomSheetContent" */
        './FacilitiesFilterBottomSheetContent'
      ),
    {}
  )
