import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import once from 'lodash/once'
import {trackTimingEvent} from 'middleware/analytics'

import {logTimeStart} from '@daedalus/core/src/_web/utils/logging/performanceTimer'
import {Entity} from '@daedalus/core/src/analytics/types/Events'

const trackDatePickerReady = debounce((dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'DatePickerReady',
    'DatePickerReady-start',
    Entity.DatePicker
  )
})

const trackDatePickerLoaded = once((dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'DatePickerLoaded',
    'DatePickerLoaded-start',
    Entity.DatePicker
  )
})

export const useDatePickerTiming = () => {
  const dispatch = useDispatch()

  const datePickerReadyTimerStart = () => {
    logTimeStart('DatePickerReady')
  }

  const datePickerLoadedTimerStart = () => {
    logTimeStart('DatePickerLoaded')
  }

  const datePickerReadyTimerEnd = useCallback(
    () => trackDatePickerReady(dispatch),
    [dispatch]
  )

  const datePickerLoadedTimerEnd = useCallback(
    () => trackDatePickerLoaded(dispatch),
    [dispatch]
  )

  return {
    datePickerReadyTimerStart,
    datePickerReadyTimerEnd,
    datePickerLoadedTimerStart,
    datePickerLoadedTimerEnd
  }
}
