import {equals, pipe, toUpper} from 'ramda'

export const BOFH_PROXY_PROVIDER_CODE = 'FHT'

const isBofhProxyProvider = equals<string>(BOFH_PROXY_PROVIDER_CODE)

/**
 *
 * Determines if FindHotel is the provider for the given offer.
 *
 * @example
 *
 * ```
 * isBofhOffer(offer)
 * ```
 *
 * @param offer - The offer you are checking if it's FH provider or not
 *
 * @returns Whether FindHotel is the provider for the given offer or not
 */
const isBofhOffer = (offer?: {
  intermediaryProvider?: string
  proxyProviderCode?: string
}): boolean => {
  const providerCode =
    offer?.intermediaryProvider ?? offer?.proxyProviderCode ?? ''
  return pipe(toUpper, isBofhProxyProvider)(providerCode)
}

export default isBofhOffer
