import {dissocPath, pick, when} from 'ramda'
import {AnyAction} from 'redux'

import {State} from '../selectors'

/**
 * Remove user profile data from state
 * This helper is meant to be used in the `stateTransformer` option of Sentry middleware
 *
 * @param state - State object including a `user` module
 * @returns {object} - The modified state object which can be sent to Sentry
 */
export const removeUserProfile = (state: State) =>
  dissocPath(['user', 'profile'], state)

/**
 * Remove user user data from any Redux action
 * This helper is meant to be used in the `actionTransformer` option of Sentry middleware
 *
 * @param action - Any Redux action
 * @returns {object} - The modified action which can be sent to Sentry
 */
export const removeUserData = (action: AnyAction) => {
  const regex = /USER_/g
  const hasUserActionType = (action: AnyAction) => action?.type?.match(regex)

  return when(hasUserActionType, pick(['type']))(action)
}
