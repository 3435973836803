import {useSelector} from 'react-redux'
import {
  getHotelTopOffer,
  getShowTotalPrices,
  getSplitBookingDetails,
  getSplitBookingPriceSummary
} from 'modules/sapiSearch/selectors'

import type {Hotel} from 'modules/sapiSearch/slice'

interface Props {
  hotelId: Hotel['objectID']
}

// TODO: this logic is extracted/copied from packages/client/src/components/Map/HotelMarker/index.tsx
// It should be tested and reused in both places.
export const useMapHotelPrice = ({hotelId}: Props) => {
  const showTotalPrices = useSelector(getShowTotalPrices)
  const {hasSplitBookingOffer, splitBookingPosition} = useSelector(state =>
    getSplitBookingDetails(state, hotelId)
  )
  const sbPriceSummary = useSelector(state =>
    getSplitBookingPriceSummary(state, hotelId)
  )

  const topOffer = useSelector(state => getHotelTopOffer(state, hotelId))

  const hasTopOffer = Boolean(topOffer)

  const calculatePrice = (): number | undefined => {
    // If the top offer is a split booking, show split booking price
    if (hasSplitBookingOffer && splitBookingPosition === 0) {
      return showTotalPrices ? sbPriceSummary.sum : sbPriceSummary.average
    }

    if (hasTopOffer) {
      return showTotalPrices ? topOffer.totalRate : topOffer.nightlyRate
    }

    return undefined
  }

  return calculatePrice()
}
