import React, {useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getUrlParam} from '@daedalus/core/src/utils/url'

import {SusiContext} from '../../authentication/SUSI/bottomSheet/SusiBottomSheet/SusiProvider'
import {PriceWatchCTAParams} from '../types'
import {getCallbackUrl} from '../utils'
import {usePriceWatch} from './usePriceWatch'
import {usePriceWatchToggleToast} from './usePriceWatchToggleToast'

export const usePriceWatchToggleWebLogic = (props: PriceWatchCTAParams) => {
  const {setCallbackUrl} = useContext(SusiContext)
  const history = useHistory()
  const {
    isAuthenticated,
    hotel,
    checkIn,
    checkOut,
    shouldRedirectToAp,
    hotelContext,
    getSearchContext,
    setWebBottomSheetOpen,
    alertsManagementUrl,
    componentName
  } = props
  const searchContext = useSelector(getSearchContext)

  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search)

  const onPriceWatchInitialized = () => {
    setShouldShowPriceWatchEnabledMessage(true)

    const component = queryParams.get('componentPriceWatch')

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.PriceAlertEnable,
        action: Action.Succeeded,
        team: Team.Retention,
        analyticsContext: {
          [AnalyticsContext.SearchContext]: searchContext,
          [AnalyticsContext.HotelContext]: hotelContext
        },
        payload: {
          isUserSignedIn: props.isAuthenticated
        },
        component: component || componentName
      })
    )

    if (component) {
      queryParams.delete('componentPriceWatch')
      history.replace({search: queryParams.toString()})
    }

    // In case the toggle in on SRP, we cleanup the param after initialize the price watch for that hotel
    if (!shouldRedirectToAp) {
      if (queryParams.get('enablePriceWatch') === hotel.objectID) {
        queryParams.delete('enablePriceWatch')

        history.replace({search: queryParams.toString()})
      }
    }
  }

  const onToastClick = (event: React.SyntheticEvent) => {
    event?.preventDefault()
    event?.stopPropagation()
    event?.nativeEvent.stopImmediatePropagation()

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceAlertToast,
        action: Action.Clicked,
        team: Team.Retention,
        analyticsContext: {
          [AnalyticsContext.SearchContext]: searchContext,
          [AnalyticsContext.HotelContext]: hotelContext
        }
      })
    )

    alertsManagementUrl && history.push(alertsManagementUrl)
  }

  const params = {
    ...props,
    onPriceWatchInitialized
  }

  const {
    priceWatchEntry,
    isPriceWatchOn,
    cancelPriceWatch,
    removePriceWatch,
    initPriceWatch
  } = usePriceWatch(params)

  const {
    setShouldShowPriceWatchEnabledMessage,
    setShouldShowPriceWatchDisabledMessage
  } = usePriceWatchToggleToast({
    isPriceWatchOn,
    hotel,
    checkIn,
    checkOut,
    isEnabledToTriggerToggle: Boolean(isAuthenticated),
    shouldShowNavigationIcon: Boolean(alertsManagementUrl),
    onToastClick
  })

  const handleClick = (event: React.SyntheticEvent<Element, Event>) => {
    event?.preventDefault()
    event?.stopPropagation()

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceAlertToggle,
        action: Action.Clicked,
        team: Team.Retention,
        analyticsContext: {
          [AnalyticsContext.SearchContext]: searchContext,
          [AnalyticsContext.HotelContext]: hotelContext
        },
        payload: {
          isUserSignedIn: isAuthenticated,
          from: isPriceWatchOn ? 'enabled' : 'disabled',
          to: !isPriceWatchOn ? 'enabled' : 'disabled'
        },
        component: componentName
      })
    )

    if (!isAuthenticated) {
      setCallbackUrl(
        getCallbackUrl({...props, hotelId: hotel.objectID, shouldRedirectToAp})
      )
      setWebBottomSheetOpen()

      return
    }

    if (isAuthenticated && !isPriceWatchOn) {
      initPriceWatch()
      return
    }

    if (isPriceWatchOn) {
      cancelPriceWatch()
      setShouldShowPriceWatchDisabledMessage(true)
      return
    }
  }

  const hotelId = hotelContext?.hotelId

  const enablePriceWatchAfterAuth = () => {
    const component = queryParams.get('componentPriceWatch')
    const enablePriceWatchParam = getUrlParam({
      search: location.search,
      param: 'enablePriceWatch'
    })
    const paramMatchWithHotel = hotelId === enablePriceWatchParam

    const isTriggeredByThisComponent = componentName === component

    const shouldInitPriceWatchPostAuth =
      paramMatchWithHotel &&
      isAuthenticated &&
      !isPriceWatchOn &&
      isTriggeredByThisComponent

    if (shouldInitPriceWatchPostAuth) initPriceWatch()
  }

  const disablePriceWatchViaURL = () => {
    const unsubscribePriceWatchParam = queryParams.get('unsubscribePriceWatch')
    const hotelIdURL = queryParams.get('hotelId')

    const shouldUnsubscribePriceWatch =
      unsubscribePriceWatchParam && hotelId === hotelIdURL

    if (shouldUnsubscribePriceWatch) {
      cancelPriceWatch()
      setShouldShowPriceWatchDisabledMessage(true)
      queryParams.delete('unsubscribePriceWatch')
      history.replace({search: queryParams.toString()})
    }
  }

  useEffect(() => {
    enablePriceWatchAfterAuth()
    disablePriceWatchViaURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    // Cleanup enablePriceWatch when unmount component
    return () => {
      if (queryParams.get('enablePriceWatch')) {
        queryParams.delete('enablePriceWatch')
        history.replace({search: queryParams.toString()})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isToggleOn = isPriceWatchOn && Boolean(isAuthenticated)

  return {
    handleClick,
    isToggleOn,
    priceWatchEntry,
    cancelPriceWatch,
    removePriceWatch,
    initPriceWatch
  }
}
