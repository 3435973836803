import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {getUrlParams} from '@daedalus/core/src/_web/utils/url'
import {ProfileKey} from '@findhotel/sapi'

import {ExternalActions} from '../../context/ExternalActionsContext'
import {setApplication} from '../../modules/slice'

export interface OverlayStateType {
  type: string
  extraState?: Record<string, unknown>
}

interface Props {
  appCurrency: string | undefined
  sapiProfileKey: ProfileKey
  boVioEndpoint: string
  boVioProfile: string
  checkoutUrl: string
  bookingManagementUrl: string
  languageCode: string
  actions: ExternalActions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSearchId: (state: any) => string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCountryCode: (state: any) => string | null
}

/**
 * DF is in the shared package and is used on other packages of the monorepo.
 * storing application's related properties in Redux to access them without being coupled to the outside application's internal functionalities, and can also improve the debugging process.
 * This component is separated from dealFreeze/index to avoid re-rendering caused by the use of useLocation.
 */
export const UpdateApplication = ({
  appCurrency,
  checkoutUrl,
  bookingManagementUrl,
  languageCode,
  getSearchId,
  getCountryCode
}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const {search} = location

  const [currency, setCurrency] = useState<string | undefined>(appCurrency)

  useEffect(() => {
    if (currency) return
    const {currency: urlCurrency} = getUrlParams()
    setCurrency(urlCurrency as string)
  }, [currency, search])

  const countryCode = useSelector(getCountryCode)
  const searchId = useSelector(getSearchId)

  useEffect(() => {
    dispatch(
      setApplication({
        countryCode,
        searchId,
        currency,
        languageCode,
        checkoutUrl,
        bookingManagementUrl
      })
    )
  }, [
    dispatch,
    searchId,
    countryCode,
    currency,
    languageCode,
    checkoutUrl,
    bookingManagementUrl
  ])

  return null
}
