import React from 'react'
import {useIntl} from 'react-intl'

import {
  numberOfRooms,
  roomsToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'

import {guestPickerMessages} from '../GuestPicker/guestPickerMessages'

interface GuestAndRoomTextProps {
  rooms: string
}

export const GuestAndRoomText = ({rooms}: GuestAndRoomTextProps) => {
  const {formatMessage} = useIntl()
  const roomsNumber = numberOfRooms(rooms)
  const roomObject = roomsToConfigurationObject(rooms)
  const guestsNumber = roomObject.numberOfAdults + roomObject.numberOfChildren

  const guestMessage = formatMessage(guestPickerMessages.guest, {
    count: guestsNumber
  })

  const roomsMessage = formatMessage(guestPickerMessages.room, {
    count: roomsNumber
  })

  const guestsText = guestsNumber ? `${guestsNumber} ${guestMessage}` : null
  const commaText = guestsNumber && roomsNumber ? ', ' : null
  const roomsText = roomsNumber ? `${roomsNumber} ${roomsMessage}` : null
  return (
    <>
      {guestsText}
      {commaText}
      {roomsText}
    </>
  )
}
