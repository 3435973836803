import React from 'react'
import {
  SplitBookingBundleType,
  SplitBookingOfferInfo
} from 'components/Offer/types'

import {OfferPrimaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {OfferLayout} from '@daedalus/shared/src/search/OfferLayout'
import {SplitBookingBundle} from '@findhotel/sapi'

import {ProviderLogoComponent} from '../Shared/ProviderLogoComponent'
import {RoomLabelsComponent} from '../Shared/RoomLabelsComponent'
import {CtaComponent} from './CtaComponent'
import {PriceComponent} from './PriceComponent'
import {RoomSwitchRequiredTooltip} from './RoomSwitchRequiredTooltip/RoomSwitchRequiredTooltip'
import {TagComponent} from './TagComponent/TagComponent'

interface SplitBookingProps {
  isLoading?: false
  variant: OfferPrimaryLayoutVariant
  hotelId: string
  splitBookingOffers: SplitBookingOfferInfo[]
  splitBookingBundle?: SplitBookingBundle
  onLabelClick?: () => void
  hasRoomLabels?: boolean
  isSplitBooking: boolean
  splitBookingType: SplitBookingBundleType
  showSignInToUnlock?: never
  shouldShowTaxation?: boolean
  shouldShowTaxationAndNightly?: boolean
  showPriceIndication?: boolean
  offerVariant?: never
  hasInteraction?: false
  offer?: never
  anchorPrice?: number
  anchorPriceTotal?: number
  shouldShowCtaLockIcon?: never
  sourceComponent?: string
  offerPosition?: never
  hasWeb2AppPromo?: never
}

export const SplitBookingPrimaryOffer: React.FC<SplitBookingProps> = ({
  anchorPrice,
  anchorPriceTotal,
  variant,
  hotelId,
  splitBookingOffers = [],
  isLoading = false,
  splitBookingType,
  onLabelClick,
  shouldShowTaxation = true,
  shouldShowTaxationAndNightly = false,
  hasRoomLabels = true
}) => {
  return (
    <OfferLayout
      dataId="PrimarySplitBookingOffer"
      variant={variant}
      isLoading={isLoading}
      tagComponent={
        <TagComponent
          isSplitBooking
          splitBookingType={splitBookingType}
          hotelId={hotelId}
          onTagClick={onLabelClick}
          variant={variant}
        />
      }
      priceComponent={
        <PriceComponent
          isSplitBooking
          splitBookingType={splitBookingType}
          anchorPrice={anchorPrice}
          anchorPriceTotal={anchorPriceTotal}
          hotelId={hotelId}
          shouldShowTaxation={shouldShowTaxation}
          shouldShowTaxationAndNightly={shouldShowTaxationAndNightly}
          variant={variant}
        />
      }
      providerLogoComponent={
        <ProviderLogoComponent
          isSplitBooking
          splitBookingOffers={splitBookingOffers}
          variant={variant}
        />
      }
      roomLabelsComponent={
        <>
          <RoomLabelsComponent
            isSplitBooking
            splitBookingType={splitBookingType}
            splitBookingOffers={splitBookingOffers}
            hasRoomLabels={hasRoomLabels}
            hotelId={hotelId}
          />
          {splitBookingType === 'single_flow' && <RoomSwitchRequiredTooltip />}
        </>
      }
      ctaComponent={
        <CtaComponent
          splitBookingType={splitBookingType}
          hotelId={hotelId}
          variant={variant}
        />
      }
    />
  )
}
