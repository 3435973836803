import {useSelector} from 'react-redux'
import {getUrlParams} from 'modules/search/selectors'

interface Props {
  splitBookingOfferUrl: string
}

export const useSplitBookingRedirectLink = ({
  splitBookingOfferUrl
}: Props): string => {
  try {
    const urlParams = useSelector(getUrlParams)
    const redirectURL = new URL(splitBookingOfferUrl)

    if (urlParams?.freeCancellation === '1')
      redirectURL.searchParams.append('freeCancellation', '1')
    if (urlParams?.payLater === '1')
      redirectURL.searchParams.append('payLater', '1')
    if (urlParams?.amenities?.length > 0) {
      urlParams.amenities.forEach(i =>
        redirectURL.searchParams.append('amenities', i)
      )
    }

    return redirectURL.toString()
  } catch {
    return splitBookingOfferUrl
  }
}
