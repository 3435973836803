import {Dispatch as ReduxDispatch} from 'redux'

export const REDIRECT_EXTERNAL = 'REDIRECT_EXTERNAL'

export type ActionType = {
  type: typeof REDIRECT_EXTERNAL
  url: string
}

export type ExternalRedirectType = (url: string) => ActionType
export type DispatchType = ReduxDispatch<null>

const externalRedirect: ExternalRedirectType = (url = '') => ({
  type: REDIRECT_EXTERNAL,
  url
})

export default externalRedirect
