import {LinkType as BookingLinkType} from '../../api-types/bovio/response/booking'
import {LinkType as OfferCheckLinkType} from '../../api-types/bovio/response/offer_check'
import {LinkType} from '../../booking/types/Link'
import {ActionLink, ActionLinkTypes} from '../types/actionLink'

export const getLinkByType = (
  links: LinkType[] | ActionLink[] | undefined,
  type: string | ActionLinkTypes | BookingLinkType | OfferCheckLinkType
) => {
  if (!links) return undefined
  return links ? links?.find(link => link.type === type)?.href : undefined
}
