import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {getMyBookingsUrl} from 'modules/meta/selectors'

import {
  FROZEN_DEAL_PARAM,
  useFrozenDealToast
} from '@daedalus/shared/src/dealFreeze/hooks/useFrozenDealToast'

// FrozenDealToast component is used to show a toast message whenever there is a url param called frozenDeal=1 that indicates the user frozen a deal.
export const FrozenDealToast: React.FC = () => {
  const params = new URLSearchParams(window.location.search)
  const myBookingsUrl = useSelector(getMyBookingsUrl)
  const history = useHistory()

  const {showFrozenDealToast, closeFrozenDealToast} = useFrozenDealToast({
    myBookingsUrl
  })

  const removeParamFromUrl = () => {
    params.delete(FROZEN_DEAL_PARAM)
    history.replace({search: params.toString()})
  }

  useEffect(() => {
    if (params.has(FROZEN_DEAL_PARAM)) {
      showFrozenDealToast()
      removeParamFromUrl()
    }

    return () => closeFrozenDealToast()
    // Do not add showFrozenDealToast, closeFrozenDealToast methods to the dependencies array. It can cause infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
