import {LocationSearchType} from 'modules/search/slice'

import {logTimeStart} from '@daedalus/core/src/_web/utils/logging/performanceTimer'

export enum GeolocationSource {
  Ip = 'ip',
  Device = 'device',
  Url = 'url'
}

const GEOLOCATION_PERMISSION_NAME = 'geolocation'
export enum GeolocationPermissions {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt'
}

const geolocationRetrievalOptions = {
  maximumAge: 60000, // 1 minute
  timeout: 10000, // 10 seconds
  enableHighAccuracy: true
}

// eslint-disable-next-line no-undef
export const getPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      resolve,
      reject,
      geolocationRetrievalOptions
    )
  )
}

export const getHasGeolocationPermission = () => {
  return navigator.permissions
    .query({name: GEOLOCATION_PERMISSION_NAME})
    .then(permissionStatus => {
      const {state} = permissionStatus
      permissionStatus.onchange = () => {
        if (permissionStatus.state === GeolocationPermissions.GRANTED) {
          logTimeStart('GeolocationCoordinates')
        }
      }
      return state === GeolocationPermissions.GRANTED
    })
}

export const getLocationSearchTypeFromGeolocationSourceCookie = (
  geolocationSource
): LocationSearchType => {
  const geolocationSourceToLocationSearchTypeMap = {
    [GeolocationSource.Ip]: LocationSearchType.IpLocation,
    [GeolocationSource.Device]: LocationSearchType.UserLocation,
    [GeolocationSource.Url]: LocationSearchType.UrlLocation
  }

  return geolocationSourceToLocationSearchTypeMap[geolocationSource]
}
