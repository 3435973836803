import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {mapEventChannel} from 'events/map'
import {getLanguageCode} from 'modules/meta/selectors'
import {getHotelIds} from 'modules/sapiSearch/selectors'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Footer as FooterAtlas} from '@daedalus/atlas/Footer'
import {Text} from '@daedalus/atlas/Text'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {CookiePreferencesLink} from '@daedalus/shared/src/search/cookies/CookiePreferencesLink'
import {TrustpilotWidget} from '@daedalus/shared/src/search/footer/TrustpilotWidget'

import {GetTheAppFooterWidget} from './GetTheAppFooterWidget/GetTheAppFooterWidget'

interface Props {
  forcedLayout?: 'compact' | 'wide'
}

const FooterWidget = () => {
  const {brandIsVio, brandIsKiwi} = useBrand()
  const lang = useSelector(getLanguageCode) || 'en'

  if (brandIsVio) {
    return <TrustpilotWidget lang={lang} />
  }
  if (brandIsKiwi) {
    return (
      <Text variant="labelM">
        <FormattedMessageWrapper
          defaultMessage="We hack the system. You stay for less"
          id="kiwi.tagline"
        />
      </Text>
    )
  }

  return null
}
export const Footer = ({forcedLayout}: Props) => {
  const {brand} = useBrand()
  const {isMobile} = useDeviceLayout()
  const {ref, inView} = useInView({threshold: 0.7})
  const hotels = useSelector(getHotelIds)
  const languageCode = useSelector(getLanguageCode)

  useEffect(() => {
    if (isMobile && hotels?.length !== 1) {
      // for mobile, emit the footerInView event when its in view
      mapEventChannel.emit('footerInView', inView)
    }
  }, [isMobile, inView])

  return (
    <div ref={ref}>
      <FooterAtlas
        brand={brand}
        forcedLayout={forcedLayout}
        widget={<FooterWidget />}
        getTheAppSlot={<GetTheAppFooterWidget />}
        CookiePreferencesLinkSlot={<CookiePreferencesLink brand={brand} />}
        languageCode={languageCode}
      />
    </div>
  )
}
