import {trackTimingEvent} from 'middleware/analytics'
import {fetchIpInfo} from 'services/ipInfo'
import {CountryType, LocalizationConfig} from 'types/Localization'
import {
  GeolocationSource,
  getHasGeolocationPermission,
  getPosition
} from 'utils/geolocation'
import {getCountry} from 'utils/localization/country'
import {
  getCurrencyCodeConfig,
  persistCurrencyCode
} from 'utils/localization/currency'
import {getLanguageCode, persistLanguageCode} from 'utils/localization/language'

import {
  getPersistedUserCity,
  persistUserCity
} from '@daedalus/core/src/_web/userConfig/userCity'
import {
  getCountryCodeFromQueryString,
  getPersistedUserCountryCode,
  persistUserCountryCode
} from '@daedalus/core/src/_web/userConfig/userCountry'
import {
  getPersistedUserIp,
  persistUserIp
} from '@daedalus/core/src/_web/userConfig/userIp'
import {safelySetStorageItem} from '@daedalus/core/src/_web/utils/persistence'
import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'
import {Entity} from '@daedalus/core/src/analytics/types/Events'
import {defaultCountry} from '@daedalus/core/src/localization/business/countries'
import {
  getFallbackLanguages,
  isRtlLanguage
} from '@daedalus/core/src/localization/services/locale'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {
  getGeolocationFromQueryString,
  getPersistedGeolocation,
  getPersistedGeolocationSource,
  persistGeolocation,
  persistGeolocationSource
} from '@daedalus/core/src/utils/geolocation'
import {logAndCaptureErrorMessage} from '@daedalus/core/src/utils/logging/errorHandlers'

interface InitialLocalizationValuesType {
  userIp?: string
  geolocation?: {lat: number; lon: number}
  geolocationSource?: GeolocationSource | string
  userCountryCode?: string
  userCountryRegion?: string
  userCity?: string
}

/**
 * Pick and rename some country props
 */
const formatCountryDataForMeta = ({
  code,
  measurementSystem,
  includeLocalTaxes,
  includeTaxes,
  includeTaxesParam,
  includeLocalTaxesParam
}: CountryType) => {
  const result = {
    userCountryCode: code,
    userMeasurementSystem: measurementSystem,
    includeLocalTaxes,
    includeTaxes
  }

  // Allow changing price settings with URL parameters
  if (includeTaxesParam) {
    safelySetStorageItem(localStorage, 'includeTaxes', includeTaxesParam)
    result['includeTaxes'] = includeTaxesParam === '1'
  }

  // Allow changing price settings with URL parameters
  if (includeLocalTaxesParam) {
    safelySetStorageItem(
      localStorage,
      'includeLocalTaxes',
      includeLocalTaxesParam
    )
    result['includeLocalTaxes'] = includeLocalTaxesParam === '1'
  }

  return result
}

/**
 * When WebView, get geolocation from URL, then set source as URL.
 * Otherwise, try getting it from the persisted Cookie with persisted source.
 */
const getGeolocationFromUrlOrCookie = () => {
  const geolocationFromUrl = getGeolocationFromQueryString()

  if (isReactNativeWebView() && geolocationFromUrl) {
    return {
      geolocation: geolocationFromUrl,
      geolocationSource: GeolocationSource.Url
    }
  }

  return {
    geolocation: getPersistedGeolocation(),
    geolocationSource: getPersistedGeolocationSource()
  }
}

/**
 * Retrieves localization configuration using various sources in a prioritized manner.
 * It fetches localization data from URL parameters, cookies, and then, if necessary, uses a geolocation service.
 *
 * If IpInfo is down fallback to the cookie otherwise to the default country code
 * @returns Promise<LocalizationConfig> - The localization configuration based on the retrieved data.
 *
 */
export const getLocalizationConfig = async (
  dispatch
): Promise<LocalizationConfig> => {
  // Try fetching the user's country code from the URL.
  // URL parameters take precedence over ipinfo and persisted data.
  const userCountryCode = getCountryCodeFromQueryString()

  const params = getQueryStringObject()
  const isReactNative = isReactNativeWebView()

  const {geolocation, geolocationSource} = getGeolocationFromUrlOrCookie()

  // Initialize localization data from persisted values (typically cookies).
  const initialLocalizationValues: InitialLocalizationValuesType = {
    userIp: getPersistedUserIp(),
    geolocation,
    geolocationSource,
    userCountryCode: userCountryCode,
    userCity: getPersistedUserCity()
  }

  // Create a copy of the initialLocalizationValues to update based on the response.
  const updatedLocalizationValues: InitialLocalizationValuesType = {
    ...initialLocalizationValues
  }

  try {
    const response = await fetchIpInfo()

    // Update the values from the IP info response only if they weren't initially set.
    updatedLocalizationValues.userIp =
      response.ip || updatedLocalizationValues.userIp

    // If userCountryCode not found in the URL, get it from ipInfo
    if (!initialLocalizationValues.userCountryCode && response.country) {
      updatedLocalizationValues.userCountryCode = response.country
    }

    updatedLocalizationValues.userCity =
      response.city || updatedLocalizationValues.userCity

    updatedLocalizationValues.userCountryRegion = response.region

    // If geolocation not found in the URL or Cookie, get it from ipInfo
    if (
      !initialLocalizationValues.geolocation &&
      response.lat &&
      response.lon
    ) {
      updatedLocalizationValues.geolocation = {
        lat: Number(response.lat),
        lon: Number(response.lon)
      }
      updatedLocalizationValues.geolocationSource = GeolocationSource.Ip
    }
  } catch (error) {
    logAndCaptureErrorMessage(`Error fetching IP info - ${error.message}`, {
      level: 'warning'
    })
    // If there was an error with the IP info service, attempt to get geolocation from the device.
    try {
      const hasPermission = isReactNative
        ? false
        : await getHasGeolocationPermission()

      if (hasPermission) {
        const position = await getPosition()
        const {latitude, longitude} = position.coords
        updatedLocalizationValues.geolocationSource = GeolocationSource.Device
        updatedLocalizationValues.geolocation = {
          lat: Number(latitude),
          lon: Number(longitude)
        }
        trackTimingEvent(
          dispatch,
          'GeolocationCoordinates',
          'GeolocationCoordinates-start',
          Entity.GeolocationCoordinatesFetch
        )
      }
    } catch (error) {
      logAndCaptureErrorMessage(
        `Error fetching geolocation from device - ${error.message}`,
        {
          level: 'warning'
        }
      )
    }
  }

  // Persist updated values for future sessions.

  // If userCountryCode not found in the URL or from IpInfo, get it from the cookie
  const calculatedUserCountryCode =
    updatedLocalizationValues.userCountryCode ||
    getPersistedUserCountryCode() ||
    defaultCountry.code

  persistUserCountryCode(calculatedUserCountryCode)

  if (updatedLocalizationValues.userCity)
    persistUserCity(updatedLocalizationValues.userCity)
  if (updatedLocalizationValues.userIp)
    persistUserIp(updatedLocalizationValues.userIp)
  if (updatedLocalizationValues.geolocation)
    persistGeolocation(updatedLocalizationValues.geolocation)
  if (updatedLocalizationValues.geolocationSource)
    persistGeolocationSource(updatedLocalizationValues.geolocationSource)

  // Get user country details. Use a default country if none is determined.
  const userCountry = getCountry(calculatedUserCountryCode)
  const languageCode = getLanguageCode()
  const currencyCode = getCurrencyCodeConfig({userCountry})
  const includeLocalTaxesParam = params.includeLocalTaxes
    ? params.includeLocalTaxes.toString()
    : undefined
  const includeTaxesParam = params.includeTaxes
    ? params.includeTaxes.toString()
    : undefined

  // Update and persist language and currency based on the user's country.
  persistLanguageCode(languageCode)
  persistCurrencyCode(currencyCode)

  // Compile and return the full localization configuration.
  return {
    ...formatCountryDataForMeta({
      ...userCountry,
      includeTaxesParam,
      includeLocalTaxesParam
    }),
    userCountryRegion: updatedLocalizationValues.userCountryRegion,
    address: updatedLocalizationValues.userIp,
    currencyCode,
    languageCode,
    fallbackLanguages: getFallbackLanguages(languageCode),
    isRTLLanguage: isRtlLanguage(languageCode),
    localeCode: languageCode,
    geolocation: updatedLocalizationValues.geolocation,
    userCity: updatedLocalizationValues.userCity
  }
}
