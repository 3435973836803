/*global google */
import React, {useCallback, useMemo, useState} from 'react'
import debounce from 'lodash/debounce'

import {googleToVioLatLng} from './utils'

import type {Location} from './types'

interface Props {
  initialLocation: Location
  streetViewPano: google.maps.StreetViewPanorama | undefined
}

/**
 * Track the current location of the panorama as the user moves around
 * To be used for marker distances
 */
export const usePanoPosition = ({initialLocation, streetViewPano}: Props) => {
  const [currentLocation, setCurrentLocation] = useState(initialLocation)

  const onPositionChanged = useCallback(() => {
    const latLng = streetViewPano?.getLocation?.()?.latLng
    if (!latLng) return
    setCurrentLocation(googleToVioLatLng(latLng))
  }, [streetViewPano])

  // Avoids re-rendering loop
  const onPositionChangedDebounced = React.useMemo(
    () => debounce(onPositionChanged, 100),
    [onPositionChanged]
  )

  return useMemo(
    () => ({
      currentLocation,
      onPositionChanged: onPositionChangedDebounced
    }),
    [currentLocation, onPositionChangedDebounced]
  )
}
