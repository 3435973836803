import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface CookiesState {
  acceptedByUser: boolean
  vclid?: string
}

const initialState: CookiesState = {
  acceptedByUser: true
}

export const initializeUserCookiesPreference = createAction(
  'cookies/initializeUserCookiesPreference'
)

const {actions, reducer} = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    setCookiesAcceptedByUser: (state, action: PayloadAction<boolean>) => ({
      ...state,
      acceptedByUser: action.payload
    }),
    setCookiesVclid: (state, action: PayloadAction<string>) => ({
      ...state,
      vclid: action.payload
    })
  }
})

export const {setCookiesAcceptedByUser, setCookiesVclid} = actions

export default reducer
