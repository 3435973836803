/*global google */
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  GoogleMap,
  LoadScript,
  MapContext,
  StreetViewPanorama
} from '@react-google-maps/api'
import {getHotelFromRtkQuery} from 'middleware/analytics/selectors'
import {logEvent} from 'middleware/analytics/utils/logEvent'
import {hideOverlay} from 'modules/overlay/slice'

import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

import {StreetviewMarkers} from './StreetviewMarkers'
import {usePanoPosition} from './usePanoPosition'
import {computeAngle, googleToVioLatLng, vioToGoogleLatLng} from './utils'

import type {Location} from './types'

interface Props {
  hotelId: string
}

const containerStyle = {
  width: '100%',
  height: '100vh'
}

type PanoProps = {
  pano?: string
  options: google.maps.StreetViewPanoramaOptions
}

const defaultPanoOptions: google.maps.StreetViewPanoramaOptions = {
  addressControl: false,
  fullscreenControl: false,
  enableCloseButton: false,
  zoomControl: false,
  visible: true,
  zoom: 1 // Workaround for markers not showing - https://stackoverflow.com/a/49474807
}

const GoogleStreetView = ({hotelId}: Props) => {
  const dispatch = useDispatch()
  const dispatchTeamEvent = useDispatchTeamEvent()
  const hotel = useSelector(state => getHotelFromRtkQuery(state, hotelId))
  const initialLocation = hotel._geoloc

  const initialLocationForGoogle = useMemo(
    () => vioToGoogleLatLng(initialLocation),
    [initialLocation]
  )

  const [streetViewService] = useState(new google.maps.StreetViewService())
  const [panoProps, setPanoProps] = useState<PanoProps>(undefined)
  const [streetViewPano, setStreetViewPano] =
    useState<google.maps.StreetViewPanorama>()

  const {currentLocation, onPositionChanged} = usePanoPosition({
    initialLocation,
    streetViewPano
  })

  /**
   * Move the streetview to a new location and turn it to the correct angle from the street to see that location
   */
  const setPositionAndPov = useCallback(
    async (newLocation: Location) => {
      const newLocationForGoogle = vioToGoogleLatLng(newLocation)

      // Fetch the panorama for the future location so we can calculate the right angle from the street to the hotel
      try {
        const {data} = await streetViewService.getPanorama({
          location: newLocationForGoogle,
          radius: 300, // outdoor panorama search radius in meters
          source: google.maps.StreetViewSource.OUTDOOR
        })

        if (!data?.location?.latLng)
          throw new Error('Getting pano for POV failed')

        const panoId = data.location.pano
        const panoLocation = googleToVioLatLng(data.location.latLng)

        setPanoProps(currentProps => ({
          pano: panoId,
          options: {
            ...defaultPanoOptions,
            ...currentProps?.options,
            position: newLocationForGoogle,
            pov: {
              heading: computeAngle(panoLocation, newLocation),
              pitch: 0
            }
          }
        }))
      } catch (error) {
        dispatchTeamEvent(
          trackEvent({
            category: Category.System,
            entity: Entity.HotelDetailsStreetView,
            action: Action.Errored,
            analyticsContext: {
              [AnalyticsContext.ErrorContext]: {
                errorType: 'LoadingStreetviewPanoramaFailed',
                errorMessage: error.message
              }
            }
          })
        )
        logEvent('StreetviewError', {message: error.message}, 'error')
        if (typeof Sentry !== 'undefined')
          Sentry.captureException(error, {extra: {feature: 'streetview'}})

        // TODO: once we know which errors are recoverable we could fall back to position instead of killing the overlay
        dispatch(hideOverlay())
      }
    },
    [dispatch, streetViewService]
  )

  // upon load find the nearest outdoor street image for a pinned hotel & turn to the right heading
  const initialLocationSetRef = useRef(false)
  useEffect(() => {
    if (initialLocationSetRef.current) return
    initialLocationSetRef.current = true
    setPositionAndPov(hotel._geoloc)
  }, [hotel._geoloc, setPositionAndPov, streetViewPano])

  /**
   * Cleanup WebGL context and StreetViewPanorama object on unmount to improve performance
   */
  const handleMapUnmount = useCallback(() => {
    setStreetViewPano(null)
  }, [])

  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialLocationForGoogle}
        zoom={14}
        onUnmount={handleMapUnmount}
      >
        {/* Street View Panorama */}
        {panoProps && (
          <StreetViewPanorama
            {...panoProps}
            onLoad={setStreetViewPano}
            onPositionChanged={onPositionChanged}
            onUnmount={handleMapUnmount}
          />
        )}

        {streetViewPano && (
          // @ts-expect-error - We need to provide the pano as map context to OverlayView components but types don't match
          <MapContext.Provider value={streetViewPano}>
            <StreetviewMarkers
              currentHotelId={hotelId}
              currentLocation={currentLocation}
              setPositionAndPov={setPositionAndPov}
            />
          </MapContext.Provider>
        )}
      </GoogleMap>
    </div>
  )
}

const GoogleStreetViewWithLoader = ({hotelId}: Props) => (
  <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}>
    <GoogleStreetView hotelId={hotelId} />
  </LoadScript>
)

export default GoogleStreetViewWithLoader
