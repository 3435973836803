export type ReviewSentimentTranslationKeyType =
  | 'exceptional'
  | 'superb'
  | 'fabulous'
  | 'veryGood'
  | 'good'
  | 'pleasant'
  | 'noRating'

export const getReviewSentimentKey = (
  score: number
): ReviewSentimentTranslationKeyType | void => {
  switch (false) {
    case !(score >= 9.5):
      return 'exceptional'
    case !(score >= 9):
      return 'superb'
    case !(score > 8.5):
      return 'fabulous'
    case !(score >= 8):
      return 'veryGood'
    case !(score >= 7):
      return 'good'
    case !(score >= 6):
      return 'pleasant'
    case !(score <= 0):
      return 'noRating'
  }
}
