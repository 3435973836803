import {ReactElement} from 'react'
import {useSelector} from 'react-redux'

import {AvailabilityHotelEntity} from '../../../../availability/types'
import {dateFormat} from '../../../../utils/date'
import {
  getMonthAvailabilityPrices,
  getMonthAvailabilityStatus,
  State
} from '../selectors'
import {ColorPriceCalendarRequestStatus} from '../slice'

export interface PriceColorDayData {
  hotelAvailabilityPrices: AvailabilityHotelEntity
  searchComplete: boolean
}

interface Props {
  day: Date
  children: (props: PriceColorDayData) => ReactElement
}

/**
 * An HOC that provides the hotel availability prices and completion status for a given day. The results are passed as props to the children.
 * @param day - The day for which the hotel availability prices are requested.
 * @returns The children with the hotel availability prices and completion status as props.
 */
export const WithColorPriceDayData = ({day, children}: Props) => {
  const month = dateFormat(day, 'yyyy-MM')

  const hotelAvailabilityPrices = useSelector((state: State) =>
    getMonthAvailabilityPrices(state, month)
  )
  const monthsAvailabilityStatus = useSelector((state: State) =>
    getMonthAvailabilityStatus(state, month)
  )

  return children({
    hotelAvailabilityPrices: hotelAvailabilityPrices,
    searchComplete:
      monthsAvailabilityStatus === ColorPriceCalendarRequestStatus.COMPLETED
  })
}
