import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {CardGallery} from 'components/CardGallery'
import {HotelCardContext} from 'components/HotelCardContext'
import placeHolderImage from 'images/placeholder-hotel-image.svg'
import {getHotel} from 'modules/sapiSearch/selectors'
import {imageProvider} from 'utils/imageProvider'

import {Box} from '@daedalus/atlas/helpers/Box'

import {GalleryLoading} from './GalleryLoading'

interface Props {
  /** Whether the card is the priority card (card in the first position of the list) */
  isPriorityCard?: boolean
}

export const Gallery: React.FC<Props> = ({isPriorityCard}) => {
  const {hotelId} = useContext(HotelCardContext)

  const hotel = useSelector(state => getHotel(state, hotelId))

  if (!hotel) return <GalleryLoading />

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CardGallery
        images={hotel.imageURIs}
        imageProvider={imageProvider}
        mainImageSize="extraLarge"
        placeHolderImage={placeHolderImage}
        isPriorityCard={isPriorityCard}
      />
    </Box>
  )
}
