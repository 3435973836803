import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {
  Source,
  trackSystemPageErrored
} from 'middleware/analytics/actions/trackSystemPageErrored'
import {history} from 'utils'

import {
  getSearchApiErrorMessage,
  useGetHotelQuery
} from '@daedalus/core/src/sapi/services/searchApi'

/**
 * Handle hotel fetch error by redirecting home
 */
export const useRedirectHome = ({hotelId}: {hotelId: string}) => {
  const dispatch = useDispatch()
  const {error} = useGetHotelQuery({hotelId}, {skip: !hotelId})

  useEffect(() => {
    const redirectHome = (errorMessage: string) => {
      dispatch(
        trackSystemPageErrored({
          payload: {
            source: Source.Routes,
            message: errorMessage
          }
        })
      )
      history.push('/')
    }

    if (!hotelId) redirectHome(`Page not found: ${window.location.href}`)
    if (error)
      redirectHome(
        `Error fetching hotel "${hotelId}": ${getSearchApiErrorMessage(error)}`
      )
  }, [hotelId, error, dispatch])
}
