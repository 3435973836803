import React from 'react'

import {Box} from '@daedalus/atlas/helpers/Box'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

import {GALLERY_WIDTH as GALLERY_WIDTH_EXPERIMENTAL} from '../ExperimentalNoOffersCard/constants'
import {useIsExperimentalNoOffersCardEnabled} from '../ExperimentalNoOffersCard/helpers'
import {
  GALLERY_WIDTH,
  TOP_SECTION_MIN_HEIGHT
} from '../HotelCardLayout/HotelCardLayout'

export const GalleryLoading = () => {
  const isExperimentalCardEnabled = useIsExperimentalNoOffersCardEnabled()

  return (
    <Box width="100%" height="100%" overflow="hidden" position="relative">
      {useIsExperimentalNoOffersCardEnabled() ? (
        <SkeletonLoader
          style={{height: '100%'}}
          width={
            isExperimentalCardEnabled
              ? GALLERY_WIDTH_EXPERIMENTAL
              : GALLERY_WIDTH
          }
        />
      ) : (
        <SkeletonLoader
          height={!isExperimentalCardEnabled && TOP_SECTION_MIN_HEIGHT}
          width={
            isExperimentalCardEnabled
              ? GALLERY_WIDTH_EXPERIMENTAL
              : GALLERY_WIDTH
          }
        />
      )}
    </Box>
  )
}
