import {useCallback} from 'react'
import {useSelector} from 'react-redux'
import {getAnalyticsPageName, getAnonymousId} from 'modules/meta/selectors'
import {
  getDeepLinkSearchParameters,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'
import {
  getEpvUrlParam,
  getEsdUrlParam,
  getIsUserLocationSearch
} from 'modules/search/selectors'

import {Page} from '@daedalus/core/src/analytics/types/Events'
import {getPersistedVclidValue} from '@daedalus/core/src/analytics/utils/vclidCookie'
import {generateAppDownloadUrl} from '@daedalus/core/src/deepLinking/generateAppDownloadUrl'
import {
  DeepLinkSearchParams,
  OneLinkURLs,
  Placement
} from '@daedalus/core/src/deepLinking/types'
import {SearchParameters} from '@daedalus/core/src/sapi/types'

type DeepLinkSearchParamsType = Pick<
  SearchParameters,
  'checkIn' | 'checkOut' | 'hotelId' | 'placeId' | 'rooms' | 'searchId'
>

const useGenerateAppDownloadUrl = (currentPage?: Page) => {
  const anonymousId = useSelector(getAnonymousId)
  const searchParameters: DeepLinkSearchParamsType = useSelector(
    getDeepLinkSearchParameters
  )
  const isUserLocationSearch = useSelector(getIsUserLocationSearch)
  const esd = useSelector(getEsdUrlParam)
  const epv = useSelector(getEpvUrlParam)
  const vclid = getPersistedVclidValue()
  const showTotalPrices = useSelector(getShowTotalPrices)
  const page = useSelector(getAnalyticsPageName)

  const generateAppLockedDealDeepLink = useCallback(
    (placement: Placement, params?: DeepLinkSearchParams) => {
      const {clickedOfferRate, currencyCode, ...other} = params || {}
      return generateAppDownloadUrl(
        OneLinkURLs.WEB_TO_APP_OFFERS,
        currentPage || page,
        placement,
        {
          ...searchParameters,
          userLocationSearch: isUserLocationSearch ? 1 : 0,
          showTotal: showTotalPrices ? 1 : 0,
          vclid,
          esd,
          epv,
          anonymousId,
          currencyCode,
          clickedOfferRate,
          ...other
        }
      )
    },
    [
      anonymousId,
      currentPage,
      epv,
      esd,
      isUserLocationSearch,
      page,
      searchParameters,
      showTotalPrices,
      vclid
    ]
  )

  const generateAppStoreDeepLink = useCallback(
    (placement: Placement, params?: DeepLinkSearchParams) => {
      return generateAppDownloadUrl(
        OneLinkURLs.APP_STORE,
        currentPage || page,
        placement,
        {
          vclid,
          anonymousId,
          ...params
        }
      )
    },
    [anonymousId, currentPage, page, vclid]
  )

  const generatePlayStoreDeepLink = useCallback(
    (placement: Placement, params?: DeepLinkSearchParams) => {
      return generateAppDownloadUrl(
        OneLinkURLs.PLAY_STORE,
        currentPage || page,
        placement,
        {
          vclid,
          anonymousId,
          ...params
        }
      )
    },
    [anonymousId, currentPage, page, vclid]
  )

  const generateGeneralDeepLink = useCallback(
    (placement: Placement, params?: DeepLinkSearchParams) => {
      return generateAppDownloadUrl(
        OneLinkURLs.GENERAL,
        currentPage || page,
        placement,
        {
          vclid,
          anonymousId,
          ...params
        }
      )
    },
    [anonymousId, currentPage, page, vclid]
  )

  return {
    generateAppLockedDealDeepLink,
    generateGeneralDeepLink,
    generateAppStoreDeepLink,
    generatePlayStoreDeepLink
  }
}

export default useGenerateAppDownloadUrl
