import {createSlice} from '@reduxjs/toolkit'

import {getCookie, setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {cookieSecuritySettings} from '@daedalus/core/src/_web/utils/cookies/cookieSecuritySettings'
import {getDomainName} from '@daedalus/core/src/utils/url'

import type {PayloadAction} from '@reduxjs/toolkit'

interface OneTimePasswordState {
  isOpen: boolean
  isPhoneVerified: boolean
}

const initialState = {
  isOpen: false,
  isPhoneVerified: getCookie('isPhoneVerified') === 'true'
} as OneTimePasswordState

const {actions, reducer} = createSlice({
  name: 'oneTimePassword',
  initialState,
  reducers: {
    setVisibility(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload
    },
    setIsPhoneVerified(state, action: PayloadAction<boolean>) {
      state.isPhoneVerified = action.payload

      if (action.payload)
        setCookie('isPhoneVerified', 'true', {
          domain: getDomainName(window.location.hostname),
          expires: 7, // 7 days
          ...cookieSecuritySettings(true)
        })
    }
  }
})

export default reducer

export const {setVisibility, setIsPhoneVerified} = actions
