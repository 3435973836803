import {FacetType} from 'types/Search'

import {IconName} from '@daedalus/atlas/Icon'
import {FormattedMessageWrapperProps} from '@daedalus/core/src/localization/components/FormattedMessage'

export enum ColumnType {
  One = 1,
  Two = 2,
  Three = 3
}

export enum RenderType {
  Card = 'CARD',
  List = 'LIST',
  Group = 'GROUP'
}

export type ShowMoreRenderType = 'Accordion' | 'Link'

export type FacetGroupsType = 'popular' | 'hotels' | 'vacationRentals'

export interface FacetGroupHeader {
  title: FormattedMessageWrapperProps
  description: FormattedMessageWrapperProps
  groupIcon: IconName
}

export interface FacetGroupItem {
  type: FacetGroupsType
  facetIds: Set<string>
  header?: FacetGroupHeader
}

export type FacetGroup = Partial<{
  [key in FacetGroupsType]: FacetType[]
}>

export type FacetGroupTitles = Partial<
  Record<FacetGroupsType, FormattedMessageWrapperProps>
>
