import React, {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {getAllFilterKeys} from 'components/Filters/utils'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {showFilters} from 'modules/overlay/actions'

import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

const allFilterKeys = getAllFilterKeys()

export const MobileFiltersButton = () => {
  const {getAppliedFiltersCount} = useFiltersForm(allFilterKeys, 'allFilters')
  const numberOfSelectedFilters = getAppliedFiltersCount()
  const isActive = numberOfSelectedFilters > 0

  const dispatch = useDispatch()

  const handleShowFilters = useCallback(
    (event?: React.SyntheticEvent) => {
      event?.preventDefault()

      dispatch(showFilters())

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.FilterButton,
          action: Action.Clicked,
          component: 'Static',
          payload: {
            selectedElement: 'allFilters'
          }
        })
      )
    },
    [dispatch]
  )

  return (
    <Pill
      fullWidth={false}
      isSelected={isActive}
      onClick={handleShowFilters}
      count={numberOfSelectedFilters}
    >
      <Icon name="SettingsSliders" />
    </Pill>
  )
}
