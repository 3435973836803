import {Room, SplitBooking} from '@daedalus/core/src/room/types/room'

/**
 * Gets analytics properties from SAPI rooms-offers matching data
 * @param rooms - Array of Room objects containing offer information
 * @param splitBooking - Split booking object
 * @returns Object containing:
 *  - hasMatchedOffer: Whether any room has a clicked offer or split booking is clicked
 *  - matchType: The match type from either matched offer or split booking (available only if hasMatchedOffer=true)
 *  - matchedOfferPriceDiff: Price difference from either matched offer or split booking (available only if hasMatchedOffer=true)
 */
export const getRoomsOffersMatchingData = (
  rooms: Room[],
  splitBooking: SplitBooking
) => {
  // We can't rely on rooms.offers.isClicked because that can be cleared on transform lvl
  // this should be fixed in [sc-148585]
  const matchedOffer = rooms
    .flatMap(room => room.offers || [])
    .find(offer => offer.matchType)

  const hasMatchedOffer = Boolean(matchedOffer || splitBooking?.matchType)

  const matchType = matchedOffer?.matchType || splitBooking?.matchType
  const matchedOfferPriceDiff =
    matchedOffer?.matchedOfferPriceDiff ||
    splitBooking?.matchedOfferPriceDiff ||
    0

  return {
    hasMatchedOffer,
    ...(hasMatchedOffer ? {matchType, matchedOfferPriceDiff} : {})
  }
}
