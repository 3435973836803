import React from 'react'

import {SwipePanes} from '@daedalus/atlas/helpers/SwipePanes'
import {Image, SizeType} from '@daedalus/atlas/Image'
import {ImageProvider} from '@daedalus/core/src/staticContent/types/ImageProvider'

import {ImageCenteredWrapper, Wrapper} from './styled'

type PropTypes = {
  /** List of images to be displayed */
  images: string[]
  /** Size to scale images to */
  imageSize?: SizeType
  /** Imaging service for resizing */
  imageProvider: ImageProvider
  /** First pane to display after mount */
  initialImageIndex?: number
  /** Displays dots indicator with shaded gradient */
  hasDarkGradient?: boolean
  /** Displays page number with shade background underneath */
  hasDarkBackground?: boolean
  /** How to scale the image */
  backgroundSize: 'cover' | 'contain'
  /** Show pane counter in the bottom right */
  showPageNumber?: boolean
  /** Show next/previous pane buttons */
  showPaginationButtons?: boolean
  /** Whether to enable pinch-zoom */
  isZoomEnabled?: boolean
  /** Whether we're in an RTL language */
  isRtl: boolean
  /** Triggered whenever the user swipes the gallery */
  onSwipe?: () => void
  /** Triggered whenever the user zooms the gallery */
  onZoom?: () => void
  /** Handle overlay close */
  onClose?: () => void
  /** URL of the image placeholder in case of broken images */
  placeHolderImage?: string
  /** Fallback for handling error on image */
  handleImageError?: () => void
  /** Shows pagination buttons when pane is hovered */
  showPaginationOnHover?: boolean
}

const makeRenderImagePane = (
  images: string[],
  imageProvider: ImageProvider,
  size: SizeType,
  backgroundSize: 'contain' | 'cover',
  placeHolderImage?: string,
  onImageError?: () => void
) =>
  function renderImagePage(index: number) {
    return images ? (
      <ImageCenteredWrapper>
        <Image
          fullWidth
          isResponsive
          imageProvider={imageProvider}
          size={size}
          url={images[index]}
          backgroundSize={backgroundSize}
          placeHolderImage={placeHolderImage}
          hasSkeletonLoader
          onImageError={onImageError}
        />
      </ImageCenteredWrapper>
    ) : null
  }

export const SwipeGallery = ({
  images,
  imageProvider,
  initialImageIndex = 0,
  imageSize = 'gridPrimary',
  showPageNumber = false,
  hasDarkGradient = true,
  hasDarkBackground = false,
  showPaginationButtons = false,
  isZoomEnabled = true,
  isRtl,
  backgroundSize,
  onSwipe,
  onZoom,
  onClose = () => null,
  placeHolderImage,
  handleImageError,
  showPaginationOnHover = false
}: PropTypes) => {
  if (!images?.length) return null

  return (
    <Wrapper>
      <SwipePanes
        panesAmount={images?.length}
        renderPane={makeRenderImagePane(
          images,
          imageProvider,
          imageSize,
          backgroundSize,
          placeHolderImage,
          handleImageError
        )}
        initialPaneIndex={initialImageIndex}
        showPageNumber={showPageNumber}
        hasDarkGradient={hasDarkGradient}
        hasDarkBackground={hasDarkBackground}
        showPaginationButtons={showPaginationButtons}
        isZoomEnabled={isZoomEnabled}
        isRtl={isRtl}
        onSwipe={onSwipe}
        onZoom={onZoom}
        onClose={onClose}
        showPaginationOnHover={showPaginationOnHover}
      />
    </Wrapper>
  )
}
