import React from 'react'

import {Flex} from '@daedalus/atlas/helpers/Flex'
import {Brand} from '@daedalus/core/src/brand/types'
import {BrandLogo} from '@daedalus/shared/src/whiteLabel/BrandLogo'

interface Props {
  brand: Brand
}

export const FooterCopyrightSection = ({brand}: Props) => {
  const currentYear = new Date().getFullYear()
  return (
    <Flex.Row justifyContent="space-between" alignItems="center">
      <div>
        <BrandLogo brand={brand} height={24} withWhiteText />
      </div>
      <div className="chromatic-ignore">
        © {currentYear} {brand.name}
      </div>
    </Flex.Row>
  )
}
