import {createSlice, PayloadAction, prepareAutoBatched} from '@reduxjs/toolkit'
import cookieConfig, {
  ANCHOR_HOTEL_CHAINS_VIEWED_KEY,
  ANCHOR_HOTELS_VIEWED_KEY
} from 'config/cookies/chainCount'
import {Hotel, SearchOffer, searchStarted} from 'modules/sapiSearch/slice'

import {getCookie, setCookie} from '@daedalus/core/src/_web/utils/cookies'

export interface AnalyticsState {
  offersViewed: Array<SearchOffer['id']>
  anchorHotelsViewed: Array<Hotel['objectID']>
  anchorHotelChainsViewed: Array<Hotel['chainID']>
  screenshotTaken: boolean
}

export interface SetOfferViewedPayload {
  offerId: SearchOffer['id']
}

export interface SetAnchorHotelViewedPayload {
  hotelId: Hotel['objectID']
  chainID: Hotel['chainID']
  parentChainID: Hotel['parentChainID']
}

const getChainCountCookie = (): Record<string, string[]> => {
  const chainCountCookie = getCookie(cookieConfig.name)
  return chainCountCookie ? JSON.parse(chainCountCookie) : {}
}

const getInitialAnchorHotelsViewed = (): string[] => {
  const chainCountCookie = getChainCountCookie()
  return chainCountCookie[ANCHOR_HOTELS_VIEWED_KEY] ?? []
}

const getInitialAnchorHotelChainsViewed = (): string[] => {
  const chainCountCookie = getChainCountCookie()
  return chainCountCookie[ANCHOR_HOTEL_CHAINS_VIEWED_KEY] ?? []
}

const persistChainCount = (
  anchorHotelsViewed: string[],
  anchorHotelChainsViewed: string[]
) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  setCookie(
    cookieConfig.name,
    {
      [ANCHOR_HOTELS_VIEWED_KEY]: anchorHotelsViewed,
      [ANCHOR_HOTEL_CHAINS_VIEWED_KEY]: anchorHotelChainsViewed
    },
    {
      expires,
      domain,
      secure,
      sameSite
    }
  )
}

export const initialState: AnalyticsState = {
  offersViewed: [],
  anchorHotelsViewed: getInitialAnchorHotelsViewed(),
  anchorHotelChainsViewed: getInitialAnchorHotelChainsViewed(),
  screenshotTaken: false
}

const {actions, reducer} = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setOfferViewed: {
      reducer(state, {payload}: PayloadAction<SetOfferViewedPayload>) {
        const {offerId} = payload
        const newOffersViewed = [...(state.offersViewed ?? [])]
        if (offerId.length > 0) {
          newOffersViewed.push(offerId)
        }

        state.offersViewed = newOffersViewed
      },
      prepare: prepareAutoBatched<SetOfferViewedPayload>()
    },
    setAnchorHotelViewed: (
      state,
      {payload}: PayloadAction<SetAnchorHotelViewedPayload>
    ) => {
      const {hotelId, chainID, parentChainID} = payload
      const newAnchorHotelsViewed = [...(state.anchorHotelsViewed ?? [])]
      const newChainsViewed = [...(state.anchorHotelChainsViewed ?? [])]

      if (hotelId.length > 0 && !newAnchorHotelsViewed.includes(hotelId)) {
        newAnchorHotelsViewed.push(hotelId)

        if (chainID) {
          newChainsViewed.push(chainID)
        }

        if (parentChainID) {
          newChainsViewed.push(parentChainID)
        }
      }

      state.anchorHotelsViewed = newAnchorHotelsViewed
      state.anchorHotelChainsViewed = newChainsViewed

      persistChainCount(newAnchorHotelsViewed, newChainsViewed)
    },
    setScreenshotTaken: state => {
      state.screenshotTaken = true
    }
  },
  extraReducers: {
    [searchStarted.type]: state => {
      // Each offer viewed impression is per search
      state.offersViewed = []
    }
  }
})

export const {setOfferViewed, setAnchorHotelViewed, setScreenshotTaken} =
  actions

export default reducer
