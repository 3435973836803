import React from 'react'
import {Footer} from 'components/Accommodation/Footer'
import {BottomCTA} from 'components/Accommodation/Offers/BottomCTA'
import {useIsAccommodationFooterVisible} from 'hooks/useIsAccommodationFooterVisible'

type Props = {
  hotelId: string
}

const OFFER_SOURCE_COMPONENT = 'MobileHotelViewAPStaticOfferCTA'

export const AccommodationFooterContainer = ({hotelId}: Props) => {
  const isFooterVisible = useIsAccommodationFooterVisible({hotelId})
  return (
    <Footer isFooterVisible={isFooterVisible}>
      <BottomCTA hotelId={hotelId} sourceComponent={OFFER_SOURCE_COMPONENT} />
    </Footer>
  )
}
