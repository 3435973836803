import {
  CalculatedSplitBookingPrice,
  SplitBookingBundleType,
  SplitBookingDetails
} from 'components/Offer/types'
import {sapiSBOtoSearchSBO} from 'components/SplitBookings/helpers'
import {SELECTOR_MAX_CACHE_ENTRIES} from 'config/selectorCaching'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import parseISO from 'date-fns/parseISO'
import {
  getDeviceLayout,
  getIsReturningUser,
  getLocalTaxesIncluded,
  getTaxesIncluded,
  getUserCountryCode
} from 'modules/meta/selectors'
import {getPopupOnExitVisibility} from 'modules/popupOnExit/slice'
import {HOSTEL_PROPERTY_TYPE_ID, MAX_NO_RESULT} from 'modules/search/constants'
import {
  getIsHostelsFilterSelected,
  getIsMultiRoomBundlesSearch,
  getIsOptimizeRooms,
  getIsOTAMode,
  getNoHostels,
  getUrlParams,
  isSortAscendingByPrice
} from 'modules/search/selectors'
import {
  getSearchBoxSearchType,
  getSearchBoxValues
} from 'modules/searchBox/selectors'
import {getValidSearchBoxValues} from 'modules/searchBox/utils'
import {toggle} from 'opticks'
import {findLastIndex, isEmpty} from 'ramda'
import {createSelectorCreator, defaultMemoize} from 'reselect'
import Settings from 'Settings'
import {RootState} from 'store'
import {getIsCountryExcludedFromAppLockedDeals} from 'utils/countriesExcludedFromAppLockedDeals'
import {
  calculateSavingsPercentageByNightlyRate,
  getCheapestOfferTotalRate,
  getCheapestTopOfferByNightlyRate,
  getHighestVisiblePrice as getHighestPrice
} from 'utils/offers'
import {getIsFromGha, getIsFromGVR} from 'utils/trafficSource'

import {getAppLockedAnchorDealImpressionsCount} from '@daedalus/core/src/_web/appLockedDeal/impressionsCount/getAppLockedAnchorDealImpressionsCount'
import {getLastAppLockedAnchorDealImpressionsTimestamp} from '@daedalus/core/src/_web/appLockedDeal/impressionsCount/getLastAppLockedAnchorDealImpressionsTimestamp'
import {getShouldSeeOffersUnlocked} from '@daedalus/core/src/auth/modules/selectors'
import {AvailabilityHotelEntity} from '@daedalus/core/src/availability/types'
import {Placement} from '@daedalus/core/src/deepLinking/types'
import {calculateTotalRate} from '@daedalus/core/src/offer/business/calculateTotalRate'
import isBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {isSplitBookingPriceEligible} from '@daedalus/core/src/offer/business/isSplitBookingPriceEligible'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {
  calculateNightlyRate,
  PriceRateBreakdown
} from '@daedalus/core/src/offer/business/offersMapping'
import {offersHasPrivateDeal} from '@daedalus/core/src/offer/business/privateDeals'
import {
  GenericOffer,
  OfferPromoKey,
  OfferTagLabel
} from '@daedalus/core/src/offer/types/offer'
import {
  numberOfGuests,
  numberOfRooms
} from '@daedalus/core/src/room/business/roomConfiguration'
import {calculateDistance} from '@daedalus/core/src/utils/geolocation/calculateDistance'
import {toPercentage} from '@daedalus/core/src/utils/number'
import {
  applyRoundingStrategy,
  getRoundingStrategy
} from '@daedalus/core/src/utils/number'

import {
  calculateGreatDealBoundary,
  calculatePredictedVsCheapestRateDelta
} from '../search/utils'
import {
  AnchorType,
  Hotel,
  HotelOfferEntity,
  ModuleState,
  SearchOffer,
  State as SapiSearchState
} from './slice'
import {
  calculateHotelHasOffers,
  getAnchorPrice as getAnchorPriceFromOfferEntity,
  getSearchTypeFromSearchParameters,
  hotelOfferEntitiesHaveOffers,
  mapHotelToDaedalusFormat,
  SearchType
} from './utils'

export interface State extends RootState {
  sapiSearch: SapiSearchState
}

export interface AppLockedDealConfig {
  isAppLockedDeal: boolean
  appDownloadLinkId?: Placement
}

/**
 * Creates a selector with multiple cache entries to let selectors with parameters (ie. hotelId or offerId) get cached properly
 */
const createSelector = createSelectorCreator(defaultMemoize, {
  maxSize: SELECTOR_MAX_CACHE_ENTRIES
})

/**
 * Shortcut to access the hotelId
 */
const getHotelIdParam = (_, hotelId: Hotel['objectID']) => hotelId

/**
 * Shortcut to access the offerId
 */
const getOfferIdParam = (_, __, offerId: HotelOfferEntity['id']) => offerId

/**
 * Returns an object containing all offer entities for the current search
 */
const getHotelOfferEntities = ({sapiSearch}: State) =>
  sapiSearch.hotelOfferEntities

/**
 * Determines whether any of the passed in `hotelOfferEntities` have offers
 */
export const getHotelOfferEntitiesHaveOffers = ({sapiSearch}: State) =>
  hotelOfferEntitiesHaveOffers(sapiSearch.hotelOfferEntities)

/**
 * Returns hotel offer entity by a provided hotel id
 * @param hotelId - id of the hotel
 * @param hotelOfferEntities - the object containing all offer entities
 */
const getHotelOfferEntityByHotelId = (
  hotelId: string | number,
  hotelOfferEntities: Record<string | number, HotelOfferEntity>
): HotelOfferEntity | undefined => hotelOfferEntities?.[hotelId] // SAPI_TODO: optional chaining for sapiSearch here as we call this selector on the A side as well for tracking

/**
 * Returns hotel offer entity by hotel id
 * @param sapiSearch - sapiSearch state
 * @param hotelId - id of the hotel
 */
export const getHotelOfferEntity = createSelector(
  [getHotelIdParam, getHotelOfferEntities],
  (hotelId, hotelOfferEntities): HotelOfferEntity | undefined =>
    getHotelOfferEntityByHotelId(hotelId, hotelOfferEntities)
)

/**
 * Returns cheapest offer based on nightly rate from a hotel offer entity by hotel id
 * @param sapiSearch - sapiSearch state
 * @param hotelId - id of the hotel
 */
const getHotelCheapestOffer = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => getCheapestTopOfferByNightlyRate(hotelOfferEntity?.offers)
)

/**
 * Returns an object containing all hotel entities for the current search
 */
export const getHotelEntities = ({sapiSearch}: State) =>
  sapiSearch.hotelEntities

/**
 * Returns hotel entity by hotel id if exists using the actual data dependencies
 * This function is not a selector itself but will be used by selectors.
 * @param sapiSearch - sapiSearch state
 * @param hotelId - id of the hotel
 */
const getHotelById = (
  hotelId: string | undefined,
  hotelEntities: SapiSearchState['hotelEntities']
): Hotel | undefined => {
  return hotelEntities?.[hotelId]
}

/**
 * Returns hotel entity by hotel id if exists
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getHotel = createSelector(
  [getHotelIdParam, getHotelEntities],
  (hotelId, hotelEntities): Hotel | undefined =>
    getHotelById(hotelId, hotelEntities)
)

export const getHotelName = createSelector(
  [getHotel],
  hotel => hotel?.hotelName
)

export const getHotelCity = createSelector(
  [getHotel],
  hotel => hotel?.navPathInfo?.city?.name
)

export const getHotelAddress = createSelector(
  [getHotel],
  hotel => hotel?.displayAddress
)

export const getHotelImageURIs = createSelector(
  [getHotel],
  hotel => hotel?.imageURIs
)

export const getHotelStarRating = createSelector(
  [getHotel],
  hotel => hotel?.starRating
)

/**
 * Returns hotel entity's regularPriceRange
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getHotelRegularPriceRange = createSelector(
  [getHotelIdParam, getHotelEntities],
  (hotelId, hotelEntities) => hotelEntities?.[hotelId]?.regularPriceRange
)

/**
 * Returns current module state
 */
export const getModuleState = createSelector(
  [(state: State) => state.sapiSearch],
  sapiSearch => sapiSearch.moduleState
)

/**
 * Returns current search ID
 */
export const getSearchId = (state: State) => state.sapiSearch.searchId

/**
 * Returns current page index
 */
export const getCurrentPage = (state: State) => state.sapiSearch.page

/**
 * Returns current page as 1-indexed number
 */
export const getCurrentPageNumber = (state: State) =>
  (getCurrentPage(state) ?? 0) + 1

/**
 * Returns current page offset
 */
export const getCurrentPageOffset = createSelector([getCurrentPage], page => {
  const pageSize: number = Settings.get('CLIENT_SEARCH_PAGE_SIZE') ?? 20

  return page * pageSize
})

/**
 * Returns total number of static (hotel) results available for current search
 */
export const getTotalCountOfResults = ({sapiSearch}: State) =>
  sapiSearch.resultsCountTotal ?? 0

/**
 * Returns search anchor object
 */
export const getAnchor = createSelector(
  [(state: State) => state.sapiSearch.anchor],
  anchor => anchor
)

/**
 * Returns type of search anchor
 */
export const getAnchorType = ({sapiSearch}: State) => sapiSearch.anchorType

/**
 * Returns anchor hotel ID if exists
 */
const getAnchorHotelIdValue = ({sapiSearch}: State) => sapiSearch?.anchorHotelId

export const getAnchorHotelId = createSelector(
  [getAnchorHotelIdValue],
  anchorHotelId => anchorHotelId
)

/**
 * Returns anchor hotel if exists
 */
export const getAnchorHotel = createSelector(
  [getAnchorHotelId, getHotelEntities],
  (anchorHotelId, hotelEntities) => getHotelById(anchorHotelId, hotelEntities)
)

/**
 * Returns anchor hotel name if exists
 */
export const getAnchorHotelName = createSelector(
  [getAnchorHotel],
  anchorHotel => anchorHotel?.hotelName
)

/**
 * Returns anchor hotel country code if exists
 */
export const getAnchorHotelCountry = createSelector(
  [getAnchorHotel],
  anchorHotel => anchorHotel?.country
)

/**
 * Returns boolean if the anchor hotel is avaible or not (has offers) for the current search
 * or undefined if not an anchor hotel search
 * @param state - redux state object
 */
export const getIsAnchorHotelAvailable = createSelector(
  [getAnchorHotelId, getHotelOfferEntities],
  (anchorHotelId, hotelOfferEntities): boolean | undefined => {
    if (!anchorHotelId) return undefined
    const anchorHotelOfferEntity = getHotelOfferEntityByHotelId(
      anchorHotelId,
      hotelOfferEntities
    )
    return Boolean(anchorHotelOfferEntity?.offers?.length)
  }
)

/**
 * Returns flatten list of hotel ids after applying unavailability filter
 */
export const getHotelIds = createSelector(
  [({sapiSearch}: State) => sapiSearch.hotelIds],
  hotelIds => hotelIds?.flat()
)

/**
 * Returns flatten list of hotel ids without filtering by unavailability
 */
export const getHotelIdsRaw = createSelector(
  [({sapiSearch}: State) => sapiSearch.hotelIdsRaw],
  hotelIdsRaw => hotelIdsRaw?.flat()
)

/**
 * Returns flatten list of available hotel ids.
 * Does not include the anchor hotel
 */
export const getHotelIdsToDisplay = createSelector(
  [getHotelIds, getCurrentPage],
  (hotelIds, page) => {
    const pageSize: number = Settings.get('CLIENT_SEARCH_PAGE_SIZE') ?? 20
    const from = 0
    const to = page * pageSize + pageSize
    const hotelIdsCopy = hotelIds ? [...hotelIds] : []

    return hotelIdsCopy.slice(from, to)
  }
)

/**
 * Returns flat list of hotel ids including anchor hotel
 */
export const getHotelIdsToDisplayIncludingAnchor = createSelector(
  [getHotelIdsToDisplay, getAnchorHotelId],
  (hotelIds, anchorHotelId) =>
    anchorHotelId ? [anchorHotelId, ...hotelIds] : hotelIds
)

/**
 * Checks if current search has available results
 */
export const getIsSearchHasResults = createSelector([getHotelIds], hotelIds => {
  const hotelIdsCopy = hotelIds ? [...hotelIds] : []

  return hotelIdsCopy.length > 0
})

/**
 * Returns the first hotel in the list
 */
export const getFirstHotel = createSelector(
  [getHotelIdsToDisplay, getHotelEntities],
  (hotelIds, hotelEntities) => {
    const firstHotel = getHotelById(hotelIds?.[0], hotelEntities)
    return firstHotel
  }
)

/**
 * Returns the country code of the first available hotel in the list
 */
export const getFirstHotelCountry = createSelector(
  [getFirstHotel],
  firstHotel => {
    return firstHotel?.country
  }
)

/**
 * Returns the length of current page/set of displayed results
 */
export const getCurrentPageLength = createSelector(
  [getHotelIdsToDisplay],
  hotelIdsToDisplay => hotelIdsToDisplay?.length ?? 0
)

/**
 * Returns search parameters for current search
 */
export const getSearchParameters = createSelector(
  [(state: State) => state.sapiSearch?.searchParameters],
  searchParameters => searchParameters
)

export const getSearchParametersRooms = createSelector(
  [(state: State) => state.sapiSearch?.searchParameters?.rooms],
  rooms => rooms
)

export const getSearchParametersCheckIn = createSelector(
  [(state: State) => state.sapiSearch?.searchParameters?.checkIn],
  checkIn => checkIn
)

export const getSearchParametersCheckOut = createSelector(
  [(state: State) => state.sapiSearch?.searchParameters?.checkOut],
  checkOut => checkOut
)

/**
 * Returns some of the search parameters for deep link generation
 */
export const getDeepLinkSearchParameters = createSelector(
  [(state: State) => state.sapiSearch?.searchParameters],
  searchParameters => {
    const {checkIn, checkOut, hotelId, placeId, rooms, searchId} =
      searchParameters ?? {}
    return {
      checkIn,
      checkOut,
      hotelId,
      placeId,
      rooms,
      searchId
    }
  }
)

/**
 * Returns search type for current search
 */
export const getSearchType = createSelector(
  [getSearchParameters],
  searchParameters => getSearchTypeFromSearchParameters(searchParameters)
)

/**
 * Returns whether user has applied any filters
 */
export const getIsFiltersApplied = createSelector(
  [getSearchParameters],
  searchParameters => {
    const filters = searchParameters?.filters
    return filters && !isEmpty(filters)
  }
)

/**
 * Returns only the search parameters that are used in the search box
 */
export const getSearchParametersForSearchBox = createSelector(
  [getSearchParameters],
  searchParameters => getValidSearchBoxValues(searchParameters ?? {})
)

/**
 * Returns whether search has more results than already displayed
 */
export const getHasMoreResults = createSelector(
  [(state: State) => state.sapiSearch.hasMoreResults],
  Boolean
)

/**
 * Returns whether website is waiting for the hotels data from SAPI (anchor + hotels)
 */
export const getIsWaitingForHotelsData = createSelector(
  [getModuleState],
  moduleState =>
    [ModuleState.Idle, ModuleState.HotelsPending].includes(moduleState)
)

/**
 * Returns whether current (first/landing) search is in progress
 */
export const getIsSearchPending = createSelector(
  [getModuleState, getCurrentPage],
  (moduleState, currentPage) => {
    if (currentPage > 0) return false

    return [
      ModuleState.Idle,
      ModuleState.HotelsPending,
      ModuleState.OffersPending
    ].includes(moduleState)
  }
)

/**
 * Returns whether website is waiting for the hotels offers data
 */
export const getIsOffersPending = createSelector(
  [getModuleState],
  (moduleState): boolean => moduleState === ModuleState.OffersPending
)

/**
 * Returns whether website is finished fetching hotels and rates (for the first or subsequent pages)
 */
export const getIsSearchCompleted = createSelector(
  [getModuleState],
  (moduleState): boolean => moduleState === ModuleState.SearchCompleted
)

/**
 * Returns whether load more hotels (pagination) is in progress
 */
export const getIsLoadMoreResultsPending = createSelector(
  [getModuleState, getCurrentPage],
  (moduleState, currentPage) => {
    return (
      moduleState === ModuleState.MoreHotelsPending ||
      (currentPage !== 0 && moduleState !== ModuleState.SearchCompleted)
    )
  }
)

/**
 * Returns whether the specified hotel id is the anchor hotel
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getIsAnchorHotel = createSelector(
  [getHotelIdParam, getAnchorHotelId, getUrlParams],
  (hotelId, anchorHotelId, urlParams) => {
    const {isAnchorHotelSelectedInSRP} = urlParams
    if (isAnchorHotelSelectedInSRP) return isAnchorHotelSelectedInSRP === '1'
    return hotelId === anchorHotelId
  }
)

/**
 * Get hotel offer entity by hotelId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getOfferEntity = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => hotelOfferEntity
)

/**
 * Get hotel offer entity by hotelId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getRoomsConfigFromOfferEntity = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => hotelOfferEntity?.roomsConfig
)

/**
 * Get rooms string from hotel offer entity if optimizeRooms otherwise from searchParameters
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */

export const getOptimizedRoomsConfig = createSelector(
  [getHotelOfferEntity, getIsOptimizeRooms, getSearchParametersRooms],
  (hotelOfferEntity, optimizeRooms, rooms) => {
    return !optimizeRooms || (hotelOfferEntity && !hotelOfferEntity.roomsConfig)
      ? rooms
      : hotelOfferEntity?.roomsConfig
  }
)

/**
 * Get hotels first top offer by hotelId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getHotelTopOffer = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity =>
    hotelOfferEntity ? hotelOfferEntity.offers[0] : undefined
)

/**
 * Get hotels top offers by hotelId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getHotelOfferEntityOffers = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => hotelOfferEntity?.offers
)

/**
 * Get the cheapest offer and check if either of the other two offers have the same price. If they do, it is parity
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getCheapestOfferHasPriceParity = createSelector(
  [getHotelOfferEntityOffers],
  hotelTopOffers => {
    const roundingStrategy = getRoundingStrategy()
    const cheapestOffer = getCheapestTopOfferByNightlyRate(hotelTopOffers)

    if (!hotelTopOffers || !cheapestOffer) return true

    const otherOffers = hotelTopOffers.filter(
      offer => offer.id !== cheapestOffer.id
    )

    const cheapestOfferRoundedPrice = applyRoundingStrategy(
      roundingStrategy,
      cheapestOffer.nightlyRate
    )

    return otherOffers.some(offer => {
      return (
        cheapestOfferRoundedPrice ===
        applyRoundingStrategy(roundingStrategy, offer.nightlyRate)
      )
    })
  }
)

/**
 * Get the cheapest offer and check if it is tagged as preferred rate
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
const getIsCheapestOfferPreferredRate = createSelector(
  [getHotelOfferEntityOffers],
  hotelTopOffers => {
    const cheapestOffer = getCheapestTopOfferByNightlyRate(hotelTopOffers)
    return hasOfferTag(cheapestOffer, OfferTagLabel.IsYourChoice)
  }
)

/**
 * Get the cheapest offer and check if it meets the savings threshold of 5%. The threshold is the difference between the cheapest offer and the second cheapest offer.
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
const getCheapestOfferMeetsSavingsThreshold = createSelector(
  [getHotelOfferEntityOffers],
  hotelTopOffers => {
    const savingsPercentage =
      calculateSavingsPercentageByNightlyRate(hotelTopOffers)

    return savingsPercentage >= 5
  }
)

export const getHotelAvailabilityPrices = (
  state,
  hotelId: string
): AvailabilityHotelEntity => {
  return state?.sapiSearch?.hotelAvailabilityPrices?.[hotelId]
}

export const getHotelAvailabilityPricesComplete = state =>
  state?.sapiSearch?.hotelAvailabilityPendingRequests === 0

const getAnchorCompleteValue = (state: State) =>
  state?.sapiSearch?.anchorComplete

export const getAnchorComplete = createSelector(
  [getAnchorCompleteValue],
  anchorComplete => anchorComplete
)

/**
 * Get all offers from stored hotel availability prices data
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getAvailabilityOffers = createSelector(
  [getHotelAvailabilityPrices],
  hotelAvailabilityPrices => {
    if (!hotelAvailabilityPrices) return []
    const entities = Object.values(hotelAvailabilityPrices)
    return entities.flatMap(entity => entity.offers)
  }
)

/**
 * Return an offer by hotelId and offerId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel containing the offer you require
 * @param offerId - id of the offer you require
 */
export const getOffer = createSelector(
  [
    (_, __, offerId: HotelOfferEntity['id']) => offerId,
    getHotelOfferEntity,
    getAvailabilityOffers
  ],
  (offerId, hotelOfferEntity, availabilityOffers): SearchOffer => {
    const offers = [hotelOfferEntity?.offers || [], availabilityOffers].flat()

    const splitBookingOffers =
      (hotelOfferEntity?.splitBooking?.offers?.map(
        o => o.offer
      ) as SearchOffer[]) || []

    const promos = Object.values(hotelOfferEntity?.promos || {})
    const allOffers = [...offers, ...splitBookingOffers, ...promos]

    return allOffers?.find(offer => offer.id === offerId)
  }
)

/**
 * Get whether the hotel has offers
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getHotelHasOffers = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => calculateHotelHasOffers(hotelOfferEntity)
)

/**
 * Get whether the hotel has more than one offer
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getHotelHasMoreThanOneOffer = createSelector(
  [getHotelOfferEntity, getIsOTAMode],
  (hotelOfferEntity, isOTAMode) =>
    isOTAMode ? false : Boolean(hotelOfferEntity?.offers?.[1])
)

/**
 * Returns whether search results contain hostels
 */
export const getDoResultsHaveHostels = createSelector(
  [(state: State) => state.sapiSearch.hotelEntities],
  hotelEntities => {
    if (hotelEntities === undefined) return false

    return Object.keys(hotelEntities).some(
      key =>
        hotelEntities[key]?.propertyTypeId === Number(HOSTEL_PROPERTY_TYPE_ID)
    )
  }
)

export const getShowHostelsAlert = createSelector(
  [
    getDoResultsHaveHostels,
    getNoHostels,
    isSortAscendingByPrice,
    getIsHostelsFilterSelected
  ],
  (
    doResultsHaveHostels,
    noHostels,
    isSortAscendingByPrice,
    isHostelsFilterApplied
  ) => {
    if (isHostelsFilterApplied || (!doResultsHaveHostels && !noHostels)) {
      return false
    }

    return isSortAscendingByPrice
  }
)

/**
 * Returns facets for the current search
 */
export const getFacets = createSelector(
  [(state: State) => state.sapiSearch.facets],
  facets => facets
)

/**
 * Get anchor hotel entity
 *
 * Add as part of 366b54ed-price-difference-popup
 */
export const getAnchorHotelOfferEntity = createSelector(
  [getAnchorHotelId, getHotelOfferEntities],
  (anchorHotelId, hotelOfferEntities) =>
    getHotelOfferEntityByHotelId(anchorHotelId, hotelOfferEntities)
)

/**
 * Returns whether anchor hotel has private deal
 */
export const getAnchorHotelHasPrivateDeal = createSelector(
  [getAnchorHotelOfferEntity, getShouldSeeOffersUnlocked],
  (anchorHotelOfferEntity, shouldSeeOffersUnlocked) => {
    return offersHasPrivateDeal(
      anchorHotelOfferEntity?.offers,
      shouldSeeOffersUnlocked
    )
  }
)

/**
 * Determines the indices of hotels with private deals based on display hotel IDs and user privileges.
 * @param getHotelIdsToDisplay - Selector that retrieves a list of hotel IDs to display.
 * @param getShouldSeeOffersUnlocked - Selector that indicates if the user should see unlocked offers.
 * @param getHotelOfferEntities - Selector that retrieves all offer entities for the current search.
 * @returns An array of indices corresponding to hotels with private deals.
 */
export const getHotelIndicesWithPrivateDeal = createSelector(
  [getHotelIdsToDisplay, getShouldSeeOffersUnlocked, getHotelOfferEntities],
  (hotelIds, shouldSeeOffersUnlocked, hotelOfferEntities) => {
    const hotelsWithPDIndices = []

    for (const [idx, hotelId] of hotelIds.entries()) {
      const hotelOffer = getHotelOfferEntityByHotelId(
        hotelId,
        hotelOfferEntities
      )
      const hasPrivateDeal = offersHasPrivateDeal(
        hotelOffer?.offers,
        shouldSeeOffersUnlocked
      )
      if (hasPrivateDeal) {
        hotelsWithPDIndices.push(idx)
      }
    }

    return hotelsWithPDIndices
  }
)

/**
 * Returns hotels' ids with great deals (price drop)
 */
export const getHotelIdsWithGreatDeal = createSelector(
  [getHotelOfferEntities, getHotelEntities, getHotelIds],
  (hotelOfferEntities, hotelEntities, hotelIds) => {
    // SAPI_TODO: Split out original utils to not have to fake the Hits
    const hotelIdsWithGreatDeal = new Set<string>()
    if (!hotelIds) return hotelIdsWithGreatDeal

    const fakeHits = hotelIds.map(hotelId => {
      return {
        ...hotelEntities?.[hotelId],
        offers: hotelOfferEntities?.[hotelId]?.offers
      }
    })

    // TODO: Search TS preexisting issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const boundary = calculateGreatDealBoundary(fakeHits)

    for (const fakeHit of fakeHits) {
      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const delta = calculatePredictedVsCheapestRateDelta(fakeHit)
      if (Boolean(delta) && delta <= boundary)
        hotelIdsWithGreatDeal.add(fakeHit.objectID)
    }

    return hotelIdsWithGreatDeal
  }
)

/**
 * Selector to obtain a list of hotels in Daedalus format.
 *
 * @param state - The Redux state object. First item is the list of hotel IDs to display, the second is the hotel entities, and the third is the hotel offer entities.
 * @returns An array of hotels in Daedalus format.
 */
export const getHotelsInDaedalusFormat = createSelector(
  [getHotelIdsToDisplay, getHotelEntities, getHotelOfferEntities],
  (hotelIds, hotelEntities, hotelOfferEntities) => {
    return hotelIds.map(hotelId =>
      mapHotelToDaedalusFormat(
        hotelEntities?.[hotelId],
        getHotelOfferEntityByHotelId(hotelId, hotelOfferEntities)
      )
    )
  }
)

/**
 * Selector to obtain the anchor hotel in Daedalus format using its ID.
 *
 * @param  state - The Redux state object. First item is the anchor hotel ID, the second is the hotel entities, and the third is the hotel offer entities.
 * @returns The anchor hotel in Daedalus format.
 */
export const getAnchorHotelInDaedalusFormat = createSelector(
  [getAnchorHotelId, getHotelEntities, getHotelOfferEntities],
  (hotelId, hotelEntities, hotelOfferEntities) =>
    mapHotelToDaedalusFormat(
      hotelEntities?.[hotelId],
      getHotelOfferEntityByHotelId(hotelId, hotelOfferEntities)
    )
)

/**
 * Get physical distance from hotel to anchor hotel or searched place
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getDistanceFromTarget = createSelector(
  [getHotel, getAnchor],
  (hotelEntity, anchor) => {
    return hotelEntity && anchor
      ? calculateDistance(hotelEntity._geoloc, anchor._geoloc)
      : undefined
  }
)

/**
 * Returns the absolute position (index) for available hotel (starting from 0)
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getAbsoluteHotelPosition = createSelector(
  [getHotelIdParam, getHotelIds, getAnchorType, getAnchorHotelId],
  (hotelId, hotelIds, anchorType, anchorHotelId) => {
    if (hotelId === anchorHotelId) return 0

    const index = hotelIds?.indexOf(hotelId)

    if (index === undefined || index === -1) {
      return -1
    }

    return anchorType === AnchorType.hotel ? index + 1 : index
  }
)

/**
 * Returns highest visible price for provided hotel id
 */
export const getHighestVisiblePrice = (
  state: State,
  hotelId: Hotel['objectID']
): number | null | undefined => {
  const offerEntity = getHotelOfferEntity(state, hotelId)

  if (offerEntity !== undefined) {
    return getHighestPrice(
      offerEntity.anchorPriceRateBreakdown?.nightlyRate,
      offerEntity.offers,
      false // SAPI_TODO: find out the way to get shouldSeeOffersUnlocked flag from useAuth hook here
    )
  }
}

/**
 * Returns requested results count
 */
export const getRequestedResultsCount = (state: State) =>
  state.sapiSearch.requestedResultsCount

/**
 * Returns requested offset
 */
export const getRequestedOffset = (state: State) =>
  state.sapiSearch.requestedOffset

/**
 * Returns the list of unavailable hotels' positions
 */
export const getUnavailableHotelsPositions = createSelector(
  [getHotelIdsRaw, getHotelOfferEntities],
  (hotelIds, hotelOfferEntities) => {
    const unavailableHotelIdsWithPosition = []
    if (hotelIds === undefined) return []

    for (const [idx, hotelId] of hotelIds.entries()) {
      const hotelOfferEntity = getHotelOfferEntityByHotelId(
        hotelId,
        hotelOfferEntities
      )
      if (!calculateHotelHasOffers(hotelOfferEntity)) {
        unavailableHotelIdsWithPosition.push({
          id: hotelId,
          position: idx + 1
        })
      }
    }

    return unavailableHotelIdsWithPosition
  }
)

/**
 * Get anchor price for provided hotel id
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getAnchorPrice = createSelector(
  [getHotelOfferEntity],
  getAnchorPriceFromOfferEntity
)

/**
 * Get anchor price total for provided hotel id
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getAnchorPriceTotal = createSelector(
  [getHotelOfferEntity],
  offerEntity => offerEntity?.anchorPriceRateBreakdown?.totalRate
)

/**
 * Get the cheapest offer based on nightly rate from the anchor hotel entity
 *
 * Add as part of 366b54ed-price-difference-popup
 */
export const getAnchorHotelCheapestOffer = createSelector(
  [getAnchorHotelOfferEntity],
  anchorHotelOfferEntity =>
    getCheapestTopOfferByNightlyRate(anchorHotelOfferEntity?.offers)
)

/**
 * Get the your choice offer from the anchor hotel entity
 *
 * Add as part of 366b54ed-price-difference-popup
 */
export const getAnchorHotelYourChoiceOffer = createSelector(
  [getAnchorHotelOfferEntity],
  anchorHotelOfferEntity =>
    anchorHotelOfferEntity?.offers.find(offer =>
      hasOfferTag(offer, OfferTagLabel.IsYourChoice)
    )
)

/**
 * Returns whether anchor hotel has your choice offer
 */
export const getAnchorHotelHasYourChoice = createSelector(
  [getAnchorHotelYourChoiceOffer],
  anchorHotelYourChoiceOffer => !!anchorHotelYourChoiceOffer
)

const getCheckIn = (state: State) => state.sapiSearch.searchParameters?.checkIn
const getCheckOut = (state: State) =>
  state.sapiSearch.searchParameters?.checkOut

/**
 * Returns length of stay for checkIn/checkOut dates from state
 */
export const getLengthOfStay = createSelector(
  [getCheckIn, getCheckOut],
  (checkIn, checkOut) =>
    checkIn && checkOut
      ? differenceInCalendarDays(parseISO(checkOut), parseISO(checkIn))
      : 0
)

/**
 * Returns boolean if 'no more deals' message should be displayed
 * depending on how many times the user has clicked 'load more' with no
 * results from sapi
 */
export const getShouldShowNoMoreDeals = ({sapiSearch}: State): boolean => {
  if (sapiSearch?.countNoReturnedResults >= MAX_NO_RESULT) return true
  return false
}

/**
 * Returns the number of rooms for the current search
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getNumberOfRooms = createSelector(
  [getRoomsConfigFromOfferEntity, getSearchParameters],
  (roomsConfig, searchParameters) => {
    const rooms =
      searchParameters?.optimizeRooms && roomsConfig
        ? roomsConfig
        : searchParameters?.rooms || ''
    return numberOfRooms(rooms)
  }
)

/**
 * Returns the number of guests for the current search
 */
export const getNumberOfGuests = createSelector(
  [getSearchParametersRooms],
  rooms => numberOfGuests(rooms || '')
)

/**
 * Returns the roomConfiguration object to be used with Offer
 * components.
 */
export const getRoomConfiguration = createSelector(
  [getNumberOfRooms, getNumberOfGuests, getIsMultiRoomBundlesSearch],
  (numberOfRooms, numberOfGuests, isMultiRoomBundlesSearch) => {
    return {
      numberOfRooms,
      numberOfGuests,
      isMultiRoomBundlesSearch
    }
  }
)

/**
 * Returns whether the split booking is cheaper than all the top regular offers by at least max (2%, 10 currency units).
 */
export const getIsSplitBookingOfferTheCheapest = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => {
    const {splitBooking, offers = []} = hotelOfferEntity || {}
    const cheapestOfferTotalRate = offers?.length
      ? Math.min(...offers.map(o => o.totalRate))
      : 0
    return isSplitBookingPriceEligible(
      splitBooking?.totalRate,
      cheapestOfferTotalRate
    )
  }
)

/**
 * Returns whether the split booking offer is the preferred rate
 */
export const getIsSplitBookingOfferYourChoice = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity =>
    hasOfferTag(hotelOfferEntity?.splitBooking, OfferTagLabel.IsYourChoice)
)

const shouldPromoteSplitBooking = (
  offers: SearchOffer[],
  offerPositionToRemove: number,
  sbOfferTotalRate: number
) => {
  const promotionThreshold = 10.0
  const cheapestTotalRate = getCheapestOfferTotalRate(
    offers.filter((_, i) => i !== offerPositionToRemove)
  )
  const percentage = toPercentage(
    cheapestTotalRate - sbOfferTotalRate,
    cheapestTotalRate
  )
  return percentage >= promotionThreshold
}

/**
 * Retrieves details of a single flow split booking offer.
 * This function determines the position and availability of a split booking offer within the list of hotel offers.
 * If certain conditions are met, the split booking offer may be promoted to a specific position in the list.
 *
 * Context: https://innovativetravel.atlassian.net/wiki/x/04Dr5g
 */
export const getSingleFlowSplitBookingDetails = createSelector(
  [
    getHotelOfferEntity,
    getIsSplitBookingOfferTheCheapest,
    getIsSplitBookingOfferYourChoice
  ],
  (
    hotelOfferEntity,
    isSingleFlowSBOfferTheCheapest,
    isSBOfferYourChoice
  ): SplitBookingDetails => {
    const {splitBooking, offers: hotelOffers = []} = hotelOfferEntity || {}
    const offers = Array.isArray(hotelOffers) ? hotelOffers : []
    const offersCount = offers?.length || 0

    const splitBookingOfferUrl = splitBooking?.url

    const defaultResponse: SplitBookingDetails = {
      splitBookingType: 'single_flow' as SplitBookingBundleType,
      hasSplitBookingOffer: false,
      splitBookingOffers: [],
      splitBookingPosition: null,
      offerPositionToRemove: null,
      splitBookingOfferUrl: null,
      showSplitBookingLoader: false,
      splitBookingBundle: null,
      cancellationPenalties: splitBooking?.cancellationPenalties ?? [],
      isSplitBookingOfferTheCheapest: false
    }

    const isAvailable =
      offersCount == 0 || isSBOfferYourChoice || isSingleFlowSBOfferTheCheapest

    if (!splitBooking?.bundleId || !splitBooking?.url || !isAvailable) {
      return defaultResponse
    }

    const splitBookingOffers =
      sapiSBOtoSearchSBO(splitBooking?.offers ?? []) ?? []

    // when the sb offer is 10% cheaper than the cheapest offer excluding the one being replaced it should be promoted to the first position
    // and remove one offer to shift the remaining offers one position
    const sbOfferTotalRate = calculateTotalRate(
      splitBooking.totalRate,
      true,
      true
    )

    // when there is a vio offer available and it's not the preferred rate, swap it with the sb offer
    const vioOfferIndex = findLastIndex(o => isBofhOffer(o), offers || [])

    if (vioOfferIndex !== -1) {
      const vioOffer = offers[vioOfferIndex]
      const isYourChoice = hasOfferTag(vioOffer, OfferTagLabel.IsYourChoice)

      if (isYourChoice) {
        return defaultResponse
      }

      const shouldPromoteToFirstPosition = shouldPromoteSplitBooking(
        offers,
        vioOfferIndex,
        sbOfferTotalRate
      )

      return {
        ...defaultResponse,
        offerPositionToRemove: vioOfferIndex,
        hasSplitBookingOffer: true,
        splitBookingPosition: shouldPromoteToFirstPosition ? 0 : vioOfferIndex,
        splitBookingOffers,
        splitBookingOfferUrl,
        splitBookingBundle: splitBooking
      }
    }

    // when there is no vio offer available, find an available offer position to swap with the sb offer, following this rules:
    // - it should never be the preferred rate offer position
    // - it should be the lowest position available
    const yourChoiceOfferIndex = offers?.findIndex(o =>
      o?.tags?.includes(OfferTagLabel.IsYourChoice)
    )

    const availableOfferSpot =
      // when there are less than 3 offers, get the first available spot
      offersCount < 3
        ? offersCount
        : // when the preferred rate is not in the third position, get the third position, otherwise, the second
          isSBOfferYourChoice || yourChoiceOfferIndex <= 1
          ? 2
          : 1

    const offerPositionToRemove = offersCount >= 3 ? availableOfferSpot : null
    const shouldPromoteToFirstPosition = shouldPromoteSplitBooking(
      offers,
      offerPositionToRemove,
      sbOfferTotalRate
    )

    if (shouldPromoteToFirstPosition) {
      return {
        ...defaultResponse,
        hasSplitBookingOffer: true,
        splitBookingPosition: 0,
        offerPositionToRemove,
        splitBookingOffers,
        splitBookingOfferUrl,
        splitBookingBundle: splitBooking
      }
    }

    // when the sb offer is not 10% cheaper, it should be swapped with the available offer spot
    return {
      ...defaultResponse,
      hasSplitBookingOffer: true,
      splitBookingPosition: availableOfferSpot,
      offerPositionToRemove,
      splitBookingOffers,
      splitBookingOfferUrl,
      splitBookingBundle: splitBooking
    }
  }
)

/**
 * Get hotel split booking offer by hotelId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel
 * @param forceDisable - flag to force disable sb
 */
export const getSplitBookingDetails = createSelector(
  [
    getHotelOfferEntity,
    getModuleState,
    getSearchParameters,
    getSingleFlowSplitBookingDetails,
    getIsSplitBookingOfferTheCheapest,
    getIsSplitBookingOfferYourChoice
  ],
  (
    hotelOfferEntity,
    moduleState,
    searchParameters,
    singleFlowSplitBookingDetails,
    isSplitBookingOfferTheCheapest,
    isSplitBookingOfferYourChoice
  ): SplitBookingDetails => {
    const {splitBooking, offers} = hotelOfferEntity || {}

    const hasSplitBookingOffer = splitBooking?.offers?.length > 0
    const hasSbcParameter = Boolean(searchParameters?.sbc)
    const showSplitBookingLoader: boolean =
      splitBooking?.inProgress &&
      moduleState !== ModuleState.SearchCompleted &&
      hasSbcParameter

    const splitBookingOffers =
      sapiSBOtoSearchSBO(splitBooking?.offers ?? []) ?? []

    const isSingleFlowSB = splitBooking?.bundleId && splitBooking?.url
    if (isSingleFlowSB) {
      return {
        ...singleFlowSplitBookingDetails,
        showSplitBookingLoader,
        splitBookingBundle: splitBooking
      }
    }

    // Temporary solution to remove split bookings when it does not make sense.
    // This used to be implemented in RAA but got lost when moving to ADE.
    if (!isSplitBookingOfferTheCheapest && !isSplitBookingOfferYourChoice) {
      return {
        hasSplitBookingOffer: false,
        splitBookingOffers: [],
        splitBookingPosition: 0,
        splitBookingType: 'split_flow',
        showSplitBookingLoader,
        offerPositionToRemove: null,
        splitBookingOfferUrl: null,
        isSplitBookingOfferTheCheapest
      }
    }

    return {
      hasSplitBookingOffer,
      splitBookingOffers,
      splitBookingPosition: offers?.length ?? 0,
      splitBookingType: 'split_flow',
      showSplitBookingLoader,
      offerPositionToRemove: null,
      splitBookingOfferUrl: null,
      cancellationPenalties: [],
      isSplitBookingOfferTheCheapest
    }
  }
)

/**
 * Get split booking price summary by hotelId from the state
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getSplitBookingPriceSummary = createSelector(
  [getHotelOfferEntity],
  (hotelOfferEntity): CalculatedSplitBookingPrice => {
    const {splitBooking} = hotelOfferEntity || {}
    const {totalRate, offers = []} = splitBooking || {}

    if (!offers.length) return {sum: 0, average: 0}

    const splitBookingOffers = sapiSBOtoSearchSBO(offers)
    const nights = splitBookingOffers?.reduce(
      (sum, offer) => sum + offer.nights,
      0
    )

    const sum = totalRate?.base + totalRate?.taxes + totalRate?.hotelFees
    const average = sum / nights

    return {sum, average}
  }
)

/**
 * Get grouped hotel details based `partiallyMatched` variable
 */

export const getGroupedHotelDetails = createSelector(
  [getHotelIdsToDisplay, getHotelEntities],
  (
    hotelIds,
    entities
  ): {
    greatDeals: string[]
    partiallyMatched: string[]
    groupCounts: number[]
    hasPartiallyMatched: boolean
  } => {
    const partiallyMatched = new Set<string>()
    const greatDeals = new Set<string>()

    hotelIds?.forEach(hotelId => {
      if (entities?.[hotelId]?.partiallyMatched) partiallyMatched.add(hotelId)
      else greatDeals.add(hotelId)
    })

    return {
      greatDeals: [...greatDeals],
      partiallyMatched: [...partiallyMatched],
      groupCounts: [greatDeals.size, partiallyMatched.size],
      hasPartiallyMatched: partiallyMatched.size > 0
    }
  }
)

/**
 * Returns the list of promos for the current hotel
 * @param state - redux state object
 * @param hotelId - id of the hotel
 * @returns an array of promos
 */
const getHotelPromoOffers = createSelector(
  [getHotelOfferEntity],
  hotelOfferEntity => {
    return hotelOfferEntity?.promos
  }
)

/**
 * Returns the web2app promo for the current hotel
 * @param state - redux state object
 * @param hotelId - id of the hotel
 * @returns a promo offer if one exists
 */
const getHotelWeb2AppPromoOffer = createSelector(
  [getHotelPromoOffers],
  promoOffers => {
    return promoOffers?.[OfferPromoKey.Web2App]
  }
)

/**
 * Retruns whether the hotel has the web2app promo offer
 * @param state - redux state object
 * @param hotelId - id of the hotel
 * @returns a boolean
 */
const getHotelHasWeb2AppPromoOffer = createSelector(
  [getHotelPromoOffers],
  promoOffers => {
    return Boolean(promoOffers?.[OfferPromoKey.Web2App])
  }
)

/**
 * Returns whether the offer is a web2app promo offer
 * @param state - redux state object
 * @param hotelId - id of the hotel
 * @param offerId - id of the offer
 * @returns a boolean
 */
const getIsOfferWeb2AppPromo = createSelector(
  [getHotelWeb2AppPromoOffer, getOffer],
  (web2AppPromoOffer, offer) => {
    return web2AppPromoOffer?.id === offer?.id
  }
)

/**
 * Get if it's a base app locked deal at a high level for anchor hotels (not specific to a hotel and/or offer)
 */
export const getIsBaseAppLockedDealAnchorAudience = createSelector(
  [getUserCountryCode],
  countryCode => {
    const userCountryIsExcludedFromAppLockedDeals =
      getIsCountryExcludedFromAppLockedDeals(countryCode)

    return !userCountryIsExcludedFromAppLockedDeals
  }
)

/**
 * Get audience for v2 of the app locked deal at a high level (not specific to a hotel and/or offer).
 * This is for returning US users.
 */
export const getIsAppLockedDealForUSReturningUsersAudience = createSelector(
  [
    getIsBaseAppLockedDealAnchorAudience,
    getUserCountryCode,
    getIsReturningUser
  ],
  (isBaseAppLockedDealAnchorAudience, userCountry, isReturningUser) => {
    const isUserInUS = userCountry === 'US'

    const isReturningUserFromUs = isUserInUS && isReturningUser

    return isBaseAppLockedDealAnchorAudience && isReturningUserFromUs
  }
)

/**
 * Get audience for v4 of the app locked deal at a high level (not specific to a hotel and/or offer).
 * This is for none US returning users (excluding trip advisor).
 */
export const getIsAppLockedDealForNonUsReturningUserAudience = createSelector(
  [
    getIsBaseAppLockedDealAnchorAudience,
    getIsReturningUser,
    getUserCountryCode
  ],
  (isBaseAppLockedDealAnchorAudience, isReturningUser, userCountryCode) => {
    const isUserInUS = userCountryCode === 'US'

    const isAppLockedDealForNonUsReturningUser =
      isBaseAppLockedDealAnchorAudience && !isUserInUS && isReturningUser

    return isAppLockedDealForNonUsReturningUser
  }
)

/**
 * Get audience for v5 of the app locked deal at a high level (not specific to a hotel and/or offer).
 * This is for all GHA users.
 */
export const getIsAppLockedDealForGHAUserAudience = createSelector(
  [getIsBaseAppLockedDealAnchorAudience, getIsFromGha],
  (isBaseAppLockedDealAnchorAudience, isFromGha) =>
    isBaseAppLockedDealAnchorAudience && isFromGha
)

/**
 * Get whether the web to app feature is enabled for anchors in the current device layout
 */
export const getIsWebToAppFeatureEnabledAnchor = createSelector(
  [getDeviceLayout],
  deviceLayout => {
    return (
      deviceLayout === 'mobile' &&
      toggle('feature-web-to-app-mobile-anchor', false, true)
    )
  }
)

/**
 * Get whether the web to app feature is enabled for non-anchors in the current device layout
 */
export const getIsWebToAppFeatureEnabledNonAnchor = createSelector(
  [getDeviceLayout],
  deviceLayout => {
    return (
      deviceLayout === 'mobile' &&
      toggle('feature-web-to-app-mobile-non-anchor', false, true)
    )
  }
)

/**
 * Get whether the web to app feature is enabled for Desktop
 */
export const getIsWebToAppFeatureEnabledDesktop = () => {
  return toggle('feature-web-to-app-desktop', false, true)
}

/**
 * Get whether the web to app holdout group is enabled
 */
const getIsWebToAppHoldoutGroupEnabled = () => {
  return toggle('h02hslwa-app-exclusive-deal-hold-out-group', false, true)
}

/**
 * Get the app locked deal audience config for anchor hotels
 */
export const getAppLockedDealAnchorAudienceConfig = createSelector(
  [
    getIsAppLockedDealForUSReturningUsersAudience,
    getIsAppLockedDealForNonUsReturningUserAudience,
    getIsAppLockedDealForGHAUserAudience,
    getIsWebToAppFeatureEnabledAnchor,
    getIsWebToAppHoldoutGroupEnabled
  ],
  (
    isAppLockedDealForUSReturningUsersAudience,
    isAppLockedDealForNonUsReturningAudience,
    isAppLockedDealForGHAUserAudience,
    isWebToAppFeatureEnabled,
    isHoldOutGroupEnabled
  ) => {
    if (!isWebToAppFeatureEnabled || isHoldOutGroupEnabled) {
      return {isAppLockedDealAudience: false}
    }

    if (
      isAppLockedDealForUSReturningUsersAudience ||
      isAppLockedDealForNonUsReturningAudience ||
      isAppLockedDealForGHAUserAudience
    ) {
      return {
        isAppLockedDealAudience: true,
        placement: Placement.AnchorAppLockedDeal
      }
    }

    return {
      isAppLockedDealAudience: false
    }
  }
)

/**
 * Get the app locked deal audience config for non-anchor hotels
 */
export const getAppLockedDealNonAnchorAudienceConfig = createSelector(
  [getIsWebToAppFeatureEnabledNonAnchor, getIsWebToAppHoldoutGroupEnabled],
  (isWebToAppFeatureEnabled, isHoldOutGroupEnabled) => {
    if (!isWebToAppFeatureEnabled || isHoldOutGroupEnabled) {
      return {isAppLockedDealAudience: false}
    }

    return {
      isAppLockedDealAudience: true,
      placement: Placement.NonAnchorAppLockedDeal
    }
  }
)

/**
 * Get whether the hotel has an app locked deal for desktop.
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getAppLockedDealConfigDesktop = createSelector(
  [
    getHotelHasWeb2AppPromoOffer,
    getIsWebToAppFeatureEnabledDesktop,
    getDeviceLayout
  ],
  (hotelHasWeb2AppPromo, isWebToAppFeatureEnabled, deviceLayout) =>
    hotelHasWeb2AppPromo &&
    isWebToAppFeatureEnabled &&
    deviceLayout === 'desktop'
)

/**
 * Get whether the hotel has an app locked deal.
 * This is used to determine whether the hotel's offers should be re-ordered to show the app locked deal first.
 * @param state - redux state object
 * @param hotelId - id of the hotel
 */
export const getAppLockedDealHotelConfig = createSelector(
  [
    getIsAnchorHotel,
    getAppLockedDealAnchorAudienceConfig,
    getAppLockedDealNonAnchorAudienceConfig,
    getCheapestOfferHasPriceParity,
    getCheapestOfferMeetsSavingsThreshold,
    getHotelHasWeb2AppPromoOffer
  ],
  (
    isAnchorHotel,
    appLockedDealAnchorAudienceConfig,
    appLockedDealNonAnchorAudienceConfig,
    cheapestOfferHasPriceParity,
    cheapestOfferMeetsSavingsThreshold,
    hotelHasWeb2AppPromo
  ) => {
    const isAppLockedAnchorHotel =
      isAnchorHotel &&
      appLockedDealAnchorAudienceConfig.isAppLockedDealAudience &&
      !cheapestOfferHasPriceParity &&
      hotelHasWeb2AppPromo
    const isAppLockedNonAnchorHotel =
      !isAnchorHotel &&
      appLockedDealNonAnchorAudienceConfig.isAppLockedDealAudience &&
      cheapestOfferMeetsSavingsThreshold &&
      hotelHasWeb2AppPromo

    if (isAppLockedNonAnchorHotel) {
      return {
        isAppLockedHotel:
          appLockedDealNonAnchorAudienceConfig.isAppLockedDealAudience,
        appDownloadLinkId: appLockedDealNonAnchorAudienceConfig.placement
      }
    }

    if (isAppLockedAnchorHotel) {
      return {
        isAppLockedHotel: true,
        appDownloadLinkId: appLockedDealAnchorAudienceConfig.placement
      }
    }

    return {isAppLockedHotel: false}
  }
)

/**
 * Get whether the offer is an app locked deal.
 * This is used to determine whether the offer should be app locked in the UI
 * @param state - redux state object
 * @param hotelId - id of the hotel
 * @param offerId - id of the offer
 */
export const getAppLockedDealConfig = createSelector(
  [
    getIsAnchorHotel,
    getOfferIdParam,
    getAppLockedDealHotelConfig,
    getHotelCheapestOffer,
    getHotelTopOffer,
    getIsOfferWeb2AppPromo,
    getPopupOnExitVisibility,
    getAppLockedDealConfigDesktop,
    getIsWebToAppFeatureEnabledAnchor,
    getIsWebToAppFeatureEnabledNonAnchor,
    getIsWebToAppFeatureEnabledDesktop
  ],
  (
    isAnchorHotel,
    offerIdParam,
    appLockedDealHotelConfig,
    cheapestOffer,
    topOffer,
    isOfferWeb2AppPromo,
    isPopupOnExitVisible,
    isAppLockedHotelDesktop,
    // Added as part of feature-web-to-app-mobile and feature-web-to-app-desktop
    isWebToAppFeatureEnabledAnchor,
    isWebToAppFeatureEnabledNonAnchor,
    isWebToAppFeatureEnabledDesktop
  ): AppLockedDealConfig => {
    if (
      isAnchorHotel &&
      !isWebToAppFeatureEnabledAnchor &&
      !isWebToAppFeatureEnabledDesktop
    ) {
      return {isAppLockedDeal: false}
    }

    if (
      !isAnchorHotel &&
      !isWebToAppFeatureEnabledNonAnchor &&
      !isWebToAppFeatureEnabledDesktop
    ) {
      return {isAppLockedDeal: false}
    }

    if (
      isPopupOnExitVisible ||
      !cheapestOffer ||
      !offerIdParam ||
      !isOfferWeb2AppPromo
    ) {
      return {isAppLockedDeal: false}
    }

    const {isAppLockedHotel, appDownloadLinkId} = appLockedDealHotelConfig
    const isCheapestOffer = offerIdParam === cheapestOffer?.id
    const isTopOffer = offerIdParam === topOffer?.id

    const isAnchorAppLockedDeal =
      isAnchorHotel && isAppLockedHotel && isCheapestOffer && isTopOffer
    const isNonAnchorAppLockedDeal =
      !isAnchorHotel && isAppLockedHotel && isCheapestOffer

    if (isAppLockedHotelDesktop) {
      return {
        isAppLockedDeal: true
      }
    }

    if (isAnchorAppLockedDeal) {
      const now = new Date()
      const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)
      const lastAppLockedDealImpressionsTimestamp =
        getLastAppLockedAnchorDealImpressionsTimestamp()
      const lastAppLockedDealImpressionsDate = new Date(
        lastAppLockedDealImpressionsTimestamp
          ? lastAppLockedDealImpressionsTimestamp
          : now
      )

      const impressionCount = getAppLockedAnchorDealImpressionsCount() || 0
      const shouldUnlockDeal =
        impressionCount > 1 ||
        (impressionCount === 1 &&
          lastAppLockedDealImpressionsDate < twentyFourHoursAgo)

      return {
        isAppLockedDeal: !shouldUnlockDeal,
        appDownloadLinkId
      }
    }

    if (isNonAnchorAppLockedDeal) {
      return {
        isAppLockedDeal: true,
        appDownloadLinkId
      }
    }

    return {isAppLockedDeal: false}
  }
)

export const getHotelHasGhaPreferredRate = createSelector(
  [getIsCheapestOfferPreferredRate, getIsFromGha],
  (cheapestOfferIsPreferredRate, isFromGha) => {
    return cheapestOfferIsPreferredRate && isFromGha
  }
)

export const getHotelHasSameTotalPriceAndDisplayedNightlyPrice = createSelector(
  [getAnchorPrice, getAnchorPriceTotal],
  (anchorPrice, anchorPriceTotal): boolean => {
    return anchorPrice === anchorPriceTotal
  }
)

export const getOfferHasGhaOrGvrPreferredRate = createSelector(
  [getIsFromGha, getIsFromGVR, (state, offer: GenericOffer) => offer],
  (isFromGha, isFromGVR, offer): boolean => {
    return (
      (isFromGha || isFromGVR) && hasOfferTag(offer, OfferTagLabel.IsYourChoice)
    )
  }
)

export const getVioOfferFromOfferEntities = createSelector(
  [getHotelOfferEntities],
  entities => {
    let result: SearchOffer | null = null

    for (const key in entities) {
      const currentEntity = entities[key]

      const {offers} = currentEntity

      const offerWithVioUrl = offers.find(isBofhOffer)

      if (offerWithVioUrl) {
        result = offerWithVioUrl
        break
      }
    }

    return result
  }
)

export const getShowTotalPriceToggle = createSelector(
  [
    getSearchParameters,
    getLengthOfStay,
    getTaxesIncluded,
    getLocalTaxesIncluded
  ],
  (_, lengthOfStay, taxesIncluded, localTaxesIncluded) => {
    const includeTaxesAndFees = Boolean(taxesIncluded && localTaxesIncluded)

    if (lengthOfStay === 1 && includeTaxesAndFees) {
      return false
    }

    return true
  }
)

export const getShowTotalPrices = createSelector(
  [getShowTotalPriceToggle, (state: RootState) => state.search.showTotalPrices],
  (showTotalPriceToggle, showTotalPrices) =>
    showTotalPriceToggle && showTotalPrices
)

/**
 * Check if the hotels have static position
 */
export const getHotelsHaveStaticPosition = createSelector(
  [(state: State) => state.sapiSearch],
  sapiSearch => sapiSearch.hotelsHaveStaticPosition
)

/**
 * Get the hotel ids of hotels for requesting the availability for the price/color guide calendars, based on the search type from the searchBox
 */
export const getHotelIdsForPriceColorGuideCalendars = createSelector(
  [
    getSearchBoxValues,
    getHotelIdsToDisplay,
    getSearchBoxSearchType,
    getSearchParameters
  ],
  (
    searchBoxValues,
    hotelIdsToDisplay,
    searchBoxSearchType,
    searchParameters
  ) => {
    if (searchBoxSearchType === SearchType.Hotel) {
      return searchBoxValues.hotelId ? [searchBoxValues.hotelId] : []
    }

    if (searchBoxSearchType === SearchType.Place) {
      return searchBoxValues.placeId === searchParameters.placeId
        ? hotelIdsToDisplay.slice(0, 5)
        : []
    }

    return hotelIdsToDisplay.slice(0, 5)
  }
)

const getHotelOfferEntityParam = (_, offerEntity: HotelOfferEntity) =>
  offerEntity

export const getAnchorPriceNightlyPerRoom = createSelector(
  [getHotelOfferEntityParam, getCheckIn, getCheckOut],
  (offerEntity, checkIn, checkOut) => {
    if (!offerEntity?.anchorPriceRateBreakdown) return undefined
    return calculateNightlyRate(
      offerEntity.anchorPriceRateBreakdown.totalRate,
      checkIn,
      checkOut
    )
  }
)

/**
 * Get the cheapest offer rate from the hotel offer entity
 */
export const getCheapestPriceRateBreakdown = createSelector(
  [getHotelOfferEntity],
  (hotelOfferEntity): PriceRateBreakdown =>
    hotelOfferEntity?.cheapestPriceRateBreakdown
)
