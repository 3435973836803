import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {differenceInDays} from 'date-fns'
import {ulid} from 'ulid'

import {SIZES} from '@daedalus/atlas/Image'
import {imageProvider} from '@daedalus/atlas/testutils/imageProvider'
import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {Rate} from '@daedalus/core/src/analytics/services/CustomerIo/types'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {selectUserId} from '@daedalus/core/src/auth/modules/selectors'
import {getEpochSeconds, getTimezone} from '@daedalus/core/src/utils/date'

import {cancelPriceWatchAPI} from '../services'
import {
  addPriceWatchEntryFromParams,
  disablePriceWatchEntry,
  removePriceWatchEntry
} from '../slice'
import {selectPriceWatchEntry} from '../slice/selectors'
import {PriceWatchEntry} from '../types'

import type {ContentHotel, Hotel} from '@findhotel/sapi'

export interface UsePriceWatchProps {
  checkIn: string
  checkOut: string
  rooms: string
  currency: string
  locale: string
  hotel: Hotel | ContentHotel
  rate: Rate
  priceWatchURL: string
  onPriceWatchInitialized?: (flowType?: string) => void
  roomName?: string
}

export const usePriceWatch = ({
  checkIn,
  checkOut,
  rooms,
  currency,
  locale,
  hotel,
  rate,
  priceWatchURL,
  onPriceWatchInitialized,
  roomName
}: UsePriceWatchProps) => {
  const {brand} = useBrand()
  const cio = customerIo(brand)
  const userId = useSelector(selectUserId)
  const anonymousId = getAnonymousId() as string
  const dispatch = useDispatch()

  const nights = differenceInDays(new Date(checkOut), new Date(checkIn))

  const params = {
    checkIn,
    nights,
    hotelId: hotel.objectID,
    rooms,
    currency,
    rate
  }

  const priceWatchEntry = useSelector(selectPriceWatchEntry(params))
  const priceWatchId = priceWatchEntry?.id
  const isEnabled = priceWatchEntry?.enabled

  const isPriceWatchOn = Boolean(priceWatchId) && isEnabled

  const hotelImageUrl = hotel?.imageURIs?.[0]
    ? imageProvider(hotel?.imageURIs[0], SIZES['large'])
    : undefined

  const [undoCarbonCopy, setUndoCarbonCopy] = useState<PriceWatchEntry>()
  const initPriceWatch = (flowType?: string) => {
    const id = ulid()
    dispatch(addPriceWatchEntryFromParams(undoCarbonCopy || {...params, id}))
    cio.trackEvent({
      name: `${Category.User}_${Entity.SubscribeToPriceWatch}_${Action.Submitted}`,
      anonymousId,
      userId,
      eventId: id,
      checkIn: Number(getEpochSeconds(checkIn)),
      checkOut: Number(getEpochSeconds(checkOut)),
      hotelId: hotel.objectID,
      rooms,
      currency,
      locale,
      hotelImageUrl,
      rate,
      starRating: hotel?.starRating,
      hotelName: hotel?.hotelName,
      hotelAddress: hotel?.displayAddress,
      timezone: getTimezone(),
      roomName,
      type: 'push'
    })
    onPriceWatchInitialized?.(flowType)
  }

  const pausePriceWatch = async () => {
    try {
      await cancelPriceWatchAPI({
        priceWatchId,
        userId: userId || anonymousId,
        priceWatchURL
      })
    } catch (error) {
      console.error('Error pausing PriceWatch', error)
    }
  }

  const cancelPriceWatch = async () => {
    setUndoCarbonCopy(priceWatchEntry)
    dispatch(disablePriceWatchEntry(params))

    const queryParams = new URLSearchParams(location.search)
    const urlPriceWatchId = queryParams.get('priceWatchId')
    const priceWatchIdWithFallback = priceWatchId || urlPriceWatchId
    if (!priceWatchIdWithFallback) throw new Error('No priceWatchId found')
    pausePriceWatch()
  }

  const removePriceWatch = async () => {
    setUndoCarbonCopy(priceWatchEntry)
    dispatch(removePriceWatchEntry(params))
    pausePriceWatch()
  }

  return {
    priceWatchEntry,
    isPriceWatchOn,
    initPriceWatch,
    cancelPriceWatch,
    removePriceWatch
  }
}
