import React from 'react'
import {useShare} from 'hooks/useShare'
import {SearchProps} from 'utils/sharing'

import {Button} from '@daedalus/atlas/Button'
import {Icon} from '@daedalus/atlas/Icon'

import {ShareType} from './constants'

interface Props {
  shareParams: SearchProps
  shareType?: ShareType
  component?: string
}

export const RoundShareButton = ({
  shareParams,
  component,
  shareType
}: Props) => {
  const {onShare, shouldShow} = useShare({shareParams, component, shareType})

  if (!shouldShow()) return null

  return (
    <Button
      variant="secondary"
      isRounded
      isFloating
      dataId="ShareButton"
      iconStart={<Icon name="Share3" />}
      onClick={onShare}
      size="lg"
    />
  )
}
