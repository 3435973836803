import {useIntl} from 'react-intl'
import {toggle} from 'opticks'

import {getHasFreeCancellation} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {hasChargeLaterTag} from '@daedalus/core/src/offer/business/chargeLater'
import {roomLabels} from '@daedalus/core/src/offer/business/getRoomLabels'
import {getMealsAmenitiesFromList} from '@daedalus/core/src/offer/business/meal'
import {Amenities} from '@findhotel/sapi'

import type {Offer} from '@daedalus/core/src/offer/types/offer'

interface useGetRoomOfferLabelsProps {
  offer: Offer
}
export const useGetRoomOfferLabels = ({
  offer
}: useGetRoomOfferLabelsProps): string[] => {
  const {formatMessage} = useIntl()
  if (!offer) return []
  const isChargeLaterEligible = hasChargeLaterTag(offer)
  const {canPayLater} = offer
  const hasFreeCancellation = getHasFreeCancellation(
    offer?.cancellationPenalties
  )
  // NOTE: Typecasting here because the offer.services is an array of amenities, but it has an old type
  const meal = getMealsAmenitiesFromList(offer.services as Amenities[])[0]

  const roomLabelItems = {
    freeCancellation: hasFreeCancellation,
    payLater: canPayLater
  }

  const translatedRoomLabels = Object.keys(roomLabelItems).reduce(
    (out, key) => {
      if (roomLabelItems[key] && roomLabels[key]) {
        return out.concat(formatMessage(roomLabels[key]))
      }

      return out
    },
    []
  )

  const translatedMealsLabels =
    meal && roomLabels[meal] ? formatMessage(roomLabels[meal]) : []

  const bookNowPayLaterMessage = toggle(
    '50feb427-cl-differential-price',
    roomLabels.bookNowPayLater,
    roomLabels.chargeLater
  )

  const translatedChargeLaterLabel = isChargeLaterEligible
    ? [formatMessage(bookNowPayLaterMessage)]
    : []
  const translatedLabels = [].concat(
    translatedRoomLabels,
    translatedChargeLaterLabel,
    translatedMealsLabels
  )

  return translatedLabels
}
