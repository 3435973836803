import {createSelector} from 'reselect'
import {RootState} from 'store'

import {HotelDetailsOverlayInfo, Overlay, OverlayType} from './types'

export const getOverlay = (state: RootState) => state.overlay

/**
 * Factory function that accepts the `OverlayType` and returns the `visibilityState`
 */
export const getOverlayVisibilityState = createSelector(
  [getOverlay, (_, type: OverlayType) => type],
  (overlay, type): boolean => overlay.some(overlay => overlay.type === type)
)

//TODO: Replace other selectors with getOverlayVisibilityState and rename it to getOverlayVisibility
// https://app.shortcut.com/findhotel/story/94192/use-getoverlayvisibilitystate-instead-of-all-the-selectors-inside-the-overlay-directory

export const getOverlayVisibility = (
  {overlay}: RootState,
  type: OverlayType
): boolean => overlay.some(overlay => overlay.type === type)

export const getLocationVisibility = createSelector(getOverlay, overlay =>
  overlay.some(overlay => overlay.type === OverlayType.LocationOverlay)
)

export const getStreetViewVisibility = createSelector(getOverlay, overlay =>
  overlay.some(overlay => overlay.type === OverlayType.StreetView)
)

export const getHotelDetailsVisibility = createSelector(getOverlay, overlay =>
  overlay.some(overlay => overlay.type === OverlayType.HotelDetails)
)

export const getHotelFacilitiesVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.HotelFacilities)

export const getShareYourFeedbackVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.ShareYourFeedbackOverlay)

export const getPreferencesVisibility = createSelector(getOverlay, overlay =>
  overlay.some(overlay => overlay.type === OverlayType.Preferences)
)

export const getFiltersVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.Filters)

export const getPriceFilterVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.PriceFilter)

export const getPropertyTypesFilterVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.PropertyTypeFilter)

export const getReviewScoreFilterVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.ReviewScoreFilter)

export const getFacilitiesFilterVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.FacilitiesFilter)

export const getSortByVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.SortBy)

export const getIsDatePickerOpen = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.DatePicker)

export const getIsDestinationPickerOpen = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.DestinationPicker)

export const getIsGuestPickerOpen = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.GuestPicker)

export const getIsHotelDatePickerOpen = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.HotelDatePicker)

export const getIsHotelGuestPickerOpen = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.HotelGuestPicker)

export const getAvailabilityPopupVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.AvailabilityPopup)

export const getVerticalGalleryOverlayVisibility = (
  state: RootState
): boolean => getOverlayVisibility(state, OverlayType.VerticalGalleryOverlay)

export const getRoomDetailOverlayVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.RoomDetailsOverlay)

export const getGuestReviewsOverlayVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.GuestReviews)
/**
 * Find an overlay by type in a type-safe way
 */
const findOverlayByType = <Type extends OverlayType>(
  overlays: Overlay[],
  type: Type
) =>
  overlays.find(
    (overlay): overlay is Extract<Overlay, {type: Type}> =>
      overlay.type === type
  )

export const getLightboxOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.LightboxGalleryOverlay)
)

export const getHotelPoliciesOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.HotelPolicies)
)

export const getAllOffersOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.HotelAllOffers)
)

export const getRoomDetailOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.RoomDetailsOverlay)
)

export const getRoomOfferPriceBreakdownOverlay = createSelector(
  getOverlay,
  overlay =>
    findOverlayByType(overlay, OverlayType.RoomOfferPriceBreakdownOverlay)
)

export const getHotelDetailsOverlayInfo = createSelector(
  getOverlay,
  overlay =>
    findOverlayByType(overlay, OverlayType.HotelDetails) ||
    ({} as Partial<HotelDetailsOverlayInfo>)
)

export const getDateGuestPickerOverlay = createSelector(
  getOverlay,
  overlays => {
    const matchingOverlay = overlays.find(overlay =>
      [OverlayType.HotelGuestPicker, OverlayType.HotelDatePicker].includes(
        overlay.type
      )
    )

    return matchingOverlay || {type: null, source: null}
  }
)

// As part of sapi4eva-hotel-descriptions
export const getHotelDescriptionOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.HotelDescription)
)

export const getWeb2AppPopupOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.Web2AppPopup)
)

export const getStreetViewOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.StreetView)
)

export const getPriceWatchOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.PriceWatchOverlay)
)

export const getStarRatingFilterVisibility = (state: RootState): boolean =>
  getOverlayVisibility(state, OverlayType.StarRatingsFilter)

export const getReloadApOverlay = createSelector(getOverlay, overlay =>
  findOverlayByType(overlay, OverlayType.ReloadAP)
)
