import {useCallback, useLayoutEffect, useMemo, useState} from 'react'
import throttle from 'lodash/throttle'

/**
 * Determine whether to show the back to top button
 * Returns true if the user is scrolling up or at the bottom of the page
 * Forces to return false if the user is at the top of the page
 */
const useBackToTop = (enabled: boolean): boolean => {
  const [shouldShowBackToTop, setShouldShowBackToTop] = useState(false)
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)

  const handleScroll = useCallback(() => {
    if (enabled) {
      const top = window.scrollY || document.documentElement.scrollTop
      const isBottom = top + window.innerHeight >= document.body.clientHeight
      const shouldShow = top !== 0 && (top < prevScrollPosition || isBottom)
      setShouldShowBackToTop(shouldShow)
      setPrevScrollPosition(top)
    }
  }, [prevScrollPosition, enabled])

  // Throttle by a decent amount to prevent performance issues + trigger at trailing edge so it's more likely to cause state change when done scrolling
  // If you want to lower the timeout: consider that any state change during scrolling can lead to rendering peformance issues
  const handleScrollThrottled = useMemo(
    () => throttle(handleScroll, 500, {leading: false, trailing: true}),
    [handleScroll]
  )

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScrollThrottled)
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled)
    }
  }, [handleScrollThrottled])

  return shouldShowBackToTop
}

export default useBackToTop
