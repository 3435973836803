import {useCallback, useEffect, useRef} from 'react'

interface ExitIntentDetectionProps {
  isEnabled: boolean
  onExitIntent: () => void
}

const TOP_OFFSET = 5
const DELAY = 200

/**
 * Hook that detects when a user intends to exit the page by tracking mouse movement
 * near the top of the viewport.
 *
 * @param isEnabled - Whether exit intent detection is currently enabled
 * @param onExitIntent - Callback function to execute when exit intent is detected
 * @returns void
 */

export const useExitIntentDetection = ({
  isEnabled,
  onExitIntent
}: ExitIntentDetectionProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const clearTimeoutSafely = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const handleMouseLeave = useCallback(
    (e: MouseEvent) => {
      if (e.clientY <= TOP_OFFSET && isEnabled) {
        timeoutRef.current = setTimeout(onExitIntent, DELAY)
      }
    },
    [isEnabled, onExitIntent]
  )

  const handleMouseEnter = useCallback(() => {
    clearTimeoutSafely()
  }, [clearTimeoutSafely])

  useEffect(() => {
    const abortController = new AbortController()

    if (!isEnabled) {
      clearTimeoutSafely()
      return
    }

    document.body?.addEventListener('mouseleave', handleMouseLeave, {
      signal: abortController.signal
    })
    document.body?.addEventListener('mouseenter', handleMouseEnter, {
      signal: abortController.signal
    })

    return () => {
      clearTimeoutSafely()
      abortController.abort()
    }
  }, [isEnabled, handleMouseLeave, handleMouseEnter, clearTimeoutSafely])
}
