import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {CombinedHotelAndOffer} from 'components/CarouselWidgets/types'
import {
  combineHotelDetailsAndOffers,
  filterHotelsWithoutOffers,
  getHotelIdsForHotelsWithOffers
} from 'components/CarouselWidgets/utils/offers'
import {useSapi} from 'modules/sapiSearch/SapiProvider'
import {getSearchParameters} from 'modules/sapiSearch/selectors'

import {getPersistedSapiLabel} from '@daedalus/core/src/_web/sapiLabel/business'
import {getPersistedGeolocation} from '@daedalus/core/src/utils/geolocation'

interface Props {
  hotelId?: string
  placeId?: string
  checkIn: string
  checkOut: string
  rooms: string
  instanceId: string
  onRequestComplete?: (instanceId: string) => void
  onRequestStart?: (instanceId: string) => void
  onRequestError?: (instanceId: string, error: Error) => void
  numberOfResults?: number
}

export const useGetDealsForTonight = ({
  hotelId,
  placeId,
  checkIn,
  checkOut,
  rooms,
  instanceId,
  onRequestComplete,
  onRequestStart,
  onRequestError,
  numberOfResults
}: Props) => {
  const {sapiClient} = useSapi()
  const {cugDeals, tier, metadata} = useSelector(getSearchParameters) || {}
  const sapiLabel = getPersistedSapiLabel()
  const [hotelsReceived, setHotelsReceived] = useState(false)
  const [hotelIds, setHotelIds] = useState<string[]>([])
  const [hotels, setHotels] = useState<CombinedHotelAndOffer>()

  const handleHotelsAndOffers = response => {
    const hotelIds = getHotelIdsForHotelsWithOffers(response)
    setHotelIds(hotelIds)
    setHotels(combineHotelDetailsAndOffers(filterHotelsWithoutOffers(response)))
  }

  const getSuggestedHotels = useCallback(
    async parameters => {
      try {
        const callbacks = {
          onStart: () => {
            setHotelsReceived(false)
            onRequestStart?.(instanceId)
          },
          onHotelsReceived: response => {
            setHotelIds(response?.hotelIds)
            setHotels(combineHotelDetailsAndOffers(response))
            setHotelsReceived(true)
          },
          onOffersReceived: handleHotelsAndOffers,
          onComplete: response => {
            handleHotelsAndOffers(response)
            onRequestComplete?.(instanceId)
          }
        }
        await sapiClient.search(parameters, callbacks)
      } catch (error) {
        onRequestError?.(instanceId, error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sapiClient]
  )

  useEffect(() => {
    if (sapiClient) {
      // in the scenario where we don't have a hotelId or placeId, we need to validate if the geolocation is available
      // before to calling the API, otherwise the user will receive an error.
      let geoParam = {}
      if (!hotelId && !placeId) {
        const geolocation = getPersistedGeolocation()
        if (!geolocation) return

        geoParam = {geolocation}
      }

      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(async () => {
        await getSuggestedHotels({
          hotelId,
          placeId,
          checkIn,
          checkOut,
          rooms,
          tier,
          cugDeals,
          metadata,
          pageSize: numberOfResults,
          ...geoParam,
          ...(sapiLabel && {label: sapiLabel})
        })
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hotelId,
    placeId,
    checkIn,
    checkOut,
    rooms,
    tier,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(cugDeals),
    sapiClient,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(metadata),
    getSuggestedHotels,
    sapiLabel
  ])

  return {
    hotelIds,
    hotels,
    hotelsReceived
  }
}
