import cookieConfig from 'config/cookies/currencyCode'
import isString from 'lodash/isString'
import {parse} from 'qs'
import Settings from 'Settings'
import {CountryType} from 'types/Localization'

import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {getCookie, setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {getBrandByCode} from '@daedalus/core/src/brand/config'
import {
  ALL_CURRENCIES,
  defaultCurrencyCode as DEFAULT_CURRENCY_CODE,
  getEnabledCurrencies
} from '@daedalus/core/src/localization/business/currency'

import {getDomainConfig} from './domain'

/**
 * Check that currency code is both available & enabled
 */
const getEnabledCurrencyCode = currencyCode => {
  const enabledCurrencies = getEnabledCurrencies(
    Settings.get('ENABLED_CURRENCIES')
  )
  return (
    enabledCurrencies.find(({code}) => code === currencyCode) && currencyCode
  )
}

const getSupportedCurrencyCode = currencyCode =>
  ALL_CURRENCIES.some(curr => curr.code === currencyCode) && currencyCode

const getFromCookie = () => getCookie(cookieConfig.name)

const getFromQueryString = (): string | null => {
  const {currency, curr: fallbackCurrency} = parse(location.search, {
    ignoreQueryPrefix: true
  })

  const resultCurrency = currency || fallbackCurrency

  if (!isString(resultCurrency)) return null

  const currencyCode = resultCurrency?.toUpperCase?.()

  return getSupportedCurrencyCode(currencyCode)
}

const getFromQueryStringIfForced = (): string | null => {
  const {forceCurrencyChange} = parse(location.search, {
    ignoreQueryPrefix: true
  })
  if (forceCurrencyChange !== '1') return null

  return getFromQueryString()
}

const getFromUserCountry = ({
  userCountry
}: {
  userCountry: CountryType | undefined
}) => getEnabledCurrencyCode(userCountry?.currencyCode)

/**
 * @deprecated The domains used in this function seem outdated. Verify that they are correct before using this function.
 * @returns    The enabled currency code from the domain configuration, or null if not found.
 */
const getFromDomain = (): string | null => {
  const domain = getDomainConfig(location.hostname)
  return domain ? getEnabledCurrencyCode(domain.currencyCode) : null
}

const getFromBrandConfig = (): string | null => {
  const brand = getBrandByCode(
    getBrandCodeFromUrlOrCookie(process.env.NEXT_PUBLIC_ENV)
  )
  return brand?.bookOnBrand?.defaultCurrencyCode
    ? getEnabledCurrencyCode(brand.bookOnBrand.defaultCurrencyCode)
    : null
}

/**
 * Currency logic steps:
 *
 * 1 - Forced query string parameter: check if forceCurrencyChange parameter is set to "1" in the URL and use step 3.
 * 2 - Cookie: check if currency code is already stored in a cookie and use its value.
 * 3 - Query string parameter: check if currency (or curr, when landing from GHA) parameter is present in the URL and use its value.
 * 4 - Brand: get currency code from brand config.
 * 5 - Domain: get currency code from country domain/subdomain.
 * 6 - User country: use the currency of the user country.
 **/
export const getCurrencyCodeConfig = (context: {
  userCountry: CountryType | undefined
}) =>
  getFromQueryStringIfForced() ||
  getFromCookie() ||
  getFromQueryString() ||
  getFromBrandConfig() ||
  getFromDomain() ||
  getFromUserCountry({userCountry: context.userCountry}) ||
  DEFAULT_CURRENCY_CODE

export const persistCurrencyCode = (currencyCode: string) => {
  const {secure, sameSite, maxAge: expires} = cookieConfig.options
  setCookie(cookieConfig.name, currencyCode, {
    expires,
    secure,
    sameSite
  })
}
