import {Hotel, HotelOfferEntity} from 'modules/sapiSearch/slice'
import {isLastBookedLabelVisible} from 'utils/lastBookedLabel'

import {offersHasPrivateDeal} from '@daedalus/core/src/offer/business/privateDeals'

enum HotelTrackingTag {
  PriceDrop = 'price_drop',
  MemberDeal = 'member_deal',
  MemberPlusDeal = 'member_plus_deal',
  LastBookedRecently = 'last_booked_recently'
}

export enum OfferDisplayedTrackingTag {
  LowestPrice = 'lowest_price',
  Gems = 'gems',
  SignInToUnlock = 'sign_in_to_unlock'
}

/**
 * Forms an array of 'hotelTags' used for tracking purposes on 'hotelContext'
 * @returns An array of tags
 */
export const listHotelTags = ({
  hotel,
  offerEntity,
  hotelIdsWithGreatDeal,
  shouldSeeOffersUnlocked
}: {
  hotel: Hotel
  offerEntity: HotelOfferEntity
  hotelIdsWithGreatDeal: Set<string>
  shouldSeeOffersUnlocked: boolean
}): HotelTrackingTag[] => {
  const tags = []

  if (hotelIdsWithGreatDeal.has(hotel.objectID))
    tags.push(HotelTrackingTag.PriceDrop)

  if (offersHasPrivateDeal(offerEntity?.offers, shouldSeeOffersUnlocked))
    tags.push(HotelTrackingTag.MemberDeal)

  if (isLastBookedLabelVisible(hotel.lastBooked))
    tags.push(HotelTrackingTag.LastBookedRecently)

  return tags
}

/**
 * Forms an array of 'displayedTags' used for tracking purposes on 'offerContext'
 * @returns An array of tags
 */
export const listDisplayedTagsForOffer = ({
  isCheapestOffer,
  isFirstOfferInList
}: {
  isCheapestOffer: boolean
  isFirstOfferInList: boolean
}): OfferDisplayedTrackingTag[] => {
  if (isCheapestOffer && isFirstOfferInList) {
    return [OfferDisplayedTrackingTag.LowestPrice]
  }

  return []
}
