import {loadModule} from 'utils'

export const Alerts = loadModule(
  async () =>
    import(
      /* webpackChunkName: "Alerts" */
      './Alerts'
    ),
  {}
)
