import axios from 'axios'

export const fetchShortUrl = async ({
  url,
  brandShareUrl
}: {
  url: string
  brandShareUrl: string
}): Promise<string | null | undefined> => {
  const {origin} = new URL(url)
  const urlPrefix =
    origin === 'https://secure.vio.com' ? 'https://www.vio.com/' : '/'

  const response = await axios
    .create()
    .post(`${urlPrefix}api/shortUrl`, {url, brandShareUrl})

  return response?.data?.url
}
