const ARROW_LEFT = 'ArrowLeft'
const ARROW_RIGHT = 'ArrowRight'
const KEYCODE_ARROW_LEFT = 37
const KEYCODE_ARROW_RIGHT = 39

interface Props {
  onLeft: () => void
  onRight: () => void
}

export const handleArrowKeys = ({onLeft, onRight}: Props) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === ARROW_LEFT || e.keyCode === KEYCODE_ARROW_LEFT) {
      onLeft?.()
    } else if (e.key === ARROW_RIGHT || e.keyCode === KEYCODE_ARROW_RIGHT) {
      onRight?.()
    }
  }

  window.addEventListener('keydown', handleKeyDown, true)

  return () => {
    window.removeEventListener('keydown', handleKeyDown, true)
  }
}
