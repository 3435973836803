import React, {memo} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getSearchType} from 'modules/sapiSearch/selectors'
import {SearchType} from 'modules/sapiSearch/utils'
import {SORT_FIELD_DISTANCE} from 'modules/search/constants'
import {FilterUrlParams, SortField, SortOrder} from 'modules/search/types'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Radio} from '@daedalus/atlas/Radio'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {
  SORT_FIELD_POPULARITY,
  SORT_ORDER_ASCENDING
} from '@daedalus/core/src/searchParams/constants'

import {SORT_BY, SORT_BY_WITH_DISTANCE, TIERS} from './constants'
import {getSortByLabels, shouldShowSortByWithDistance} from './helpers'

interface Props {
  sortField: SortField
  sortOrder: SortOrder
  onChange: (value: Pick<FilterUrlParams, 'sortField' | 'sortOrder'>) => void
}

const SortByContentComponent: React.FC<Props> = ({
  sortField,
  sortOrder = SORT_ORDER_ASCENDING,
  onChange
}) => {
  const {formatMessage} = useIntl()
  const {isMobile} = useDeviceLayout()
  const {brandIsVio} = useBrand()
  const sortLabels = getSortByLabels()
  const tier = brandIsVio ? TIERS.Gems : TIERS.PrivateDeal
  const searchType = useSelector(getSearchType)
  const sortByList = shouldShowSortByWithDistance(searchType)
    ? SORT_BY_WITH_DISTANCE
    : SORT_BY

  // in case of nearMe search,  default sort field should be distance and popularity otherwise
  const selectedSortField =
    sortField ||
    (searchType === SearchType.Location
      ? SORT_FIELD_DISTANCE
      : SORT_FIELD_POPULARITY)

  return (
    <div>
      {sortByList.map(({key, sortField: field, sortOrder: order}) => {
        const isActive = field === selectedSortField && order === sortOrder
        return (
          <Radio
            fullWidth
            hasTouchArea
            key={key}
            checked={isActive}
            name={`radio-${sortField}`}
            label={formatMessage(sortLabels[key], {
              tier
            })}
            onChange={() => onChange({sortField: field, sortOrder: order})}
            labelSize={isMobile ? 'bodyM' : 'bodyS'}
          />
        )
      })}
    </div>
  )
}

export const SortByContent = memo(SortByContentComponent)
