import {useSelector} from 'react-redux'

import {getIsBrandOffersLockedByDefault} from '../../_web/brand/modules/selectors'
import {selectIsAuthenticated} from '../modules/selectors'
import {useHasUnlockPlusTokenInUrl} from './useHasUnlockPlusTokenInUrl'
import {useIsAudience} from './useIsAudience'

export const useShouldSeeOffersUnlocked = () => {
  const brandOffersLockedByDefault = useSelector(
    getIsBrandOffersLockedByDefault
  )
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const isAudience = useIsAudience()
  const hasUnlockPlusToken = useHasUnlockPlusTokenInUrl()

  return (
    hasUnlockPlusToken ||
    isAuthenticated ||
    isAudience ||
    brandOffersLockedByDefault === false
  )
}
