import React, {FC} from 'react'
import {useOfferClickHandler} from 'hooks/useOfferClickHandler'

import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {useLogIn} from '@daedalus/shared/src/authentication/hooks/useLogIn'

import {LockedDealWrapper} from './styles'

interface Props {
  inViewRef: (node?: Element) => void
  role: string
  dataId: string
  onClick?: (offer: SearchOffer) => void
  component: string
  hotelId: string
  offer: SearchOffer
  offerPosition: number
  isDiscount: boolean
  isSplitBooking: boolean
}

export const LockedDeal: FC<Props> = ({
  dataId,
  inViewRef,
  children,
  component,
  hotelId,
  offer,
  offerPosition,
  onClick,
  isDiscount,
  isSplitBooking
}) => {
  const logIn = useLogIn()
  const {handleClick} = useOfferClickHandler({
    component,
    hotelId,
    offer,
    offerPosition,
    onClick,
    isDiscount,
    isSplitBooking,
    logIn
  })

  return (
    <LockedDealWrapper
      ref={inViewRef}
      role="button"
      data-id={dataId}
      onClick={handleClick}
    >
      {children}
    </LockedDealWrapper>
  )
}
