import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getUrlParams} from 'modules/search/selectors'
import {setIsMapOpen, setLayout} from 'modules/search/slice'
import {LayoutType} from 'modules/search/types'
import {toggle} from 'opticks'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'

/**
 * Hook used to handle default layout logic
 */
export const useDefaultLayout = () => {
  const dispatch = useDispatch()
  const {isDesktopMd, isDesktopLg} = useDeviceLayout()
  const {brandHasMap} = useBrand()
  const {layout: layoutUrlParam} = useSelector(getUrlParams) || {}

  // Default to SPLIT layout on desktop if map view is enabled, otherwise use LIST layout
  const defaultLayout =
    brandHasMap && (isDesktopMd || isDesktopLg)
      ? toggle('feature-static-srp', LayoutType.SPLIT, LayoutType.LIST)
      : LayoutType.LIST

  const layout = layoutUrlParam || defaultLayout

  const isMapVisible = layout === LayoutType.MAP || layout === LayoutType.SPLIT

  const isListVisible =
    layout === LayoutType.LIST || layout === LayoutType.SPLIT

  // This effect is used to update the layout and map visibility when the layout changes
  // We check if layout is not undefined to avoid setting an invalid value
  useEffect(() => {
    dispatch(setLayout(layout))
    dispatch(setIsMapOpen(isMapVisible))
  }, [dispatch, layout, isMapVisible])

  return {
    layout,
    isListVisible,
    isMapVisible
  }
}
