import React from 'react'
import {ColorPath} from 'types/Theme'

import {Icon, IconSize} from '@daedalus/atlas/Icon'

const categoryIcons = {
  general: 'Building',
  activities: 'Baseball',
  internet: 'Wifi',
  parking: 'Parking',
  services: 'ServiceBell'
}

interface Props {
  categoryId: string
  size?: IconSize
  colorPath?: ColorPath
}
export const CategoryIcon: React.FC<Props> = ({
  categoryId,
  size = 'md',
  colorPath = 'content.neutral.c600'
}) => {
  const iconName = categoryIcons[categoryId] || categoryIcons.general

  return <Icon name={iconName} size={size} colorPath={colorPath} />
}
