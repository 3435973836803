import {GeolocationConfig} from 'types/Localization'
import {GeolocationSource} from 'utils/geolocation'

export const getShowNearbySuggestion = ({
  destinationPickerInputDisplayValue,
  suggestionsSearchValue,
  isReactNative,
  geolocation,
  geolocationSource
}: {
  destinationPickerInputDisplayValue: string
  suggestionsSearchValue: string
  isReactNative: boolean
  geolocation: GeolocationConfig
  geolocationSource: GeolocationSource
}): boolean => {
  // The user has started typing in the destination picker
  const destinationFieldIsBlank =
    destinationPickerInputDisplayValue === suggestionsSearchValue ||
    suggestionsSearchValue === ''
  // the user is in react native and has geolocation (ip based) available
  const isReactNativeWithIpLocation =
    isReactNative && geolocation && geolocationSource === GeolocationSource.Ip
  return (
    // show when user hasn't typed and
    // either is in React Native and have IP location,
    // or is not in React Native at all (Can enable IP or GPS location)
    destinationFieldIsBlank && (isReactNativeWithIpLocation || !isReactNative)
  )
}
