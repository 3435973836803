import {setCookie} from '../../utils/cookies'
import {
  appLockedAnchorDealImpressionsCountConfig,
  firstAppLockedAnchorDealImpressionsTimestampConfig,
  lastAppLockedAnchorDealImpressionsTimestampConfig
} from './cookieConfig'
import {getAppLockedAnchorDealImpressionsCount} from './getAppLockedAnchorDealImpressionsCount'
import {getFirstAppLockedAnchorDealImpressionsTimestamp} from './getFirstAppLockedAnchorDealImpressionsTimestamp'
import {getLastAppLockedAnchorDealImpressionsTimestamp} from './getLastAppLockedAnchorDealImpressionsTimestamp'

export const persistAppLockedDealImpressionsCount = (
  isAnchorAppLockedDeal: boolean
): {
  anchorCount: number
  firstAppLockedAnchorDealImpressionsTimestamp?: string
} => {
  const appLockedAnchorDealImpressionsCount =
    getAppLockedAnchorDealImpressionsCount()
  const now = new Date()

  const lastAppLockedAnchorDealImpressionsTimestamp =
    getLastAppLockedAnchorDealImpressionsTimestamp()
  const lastAppLockedAnchorDealImpressionsDate = new Date(
    lastAppLockedAnchorDealImpressionsTimestamp || now
  )

  const firstAppLockedAnchorDealImpressionsTimestamp =
    getFirstAppLockedAnchorDealImpressionsTimestamp()

  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)

  let anchorCount = appLockedAnchorDealImpressionsCount || 0

  const {
    secure,
    sameSite,
    domain,
    maxAge: expires
  } = appLockedAnchorDealImpressionsCountConfig.options

  if (isAnchorAppLockedDeal) {
    if (!firstAppLockedAnchorDealImpressionsTimestamp) {
      setCookie(
        firstAppLockedAnchorDealImpressionsTimestampConfig.name,
        now.toISOString(),
        {
          expires,
          secure,
          sameSite,
          domain
        }
      )
    }

    if (
      lastAppLockedAnchorDealImpressionsDate < twentyFourHoursAgo ||
      !lastAppLockedAnchorDealImpressionsTimestamp
    ) {
      anchorCount++
      setCookie(
        appLockedAnchorDealImpressionsCountConfig.name,
        anchorCount.toString(),
        {
          expires,
          secure,
          sameSite,
          domain
        }
      )
      setCookie(
        lastAppLockedAnchorDealImpressionsTimestampConfig.name,
        now.toISOString(),
        {
          expires,
          secure,
          sameSite,
          domain
        }
      )
    }
  }

  return {
    anchorCount,
    firstAppLockedAnchorDealImpressionsTimestamp
  }
}
