import React, {useCallback, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useFiltersForm} from 'hooks/useFiltersForm'
import isNil from 'lodash/isNil'
import {showPropertyTypeFilter} from 'modules/overlay/actions'
import {searchParamToFacet} from 'modules/search/constants'
import {getListOfValues} from 'modules/search/selectors'
import {takeLast} from 'ramda'
import {getFacetLabel} from 'utils/filters'

import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

import {PropertyTypeFilterBottomSheet} from './PropertyTypeFilterBottomSheet'

const facetLabel = 'PropertyType'

interface Props {
  isActive?: boolean
}

export const PropertyTypeFilterPill: React.FC<Props> = ({isActive = false}) => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()
  const listOfValues = useSelector(getListOfValues)

  // Store the facetKey in a ref to simulate an instance variable that won't change when the component re-renders
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const facetFilterKeys = useRef([searchParamToFacet.propertyTypes.param])
  const facetFilterKey = facetFilterKeys.current[0]
  const labelMessage = formatMessage(filterMessages.propertyType)
  const getLabel = getFacetLabel(facetLabel, listOfValues)
  const {getAppliedFilters, trackFilterClick} = useFiltersForm(
    facetFilterKeys.current
  )

  const appliedFacetFilter = getAppliedFilters()[facetFilterKey]

  const buildFacetsLabel = useCallback(() => {
    if (isNil(appliedFacetFilter) || isNil(listOfValues)) {
      return labelMessage
    }

    if (appliedFacetFilter.length === 1) {
      return getLabel(appliedFacetFilter[0]) as string
    }

    return `${getLabel(takeLast(1, appliedFacetFilter).toString())} +${
      appliedFacetFilter.length - 1
    }`
  }, [getLabel, appliedFacetFilter, listOfValues])

  const handleOnPillClick = () => {
    dispatch(showPropertyTypeFilter())
    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })
  }

  return (
    <>
      <Pill
        onClick={handleOnPillClick}
        dataId="propertyTypeFilter"
        isSelected={isActive}
        showArrow
      >
        {buildFacetsLabel()}
      </Pill>
      <PropertyTypeFilterBottomSheet />
    </>
  )
}
