import {loadModule} from 'utils'

export const Account = loadModule(
  async () =>
    import(
      /* webpackChunkName: "Account" */
      './Account'
    ),
  {}
)
