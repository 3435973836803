import {useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FOOTER_OFFER_COMPONENT} from 'components/Accommodation/RoomsDetailOverlayContainer/constants'
import {useHotelRoomsFilters} from 'hooks/useHotelRoomsFilters'
import {getOfferContextFromRoomContext} from 'middleware/analytics/contexts/roomContext'
import {getRoomContext} from 'middleware/analytics/selectors'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {Offer} from '@daedalus/core/src/offer/types/offer'
import {getDisplayPrice} from '@daedalus/core/src/price/business/price'
import {roomsFilterListToMap} from '@daedalus/core/src/room/business/utils/filters'
import {Room} from '@daedalus/core/src/room/types/room'

type Props = {
  offer: Offer
  room: Room
}

export const useTrackRoomOfferViewed = ({offer, room}: Props) => {
  const dispatch = useDispatch()
  const {activeRoomsFilters} = useHotelRoomsFilters()

  const roomContext = useSelector(state => getRoomContext(state, room?.id))
  const offerContext =
    roomContext && getOfferContextFromRoomContext(roomContext, offer?.id)

  const analyticsContext = useMemo(() => {
    return {
      [AnalyticsContext.RoomContext]: roomContext,
      [AnalyticsContext.OfferContext]: offerContext
    }
  }, [offerContext, roomContext])

  const getPayload = () => {
    if (!offer) return {}

    const {prices, isClicked, matchType, matchedOfferPriceDiff} = offer

    const displayPrice = getDisplayPrice(prices)

    const {base: baseRateDisplayPrice, taxes: taxesDisplayPrice} =
      displayPrice.chargeable

    const hotelFeesPrice = displayPrice.hotelFees.total
    const singleRoomTotal =
      baseRateDisplayPrice + hotelFeesPrice + taxesDisplayPrice

    const matchedOfferPriceDiffPercent =
      (matchedOfferPriceDiff / singleRoomTotal) * 100

    return {
      matchedOfferPriceDiffPercent,
      isClicked,
      matchType,
      roomFilterApplied: activeRoomsFilters.length > 0,
      allSelectedRoomFilters: roomsFilterListToMap(activeRoomsFilters)
    }
  }

  return {
    trackOfferViewed: () => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.RoomOffer,
          action: Action.Viewed,
          team: Team.Select,
          page: Page.Accommodation,
          component: FOOTER_OFFER_COMPONENT,
          payload: getPayload(),
          analyticsContext
        })
      )
    }
  }
}
