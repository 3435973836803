import {mapObjIndexed} from 'ramda'

export enum Breakpoints {
  desktopLg = 'desktopLg',
  desktopMd = 'desktopMd',
  desktopSm = 'desktopSm',
  desktopXs = 'desktopXs',
  mobileLg = 'mobileLg',
  mobileSm = 'mobileSm'
}

export const breakpoints = {
  [Breakpoints.mobileSm]: 0,
  [Breakpoints.mobileLg]: 411,
  [Breakpoints.desktopXs]: 744,
  [Breakpoints.desktopSm]: 960,
  [Breakpoints.desktopMd]: 1280,
  [Breakpoints.desktopLg]: 1920
} as const

export type BreakpointType = keyof typeof breakpoints

export const deviceWidthToBreakpointsMapping = (
  viewportWidth: number
): BreakpointType => {
  viewportWidth = Math.max(viewportWidth, 0)

  const breakpointDeviceMap: BreakpointType[] = [
    Breakpoints.desktopLg,
    Breakpoints.desktopMd,
    Breakpoints.desktopSm,
    Breakpoints.desktopXs,
    Breakpoints.mobileLg,
    Breakpoints.mobileSm
  ]

  const currentBreakpoint: BreakpointType | null | undefined =
    breakpointDeviceMap.find(breakpoint => {
      return viewportWidth >= breakpoints[breakpoint]
    })

  return currentBreakpoint || Breakpoints.mobileLg
}

export default breakpoints

const linariaCreateBreakpoint = (label: string | number) => {
  const prefix = typeof label === 'string' ? '' : 'min-width:'
  const suffix = typeof label === 'string' ? '' : 'px'

  return `@media (${prefix + label + suffix})`
}

export const linariaCustomMq = (label: number): string =>
  linariaCreateBreakpoint(label)

export const linariaMq = {
  ...mapObjIndexed(linariaCreateBreakpoint, breakpoints),
  retina: `@media only screen and (-webkit-min-device-pixel-ratio: 2)`
}
