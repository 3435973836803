import {persistedUTMParamsKeys} from 'modules/search/constants'
import {pick} from 'ramda'

import urls from '@daedalus/core/src/utils/url'

/**
 * Factory function that return utm params from URL
 * @returns utm params
 */
export const getUtmParamsFromURL = () => {
  const {searchParams} = new URL(window.location.href)
  return pick(persistedUTMParamsKeys, Object.fromEntries(searchParams))
}

/**
 * Factory function that return URl with persisted utm params
 * @param hasOrigin  - return url with origin
 * @returns string
 */
export const getHomeUrlWithUTMParams = (hasOrigin = true): string => {
  const {origin} = new URL(window.location.href)

  const rootURL = urls.root
  const utmParams = getUtmParamsFromURL()
  const params = new URLSearchParams(utmParams)
  const stringPrams = params.toString()

  return `${hasOrigin ? origin : ''}${rootURL}${
    stringPrams ? ['?', stringPrams].join('') : ''
  }`
}
