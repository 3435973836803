import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import {resetSearchData} from 'modules/search/slice'
import {
  setSearchBoxDestinationDisplayValue,
  setSearchBoxValues
} from 'modules/searchBox/slice'
import {getUserSearchHistory} from 'utils/persistence'

import {validateSearchParamDates} from '@daedalus/core/src/searchParams/validateSearchParamDates'
import {dateToMiddayDate, UTS_DATE_FORMAT} from '@daedalus/core/src/utils/date'
import {UrlParamsType} from '@daedalus/core/src/utils/url/types/UrlParams'

/**
 * Syncs `searchBox` state with user search history or sets default values
 */
export const useSyncHomePageSearchBox = () => {
  const dispatch = useDispatch()
  const today = dateToMiddayDate(new Date())
  const tomorrow = addDays(today, 1)
  const todayString = format(today, UTS_DATE_FORMAT)
  const tomorrowString = format(tomorrow, UTS_DATE_FORMAT)

  useEffect(() => {
    const userSearchHistory = getUserSearchHistory()
    const latestUserSearch = userSearchHistory?.[0]

    if (latestUserSearch) {
      dispatch(
        setSearchBoxDestinationDisplayValue(
          // TODO: Search TS preexisting issue
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore

          // To support SAPI format
          latestUserSearch.searchData.hotelName ??
            latestUserSearch.searchData.placeName ??
            latestUserSearch.searchData.placeDisplayName
        )
      )
      dispatch(
        setSearchBoxValues(
          validateSearchParamDates(
            latestUserSearch.searchParams as unknown as Partial<UrlParamsType>
          )
        )
      )
    } else {
      dispatch(setSearchBoxDestinationDisplayValue(''))
      dispatch(
        setSearchBoxValues({
          checkIn: todayString,
          checkOut: tomorrowString
        })
      )
      dispatch(resetSearchData())
    }
  }, [dispatch])
}
