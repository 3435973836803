import {getCurrencyCode, getLocaleCode} from 'modules/meta/selectors'
import {SearchStartedPayload} from 'modules/sapiSearch/slice'
import {
  getIsDefaultDatesSearch,
  getIsUserSearch
} from 'modules/search/selectors'
import {SortField, SortOrder} from 'modules/search/types'
import {RootState} from 'store'

import {
  SORT_FIELD_POPULARITY,
  SORT_ORDER_ASCENDING
} from '@daedalus/core/src/searchParams/constants'

export interface TrackHotelsSearchedParameters {
  checkIn: string
  checkOut: string
  currency: string
  hotelId: string | null | undefined
  isDefaultDates: boolean
  isUserSearch: boolean
  locale: string
  placeId: string | null | undefined
  rooms: string
  searchId: string | null | undefined
  sortField: SortField
  sortOrder: SortOrder
}

/**
 * @deprecated
 * This is only used for DD payload it used to be used also for old legacy events
 * TODO: @(Search) - the new search anatlytics contexts to DD instead
 */
export const getTrackHotelsSearchedParamsForDD = (
  state: RootState,
  {payload}: {payload: SearchStartedPayload}
): TrackHotelsSearchedParameters => {
  const {searchId, searchParameters} = payload
  const {
    checkIn,
    checkOut,
    hotelId,
    placeId,
    rooms,
    sortField = SORT_FIELD_POPULARITY,
    sortOrder = SORT_ORDER_ASCENDING
  } = searchParameters

  const currencyCode = getCurrencyCode(state)
  const locale = getLocaleCode(state)
  const isDefaultDates = getIsDefaultDatesSearch(state)

  return {
    checkIn,
    checkOut,
    currency: currencyCode,
    hotelId,
    isUserSearch: getIsUserSearch(state),
    isDefaultDates,
    locale,
    placeId,
    rooms,
    searchId,
    sortField: sortField as SortField,
    sortOrder: sortOrder as SortOrder
  }
}
