import {getRoomsActiveFilters} from 'modules/accommodation/selectors'
import {isVioOfferOrBundleSelected} from 'modules/accommodation/utils'
import {getDeviceLayout} from 'modules/meta/selectors'
import {
  getAppLockedDealConfig,
  getNumberOfRooms,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {HotelOfferEntity} from 'modules/sapiSearch/slice'
import {getIsOTAMode} from 'modules/search/selectors'
import {getOffersSearchContext} from 'modules/searchApi/selectors'
import {once} from 'ramda'
import {Dispatch} from 'redux'
import {RootState} from 'store'

import {
  logTimeEnd,
  logTimeStart
} from '@daedalus/core/src/_web/utils/logging/performanceTimer'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  SearchContext,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {
  getShouldSeeOffersUnlocked,
  selectShouldSeeOffersUnlocked
} from '@daedalus/core/src/auth/modules/selectors'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {offersHasPrivateDeal} from '@daedalus/core/src/offer/business/privateDeals'
import {OfferTagLabel} from '@daedalus/core/src/offer/types/offer'
import {
  applyRoomsFilterMemo,
  roomsFilterListToMap
} from '@daedalus/core/src/room/business/utils/filters'
import {sortRoomsAndSplitBooking} from '@daedalus/core/src/room/business/utils/sortRoomsAndSplitBooking'
import {SapiAbstractOfferType} from '@daedalus/core/src/room/types/room'
import {searchApi} from '@daedalus/core/src/sapi/services/searchApi'
import {
  offersSearchErrored,
  offersSearchReceived,
  offersSearchStarted,
  offersSearchSucceeded,
  roomSearchStarted,
  roomSearchSucceeded
} from '@daedalus/core/src/sapi/services/searchApi/action'

import {MiddlewareType} from '..'
import {trackTimingEvent} from '.'
import {buildOfferContext} from './contexts/offerContext'
import {
  getHotelContext,
  getIsRoomsSearchManuallyTriggered,
  getPaginationContext,
  getRoomContext,
  getSearchContext,
  getSearchResultsContext,
  getTopOffersOfferContexts
} from './selectors'
import {getRoomsOffersMatchingData} from './utils/getRoomsOffersMatchingData'
import {logEvent} from './utils/logEvent'

const ROOM_SEARCH_SOURCE_COMPONENT = 'HotelViewRoomsSection'
const ROOM_SEARCH_APPLIED_FILTER = 'ap_roomsFilter'

enum MatchFlow {
  Other = 'Other',
  VioClicker = 'vioOfferClicker'
}

const getAllOffersContexts = (
  state: RootState,
  offerEntity: HotelOfferEntity
) => {
  const allOffers = offerEntity?.offers.map(offer => {
    return buildOfferContext(
      offer.id,
      '',
      offerEntity,
      offer,
      getNumberOfRooms(state, undefined),
      selectShouldSeeOffersUnlocked(state),
      getIsOTAMode(state),
      getAppLockedDealConfig(state, offerEntity?.id, offer?.id),
      getSplitBookingDetails(state, offerEntity?.id),
      getDeviceLayout(state)
    )
  })
  return allOffers
}

const searchResultsContextForOffers = (
  state: RootState,
  offers: HotelOfferEntity
) => {
  const shouldSeeOffersUnlocked = getShouldSeeOffersUnlocked(state)
  const anchorHotelHasPrivateDeal = offersHasPrivateDeal(
    offers?.offers,
    shouldSeeOffersUnlocked
  )
  const anchorHotelHasYourChoice = offers?.offers.some(offer =>
    hasOfferTag(offer, OfferTagLabel.IsYourChoice)
  )
  const {hotelId} = state.accommodation
  const hotelsMetaData = [
    {
      ...getHotelContext(state, hotelId),
      // get top offers from sapiSearch state
      topOffers: getTopOffersOfferContexts(state, hotelId),
      // get all offers from searchApi search result
      allOffers: getAllOffersContexts(state, offers)
    }
  ]

  return {
    algoliaNbHotels: 1,
    algoliaTotalNbHotels: 1,
    anchorHotelHasPrivateDeal,
    anchorHotelHasYourChoice,
    hotelIndexesWithPrivateDeals: anchorHotelHasPrivateDeal
      ? [state.accommodation.hotelId]
      : [],
    hotelsMetaData,
    nbHotels: 1,
    staticHotelsLength: 1,
    staticHotelsOffset: 0,
    unavailableHotels: null, // this relates only to hotel list which we don't have here
    pageNumber: 1,
    pageOffset: 0,
    pageLength: 1
  }
}

const trackOffersSearchRecieved = once((dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'OffersSearchRecieved',
    'offersSearch-start',
    Entity.Search,
    Team.Select
  )
})

const trackOffersSearchRecievedTiming = (dispatch: Dispatch) => {
  trackTimingEvent(
    dispatch,
    'OffersReceived',
    'offersSearch-start',
    Entity.Search,
    Team.Select
  )
}

const middleware: MiddlewareType = store => next => async action => {
  const state = store.getState()
  const {dispatch} = store
  switch (action.type) {
    case offersSearchStarted.type: {
      const {parameters, searchId} = action.payload
      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const searchContext: SearchContext = {
        ...getSearchContext(state, searchId),
        ...getOffersSearchContext(state),
        ...(parameters.getAllOffers ? {isUserSearch: false} : {})
      }

      logEvent('OffersSearch', parameters)
      logTimeStart('offersSearch')

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Requested,
          analyticsContext: {
            [AnalyticsContext.PaginationContext]: getPaginationContext(state),
            [AnalyticsContext.SearchContext]: searchContext
          },
          team: Team.Select
        })
      )
      break
    }

    case offersSearchReceived.type: {
      trackOffersSearchRecieved(dispatch)
      break
    }

    case offersSearchSucceeded.type: {
      const {offers, parameters, searchId} = action.payload
      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const searchContext: SearchContext = {
        ...getSearchContext(state, searchId),
        ...getOffersSearchContext(state),
        ...(parameters.getAllOffers ? {isUserSearch: false} : {})
      }

      trackOffersSearchRecievedTiming(dispatch)
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Succeeded,
          analyticsContext: {
            [AnalyticsContext.PaginationContext]: getPaginationContext(state),
            [AnalyticsContext.SearchContext]: searchContext,
            [AnalyticsContext.SearchResultsContext]:
              searchResultsContextForOffers(state, offers)
          },
          payload: {
            // currently viewed hotel is considered as "anchor" and its availability is based on found offers
            isAnchorHotelAvailable: offers.offers.length > 0
          },
          team: Team.Select
        })
      )
      break
    }

    case offersSearchErrored.type: {
      // TODO: Search TS preexisting issue
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const searchContext: SearchContext = {
        ...getSearchContext(state),
        ...getOffersSearchContext(state)
      }

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Search,
          action: Action.Errored,
          analyticsContext: {
            [AnalyticsContext.PaginationContext]: getPaginationContext(state),
            [AnalyticsContext.SearchContext]: searchContext
          },
          team: Team.Select
        })
      )
      break
    }

    case roomSearchStarted.type: {
      const activeRoomsFilters = getRoomsActiveFilters(state)
      const isUserSearch = getIsRoomsSearchManuallyTriggered(state)

      const urlParams = window
        ? new URLSearchParams(window.location.search)
        : null
      const redirectId = urlParams?.get('redirectId') || undefined

      // We only want to start timers for events which are triggered by real SAPI.rooms call
      // Events triggered without calling SAPI.rooms should not be "timed" (f.e. when using rooms filters)
      const roomsRequestId = action.payload?.roomsRequestId
      if (roomsRequestId) {
        logTimeStart(`${Entity.RoomSearch}-${roomsRequestId}`)
      }

      const component =
        activeRoomsFilters.length > 0
          ? ROOM_SEARCH_APPLIED_FILTER
          : ROOM_SEARCH_SOURCE_COMPONENT

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.RoomSearch,
          action: Action.Requested,
          component,
          page: Page.Accommodation,
          payload: {
            isUserSearch,
            redirectId
          },
          team: Team.Select
        })
      )
      break
    }

    case roomSearchSucceeded.type: {
      const activeRoomsFilters = getRoomsActiveFilters(state)
      const isUserSearch = getIsRoomsSearchManuallyTriggered(state)

      const urlParams = window
        ? new URLSearchParams(window.location.search)
        : null
      const redirectId = urlParams?.get('redirectId') || undefined

      const roomsRequestId = action.payload?.roomsRequestId

      const roomSearchDuration = roomsRequestId
        ? logTimeEnd(`${Entity.RoomSearch}-${roomsRequestId}`)
        : undefined

      //Bypassing using getFilteredRoomsData selector as it's using current location/url state
      //and we need to use the state at the time of the action (AP might be closed or user might have navigated to another page)
      // - Using params passed along with action payload always connects it with the cached query data
      const {data: roomsData} = searchApi.endpoints.getRooms.select(
        action.payload.params
      )(state)
      const {splitBooking: stateSplitBooking} = roomsData
      const {rooms, totalResults, availableFilters, splitBooking} =
        applyRoomsFilterMemo(roomsData, activeRoomsFilters)
      const sortedRoomsAndSplitBooking = sortRoomsAndSplitBooking(
        rooms,
        splitBooking
      )

      const component =
        activeRoomsFilters.length > 0
          ? ROOM_SEARCH_APPLIED_FILTER
          : ROOM_SEARCH_SOURCE_COMPONENT

      const matchingProps = getRoomsOffersMatchingData(rooms, splitBooking)

      const matchFlow = isVioOfferOrBundleSelected()
        ? MatchFlow.VioClicker
        : MatchFlow.Other

      const payload = {
        redirectId,
        isUserSearch,
        isAnyRoomAvailable: !!totalResults,
        roomFiltersDisplayed: availableFilters,
        roomFiltersApplied: roomsFilterListToMap(activeRoomsFilters),
        roomSearchDuration,
        ...matchingProps,
        matchFlow
      }

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.RoomSearch,
          action: Action.Succeeded,
          component,
          page: Page.Accommodation,
          payload,
          team: Team.Select,
          analyticsContext: {
            [AnalyticsContext.SearchResultsContext]:
              getSearchResultsContext(state),
            [AnalyticsContext.RoomContextList]: rooms.map(room =>
              getRoomContext(state, room.id, rooms)
            ),
            [AnalyticsContext.SplitBooking]: stateSplitBooking
              ? {
                  ...stateSplitBooking,
                  isHidden: !splitBooking,
                  roomPosition:
                    sortedRoomsAndSplitBooking.findIndex(
                      entity =>
                        entity.type === SapiAbstractOfferType.SplitBooking
                    ) + 1
                }
              : null
          }
        })
      )
      break
    }

    default: {
      break
    }
  }

  return next(action)
}

export default middleware
