import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {
  getAnonymousId,
  getCurrencyCode,
  getDeviceId,
  getLocaleCode,
  getUserCountryCode
} from 'modules/meta/selectors'
import {
  getAnchorHotelCountry,
  getAppLockedDealConfig,
  getFirstHotelCountry,
  getIsAnchorHotel,
  getNumberOfRooms
} from 'modules/sapiSearch/selectors'
import {SearchOffer} from 'modules/sapiSearch/slice'
import {
  getClickId,
  getFreeCancellation,
  getGsiteParam,
  getIsOptimizeRooms,
  getIsOTAMode,
  getPersistedUTMParams,
  getProfileUrlParam,
  getUrlParams
} from 'modules/search/selectors'
import {SearchUrlParams} from 'modules/search/types'
import {curry, identity, omit, pipe} from 'ramda'
import {SearchParamsType} from 'types/Search'
import {
  augmentBookURIWithAmenities,
  augmentBookURIWithClickId,
  augmentBookURIWithDeviceId,
  augmentBookURIWithFreeCancellation,
  augmentBookURIWithGsiteParam,
  augmentBookURIWithHotelCountry,
  augmentBookURIWithOptimizeRooms,
  augmentBookURIWithOta,
  augmentBookURIWithPlusTokenParam,
  augmentBookURIWithProfileParam,
  augmentBookURIWithSignUpTokenParam,
  augmentBookUriWithUserCountry,
  augmentBookURIWithUtmParams,
  generateRedirectLink
} from 'utils/offers'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {useIsAudience} from '@daedalus/core/src/auth/hooks/useIsAudience'
import {getIsPrivateDeal} from '@daedalus/core/src/auth/modules/selectors'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {
  augmentBookUriWithAnonymousId,
  hasOfferTag
} from '@daedalus/core/src/offer/business/offers'
import {
  hasExpectedGsiteValueInUrl,
  hasUnlockPlusTokenInUrl
} from '@daedalus/core/src/offer/business/privateDeals'
import {OfferTagLabel} from '@daedalus/core/src/offer/types/offer'

import useGenerateAppDownloadUrl from './useGenerateAppDownloadUrl'

interface Props {
  anchorPriceTotal: number
  offer: SearchOffer
  hotelId?: string
}

// The pipe is pretty legible despite the amount of conditionals so the complexity limit is upped a bit
/* eslint complexity: ["error", 28] */
const useRedirectLink = ({anchorPriceTotal, offer, hotelId}: Props): string => {
  const isAnchorHotel = useSelector(state => getIsAnchorHotel(state, hotelId))

  const {brand} = useBrand()
  const {hideInternalRedirect} = brand
  const localeCode = useSelector(getLocaleCode)
  const currencyCode = useSelector(getCurrencyCode)
  const anonymousId = useSelector(getAnonymousId)
  const utmParams = useSelector(getPersistedUTMParams)
  const urlParams = useSelector(getUrlParams) as SearchUrlParams
  const gsiteParam = useSelector(getGsiteParam)
  const profileUrlParam = useSelector(getProfileUrlParam)
  const isOta = useSelector(getIsOTAMode)
  const anchorHotelCountry = useSelector(getAnchorHotelCountry)
  const firstHotelCountry = useSelector(getFirstHotelCountry)
  const clickId = useSelector(getClickId)
  const hotelCountry = anchorHotelCountry || firstHotelCountry
  const userCountry = useSelector(getUserCountryCode)
  const isAudience = useIsAudience()
  const freeCancellation = useSelector(getFreeCancellation)
  const {amenities = []} = urlParams || {}
  const isGems = useSelector(getIsPrivateDeal(offer))
  const isOptimizeRooms = useSelector(getIsOptimizeRooms)
  const numberOfRooms = useSelector(state => getNumberOfRooms(state, hotelId))
  const deviceId = useSelector(getDeviceId)
  const {isAppLockedDeal, appDownloadLinkId: placement} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )
  const isBofhOffer = useMemo(() => getIsBofhOffer(offer), [offer])

  const totalPrice = offer?.totalRate

  const {generateAppLockedDealDeepLink} = useGenerateAppDownloadUrl(Page.Search)
  const appDownloadUrl = generateAppLockedDealDeepLink(placement, {
    hotelId,
    clickedOfferRate: totalPrice,
    currencyCode
  })

  if (!offer) return undefined

  if (isAppLockedDeal) {
    return appDownloadUrl
  }

  const {currency, intermediaryProvider, url} = offer

  const shouldAddSignUpToken = isBofhOffer && isAudience

  const shouldAddPlusToken = isBofhOffer && hasUnlockPlusTokenInUrl(urlParams)
  const shouldAddGsiteParam =
    isBofhOffer && hasExpectedGsiteValueInUrl(urlParams as SearchParamsType)

  const redirectURI = pipe<
    [string],
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  >(
    curry(augmentBookUriWithAnonymousId)(anonymousId),
    shouldAddSignUpToken ? augmentBookURIWithSignUpTokenParam : identity,
    shouldAddPlusToken ? augmentBookURIWithPlusTokenParam : identity,
    isBofhOffer && deviceId
      ? curry(augmentBookURIWithDeviceId)(deviceId)
      : identity,
    shouldAddGsiteParam
      ? curry(augmentBookURIWithGsiteParam)(gsiteParam)
      : identity,
    isBofhOffer
      ? curry(augmentBookURIWithUtmParams)(omit(['label'], utmParams))
      : identity,
    isBofhOffer
      ? curry(augmentBookURIWithHotelCountry)(hotelCountry)
      : identity,
    isBofhOffer ? curry(augmentBookUriWithUserCountry)(userCountry) : identity,
    isBofhOffer && clickId
      ? curry(augmentBookURIWithClickId)(clickId)
      : identity,
    isBofhOffer && isOta ? curry(augmentBookURIWithOta)(isOta) : identity,
    isBofhOffer && !isAnchorHotel
      ? curry(augmentBookURIWithFreeCancellation)(freeCancellation)
      : identity,
    isBofhOffer
      ? curry(augmentBookURIWithOptimizeRooms)(isOptimizeRooms)
      : identity,
    isBofhOffer && profileUrlParam
      ? curry(augmentBookURIWithProfileParam)(profileUrlParam)
      : identity,
    isBofhOffer && amenities.length && !isAnchorHotel
      ? curry(augmentBookURIWithAmenities)(amenities)
      : identity
  )(url)

  const redirectParams = {
    anchorPrice: anchorPriceTotal * numberOfRooms,
    currency,
    providerCode: intermediaryProvider,
    redirectURI,
    totalPrice: totalPrice,
    lang: localeCode,
    yourChoice: hasOfferTag(offer, OfferTagLabel.IsYourChoice),
    isGems
  }

  const redirectLink = generateRedirectLink(
    redirectParams,
    hideInternalRedirect,
    isBofhOffer
  )

  return redirectLink
}

export default useRedirectLink
