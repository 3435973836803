import {defineMessages} from 'react-intl'
import {GuestType, RatingType} from 'types/Hotel'

type GuestTypeKey = keyof GuestType
type RatingKey = keyof RatingType
type ReviewLabelKey = Exclude<GuestTypeKey | RatingKey, 'overall' | 'pricing'> // These keys will be required

export const reviewsLabels = defineMessages<ReviewLabelKey>({
  business: {
    id: 'business',
    defaultMessage: 'Business'
  },
  couples: {
    id: 'couple',
    defaultMessage: 'Couple'
  },
  families: {
    id: 'family',
    defaultMessage: 'Family'
  },
  groups: {
    id: 'group',
    defaultMessage: 'Group'
  },
  solo: {
    id: 'solo',
    defaultMessage: 'Solo'
  },
  cleanliness: {
    id: 'cleanliness',
    defaultMessage: 'Cleanliness'
  },
  dining: {
    id: 'dining',
    defaultMessage: 'Dining'
  },
  facilities: {
    id: 'facilities',
    defaultMessage: 'Facilities'
  },
  location: {
    id: 'location',
    defaultMessage: 'Location'
  },
  rooms: {
    id: 'rooms',
    defaultMessage: 'Rooms'
  },
  service: {
    id: 'service',
    defaultMessage: 'Service'
  }
})
