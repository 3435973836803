import {GuestReviewLayout} from './constants'

export enum GuestReviewLayoutConfig {
  ReviewsRating = 'reviewsRating',
  ReviewsCarousel = 'reviewsCarousel',
  ReviewsSummary = 'reviewsSummary',
  ReviewComments = 'reviewComments'
}

const layoutConfigDefault = [
  GuestReviewLayoutConfig.ReviewsRating,
  GuestReviewLayoutConfig.ReviewsCarousel,
  GuestReviewLayoutConfig.ReviewsSummary
]
const layoutConfigFull = [
  GuestReviewLayoutConfig.ReviewsRating,
  GuestReviewLayoutConfig.ReviewsSummary,
  GuestReviewLayoutConfig.ReviewComments
]

export const layoutConfigMapping = {
  [GuestReviewLayout.DEFAULT]: layoutConfigDefault,
  [GuestReviewLayout.FULL]: layoutConfigFull
}
