import React from 'react'

import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {
  AlarmIconWrapper,
  ExitPopupHeaderContentWrapper,
  HeaderElement
} from './styles'

interface Props {
  variant: 'searchResultsPage' | 'accommodationPage' | 'loading'
}

export const ExitPopupHeader = ({variant}: Props) => {
  switch (variant) {
    case 'loading':
      return (
        <Text variant="titleL" colorPath="content.neutral.c000">
          <FormattedMessageWrapper
            dataId="searchingTravelSites"
            id="searchingTravelSites"
            defaultMessage="Searching all the best travel sites"
          />
        </Text>
      )
    case 'searchResultsPage':
      return (
        <HeaderElement justifyContent="space-between" alignItems="center">
          <ExitPopupHeaderContentWrapper
            justifyContent="center"
            alignItems="center"
          >
            <AlarmIconWrapper>
              <Icon name="Bell" size="md" />
            </AlarmIconWrapper>
            <Text variant="titleL">
              <FormattedMessageWrapper
                id="bestDealModal.greatDealAtThisHotelTitle"
                defaultMessage="We found you a great deal!"
              />
            </Text>
          </ExitPopupHeaderContentWrapper>
        </HeaderElement>
      )
    case 'accommodationPage':
      return (
        <HeaderElement justifyContent="space-between" alignItems="center">
          <ExitPopupHeaderContentWrapper
            justifyContent="center"
            alignItems="center"
          >
            <AlarmIconWrapper>
              <Icon name="Bell" size="md" />
            </AlarmIconWrapper>
            <Text variant="titleM">
              <FormattedMessageWrapper
                id="bestDealModal.dealsOnOtherSites"
                defaultMessage="Check out these similar deals on other sites"
              />
            </Text>
          </ExitPopupHeaderContentWrapper>
        </HeaderElement>
      )
    default:
      return null
  }
}
