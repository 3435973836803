import {matchPath} from 'react-router-dom'

import {Page} from '@daedalus/core/src/analytics/types/Events'

import {
  ACCOMMODATION_ROUTE,
  ACCOUNT_ROUTE,
  HOME_ROUTE,
  MEMBERSHIP_ROUTE,
  PROFILE_ROUTE,
  PROVIDER_REDIRECT_ROUTE,
  SEARCH_ROUTE
} from '../../../constants'

export const getPageName = (pathname: string) => {
  switch (pathname) {
    case HOME_ROUTE:
      return Page.Home
    case SEARCH_ROUTE:
      return Page.Search
    case PROVIDER_REDIRECT_ROUTE:
      return Page.ProviderRedirect
    case PROFILE_ROUTE:
      return Page.Profile
    case MEMBERSHIP_ROUTE:
      return Page.Membership
    case ACCOUNT_ROUTE:
      return Page.Account
    case matchPath(pathname, {path: ACCOMMODATION_ROUTE})?.url:
      return Page.Accommodation
    default:
      return null
  }
}
