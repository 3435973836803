import React from 'react'

import {isReactNativeWebView} from '@daedalus/core/src/native'

import {PriceWatchCTAParams} from '../../types'
import {WebComponentWrapper} from './WebComponentWrapper'
import {WebViewComponentWrapper} from './WebViewComponentWrapper'

export const PriceWatchCTA = (props: PriceWatchCTAParams) => {
  if (!props?.rate?.baseRate) return null

  return isReactNativeWebView() ? (
    <WebViewComponentWrapper {...props} />
  ) : (
    <WebComponentWrapper {...props} />
  )
}
