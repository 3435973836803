export enum ShareType {
  WhatsApp = 'WhatsApp',
  CopyLink = 'CopyLink',
  Email = 'Email',
  Native = 'Native',
  ShareLink = 'ShareLink'
}

export enum ShareComponent {
  LocationOverlay = 'LocationOverlay',
  HotelDetails = 'HotelDetails',
  FullScreenImageGallery = 'FullScreenImageGallery',
  VerticalGalleryOverlay = 'VerticalGalleryOverlay'
}
