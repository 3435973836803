import {parse} from 'qs'

/**
 *
 * Parses URL into a detailed object
 * @returns An object with parsed details about the URL
 * @param url the url to be parsed
 */
export const parseUrl = (url: string) => {
  // Let the browser do the work
  const parser = document.createElement('a')
  parser.href = url
  const sanitizedSearchString = parser.search.replace(/^\?/g, '')

  const searchObject = parse(sanitizedSearchString)

  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    hash: parser.hash,
    search: sanitizedSearchString,
    searchObject
  }
}

/**
 * Returns the query params from the `window.location.search` in object structure
 * @returns Object with key-value pairs for each of the parameters in the query
 */
// eslint-disable-next-line no-warning-comments
// TODO (Core): refactor to split this out so the parse url lives in url module
export const getQueryStringObject = () => {
  const queryString = window.location.search

  return parse(queryString, {ignoreQueryPrefix: true})
}

/**
 * Converts an url into base 64 and removes unsafe characters
 * @param url URL you want to convert.
 * @returns a base64 string
 */
export const safeBase64Url = (url: string) => {
  if (typeof window === 'undefined') {
    return Buffer.from(url)
      .toString('base64')
      .replace('+', '-')
      .replace('/', '_')
      .replace(/=+$/, '')
  }
  return window.btoa(url).replace('+', '-').replace('/', '_').replace(/=+$/, '')
}

/**
 * @deprecated Use getQueryStringObject instead.
 */
export const getUrlParams = () =>
  parse(location?.search, {
    ignoreQueryPrefix: true
  })
