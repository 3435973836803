import {loadModule} from '@daedalus/shared/src/utils/loadModule'

export const PriceWatchPopUpLoadable = loadModule(
  async () =>
    import(
      /* webpackChunkName: "PriceWatchPopUp" */
      './PriceWatchPopUp'
    ),
  {}
)
