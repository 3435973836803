import {Source} from '@daedalus/core/src/api-types/bovio/response/booking'
import {getHasFreeCancellation} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {
  calculateChargeDate,
  hasChargeLaterTag
} from '@daedalus/core/src/offer/business/chargeLater'
import {BOFH_PROXY_PROVIDER_CODE} from '@daedalus/core/src/offer/business/isBofhOffer'
import {ActionLinkTypes} from '@daedalus/core/src/offer/types/actionLink'
import {
  getDisplayPrice,
  getTotalPrice
} from '@daedalus/core/src/price/business/price'
import {getNightlyDisplayTotalPrice} from '@daedalus/core/src/room/business/transformSapiRoomsResponse/utils/price'
import {getTopRoomAmenities} from '@daedalus/shared/src/accommodation/RoomAmenities/RoomAmenities'

import type {
  RoomContext,
  RoomOfferContext
} from '@daedalus/core/src/analytics/types/Events'
import type {Offer} from '@daedalus/core/src/offer/types/offer'
import type {Room, RoomWithoutOffers} from '@daedalus/core/src/room/types/room'

export const buildRoomOfferContext = (
  offer: Offer,
  offerPosition: number,
  room: Room | RoomWithoutOffers,
  roomPosition: number,
  isPrivateDeal: boolean,
  anchorPrice: number
): RoomOfferContext => {
  const isChargeLaterEligible = hasChargeLaterTag(offer)
  const chargeDate = calculateChargeDate(offer.cancellationPenalties)
  const chargeType = isChargeLaterEligible
    ? [Source.ChargeLater]
    : [Source.Default]

  const paymentExpiryAt = {
    ...(isChargeLaterEligible && {chargeExpiryAt: chargeDate?.toISOString()})
  }

  const hasFreeCancellation = getHasFreeCancellation(
    offer?.cancellationPenalties
  )
  const offerCheckoutUrl = offer.links.find(
    link => link.type == ActionLinkTypes.CHECKOUT
  )?.href

  const {offer_token: offerToken = null} = offerCheckoutUrl
    ? Object.fromEntries(new URLSearchParams(new URL(offerCheckoutUrl).search))
    : {}

  return {
    offerId: offer.id,
    totalPrice: getTotalPrice(getDisplayPrice(offer.prices)),
    offerTags: offer.tags,
    availableRooms: offer.availableRooms,
    canPayLater: offer.canPayLater || isChargeLaterEligible,
    hasFreeCancellation,
    providerCode: offer.providerCode,
    proxyProviderCode: BOFH_PROXY_PROVIDER_CODE,
    cancellationPenalties: offer.cancellationPenalties,
    chargeType,
    cug: offer.cug,
    providerFeedId: offer.metadata?.feedID,
    providerRateType: offer.metadata?.providerRateType,
    isClicked: offer.isClicked,
    matchType: offer.matchType,
    matchedDim: offer.matchedDim,
    matchedOfferPriceDiff: offer.matchedOfferPriceDiff,
    services: offer.services,
    payAtProperty: offer.canPayLater,
    roomName: room.name,
    offerPosition,
    roomMasterId: room.masterId,
    roomPosition,
    roomId: room.id,
    numberOfImages: room.images.length,
    roomAmenities: room.amenities,
    roomDescription: room.description,
    squashedIds: 'squashedIds' in room ? room.squashedIds : [],
    displayPricePerRoomPerNight: getNightlyDisplayTotalPrice(offer.prices),
    isPrivateDeal,
    anchorPrice,
    offerToken,
    ...(isChargeLaterEligible && {paymentExpiryAt})
    // isCheapest
  }
}
/**
 * Returns the hotel room context
 * @param room - room object
 * @param roomPosition - room index in array of rooms (index + 1)
 * @param offers - array of offers context
 */
export const buildRoomContext = (
  room: Room | RoomWithoutOffers,
  roomPosition: number,
  offers = []
): RoomContext => {
  if (!room) return null
  return {
    numberOfImages: room.images?.length,
    roomAmenities: room.amenities,
    keyAmenities: getTopRoomAmenities(room.amenities).map(
      amenity => amenity.defaultMessage
    ),
    roomDescription: room.description,
    roomPosition,
    providerRoomName: room.raaName,
    displayedRoomName: room.name,
    roomId: room.id,
    roomMasterId: room.masterId,
    squashedIds: 'squashedIds' in room ? room.squashedIds : [],
    isSelectedRoom:
      'offers' in room ? room.offers?.some(offer => offer?.isClicked) : false,
    offers
  }
}

export const getOfferContextFromRoomContext = (
  roomContext: RoomContext,
  offerId: string
): RoomOfferContext =>
  roomContext.offers.find(context => context.offerId === offerId)
