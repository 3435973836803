import {Bookings} from '@daedalus/core/src/api-types/bovio/response/bookings'

export const getFirstBookingWithInterval = (bookings: Bookings[]): Bookings => {
  if (!bookings?.length) return null

  let checkout = bookings[0].checkOut
  let lastBooking = bookings[0]

  for (const booking of bookings) {
    if (checkout === booking.checkIn) {
      checkout = booking.checkOut
      lastBooking = booking
    }
  }
  return lastBooking
}
