import {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getPaginationContext} from 'middleware/analytics/selectors'
import {
  getCurrentPage,
  getCurrentPageLength,
  getCurrentPageNumber,
  getCurrentPageOffset,
  getGroupedHotelDetails,
  getHasMoreResults,
  getIsLoadMoreResultsPending,
  getIsSearchPending,
  getRequestedOffset,
  getSearchId,
  getShouldShowNoMoreDeals,
  getTotalCountOfResults
} from 'modules/sapiSearch/selectors'
import useSapiSearch from 'modules/sapiSearch/useSapiSearch'
import {scrollTo} from 'utils'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export enum PaginationState {
  Loading = 'LOADING',
  HasMore = 'HAS_MORE',
  NoMore = 'NO_MORE',
  Disabled = 'DISABLED'
}

type ReturnType = {
  currentPageNumber: number
  paginationState: PaginationState
  pageHitsOffset: number
  onScrollToTop: () => void
  onLoadMore: () => void
}

const scrollToWindowTopSmoothly = () =>
  scrollTo(document.querySelector('html'), 0, 250)

export const usePaginationState = (): PaginationState => {
  const searchIsPending = useSelector(getIsSearchPending)
  const loadMoreResultsIsPending = useSelector(getIsLoadMoreResultsPending)
  const hasMoreResults = useSelector(getHasMoreResults)
  const totalResultsCount = useSelector(getTotalCountOfResults)
  const currentLength = useSelector(getCurrentPageLength)
  const currentPage = useSelector(getCurrentPage)
  const shouldShowNoMoreDeals = useSelector(getShouldShowNoMoreDeals)

  const {hasPartiallyMatched} = useSelector(getGroupedHotelDetails)

  if (loadMoreResultsIsPending) return PaginationState.Loading

  if (hasPartiallyMatched) return PaginationState.Disabled

  if (!searchIsPending && hasMoreResults && !shouldShowNoMoreDeals)
    return PaginationState.HasMore

  if (
    (!searchIsPending &&
      !hasMoreResults &&
      totalResultsCount > 0 &&
      currentLength > 0 &&
      currentPage > 0) ||
    shouldShowNoMoreDeals
  ) {
    return PaginationState.NoMore
  }

  return PaginationState.Disabled
}

const usePagination = (): ReturnType => {
  const {loadMoreResults} = useSapiSearch()
  const dispatch = useDispatch()

  const currentOffset = useSelector(getCurrentPageOffset)
  const currentPageNumber = useSelector(getCurrentPageNumber)
  const searchId = useSelector(getSearchId)
  const pageHitsOffset = useSelector(getRequestedOffset)
  const totalNumberOfStaticResults = useSelector(getTotalCountOfResults)
  const paginationContext = useSelector(getPaginationContext)

  const paginationState = usePaginationState()

  const onLoadMore = useCallback(() => {
    loadMoreResults()

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.LoadMoreResultsButton,
        action: Action.Clicked,
        analyticsContext: {
          [AnalyticsContext.PaginationContext]: paginationContext
        }
      })
    )
  }, [
    currentOffset,
    currentPageNumber,
    totalNumberOfStaticResults,
    searchId,
    loadMoreResults,
    paginationContext,
    dispatch
  ])

  return {
    currentPageNumber,
    paginationState,
    pageHitsOffset,
    onScrollToTop: scrollToWindowTopSmoothly,
    onLoadMore
  }
}

export default usePagination
