import {useSelector} from 'react-redux'
import {useGetHotelDetails} from 'components/HotelDetailsOverlay/Desktop/useGetHotelDetails'
import {getHotelContext, getSearchContext} from 'middleware/analytics/selectors'
import {getHotelDetailsOverlayInfo} from 'modules/overlay/selectors'
import {
  getAnchorHotel,
  getFirstHotel,
  getOfferEntity,
  getShowTotalPrices,
  getSplitBookingDetails,
  getSplitBookingPriceSummary
} from 'modules/sapiSearch/selectors'
import {getAnchorPriceTotal} from 'modules/sapiSearch/utils'
import {getSearchIdFromConditionalSource} from 'modules/searchApi/selectors'
import {getSearchBoxValues} from 'modules/searchBox/selectors'
import {toggle} from 'opticks'
import {
  getAnchorPrice,
  getCheapestOfferTotalRate,
  getCheapestTopOfferByNightlyRate
} from 'utils/offers'

import {AnalyticsContext} from '@daedalus/core/src/analytics/types/Events'
import {
  postMessageToWebView,
  RNWebViewMessageTypes
} from '@daedalus/core/src/native'

export const usePriceWatchMobileAppBottomSheet = () => {
  const {hotelId} = useSelector(getHotelDetailsOverlayInfo)
  const hotelFromOverlay = useGetHotelDetails(hotelId)
  const firstHotel = useSelector(getFirstHotel)
  const anchorHotel = useSelector(getAnchorHotel)

  const isHoldOutGroup = toggle(
    'a87807d8-price-watch-toggle-holdout',
    true,
    false
  )
  const hotelContext = useSelector(state => getHotelContext(state, hotelId))
  const searchId = useSelector(getSearchIdFromConditionalSource)
  const searchContext = useSelector(state => getSearchContext(state, searchId))

  const hotel = hotelFromOverlay || anchorHotel || firstHotel

  const {checkIn, checkOut} = useSelector(getSearchBoxValues)
  const shouldShowTotal = useSelector(getShowTotalPrices)
  const {hasSplitBookingOffer, isSplitBookingOfferTheCheapest} = useSelector(
    state => getSplitBookingDetails(state, hotel?.objectID)
  )

  const sbPriceSummary = useSelector(state =>
    getSplitBookingPriceSummary(state, hotel?.objectID)
  )

  const offerEntity = useSelector(state =>
    getOfferEntity(state, hotel?.objectID)
  )

  const cheapestOffer = getCheapestTopOfferByNightlyRate(offerEntity?.offers)
  const cheapestTotalRate = getCheapestOfferTotalRate(offerEntity?.offers)
  const anchorPrice = getAnchorPrice(offerEntity?.topOfferData)
  const anchorPriceTotal = getAnchorPriceTotal(offerEntity)

  const shouldReplaceCheapestOfferWithSplitBooking =
    hasSplitBookingOffer && isSplitBookingOfferTheCheapest

  const priceComposition = {
    nightlyRate: shouldReplaceCheapestOfferWithSplitBooking
      ? sbPriceSummary?.average
      : cheapestOffer?.nightlyRate,
    totalRate: shouldReplaceCheapestOfferWithSplitBooking
      ? sbPriceSummary?.sum
      : cheapestTotalRate,
    anchorPrice,
    anchorPriceTotal,
    shouldShowTotal
  }

  /*
   * Post message to mobile app when navigate back from Accommodation Page to Search Results Page.
   *
   * The mobile app holds all the biz rules to decide to show or not the Price Watch Bottom Sheet.
   **/
  const shouldOpenPriceWatchMobileBottomSheetOnApToSrpScenario = () => {
    if (isHoldOutGroup || !priceComposition?.nightlyRate) return null

    toggle(
      'feature-price-watch-bottom-sheet-ap-to-srp',
      null,
      postMessageToWebView({
        type: RNWebViewMessageTypes.USER_ACTION,
        payload: {
          action: 'Click',
          entity: 'AccommodationPageBackButton',
          context: {
            hotel,
            priceComposition,
            checkIn,
            checkOut,
            analyticsContext: {
              [AnalyticsContext.HotelContext]: hotelContext,
              [AnalyticsContext.SearchContext]: searchContext
            }
          }
        }
      })
    )
  }

  /*
   * Post message to mobile app when user scrolls the Search Results Page to or past the 5th hotel in the list.
   *
   * The mobile app holds all the biz rules to decide to show or not the Price Watch Bottom Sheet.
   **/
  const openPriceWatchBottomSheetOnScroll = () => {
    if (isHoldOutGroup || !priceComposition?.nightlyRate) return null

    toggle(
      'feature-price-watch-bottom-sheet-on-scroll',
      null,
      postMessageToWebView({
        type: RNWebViewMessageTypes.USER_ACTION,
        payload: {
          action: 'Scroll',
          entity: 'SearchResults',
          context: {
            hotel,
            priceComposition,
            checkIn,
            checkOut,
            analyticsContext: {
              [AnalyticsContext.HotelContext]: hotelContext,
              [AnalyticsContext.SearchContext]: searchContext
            }
          }
        }
      })
    )
  }

  return {
    shouldOpenPriceWatchMobileBottomSheetOnApToSrpScenario,
    openPriceWatchBottomSheetOnScroll
  }
}
