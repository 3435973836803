import {createAsyncThunk} from '@reduxjs/toolkit'
import {SignInWithRedirectInput} from 'aws-amplify/auth'
import {History} from 'history'
import {keys, reject} from 'ramda'

import {UserTier} from '../../offer/business/privateDeals'
import {WAS_PAYMENT_PAGE_AUTHENTICATED} from '../../searchParams/constants'
import {getLastLoginUrl, setLastLoginUrl} from '../business/persistData'
import {
  socialLoginService,
  updateUserAttributesService,
  upgradeUserTier
} from '../services'
import {SourceComponentType} from '../types/Auth'
import {CognitoError, User, UserAttributes} from '../types/Cognito'

export enum SocialConnections {
  Google = 'google-oauth2',
  Facebook = 'facebook',
  Apple = 'apple'
}

export interface SendMessageError {
  error: string
  error_description: string
}

export interface CognitoDDError {
  type: string
  error: CognitoError | string
}

/**
 * Authorize users via Cognito sdk
 */
interface makeSocialLoginArgs {
  connection: SocialConnections
  unlockHotelId: string
  sourceComponent?: SourceComponentType
  callbackUrl?: string | null
}

export const makeSocialLogin = createAsyncThunk<
  unknown,
  makeSocialLoginArgs,
  {rejectValue: CognitoError}
>(
  'makeSocialLogin',
  async ({connection, unlockHotelId, sourceComponent, callbackUrl}) => {
    const returningUrl = new URL(callbackUrl || window.location.href)

    if (unlockHotelId) {
      returningUrl.searchParams.set('hotelId', unlockHotelId)
    }

    if (sourceComponent === SourceComponentType.PaymentPage) {
      returningUrl.searchParams.set(WAS_PAYMENT_PAGE_AUTHENTICATED, 'true')
    }

    setLastLoginUrl(returningUrl.toString())

    const connectionMapping = {
      [SocialConnections.Google]: 'Google',
      [SocialConnections.Facebook]: 'Facebook',
      [SocialConnections.Apple]: 'Apple'
    }

    return socialLoginService(
      connectionMapping[connection] as SignInWithRedirectInput['provider']
    )
  }
)

/**
 * Redirect users from callback login previous page
 */
export const redirectUsersFromCallbackUrl = createAsyncThunk<
  User,
  {history: History; user: User}
>('redirectUsersFromCallbackUrl', async ({history, user}) => {
  const lastLoginUrl = getLastLoginUrl()
  const url = new URL(lastLoginUrl || location.origin)
  history.replace(url.pathname + url.search)
  return user
})

/**
 * Updates users' Cognito attributes
 */
export const updateUserAttributes = createAsyncThunk<
  UserAttributes,
  UserAttributes,
  {rejectValue: unknown}
>('updateUserAttributes', async (attributes, {rejectWithValue}) => {
  try {
    const response = await updateUserAttributesService(attributes)

    const rejectedValues = reject(
      (attribute: keyof UserAttributes) => response[attribute]?.isUpdated,
      keys(attributes)
    )

    if (rejectedValues?.length === 0) {
      return attributes
    }

    return rejectWithValue(rejectedValues)
  } catch (error) {
    return rejectWithValue(error as Error)
  }
})

/**
 * Upgrades users to plus
 */
export const upgradeUserToPlus = createAsyncThunk<
  unknown,
  void,
  {
    rejectValue: {
      error: unknown
    }
  }
>('upgradeUserToPlus', async (_, {rejectWithValue, fulfillWithValue}) => {
  try {
    const response = await upgradeUserTier(UserTier.Plus)

    return fulfillWithValue(response)
  } catch (error) {
    return rejectWithValue(error as {error: unknown})
  }
})
