import {parse} from 'qs'
import {CookieConfig} from 'types/Cookie'

import {getCookie, setCookie} from '../../_web/utils/cookies'
import {geolocationConfig, geolocationSourceConfig} from './cookieConfig'

const getCookieOptions = (cookieConfig: CookieConfig) => {
  const {secure, sameSite, maxAge: expires, domain} = cookieConfig.options
  return {
    expires,
    secure,
    sameSite,
    domain
  }
}

export const getPersistedGeolocation = () => {
  const stringifiedCoords = getCookie(geolocationConfig.name)
  if (stringifiedCoords) {
    const [lat, lon] = stringifiedCoords.split(',')
    return {lat: Number(lat), lon: Number(lon)}
  }
}

export const persistGeolocation = (geolocation: {lat: number; lon: number}) => {
  if (geolocation?.lat && geolocation?.lon) {
    const geoString = `${geolocation?.lat},${geolocation?.lon}`
    setCookie(
      geolocationConfig.name,
      geoString,
      getCookieOptions(geolocationConfig)
    )
  }
}

export const getPersistedGeolocationSource = () => {
  const source = getCookie(geolocationSourceConfig.name)
  return source
}

export const persistGeolocationSource = (source: string) => {
  setCookie(
    geolocationSourceConfig.name,
    source,
    getCookieOptions(geolocationSourceConfig)
  )
}

export const getGeolocationFromQueryString = () => {
  const {lat, lon} = parse(location.search, {
    ignoreQueryPrefix: true
  })
  if (lat && lon) {
    return {lat: Number(lat), lon: Number(lon)}
  }
}
