import React, {useEffect, useState} from 'react'

import {useToastController} from '@daedalus/atlas/context/toastController'

import {
  PriceWatchToastIcon,
  PriceWatchToastMessage
} from '../components/PriceWatchToastMessage'

import type {ContentHotel, Hotel} from '@findhotel/sapi'

type UsePriceWatchToggleToast = {
  isPriceWatchOn: boolean
  hotel: ContentHotel | Hotel
  checkIn: string
  checkOut: string
  isEnabledToTriggerToggle: boolean
  onToastClick: (event: React.SyntheticEvent) => void
  shouldShowNavigationIcon: boolean
}

export const usePriceWatchToggleToast = ({
  isPriceWatchOn,
  hotel,
  checkIn,
  checkOut,
  isEnabledToTriggerToggle,
  onToastClick,
  shouldShowNavigationIcon
}: UsePriceWatchToggleToast) => {
  const [
    shouldShowPriceWatchEnabledMessage,
    setShouldShowPriceWatchEnabledMessage
  ] = useState(false)

  const [
    shouldShowPriceWatchDisabledMessage,
    setShouldShowPriceWatchDisabledMessage
  ] = useState(false)
  const {open, closeAll} = useToastController()

  const handleClick = (event: React.SyntheticEvent) => {
    closeAll()
    onToastClick(event)
  }

  useEffect(() => {
    if (!isPriceWatchOn) return

    if (shouldShowPriceWatchEnabledMessage && isEnabledToTriggerToggle) {
      open(
        <PriceWatchToastMessage
          hotel={hotel}
          checkIn={checkIn}
          checkOut={checkOut}
          shouldShowNavigationIcon={shouldShowNavigationIcon}
          enabled
        />,
        {
          icon: <PriceWatchToastIcon enabled />,
          onClick: handleClick
        }
      )

      setShouldShowPriceWatchEnabledMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldShowPriceWatchEnabledMessage,
    isEnabledToTriggerToggle,
    isPriceWatchOn,
    open
  ])

  useEffect(() => {
    if (isPriceWatchOn) return

    if (shouldShowPriceWatchDisabledMessage) {
      open(
        <PriceWatchToastMessage
          hotel={hotel}
          checkIn={checkIn}
          checkOut={checkOut}
          enabled={false}
        />,
        {
          icon: <PriceWatchToastIcon enabled={false} />,
          onClick: onToastClick
        }
      )

      setShouldShowPriceWatchDisabledMessage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldShowPriceWatchDisabledMessage,
    isEnabledToTriggerToggle,
    isPriceWatchOn,
    open
  ])

  return {
    setShouldShowPriceWatchEnabledMessage,
    setShouldShowPriceWatchDisabledMessage
  }
}
