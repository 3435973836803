import React from 'react'
import {HotelCard} from 'components/HotelCard'
import {HotelCardProvider} from 'components/HotelCardContext'
import {SplitBookingDetails} from 'components/SplitBookings/SplitBookingDetails'
import {Hotel} from 'modules/sapiSearch/slice'

interface Props {
  anchorHotelId: Hotel['objectID']
}
const SearchResultsAnchorHotel: React.FC<Props> = ({anchorHotelId}) => {
  return (
    <HotelCardProvider isAnchor hotelId={anchorHotelId}>
      <HotelCard isPriorityCard />
      <SplitBookingDetails />
    </HotelCardProvider>
  )
}
export const AnchorHotel = React.memo(SearchResultsAnchorHotel)
