import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setIsAcquiringGeoCoordinates} from 'modules/meta/slice'
import {getIsUserLocationSearch} from 'modules/search/selectors'
import {GeolocationPermissions} from 'utils/geolocation'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {useSetDeviceGeolocationSearchParams} from './useSetDeviceGeolocationSearchParams'

export const useHandleUserGeolocation = async () => {
  let currentPermissionStatus = null
  const isUserLocationSearch = useSelector(getIsUserLocationSearch)
  const isReactNative = isReactNativeWebView()

  const {setDeviceGeolocationSearchParams} =
    useSetDeviceGeolocationSearchParams()

  const dispatch = useDispatch()

  const handleGeolocationRequest = useCallback(async () => {
    setDeviceGeolocationSearchParams()
  }, [isUserLocationSearch])

  useEffect(() => {
    if (isUserLocationSearch && !isReactNative) {
      handleGeolocationRequest()
    }
  }, [isUserLocationSearch])

  useEffect(() => {
    if (navigator.permissions && !isReactNative) {
      navigator.permissions
        .query({name: 'geolocation'})
        .then(permissionStatus => {
          currentPermissionStatus = permissionStatus.state
          permissionStatus.onchange = handlePermissionStatusChange
        })
    }
  }, [])

  const handlePermissionStatusChange = ev => {
    const newPermissionState = ev.target.state

    // currentPermissionStatus keeps track of the permission changing to avoid this
    // callback to be called by all hooks useSetDeviceGeolocationSearchParams, leading
    // to > 1 trackEvent calls.
    if (
      newPermissionState === GeolocationPermissions.GRANTED ||
      newPermissionState === GeolocationPermissions.DENIED
    ) {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.LocationAccessPreference,
          action: Action.Submitted,
          payload: {
            consent: newPermissionState === GeolocationPermissions.GRANTED
          }
        })
      )
    }

    // We want to make sure to trigger this only when users are asking for the first time to enable location services
    if (
      newPermissionState === GeolocationPermissions.GRANTED &&
      currentPermissionStatus === GeolocationPermissions.PROMPT
    ) {
      dispatch(setIsAcquiringGeoCoordinates(true))
    }

    currentPermissionStatus = newPermissionState
  }
}
