import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import throttle from 'lodash/throttle'
import {setDeviceLayout} from 'modules/meta/slice'

import {getUserDeviceDetails} from '@daedalus/core/src/_web/utils/userAgent/device'

const THROTTLING_INTERVAL = 200 // ms

const getViewPortHeight = () => window?.innerHeight
const getViewPortWidth = () => window?.innerWidth

const SyncStateWithDeviceLayout = () => {
  const {deviceCategory} = getUserDeviceDetails()
  const dispatch = useDispatch()

  // TODO (@Gerrit88) [2021-09-09]: Consider moving the viewPort logic to the useDeviceLayout hook in Atlas to make it available to all packages.
  const [viewPortHeight, setViewPortHeight] = useState(getViewPortHeight())
  const [viewPortWidth, setViewPortWidth] = useState(getViewPortWidth())

  useEffect(() => {
    const resizeListener = throttle(() => {
      setViewPortHeight(getViewPortHeight())
      setViewPortWidth(getViewPortWidth())
    }, THROTTLING_INTERVAL)

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  useEffect(() => {
    dispatch(setDeviceLayout({deviceCategory, viewPortHeight, viewPortWidth}))
  }, [dispatch, deviceCategory, viewPortHeight, viewPortWidth])

  return null
}

export default SyncStateWithDeviceLayout
