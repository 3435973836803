import React from 'react'
import {useSelector} from 'react-redux'
import {SplitBookingBundleType} from 'components/Offer/types'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {getIsPrivateDeal} from '@daedalus/core/src/auth/modules/selectors'
import {OfferPrimaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {OfferCTA} from '../../Shared/OfferCTA'

interface Props {
  offer?: SearchOffer
  splitBookingType?: SplitBookingBundleType
  ctaLoading?: boolean
  showLockIcon?: boolean
  hotelId: string
  variant: OfferPrimaryLayoutVariant
  isRequiredOneTimePasswordFlow?: boolean
  hasWeb2AppPromo?: boolean
}

export const CtaComponent = ({
  offer,
  splitBookingType,
  ctaLoading = false,
  showLockIcon = true,
  hotelId,
  variant = 'PrimaryDefault',
  isRequiredOneTimePasswordFlow,
  hasWeb2AppPromo = false
}: Props) => {
  const isPrivate = useSelector(getIsPrivateDeal(offer))
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  return (
    <Box
      data-id="OfferCta"
      whiteSpace="nowrap"
      minWidth="128px"
      marginTop={variant !== 'PrimaryDefault' ? 's300' : undefined}
      desktopXs={{marginTop: variant !== 'PrimaryDefault' ? 's400' : undefined}}
    >
      <OfferCTA
        fullWidth
        withSecondaryVariant={hasWeb2AppPromo && variant === 'PrimaryDefault'}
        showLockIcon={showLockIcon && isPrivate}
        isAppLocked={isAppLockedDeal}
        offer={offer}
        loading={ctaLoading}
        splitBookingType={splitBookingType}
        isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
      />
    </Box>
  )
}
