import {setCookie} from '../../utils/cookies'
import cookieConfig from './cookieConfig'
import {getFirstVisit} from './getFirstVisit'

export const persistFirstVisit = (): void => {
  const firstVisit = getFirstVisit()
  if (firstVisit) return
  const date = new Date().toISOString()
  const {secure, sameSite, domain, maxAge: expires} = cookieConfig.options
  setCookie(cookieConfig.name, date, {
    expires,
    secure,
    sameSite,
    domain
  })
}
