import React from 'react'
import {useSelector} from 'react-redux'
import {Referral} from 'components/User/Referral'
import {ReferralLinkProvider} from 'components/User/Referral/ReferralLinkProvider'
import {UserLayout} from 'components/User/UserLayout'
import Settings from 'Settings'

import {getBrand} from '@daedalus/core/src/_web/brand/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const REFERRAL_SHORT_LINK_URL = `${Settings.get(
  'MEMBERSHIP_API_ENDPOINT'
)}referral/short-link`

export const ReferralRoute = () => {
  const brand = useSelector(getBrand)
  return (
    <UserLayout
      headerContent={
        <FormattedMessageWrapper
          id="referral.inviteFriends"
          defaultMessage="Invite friends"
        />
      }
    >
      <ReferralLinkProvider
        brand={brand}
        referralShortLinkUrl={REFERRAL_SHORT_LINK_URL}
      >
        <Referral />
      </ReferralLinkProvider>
    </UserLayout>
  )
}
