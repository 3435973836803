import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {trackTimingEvent} from 'middleware/analytics'

import {Entity, Team} from '@daedalus/core/src/analytics/types/Events'

/**
 * Hides the splash screen, do NOT remove the HTML node because React will behave erratically.
 */
export const SplashScreenRemover = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const splashScreen = document.getElementById('splash_screen')
    if (splashScreen) {
      const splashScreenDisplayed = Boolean(splashScreen.dataset.visible)
      if (splashScreenDisplayed) {
        splashScreen.style.opacity = '0'
        setTimeout(() => {
          splashScreen.style.display = 'none'
        }, 500)
      } else {
        splashScreen.style.display = 'none'
      }

      trackTimingEvent(
        dispatch,
        'Loaded',
        'responseStart',
        Entity.SplashScreen,
        Team.Default,
        false,
        {splashScreenDisplayed}
      )
    }
  }, [dispatch])

  return null
}
