import React, {ReactNode, useCallback} from 'react'
import {assocPath} from 'ramda'

import {
  OPTIMIZED_MAX_ADULTS_PER_ROOM,
  OPTIMIZED_MAX_GUESTS_PER_ROOM
} from '@daedalus/core/src/room/business/roomConfiguration/config'
import {UniqueRoomSplitType} from '@daedalus/core/src/room/types/RoomConfiguration'

import {GuestPickerLayout} from '../../GuestPicker/GuestPickerLayout'

interface Props {
  roomsSplit: UniqueRoomSplitType[]
  updateRoomsSplit: (roomsSplit: UniqueRoomSplitType[]) => void
  isCompactLayout?: boolean
  customTitle?: ReactNode
}

export const OptimizedRoomGuestPicker = ({
  roomsSplit,
  updateRoomsSplit,
  isCompactLayout = false,
  customTitle
}: Props) => {
  const handleAdultsChange = useCallback(
    (index: number, adults: number) => {
      if (adults) {
        const newRoomsSplit = assocPath([index, 'adults'], adults, roomsSplit)
        updateRoomsSplit(newRoomsSplit)
      }
    },
    [roomsSplit, updateRoomsSplit]
  )

  const handleChildrenChange = useCallback(
    (index: number, children: number[]) => {
      const newRoomsSplit = assocPath([index, 'children'], children, roomsSplit)

      updateRoomsSplit(newRoomsSplit)
    },
    [roomsSplit, updateRoomsSplit]
  )

  return (
    <GuestPickerLayout
      customTitle={customTitle}
      adultGuests={roomsSplit?.[0].adults}
      childGuests={roomsSplit?.[0].children}
      index={0}
      maxGuests={OPTIMIZED_MAX_GUESTS_PER_ROOM}
      maxAdultGuests={OPTIMIZED_MAX_ADULTS_PER_ROOM}
      onAdultsChange={handleAdultsChange}
      onChildrenChange={handleChildrenChange}
      isCompactLayout={isCompactLayout}
    />
  )
}
