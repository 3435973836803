import {defineMessages} from 'react-intl'

export const translationMessages = defineMessages({
  bestAvailableDeal: {
    id: 'bestAvailableDeal',
    defaultMessage: `Best available deal`
  },
  yourSelectedOffer: {
    id: 'yourSelectedOffer',
    defaultMessage: 'Your selected offer'
  },
  ourBestDeal: {
    id: 'ourBestDeal',
    defaultMessage: 'Our best deal for this room'
  },
  bestAvailableDeals: {
    id: 'bestAvailableDeals',
    defaultMessage: `Best available deals`
  },
  ourBestDeals: {
    id: 'ourBestDeals',
    defaultMessage: 'Our best deals for this room'
  }
})
