import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useConditionalEffect} from 'hooks/useConditionalEffect'
import {getFiltersVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {OverlayPage} from '@daedalus/atlas/OverlayPage'

import {FiltersOverlayContentLoadable} from './Loadable'

export const FiltersOverlay = () => {
  const {formatMessage} = useIntl()
  const isOpen = useSelector(getFiltersVisibility)
  const dispatch = useDispatch()
  const handleHideFilters = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  useConditionalEffect(
    async () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "FiltersOverlayContent" */
        './FiltersOverlayContent'
      )
  )

  return (
    <OverlayPage
      isOpen={isOpen}
      titleBarProps={{
        centerContent: formatMessage(filterMessages.allFilters)
      }}
      onClose={handleHideFilters}
    >
      {isOpen && <FiltersOverlayContentLoadable />}
    </OverlayPage>
  )
}
