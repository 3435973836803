import {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  getAnchorHotelId,
  getHotelAvailabilityPrices,
  getHotelAvailabilityPricesComplete,
  getSearchParameters
} from 'modules/sapiSearch/selectors'
import useSapiSearch from 'modules/sapiSearch/useSapiSearch'
import {getSelectedAvailabilityDate} from 'modules/search/selectors'
import {setSelectedAvailabilityDate} from 'modules/search/slice'

import {
  getAvailabilityStatus,
  getDatesToDisplay,
  MAX_DATES_TO_SHOW_DESKTOP
} from '@daedalus/core/src/_web/availability'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

export enum AvailabilityComponent {
  DesktopAvailabilityCalendar = 'DesktopSearchViewAvailabilityCalendar'
}

type Props = {
  component: AvailabilityComponent
}

export const useAvailabilityDatePicker = ({component}: Props) => {
  const anchorHotelId = useSelector(getAnchorHotelId)
  const hotelAvailabilityPrices = useSelector(state =>
    getHotelAvailabilityPrices(state, anchorHotelId)
  )

  const availabilitySearchComplete = useSelector(
    getHotelAvailabilityPricesComplete
  )
  const selectedAvailabilityDate = useSelector(getSelectedAvailabilityDate)
  const {checkIn} = useSelector(getSearchParameters)

  const dispatch = useDispatch()
  const {useGetHotelAvailability} = useSapiSearch()
  const {getMonthlyAvailability} = useGetHotelAvailability({
    hotelId: anchorHotelId
  })

  const datesToDisplay =
    hotelAvailabilityPrices &&
    getDatesToDisplay(
      hotelAvailabilityPrices,
      checkIn,
      MAX_DATES_TO_SHOW_DESKTOP
    )

  const handleMonthChange = useCallback(
    (date: Date) => {
      getMonthlyAvailability(date)
    },
    [getMonthlyAvailability]
  )

  const handleDayClick = useCallback(
    (date: string) => {
      const price = hotelAvailabilityPrices?.[date]?.cheapestNightlyRate
      if (!price && !availabilitySearchComplete) return
      dispatch(setSelectedAvailabilityDate(date))
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.AvailabilityCalendarDay,
          action: Action.Clicked,
          payload: {
            date,
            availabilityStatus: getAvailabilityStatus({
              hotelAvailabilityPrices,
              date,
              searchComplete: availabilitySearchComplete
            })
          },
          component: component
        })
      )
    },
    [dispatch, hotelAvailabilityPrices, availabilitySearchComplete, component]
  )

  useEffect(() => {
    if (
      hotelAvailabilityPrices &&
      availabilitySearchComplete &&
      !selectedAvailabilityDate
    ) {
      dispatch(setSelectedAvailabilityDate(datesToDisplay[0]))
    }
  }, [
    hotelAvailabilityPrices,
    availabilitySearchComplete,
    selectedAvailabilityDate,
    dispatch,
    datesToDisplay
  ])
  return {
    handleDayClick,
    handleMonthChange
  }
}
