import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  PageViewParamsType,
  trackCIOPageView,
  trackPageView
} from 'middleware/analytics/actions/trackPageView'
import {
  getAnonymousId,
  getCurrencyCode,
  getDeviceCategory,
  getLocaleCode,
  getUserCountryCode
} from 'modules/meta/selectors'
import {setAnalyticsPageName} from 'modules/meta/slice'
import {
  getHotel,
  getIsSearchCompleted,
  State
} from 'modules/sapiSearch/selectors'
import {Hotel} from 'modules/sapiSearch/slice'
import {getHotelId, getIsUserSearch} from 'modules/search/selectors'
import {getEpochSeconds, getTimezone} from 'utils'
import {getTrafficSource} from 'utils/trafficSource'

import {SIZES} from '@daedalus/atlas/Image'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Page
} from '@daedalus/core/src/analytics/types/Events'
import {
  getIsLoading,
  selectUserId
} from '@daedalus/core/src/auth/modules/selectors'
import {parseQueryString} from '@daedalus/core/src/utils/url'

import {imageProvider} from '../../utils/imageProvider'

interface Props {
  pageName: Page
  trackingParams?: Partial<PageViewParamsType>
}

interface QueryParams {
  label?: string
  utm_source?: string
  checkIn?: string
  checkOut?: string
  hotelId?: string
  rooms?: string
}

const useTrackCIOPageView = ({pageName}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)
  const id = useSelector(selectUserId)
  const currency = useSelector(getCurrencyCode)
  const locale = useSelector(getLocaleCode)
  const isUserSearch = useSelector(getIsUserSearch)
  const anonymousId = useSelector(getAnonymousId)
  const {brandIsVio} = useBrand()
  const stateHotelId = useSelector(getHotelId)
  const {
    checkIn,
    checkOut,
    hotelId: hotelIdFromSearch,
    rooms
  }: QueryParams = parseQueryString(location.search)

  const isSearchCompleted = useSelector(getIsSearchCompleted)
  const hotel = useSelector((state: State) =>
    getHotel(state, hotelIdFromSearch)
  ) as Hotel

  const {hotelName, displayAddress, imageURIs} = hotel || {}
  const pageUrl = location.href
  const hotelId = hotelIdFromSearch || stateHotelId

  const hotelImageUrl = imageURIs?.[0]
    ? imageProvider(imageURIs[0], SIZES['large'])
    : undefined

  useEffect(() => {
    if (!isLoading && brandIsVio && isSearchCompleted)
      dispatch(
        trackCIOPageView(pageName, {
          id,
          currency,
          locale,
          checkIn: getEpochSeconds(checkIn),
          checkOut: getEpochSeconds(checkOut),
          pageUrl,
          isUserSearch,
          hotelId,
          anonymousId,
          rooms,
          hotelName,
          hotelAddress: displayAddress,
          hotelImageUrl,
          timezone: getTimezone()
        })
      )
  }, [isLoading, isSearchCompleted])
}

const TrackPageView = ({pageName, trackingParams}: Props) => {
  const dispatch = useDispatch()
  const deviceCategory = useSelector(getDeviceCategory)
  const userCountryCode = useSelector(getUserCountryCode)
  const {label, utm_source: utmSource}: QueryParams = parseQueryString(
    location.search
  )
  const trafficSource = getTrafficSource(utmSource, label)

  useTrackCIOPageView({pageName})

  useEffect(() => {
    dispatch(setAnalyticsPageName({page: pageName}))
    dispatch(
      trackPageView(pageName, {
        ...trackingParams,
        deviceCategory,
        userCountryCode,
        trafficSource
      })
    )

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.Page,
        action: Action.Displayed,
        page: pageName,
        payload: trackingParams
      })
    )
    // Make sure it only triggers once
  }, [])

  return null
}

export default TrackPageView
