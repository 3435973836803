import React from 'react'
import {ReviewsSection} from 'components/Accommodation/ReviewsSection'
import {GuestReviewLayout} from 'components/Accommodation/ReviewsSection/constants'
import {getReviewAvailability} from 'components/Accommodation/ReviewsSection/utils'
import {WrapperWithPadding} from 'components/Accommodation/WrapperWithPadding'

import {Divider} from '@daedalus/atlas/Divider'
import {ContentHotel} from '@findhotel/sapi'

interface Props {
  hotel: ContentHotel
  reviewsRef?: React.RefObject<HTMLDivElement>
  layoutConfig?: GuestReviewLayout
  mountedOnOverlay?: boolean
}
export const ConditionalReviews = ({
  hotel,
  reviewsRef,
  layoutConfig = GuestReviewLayout.DEFAULT,
  mountedOnOverlay = false
}: Props) => {
  const {hasAnyReviewComponent} = getReviewAvailability(hotel)
  const shouldRenderReviews = !hotel || hasAnyReviewComponent
  if (!shouldRenderReviews) return null

  return (
    <>
      {mountedOnOverlay ? null : <Divider sideMargin="s500" />}
      <>
        <WrapperWithPadding
          paddingBottom="s000"
          paddingTop={mountedOnOverlay ? 's600' : 's700'}
        >
          <ReviewsSection
            anchorRef={reviewsRef}
            hotel={hotel}
            layoutConfig={layoutConfig}
            mountedOnOverlay={mountedOnOverlay}
          />
        </WrapperWithPadding>
      </>
    </>
  )
}
