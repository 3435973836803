import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {trackTimingEvent} from 'middleware/analytics'

import {logTimeStart} from '@daedalus/core/src/_web/utils/logging/performanceTimer'
import {Entity} from '@daedalus/core/src/analytics/types/Events'

export const usePageLoadStartTimer = () => {
  useEffect(() => {
    logTimeStart('PageLoad')
  }, [])
}

export const usePageLoadEndTimer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    trackTimingEvent(dispatch, 'PageLoad', 'PageLoad-start', Entity.Page)
  }, [dispatch])
}
