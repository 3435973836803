import React, {createContext, ReactNode} from 'react'
import {getUniqueRecentHotelSearches} from 'utils/persistence'

import {Bookings} from '@daedalus/core/src/api-types/bovio/response/bookings'
import {
  FlattenSearchHistory,
  flattenSearchHistory
} from '@daedalus/core/src/searchHistory'
import {selectSearchHistoryIds} from '@daedalus/core/src/searchHistory/flattenSearchHistory'

import {useGetBookings} from '../hooks/useGetBookings'

export interface CarouselWidgetsProvider {
  searchHistory: FlattenSearchHistory[]
  bookings: Bookings[]
  bookingsIsLoading: boolean
  bookingsIsFetching: boolean
  bookingsIsError: boolean
  searchIds: string[]
}

const CarouselWidgetsContext = createContext<CarouselWidgetsProvider>(
  {} as CarouselWidgetsProvider
)

const CarouselWidgetsProvider = ({children}: {children: ReactNode}) => {
  const uniqueRecentHotelSearches = getUniqueRecentHotelSearches()
  const searchHistory = flattenSearchHistory(uniqueRecentHotelSearches)
  const searchIds = selectSearchHistoryIds(searchHistory)

  const {
    bookings,
    isLoading: bookingsIsLoading,
    isFetching: bookingsIsFetching,
    isError: bookingsIsError
  } = useGetBookings()

  return (
    <CarouselWidgetsContext.Provider
      value={{
        searchIds,
        searchHistory,
        bookings,
        bookingsIsLoading,
        bookingsIsFetching,
        bookingsIsError
      }}
    >
      {children}
    </CarouselWidgetsContext.Provider>
  )
}

export {CarouselWidgetsContext, CarouselWidgetsProvider}
