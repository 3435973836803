const MAPBOX_STYLE_PREFIX = 'mapbox://styles/'
export const MAPBOX_STYLE_PART = 'leeclissett/clxllxwyy00g601qmfrrce321'
export const MAPBOX_STYLE = `${MAPBOX_STYLE_PREFIX}${MAPBOX_STYLE_PART}`
export const MAPBOX_STYLE_PART_STANDARD = 'mapbox/standard'
export const MAPBOX_STYLE_STANDARD = `${MAPBOX_STYLE_PREFIX}${MAPBOX_STYLE_PART_STANDARD}`
export const MAPBOX_MIN_ZOOM = 0 // Min zoom value based on mapbox docs
export const MAPBOX_MAX_ZOOM = 22 // Max zoom value based on mapbox docs
export const MAPBOX_DEFAULT_ZOOM = 14
export const MAPBOX_ZOOM_INCREMENT = 1
export const MAPBOX_SATELLITE_STYLE = `${MAPBOX_STYLE_PREFIX}leeclissett/clmj1tj1c008w01pd8akbgpbx?optimize=true`
export const MAPBOX_PITCH_DEFAULT = 0
export const MAPBOX_PITCH_3D = 62
