import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAvailabilityPopupVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {AvailabilityPopup} from './AvailabilityPopup'

export const AvailabilityPopupWrapper = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getAvailabilityPopupVisibility)
  const onClose = useCallback(() => {
    dispatch(hideOverlay())
  }, [dispatch])

  return <AvailabilityPopup isOpen={isOpen} onClose={onClose} />
}
