import {cookieSecuritySettings} from '@daedalus/core/src/_web/utils/cookies/cookieSecuritySettings'

const maxAge = 365 * 30 // 30 years
const name = 'currencyCode'

const config = {
  name,
  options: {
    maxAge,
    ...cookieSecuritySettings(true)
  }
}

export default config
