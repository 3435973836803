import {FullStory} from '@daedalus/core/src/analytics/types/FullStory'

declare let FS: FullStory

export const getFullStorySessionUrl = () => {
  const fs = typeof FS === 'undefined' ? undefined : FS
  if (fs) {
    return fs.getCurrentSessionURL?.(true)
  }
}
