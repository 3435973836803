import {ExitPopupPage} from 'components/ExitPopup/useExitPopup'
import {pipe} from 'ramda'

import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '@daedalus/core/src/_web/utils/persistence'
import {
  removeOutdatedUserSearchHistory,
  selectUniqueSearches,
  updateExpiredDates,
  UserSearchHistoryItem
} from '@daedalus/core/src/searchHistory'

export const getPopupOnExitShown = (): boolean =>
  Boolean(
    safelyGetStorageItem(
      sessionStorage,
      'isPopupOnExitShown',
      'getPopupOnExitShown failed'
    )
  )
export const setPopupOnExitShown = (page: ExitPopupPage): void =>
  safelySetStorageItem(
    sessionStorage,
    `isPopupOnExitShown_${page}`,
    '1',
    `isPopupOnExitShown_${page}_Failed`
  )

export const selectUniqueNonExpiredHotelSearches = pipe(
  JSON.parse,
  selectUniqueSearches,
  updateExpiredDates
)

export const getUniqueRecentHotelSearches = (): UserSearchHistoryItem[] => {
  const empty = []
  const searchHistory = safelyGetStorageItem(
    localStorage,
    'searchHistory',
    'getUserSearchHistory failed'
  )

  try {
    return selectUniqueNonExpiredHotelSearches(searchHistory)
  } catch (error) {
    return empty
  }
}

const SEARCH_HISTORY_LIMIT = 4

export const getUserSearchHistory = (
  limitToSearchHistory = SEARCH_HISTORY_LIMIT,
  removeOutdated = true
): UserSearchHistoryItem[] => {
  const empty = []
  const searchHistory = safelyGetStorageItem(
    localStorage,
    'searchHistory',
    'getUserSearchHistory failed'
  )

  try {
    const parsedSearchHistory = JSON.parse(searchHistory).slice(
      0,
      limitToSearchHistory
    )

    if (removeOutdated) {
      return removeOutdatedUserSearchHistory(parsedSearchHistory)
    }

    return parsedSearchHistory
  } catch (error) {
    return empty
  }
}

export const setUserSearchHistory = (
  searchData: UserSearchHistoryItem[]
): void =>
  safelySetStorageItem(
    localStorage,
    'searchHistory',
    JSON.stringify(searchData),
    'setUserSearchHistory failed'
  )

export const removeUserSearchHistoryById = (historyId: string) => {
  const oldList = getUserSearchHistory()
  const newList = oldList.filter(item => item.historyId !== historyId)
  setUserSearchHistory(newList)
}

export const isCookiesAcceptedByUser = (): boolean =>
  Boolean(
    safelyGetStorageItem(
      localStorage,
      'cookiePreference',
      'isCookiesAcceptedByUser failed'
    )
  )

export const setCookiesAcceptedByUser = (): void => {
  safelySetStorageItem(
    localStorage,
    'cookiePreference',
    'acceptedByUser',
    'setCookiesAcceptedByUser failed'
  )
}

export const getLogEvents = (): string =>
  safelyGetStorageItem(localStorage, 'logEvents', 'getLogEvents failed')

export const setLogEvents = (logEvents: string): void =>
  safelySetStorageItem(
    localStorage,
    'logEvents',
    logEvents,
    'setLogEvents failed'
  )
