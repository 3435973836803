import {useDispatch, useSelector} from 'react-redux'
import {OfferViewType} from 'context/offerViewContext'
import {expandRoomsView, setScrollToApTarget} from 'modules/accommodation/slice'
import {AccommodationPageNavSections} from 'modules/accommodation/types'
import {OverlayType} from 'modules/overlay/types'
import {getFilteredRoomsData} from 'modules/searchApi/selectors'
import {history} from 'utils'

import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

import {COMPACT_ROOMS_AMOUNT} from '../../components/Accommodation/Rooms/constants'
import {getScrollTargetSpecificRoom} from './utils'

export const useHandleScrollToClickedOfferTarget = ({
  offerView
}: {
  offerView: OfferViewType
}) => {
  const overlayState = window.history.state?.state?.overlay
  const dispatch = useDispatch()
  const {rooms} = useSelector(getFilteredRoomsData)

  const onOfferClick = (clickedOffer?: SearchOffer) => {
    const matchingRoom = clickedOffer
      ? rooms.find(room =>
          room.offers.find(offer => offer.id == clickedOffer.id)
        )
      : null

    const shouldExpandRooms =
      rooms.indexOf(matchingRoom) >= COMPACT_ROOMS_AMOUNT

    if (shouldExpandRooms) {
      dispatch(expandRoomsView())
    }

    const indexOfAp = overlayState?.findIndex(
      overlay => overlay.type === OverlayType.HotelDetails
    )

    const forceScrollToRoomsSection = [
      OfferViewType.AP_OVERLAY,
      OfferViewType.AP_FOOTER,
      OfferViewType.AP_DESKTOP
    ].includes(offerView)

    const hasOverlaysOnTop = overlayState?.length > indexOfAp + 1
    const scrollTarget =
      matchingRoom && !forceScrollToRoomsSection
        ? getScrollTargetSpecificRoom(matchingRoom.id)
        : AccommodationPageNavSections.Rooms

    dispatch(setScrollToApTarget({target: scrollTarget}))

    if (hasOverlaysOnTop) {
      const newOverlayState = overlayState.slice(0, indexOfAp + 1)
      const stepsAmount = newOverlayState.length - overlayState.length
      history.go(stepsAmount)
      return
    }
  }

  return {
    onOfferClick
  }
}
