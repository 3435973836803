import React, {useCallback, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {FilterDisplayContainer} from 'components/FilterDisplayContainer/FilterDisplayContainer'
import {filterMessages} from 'components/Filters/filterMessages'
import {ReviewScoreFilter} from 'components/Filters/ReviewScoreFilter/ReviewScoreFilter'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {searchParamToFacet} from 'modules/search/constants'

import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

const filterKeys = [searchParamToFacet.reviewScore.param]

interface Props {
  isActive?: boolean
}

export const ReviewScoreFilterPopover: React.FC<Props> = ({
  isActive = false
}) => {
  const {formatMessage} = useIntl()
  const {
    toggleFilters,
    getFilters,
    getAppliedFilters,
    submitFilters,
    resetFilters,
    clearFilters,
    trackFilterClick
  } = useFiltersForm(filterKeys)

  const {reviewScore} = getFilters()
  const appliedReviewScoreFilter = getAppliedFilters().reviewScore

  const label = useMemo(() => {
    if (!appliedReviewScoreFilter) {
      return formatMessage(filterMessages.reviewScore)
    }

    return (
      <FormattedMessageWrapper
        id="guestRatingsWithValue"
        defaultMessage="{value}+ rating"
        values={{
          value: Number(appliedReviewScoreFilter).toFixed(1)
        }}
      />
    )
  }, [appliedReviewScoreFilter, formatMessage])

  const handleApply = useCallback(() => {
    submitFilters()
  }, [submitFilters])

  return (
    <FilterDisplayContainer
      label={label}
      dataId="reviewScoreFilter"
      title={<FormattedMessageWrapper {...filterMessages.reviewScore} />}
      isActive={isActive}
      onClear={clearFilters}
      onApply={handleApply}
      onClose={resetFilters}
      onPillClick={trackFilterClick}
    >
      <ReviewScoreFilter value={reviewScore} onChange={toggleFilters} />
    </FilterDisplayContainer>
  )
}
