import {useMemo} from 'react'

import {sortRoomsByNightlyDisplayTotalAsc} from '@daedalus/core/src/room/business/utils/sortRooms'
import {Room} from '@daedalus/core/src/room/types/room'

// Stable reference prevents renders
const EMPTY_ROOMS = []

/**
 * Get cheapest room from rooms list
 */
export const useGetCheapestRoom = (rooms: Room[] = EMPTY_ROOMS) => {
  return useMemo(() => sortRoomsByNightlyDisplayTotalAsc(rooms)[0], [rooms])
}
