import * as React from 'react'
import {useSelector} from 'react-redux'
import {getAnonymousId, getUserCountryCode} from 'modules/meta/selectors'
import Settings from 'Settings'

import {denyUrls, ignoreErrors} from './ignorePatterns'

const IGNORED_USER_AGENTS = /google-read-aloud/i
/**
 * Here we ignore error from bots that are just adding
 * noise to our logs.
 */
const beforeSend = (event, hint) => {
  if (IGNORED_USER_AGENTS.test(navigator.userAgent)) {
    return null
  }

  // Filter ChunkLoadError errors. We report them as ModuleLoadError.
  if (event.exception?.values?.[0]?.type === 'ChunkLoadError') {
    return null
  }

  // Filter CSS loading errors. We report them as ModuleLoadError.
  if (event.exception?.values?.[0]?.value.startsWith('Loading CSS chunk')) {
    return null
  }

  // Filter Google One Tap error by message
  const error = hint?.originalException
  if (error?.message?.match(/malformed JSON response/i)) {
    return null
  }

  return event
}

const InitializeSentry: React.FC = ({children}) => {
  const anonymousId = useSelector(getAnonymousId)
  const userCountryCode = useSelector(getUserCountryCode)
  const SENTRY_DSN = Settings.get('SENTRY_DSN')
  const sentryIsEnabled = typeof Sentry !== 'undefined' && SENTRY_DSN

  React.useEffect(() => {
    if (userCountryCode) {
      if (sentryIsEnabled) {
        Sentry.configureScope(scope => {
          scope.setTag('country', userCountryCode)
        })
      }
    }
  }, [userCountryCode, sentryIsEnabled])

  try {
    if (sentryIsEnabled) {
      Sentry.onLoad(() => {
        Sentry.init({
          environment: Settings.get('APP_ENV'),
          release: Settings.get('APP_VERSION'),
          denyUrls,
          ignoreErrors,
          beforeSend
        })
        Sentry.configureScope(scope => {
          scope.setUser({id: anonymousId})
        })
      })
    }
  } catch (error) {
    console.error('Error initializing Sentry.', error)
  }

  return <>{children}</>
}

export default InitializeSentry
