import memoize from 'lodash/memoize'

import {hasChargeLaterTag} from '../../../offer/business/chargeLater'
import {isSplitBookingPriceEligible} from '../../../offer/business/isSplitBookingPriceEligible'
import {MEAL_AMENITIES} from '../../../offer/business/meal'
import {Offer} from '../../../offer/types/offer'
import {getDisplayPrice, getTotalPrice} from '../../../price/business/price'
import {TransformedRoomsResponse} from '../../../sapi/services/searchApi'
import {Room, SplitBooking} from '../../types/room'
import {sortRoomsByNightlyDisplayTotalAsc} from './sortRooms'

/**
 * All supported filters
 */
export enum RoomsFilterKey {
  FreeCancellation = 'freeCancellation',
  BreakfastIncluded = 'breakfastIncluded',
  PayLater = 'payLater'
}

export enum FilterStrategy {
  Some = 'some',
  Every = 'every'
}

export type RoomsFilterParameters = Partial<
  Record<(typeof RoomsFilterKey)[keyof typeof RoomsFilterKey], boolean>
>

export interface FilteredRoomsData extends TransformedRoomsResponse {
  rooms: Room[]
  totalResults: number
  isSplitBookingAvailable: boolean
  availableFilters: RoomsFilterParameters
}

const filterPredicates = {
  payLater: (offer: Offer) => {
    return offer.canPayLater || hasChargeLaterTag(offer)
  },
  breakfastIncluded: (offer: Offer) =>
    offer.services.some(amenity => MEAL_AMENITIES.has(amenity)),
  freeCancellation: (offer: Offer) => offer.cancellationPenalties.length > 0
}

/**
 * Predicates for each room filter, using "some" method by default
 */
const filterConditions = {
  payLater: (offers: Offer[]) => offers.some(filterPredicates.payLater),
  breakfastIncluded: (offers: Offer[]) =>
    offers.some(filterPredicates.breakfastIncluded),
  freeCancellation: (offers: Offer[]) =>
    offers.some(filterPredicates.freeCancellation)
}

/**
 * Predicates for each room filter, using "every" method
 */
const filterConditionsEvery = {
  payLater: (offers: Offer[]) => offers.every(filterPredicates.payLater),
  breakfastIncluded: (offers: Offer[]) =>
    offers.every(filterPredicates.breakfastIncluded),
  freeCancellation: (offers: Offer[]) =>
    offers.every(filterPredicates.freeCancellation)
}

/**
 * Function that generates an offer list based on the active filters.
 * @returns An array of offers that meet the active filters.
 */
export const filterOffersWithActiveFilters = (
  offers: Offer[],
  activeFilters: RoomsFilterKey[]
) => {
  return offers.filter(offer =>
    activeFilters.every(filterKey =>
      filterConditions[filterKey] ? filterConditions[filterKey]([offer]) : false
    )
  )
}

/**
 * Applies active filters to a list of room objects.
 * Will remove the room object completely if all offers are filtered out
 * Also updates hasClickedOffer if clicked offer was filtered out
 * @param rooms - list of room objects that have a list of offers inside
 * @param filters - list of active room filters
 * @returns An array of rooms that meet the active filters.
 */
const filterRoomsAndOffers = (rooms: Room[], filters: RoomsFilterKey[]) =>
  rooms.reduce<Room[]>((acc, room) => {
    const filteredOffers = filterOffersWithActiveFilters(room.offers, filters)
    const hasClickedOffer = filteredOffers.some(offer => offer.isClicked)
    if (filteredOffers.length > 0) {
      return [...acc, {...room, hasClickedOffer, offers: filteredOffers}]
    }

    return acc
  }, [])

export const filterSplitBooking = (
  splitBooking: SplitBooking | undefined,
  filters: RoomsFilterKey[]
) => {
  const flatSplitBookingOffers =
    splitBooking?.offers.map(offer => offer.offer) || []

  return filterOffersWithActiveFilters(flatSplitBookingOffers, filters)
    .length === flatSplitBookingOffers.length
    ? splitBooking
    : undefined
}

export const getAvailableFiltersFromSplitBooking = (
  splitBooking: SplitBooking | undefined
) => {
  const offers = splitBooking?.offers.map(offer => offer.offer) || []

  return getAvailableFilters(offers, FilterStrategy.Every)
}

/**
 * Returns all the filters available for the rooms response data set (splitBooking and regular rooms)
 * @returns An object with filter flags set to true when available
 */
export const getFiltersAvailableForRoomsResult = (
  rooms: Room[],
  splitBooking?: SplitBooking
) => {
  const flatRoomOffers = rooms
    .map(room => room.offers)
    .reduce((acc, cur) => [...acc, ...cur], [])

  const availableRoomFilters = getAvailableFilters(flatRoomOffers)
  const availableSplitBookingFilters =
    getAvailableFiltersFromSplitBooking(splitBooking)

  return Object.fromEntries(
    Object.values(RoomsFilterKey).map(filterId => [
      filterId,
      Boolean(
        availableRoomFilters[filterId] || availableSplitBookingFilters[filterId]
      )
    ])
  )
}

/**
 * Apply rooms filters and build metadata required for filters
 *  * @returns An object containing the filtered list of rooms, the total number of results, and the available filters
 */
export const applyRoomsFilter = (
  {
    rooms,
    splitBooking: responseSplitBooking,
    ...rest
  }: TransformedRoomsResponse,
  filters: RoomsFilterKey[]
): FilteredRoomsData => {
  const filteredRooms = filters?.length
    ? sortRoomsByNightlyDisplayTotalAsc(filterRoomsAndOffers(rooms, filters)) // Re-sort because we may have removed the cheaper offers
    : rooms
  const cheapestOffer = rooms[0]?.offers[0]
  const cheapestOfferTotalPrice = cheapestOffer
    ? getTotalPrice(getDisplayPrice(cheapestOffer.prices))
    : 0
  const isSplitBookingOfferTheCheapest = isSplitBookingPriceEligible(
    responseSplitBooking?.totalRate,
    cheapestOfferTotalPrice
  )
  const splitBooking =
    isSplitBookingOfferTheCheapest || responseSplitBooking?.isClicked
      ? responseSplitBooking
      : undefined

  const availableFilters = getFiltersAvailableForRoomsResult(
    rooms,
    splitBooking
  )

  return {
    ...rest,
    rooms: filteredRooms,
    splitBooking: filterSplitBooking(splitBooking, filters),
    isSplitBookingAvailable: !!splitBooking,
    totalResults: rooms.length + (splitBooking ? 1 : 0),
    availableFilters
  }
}

export const filtersToCacheKey = (filters: RoomsFilterKey[]) =>
  filters.slice(0).sort().join()

/**
 * Apply rooms filter with memoization by searchId + filters as a cache key
 * Prefer using this because memoizing selectors and component state is very fragile. Note that the key generator may still run many times.
 */
export const applyRoomsFilterMemo = memoize(
  applyRoomsFilter,
  (data, filters) => `${data.searchId}.${filtersToCacheKey(filters)}`
)

/**
 * Function that returns an object indicating which filters are available, based on rooms data
 * @returns An object that each key is a filter ID and the value is a boolean indicating if the filter is available.
 */
export const getAvailableFilters = (
  offers: Offer[],
  strategy: FilterStrategy = FilterStrategy.Some
) => {
  const conditions =
    strategy === FilterStrategy.Some ? filterConditions : filterConditionsEvery

  return Object.fromEntries(
    Object.values(RoomsFilterKey).map(filterId => [
      filterId,
      offers.length ? conditions[filterId](offers) : false
    ])
  ) as RoomsFilterParameters
}

/**
 * Convert a list of filter keys to a parameters object
 * @returns An object where each key is a filter ID, and the value is a boolean indicating if the filter is in the list
 */
export const roomsFilterListToMap = (activeFilters: RoomsFilterKey[]) => {
  return Object.fromEntries(
    Object.values(RoomsFilterKey).map(filterId => [
      filterId,
      activeFilters.includes(filterId)
    ])
  ) as RoomsFilterParameters
}
/**
 * Convert an object of SRP filters to AP Rooms filters
 * @returns a list of RoomsFilterKey values compatible with Rooms filters
 */
export const mapSearchToRoomsFilters = (searchFilters: {
  // eslint-disable-next-line no-undef
  freeCancellation?: BooleanUrlParam
  amenities?: string | string[]
}) => {
  const hasBreakfastIncluded = searchFilters.amenities?.includes('breakfast')
  const hasFreeCancellation = Boolean(searchFilters.freeCancellation)
  const roomsFilters: Array<RoomsFilterKey> = []
  if (hasBreakfastIncluded) {
    roomsFilters.push(RoomsFilterKey.BreakfastIncluded)
  }
  if (hasFreeCancellation) {
    roomsFilters.push(RoomsFilterKey.FreeCancellation)
  }

  return roomsFilters
}
