import {Page} from '@daedalus/core/src/analytics/types/Events'

export interface ShouldShowChargeLaterPerkParams {
  page?: Page
  isChargeLaterSelected?: boolean
  isChargeLaterEligible?: boolean
  isChargeLaterBooking?: boolean
  chargeLaterDate?: Date
}

/**
 * A function that determines whether the charge later perk should be shown
 * @param isChargeLaterSelected - Whether the charge later option is selected on the payment page
 * @param isChargeLaterEligible - Whether the offer is eligible for charge later
 * @param isChargeLaterBooking - Whether it's an existing charge later booking
 * @param page - Page where the component is rendered
 * @param chargeLaterDate - The date when the charge will be made
 */
export const shouldShowChargeLaterPerk = ({
  isChargeLaterSelected,
  isChargeLaterEligible,
  isChargeLaterBooking,
  page,
  chargeLaterDate
}: ShouldShowChargeLaterPerkParams) => {
  if (!chargeLaterDate) return false

  // Charge later perk should not be shown if it's an existing charge later booking
  if (isChargeLaterBooking) return false

  // On the payment page, we should show the charge later perk if the charge later option is selected. If a user selects charge now, they should not see the charge later perk
  if (page === Page.Payment) return !!isChargeLaterSelected
  // On pages other than the payment page, the charge later perk should be shown if the offer is eligible for charge later or if it's an existing charge later booking
  return Boolean(isChargeLaterEligible || isChargeLaterBooking)
}

interface ShouldShowChargeLaterRefundPolicyParams {
  page?: Page
  isChargeLaterSelected?: boolean
  isChargeLaterEligible?: boolean
  isChargeLaterBooking?: boolean
}

/**
 * A function that determines whether the charge later refund policy should be shown in the cancellation policy modal
 * @param page - Page where the component is rendered
 * @param isChargeLaterSelected - Whether the charge later option is selected on the payment page
 * @param isChargeLaterEligible - Whether the offer is eligible for charge later
 * @param isChargeLaterBooking - Whether it's an existing charge later booking
 */
export const shouldShowChargeLaterRefundPolicy = ({
  page,
  isChargeLaterSelected,
  isChargeLaterEligible,
  isChargeLaterBooking
}: ShouldShowChargeLaterRefundPolicyParams) => {
  // we should show the refund policy if it's an existing charge later booking
  if (isChargeLaterBooking) return true
  // On the Payment page, if it's not an existing charge later booking, we should show the CL refund policy if the charge later option is selected
  if (page === Page.Payment) return !!isChargeLaterSelected

  return !!isChargeLaterEligible
}

interface ShouldHideCancellationDeadlineParams {
  isChargeLaterEligible?: boolean
  isChargeLaterBooking?: boolean
}

/**
 * A function that determines whether the cancellation deadline should be hidden.
 * For example, `Free cancellation before Jul 09` should be rendered as `Free cancellation` if the function returns true
 * @param isChargeLaterEligible - Whether the offer is eligible for charge later
 * @param isChargeLaterBooking - Whether it's an existing charge later booking
 */
export const shouldHideCancellationDeadline = ({
  isChargeLaterEligible,
  isChargeLaterBooking
}: ShouldHideCancellationDeadlineParams) => {
  return Boolean(isChargeLaterEligible || isChargeLaterBooking)
}

const DIFFERENTIAL_PERCENTAGE = 0.02
/**
 * A function to get the total differential price for CL differential price fake door
 * @param totalDisplayPrice - totalDisplayPrice of the offer
 */
export const getTotalChargeLaterDifferentialPrice = (
  totalDisplayPrice: number
) => {
  return totalDisplayPrice + totalDisplayPrice * DIFFERENTIAL_PERCENTAGE
}

/**
 * A function to get the differential price for CL differential price fake door
 * @param totalDisplayPrice - totalDisplayPrice of the offer
 */
export const getChargeLaterDifferentialPrice = (totalDisplayPrice: number) => {
  return totalDisplayPrice * DIFFERENTIAL_PERCENTAGE
}
