/**
 * Provider codes for Book With Hotel
 */
export const BOOK_WITH_HOTEL_PROVIDER_CODES = new Set([
  'BOO',
  'FSB',
  'TCK',
  'DYS',
  'FBD',
  'MIR',
  'MHS',
  'NOZ',
  'PHO',
  'SAB'
])

/**
 * Provider codes for BoVio partners that we always show as Vio.com
 */
export const BOFH_PROVIDER_CODES = new Set([
  'FHT',
  'GAR',
  'HBD',
  'EAN',
  'EPS',
  'PPN',
  'WBD',
  'SMR',
  'BTL',
  'FPH',
  'NTE',
  'WTM',
  'OLY',
  'HTR',
  'GOG'
])

export const PROVIDER_LOGO_URL_PREFIX = 'https://i.fih.io/provider'

export const VIO_INTERMEDIARY_PROVIDER_CODE = 'FHT'
export const DEFAULT_PROVIDER_NAME = 'Vio.com'
export const DEFAULT_PROVIDER_CODE = 'VIO'
export const VIO_PROVIDER_NAME = 'Vio.com'
export const VIO_PROVIDER_CODE = 'VIO'

const bookOnBrandProviders = {
  BOOKAWAY: 'Bookaway',
  DEMO: 'Vio Demo',
  HOLIDAYPIRATESDE: 'Urlaubspiraten'
} as const

/**
 * Map of provider codes to provider names
 */
export const providersMap = {
  ACC: 'Accor',
  AGD: 'Agoda',
  BKS: 'Booking.com',
  BOO: 'Revato',
  BWS: 'BestWestern',
  CTE: 'Trip.com',
  DES: 'Destinia',
  DRR: 'Drury',
  EBK: 'ebookers.com',
  EBX: 'ebookers.com',
  EXP: 'Expedia.com',
  HDE: 'hotel.de',
  HOA: 'Hotelopia',
  HOW: 'Hostelworld',
  HRS: 'hotel.de',
  IAN: 'Hotels.com',
  MMT: 'MakeMyTrip',
  ORB: 'Orbitz',
  OYO: 'Oyorooms',
  PLN: 'Priceline',
  RDI: 'Roomdi.com',
  SBO: 'Sembo',
  SFL: 'Stayforlong',
  TCY: 'Travelocity',
  TRO: 'Algotels',
  VIO: 'Vio.com',
  WTF: 'Wotif',
  ZHT: 'zenhotels',
  ...bookOnBrandProviders
} as const

type ProvidersMap = typeof providersMap
export type ProvidersMapKeys = keyof ProvidersMap
export type ProvidersMapValues = ProvidersMap[keyof ProvidersMap]

/**
 * Gets the provider code for BoFH/BoVio
 * @returns Vio/FindHotel's provider code, as specified by BoVio
 */
export const getBofhCode = (): string => {
  return VIO_PROVIDER_CODE.toLowerCase()
}

/**
 * Gets the provider name from the map or the default provider name.
 * @param providerCode - The provider code of the provider who's name is being requested.
 * @returns the provider's name from the map or the default provider name.
 */
export const getProviderName = (providerCode: string): string => {
  const code = providerCode.toUpperCase()

  return BOFH_PROVIDER_CODES.has(code)
    ? VIO_PROVIDER_NAME
    : providersMap[code as Uppercase<ProvidersMapKeys>]
}

/**
 * Builds the Cloudfront URL that serves the svg icon situated in an S3 bucket.
 * @param params - The parameters to build the URL, consisting of the provider code and whether it's the icon with text.
 * @param params.providerCode - The provider code of the provider who's icon is being requested.
 * @param params.withText - Whether it's the icon with text (Only applicable to Vio so far)
 * @returns The constructed URL for the pertinent provider icon.
 */
export const getProviderIcon = ({
  providerCode,
  withText
}: {
  providerCode: string
  withText?: boolean
}): string => {
  const upperCaseCode = providerCode.toUpperCase()

  const lowerCaseCode = BOFH_PROVIDER_CODES.has(upperCaseCode)
    ? getBofhCode()
    : upperCaseCode.toLowerCase()

  return (
    PROVIDER_LOGO_URL_PREFIX +
    `/svg/${withText ? 'withText/' : ''}${lowerCaseCode}--icon.svg`
  )
}

/**
 * Builds the Cloudfront URL that serves the svg logo situated in an S3 bucket.
 * @param params - The parameters to build the URL, consisting of the provider code and whether it's the logo with white text.
 * @param params.providerCode - The provider code of the provider who's logo is being requested.
 * @param params.withWhiteText - Whether it's the logo with white text (Only applicable to Vio so far on the redesigned footer)
 * @returns The constructed URL for the pertinent provider logo.
 */
export const getSvgProviderLogo = ({
  providerCode,
  withWhiteText
}: {
  providerCode: string
  withWhiteText?: boolean
}): string => {
  const upperCaseCode = providerCode.toUpperCase()

  const lowerCaseCode = BOFH_PROVIDER_CODES.has(upperCaseCode)
    ? getBofhCode()
    : upperCaseCode.toLowerCase()

  return (
    PROVIDER_LOGO_URL_PREFIX +
    `/svg/${withWhiteText ? 'withText/' : ''}${lowerCaseCode}${withWhiteText ? '-white' : ''}.svg`
  )
}
