import {useCallback, useEffect} from 'react'
import {append} from 'ramda'
import {v4 as uuid} from 'uuid'

import {
  addIdsToRoomsSplit,
  ensureRoomsSplitGuestCountIsValid,
  flattenRoomsSplitToSingleRoom,
  removeIdsFromRoomsSplit,
  roomsSplitToConfigurationObject
} from '@daedalus/core/src/room/business/roomConfiguration'
import {
  MANUAL_MAX_GUESTS_PER_ROOM,
  OPTIMIZED_MAX_GUESTS_PER_ROOM
} from '@daedalus/core/src/room/business/roomConfiguration/config'
import {
  RoomConfigType,
  RoomSplitType,
  UniqueRoomSplitType
} from '@daedalus/core/src/room/types/RoomConfiguration'

interface Props {
  roomConfigType?: RoomConfigType
  roomsSplit: RoomSplitType[]
  onSubmit: (roomsSplit: RoomSplitType[], isOptimizeRooms?: boolean) => void
  onRoomsChange: (roomsSplit: RoomSplitType[]) => void
}

export const useGuestRoomConfig = ({
  roomConfigType,
  roomsSplit,
  onSubmit,
  onRoomsChange
}: Props) => {
  const roomConfigIsOptimized = roomConfigType === RoomConfigType.Optimized
  const roomConfigIsManual = roomConfigType === RoomConfigType.Manual

  const localRoomsSplit = roomConfigIsManual
    ? addIdsToRoomsSplit(
        ensureRoomsSplitGuestCountIsValid({
          roomsSplit,
          maxGuests: MANUAL_MAX_GUESTS_PER_ROOM
        })
      )
    : addIdsToRoomsSplit(
        flattenRoomsSplitToSingleRoom({
          roomsSplit,
          maxGuests: OPTIMIZED_MAX_GUESTS_PER_ROOM
        })
      )

  // A more efficient way to "deep compare" a shallow object.
  // Use this in the dependency arrays instead of roomsSplit.
  const comparableRoomsSplit = JSON.stringify(roomsSplit)

  useEffect(() => {
    const roomsSplitOnChange = roomConfigIsOptimized
      ? flattenRoomsSplitToSingleRoom({
          roomsSplit,
          maxGuests: OPTIMIZED_MAX_GUESTS_PER_ROOM
        })
      : ensureRoomsSplitGuestCountIsValid({
          roomsSplit,
          maxGuests: MANUAL_MAX_GUESTS_PER_ROOM
        })

    onRoomsChange(addIdsToRoomsSplit(roomsSplitOnChange))

    // eslint-disable-next-line react-hooks/exhaustive-deps -- comparableRoomsSplit replaces roomsSplit
  }, [comparableRoomsSplit, roomConfigIsOptimized])

  const {numberOfAdults, numberOfChildren, numberOfRooms} =
    roomsSplitToConfigurationObject(localRoomsSplit)

  const handleSubmit = useCallback(
    (roomsSplit: UniqueRoomSplitType[]) => {
      const roomsSplitWithoutIds = removeIdsFromRoomsSplit(roomsSplit)

      onSubmit(roomsSplitWithoutIds, roomConfigIsOptimized)
    },
    [onSubmit, roomConfigIsOptimized]
  )

  const updateRoomsSplit = useCallback(
    (roomsSplit: UniqueRoomSplitType[]) => {
      onRoomsChange(roomsSplit)
    },
    [onRoomsChange]
  )

  const handleAddRoom = useCallback(() => {
    const newRoomsSplit = append(
      {adults: 1, children: [], id: uuid()},
      localRoomsSplit
    )

    updateRoomsSplit(newRoomsSplit)
  }, [localRoomsSplit, updateRoomsSplit])

  return {
    roomConfigIsOptimized,
    numberOfAdults,
    numberOfChildren,
    numberOfRooms,
    localRoomsSplit,
    handleSubmit,
    handleAddRoom,
    updateRoomsSplit
  }
}
