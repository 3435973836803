import React from 'react'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

import HotelDetailsOverlayDesktop from './Desktop'
import HotelDetailsOverlayMobile from './Mobile'

const HotelDetailsOverlayWrapper = () => {
  const {isMobile, isDesktopXs} = useDeviceLayout()

  if (isMobile || isDesktopXs) {
    return <HotelDetailsOverlayMobile />
  }

  return <HotelDetailsOverlayDesktop />
}

export default HotelDetailsOverlayWrapper
