import React from 'react'
import {motion} from 'framer-motion'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'

import {BaseHomeDesktopHeader} from './BaseHomeDesktopHeader'
import {BaseHomeMobileHeader} from './BaseHomeMobileHeader'
import {SearchBoxShadow} from './SearchBoxShadow'

interface Props {
  isVisible?: boolean
}
export const FixedHeader: React.FC<Props> = ({isVisible}) => {
  const {isMobile, isDesktopLg} = useDeviceLayout()

  return (
    <>
      <motion.div
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '4'
        }}
        initial={false}
        animate={{
          top: isVisible ? 0 : -170
        }}
      >
        {isMobile ? (
          <BaseHomeMobileHeader
            hasHeaderSearchSummary={true}
            hasDivider={true}
          />
        ) : (
          <BaseHomeDesktopHeader
            withSearchForm
            showSupportButton={isDesktopLg}
            hasShadow={true}
          />
        )}
      </motion.div>

      {isMobile && <SearchBoxShadow />}
    </>
  )
}
