import cookieConfig from 'config/cookies/languageCode'
import isString from 'lodash/isString'
import {parse} from 'qs'

import {getBrandCodeFromUrlOrCookie} from '@daedalus/core/src/_web/brand/utils'
import {getCookie, setCookie} from '@daedalus/core/src/_web/utils/cookies'
import {getBrandByCode} from '@daedalus/core/src/brand/config'
import {
  defaultLanguage as DEFAULT_LANGUAGE_CODE,
  enabledLanguages as ENABLED_LANGUAGES
} from '@daedalus/core/src/localization/services/locale'
import {localeLanguageMapper} from '@daedalus/core/src/localization/services/mappingLocales'

import {getDomainConfig} from './domain'
import {normalizeLanguage, parseLocale} from './locale'

const getEnabledLanguageCode = (languageCode: string | null): string | null => {
  if (!languageCode) return null

  let normalizedLanguage = normalizeLanguage(languageCode)

  if (!normalizedLanguage) return null

  normalizedLanguage = localeLanguageMapper(normalizedLanguage)

  return ENABLED_LANGUAGES.includes(normalizedLanguage)
    ? normalizedLanguage
    : null
}

const getFromQueryString = (): string | null => {
  const {lang} = parse(location.search, {
    ignoreQueryPrefix: true
  })

  if (!lang) return null

  // Check if lang url param is array and take the last item
  // Initially required by NFH -> V3 campaigns migration A/B test
  // FIXME: decide if we still need it after A/B is finished
  const language = Array.isArray(lang) ? lang[lang.length - 1] : lang

  // The value can be a deeper array
  if (!isString(language)) return null

  return getEnabledLanguageCode(language)
}

const getFromQueryStringIfForced = (): string | null => {
  const {forceLanguageChange} = parse(location.search, {
    ignoreQueryPrefix: true
  })

  if (forceLanguageChange === '1') return getFromQueryString()

  return null
}

const getFromCookie = (): string | null => getCookie(cookieConfig.name) || null

/**
 * @deprecated The domains used in this function seem outdated. Verify that they are correct before using this function.
 * @returns    The enabled language code from the domain configuration, or null if not found.
 */
const getFromDomain = (): string | null => {
  const domain = getDomainConfig(location.hostname)
  return domain ? getEnabledLanguageCode(domain.locale) : null
}

const getFromBrowserLanguage = (): string | null => {
  if (!navigator.language) return null

  const localeData = parseLocale(navigator.language)

  if (!localeData) return null

  return getEnabledLanguageCode(localeData.language)
}

const getFromBrandConfig = (): string | null => {
  const brand = getBrandByCode(
    getBrandCodeFromUrlOrCookie(process.env.NEXT_PUBLIC_ENV)
  )
  return brand?.bookOnBrand?.defaultLanguageCode
    ? getEnabledLanguageCode(brand.bookOnBrand.defaultLanguageCode)
    : null
}

/**
 * Language logic steps:
 *
 * 1 - Forced query string parameter: check if forceLanguageChange parameter is set to "1" in the URL and use step 3.
 * 2 - Cookie: check if language code is already stored in a cookie and use its value.
 * 3 - Query string parameter: check if lang parameter is present in the URL and use its value.
 * 4 - Brand: get language code from brand config.
 * 5 - Domain: get language code from country domain/subdomain.
 * 6 - Browser language: use the language of the user's browser.
 */
export const getLanguageCode = (): string =>
  getFromQueryStringIfForced() ||
  getFromCookie() ||
  getFromQueryString() ||
  getFromBrandConfig() ||
  getFromDomain() ||
  getFromBrowserLanguage() ||
  DEFAULT_LANGUAGE_CODE

export const persistLanguageCode = (languageCode: string) => {
  const {secure, sameSite, maxAge: expires} = cookieConfig.options
  setCookie(cookieConfig.name, languageCode, {
    expires,
    secure,
    sameSite
  })
}

export const getLanguageTrackingInfo = () => {
  return {
    urlLang: getFromQueryString(),
    cookieLang: getFromCookie(),
    browserLang: getFromBrowserLanguage()
  }
}
