import {
  EndpointNames,
  Pages,
  RequestMethod
} from '@vio/debug-panel/src/types/enums'
import {ScenarioMetadata} from '@vio/debug-panel/src/types/types'

export const metadata: ScenarioMetadata = {
  scope: 'permanent',
  name: 'My bookings simulations',
  value: 'Bookings',
  description:
    'Scenarios for the bookings endpoint, including upcoming bookings',
  routes: [
    {
      endpoint: EndpointNames.Bookings,
      method: RequestMethod.GET,
      page: Pages.HP
    }
  ]
}
