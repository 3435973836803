import {loadModule} from 'utils'

import type {PriceFilterBottomSheetContentProps} from './PriceFilterBottomSheetContent'

export const PriceFilterBottomSheetContentLoadable =
  loadModule<PriceFilterBottomSheetContentProps>(
    async () =>
      import(
        /* webpackChunkName: "PriceFilterBottomSheetContent" */
        './PriceFilterBottomSheetContent'
      ),
    {}
  )
