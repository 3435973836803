import {skipToken} from '@reduxjs/toolkit/query'

import {SearchData, useGetSearchQuery} from '../sapi/services/searchApi'
import {Options, SearchParameters} from './types'
import {isAnchorComplete, isComplete, isHotelsReceived} from './utils'

const getAnchorHasOffers = (data?: SearchData) => {
  const anchorOfferEntity = data?.anchorHotelId
    ? data.hotelOfferEntities?.[data.anchorHotelId]
    : undefined
  return Boolean(anchorOfferEntity?.offers?.length)
}

/** Sort anchor to top if it should be included */
const getSimilarHotelIds = (
  hotelIds: string[],
  anchorId: string | undefined,
  includeSameProperty: boolean,
  anchorHasOffers: boolean
) => {
  if (!anchorId || !anchorHasOffers) return hotelIds
  const filteredHotelIds = hotelIds.filter(id => id !== anchorId)
  return includeSameProperty
    ? [anchorId, ...filteredHotelIds]
    : filteredHotelIds
}

/**
 * Wraps useGetSearchQuery to get `hotelIds` and `isLoading` according to extended query options
 */
export const useGetSimilarHotels = (
  searchParameters: SearchParameters,
  {shouldWaitRequestComplete, includeSameProperty = false, ...options}: Options
) =>
  useGetSearchQuery(searchParameters, {
    ...options,
    selectFromResult: result => {
      if (searchParameters === skipToken) return result

      const {data, ...rest} = result

      const anchorHasOffers = getAnchorHasOffers(data)
      const hotelIds = getSimilarHotelIds(
        data?.hotelIds || [],
        searchParameters.hotelId,
        includeSameProperty,
        anchorHasOffers
      ).slice(0, searchParameters.pageSize) // SAPI may return more results to accommodate for the filtering we do on the client

      const filteredData: SearchData = {
        ...data,
        hotelIds
      }

      const anchorComplete = isAnchorComplete(data)
      const hotelsReceived = isHotelsReceived(data)
      const isAllComplete = isComplete(data)
      const isNotAllComplete = shouldWaitRequestComplete && !isAllComplete
      const isAnchorNotComplete =
        includeSameProperty && searchParameters.hotelId && !anchorComplete

      return {
        ...rest,
        data: filteredData,
        isLoading: isNotAllComplete || isAnchorNotComplete || !hotelsReceived
      }
    }
  })
