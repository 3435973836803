import React from 'react'

import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

export const YourChoiceLabel = () => {
  return (
    <Text
      data-id="YourChoiceLabel"
      variant="bodyXS"
      colorPath="content.featured.c500"
    >
      <FormattedMessageWrapper id="yourChoice" defaultMessage="Your Choice" />
    </Text>
  )
}
