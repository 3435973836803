import {isRejectedWithValue} from '@reduxjs/toolkit'
import handleFatalError from 'modules/common/actions/errors'

import {SearchApiError} from '@daedalus/core/src/sapi/services/searchApi'

import {MiddlewareType} from '..'

const middleware: MiddlewareType = () => next => action => {
  if (action.type === handleFatalError.type) {
    if (typeof Sentry !== 'undefined') {
      const {error, errorInfo} = action.payload
      Sentry.captureException(error, {extra: errorInfo})
    }
  }

  // Blow up as early warning for developers
  if (
    process.env.NODE_ENV === 'development' &&
    isRejectedWithValue(action) &&
    action.payload instanceof SearchApiError &&
    action.payload.message.includes('SAPI client timeout')
  ) {
    throw new Error(
      'Getting SAPI client for searchApi failed, is view wrapped in SapiProvider?'
    )
  }

  // Handle API slice errors
  if (isRejectedWithValue(action) && action.payload instanceof SearchApiError) {
    if (typeof Sentry !== 'undefined') {
      Sentry.captureMessage(action.payload)
    }
  }

  return next(action)
}

export default middleware
