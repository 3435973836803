import {loadModule} from '@daedalus/shared/src/utils/loadModule'

export const PriceWatchBottomSheetLoadable = loadModule(
  async () =>
    import(
      /* webpackChunkName: "PriceWatchBottomSheet" */
      './PriceWatchBottomSheet'
    ),
  {}
)
