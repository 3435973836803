import React, {PropsWithChildren, useContext, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {
  buildOfferContext,
  SplitBookingDataProps
} from 'middleware/analytics/contexts/offerContext'
import {getDeviceLayout} from 'modules/meta/selectors'
import {
  getAppLockedDealConfig,
  getSplitBookingDetails
} from 'modules/sapiSearch/selectors'
import {HotelOfferEntity, SearchOffer} from 'modules/sapiSearch/slice'
import {getIsOTAMode} from 'modules/search/selectors'

import {SearchOfferContext} from '@daedalus/core/src/analytics/types/Events'
import {getShouldSeeOffersUnlocked} from '@daedalus/core/src/auth/modules/selectors'
import {numberOfRooms as getNumberOfRooms} from '@daedalus/core/src/room/business/roomConfiguration'
import {GetOffersParams} from '@daedalus/core/src/sapi/services/searchApi'
import {SearchParameters} from '@daedalus/core/src/sapi/types'

export interface OfferTrackingContextType {
  offerEntity: HotelOfferEntity
  parameters: GetOffersParams | SearchParameters

  splitBookingDataProps?: SplitBookingDataProps
}

const OfferTrackingContext = React.createContext<
  OfferTrackingContextType | undefined
>(undefined)

/**
 * Provide a memoized context value from props
 */
export const OfferTrackingContextProvider = ({
  children,
  offerEntity,
  parameters,
  splitBookingDataProps
}: PropsWithChildren<OfferTrackingContextType>) => {
  const value = useMemo(
    () => ({offerEntity, parameters, splitBookingDataProps}),
    [offerEntity, parameters, splitBookingDataProps]
  )
  return (
    <OfferTrackingContext.Provider value={value}>
      {children}
    </OfferTrackingContext.Provider>
  )
}

const useOfferTrackingContext = () =>
  useContext<OfferTrackingContextType>(OfferTrackingContext)

interface Props {
  offer: SearchOffer
  offerEntity: HotelOfferEntity
  offerContext: SearchOfferContext
}

/**
 * Use context values to provide offer tracking data or fall back to what was provided
 */
export const useOfferTrackingContextWithFallback = ({
  offer,
  offerEntity,
  offerContext
}: Props) => {
  const context = useOfferTrackingContext()
  const shouldSeeOffersUnlocked = useSelector(getShouldSeeOffersUnlocked)
  const isOTAMode = useSelector(getIsOTAMode)
  const appLockedDealConfig = useSelector(state =>
    getAppLockedDealConfig(state, offerEntity?.id, offer?.id)
  )
  const splitBookingDetails = useSelector(state =>
    getSplitBookingDetails(state, offerEntity?.id)
  )
  const deviceLayout = useSelector(getDeviceLayout)

  // Construct a stable return value for fallback as well to prevent over-rendering
  return useMemo(
    () =>
      context
        ? {
            offerEntity: context.offerEntity,
            offerContext: buildOfferContext(
              offer?.id,
              offerContext?.sourceComponent,
              context.offerEntity,
              offer,
              getNumberOfRooms(context.parameters.rooms),
              shouldSeeOffersUnlocked,
              isOTAMode,
              appLockedDealConfig,
              splitBookingDetails,
              deviceLayout
            )
          }
        : {
            offerEntity,
            offerContext
          },
    [
      context,
      offer,
      offerEntity,
      offerContext,
      shouldSeeOffersUnlocked,
      isOTAMode,
      appLockedDealConfig,
      splitBookingDetails,
      deviceLayout
    ]
  )
}
