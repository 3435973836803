import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {toggle} from 'opticks'

import {getCookie, removeCookie} from '@daedalus/core/src/_web/utils/cookies'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'

/**
 * Added as part of lambda-a-a-experiment
 *
 * This component is used to track the mismatch between the lambda allocation and the
 * frontend one. Should be cleaned up after we verify that the allocations match.
 */
export const TrackSSRExperiments = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const cookieValue = getCookie('lambda-a-a-experiment')

    if (!cookieValue) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Experiment,
          action: Action.Missing
        })
      )
      return
    }

    removeCookie('lambda-a-a-experiment')

    const experimentSide = toggle('lambda-a-a-experiment', 'a', 'b')

    if (experimentSide !== cookieValue) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Experiment,
          action: Action.Mismatched
        })
      )
    }
  }, [dispatch])

  return null
}
