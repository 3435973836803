import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {filterMessages} from 'components/Filters/filterMessages'
import {useFiltersForm} from 'hooks/useFiltersForm'
import {showReviewScoreFilter} from 'modules/overlay/actions'
import {searchParamToFacet} from 'modules/search/constants'

import {Icon} from '@daedalus/atlas/Icon'
import {Pill} from '@daedalus/atlas/Pill'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReviewScoreBottomSheet} from './ReviewScoreBottomSheet'

const filterKeys = [searchParamToFacet.reviewScore.param]

interface Props {
  isActive?: boolean
  isQuickFilter?: boolean
  quickFilterValue?: string
}

export const ReviewScorePill: React.FC<Props> = ({
  isActive = false,
  isQuickFilter = false,
  quickFilterValue = '8'
}) => {
  const dispatch = useDispatch()

  const {formatMessage} = useIntl()
  const {getAppliedFilters, trackFilterClick, submitFilters} =
    useFiltersForm(filterKeys)

  const appliedReviewScoreFilter = getAppliedFilters().reviewScore

  const label = useMemo(() => {
    if (!appliedReviewScoreFilter && !isQuickFilter) {
      return formatMessage(filterMessages.reviewScore)
    }

    return (
      <FormattedMessageWrapper
        id="guestRatingsWithValue"
        defaultMessage="{value}+ rating"
        values={{
          value: Number(
            isQuickFilter ? quickFilterValue : appliedReviewScoreFilter
          ).toFixed(1)
        }}
      />
    )
  }, [appliedReviewScoreFilter, formatMessage, isQuickFilter, quickFilterValue])

  const handleOnPillClick = () => {
    trackFilterClick({
      category: Category.User,
      entity: Entity.FilterButton,
      action: Action.Clicked
    })

    if (!isQuickFilter) {
      dispatch(showReviewScoreFilter())
    } else {
      submitFilters({
        reviewScore: quickFilterValue
      })
    }
  }

  if (isQuickFilter && isActive) {
    return null
  }

  return (
    <>
      <Pill
        dataId={isQuickFilter ? 'reviewScoreQuickFilter' : 'reviewScoreFilter'}
        onClick={handleOnPillClick}
        isSelected={isActive}
        showArrow={!isQuickFilter}
        iconStart={isQuickFilter && <Icon name="SpeechBubble" />}
      >
        {label}
      </Pill>
      <ReviewScoreBottomSheet />
    </>
  )
}
