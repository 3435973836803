import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getPreferencesVisibility} from 'modules/overlay/selectors'
import {hideOverlay} from 'modules/overlay/slice'

import {ANIMATION_TYPES} from '@daedalus/atlas/Overlay/Overlay'

import {PreferencesOverlayLoadable} from './Loadable'

export const PreferencesOverlay = ({
  forceOpen = false
}: {
  forceOpen?: boolean
}) => {
  const isPreferencesOpen = useSelector(getPreferencesVisibility) || forceOpen
  const dispatch = useDispatch()
  const handleHidePreferences = forceOpen ? null : () => dispatch(hideOverlay())

  return (
    isPreferencesOpen && (
      <PreferencesOverlayLoadable
        isOpen={isPreferencesOpen}
        animationType={
          forceOpen ? ANIMATION_TYPES.NONE : ANIMATION_TYPES.SLIDE_RIGHT
        }
        hidePreferences={handleHidePreferences}
      />
    )
  )
}
