import React from 'react'

import {cssTheme} from '@daedalus/atlas/themes'

interface Props {
  className?: string
}

export const EngineeredPrivateDealLogo = ({className}: Props) => {
  const {colors} = cssTheme

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={74}
      height={20}
      viewBox="0 0 74 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#acdclip)">
        <path
          fill={colors.background.special.c500}
          fillRule="evenodd"
          d="M0 11.65s2.378-.343 3.07-1.057c.69-.715 1.022-3.173 1.022-3.173s.338 2.464 1.024 3.173c.685.709 3.069 1.057 3.069 1.057s-2.366.33-3.07 1.058c-.703.727-1.023 3.172-1.023 3.172s-.287-2.412-1.023-3.172C2.334 11.948 0 11.65 0 11.65Z"
          clipRule="evenodd"
          opacity={0.5}
        />
        <path
          fill={colors.content.neutral.c000}
          stroke={colors.background.special.c700}
          d="M12.274 6.416c0 .566-.44.996-.947.996-.508 0-.947-.43-.947-.996 0-.566.44-.996.947-.996.508 0 .947.43.947.996Z"
          opacity={0.75}
        />
        <path
          fill={colors.background.special.c500}
          fillRule="evenodd"
          d="M18.861 4.173s1.784-.257 2.302-.793c.519-.536.768-2.38.768-2.38s.253 1.848.767 2.38c.514.531 2.302.793 2.302.793s-1.774.247-2.302.793-.767 2.38-.767 2.38-.216-1.81-.768-2.38c-.551-.57-2.302-.793-2.302-.793Z"
          clipRule="evenodd"
          opacity={0.5}
        />
        <path
          fill={colors.background.special.c500}
          fillRule="evenodd"
          d="M14.345 16.885s1.178-.16 1.535-.529c.356-.368.511-1.586.511-1.586s.17 1.233.512 1.586c.34.353 1.535.529 1.535.529s-1.193.175-1.535.528C16.56 17.767 16.39 19 16.39 19s-.17-1.234-.511-1.587c-.341-.352-1.535-.528-1.535-.528Z"
          clipRule="evenodd"
        />
        <path
          fill={colors.background.special.c500}
          fillRule="evenodd"
          d="M74 11.65s-2.416-.343-3.119-1.057c-.702-.715-1.04-3.173-1.04-3.173s-.342 2.464-1.039 3.173c-.697.709-3.119 1.057-3.119 1.057s2.404.33 3.119 1.058c.715.727 1.04 3.172 1.04 3.172s.292-2.412 1.04-3.172C71.628 11.948 74 11.65 74 11.65Z"
          clipRule="evenodd"
          opacity={0.5}
        />
        <path
          fill={colors.content.neutral.c000}
          stroke={colors.background.special.c700}
          d="M61.52 6.416c0 .558.443.996.97.996.528 0 .97-.438.97-.996a.983.983 0 0 0-.97-.996.983.983 0 0 0-.97.996Z"
          opacity={0.75}
        />
        <path
          fill={colors.content.neutral.c000}
          stroke={colors.background.special.c500}
          d="M48.288 13.52c0 .352.278.622.603.622.325 0 .603-.27.603-.622a.612.612 0 0 0-.603-.622.612.612 0 0 0-.603.622ZM25.5 13.52c0 .352.278.622.603.622.324 0 .602-.27.602-.622a.612.612 0 0 0-.602-.622.612.612 0 0 0-.603.622Z"
          opacity={0.75}
        />
        <path
          fill={colors.background.special.c500}
          fillRule="evenodd"
          d="M54.835 4.173s-1.812-.257-2.34-.793C51.97 2.844 51.717 1 51.717 1s-.257 1.848-.78 2.38c-.522.531-2.339.793-2.339.793s1.803.247 2.34.793c.536.546.78 2.38.78 2.38s.218-1.81.779-2.38c.56-.57 2.339-.793 2.339-.793Z"
          clipRule="evenodd"
          opacity={0.5}
        />
        <path
          fill={colors.background.special.c500}
          fillRule="evenodd"
          d="M59.424 16.885s-1.197-.16-1.56-.529c-.361-.368-.52-1.586-.52-1.586s-.172 1.233-.519 1.586c-.347.353-1.56.529-1.56.529s1.212.175 1.56.528c.348.354.52 1.587.52 1.587s.173-1.234.52-1.587c.346-.352 1.56-.528 1.56-.528ZM42.875 8.4C42.85 4.86 40.677 2 38 2s-4.85 2.86-4.875 6.4c-.898 0-1.625.781-1.625 1.745v6.11c0 .963.727 1.745 1.625 1.745h9.75c.897 0 1.625-.782 1.625-1.746v-6.109c0-.964-.728-1.745-1.625-1.745Zm-2.45 0C40.296 6.165 39.26 4.423 38 4.423c-1.26 0-2.297 1.742-2.424 3.977h4.848ZM38 11.032a1.29 1.29 0 0 1 .65 2.411v1.68a.648.648 0 0 1-.65.646.648.648 0 0 1-.65-.646v-1.68a1.29 1.29 0 0 1 .65-2.411Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="acdclip">
          <path fill={colors.content.neutral.c000} d="M0 0h74v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
