import {PayloadAction} from '@reduxjs/toolkit'
import {Middleware} from 'redux'

import {sapiAvailabilityToDaedalusModel} from '@daedalus/core/src/offer/business/offersMapping'
import {AvailabilityResults, SearchResults} from '@findhotel/sapi'

import {getMappingContextFromState, sapiToDaedalusModel} from '../offersMapping'
import {State} from '../selectors'
import {hotelsAvailabilityCompleted, offersReceived} from '../slice'

/**
 * [OffersV3]
 * Added as part of [this epic](https://app.shortcut.com/findhotel/epic/62938/offers-v3-model-search-clients-migration)
 *
 * Transforms the new offers data model V3 to the old backend augmentation format used by the website
 * in the OFFERS_RECEIVED action payload.
 *
 * Should be deleted after the migration to the new models is complete.
 */
const offersV3MappingMiddleware: Middleware =
  store => next => (action: PayloadAction<object>) => {
    const state: State = store.getState()
    if (action.type === offersReceived.type) {
      return next({
        ...action,
        payload: sapiToDaedalusModel(action.payload as SearchResults, state)
      })
    }

    if (action.type === hotelsAvailabilityCompleted.type) {
      const mappingContext = getMappingContextFromState(state)

      const daedalusAvailability = sapiAvailabilityToDaedalusModel(
        action.payload as AvailabilityResults,
        mappingContext
      )

      return next({
        ...action,
        payload: {
          ...action.payload,
          ...daedalusAvailability
        }
      })
    }

    return next(action)
  }

export default offersV3MappingMiddleware
