export const updateOverlayOfType = (overlays, type, newOverlayState) => {
  const current = overlays.find(overlay => overlay.type === type)
  const updated = {...current, ...newOverlayState}
  const currentPosition = overlays.indexOf(current)

  return [
    ...overlays.slice(0, currentPosition),
    updated,
    ...overlays.slice(currentPosition + 1)
  ]
}
