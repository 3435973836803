import {useDispatch} from 'react-redux'

import {oneTimePasswordIsValid, sendOneTimePassword} from '../services'
import {setIsPhoneVerified} from '../slice'
import {InitOneTimePassword} from '../types'

export const useOneTimePassword = ({
  brandCode,
  languageCode,
  phoneNumber,
  oneTimePasswordUrl,
  verifyUrl
}: InitOneTimePassword) => {
  const dispatch = useDispatch()

  const handleSendOneTimePassword = async () => {
    return await sendOneTimePassword({
      brandCode,
      languageCode,
      phoneNumber,
      oneTimePasswordUrl
    })
  }

  const handleValidateOneTimePassword = async (inputPassword: string) => {
    const isCorrect = await oneTimePasswordIsValid({
      brandCode,
      phoneNumber,
      password: inputPassword,
      verifyUrl
    })

    await dispatch(setIsPhoneVerified(isCorrect))
    return isCorrect
  }

  return {
    sendOneTimePassword: handleSendOneTimePassword,
    validateOneTimePassword: handleValidateOneTimePassword
  }
}
