import {propOr} from 'ramda'

export type SettingsType = {
  APP_ENV: string
  APP_VERSION: string
  CLIENT_ANALYTICS_API_KEY: string
  CLIENT_ANALYTICS_CUSTOMER_DATA_API_KEY: string
  CLIENT_ANALYTICS_CUSTOMER_DATA_ENDPOINT: string
  CLIENT_ANALYTICS_ENDPOINT: string
  CLIENT_ANALYTICS_DEFAULT_API_KEY: string
  CLIENT_ANALYTICS_DEFAULT_ENDPOINT: string
  CLIENT_ANALYTICS_RETENTION_API_KEY: string
  CLIENT_ANALYTICS_RETENTION_ENDPOINT: string
  CLIENT_ANALYTICS_SELECT_ENDPOINT: string
  CLIENT_ANALYTICS_SELECT_API_KEY: string
  CLIENT_ANALYTICS_POWER_FEATURES_ENDPOINT: string
  CLIENT_ANALYTICS_POWER_FEATURES_API_KEY: string
  CLIENT_ANALYTICS_CORE_WEB_VITALS_ENDPOINT: string
  CLIENT_ANALYTICS_CORE_WEB_VITALS_API_KEY: string
  CLIENT_ASSETS_ENDPOINT: string
  CLIENT_STATIC_ASSETS_ENDPOINT: string
  CLIENT_DD_CLIENT_TOKEN?: string
  CLIENT_DD_RUM_APP_ID?: string
  CLIENT_DD_SAMPLE_RATE?: number
  CLIENT_DD_REPLAY_SAMPLE_RATE?: number
  CLIENT_GTM_ID: string
  CLIENT_IMAGE_RESIZE_SERVICE_SECRET: string
  CLIENT_IMAGE_RESIZE_SERVICE_URL: string
  CLIENT_MAPBOX_API_KEY: string
  CLIENT_SELECT_MAPBOX_API_KEY: string
  CLIENT_SAPI_PROFILE_KEY: string
  CLIENT_SEARCH_PAGE_SIZE?: number
  CLIENT_USE_MOCKS?: boolean
  CLIENT_RECAPTCHA_KEY: string
  ENABLED_CURRENCIES: string
  PUBLIC_URL?: string
  SENTRY_DSN?: string
  VISIBLE_TOP_OFFERS_LIMIT: number
  WEBSITE_AUTH_AUDIENCE: string
  WEBSITE_AUTH_CLIENT_ID: string
  WEBSITE_AUTH_DOMAIN: string
  WEBSITE_AUTH_TOKEN_NAME_SPACE: string
  WEBSITE_AUTH_ETRIP_AUDIENCE: string
  WEBSITE_AUTH_ETRIP_CLIENT_ID: string
  WEBSITE_AUTH_ETRIP_DOMAIN: string
  WEBSITE_AUTH_ETRIP_TOKEN_NAME_SPACE: string
  WEBSITE_ENABLE_SIMULATOR?: boolean
  NEXT_PUBLIC_OPTIMIZELY_DATAFILE_URL: string
  TRUSTPILOT_API_URL: string
  // These values do not come from the ENV, but are set in modules/sapiSearch/utils
  CURRENCY_EXCHANGE_RATE?: number
  CURRENCY_EXCHANGE_RATE_EUR?: number
  MEMBERSHIP_API_ENDPOINT: string
  MEMBERSHIP_CACHED_API_ENDPOINT: string
  DEAL_BASE_PARTNER_ID: string
  GO_SEEK_PARTNER_ID: string
  YOUR_CAMPUS_PARTNER_ID: string
  WEBSITE_CUSTOMER_IO_TRACKING_SITE_ID: string
  WEBSITE_CUSTOMER_IO_TRACKING_API_KEY: string
  CLIENT_BOVIO_ENDPOINT: string
  CLIENT_BOVIO_PROFILE_DEFAULT: string
  CLIENT_BOVIO_PROFILE_CL_EXPERIMENT: string
  CLIENT_AVUXI_API_KEY: string
  GOOGLE_TRANSLATE_API_KEY: string
}

type SettingsKey = keyof SettingsType

const storeKey = Symbol('store')

export const Settings = function (configs: SettingsType) {
  this[storeKey] = configs
  this.reportedMissingSettings = new Set()

  const get = <Key extends SettingsKey>(
    key: Key,
    defaultValue?: SettingsType[Key] | undefined
  ): SettingsType[Key] => {
    const settings = this[storeKey]
    const value = propOr<SettingsType[Key], SettingsType, SettingsType[Key]>(
      defaultValue,
      key,
      settings
    )

    // Only report a missing setting once & mute in tests
    if (
      typeof value === 'undefined' &&
      !this.reportedMissingSettings.has(key) &&
      process.env.NODE_ENV !== 'test'
    ) {
      this.reportedMissingSettings.add(key)
      console.error('Missing setting:', key)
    }

    return value
  }

  const getAll = (): SettingsType => this[storeKey]

  const set = <Key extends SettingsKey>(
    key: SettingsKey,
    value: SettingsType[Key]
  ): void => {
    this[storeKey][key] = value
  }

  return {get, getAll, set}
}

// @ts-expect-error - the function constructor doesn't seem to be supported in TS?
export default new Settings(window._env || {}) as ReturnType<typeof Settings>
