import React, {useMemo} from 'react'
import {cx} from '@linaria/core'
import {SecondaryOffer} from 'components/Offer/Secondary'
import {
  SplitBookingBundleType,
  SplitBookingOfferInfo
} from 'components/Offer/types'
import {Hotel} from 'modules/sapiSearch/slice'

import {Divider} from '@daedalus/atlas/Divider'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'
import {OfferSecondaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {SplitBookingBundle} from '@findhotel/sapi'

import {SecondaryOfferListItem, SecondaryOfferListWrapper} from './styles'

export type OfferListItemVariant =
  | 'vertical'
  | 'verticalInline' // Added as a part of 70766997-desktop-hotel-card-secondary-offers
  | 'horizontal'

interface MainProps {
  isLoading?: false
  nLoaders?: never
  secondaryOffers: SearchOffer[]
  hotelId: Hotel['objectID']
  splitBookingOffers?: SplitBookingOfferInfo[]
  variant: OfferListItemVariant
  /** Allows to pass a component that will be rendered at the end of the list */
  viewMoreSlot?: React.ReactNode
  anchorPrice: number
  anchorPriceTotal: number
  sourceComponent: string
  showSplitBookingLoader?: boolean
  splitBookingPosition?: number
  splitBookingType?: SplitBookingBundleType
  splitBookingBundle?: SplitBookingBundle
  showPriceIndication?: boolean
}

interface LoadingProps {
  isLoading: true
  nLoaders?: number
  variant: OfferListItemVariant
  secondaryOffers?: never
  hotelId?: never
  splitBookingOffers?: never
  viewMoreSlot?: never
  anchorPrice?: never
  anchorPriceTotal?: never
  sourceComponent?: never
  showSplitBookingLoader?: never
  splitBookingPosition?: number
  splitBookingType?: SplitBookingBundleType
  splitBookingBundle?: never
  showPriceIndication?: never
}

const offerLayoutVariantMap: Record<
  OfferListItemVariant,
  OfferSecondaryLayoutVariant
> = {
  horizontal: 'SecondaryWithoutRoomName',
  vertical: 'SecondaryDefault',
  verticalInline: 'SecondaryInline' // Added as a part of 70766997-desktop-hotel-card-secondary-offers
}

export const SecondaryOfferList: React.FC<MainProps | LoadingProps> = ({
  secondaryOffers,
  isLoading,
  nLoaders = 2,
  hotelId,
  variant = 'vertical',
  viewMoreSlot,
  splitBookingOffers,
  showSplitBookingLoader,
  anchorPrice,
  anchorPriceTotal,
  sourceComponent,
  splitBookingPosition = 2,
  splitBookingType = 'split_flow',
  splitBookingBundle,
  showPriceIndication = false
}) => {
  // Added as a part of 70766997-desktop-hotel-card-secondary-offers
  const dividerSideMargin =
    variant === 'vertical' ? 's400' : isLoading ? 's500' : 's000'

  const secondarySplitBookingOffer = useMemo(() => {
    const showDividerAfter =
      variant !== 'horizontal' && splitBookingPosition === 1
    const showDividerBefore =
      variant !== 'horizontal' && splitBookingPosition >= 2

    if (showSplitBookingLoader)
      return (
        <SecondaryOfferListItem
          key={'SplitBookingOffer'}
          className={cx(
            variant === 'horizontal' && '__horizontal',
            variant === 'verticalInline' && '__verticalInline',
            '__isSplitBookingLoading'
          )}
        >
          {showDividerBefore && <Divider sideMargin={dividerSideMargin} />}
          <SecondaryOffer isLoading variant={offerLayoutVariantMap[variant]} />
          {showDividerAfter && <Divider sideMargin={dividerSideMargin} />}
        </SecondaryOfferListItem>
      )

    return (
      splitBookingOffers?.length > 0 && (
        <SecondaryOfferListItem
          data-id="SecondarySplitBookingOffer"
          key={'SplitBookingOffer'}
          className={cx(
            variant === 'horizontal' && '__horizontal',
            variant === 'verticalInline' && '__verticalInline'
          )}
        >
          {showDividerBefore && <Divider sideMargin={dividerSideMargin} />}
          <SecondaryOffer
            isSplitBooking
            hotelId={hotelId}
            sourceComponent={sourceComponent}
            splitBookingType={splitBookingType}
            variant={offerLayoutVariantMap[variant]}
            splitBookingOffers={splitBookingOffers}
            splitBookingBundle={splitBookingBundle}
          />
          {showDividerAfter && <Divider sideMargin={dividerSideMargin} />}
        </SecondaryOfferListItem>
      )
    )
  }, [
    variant,
    splitBookingPosition,
    showSplitBookingLoader,
    splitBookingOffers,
    hotelId,
    sourceComponent,
    splitBookingType,
    splitBookingBundle,
    dividerSideMargin
  ])

  if (isLoading) {
    return (
      <SecondaryOfferListWrapper
        className={cx(variant === 'horizontal' && '__horizontal')}
        isLoading
      >
        <>
          {Array(nLoaders)
            .fill(0)
            .map((_, index) => (
              <React.Fragment key={['loading', index].join('_')}>
                {index > 0 && variant !== 'horizontal' && (
                  <Divider sideMargin={dividerSideMargin} />
                )}
                <SecondaryOfferListItem
                  className={cx(
                    variant === 'horizontal' && '__horizontal',
                    variant === 'verticalInline' && '__verticalInline'
                  )}
                >
                  <SecondaryOffer
                    isLoading
                    variant={offerLayoutVariantMap[variant]}
                  />
                </SecondaryOfferListItem>
              </React.Fragment>
            ))}
        </>
        <>
          {variant === 'horizontal' && (
            <div>
              <div>
                <SkeletonLoader height={16} width={62} />
              </div>
              <div>
                <SkeletonLoader height={16} width={48} marginTop={4} />
              </div>
            </div>
          )}
        </>
      </SecondaryOfferListWrapper>
    )
  }

  return (
    <SecondaryOfferListWrapper
      className={cx(variant === 'horizontal' && '__horizontal')}
      data-id="SecondaryOfferList"
    >
      {splitBookingPosition == 1 && secondarySplitBookingOffer}
      {secondaryOffers.map((offer, index) => (
        <SecondaryOfferListItem
          key={offer.id}
          className={cx(
            variant === 'horizontal' && '__horizontal',
            variant === 'verticalInline' && '__verticalInline'
          )}
        >
          {variant !== 'horizontal' && index > 0 && (
            <Divider sideMargin={dividerSideMargin} />
          )}
          <SecondaryOffer
            anchorPrice={anchorPrice}
            anchorPriceTotal={anchorPriceTotal}
            variant={offerLayoutVariantMap[variant]}
            offer={offer}
            hotelId={hotelId}
            hasInteraction
            sourceComponent={sourceComponent}
            offerPosition={index + 1} // secondary offers should start from 1 because primary offer is 0
            showPriceIndication={showPriceIndication}
          />
        </SecondaryOfferListItem>
      ))}
      {splitBookingPosition >= 2 && secondarySplitBookingOffer}
      {viewMoreSlot && (
        <SecondaryOfferListItem
          className={cx(
            variant === 'horizontal' && '__horizontal',
            variant === 'verticalInline' && '__verticalInline'
          )}
        >
          {variant !== 'horizontal' && (
            <Divider sideMargin={dividerSideMargin} />
          )}
          {viewMoreSlot}
        </SecondaryOfferListItem>
      )}
    </SecondaryOfferListWrapper>
  )
}
