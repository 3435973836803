/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {SerializedError} from '@reduxjs/toolkit'

/**
 * Custom error that we can narrow to in middleware
 * Prefixes message to the originalError message
 */
export class SearchApiError extends Error {
  originalMessage: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message: string, originalError: Error | any) {
    super(`${message} - ${originalError?.message || originalError}`)
    this.name = 'SearchApiError'
    this.stack = originalError.stack
    this.originalMessage = originalError?.message || originalError
  }
}

const isSearchApiError = (error: unknown): error is SearchApiError =>
  error instanceof SearchApiError

/**
 * Type safe helper to get the message from a query hook `error` response
 */
export const getSearchApiErrorMessage = (
  error: SearchApiError | SerializedError
) => (isSearchApiError(error) ? error.originalMessage : error.message)
