import React, {createContext} from 'react'
import {useSelector} from 'react-redux'
import {getIsHotelDetailsOpenOnLanding} from 'modules/search/selectors'

import {Hotel} from '@findhotel/sapi'

type Props = {
  /* The hotel id */
  hotelId: string | Hotel['objectID']
  /* Whether the hotel is an anchor hotel */
  isAnchor?: boolean
  /* Whether results list is still loading */
  isDummyLoadingCard?: boolean
  // Added as part of 377fd843-desktop-ap--v2
  isHotelDetailsOverlay?: boolean
  children: React.ReactNode
}

type InitialValues = {
  hotelId: string
  isAnchor?: boolean
  isDummyLoadingCard?: boolean
  hotelDetailsIsOpenOnLanding?: boolean
  isHotelDetailsOverlay?: boolean
}

const initialValues: InitialValues = {
  hotelId: undefined,
  isAnchor: false,
  isDummyLoadingCard: false,
  hotelDetailsIsOpenOnLanding: false,
  isHotelDetailsOverlay: false
}

export const HotelCardContext = createContext(initialValues)

/**
 * This context provider should only be used to access pieces of state that are not available directly from the store
 */
export const HotelCardProvider = ({
  children,
  hotelId,
  isAnchor = false,
  isDummyLoadingCard = false,
  isHotelDetailsOverlay = false
}: Props) => {
  const hotelDetailsIsOpenOnLanding = useSelector(
    getIsHotelDetailsOpenOnLanding
  )

  return (
    <HotelCardContext.Provider
      value={{
        hotelId,
        isAnchor,
        isDummyLoadingCard,
        hotelDetailsIsOpenOnLanding,
        isHotelDetailsOverlay
      }}
    >
      {children}
    </HotelCardContext.Provider>
  )
}
