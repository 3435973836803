import {RoomPrice} from '@findhotel/sapi'

import {Price as BookingPrice} from '../../api-types/bovio/response/booking'
import {
  PayAtHotel,
  Price as OfferCheckPrice
} from '../../api-types/bovio/response/offer_check'
import {Chargeable, OfferPrice} from '../../offer/types/offer'
import PriceTypes from '../../offer/types/PriceTypes'
import {toFloatWithTwoDecimals} from '../../utils/number'

/**
 * Union type representing different price formats from various sources
 */
export type Price = OfferPrice | BookingPrice | OfferCheckPrice | RoomPrice

/**
 * Enum containing short currency symbols for common currencies
 */
export enum ShortCurrencySymbols {
  EUR = '€',
  GBP = '£',
  ILS = '₪',
  INR = '₹',
  JPY = '¥',
  KRW = '₩',
  PHP = '₱',
  USD = '$'
}

/**
 * Constant representing resort fee type
 */
export const HOTEL_FEES_RESORT_FEE = 'resort_fee'
/**
 * Constant representing tax fee type
 */
export const HOTEL_FEES_TAX = 'tax'
/**
 * Constant representing other fee type
 */
export const HOTEL_FEES_OTHER = 'other'

/**
 * Union type for different hotel fee types
 */
type HotelFee =
  | typeof HOTEL_FEES_RESORT_FEE
  | typeof HOTEL_FEES_TAX
  | typeof HOTEL_FEES_OTHER

/**
 * Interface for objects that may contain PayAtHotel information
 */
interface HasPayAtHotel {
  payAtHotel?: PayAtHotel
}

/**
 * Type guard to check if a price object contains PayAtHotel information
 * @param price - The price object to check
 * @returns True if the price object has PayAtHotel information
 */
export const hasPayAtHotel = (
  price: Price | RoomPrice['chargeable'] | undefined
): price is Price & HasPayAtHotel => {
  return Boolean(price && 'payAtHotel' in price)
}

/**
 * Type guard to check if a chargeable object has a currency property
 * @param chargeable - The chargeable object to check
 * @returns True if the chargeable object has a currency property
 */
const hasCurrency = (
  chargeable: Chargeable | RoomPrice['chargeable']
): chargeable is Chargeable & {currency: string} => {
  return 'currency' in chargeable
}

/**
 * Type guard to check if a chargeable object has a currencyCode property
 * @param chargeable - The chargeable object to check
 * @returns True if the chargeable object has a currencyCode property
 */
const hasCurrencyCode = (
  chargeable: Chargeable | RoomPrice['chargeable']
): chargeable is Chargeable & {currencyCode: string} => {
  return 'currencyCode' in chargeable
}

/**
 * Since BoVio and SAPI return the currency in different ways, this helper conditionally extracts the currency code from the price object.
 * @param price - The price object.
 * @returns The currency code as a string, or undefined if not available.
 */
export const getPriceCurrencyCode = (price?: Price): string | undefined => {
  if (price?.chargeable) {
    const {chargeable} = price

    if (hasCurrency(chargeable)) {
      return chargeable.currency
    }
    if (hasCurrencyCode(chargeable)) {
      return chargeable.currencyCode
    }
  }

  return price?.currencyCode
}

/**
 * Finds and returns a price object of a specific type from an array of prices
 * @param type - The price type to search for
 * @param prices - Array of price objects to search through
 * @returns The matching price object or undefined if not found
 */
export const getPriceByType = <T extends Price>(
  type: string,
  prices: T[]
): T | undefined => {
  return prices.find(price => price.type === type)
}

/**
 * Check whether is there a Currency Conversion
 * A currency conversion is detected when a set of prices contains a DISPLAY_CURRENCY or USER_CURRENCY price type
 * @param prices - The list of offer/booking prices
 * @returns True if there is a currency conversion
 */
export const isThereACurrencyConversion = (prices: Price[]) => {
  return Boolean(
    getPriceByType(PriceTypes.DISPLAY_PRICE, prices) ||
      getPriceByType(PriceTypes.USER_CURRENCY, prices)
  )
}

/**
 * Returns the price the user sees. Not necessarily the price in which
 * he/she was or will be charged
 * @param prices - The list of offer/booking prices
 * @returns The display price object or undefined
 */
export const getDisplayPrice = <T extends Price>(
  prices: T[]
): T | undefined => {
  if (isThereACurrencyConversion(prices)) {
    return (
      getPriceByType(PriceTypes.DISPLAY_PRICE, prices) ||
      getPriceByType(PriceTypes.USER_CURRENCY, prices)
    )
  }

  return getChargeableCurrencyPrice(prices)
}

/**
 * Returns the total amount of hotel fees
 * @param price - The price object
 * @returns The total hotel fees as a number
 */
export const getTotalHotelFees = (price?: Price) => {
  return toFloatWithTwoDecimals(Number(price?.hotelFees?.total)) || 0
}

/**
 * Returns hotel fees in the property currency
 * @param prices - The list of offer/booking prices
 * @returns The hotel fees price object or undefined
 */
export const getPropertyCurrencyHotelFees = (
  prices: Price[]
): Price | undefined => {
  return (
    getPriceByType(PriceTypes.HOTEL_PRICE, prices) ||
    getPriceByType(PriceTypes.HOTEL_CURRENCY, prices)
  )
}

/**
 * Returns the CHARGEABLE_PRICE if available, otherwise falls back to CHARGEABLE_CURRENCY.
 * @param prices - List of offer/booking prices
 * @returns The chargeable price object or undefined
 */
export const getChargeableCurrencyPrice = <T extends Price>(
  prices: T[]
): T | undefined => {
  return (
    getPriceByType(PriceTypes.CHARGEABLE_PRICE, prices) ||
    getPriceByType(PriceTypes.CHARGEABLE_CURRENCY, prices)
  )
}

/**
 * Returns the total price including hotel fees regardless of tax display logic
 * @param price - The price object
 * @returns The total price as a number
 */
export const getTotalPrice = (price?: Price | RoomPrice) => {
  const total = Number(price?.chargeable.base) + Number(price?.chargeable.taxes)
  const hotelFees = Number(price?.hotelFees?.total) || 0
  return toFloatWithTwoDecimals(total + hotelFees) || 0
}

/**
 * Returns the nightly price from any total
 * @param price - The total price
 * @param numberOfNights - Number of nights to divide by
 * @param numberOfRooms - Number of rooms to divide by (defaults to 1)
 * @returns The nightly price or null if invalid input
 */
export const getNightlyPrice = (
  price: number | null | undefined | string,
  numberOfNights: number,
  numberOfRooms = 1
) => {
  if (Number.isNaN(price)) return null

  return toFloatWithTwoDecimals(Number(price) / numberOfNights / numberOfRooms)
}

/**
 * Converts price types from SAPI/bookings format to offer-check format
 * This converts the price types we receive in the sapi.rooms() and /bookings request to the ones we receive in /offer-check
 * The objective of this function is to provide backwards compatibility to the old price types
 * @internal
 * @param price - The price object to convert
 * @returns The converted price object
 */
export const convertPricesToNewFormat = <T extends Price>(price: T): T => {
  const conversionList = [
    {from: PriceTypes.CHARGEABLE_CURRENCY, to: PriceTypes.CHARGEABLE_PRICE},
    {
      from: PriceTypes.USER_CURRENCY,
      to: PriceTypes.DISPLAY_PRICE
    }
  ]

  const copyPrice = {...price}
  for (const mappingItem of conversionList) {
    if (copyPrice.type === mappingItem.from) {
      copyPrice.type = mappingItem.to
    }
  }
  return copyPrice
}

/**
 * Returns the total price excluding hotel fees regardless of tax display logic, unless it is a pay at property deal, then it returns 0
 * @param price - The price object
 * @param isPayAtProperty - Whether this is a pay at property deal
 * @returns The total upfront price as a number
 */
export const getTotalUpFrontPrice = (
  price?: Price,
  isPayAtProperty?: boolean
) => {
  if (isPayAtProperty || !price) return 0
  return toFloatWithTwoDecimals(
    Number(price.chargeable.base) + Number(price.chargeable.taxes)
  )
}

/**
 * Returns the total hotel fees, unless it is a pay at property deal, then it returns the total price
 * @param price - The price object
 * @param isPayAtProperty - Whether this is a pay at property deal
 * @returns The total amount to pay at property as a number
 */
export const getTotalAtProperty = (
  price?: Price,
  isPayAtProperty?: boolean
) => {
  if (isPayAtProperty) return getTotalPrice(price)
  return toFloatWithTwoDecimals(price?.hotelFees?.total || 0)
}

/**
 * Returns the total price to be paid upfront in cents
 * @param price - The price object
 * @param canPayLater - Whether payment can be made later at property
 * @returns The total upfront price in cents as a number
 */
export const getTotalUpFrontPriceAsCents = (
  price: Price | undefined,
  canPayLater: boolean
) => {
  return Math.round(Number(getTotalUpFrontPrice(price, canPayLater)) * 100)
}

/**
 * Helper function to get a specific type of hotel fee from a price object
 * @param type - The type of hotel fee to get
 * @param price - The price object
 * @returns The fee amount as a number
 */
const getHotelFeeByType = (type: HotelFee, price?: Price) =>
  Number(
    price?.hotelFees?.breakdown?.find(fee => fee.type === type)?.total || 0
  )

/**
 * Gets the resort fee from a price object
 * @param price - The price object
 * @returns The resort fee amount
 */
export const getHotelFeesResortFee = (price?: Price) =>
  getHotelFeeByType(HOTEL_FEES_RESORT_FEE, price)

/**
 * Gets the tax fee from a price object
 * @param price - The price object
 * @returns The tax fee amount
 */
export const getHotelFeesTax = (price?: Price) =>
  getHotelFeeByType(HOTEL_FEES_TAX, price)

/**
 * Gets other fees from a price object
 * @param price - The price object
 * @returns The other fees amount
 */
export const getHotelFeesOther = (price?: Price) =>
  getHotelFeeByType(HOTEL_FEES_OTHER, price)

/**
 * Returns the different pricing and currency information for an offer
 * @param prices - List of prices for an offer
 * @param isPayAtProperty - Whether this is a pay at property deal
 * @returns Object containing detailed price information
 */
export const getPriceDetails = (prices: Price[], isPayAtProperty: boolean) => {
  const displayPrice = getDisplayPrice(prices)

  if (!displayPrice)
    return {
      totalDisplayPrice: 0,
      totalUpFrontDisplayPrice: 0,
      totalAtPropertyDisplayPrice: 0,
      totalUpFrontChargeablePrice: 0,
      totalAtPropertyChargeablePrice: 0,
      displayCurrencyCode: undefined,
      chargeableCurrencyCode: undefined,
      taxesDisplayPrice: 0,
      hotelFeesResortFee: 0,
      hotelFeesTax: 0,
      hotelFeesOther: 0,
      hotelFeesTotal: 0
    }

  const {taxes: taxesDisplayPrice} = displayPrice.chargeable

  // SAPI returns currency as currencyCode and BoVio returns as `currency`
  const displayCurrencyCode = getPriceCurrencyCode(displayPrice)

  const totalDisplayPrice = getTotalPrice(displayPrice)
  const hotelFeesResortFee = getHotelFeesResortFee(displayPrice) ?? 0
  const hotelFeesTax = getHotelFeesTax(displayPrice) ?? 0
  const hotelFeesOther = getHotelFeesOther(displayPrice) ?? 0

  const chargeablePrice = getChargeableCurrencyPrice(prices)
  const chargeableCurrencyCode = getPriceCurrencyCode(chargeablePrice)

  const payAtHotel = hasPayAtHotel(chargeablePrice)
    ? chargeablePrice.payAtHotel
    : undefined

  const totalAtPropertyDisplayPrice = getTotalAtProperty(
    displayPrice,
    isPayAtProperty
  )
  const totalUpFrontDisplayPrice = getTotalUpFrontPrice(
    displayPrice,
    isPayAtProperty
  )

  const totalUpFrontChargeablePrice = getTotalUpFrontPrice(
    chargeablePrice,
    isPayAtProperty
  )
  const propertyCurrencyHotelFees = getPropertyCurrencyHotelFees(prices)
  const hotelFeesInPropertyCurrency = getTotalHotelFees(
    propertyCurrencyHotelFees
  )

  const totalAtPropertyChargeablePrice = payAtHotel
    ? payAtHotel.total
    : getTotalAtProperty(chargeablePrice, isPayAtProperty)

  return {
    totalDisplayPrice: totalDisplayPrice ?? 0,
    totalUpFrontDisplayPrice: totalUpFrontDisplayPrice ?? 0,
    totalAtPropertyDisplayPrice: totalAtPropertyDisplayPrice ?? 0,
    totalUpFrontChargeablePrice: totalUpFrontChargeablePrice ?? 0,
    totalAtPropertyChargeablePrice: totalAtPropertyChargeablePrice ?? 0,
    displayCurrencyCode,
    chargeableCurrencyCode,
    taxesDisplayPrice: toFloatWithTwoDecimals(taxesDisplayPrice) ?? 0,
    hotelFeesResortFee,
    hotelFeesTax,
    hotelFeesOther,
    hotelFeesTotal: hotelFeesResortFee + hotelFeesTax + hotelFeesOther,
    hotelFeesInPropertyCurrency
  }
}

/**
 * Calculates the nightly price, taking into account the number of rooms and whether
 * the search is for multi-room bundles.
 * @param price - The nightly price
 * @param isMultiRoomBundlesSearch - Indicates whether the search includes multi-room bundles
 * @param numberOfRooms - The total number of rooms in the search. Defaults to 1
 * @returns The calculated nightly price for all rooms or null if invalid input
 */
export const getNightlyPriceForAllRooms = (
  price: number | null | undefined | string,
  isMultiRoomBundlesSearch: boolean,
  numberOfRooms = 1
) => {
  if (!price || Number.isNaN(price)) return null
  if (!isMultiRoomBundlesSearch && numberOfRooms > 1)
    return Number(price) * numberOfRooms
  return Number(price)
}
