/**
 * Helpers for the split booking bottom sheet
 */

import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {
  SplitBookingBundleType,
  SplitBookingOfferInfo
} from 'components/Offer/types'
import {getCurrencyCode} from 'modules/meta/selectors'
import {
  getCheapestPriceRateBreakdown,
  getShowTotalPrices,
  getSplitBookingPriceSummary
} from 'modules/sapiSearch/selectors'
import {getNumberOfNights} from 'utils/offers'

import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {ShortCurrencySymbols} from '@daedalus/core/src/price/business/price'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {
  applyRoundingStrategy,
  getRoundingStrategy
} from '@daedalus/core/src/utils/number'
import {DeviceCategory} from '@daedalus/core/src/utils/userAgent/types'
import {SplitBookingOffer} from '@findhotel/sapi'

import {messages} from './messages'

/**
 *
 * @param offers - sapi split booking offers
 * @returns list of search split booking offer
 */
export const sapiSBOtoSearchSBO = (
  offers: SplitBookingOffer[]
): SplitBookingOfferInfo[] => {
  if (!offers) return []
  return offers.map(offer => ({
    offer: offer.offer as SearchOffer,
    checkIn: offer.checkIn,
    checkOut: offer.checkOut,
    nights: getNumberOfNights(offer.checkIn, offer.checkOut)
  }))
}

/**
 * Helper hook to return the formatted date string. Example `15 Jun`
 * @returns - The `formatDate` function that accept Date string in the format `YYYY-MM-DD` and returns string
 */
export const useFormattedDate = () => {
  const {formatDate: format} = useIntl()

  return {
    formatDate: (dateString: string) =>
      format(dateStringToMiddayDate(dateString), {
        day: '2-digit',
        month: 'short'
      })
  }
}

/**
 * Helper function to return the savings value for the split booking
 * @returns The savings value
 */
export const getSplitBookingSavingsValue = (
  cheapestNonSplitBookingRate: number,
  splitBookingRate: number
) => {
  const savings = applyRoundingStrategy(
    getRoundingStrategy(),
    cheapestNonSplitBookingRate - splitBookingRate
  )

  return savings > 0 ? savings : null
}

interface SplitBookingSavingsMessageProps {
  hotelId: string
}
/**
 * Helper hook to return the savings message for split booking offers
 */
export const useSplitBookingSavingsMessage = ({
  hotelId
}: SplitBookingSavingsMessageProps) => {
  const currencyCode = useSelector(getCurrencyCode)
  const sbPriceSummary = useSelector(state =>
    getSplitBookingPriceSummary(state, hotelId)
  )
  const cheapestRate = useSelector(state =>
    getCheapestPriceRateBreakdown(state, hotelId)
  )
  const showTotalPrices = useSelector(getShowTotalPrices)
  const {formatMessage, formatNumber} = useIntl()

  const currencySymbol =
    ShortCurrencySymbols[currencyCode as keyof typeof ShortCurrencySymbols] ||
    null

  const savingsValue = getSplitBookingSavingsValue(
    showTotalPrices ? cheapestRate?.totalRate : cheapestRate?.nightlyRate,
    showTotalPrices ? sbPriceSummary?.sum : sbPriceSummary?.average
  )

  const subTitleMessage = savingsValue
    ? messages.secondaryOfferSubtitleWithSavings
    : messages.secondaryOfferSubtitle

  return formatMessage(subTitleMessage, {
    savingValue: currencySymbol
      ? `${currencySymbol}${savingsValue}`
      : formatNumber(savingsValue, {
          style: 'currency',
          currency: currencyCode,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
  })
}

interface GetSecondaryOffersArgs {
  topOffers: SearchOffer[]
  hasSplitBookingOffer: boolean
  splitBookingType: SplitBookingBundleType
  splitBookingPosition: number
  offerPositionToRemove: number | null
  showSplitBookingLoader: boolean
  deviceLayout: DeviceCategory
}
export const getSecondaryOffers = ({
  topOffers,
  hasSplitBookingOffer,
  splitBookingType,
  splitBookingPosition,
  offerPositionToRemove,
  showSplitBookingLoader,
  deviceLayout
}: GetSecondaryOffersArgs) => {
  const hasSingleSBOffer =
    hasSplitBookingOffer && splitBookingType === 'single_flow'
  if (hasSingleSBOffer) {
    return topOffers?.filter((_, i) => {
      // there's less than 3 offers, so we don't need to remove any
      if (offerPositionToRemove === null) return true

      // when the sb offer is the top offer, keep showing the top offer and remove the offer being replaced
      // because the top offer can be shown as secondary offers
      if (splitBookingPosition === 0) return i !== offerPositionToRemove

      // when the sb offer is not the top offer, remove the top offer and the offer being replaced
      // because the top offer is being shown as the primary offer
      return i !== 0 && i !== offerPositionToRemove
    })
  }

  // For split_flow split bookings, we can show 4 offers on mobile but only 3 on desktop
  if (deviceLayout !== 'desktop') {
    return topOffers?.slice(1, 3)
  }

  const maxOffers = showSplitBookingLoader || hasSplitBookingOffer ? 2 : 3
  return topOffers?.slice(1, maxOffers)
}
