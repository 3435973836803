import {SearchType} from 'modules/sapiSearch/utils'
import {defaultTo} from 'ramda'
import {createSelector} from 'reselect'

import {SuggestionSectionDataType} from '@daedalus/core/src/autocomplete/types'
import {numberOfGuests} from '@daedalus/core/src/room/business/roomConfiguration'

import {
  OpenState,
  SearchBoxAncestor,
  SearchBoxStateType,
  SearchTrigger
} from './types'

export type StateType = {
  searchBox: SearchBoxStateType
}

export type DatesType = {
  checkIn?: string
  checkOut?: string
}

export const getSearchBoxValues = (state: StateType) =>
  state.searchBox.values || {}

export const getIsDatesVisible = ({searchBox}: StateType): boolean =>
  searchBox?.isDatesVisible || searchBox?.isDatesVisible === null

export const getOpenDatePickerType = createSelector(
  [(state: StateType) => state.searchBox.openDatePickerType],
  openDatePickerType => openDatePickerType
)

/**
 * Gets the current search destination
 */
export const getSearchBoxDestinationDisplayValue = (state: StateType) =>
  state.searchBox.searchBoxDestinationDisplayValue

const getSearchBoxOpenState = (state: StateType): OpenState =>
  defaultTo(OpenState.Closed, state.searchBox.openState)

export const getIsSearchBoxOpenByDefault = createSelector(
  [getSearchBoxOpenState],
  (openState): boolean => OpenState.OpenByDefault === openState
)

export const getIsSearchBoxOpen = createSelector(
  [getSearchBoxOpenState],
  (openState): boolean =>
    [OpenState.OpenByDefault, OpenState.OpenByUser].includes(openState)
)

export const getSearchBoxAncestor = (
  state: StateType
): SearchBoxAncestor | null | undefined => state.searchBox.ancestor

export const getIsSearchBoxActive = (state: StateType): boolean =>
  state.searchBox.isSearchBoxActive

export const getSuggestions = (state: StateType): SuggestionSectionDataType[] =>
  state.searchBox.suggestions

export const getSuggestionSearchValue = (state: StateType): string =>
  state.searchBox.suggestionsSearchValue

export const getSearchBoxSearchTrigger = (
  state: StateType
): SearchTrigger | null | undefined => state.searchBox.searchTrigger

/**
 * If MRB is enabled and more than 2 guests, don't show the label
 * since we don't know what the most likely options is.
 * If not MRB always show the label.
 */
export const getShowRoomsLabel = createSelector(
  [getSearchBoxValues, getSearchBoxValues],
  ({rooms}, {optimizeRooms}): boolean => {
    return Boolean(optimizeRooms ? numberOfGuests(rooms) < 3 : true)
  }
)

export const getSearchBoxSearchType = createSelector(
  [getSearchBoxValues],
  ({
    hotelId,
    placeId,
    userLocationSearch,
    address,
    boundingBox
  }): SearchType => {
    if (hotelId) {
      return SearchType.Hotel
    }
    if (placeId) {
      return SearchType.Place
    }
    if (userLocationSearch) {
      return SearchType.Location
    }
    if (address) {
      return SearchType.Address
    }
    if (boundingBox) {
      return SearchType.Area
    }
    return SearchType.Unknown
  }
)
