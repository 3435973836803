import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'

import {PriceWatchCTAParams} from '../types'
import {useHandleEnablePriceWatchOnNativeApp} from './useHandleEnablePriceWatchOnNativeApp'
import {usePriceWatch} from './usePriceWatch'
import {usePriceWatchAppPushNotifications} from './usePriceWatchAppPushNotifications'
import {usePriceWatchToggleToast} from './usePriceWatchToggleToast'

export const usePriceWatchToggleWebViewLogic = (props: PriceWatchCTAParams) => {
  const {hasPushNotificationEnabled, requestPushNotificationPermission} =
    usePriceWatchAppPushNotifications()

  const {
    hotel,
    checkIn,
    checkOut,
    isAuthenticated,
    getSearchContext,
    hotelContext,
    alertsManagementUrl,
    componentName
  } = props

  const dispatch = useDispatch()
  const searchContext = useSelector(getSearchContext)

  const hotelId = hotelContext?.hotelId
  const queryParams = new URLSearchParams(location.search)
  const history = useHistory()

  const disablePriceWatchViaURL = () => {
    const unsubscribePriceWatchParam = queryParams.get('unsubscribePriceWatch')
    const hotelIdURL = queryParams.get('hotelId')

    const shouldUnsubscribePriceWatch =
      unsubscribePriceWatchParam && hotelId === hotelIdURL

    if (shouldUnsubscribePriceWatch) {
      cancelPriceWatch()
      setShouldShowPriceWatchDisabledMessage(true)
      queryParams.delete('unsubscribePriceWatch')
      history.replace({search: queryParams.toString()})
    }
  }

  useEffect(() => {
    disablePriceWatchViaURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const onPriceWatchInitialized = (component?: string) => {
    setShouldShowPriceWatchEnabledMessage(true)
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.PriceAlertEnable,
        action: Action.Succeeded,
        team: Team.Retention,
        analyticsContext: {
          [AnalyticsContext.SearchContext]: searchContext,
          [AnalyticsContext.HotelContext]: hotelContext
        },
        payload: {
          isUserSignedIn: isAuthenticated,
          isPushEnabled: hasPushNotificationEnabled
        },
        component: component || componentName
      })
    )
  }

  const onToastClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceAlertToast,
        action: Action.Clicked,
        team: Team.Retention,
        analyticsContext: {
          [AnalyticsContext.SearchContext]: searchContext,
          [AnalyticsContext.HotelContext]: hotelContext
        }
      })
    )

    alertsManagementUrl && history.push(alertsManagementUrl)
  }

  const params = {
    ...props,
    onPriceWatchInitialized
  }

  const {isPriceWatchOn, cancelPriceWatch, initPriceWatch} =
    usePriceWatch(params)

  // Watch for "USER_ENABLED_PRICE_WATCH_ON_NATIVE" messages coming from the Native App
  useHandleEnablePriceWatchOnNativeApp({initPriceWatch, hotelId})

  const {
    setShouldShowPriceWatchEnabledMessage,
    setShouldShowPriceWatchDisabledMessage
  } = usePriceWatchToggleToast({
    isPriceWatchOn,
    hotel,
    checkIn,
    checkOut,
    isEnabledToTriggerToggle: Boolean(hasPushNotificationEnabled),
    shouldShowNavigationIcon: Boolean(alertsManagementUrl),
    onToastClick
  })

  const handleClick = (event: React.SyntheticEvent<Element, Event>) => {
    event?.preventDefault()
    event?.stopPropagation()

    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.PriceAlertToggle,
        action: Action.Clicked,
        team: Team.Retention,
        analyticsContext: {
          [AnalyticsContext.SearchContext]: searchContext,
          [AnalyticsContext.HotelContext]: hotelContext
        },
        payload: {
          isUserSignedIn: isAuthenticated,
          from: isPriceWatchOn ? 'enabled' : 'disabled',
          to: !isPriceWatchOn ? 'enabled' : 'disabled'
        },
        component: componentName
      })
    )

    if (isPriceWatchOn) {
      cancelPriceWatch()
      setShouldShowPriceWatchDisabledMessage(true)
      return
    }

    if (hasPushNotificationEnabled) {
      initPriceWatch()
      return
    }

    !hasPushNotificationEnabled && requestPushNotificationPermission(hotelId)
  }

  return {handleClick, isToggleOn: isPriceWatchOn}
}
