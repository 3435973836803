// eslint-disable no-negated-condition
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {syncStoreWithUrl, SyncStoreWithUrlPayload} from 'modules/search/slice'
import {toggle} from 'opticks'
import {mergeDeepRight} from 'ramda'

import {AvailabilityEntity} from '@daedalus/core/src/availability/types'
import {
  type OfferSearchReceivedPayload,
  offersSearchReceived
} from '@daedalus/core/src/sapi/services/searchApi/action'
import {Anchor, SearchParameters} from '@daedalus/core/src/sapi/types'
import {transformFacetsToArray} from '@daedalus/core/src/sapi/utils'
// SAPI_TODO: better export
import {
  Hotel as SapiHotel,
  HotelAnchor as SapiHotelAnchor,
  HotelOfferEntity as SapiHotelOfferEntity,
  HotelSearchParameters as SapiHotelSearchParameters,
  LocationSearchParameters as SapiLocationSearchParameters,
  PlaceAnchor as SapiPlaceAnchor,
  PlaceSearchParameters as SapiPlaceSearchParameters,
  QuerySearchParameters as SapiQuerySearchParameters
} from '@findhotel/sapi/dist/types/packages/core/src/types'

import {OldHotelOfferEntity} from './offersMapping'
import {getHotelIdsWithOffers, urlParamsToSapiSearchParameters} from './utils'

import type {FacetsAsArray} from '@daedalus/core/src/sapi/types'

export type Hotel = SapiHotel
export type {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
export type Facets = Record<string, Record<string, number>>
export type HotelOfferEntity = OldHotelOfferEntity
export type HotelAnchor = SapiHotelAnchor
export type PlaceAnchor = SapiPlaceAnchor
export type HotelSearchParameters = SapiHotelSearchParameters
export type PlaceSearchParameters = SapiPlaceSearchParameters
export type LocationSearchParameters = SapiLocationSearchParameters
export type QuerySearchParameters = SapiQuerySearchParameters

type HotelId = Hotel['objectID']

export type SearchStartedPayload = Pick<State, 'searchId' | 'searchParameters'>

export type ParametersReceivedPayload = Pick<State, 'searchParameters'>

export interface AnchorReceivedPayload
  extends Pick<State, 'anchor' | 'anchorHotelId' | 'hotelEntities'> {
  anchorType: string
  anchorHotel?: Hotel
}

export interface HotelsReceivedPayload
  extends Pick<
    State,
    'hotelEntities' | 'hotelsHaveStaticPosition' | 'resultsCountTotal'
  > {
  hotelIds: HotelId[]
  resultsCount: number
  offset: number
  facets: Facets
}

interface OffersReceivedPayload {
  status?: {
    anchorComplete: boolean
  }
  hotelOfferEntities: State['hotelOfferEntities']
  anchorHotelOffer?: SapiHotelOfferEntity
  hotelIds: HotelId[]
}

interface SearchCompletedPayload
  extends Pick<State, 'hotelsHaveStaticPosition' | 'dealScores'> {
  hotelIds: HotelId[]
}

export enum ModuleState {
  Idle,
  HotelsPending,
  OffersPending,
  SearchCompleted,
  MoreHotelsPending
}

export enum AnchorType {
  hotel,
  place
}

export interface State {
  anchorHotelId?: string
  anchorType?: AnchorType
  anchor?: Anchor
  facets?: FacetsAsArray
  moduleState: ModuleState
  searchParameters?: SearchParameters
  hasMoreResults?: boolean
  hotelsHaveStaticPosition?: boolean
  showUnavailable?: boolean
  hotelEntities?: Record<HotelId, Hotel>
  hotelOfferEntities?: Record<HotelId, HotelOfferEntity>
  dealScores?: Record<HotelId, number>
  hotelIds?: HotelId[][]
  hotelIdsRaw?: HotelId[][]
  searchId?: string
  sapiPage: number
  page: number
  resultsCountTotal: number
  requestedResultsCount: number
  requestedOffset: number
  countNoReturnedResults: number
  hotelAvailabilityPrices: AvailabilityEntity
  hotelAvailabilityPendingRequests: number
  anchorComplete: boolean
  optimizeRooms?: boolean
}

export const initialState: State = {
  anchorHotelId: undefined,
  anchorType: undefined,
  anchor: undefined,
  facets: undefined,
  moduleState: ModuleState.Idle,
  searchParameters: undefined,
  hasMoreResults: undefined,
  hotelsHaveStaticPosition: undefined,
  showUnavailable: undefined,
  hotelEntities: undefined,
  hotelOfferEntities: undefined,
  hotelIds: undefined,
  hotelIdsRaw: undefined,
  searchId: undefined,
  sapiPage: 0,
  page: 0,
  resultsCountTotal: 0,
  requestedResultsCount: 0,
  requestedOffset: 0,
  countNoReturnedResults: 0,
  hotelAvailabilityPrices: {},
  hotelAvailabilityPendingRequests: 0,
  anchorComplete: false
}

const {actions, reducer} = createSlice({
  name: 'sapiSearch',
  initialState,
  reducers: {
    newSearch: state => {
      delete state.dealScores

      // Reset properties that are set in initialState
      for (const key of Object.keys(initialState)) {
        // We don't want to reset searchParameters as they are set from the URL
        if (key !== 'searchParameters') {
          state[key] = initialState[key]
        }
      }
    },
    searchStarted: (state, {payload}: PayloadAction<SearchStartedPayload>) => {
      state.searchParameters = payload.searchParameters
      state.searchId = payload.searchId
      state.moduleState = ModuleState.HotelsPending
    },
    parametersReceived: (
      state,
      {payload}: PayloadAction<ParametersReceivedPayload>
    ) => {
      state.searchParameters = payload.searchParameters
    },
    anchorReceived: (
      state,
      {payload}: PayloadAction<AnchorReceivedPayload>
    ) => {
      const {anchor, hotelEntities, anchorHotelId, anchorType} = payload
      state.anchorType =
        anchorType === 'hotel' ? AnchorType.hotel : AnchorType.place
      state.anchor = state.anchor ?? anchor
      state.anchorHotelId = anchorHotelId
      state.hotelEntities = hotelEntities
    },
    hotelsReceived: (
      state,
      {payload}: PayloadAction<HotelsReceivedPayload>
    ) => {
      const {
        facets,
        hotelEntities,
        hotelIds,
        hotelsHaveStaticPosition,
        resultsCountTotal,
        resultsCount,
        offset
      } = payload
      let hasMoreResults = false
      let newHotels = state.hotelEntities

      const newHotelIds = [...(state.hotelIds ?? [])]
      const newHotelIdsRaw = [...(state.hotelIdsRaw ?? [])]

      if (hotelIds?.length) {
        // SAPI_TODO: use selector instead
        hasMoreResults = resultsCount + offset <= resultsCountTotal

        newHotels = {
          ...state.hotelEntities,
          ...hotelEntities
        }

        newHotelIds.push(hotelIds)
        newHotelIdsRaw.push(hotelIds)
      }

      state.facets = transformFacetsToArray(facets)
      state.hotelEntities = newHotels
      state.hotelIds = newHotelIds
      state.hotelIdsRaw = newHotelIdsRaw
      state.resultsCountTotal = resultsCountTotal
      state.requestedResultsCount = resultsCount
      state.requestedOffset = offset
      state.hotelsHaveStaticPosition = hotelsHaveStaticPosition
      state.hasMoreResults = hasMoreResults
    },
    offersRequested: state => {
      state.moduleState = ModuleState.OffersPending
    },
    offersReceived: (
      state,
      {payload}: PayloadAction<OffersReceivedPayload>
    ) => {
      const {hotelOfferEntities, hotelIds, status} = payload

      const newHotelIds = [...(state.hotelIds ?? [])]
      const newHotelIdsRaw = [...(state.hotelIdsRaw ?? [])]

      if (hotelIds?.length) {
        newHotelIds[state.sapiPage] = hotelIds
        newHotelIdsRaw[state.sapiPage] = hotelIds
      }

      const newHotelOfferEntities = {
        ...state.hotelOfferEntities,
        ...hotelOfferEntities
      }

      if (status?.anchorComplete) state.anchorComplete = true // Only set when the optional key is there to prevent resetting
      state.hotelIds = newHotelIds
      state.hotelIdsRaw = newHotelIdsRaw
      state.hotelOfferEntities = newHotelOfferEntities
    },
    // We return to searchCompleted after the first and each subsequent page
    searchCompleted: (
      state,
      {payload}: PayloadAction<SearchCompletedPayload>
    ) => {
      const {hotelIds, hotelsHaveStaticPosition, dealScores} = payload
      const newHotelIds = [...(state.hotelIds ?? [])]

      const hotelIdsForHotelsWithOffers = getHotelIdsWithOffers(
        hotelIds,
        state.hotelOfferEntities
      )

      // Added as part of feature-static-srp flag
      newHotelIds[state.sapiPage] = toggle(
        'feature-static-srp',
        hotelIdsForHotelsWithOffers,
        hotelIds
      )

      state.dealScores = {
        ...state.dealScores,
        ...dealScores
      }

      state.countNoReturnedResults = hotelIdsForHotelsWithOffers.length
        ? 0
        : state.countNoReturnedResults + 1
      state.sapiPage += 1
      state.moduleState = ModuleState.SearchCompleted
      state.hotelIds = newHotelIds
      state.hotelsHaveStaticPosition = hotelsHaveStaticPosition
      state.anchorComplete = true
    },
    moreHotelsRequested: state => {
      state.moduleState = ModuleState.MoreHotelsPending
    },
    setNextPage: state => {
      state.page += 1
    },
    hotelsAvailabilityRequested: state => {
      state.hotelAvailabilityPendingRequests += 1
    },
    hotelsAvailabilityReceived: (state, {payload}) => {
      state.hotelAvailabilityPrices = mergeDeepRight(
        state.hotelAvailabilityPrices,
        payload
      )
    },
    hotelsAvailabilityCompleted: (state, {payload}) => {
      state.hotelAvailabilityPrices = mergeDeepRight(
        state.hotelAvailabilityPrices,
        payload
      )
      state.hotelAvailabilityPendingRequests -= 1
    }
  },
  extraReducers: builder => {
    // TODO: Search TS preexisting issue
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(
      syncStoreWithUrl.type,
      (state, action: PayloadAction<SyncStoreWithUrlPayload>) => {
        const {
          urlParams,
          isBrandOffersLockedByDefault,
          isMemberPlus,
          isEmployee,
          isAuthLoading,
          isAuthenticated,
          landingQueryString,
          landingProfileId,
          sapiLabel,
          geolocation,
          isAppLockedDealAudience
        } = action.payload

        const currentSearchParameters = state.searchParameters

        const nextSearchParameters = urlParamsToSapiSearchParameters(
          urlParams,
          currentSearchParameters,
          isBrandOffersLockedByDefault,
          isMemberPlus,
          isEmployee,
          landingQueryString,
          landingProfileId,
          isAuthenticated,
          isAuthLoading,
          sapiLabel,
          isAppLockedDealAudience
        ) as SearchParameters

        state.searchParameters = {
          ...nextSearchParameters,
          geolocation
        }
      }
    )
    // Syncing offres with state only for analytics purposes
    builder.addCase(
      offersSearchReceived.type,
      (state, action: PayloadAction<OfferSearchReceivedPayload>) => {
        const {offers, parameters} = action.payload || {}
        const forceSkip = !parameters.shouldUpdateOfferEntities
        if (!offers || forceSkip) return
        const hotelId = offers.id
        state.hotelOfferEntities = {
          ...state.hotelOfferEntities,
          [hotelId]: offers
        }
      }
    )
  }
})

export default reducer

export const {
  newSearch,
  searchStarted,
  parametersReceived,
  anchorReceived,
  hotelsReceived,
  offersRequested,
  offersReceived,
  searchCompleted,
  moreHotelsRequested,
  setNextPage,
  hotelsAvailabilityRequested,
  hotelsAvailabilityReceived,
  hotelsAvailabilityCompleted
} = actions
