import {useSelector} from 'react-redux'
import {getAccommodationPageHotelId} from 'modules/accommodation/selectors'
import {isVioOfferOrBundleSelected} from 'modules/accommodation/utils'
import {
  getAppLockedDealConfig,
  getHotelOfferEntityOffers
} from 'modules/sapiSearch/selectors'
import {getIsCheapestRoomOffer} from 'modules/searchApi/selectors'
import {RootState} from 'store'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Placement} from '@daedalus/core/src/deepLinking/types'

export const useIsRoomOfferAppLocked = ({offerId}) => {
  const hotelId = useSelector(getAccommodationPageHotelId)
  const {isDesktop} = useDeviceLayout()

  const hasSelectedOffer = isVioOfferOrBundleSelected()

  const hotelOffers = useSelector((state: RootState) =>
    getHotelOfferEntityOffers(state, hotelId)
  )

  const isCheapestOffer = useSelector(state =>
    getIsCheapestRoomOffer(state, offerId)
  )
  const {isAppLockedDeal: isTopOfferAppLocked} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, hotelOffers?.[0]?.id)
  )
  const {isAppLockedDeal: isSecondOfferAppLocked} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, hotelOffers?.[1]?.id)
  )
  const {isAppLockedDeal: isThirdOfferAppLocked} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, hotelOffers?.[2]?.id)
  )

  const isAppLockedDeal =
    isTopOfferAppLocked || isSecondOfferAppLocked || isThirdOfferAppLocked
  const isRoomOfferAppLocked =
    isAppLockedDeal && isCheapestOffer && !hasSelectedOffer

  const appDownloadLinkId = isDesktop
    ? Placement.WebToAppDesktopAccommodationRoomOffer
    : Placement.AccommodationRoomOffer
  return {isRoomOfferAppLocked, appDownloadLinkId}
}
