import {loadModule} from 'utils'

import type {PreferencesOverlayProps} from './PreferencesOverlay'

export const PreferencesOverlayLoadable = loadModule<PreferencesOverlayProps>(
  async () =>
    import(
      /* webpackChunkName: "PreferencesOverlay" */
      './PreferencesOverlay'
    ),
  {}
)
