import React from 'react'

import {Divider} from '@daedalus/atlas/Divider'
import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {SkeletonLoader} from '@daedalus/atlas/SkeletonLoader'

export const RoomDetailsSectionSkeleton = () => {
  return (
    <ContentWrapper paddingTop="s500">
      <SkeletonLoader height={20} width={80} marginBottom={8} />
      <Grid
        container
        spacing="s000"
        direction="row"
        justify="space-between"
        gap="s500"
      >
        <Grid>
          <SkeletonLoader height={20} width={150} marginBottom={8} />
        </Grid>
        <Grid>
          <SkeletonLoader height={20} width={80} />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
        spacing="s000"
      >
        <SkeletonLoader height={100} width={150} marginBottom={12} />
      </Grid>
      <ContentWrapper paddingY="s500">
        <RoomPriceSkeleton />
      </ContentWrapper>
      <Divider />
    </ContentWrapper>
  )
}

export const RoomPriceSkeleton = () => {
  return (
    <Grid container spacing="s000" alignItems="center" justify="space-between">
      <SkeletonLoader height={22} width={150} />
      <SkeletonLoader height={22} width={80} />
    </Grid>
  )
}
