import {getPageName} from 'middleware/analytics/utils/getPageName'
import {createSelector, createStructuredSelector} from 'reselect'
import Settings from 'Settings'
import {RootState} from 'store'
import {MeasurementSystemType} from 'types/Localization'
import {MetaState} from 'types/State'
import {url} from 'utils'

// Direct import of selector module needed instead of useBrand hook in selector context where hooks cannot be used.
// eslint-disable-next-line @vio/custom-rules/prefer-usebrand-hook
import {
  getBrand,
  getBrandSapiCode
} from '@daedalus/core/src/_web/brand/modules/selectors'
import {Page} from '@daedalus/core/src/analytics/types/Events'
import {MY_BOOKINGS_PATH} from '@daedalus/core/src/booking/constants'
import {getMyBookingsHost} from '@daedalus/core/src/brand/utils'
import {DEVICE_TYPES} from '@daedalus/core/src/utils/userAgent/types'
import {ClientOptions} from '@findhotel/sapi'

import type {DevicePlatform} from '@daedalus/core/src/_web/utils/userAgent/types'
import type {DeviceCategory} from '@daedalus/core/src/utils/userAgent/types'

export type StateType = RootState

export const getMeta = (state: RootState): MetaState => state.meta

export const getIsRtlLanguage = (state: StateType): boolean =>
  Boolean(state.meta.isRTLLanguage)

export const getAnonymousId = createSelector(
  [(state: StateType) => state.meta.anonymousId],
  anonymousId => anonymousId ?? null
)

export const getLocaleCode = createSelector(
  [(state: StateType) => state.meta.localeCode],
  localeCode => localeCode
)

export const getAddress = createSelector(
  [(state: StateType) => state.meta.address],
  address => address
)

export const getLanguageCode = createSelector(
  [(state: StateType) => state.meta.languageCode],
  languageCode => languageCode ?? null
)

export const getIsAcquiringGeoCoordinates = (state: StateType): boolean =>
  state.meta.isAcquiringGeoCoordinates ?? false

const getUserCountryCodeValue = (state: StateType): string =>
  state.meta.userCountryCode

export const getUserCountryCode = createSelector(
  [getUserCountryCodeValue],
  userCountryCode => userCountryCode
)

export const getUserCountryRegion = (state: StateType) =>
  state.meta.userCountryRegion

export const getUserCity = (state: StateType): string => state.meta.userCity

export const getShowComparisonOffers = (state: StateType) =>
  state.meta.showComparisonOffers

export const getCurrencyCode = (state: StateType) =>
  state.meta.currencyCode || null

export const getDeviceCategory = (state: StateType): DeviceCategory =>
  state.meta.deviceCategory || 'desktop'

export const getDeviceLayout = (state: StateType): DeviceCategory =>
  state.meta.deviceLayout || 'desktop'

export const getDevicePlatform = (state: StateType): DevicePlatform =>
  state.meta.devicePlatform || null

export const getUserAgentDetails = ({
  meta
}: StateType): MetaState['userAgentDetails'] => meta.userAgentDetails

export const getTaxesIncluded = createSelector(
  [({meta}) => meta.includeTaxes],
  includeTaxes => includeTaxes
)

export const getLocalTaxesIncluded = createSelector(
  [({meta}) => meta.includeLocalTaxes],
  includeLocalTaxes => includeLocalTaxes
)

export const getFallbackLanguages = createSelector(
  [(state: StateType) => state.meta.fallbackLanguages],
  fallbackLanguages => fallbackLanguages
)

export const getPreferedLanguages = createSelector(
  [getLanguageCode, getFallbackLanguages],
  (languageCode, fallbackLanguages): string[] => [
    languageCode,
    ...(fallbackLanguages ?? [])
  ]
)

export const getViewPortHeight = (state: StateType): number =>
  state.meta.viewPortHeight

export const getViewPortWidth = (state: StateType): number =>
  state.meta.viewPortWidth

export const getAnalyticsPageName = (state: StateType): Page => {
  return state.meta.analyticsPageName || getPageName(window.location.pathname)
}

export const getLocalizationIsInitialized = (state: StateType): boolean =>
  state.meta.localizationIsInitialized

export const getGeolocationCoordinates = (state: StateType) =>
  state.meta?.geolocation

export const getIsSyntheticTest = (state: StateType): boolean =>
  state.meta.anonymousId === 'test_website_synthetics'

export const getUserMeasurementSystem = (
  state: StateType
): MeasurementSystemType => state.meta.userMeasurementSystem

type SapiMetaOptions = Pick<
  ClientOptions,
  'anonymousId' | 'language' | 'currency' | 'brand'
>

export const getDeviceType = createSelector(
  [getDeviceCategory, () => new URL(window.location.href).searchParams],
  (deviceCategory, searchParams) => {
    const deviceType = searchParams.get('deviceType') as DeviceCategory
    return DEVICE_TYPES.includes(deviceType) ? deviceType : deviceCategory
  }
)

export const getSapiMetaOptions = createStructuredSelector<
  RootState,
  SapiMetaOptions
>({
  anonymousId: getAnonymousId,
  language: getLanguageCode,
  currency: getCurrencyCode,
  brand: getBrandSapiCode
})

export const getMyBookingsUrl = createSelector(
  [getAnonymousId, getUserCountryCode, getLanguageCode, getBrand],
  (anonymousId, userCountry, locale, brand) => {
    try {
      const myBookingsHost = getMyBookingsHost({
        brand,
        appEnv: Settings.get('APP_ENV')
      })
      const host = new URL(MY_BOOKINGS_PATH, myBookingsHost)

      const query = url.stringify({anonymousId, userCountry, locale})

      return `${host}?${query}`
    } catch {
      return ''
    }
  }
)

const getIsReturningUserValue = (state: StateType): boolean =>
  state.meta.isReturningUser

export const getIsReturningUser = createSelector(
  [getIsReturningUserValue],
  isReturningUser => isReturningUser
)

export const getFirstVisit = (state: StateType): string => state.meta.firstVisit

export const getVisitsCount = (state: StateType): number =>
  state.meta.visitsCount

export const getDeviceId = createSelector(
  [(state: StateType): string => state.meta.deviceId],
  deviceId => deviceId
)

export const getTrafficSourceFromState = (state: StateType) =>
  state.meta.trafficSource
