import {createAction} from '@reduxjs/toolkit'

import {SearchResultsContext} from '@daedalus/core/src/analytics/types/Events'

export const trackStaticLocationMapBoxDisplayed = createAction(
  'analytics/trackStaticLocationMapBoxDisplayed'
)

export const trackStaticLocationMapBoxLoaded = createAction(
  'analytics/trackStaticLocationMapBoxLoaded'
)

export const trackInteractiveLocationMapBoxDisplayed = createAction(
  'analytics/trackInteractiveLocationMapBoxDisplayed'
)

export const trackInteractiveLocationMapBoxLoaded = createAction(
  'analytics/trackInteractiveLocationMapBoxLoaded'
)

export const trackMapViewDisplayed = createAction(
  'analytics/trackMapViewDisplayed'
)

export const trackMapBoxLoaded = createAction('analytics/trackMapBoxLoaded')

export const trackAccommodationPageReady = createAction(
  'analytics/trackAccommodationPageReady'
)

export const trackAccommodationPageLoad = createAction(
  'analytics/trackAccommodationPageLoad'
)

export const trackSystemSearchSucceeded = createAction<{
  searchResultsContext: SearchResultsContext
}>('analytics/trackSystemSearchSucceeded')
